import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { setAPIStatus } from "../apiState.interface";
import { TagService } from "src/app/shared/services/tag.service";
import { createUpdateTagSuccess, deleteTagSuccess, invokeAllTagNames, invokeAllTags, invokeCreateTag, invokeDeleteTag, invokeUpdateTag, setAllTagNames, setAllTags } from "./tag.actions";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { TagInterface } from "src/app/shared/models/tag/tag.interface";

@Injectable()
export class TagEffect {
    constructor(
        private actions$: Actions,
        private tagService : TagService
    ){}

    invokeAllTagNames$ = createEffect(() =>
        this.actions$.pipe(
        ofType(invokeAllTagNames),
        mergeMap((state) => {
            return this.tagService.getAllTagNames().pipe(
            map((data: string[] | null) => setAllTagNames({ tagNames: data })),
            catchError((error) =>
                of(
                setAPIStatus({
                    apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                    },
                })
                )
            )
            );
        })
        )
    );

    invokeAllTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAllTags),
      mergeMap((state) => {
        return this.tagService.getAllTags().pipe(
          map((data: TagInterface[] | null) => setAllTags({ tags: data })),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeCreateTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCreateTag),
      mergeMap((state) => {
        return this.tagService.createTag(state.tag).pipe(
          map((data: SuccessDto) =>
            createUpdateTagSuccess({ message: data.message })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeUpdateTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateTag),
      mergeMap((state) => {
        return this.tagService.updateTag(state.tag, state.oldName).pipe(
          map((data: SuccessDto) =>
            createUpdateTagSuccess({ message: data.message })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeDeleteTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeDeleteTag),
      mergeMap((state) => {
        return this.tagService.deleteTag(state.tagName).pipe(
          map((data: SuccessDto) =>
            deleteTagSuccess({ message: data.message })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
}