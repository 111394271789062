import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const regionState = (state : AppStateInterface) =>
    state.regionState;
export const allRegionSelector = createSelector(
    regionState,
    (state) => state.regions
)

export const allRegionNamesSelector = createSelector(
    regionState,
    (state) => state.regionNames
)


export const loadingRegionSelector = createSelector(
    regionState,
    (state) => state.loading
)

export const createUpdateRegionSuccessSelector = createSelector(
    regionState,
    (state) => state.createUpdateSuccess
)

export const deleteRegionSuccessSelector = createSelector(
    regionState,
    (state) => state.deleteSuccess
)

export const resultRegionsByZonesSelector = createSelector(
    regionState,
    (state) => state.regionsByZones
)
export const saveSelectedRegionSelector = createSelector(
    regionState,
    (state) => state.selectedRegion
)