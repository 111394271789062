import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { FileService } from "../../shared/services/file/file.service";
import { setAPIStatus } from "../apiState.interface";
import { invokeDownloadFile, invokeUploadFile, invokeUploadFileWithProgress, resultDownloadFile, setFileName, setFileProgress } from "./file.actions";
import { HttpEvent, HttpResponse } from "@angular/common/http";

@Injectable()
export class fileEffect {
  constructor(private actions$: Actions, private fileService: FileService) {}

  invokeUploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUploadFile),
      mergeMap((state) => {
        return this.fileService.uploadFile(state.files).pipe(
          map((data: any) => setFileName({ filename: data.filename })),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeUploadFileWithProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUploadFileWithProgress),
      mergeMap((state) => {
        return this.fileService.uploadFileWithProgress(state.file).pipe(
          map((data: HttpEvent<any>| HttpResponse<any> | null) => setFileProgress({ progress: data })),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
  invokeDownloadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeDownloadFile),
      mergeMap((state) => {
        return this.fileService.downloadFile(state.filename).pipe(
          map((data: any) => resultDownloadFile({ downloadedFile: data })),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
}
