import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Constants } from '../../data/constants';
import { CommonParamsIberis } from '../../models/iberis/paramsForGetAll';
import { NextPayment } from '../../models/iberis/paymentsReceived/nextPayment';
import { ParamsPaymentReceived } from '../../models/iberis/paymentsReceived/paramsPaymentRecaived';
import { PaymentReceived } from '../../models/iberis/paymentsReceived/paymentReceived';

@Injectable({
  providedIn: 'root'
})
export class PaymentReceivedService {

  constructor(private http: HttpClient) { }

  createPaymentReceived(
    params: CommonParamsIberis,
    paymentReceived : ParamsPaymentReceived
  ): Observable<PaymentReceived | null> {
    let param = new HttpParams();
    const itemsJson = JSON.stringify(paymentReceived.pay, null, 2);
    param = param.append("date", paymentReceived.date);
    param = param.append("payment_number", paymentReceived.payment_number);
    param = param.append("notes", paymentReceived.notes);
   // param = param.append("reference", paymentReceived.reference);
   // param = param.append("bank_fee", paymentReceived.bank_fee);
    param = param.append("bank_id", paymentReceived.bank_id);
    param = param.append("payment_method", paymentReceived.payment_method);
    param = param.append("client_id", paymentReceived.client_id);
    param = param.append("currency_rate", paymentReceived.currency_rate);
    param = param.append("language", paymentReceived.language);
    param = param.append("pay[]", itemsJson);

    return this.http.post<PaymentReceived>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/sales/payment/new?${param}`,
      null
    );
  }
}
