<h6 class="mt-2">{{ "newCampaign.step1.locations.region" | translate }}</h6>
<div class="form-check checkbox checkbox-primary m-b-5">
  <input
    class="form-check-input"
    id="checkbox-primary-1"
    [checked]="CheckRegionSelectedAll()"
    (click)="selectAllRegions($event)"
    type="checkbox"
  />
  <label class="form-check-label label" for="checkbox-primary-1">{{
    "select.selectAll" | translate
  }}</label>
</div>
<div class="justify-content-center position-sticky row">
  <div
    *ngFor="let item of getAllRegions; let i = index"
    class="border-style ps-2 col-4 col-xxl-2"
  >
    <a type="button" class="card" (click)="addRemoveRegion($event, item.name)">
      <div
        class="card-body"
        [ngClass]="{
          'border-active rounded p-l-0': regionExists(item.name)
        }"
      >
        <img
          class="img"
          width="35%"
          height="35%"
          [ngClass]="{
            'active show': regionExists(item.name)
          }"
          [src]="urlCDN + item.logo"
        />
        <div class="text-center mb-2">
          {{ item.name }}
        </div>
      </div>
    </a>
  </div>
</div>