<div class="card">
  <div class="card-body" *ngIf="!isLoading">
    <div class="col-12 col-md-12">
      <div class="media">
        <img
          src="../../../../{{ icon }}"
          alt="{{ icon }}"
          style="margin-right: 10px"
        />
        <div class="media-body">
          <h5 class="f-w-600">
            <a>{{ title | translate }}</a>
            <span class="badge badge-primary pull-right"></span>
          </h5>
          <div class="col">
            <h6 class="f-w-600" *ngIf="!isPercentage">
              {{ value | toKValue }}
            </h6>
            <h6 class="f-w-600" *ngIf="isPercentage">
              {{ value }} %
            </h6>
          </div>
        </div>
      </div>
      <!--<div class="media" *ngIf="!isDashboard && !isFinished">
        <div class="mx-2">
          <span
              [ngClass]="{
                'font-danger small pt-1 fw-bold': unit < 0,
                'font-success small pt-1 fw-bold': unit >= 0
              }"
            >
              <i
                class="widget-icon"
                [ngClass]="{
                  'icon-arrow-down-left font-danger': unit < 0,
                  'icon-arrow-up-right font-success ': unit >= 0
                }"
              ></i>
              {{ unit }} %
            </span>
        </div>
        {{ "inseights.cardInsights.sinceYesterday" | translate }}
      </div>-->
    </div>
  </div>
  <div *ngIf="isLoading">
    <div class="loader-box">
      <div class="loader-34"></div>
    </div>
    <h6 class="col align-self-center f-w-600" style="text-align: center">
      {{ "fetching" | translate }}
    </h6>
  </div>
</div>
