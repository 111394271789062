export enum ObjectiveTypeEnum {
  'BRAND_AWARENESS_AND_REACH' = 'BRAND_AWARENESS_AND_REACH',
  'WEB_SITE_TRAFFIC' = 'WEB_SITE_TRAFFIC',
  'APP_PROMOTION_AND_INSTALL' = 'APP_PROMOTION_AND_INSTALL',
  'LOCAL_STORE_PROMOTIONS' = 'LOCAL_STORE_PROMOTIONS',
  'GENERATION_DE_PROSPECTS' = 'GENERATION_DE_PROSPECTS',
}

export const ObjectiveTypeEnumMapping: Record<ObjectiveTypeEnum, string> = {
  [ObjectiveTypeEnum.BRAND_AWARENESS_AND_REACH]: 'enumMapping.objectives.BRAND_AWARENESS_AND_REACH',
  [ObjectiveTypeEnum.WEB_SITE_TRAFFIC]: 'enumMapping.objectives.WEB_SITE_TRAFFIC',
  [ObjectiveTypeEnum.APP_PROMOTION_AND_INSTALL]: 'enumMapping.objectives.APP_PROMOTION_AND_INSTALL',
  [ObjectiveTypeEnum.LOCAL_STORE_PROMOTIONS]: 'enumMapping.objectives.LOCAL_STORE_PROMOTIONS',
  [ObjectiveTypeEnum.GENERATION_DE_PROSPECTS]: 'enumMapping.objectives.GENERATION_DE_PROSPECTS',
};
export const ObjectiveMapping: Record<string, ObjectiveTypeEnum> = {
  ['enumMapping.objectives.BRAND_AWARENESS_AND_REACH'] : ObjectiveTypeEnum.BRAND_AWARENESS_AND_REACH,
  ['enumMapping.objectives.WEB_SITE_TRAFFIC'] :ObjectiveTypeEnum.WEB_SITE_TRAFFIC,
  ['enumMapping.objectives.APP_PROMOTION_AND_INSTALL'] : ObjectiveTypeEnum.APP_PROMOTION_AND_INSTALL,
  ['enumMapping.objectives.LOCAL_STORE_PROMOTIONS']: ObjectiveTypeEnum.LOCAL_STORE_PROMOTIONS,
  ['enumMapping.objectives.GENERATION_DE_PROSPECTS'] : ObjectiveTypeEnum.GENERATION_DE_PROSPECTS
};

export const HasedObjectiveEnum: Record<ObjectiveTypeEnum, string> = {
  [ObjectiveTypeEnum.BRAND_AWARENESS_AND_REACH]: 'XnK7zq',
  [ObjectiveTypeEnum.WEB_SITE_TRAFFIC]: 'Jw8pRz',
  [ObjectiveTypeEnum.APP_PROMOTION_AND_INSTALL]: 'Ee3fXn',
  [ObjectiveTypeEnum.LOCAL_STORE_PROMOTIONS]: 'Fg9eQs',
  [ObjectiveTypeEnum.GENERATION_DE_PROSPECTS]: 'Dn5pJf',
};
export interface ObjectiveStatusInterface {
  id: ObjectiveTypeEnum;
  value: string;
}
