import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MapMarker } from "@angular/google-maps";

@Component({
  selector: "app-popover-map",
  templateUrl: "./popover-map.component.html",
})
export class PopoverMapComponent implements OnInit {
  //@ViewChild("mapElement") mapElement: ElementRef;
  //map: google.maps.Map;
  @Input("mapOptions") mapOptions: google.maps.MapOptions;
  @Input("markerOptions") markerOptions: google.maps.MarkerOptions;
  @Input("markerPosition") markerPosition: google.maps.LatLngLiteral;
  @Output("changePosition") changePosition = new EventEmitter<{
    latitude: number;
    longitude: number;
  }>(false);

  constructor() {}

  ngOnInit(): void {}

  changeMarkerPosition(marker: MapMarker) {
    this.markerPosition = marker.marker
      ?.getPosition()
      ?.toJSON() as google.maps.LatLngLiteral;

    this.changePosition.emit({
      latitude: this.formatPositionNumber(
        marker.marker?.getPosition()?.toJSON().lat
      ),
      longitude: this.formatPositionNumber(
        marker.marker?.getPosition()?.toJSON().lng
      ),
    });
  }

  formatPositionNumber(number: number | undefined): number {
    return Number(Number(number).toFixed(4));
  }
}
