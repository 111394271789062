<div class="container-fluid">
  <div class="card">
    <div class="card-header pb-0">
      <div class="media">
        <h4>{{ "admin.campaigns.pipeline.title" | translate }}</h4>
        <div class="media-body text-end">
          <form class="d-inline-flex" [formGroup]="formFilter">
            <div class="w-auto" style="margin-left: 5px">
              <div
                class="input-group date"
                style="width: 17rem"
                id="dt-date"
                data-target-input="nearest"
              >
                <div
                  class="input-group-text bg-primary"
                  data-target="#dt-date"
                  [owlDateTimeTrigger]="dt"
                >
                  <i
                    class="icofont icofont-ui-calendar"
                    style="color: white; font-size: 12px"
                  ></i>
                </div>
                <input
                  style="font-size: 12px"
                  width="max-content"
                  formControlName="date"
                  class="form-control datetimepicker-input digits"
                  type="text"
                  name="daterange"
                  [placeholder]="'placehorderPickDate' | translate"
                  [selectMode]="'range'"
                  [owlDateTimeTrigger]="dt"
                  [owlDateTime]="dt"
                  (ngModelChange)="onClickFilterDates()"
                />
              </div>
              <owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>
            </div>
            <div
              class="faq-form mr-3"
              style="color: darkgrey; display: flex; margin-left: 10px"
            >
              <i class="search-icon" data-feather="search"></i>
              <div>
                <div class="input-group" style="width: 19rem">
                  <input
                    id="table-complete-search"
                    type="text"
                    class="form-control"
                    [placeholder]="
                      'placeholder.searchByCampaignTitle' | translate
                    "
                    formControlName="title"
                    (ngModelChange)="searchTerm()"
                  />
                  <span
                    class="input-group-text p-0"
                    style="background-color: transparent"
                  >
                    <app-general-filter
                      [filter]="filterAds"
                      (optionSelected)="saveOption($event)"
                    >
                    </app-general-filter>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive data-table">
        <form>
          <table class="table table-striped table-border-vertical">
            <thead>
              <tr>
                <ng-container *ngFor="let column of columns">
                  <th
                    class="h-w-{{ column.width }}"
                    sortable="{{ column.key }}"
                    (sort)="onSort($event)"
                  >
                    {{ column.label | translate }}
                  </th>
                </ng-container>
                <th>{{ "admin.campaigns.pipeline.status" | translate }}</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody *ngIf="!(loading$ | async)">
              <tr *ngFor="let adCompaign of advertisingCampaign; let j = index">
                <ng-template #none>
                  <td>
                    {{ "undefined" | translate }}
                  </td>
                </ng-template>
                <td>
                  <a
                    type="button"
                    style="color: black"
                    [routerLink]="[
                      '/',
                      'dashboard',
                      'admin',
                      'campaigns',
                      'pipeline',
                      'statistics',
                      adCompaign.advertisingCampaignHashedId
                    ]"
                  >
                    <td>
                      {{ adCompaign.title }}
                    </td>
                  </a>
                </td>
                <td
                  *ngIf="
                    firstNames[j] &&
                    lastNames[j];
                    else none
                  "
                >
                  {{ firstNames[j] }} <br />
                  {{ lastNames[j] }}
                </td>
                <td *ngIf="adCompaign.objectiveName; else none">
                  {{ campaignObjective[adCompaign.objectiveName] | translate }}
                </td>
                <td *ngIf="adCompaign.locationPartners; else none">
                  <app-column-location-partners
                    [locationPartners]="adCompaign.locationPartners"
                    [j]="j"
                  ></app-column-location-partners>
                </td>
                <td *ngIf="adCompaign.startingDate; else none">
                  {{ adCompaign.startingDate | localizedDate }}
                </td>
                <td *ngIf="adCompaign.endingDate; else none">
                  {{ adCompaign.endingDate | localizedDate }}
                </td>
                <td *ngIf="adCompaign.status; else none">
                  <span
                    [ngClass]="
                      'badge ' +
                      badgeService.getBadgeForAdvertisingCampaingStatus(
                        adCompaign.status
                      )
                    "
                  >
                    {{
                      "enumMapping.campaigns.status." + adCompaign.status
                        | translate
                    }}
                  </span>
                </td>
                <td>
                  <app-drob-zone-options
                    [options]="getOptions(adCompaign)"
                    (optionSelected)="
                      saveAction($event, adCompaign)
                    "
                  ></app-drob-zone-options>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            *ngIf="count == 0 && !(loading$ | async)"
            class="d-flex justify-content-center m-t-20"
          >
            {{ "noData" | translate }}
          </div>
          <div *ngIf="loading$ | async">
            <ng-container style="float: right" class="flex">
              <div class="loader-box">
                <div class="loader-34"></div>
              </div>
              <h6
                class="col align-self-center f-w-600"
                style="text-align: center"
              >
                {{ "fetching" | translate }}
              </h6>
            </ng-container>
          </div>
          <div class="d-flex">
            <div class="col-3"></div>
            <div class="col-6">
              <div class="d-flex justify-content-center p-3">
                <ngb-pagination
                  [collectionSize]="count!"
                  [(page)]="pageNumber"
                  [pageSize]="advertisingCompaignFilter.pager.pageSize"
                  [rotate]="true"
                  [boundaryLinks]="true"
                  (pageChange)="changePage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <div class="col-3">
              <div class="d-flex justify-content-end p-3">
                <select
                  class="form-select"
                  style="width: auto"
                  name="pageSize"
                  [(ngModel)]="changePageSize"
                >
                  <option [ngValue]="10">
                    10 {{ "pagination.itemsPerPage" | translate }}
                  </option>
                  <option [ngValue]="15">
                    15 {{ "pagination.itemsPerPage" | translate }}
                  </option>
                  <option [ngValue]="20">
                    20 {{ "pagination.itemsPerPage" | translate }}
                  </option>
                  <option [ngValue]="count">
                    {{ "pagination.allItems" | translate }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
