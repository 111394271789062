import {
  createAction,
  createReducer,
  createSelector,
  on,
  props,
} from "@ngrx/store";
import { AppStateInterface } from "./appState.interface";

export interface ApiStateInterface {
  apiStatus: "" | "error" | "success";
  apiResponseMessage: any;
}

export const setAPIStatus = createAction(
  "[API] success or failure status",
  props<{ apiStatus: ApiStateInterface }>()
);

export const initialApiState: Readonly<ApiStateInterface> = {
  apiResponseMessage: "",
  apiStatus: "",
};

export const ApiReducer = createReducer(
  initialApiState,
  on(setAPIStatus, (state, { apiStatus }) => {
    return {
      ...state,
      ...apiStatus,
    };
  }),
);

export const apiState = (state: AppStateInterface) => state.apiState;

export const selectApiState = createSelector(apiState, (state) => state);

export const invokeComponentDistroyed = createAction(
  "[ComponentDestroyed] ComponentDestroyed"
);
export const invokeApiDistroyed = createAction(
  "[APIDestroyed] APIDestroyed"
);
export const invokeApiDestroyedLocation_overview = createAction(
  "[APIDestroyed location overview] APIDestroyed location overview"
)