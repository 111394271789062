<div class="modal-header">
  <div>
    <h3 class="modal-title" id="exampleModalLabel">
      {{ "buttons.addRouter" | translate }}
    </h3>
    <h6 style="color: gray">{{ "addRouter.pop-up.sub-title" | translate }}</h6>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal()"
  ></button>
</div>
<div class="modal-body">
  <form
    novalidate
    class="theme-form login-form creation-form"
    style="padding-left: 0; padding-right: 0"
    [formGroup]="routerForm"
    (ngSubmit)="submit()"
  >
    <aw-wizard #wizard [disableNavigationBar]="true">
      <aw-wizard-step formGroupName="routerSpecification">
        <app-router-specification
          [routerType]="routerType"
          [form]="routerForm.get('routerSpecification')! | asFormGroup"
        ></app-router-specification>
        <div *ngIf="routerType; else fsi">
          <app-relead-router
            [form]="routerForm.get('releadRouter')! | asFormGroup"
          ></app-relead-router>
          <button
            class="btn btn-primary pull-right"
            type="button"
            [disabled]="
              !routerForm.get('routerSpecification')?.valid ||
              !routerForm.get('releadRouter')?.valid
            "
            (click)="checkRouterExist()"
          >
            {{ "buttons.next" | translate }}
          </button>
        </div>
        <ng-template #fsi>
          <app-fsi-router
            [form]="routerForm.get('fsiRouter')! | asFormGroup"
          ></app-fsi-router>
          <button
            class="btn btn-primary pull-right"
            type="button"
            [disabled]="
              !routerForm.get('routerSpecification')?.valid ||
              !routerForm.get('fsiRouter')?.valid
            "
            (click)="checkRouterExist()"
          >
            {{ "buttons.next" | translate }}
          </button>
        </ng-template>
        <button
          class="btn btn-outline-primary pull-left me-1"
          type="button"
          (click)="clearForm()"
        >
          {{ "buttons.cancel" | translate }}
        </button>
      </aw-wizard-step>
      <aw-wizard-step formGroupName="accessInformation">
        <app-access-information
          [form]="routerForm.get('accessInformation')! | asFormGroup"
        ></app-access-information>
        <button
          class="btn btn-primary pull-right"
          type="button"
          awNextStep
          [disabled]="!routerForm.get('accessInformation')?.valid"
        >
          {{ "buttons.next" | translate }}
        </button>
        <button
          class="btn btn-outline-primary pull-left me-1"
          type="button"
          awPreviousStep
        >
        {{"buttons.return" | translate}}
        </button>
      </aw-wizard-step>
      <aw-wizard-step formGroupName="locationInformation">
        <app-location-partner-information
          [form]="routerForm.get('locationInformation')! | asFormGroup"
        ></app-location-partner-information>
        <button
          class="btn btn-primary pull-right"
          type="submit"
          [disabled]="!routerForm.get('locationInformation')?.valid"
          awNextStep
        >
          <loading-button
            [loading]="loading$ | async"
            [value]="'buttons.submit'"
            [icon]="null"
          ></loading-button>
        </button>
        <button
          class="btn btn-outline-primary pull-left me-1"
          type="button"
          awPreviousStep
        >
          {{ "buttons.return" | translate }}
        </button>
      </aw-wizard-step>
    </aw-wizard>
  </form>
</div>
