import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, fromEvent, Subject } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { NavBarInfo } from "../data/sibeBarInfo";
import { Menu } from "../models/side-bar-menu.interface";

@Injectable({
  providedIn: "root",
})
export class NavService {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Search Box
  public search: boolean = false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
        }
        if (evt.target.innerWidth < 1199) {
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
      });
    }
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      title: "Sample Page",
      icon: "buttons",
      type: "sub",
      active: true,
      children: [
        {
          path: "/sample-page/sample-page1",
          title: "sample-page-1",
          type: "link",
        },
        {
          path: "/sample-page/sample-page2",
          title: "sample-page-2",
          type: "link",
        },
      ],
    },
    { path: "/dashboard", icon: "buttons", title: "Dashboard", type: "link" },
  ];
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

  MENU_ITEMS_ADMIN: Menu[] = NavBarInfo.admin;
  adminItems = new BehaviorSubject<Menu[]>(this.MENU_ITEMS_ADMIN);

  MENU_ITEMS_ADVERTISER: Menu[] = NavBarInfo.advertiser;
  advertiserItems = new BehaviorSubject<Menu[]>(this.MENU_ITEMS_ADVERTISER);

  MENU_ITEMS_LOCATION_PARTNER: Menu[] = NavBarInfo.locationPartner;
  locationPartnerItems = new BehaviorSubject<Menu[]>(
    this.MENU_ITEMS_LOCATION_PARTNER
  );
}
