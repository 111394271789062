import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject, takeUntil } from "rxjs";
import { Constants } from "src/app/shared/data/constants";
import { IberisActivities } from "src/app/shared/models/iberis/activities";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeCheckIfExistByCompanyName,
  setCheckUserExistsByCompanyName,
} from "src/app/store/user/user.actions";
import { checkUserExistByCompanyNameSelector } from "src/app/store/user/user.selectors";

@Component({
  selector: "app-advertiser-company-info",
  templateUrl: "./advertiser-company-info.component.html",
})
export class AdvertiserCompanyInfoComponent implements OnInit, OnDestroy {
  @Input("form") form: FormGroup;
  companyActivity: IberisActivities[];
  isExist$: Observable<boolean | null>;
  ngDestroyed$: Subject<void> = new Subject<void>();
  constructor(
    private translate: TranslateService,
    private store: Store<AppStateInterface>,
    private toastr: ToastrService
  ) {
    this.isExist$ = this.store
      .pipe(select(checkUserExistByCompanyNameSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }
  ngOnDestroy(): void {
    this.ngDestroyed$.next();
    this.ngDestroyed$.unsubscribe();
  }

  ngOnInit() {
    this.isExist$.subscribe((data) => {
      if (data == true) {
        this.toastr.error(
          this.translate.instant("response.error.companyName-exist"),
          this.translate.instant("response.errorTitle")
        );
        this.store.dispatch(
          setCheckUserExistsByCompanyName({ isExistByCompanyName: null })
        );
      }
    });
    if (this.translate.currentLang == "fr")
      this.companyActivity = Constants.IBERIS_ACTIVITIES_FR;
    else this.companyActivity = Constants.IBERIS_ACTIVITIES_EN;

    this.translate.onLangChange.subscribe(
      (langChangeEvent: LangChangeEvent) => {
        if (langChangeEvent.lang == "fr")
          this.companyActivity = Constants.IBERIS_ACTIVITIES_FR;
        else this.companyActivity = Constants.IBERIS_ACTIVITIES_EN;
      }
    );
  }
  checkUserExist() {
    this.store.dispatch(
      invokeCheckIfExistByCompanyName({
        companyName: this.form.get("companyName")?.value,
      })
    );
  }
}
