<div class="bar-chart-widget">
  <div class="bottom-content card-body">
    <div class="row">
      <div class="col-12">
        <div id="chart-widget4">
          <apx-chart
            #chartApex
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [labels]="chartOptions.labels"
            [legend]="chartOptions.legend"
            [yaxis]="chartOptions.yaxis"
            [xaxis]="chartOptions.xaxis"
            [dataLabels]="chartOptions.dataLabels"
            [stroke]="chartOptions.stroke"
            [title]="chartOptions.title"
            *ngIf="length > 0 && !isLoading"
          >
          </apx-chart>
          <div class="text-center" *ngIf="isLoading">
            <div class="loader-box">
              <div class="loader-34"></div>
            </div>
            <h6 class="f-w-600">{{ "fetching" | translate }}</h6>
          </div>
          <div *ngIf="length == 0 && !isLoading;" class="d-flex justify-content-center m-t-20">
            {{"noData" | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
