<app-help [link]="bookMeeting" [type]="'lp'"></app-help>
<div class="container-fluid" style="padding-left: 0">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 box-col-12">
      <div class="card">
        <div class="card-header p-b-0">
          <div class="d-flex align-items-baseline">
            <i class="icofont icofont-abacus-alt" style="font-size: 18px"></i>
            <h4 class="ps-2 m-r-5 m-b-0">
              {{ "newCampaign.step2.title" | translate }}
            </h4>
            <!--<i class="icofont icofont-info-circle" style="color: darkgrey"></i>-->
          </div>
        </div>
        <div class="card-body">
          <p class="m-b-10">
            {{ "location.promote.note" | translate }}
          </p>
          <h5 class="f-w-600">
            {{ "location.promote.specification" | translate }}
          </h5>
          <div class="m-b-10 m-l-30 color-darkGray">
            <div class="m-l-5">
              <ul>
                <li class="font-a">
                  <b>{{
                    "newCampaign.step2.specification.line1.title" | translate
                  }}</b
                  >{{
                    "newCampaign.step2.specification.line1.description"
                      | translate
                  }}
                </li>
                <li class="font-a">
                  <b>{{
                    "newCampaign.step2.specification.line2.title" | translate
                  }}</b
                  >{{
                    "newCampaign.step2.specification.line2.description"
                      | translate
                  }}
                </li>
                <li class="font-a">
                  <b>{{
                    "newCampaign.step2.specification.line3.title" | translate
                  }}</b
                  >{{
                    "newCampaign.step2.specification.line3.description"
                      | translate
                  }}
                </li>
                <li class="font-a">
                  <b>{{
                    "newCampaign.step2.specification.line4.title" | translate
                  }}</b
                  >{{
                    "newCampaign.step2.specification.line4.description"
                      | translate
                  }}
                </li>
                <li class="font-a">
                  <b>{{
                    "newCampaign.step2.specification.line5.title" | translate
                  }}</b
                  >{{
                    "newCampaign.step2.specification.line5.description"
                      | translate
                  }}
                </li>
              </ul>
            </div>
          </div>

          <label class="col-form-label label p-t-0">
            {{
              "newCampaign.step2.specification.downloadGuide.part1" | translate
            }}
            <a
              type="button"
              style="color: #ff69b4"
              class="font-a"
              [href]="videoGuideLink"
              target="_blank"
              >{{
                "newCampaign.step2.specification.downloadGuide.part2"
                  | translate
              }}</a
            >
          </label>

          <div class="p-r-30">
            <div class="m-b-10" *ngIf="showProgress">
              <ngb-progressbar [value]="uploadProgress"
                >{{ uploadProgress }}%</ngb-progressbar
              >
            </div>
            <ngx-dropzone
              [multiple]="false"
              (change)="onSelect($event.addedFiles)"
              accept="video/*"
            >
              <ngx-dropzone-label>
                <div>
                  <div class="dz-message needsclick">
                    <i class="icon-cloud-up"></i>
                    <p style="color: black">
                      {{ "dropzoneFile.title" | translate }}
                    </p>
                    <p class="dz-message needsclick" style="color: black">
                      {{ "dropzoneFile.rulePart1" | translate }}
                      <strong [ngClass]="{ 'txt-danger': showError }"
                        >{{ "dropzoneFile.rulePart2" | translate }} 5MB</strong
                      >
                    </p>
                  </div>
                </div>
              </ngx-dropzone-label>
              <ngx-dropzone-preview
                *ngFor="let f of files"
                [removable]="true"
                [file]="f"
                (removed)="onRemove(f)"
              >
              <ngx-dropzone-label style="align-self: flex-end; margin: 0;">
                <div class="ligne-top"></div>
                <div class="new-dropdown-label">
                  {{ f.name.split(".")[0] | castFileName }} .{{ f.type.split("/")[1] }}
                </div>
              </ngx-dropzone-label>
              </ngx-dropzone-preview>
            </ngx-dropzone>
          </div>
          <div
            style="display: flex; justify-content: end"
            class="m-r-30 m-t-10"
          >
            <button
              class="btn btn-outline-primary m-r-10"
              (click)="onClickClear()"
            >
              {{ "buttons.delete" | translate }}
            </button>
            <button
              class="btn btn-primary"
              (click)="onSubmit()"
              [disabled]="!submitted"
            >
              <loading-button
                [loading]="loading"
                [value]="'buttons.confirm' | translate"
                [icon]="null"
              ></loading-button>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 box-col-12">
      <h4>{{ "location.promote.preview" | translate }}</h4>
      <hr />
      <div class="col-6 col-lg-9 col-xxl-6" style="margin: auto">
        <app-ad-video-preview></app-ad-video-preview>
      </div>
    </div>
  </div>
</div>
