import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeAllCategoryNames } from "src/app/store/category/category.actions";
import { allCategoryNamesSelector } from "src/app/store/category/category.selectors";
import { subCategoriesByCategoryNameSelector } from "src/app/store/subCategory/subCategory.selectors";
import { invokeSubCategoriesByCategory } from "src/app/store/subCategory/subCategory.actions";
import { invokeAllTagNames } from "src/app/store/tag/tag.actions";
import { allTagNamesSelector } from "src/app/store/tag/tag.selectors";
@Component({
  selector: "app-company-specification",
  templateUrl: "./company-specification.component.html",
})
export class CompanySpecificationComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild("ngSelectComponent") ngSelectComponent: NgSelectComponent;
  @Input("form") form: FormGroup;

  //category
  allCategoryNames$: Observable<string[] | null>;
  getCategoryNames: string[] | null;

  //subCategoryByCategory
  subCategoriesByCategory$: Observable<string[] | null>;
  getSubCategoriesByCategory: string[] | null;

  //tag
  allTagNames$: Observable<string[] | null>;
  getTagNames: string[];

  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder
  ) {
    super(store);

    this.allCategoryNames$ = this.store
      .pipe(select(allCategoryNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.subCategoriesByCategory$ = this.store
      .pipe(select(subCategoriesByCategoryNameSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.allTagNames$ = this.store
      .pipe(select(allTagNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.allCategoryNames$.subscribe((result: string[] | null) => {
      if (result == null) {
        this.store.dispatch(invokeAllCategoryNames());
      }
    });

    this.allTagNames$.subscribe((result: string[] | null) => {
      if (result == null) {
        this.store.dispatch(invokeAllTagNames());
      }
    });

    this.subCategoriesByCategory$.subscribe((result: string[] | null) => {
      if (result != null) {
        this.getSubCategoriesByCategory = result;
      }
    });
  }

  onChangeCategory() {
    this.form.get("subCategories")?.reset([]);
    this.store.dispatch(
      invokeSubCategoriesByCategory({
        categoryName: this.form.get("category")?.value,
      })
    );
  }
}
