import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store, select } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { invokeDeleteAdvertiser } from "src/app/store/advertiser/advertiser.actions";
import { advertiserAdvetisingCampaignsSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import {
  invokeGetAdvertiserAdvetisingCampaigns,
  resultAdvertiserAdvetisingCampaigns,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { AppStateInterface } from "src/app/store/appState.interface";

@Component({
  selector: "app-delete-advertiser",
  templateUrl: "./delete-advertiser.component.html",
})
export class DeleteAdvertiserComponent extends BaseComponent implements OnInit {
  @Input() email: string;

  advertiserAdvetisingCampaigns$: Observable<string[] | null>;

  havingAds: boolean = true;

  loading: boolean = false;
  constructor(
    private store: Store<AppStateInterface>,
    private modalService: NgbModal
  ) {
    super(store);

    this.advertiserAdvetisingCampaigns$ = this.store
      .pipe(select(advertiserAdvetisingCampaignsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit() {
    if (this.email)
      this.store.dispatch(
        invokeGetAdvertiserAdvetisingCampaigns({ email: this.email })
      );
    this.advertiserAdvetisingCampaigns$.subscribe((ads) => {
      if (ads != null) {
        ads.length != 0 ? (this.havingAds = true) : (this.havingAds = false);
        this.store.dispatch(
          resultAdvertiserAdvetisingCampaigns({ advertisingCamapigns: null })
        );
      }
    });
  }

  onClickDelete() {
    this.loading = true;
    
    if (!this.havingAds)
      this.store.dispatch(invokeDeleteAdvertiser({ email: this.email }));
  }
  closeModal() {
    this.modalService.dismissAll();
  }
}
