import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import {
  ActionInterface,
  ActionReturned,
  advertiser_campaigns,
  OptionActionEnum,
} from "src/app/shared/data/actions-data";
import {
  estimatesFilter,
  FilterInterface,
} from "src/app/shared/data/filter-data";
import { CampaignStatusEnum } from "src/app/shared/enum/campaign-status";
import {
  EstimateStatusEnum,
  EstimateStatusEnumMapping,
} from "src/app/shared/enum/estimateStatus";
import { AdsPublisherDetailsAndStatus } from "src/app/shared/models/advertising-campaigns/ads-publisher-details-and-status.interface";
import { Data } from "src/app/shared/models/iberis/commande/data";
import { ResultListOrdersFromIberis } from "src/app/shared/models/iberis/commande/resultListOrdersFromIberis";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";
import { IColumn } from "src/app/shared/models/table/column.interface";
import { BadgeService } from "src/app/shared/services/badge.service";
import { JwtService } from "src/app/shared/services/jwt.service";
import {
  invokeIdIberis,
  resultIdIberis,
} from "src/app/store/advertiser/advertiser.actions";
import { resultIdIberisSelector } from "src/app/store/advertiser/advertiser.selectors";
import { resultStatusesByListAdsSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import {
  invokeStatusesByListAds,
  resultStatusesByListAds,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeDownloadEstimate,
  invokeListOrdersFromIberisByStatusForSpecificAdvertiser,
  invokeListOrdersFromIberisForSpecificAdvertiser,
  resultListOrdersFromIberis,
} from "src/app/store/commande/commande.actions";
import {
  loadingEstimateSelector,
  resultDownloadEstimateSelector,
  resultListOrdersFromIberisSelector,
} from "src/app/store/commande/commande.selectors";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-estimates",
  templateUrl: "./estimates.component.html",
})
export class EstimatesComponent extends BaseComponent implements OnInit {
  ad: string;
  filterAds: FilterInterface = estimatesFilter;
  loading$: Observable<boolean | null>;
  columns: IColumn[] = [
    {
      key: "ref",
      label: "estimates.numEstimate",
      sorter: true,
    },
    {
      key: "startDate",
      label: "estimates.dateEstimate",
      sorter: true,
    },
    {
      key: "endDate",
      label: "estimates.dueDate",
      sorter: false,
    },
    {
      key: "title",
      label: "campaignDetails.campaignName",
      sorter: true,
    },
  ];
  listEstimatesFromIberis$: Observable<ResultListOrdersFromIberis | null>;
  resultEstimatesFromIberis: Data[] = [];
  totalRecords: number;
  params: CommonParamsIberis = {
    lang: "fr",
    companyId: environment.IBERIS.IBERIS_COMPANY_ID,
  };
  idIberis$: Observable<string | null>;
  idIberis: string;
  itemsPerPage: number = 10;
  campaignsHashedIds: string[] = [];
  adDetails$: Observable<AdsPublisherDetailsAndStatus | null>;
  adsStatus: CampaignStatusEnum[] = [];
  campaignStatus = CampaignStatusEnum;
  showOption: boolean[] = [false];
  isLoadingDownload: boolean;
  blob: Blob;
  resultDownloadEstimate: any;
  downloadEstimate$: Observable<any>;
  specificColumun: number;
  constructor(
    private store: Store<AppStateInterface>,
    public badgeService: BadgeService,
    public jwtService: JwtService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    protected translate: TranslateService
  ) {
    super(store);
    this.loading$ = this.store
      .pipe(select(loadingEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.listEstimatesFromIberis$ = this.store
      .pipe(select(resultListOrdersFromIberisSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.idIberis$ = this.store
      .pipe(select(resultIdIberisSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.adDetails$ = this.store
      .pipe(select(resultStatusesByListAdsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.downloadEstimate$ = this.store
      .pipe(select(resultDownloadEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.loading$.subscribe((data) => {});
    this.store.dispatch(
      resultListOrdersFromIberis({ resultListOrdersFromIberis: null })
    );
    this.store.dispatch(
      resultStatusesByListAds({ publisherAndStatusDetails: null })
    );
    this.store.dispatch(resultIdIberis({ idIberis: "" }));
    this.store.dispatch(invokeIdIberis());
    this.filterAds.name.map((e) => {
      e.data = Object.keys(EstimateStatusEnum)
        .filter((value) => isNaN(Number(value)))
        .map(
          (key: string | any) =>
            EstimateStatusEnumMapping[key as keyof typeof EstimateStatusEnum]
        );
    });
    this.idIberis$.subscribe((result) => {
      if (result) {
        this.idIberis = result;
        this.store.dispatch(
          invokeListOrdersFromIberisForSpecificAdvertiser({
            params: { ...this.params },
            contactHashedId: this.idIberis,
            start: 0,
            length: this.itemsPerPage,
            draw: this.itemsPerPage,
          })
        );
      }
    });
    this.listEstimatesFromIberis$.subscribe(
      (result: ResultListOrdersFromIberis | null) => {
        if (result != null) {
          this.resultEstimatesFromIberis = result.data.estimates.data;
          this.totalRecords = result.data.estimates.recordsTotal;
          this.resultEstimatesFromIberis.map((e) => {
            this.campaignsHashedIds = [...this.campaignsHashedIds, e.notes];
          });
          this.store.dispatch(
            invokeStatusesByListAds({
              campaignsHashedIds: this.campaignsHashedIds,
            })
          );
          this.campaignsHashedIds = [];
        }
      }
    );
    this.adDetails$.subscribe((result) => {
      this.adsStatus = [];
      if (result) {
        this.adsStatus = result.status;
      }
    });
  }
  searchTerm(ad: string) {}
  filterByStatus(option: EstimateStatusEnum) {
    this.campaignsHashedIds = [];
    let status: number = 0;
    const KeyFound = Object.keys(EstimateStatusEnum).find(
      (key) => EstimateStatusEnumMapping[key as EstimateStatusEnum] === option
    );
    switch (KeyFound) {
      case EstimateStatusEnum.PAID:
        this.store.dispatch(
          invokeListOrdersFromIberisByStatusForSpecificAdvertiser({
            params: { ...this.params },
            contactHashedId: this.idIberis,
            status: 2,
            start: 0,
            length: this.itemsPerPage,
            draw: this.itemsPerPage,
          })
        );
        break;
      case EstimateStatusEnum.UNPAID:
        this.store.dispatch(
          invokeListOrdersFromIberisByStatusForSpecificAdvertiser({
            params: { ...this.params },
            contactHashedId: this.idIberis,
            status: 1,
            start: 0,
            length: this.itemsPerPage,
            draw: this.itemsPerPage,
          })
        );
        break;
      case undefined: {
        this.store.dispatch(
          invokeListOrdersFromIberisForSpecificAdvertiser({
            params: { ...this.params },
            contactHashedId: this.idIberis,
            start: 0,
            length: this.itemsPerPage,
            draw: this.itemsPerPage,
          })
        );
        break;
      }
    }
  }
  changePage(event: number) {
    let start: number = 0;
    if (event > 1) {
      start = this.itemsPerPage * (event - 1);
    } else start = 0;
    this.store.dispatch(
      resultStatusesByListAds({ publisherAndStatusDetails: null })
    );
    this.store.dispatch(
      invokeListOrdersFromIberisForSpecificAdvertiser({
        params: { ...this.params },
        contactHashedId: this.idIberis,
        start: start,
        length: this.itemsPerPage,
        draw: this.itemsPerPage,
      })
    );
  }
  openOptions(j: number) {
    this.showOption[j] = !this.showOption[j];
  }
  down(hashed: string, orderNumber: string, i: number) {
    this.isLoadingDownload = true;
    this.specificColumun = i;
    this.store.dispatch(
      invokeDownloadEstimate({ params: this.params, estimateId: hashed })
    );
    this.downloadEstimate$.subscribe((result) => {
      if (result) {
        this.resultDownloadEstimate = result;
        this.blob = this.resultDownloadEstimate.body as Blob;
        const pdf = URL.createObjectURL(this.blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = pdf;
        downloadLink.download = `${orderNumber}.pdf`;
        downloadLink.click();
        URL.revokeObjectURL(pdf);
        this.isLoadingDownload = false;
      }
    });
  }
  public onOutsideClick(event: any, j: number): void {
    this.showOption[j] = false;
    this.cdr.detectChanges();
  }
  getOptions(status: CampaignStatusEnum | null): ActionInterface[] {
    switch (status) {
      case null: {
        return advertiser_campaigns.filter(
          (value) =>
            value.type != OptionActionEnum.ADVERTISER_CAMPAIGNS_BILLING &&
            value.type != OptionActionEnum.ADVERTISER_CAMPAIGNS_STATISTICS &&
            value.type != OptionActionEnum.CAMPAIGN_DELETE &&
            value.type != OptionActionEnum.ADVERTISER_CAMPAIGNS_PUBLISH
        );
      }
      case CampaignStatusEnum.DRAFT: {
        return advertiser_campaigns.filter(
          (value) =>
            value.type != OptionActionEnum.ADVERTISER_CAMPAIGNS_BILLING &&
            value.type != OptionActionEnum.CAMPAIGN_DELETE &&
            value.type != OptionActionEnum.ADVERTISER_CAMPAIGNS_STATISTICS 
        );
      }
      case CampaignStatusEnum.AWAITING_APPROVAL: {
        return advertiser_campaigns.filter(
          (value) =>
            value.type != OptionActionEnum.CAMPAIGN_DELETE &&
            value.type != OptionActionEnum.ADVERTISER_CAMPAIGNS_PUBLISH &&
            value.type != OptionActionEnum.ADVERTISER_CAMPAIGNS_STATISTICS 
        );
      }
      case CampaignStatusEnum.AWAITING_PAYMENT: {
        return advertiser_campaigns.filter(
          (value) =>
            value.type != OptionActionEnum.CAMPAIGN_DELETE &&
            value.type != OptionActionEnum.ADVERTISER_CAMPAIGNS_BILLING &&
            value.type != OptionActionEnum.ADVERTISER_CAMPAIGNS_STATISTICS 
        );
      }
      default: {
        return advertiser_campaigns.filter(
          (value) =>
            value.type != OptionActionEnum.CAMPAIGN_DELETE &&
            value.type != OptionActionEnum.ADVERTISER_CAMPAIGNS_PUBLISH &&
            value.type != OptionActionEnum.ADVERTISER_CAMPAIGNS_STATISTICS 
        );
      }
    }
  }
  saveAction(action: ActionReturned, estimate: Data, i: number) {
    switch (action.option.type) {
      case OptionActionEnum.ADVERTISER_CAMPAIGNS_DOWNLOAD_ESTIMATE: {
        this.down(estimate.hashed_id, estimate.estimate_number, i);
        break;
      }
      case OptionActionEnum.ADVERTISER_CAMPAIGNS_BILLING: {
        this.router.navigate(["/", "dashboard", "advertiser", "payments"]);
        break;
      }
      case OptionActionEnum.ADVERTISER_CAMPAIGNS_PUBLISH: {
        this.router.navigate([
          "/",
          "dashboard",
          "advertiser",
          "advertising-campaigns",
          "create-ad",
          "payment",
          estimate.notes,
        ]);
        break;
      }
      case OptionActionEnum.ADVERTISER_CAMPAIGNS_STATISTICS: {
        this.router.navigate([
          "/",
          "dashboard",
          "advertiser",
          "insights",
          "statistics",
          estimate.notes,
        ]);
        break;
      }
    }
  }
}
