import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DateView } from "@danielmoncada/angular-datetime-picker";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/app/shared/directive/sortable.directive";
import { FilterListAdvertisingCompaign } from "src/app/shared/models/advertising-campaigns/filter-list-advertising-compaign.interface";
import { ResultFilterAdvertisingCompaign } from "src/app/shared/models/advertising-campaigns/result-filter-advertising-compaign.interface";
import {
  CampaignStatusEnum,
  CampaignStatusEnumMapping,
} from "src/app/shared/enum/campaign-status";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { IColumn } from "src/app/shared/models/table/column.interface";
import { BadgeService } from "src/app/shared/services/badge.service";
import {
  adCampaignSelector,
  filterAdvertisingCompaignSelector,
  loadingAdvertisingCampaignTableSelector,
  resultAddSynthesisReportSelector,
  resultFilterAdvertisingCompaignSelector,
  successUploadFileSelector,
  updateAdCampaignDetailsSelector,
} from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import {
  advertisingCompaignResult,
  invokeAdvertisingCompaignFilter,
  resultAddSynthesisReport,
  resultSuccessAd,
  resultUploadJustificationFile,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
import { AppStateInterface } from "src/app/store/appState.interface";
import { ObjectiveTypeEnumMapping } from "src/app/shared/enum/objectives";
import { UploadFilesComponent } from "src/app/shared/components/upload-files/upload-files.component";
import {
  ActionInterface,
  ActionReturned,
  OptionActionEnum,
  adminCampaignActions,
} from "src/app/shared/data/actions-data";
import { DetailsModalComponent } from "src/app/shared/components/details-modal/details-modal.component";
import { DetailsAdComponent } from "src/app/shared/components/details-modal/details-ad/details-ad.component";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import { VideoAdComponent } from "src/app/shared/components/video-ad/video-ad.component";
import { environment } from "src/environments/environment";
import { RoleEnum } from "src/app/shared/models/user/role.interface";
import { UpdateCampaignStatusComponent } from "./update-campaign-status/update-campaign-status.component";
import {
  advertisingCampaignsFilter,
  FilterInterface,
} from "src/app/shared/data/filter-data";
import { UpdateCampaignComponent } from "./update-campaign/update-campaign.component";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { invokeApiDistroyed } from "src/app/store/apiState.interface";
import {
  invokeDownloadFile,
  resultDownloadFile,
} from "src/app/store/file/file.actions";
import { downloadedFileSelector } from "src/app/store/file/file.selectors";
import { DeleteComponent } from "src/app/shared/components/drob-zone-options/modal/delete/delete.component";
import { ExtensionService } from "src/app/shared/services/extension/extension.service";
import { Router } from "@angular/router";
@Component({
  selector: "app-ads-compaigns",
  templateUrl: "./ads-compaigns.component.html",
})
export class AdsCompaignsComponent extends BaseComponent implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers!: QueryList<NgbdSortableHeader>;
  public years = DateView.MULTI_YEARS;
  columns: IColumn[] = [
    {
      key: "title",
      label: "admin.campaigns.pipeline.campaignName",
      sorter: true,
      width: 20,
    },
    {
      key: "firstName",
      label: "admin.campaigns.pipeline.advertiser",
      sorter: true,
      width: 15,
    },
    {
      key: "objective",
      label: "admin.campaigns.pipeline.objective",
      sorter: true,
      width: 15,
    },
    {
      key: "locations",
      label: "admin.campaigns.pipeline.locations",
      sorter: false,
      width: 40,
    },
    {
      key: "startingDate",
      label: "admin.campaigns.pipeline.startDate",
      sorter: true,
      width: 15,
    },
    {
      key: "endingDate",
      label: "admin.campaigns.pipeline.endDate",
      sorter: true,
      width: 15,
    },
  ];
  date = [null, null];
  pageSize: number = 10;
  pageNumber: number = 1;
  advertisingCompaignFilter$: Observable<FilterListAdvertisingCompaign>;
  advertisingCompaignFilter: FilterListAdvertisingCompaign = {
    ...initialState.filter,
    pager: {
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
    },
  };
  resultFilterAdvertisingCompaign$: Observable<ResultFilterAdvertisingCompaign | null>;
  resultAdvertisingCompainFilter: ResultFilterAdvertisingCompaign;
  formFilter: FormGroup;
  form: FormGroup;
  validate: string;
  submitted: boolean;
  adCampaign: AdvertisingCampaignInterface;
  adCampaign$: Observable<SuccessDto | null>;
  resultUpdateAdCampaign: SuccessDto | null;
  titleCampaign: string;
  waiting: boolean;
  isFiltring: boolean;
  campaignObjective = ObjectiveTypeEnumMapping;
  count: number;
  advertisingCampaign: AdvertisingCampaignInterface[] = [];
  campaignStatus = CampaignStatusEnum;
  loading$: Observable<boolean | null>;

  firstNames: string[] = [];
  lastNames: string[] = [];

  filterAds: FilterInterface = advertisingCampaignsFilter;

  addSynthesisReport$: Observable<SuccessDto | null>;
  resultAddSynthesisReport: SuccessDto | null;
  successMessage: string;
  successMessage$: Observable<SuccessDto | null>;

  downloadFile$: Observable<any>;
  resultDownloadFile: any;
  blob: Blob;
  adTitle: string;
  successUploadJustificatif$ : Observable<SuccessDto | null>
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    public badgeService: BadgeService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private translate: TranslateService,
    private extensionService : ExtensionService,
    private router: Router,
  ) {
    super(store);
    this.advertisingCompaignFilter$ = this.store
      .pipe(select(filterAdvertisingCompaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultFilterAdvertisingCompaign$ = this.store
      .pipe(select(resultFilterAdvertisingCompaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.adCampaign$ = this.store
      .pipe(select(adCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingAdvertisingCampaignTableSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.addSynthesisReport$ = this.store
      .pipe(select(resultAddSynthesisReportSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.successMessage$ = this.store
      .pipe(select(updateAdCampaignDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.downloadFile$ = this.store
      .pipe(select(downloadedFileSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.successUploadJustificatif$ = this.store
      .pipe(select(successUploadFileSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.formFilter = this.fb.group({
      title: [""],
      date: [[null, null]],
    });
    this.form = this.fb.group({
      title: [""],
      objectiveName: [""],
      uploadVideo: [""],
      redirectionLink: [""],
    });
  }
  ngOnInit(): void {
    this.addSynthesisReport$.subscribe((created: SuccessDto | null) => {
      if (created != null) {
        this.toastr.success(
          this.translate.instant("response.success." + created.message),
          this.translate.instant("response.successTitle")
        );
        this.store.dispatch(resultAddSynthesisReport({ successMessage: null }));
        this.store.dispatch(
          invokeAdvertisingCompaignFilter({
            filter: {
              ...this.advertisingCompaignFilter,
              roleName: RoleEnum.ROLE_ADVERTISER,
              pager: {
                pageSize: this.pageSize,
                pageNumber: this.pageNumber,
              },
            },
          })
        );
      }
    });
    this.filterAds.name.map(
      (e) =>
        (e.data = Object.keys(CampaignStatusEnum)
          .filter((value) => isNaN(Number(value)) === true)
          .map(
            (key: string | any) =>
              CampaignStatusEnumMapping[key as keyof typeof CampaignStatusEnum]
          ))
    );
    this.loading$.subscribe((data) => {});
    this.store.dispatch(
      advertisingCompaignResult({ listAdvertisingCompaign: null })
    );
    this.resultFilterAdvertisingCompaign$.subscribe((result) => {
      if (result) {
        this.resultAdvertisingCompainFilter = result;
        this.count = this.resultAdvertisingCompainFilter.count;
        if (this.resultAdvertisingCompainFilter.advertisingCampaigns) {
          this.firstNames = [];
          this.lastNames = [];
          this.advertisingCampaign =
            this.resultAdvertisingCompainFilter.advertisingCampaigns;
          this.advertisingCampaign?.map((e) => {
            this.firstNames = [...this.firstNames, e.firstName];
            this.lastNames = [...this.lastNames, e.lastName];
          });
        }
      } else {
        this.store.dispatch(
          invokeAdvertisingCompaignFilter({
            filter: {
              ...this.advertisingCompaignFilter,
              roleName: RoleEnum.ROLE_ADVERTISER,
              pager: {
                pageSize: this.pageSize,
                pageNumber: this.pageNumber,
              },
            },
          })
        );
      }
    });
    this.advertisingCompaignFilter$.subscribe(
      (result: FilterListAdvertisingCompaign) => {
        if (result) {
          this.advertisingCompaignFilter = result;
          this.pageNumber = result.pager.pageNumber;
        }
      }
    );
    this.adCampaign$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.resultUpdateAdCampaign = result;
      }
    });
    this.successMessage$.subscribe((data) => {
      if (data) {
        this.successMessage = data.message;
        this.store.dispatch(resultSuccessAd({ successMessage: null }));
        if (this.successMessage != "") {
          this.modalService.dismissAll();
        }
      }
    });
    this.successUploadJustificatif$.subscribe((success: SuccessDto | null) => {
      if (success != null) {
        this.store.dispatch(
          resultUploadJustificationFile({ successUpload: null })
        );
        this.toastr.success(
          this.translate.instant("response.success." + success.message),
          this.translate.instant("response.successTitle")
        );
        if (this.successMessage != "") {
          this.modalService.dismissAll();
          this.store.dispatch(
            invokeAdvertisingCompaignFilter({
              filter: {
                ...this.advertisingCompaignFilter,
                roleName: RoleEnum.ROLE_ADVERTISER,
              },
            })
          );
        }
      }
    });

    this.downloadFile$.subscribe((result) => {
      if (result) {
        this.blob = result.body as Blob;
        const pdf = URL.createObjectURL(this.blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = pdf;
        downloadLink.download = `${this.translate.instant(
          "campaignDetails.justificationFile"
        )}-${this.adTitle}.${this.extensionService.getExtension(result.url)}`;
        downloadLink.click();
        URL.revokeObjectURL(pdf);
        this.store.dispatch(resultDownloadFile({ downloadedFile: null }));
      }
    });
  }
  changePage(event: number) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          roleName: RoleEnum.ROLE_ADVERTISER,
          pager: {
            ...this.advertisingCompaignFilter.pager,
            pageNumber: event,
          },
        },
      })
    );
  }
  itemsPerPageChange(pageSize: number) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          roleName: RoleEnum.ROLE_ADVERTISER,
          pager: {
            ...this.advertisingCompaignFilter.pager,
            pageSize: pageSize,
          },
        },
      })
    );
  }
  get changePageSize() {
    return this.advertisingCompaignFilter.pager.pageSize;
  }
  set changePageSize(pageSize: number) {
    this.itemsPerPageChange(pageSize);
  }
  filter() {
    this.store.dispatch(invokeApiDistroyed());
    this.pageNumber = 1;
    this.isFiltring = true;
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          title: this.formFilter.get("title")?.value,
          endingDate: this.formFilter.get("date")?.value[0] ?? null,
          roleName: RoleEnum.ROLE_ADVERTISER,
          startingDate: this.formFilter.get("date")?.value[1] ?? null,
          pager: {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
          },
        },
      })
    );
  }
  removeFilter() {
    this.formFilter.reset({
      title: "",
      date: [null, null],
    });
    this.isFiltring = false;
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...initialState.filter,
          roleName: RoleEnum.ROLE_ADVERTISER,
          pager: {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
          },
        },
      })
    );
  }
  onSort({ column, direction }: SortEvent) {
    this.store.dispatch(invokeApiDistroyed());
    
    this.headers.forEach((header) => {
      if (column != header.sortable) {
        header.direction = "";
      }
    });
    var col: string | null = column;
    var sort = null;
    switch (direction) {
      case "asc":
        sort = true;
        break;
      case "desc":
        sort = false;
        break;
      default:
        sort = true;
        col = null;
    }
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          roleName: RoleEnum.ROLE_ADVERTISER,
          sortName: col,
          isAscending: sort,
        },
      })
    );
  }

  searchTerm() {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          roleName: RoleEnum.ROLE_ADVERTISER,
          title: this.formFilter.get("title")?.value,
        },
      })
    );
  }
  saveOption(options: string[] | null) {
    this.store.dispatch(invokeApiDistroyed());
    let listFounds: any[] = [];
    options?.map((option) => {
      listFounds.push(
        Object.keys(CampaignStatusEnumMapping).find(
          (key) =>
            CampaignStatusEnumMapping[key as CampaignStatusEnum] === option
        )
      );
    });
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          roleName: RoleEnum.ROLE_ADVERTISER,
          status: listFounds,
        },
      })
    );
  }
  saveAction(
    action: ActionReturned,
    ad: AdvertisingCampaignInterface
  ) {
    switch (action.option.type) {
      case OptionActionEnum.ADVERTISER_CAMPAIGNS_STATISTICS: {
        this.router.navigate([
          '/',
          'dashboard',
          'admin',
          'campaigns',
          'pipeline',
          'statistics',
          ad.advertisingCampaignHashedId,
        ]);
        break;
      }
      case OptionActionEnum.CAMPAIGN_DETAILS: {
        const modal = this.modalService.open(DetailsAdComponent, {
          centered: true,
          size: "lg",
        });
        modal.componentInstance.detailsAd = ad;
        break;
      }
      case OptionActionEnum.ADMIN_CAMPAIGN_ADVERTISER_UPDATE: {
        const modal = this.modalService.open(UpdateCampaignComponent, {
          centered: true,
        });
        modal.componentInstance.ad = ad;
        break;
      }
      case OptionActionEnum.ADMIN_CAMPAIGN_UPDATE_STATUS: {
        const modal = this.modalService.open(UpdateCampaignStatusComponent, {
          centered: true,
        });
        modal.componentInstance.detailsAd = ad;
        break;
      }
      case OptionActionEnum.CAMPAIGN_DELETE: {
        const modal = this.modalService.open(DeleteComponent, {
          centered: true,
        });
        modal.componentInstance.name = "pop-up.campaign.title";
        modal.componentInstance.reference = ad.title;
        modal.componentInstance.CampaignHashedId = ad.advertisingCampaignHashedId;
        modal.componentInstance.title = action.option.name;
        modal.componentInstance.type = OptionActionEnum.CAMPAIGN_DELETE;
        break;
      }
      case OptionActionEnum.ADMIN_CAMPAIGN_SHOW_VIDEO: {
        const modal = this.modalService.open(VideoAdComponent, {
          centered: true,
          size: "lg",
        });
        modal.componentInstance.videoLink =
          environment.CDN_RELEAD + ad.videoLink;
        modal.componentInstance.closeTheModal.subscribe((value: boolean) =>
          modal.close()
        );
        break;
      }
      case OptionActionEnum.ADMIN_CAMPAIGN_ADD_SYNTHESIS_REPORT: {
        const modal = this.modalService.open(UploadFilesComponent, {
          centered: true,
          size: "lg",
        });
        modal.componentInstance.campaignHashedId = ad.advertisingCampaignHashedId;
        modal.componentInstance.modalTitle = action.option.name;
        modal.componentInstance.action =
          OptionActionEnum.ADMIN_CAMPAIGN_ADD_SYNTHESIS_REPORT;
        modal.componentInstance.savedFile = ad.synthesisReport
        modal.componentInstance.accept = "application/pdf, .csv, image/*, .pptx"
        modal.componentInstance.closeTheModal.subscribe((value: boolean) =>
          modal.close()
        );
        break;
      }
      case OptionActionEnum.CAMPAIGN_ADD_JUSTIFICATION_FILE: {
        const modal = this.modalService.open(UploadFilesComponent, {
          centered: true,
          size: "lg",
        });
        modal.componentInstance.campaignHashedId = ad.advertisingCampaignHashedId;
        modal.componentInstance.modalTitle = action.option.name;
        modal.componentInstance.action =
          OptionActionEnum.CAMPAIGN_ADD_JUSTIFICATION_FILE;
        modal.componentInstance.savedFile = ad.justificationFile
        modal.componentInstance.accept = "application/pdf, image/*"
        modal.componentInstance.closeTheModal.subscribe((value: boolean) =>
          modal.close()
        );
        break;
      }
      case OptionActionEnum.CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE: {
        this.adTitle = ad.title;
        this.store.dispatch(
          invokeDownloadFile({ filename: ad.justificationFile })
        );
      }
    }
  }
  getOptions(ad: AdvertisingCampaignInterface): ActionInterface[] {
    var actions: ActionInterface[] = adminCampaignActions;
    if (ad.status != CampaignStatusEnum.FINISHED)
      actions = actions.filter(
        (option) =>
          option.type != OptionActionEnum.ADMIN_CAMPAIGN_ADD_SYNTHESIS_REPORT
      );
    if (ad.status == CampaignStatusEnum.FINISHED)
      actions = actions.filter(
        (option) =>
          option.type != OptionActionEnum.ADMIN_CAMPAIGN_ADVERTISER_UPDATE
      );
    if (ad.justificationFile == null)
      actions = actions.filter(
        (option) =>
          option.type !=
          OptionActionEnum.CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE
      );
    return actions;
  }

  onClickFilterDates() {
    this.store.dispatch(
      invokeAdvertisingCompaignFilter({
        filter: {
          ...this.advertisingCompaignFilter,
          startingDate: this.formFilter.get("date")?.value[0],
          endingDate: this.formFilter.get("date")?.value[1],
          roleName: RoleEnum.ROLE_ADVERTISER,
          pager: {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
          },
        },
      })
    );
  }
  invokePublisher(advertiser: AdvertiserDetailsInterface) {
    /* this.store.dispatch(advertiserToEdit({ profileDetails: advertiser })); */
  }
}
