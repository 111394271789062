import { createAction, props } from "@ngrx/store";
import { RoleInterface } from "../../shared/models/role/role.interface";
import { GroupAuthorityInterface } from "src/app/shared/models/group-authority/group-authority.interface";
import {
  SubRoleInterface,
  SubRoleUsersInterface,
} from "src/app/shared/models/sub-role/subRole.interface";
import { subRole } from "src/app/shared/models/sub-role/subRole";

//For the table
export const invokeRoles = createAction("[Invoke Roles] Invoke roles");

export const setRoles = createAction(
  "[Set Roles] Set roles",
  props<{ roles: RoleInterface[] }>()
);

//get subrole to get informations
export const invokeSubRole = createAction(
  "[Invoke Sub-Roles] Invoke sub-roles",
  props<{ roleName: string; name: string }>()
);
export const setSubRole = createAction(
  "[Set Sub-Role] Set sub-role",
  props<{ subRole: SubRoleInterface | null }>()
);

export const invokeSubRolesNames = createAction(
  "[Invoke Sub-Roles Names] Invoke sub-roles names"
);
export const setSubRolesNames = createAction(
  "[Set Sub-Role Names] Set sub-roles Names",
  props<{ subRolesNames: string[] }>()
);

//get all authorities
export const invokeGroupAuthorities = createAction(
  "[Invoke Group Authorities] Invoke group authorities"
);
export const setGroupAuthorities = createAction(
  "[Set Group Authorities] Set group authorities",
  props<{ groupAuthorities: GroupAuthorityInterface[] }>()
);

//Create & Update roles
export const invokeCreateSubRole = createAction(
  "[Invoke Create Sub-Role] Invoke Create sub-role",
  props<{ createSubRole: SubRoleInterface }>()
);
export const invokeUpdateSubRole = createAction(
  "[Invoke Update Sub-Role] Invoke Update sub-role",
  props<{ roleName: string; name: string; subRole: SubRoleInterface }>()
);

export const setCreateUpdateSubRoleSuccess = createAction(
  "[Set Create Update Sub-Role Success] Set create update sub-role success",
  props<{ message: string | null }>()
);
export const invokeAllSubRoles = createAction(
  "[ Invoke All Sub Roles ] Invoke All Sub Role for admin"
);
export const resultAllSubRoles = createAction(
  "[ Result All Sub Roles ] Result All Sub Role for admin",
  props<{ resultSubRoles: subRole[] | null }>()
);

export const invokeDeleteSubRole = createAction(
  "[ Delete Sub Role ] Delete Sub Role",
  props<{ roleName: string; subRoleName: string }>()
);

export const resultDeleteSubRole = createAction(
  "[ Result Delete Sub Role ] Result Delete Sub Role",
  props<{ message: string | null }>()
);
