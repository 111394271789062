import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { BugReportInterface } from "src/app/shared/models/bugReport/bug-report.interface";
import { BadgeService } from "src/app/shared/services/badge.service";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeBugReportByRef,
  resultBugReportByRef,
} from "src/app/store/bug-report/bug-report.action";
import { resultBugReportByRefSelector } from "src/app/store/bug-report/bug-report.selectors";

@Component({
  selector: "app-details-discution-support",
  templateUrl: "./details-discution-support.component.html",
  styleUrls: ["./details-discution-support.component.scss"],
})
export class DetailsDiscutionSupportComponent
  extends BaseComponent
  implements OnInit
{
  bugReportByRef$: Observable<BugReportInterface | null>;
  bugReport: BugReportInterface;
  ref: string | null;
  attachmentNumber: string;
  constructor(
    private store: Store<AppStateInterface>,
    private activatedRoute: ActivatedRoute,
    public badgeService: BadgeService
  ) {
    super(store);
    this.bugReportByRef$ = this.store
      .pipe(select(resultBugReportByRefSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.ref = this.activatedRoute.snapshot.queryParamMap.get("ref");
  }

  ngOnInit(): void {
    if (this.ref) this.attachmentNumber = this.ref.split("-")[1];
    this.store.dispatch(resultBugReportByRef({ support: null }));
    this.bugReportByRef$.subscribe((data) => {
      if (data) {
        this.bugReport = data;
      } else {
        this.store.dispatch(invokeBugReportByRef({ ref: this.ref }));
      }
    });
  }
}
