<div class="modal-header">
  <h4 class="modal-title" id="exampleModalLabel">
    {{ "actions.seeVideo" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal()"
  ></button>
</div>
<div class="modal-body">
  <div class="d-flex justify-content-center align-items-center">
    <video
      #myVideo
      controls
      loop
      style="max-width: 500px; border-radius: 20px ; max-height: 500px;"
      autoplay
      [src]="videoLink"
    ></video>
  </div>
  <!--     <iframe class="items-center" [src]="showVideo()"></iframe> -->
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-primary"
    data-bs-dismiss="modal"
    (click)="closeModal()"
  >
    {{ "buttons.close" | translate }}
  </button>
</div>
