<div class="row">
  <div class="col-12 col-sm-12-col-md-12 col-lg-6 col-xl-8 col-xxl-8">
    <div class="card">
      <div class="card-body">
        <div class="box">
          <div class="media pad-img">
            <img
              src="assets/identity/logo-Relead-noir-blanc 1.png"
              alt="vector"
            />
            <h2 class="ps-2 text-white d-flex" style="margin: auto 0">
              {{ "newCampaign.step3.title" | translate }}
              {{ adDetails.estimateNumber.replace("DV-", "") }}
            </h2>
          </div>
        </div>
        <div>
          <div class="pt-4">
            <h6 class="f-w-600">
              {{ "campaignDetails.campaignName" | translate }}
            </h6>
            <label>{{ adDetails.title }}</label>
          </div>

          <div class="pt-3">
            <h6 class="f-w-600">
              {{ "campaignDetails.objective" | translate }}
            </h6>
            <label class="col-form-label label">{{
              "enumMapping.objectives." + adDetails.objectiveName | translate
            }}</label>
          </div>
          <div class="pt-3">
            <h6 class="f-w-600">
              {{ "campaignDetails.locations" | translate }}
            </h6>
            <label class="col-form-label label">
              <span *ngFor="let lp of adDetails.locations">
                {{ lp }},&ThickSpace;
              </span></label
            >
          </div>
          <div class="pt-3">
            <h6 class="f-w-600">
              {{ "campaignDetails.startDate" | translate }} -
              {{ "campaignDetails.endDate" | translate }}
            </h6>
            <label class="col-form-label label">
              {{ adDetails.startDate | localizedDate }} -
              {{ adDetails.endDate | localizedDate }}
            </label>
          </div>

          <div class="pt-3">
            <h6 class="f-w-600">
              {{ "newCampaign.step3.conditionsTitle" | translate }}
            </h6>
            <label class="text-blackcol-form-label label">
              {{ "newCampaign.step3.conditionsPart0" | translate }} <br />
              {{ "newCampaign.step3.conditionsPart1" | translate }}
              <b class="color-primary"
                >{{ impressions }}
                {{ "newCampaign.step3.impressions" | translate }}
              </b>
              {{ "newCampaign.step3.conditionsPart2" | translate }} <br />
              {{ "newCampaign.step3.dueDate" | translate }}
              <b>{{ dueDate | localizedDate }}</b>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="col-12 col-sm-12-col-md-12 col-lg-6 col-xl-4 col-xxl-4 justify-content-center align-self-center"
  >
    <div class="card">
      <div class="card-header p-b-0">
        <h4 class="m-b-0">{{ "newCampaign.step3.selection" | translate }}</h4>
        <hr />
      </div>
      <div class="card-body p-t-10">
        <div class="grid-column">
          <label class="label f-w-600"
            >{{ "newCampaign.step3.price" | translate }} HT</label
          >
          <p>{{ priceHT.toFixed(3) + " TND" }}</p>
          <ng-container *ngIf="discount != 0">
            <label class="label f-w-600"
              >{{ "newCampaign.step3.promo" | translate }}
            </label>
            <p>
              {{ "- " + discount.toFixed(3) + " TND" }}
            </p>
          </ng-container>
          <label class="label f-w-600">19 % TVA</label>
          <p>{{ tva.toFixed(3) + " TND" }}</p>
          <label class="label f-w-600">{{
            "newCampaign.step3.taxStamp" | translate
          }}</label>
          <p>1.000 TND</p>
        </div>
        <hr />
        <div class="grid-column">
          <h5 class="pb-2 f-w-600">
            {{ "newCampaign.step3.total" | translate }}
          </h5>
          <h5>{{ total.toFixed(3) + " TND" }}</h5>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-12-col-md-12 col-lg-6 col-xl-8 col-xxl-8">
    <ngb-accordion
      #acc="ngbAccordion"
      [closeOthers]="true"
      style="position: sticky"
    >
      <ngb-panel id="static-1">
        <ng-template ngbPanelTitle>
          <div class="d-flex justify-content-start">
            <img src="assets/identity/tag.svg" alt="tag" width="14px" />
            <h5 class="m-b-0 m-l-5 txt-primary">
              {{ "newCampaign.step3.promotion.accordionTitle" | translate }}
            </h5>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <form class="pt-2" [formGroup]="promotionForm">
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                formControlName="promotion"
                [readOnly]="verifySucceed != null"
                [placeholder]="
                  'newCampaign.step3.promotion.placeholder' | translate
                "
                aria-label="Recipient's username"
              />
              <span
                class="input-group-text bg-primary span-hover label"
                (click)="applyCode()"
                >{{ "newCampaign.step3.promotion.apply" | translate }}</span
              >
            </div>
            <div
              class="p-t-10"
              *ngIf="
                promotionApplied != null && promotionApplied.discountValue != 0
              "
            >
              <div class="media-space-between">
                <label class="col-form-label p-b-0">
                  {{ "admin.settings.promotion.saving" | translate }}
                  <b class="txt-primary">{{
                    promotionApplied.discountValue +
                      " " +
                      getUnit(promotionApplied.discountType)
                  }}</b>
                </label>
              </div>
              <div class="media-space-between">
                <label class="col-form-label label color-darkGray p-b-0">
                  {{ "admin.settings.promotion.expiredOn" | translate }}
                  {{ promotionApplied.expirationDate | localizedDate }}
                </label>
              </div>
            </div>
          </form>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>
<br />
<br />
<br />
