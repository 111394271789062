import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { Constants } from "src/app/shared/data/constants";
import { IberisActivities } from "src/app/shared/models/iberis/activities";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeUploadFile } from "src/app/store/file/file.actions";
import { setCheckUserExistsByCompanyName } from "src/app/store/user/user.actions";
import { checkUserExistByCompanyNameSelector } from "src/app/store/user/user.selectors";

@Component({
  selector: "app-company-information",
  templateUrl: "./company-information.component.html",
})
export class CompanyInformationComponent
  extends BaseComponent
  implements OnInit
{
  @Input("form") form: FormGroup;
  @Input("option") option: string | null;
  @Input() create: boolean;
  @Input() isEmailCheck : boolean = false
  companyActivity: IberisActivities[];

  franchise: boolean;
  showError : boolean
  isUploaded: boolean;
  filename: string | null;
  files: File[] = [];
  uploadFile$: Observable<string | null>;
  isShown: boolean
  isExist$: Observable<boolean | null>;
  constructor(
    private store: Store<AppStateInterface>,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
    super(store);
    this.isExist$ = this.store
    .pipe(select(checkUserExistByCompanyNameSelector))
    .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.isExist$.subscribe((data) => {
      
      if (data == true) {
        this.toastr.error(
          this.translate.instant("response.error.companyName-exist"),
          this.translate.instant("response.errorTitle")
        );
        this.store.dispatch(setCheckUserExistsByCompanyName({isExistByCompanyName : null}))
      }
    });
    if (this.translate.currentLang == "fr")
      this.companyActivity = Constants.IBERIS_ACTIVITIES_FR;
    else this.companyActivity = Constants.IBERIS_ACTIVITIES_EN;

    this.translate.onLangChange.subscribe(
      (langChangeEvent: LangChangeEvent) => {
        if (langChangeEvent.lang == "fr")
          this.companyActivity = Constants.IBERIS_ACTIVITIES_FR;
        else this.companyActivity = Constants.IBERIS_ACTIVITIES_EN;
      }
    );

    if (this.option?.endsWith("franchise")) {
      this.franchise = false;
    }
  }
  onFileSelected(event: any) {
    if (event.target.files[0].size > 4194304) this.showError = true;
    this.files = event.target.files;
    this.isShown = true;
    const formData = new FormData();
    formData.append("files", this.files[0], this.files[0].name);
    this.store.dispatch(invokeUploadFile({ files: formData }));
  }
}
