import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../data/constants";
import {
  CreateUpdateTagInterface,
  TagInterface,
} from "../models/tag/tag.interface";
import { SuccessDto } from "../models/success-dto";

@Injectable({
  providedIn: "root",
})
export class TagService {
  constructor(private http: HttpClient) {}

  getAllTagNames(): Observable<string[] | null> {
    return this.http.get<string[] | null>(
      `${environment.APP_PORT + Constants.TAG_ENDPOINT}/allTagsNames`
    );
  }

  getAllTags(): Observable<TagInterface[]> {
    return this.http.get<TagInterface[]>(
      `${environment.APP_PORT + Constants.TAG_ENDPOINT}/allTags`
    );
  }
  createTag(tag: CreateUpdateTagInterface): Observable<SuccessDto> {
    return this.http.post<SuccessDto>(
      `${environment.APP_PORT + Constants.TAG_ENDPOINT}/create`,
      tag
    );
  }
  getTagsByRegion(region: string): Observable<string[] | null> {
    return this.http.get<string[]>(
      `${environment.APP_PORT + Constants.TAG_ENDPOINT}/tagByRegion/${region}`
    );
  }

  updateTag(
    tag: CreateUpdateTagInterface,
    oldName: string
  ): Observable<SuccessDto> {
    return this.http.patch<SuccessDto>(
      `${environment.APP_PORT + Constants.TAG_ENDPOINT}/` + oldName,
      tag
    );
  }
  deleteTag(tagName: string): Observable<SuccessDto> {
    return this.http.delete<SuccessDto>(
      `${environment.APP_PORT + Constants.TAG_ENDPOINT}/` + tagName
    );
  }
}
