import { createAction, props } from "@ngrx/store";
import { CreateUpdateZoneInterface, ZoneInterface } from "src/app/shared/models/zone/zone";

export const invokeAllZones = createAction(
    "[ Invoke Get All Zones ] invoke get all zones"
);
export const setAllZones = createAction(
    "[ Get All Zones ] get all zones",
    props<{ zones: ZoneInterface[] | null}>()
)

//Create
export const invokeCreateZone = createAction(
    "[ Invoke Create Zone ] invoke create zone",
    props<{ zone: CreateUpdateZoneInterface}>()
);
export const createUpdateZoneSuccess = createAction(
    "[ Create Update Zone Success] create update zone success",
    props<{ message: string | null}>()
);

//Update
export const invokeUpdateZone = createAction(
    "[ Invoke Update Zone ] invoke update zone",
    props<{ zone: CreateUpdateZoneInterface, oldName: string}>()
);

//Delete zone
export const invokeDeleteZone = createAction(
    "[ Invoke Delete Zones ] invoke delete Zone",
    props<{ zoneName: string}>()
);
export const deleteZoneSuccess = createAction(
    "[ Delete Zone Success ] delete zone success",
    props<{ message: string | null}>()
)


export const invokeAllZoneNames = createAction(
    "[ Invoke Get All Zone Names ] invoke get all zone names"
);
export const setAllZoneNames = createAction(
    "[ Get All Zone Names ] get all zone names",
    props<{ zoneNames: string[] | null}>()
)

export const saveSelectedZone = createAction(
    "[Save selected Zones] Save selected zones",
    props<{selectedZones : string[] | null}>()
)