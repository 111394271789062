import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const usersHistoryState = (state: AppStateInterface) =>
  state.usersHistoryState;
export const usersHistoryResultSelector = createSelector(
  usersHistoryState,
  (state) => state.history
);

export const usersHistoryFilterSelector = createSelector(
  usersHistoryState,
  (state) => state.filter
);

export const usersHistoryLoadingSelector = createSelector(
  usersHistoryState,
  (state) => state.loading
);
