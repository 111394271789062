import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { initialState } from "src/app/store/advertiser/advertiser.reducers";
import {
  filterAdvertiserSelector,
  resultFilterAdvertiserSelector,
} from "src/app/store/advertiser/advertiser.selectors";
import { AppStateInterface } from "src/app/store/appState.interface";
import { billingSelector } from "src/app/store/billing/billing..selectors";
import {
  invokeBillingInfo,
  invokeBillingInfoSuccess,
} from "src/app/store/billing/billing.actions";
import { saveUpdateFields } from "src/app/store/settings/settings.actions";
import { invokeSubRoleByUser } from "src/app/store/user/user.actions";
import { resultSubRoleSelector } from "src/app/store/user/user.selectors";
import { environment } from "src/environments/environment";
import {
  ActionInterface,
  updateAdvertiserActions,
} from "../../data/actions-data";
import { Constants } from "../../data/constants";
import { FilterListAdvertiser } from "../../models/advertiser/filter-list-advertiser.interface";
import { AdvertiserDetailsInterface } from "../../models/advertiser/profile.interface";
import { Publisher } from "../../models/advertiser/publisher.interface";
import { ResultFilterAdvertiser } from "../../models/advertiser/result-filter-advertiser.interface";
import { AdvertisingCampaignInterface } from "../../models/advertising-campaigns/advertising-campaign.interface";
import { BillingInterface } from "../../models/billing/billing.interface";
import { IberisActivities } from "../../models/iberis/activities";
import { OptionActionInterface } from "../../models/option-action.interface";
import { SubRoleInterface } from "../../models/sub-role/subRole.interface";
import { BadgeService } from "../../services/badge.service";

@Component({
  selector: "app-details-modal",
  templateUrl: "./details-modal.component.html",
})
export class DetailsModalComponent extends BaseComponent implements OnInit {
  @Output() closeTheModal: EventEmitter<boolean> = new EventEmitter();
  @Input() public details: AdvertiserDetailsInterface | null;
  @Input() public type: string;
  @Input() advertiser: Publisher;
  @Input() detailsAd: AdvertisingCampaignInterface;
  billingInfo$: Observable<BillingInterface | null>;
  billingInfo: BillingInterface | null;
  advertiserFilter$: Observable<FilterListAdvertiser>;
  advertiserFilter: FilterListAdvertiser = {
    ...initialState.filter,
  };
  resultFilterAdvertiser$: Observable<ResultFilterAdvertiser | null>;
  resultFilterAdvertiser: ResultFilterAdvertiser;
  isAdvertiser: boolean | null;
  result: any[] = [];
  subRoleByUser$: Observable<SubRoleInterface | null>;
  resultSubRole: string | null;

  @Input() title: string;
  fields: OptionActionInterface = {
    name: [],
    icon: [],
    optionSelected: null,
    title: "",
  };
  @Input() icon: boolean = false;

  /** */
  updateAdvertiserFields: ActionInterface[] = updateAdvertiserActions;
  companyActivities: IberisActivities[];
  constructor(
    private store: Store<AppStateInterface>,
    public badgeService: BadgeService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    protected translate: TranslateService
  ) {
    super(store);
    this.billingInfo$ = this.store
      .pipe(select(billingSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.advertiserFilter$ = this.store
      .pipe(select(filterAdvertiserSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultFilterAdvertiser$ = this.store
      .pipe(select(resultFilterAdvertiserSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.subRoleByUser$ = this.store
      .pipe(select(resultSubRoleSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.store.dispatch(invokeBillingInfoSuccess({ billing: null }));
    if (this.translate.currentLang == "fr")
      this.companyActivities = Constants.IBERIS_ACTIVITIES_FR;
    else this.companyActivities = Constants.IBERIS_ACTIVITIES_EN;

    this.translate.onLangChange.subscribe(
      (langChangeEvent: LangChangeEvent) => {
        if (langChangeEvent.lang == "fr")
          this.companyActivities = Constants.IBERIS_ACTIVITIES_FR;
        else this.companyActivities = Constants.IBERIS_ACTIVITIES_EN;
      }
    );
    if (this.details != null) {
      this.details = {
        ...this.details,
        companyActivity: this.companyActivities.find(
          (value) => this.details?.companyActivity == value.hashed_id
        )?.title,
      };
    }
    this.store.dispatch(
      saveUpdateFields({ options: this.updateAdvertiserFields })
    );
    this.billingInfo$.subscribe((result) => {
      if (result) {
        this.billingInfo = result;
      } else {
        if (this.details?.email)
          this.store.dispatch(invokeBillingInfo({ email: this.details.email }));
      }
    });
    if (this.details?.email) {
      this.store.dispatch(invokeSubRoleByUser({ email: this.details.email }));
    }
    this.subRoleByUser$.subscribe((result: SubRoleInterface | null) => {
      if (result) {
        this.resultSubRole = result.name;
      }
    });
  }
  closeModal() {
    this.modalService.dismissAll();
  }
  showlogo() {
    if (this.details?.logo) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        environment.CDN_RELEAD + this.details.logo
      );
    } else return null;
  }
}
