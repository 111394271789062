import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const factureState = (state: AppStateInterface) => state.factureState;
export const resultAllFacturesFromIberisSelector = createSelector(
  factureState,
  (state) => state.resultFacturesIberis
);
export const resultNextInvoiceNumberSelector = createSelector(
  factureState,
  (state) => state.nextInvoiceNumber
);
export const resultDownloadInvoiceSelector = createSelector(
  factureState,
  (state) => state.invoice
);
export const loadingInvoiceSelector = createSelector(
  factureState,
  (state) => state.loading
);
export const resultAllFacturesByClientFromIberisSelector = createSelector(
  factureState,
  (state) => state.resultFacturesIberis
);
export const resultMarkInvoiceSelector = createSelector(
  factureState,
  (state) => state.resultMarkInvoice
);