<form [formGroup]="form">
  <!-- ... nested form controls ... -->
  <div class="form-group">
    <h5 style="text-align: center">Informations sur l'entreprise</h5>
    <label>Nom de l’entreprise*</label>
    <div class="input-group">
      <span class="input-group-text">
        <img src="assets/identity/brandName.svg" alt="icone brand name" />
      </span>
      <input
        formControlName="companyName"
        class="form-control"
        type="text"
        placeholder="Saisir le nom de votre entreprise."
        required="required"
      />
    </div>
  </div>
  <div class="form-group">
    <label>Addresse*</label>
    <div class="input-group">
      <span class="input-group-text">
        <img src="assets/identity/address.png" alt="icone address" />
      </span>
      <input
        formControlName="companyAddress"
        class="form-control"
        type="text"
        placeholder="Saisir l’adresse principale de votre entreprise."
        required="required"
      />
    </div>
  </div>
  <div class="form-group">
    <label>Activité*</label>
    <div class="input-group">
      <span class="input-group-text">
        <img src="assets/identity/activity.png" alt="icone activity name" />
      </span>
      <select
        class="form-select form-control digits"
        formControlName="companyActivity"
      >
        <option selected="" disabled="" [value]="null">
          Choissisez l’activité de votre entreprise
        </option>
        <option
          class="form-select"
          *ngFor="let companyActivity of CompanyActivity"
          [value]="companyActivity.hashed_id"
        >
          {{ companyActivity.title }}
        </option>
      </select>
    </div>
  </div>
  <div class="form-group">
    <label>Rôle dans l'entreprise*</label>
    <div class="input-group">
      <span class="input-group-text">
        <i class="icofont icofont-user-alt-3"></i>
      </span>
      <input
        formControlName="companyRole"
        class="form-control"
        type="text"
        placeholder="Saisir votre rôle dans entreprise."
        required="required"
      />
    </div>
  </div>
  <div class="form-group">
    <label>Siteweb*</label>
    <div class="input-group">
      <span class="input-group-text">
        <a
          placement="bottom"
          triggers="manual"
        >
          <img src="assets/identity/website.png" alt="icone website"
        /></a>
      </span>
      <input
        formControlName="website"
        class="form-control"
        type="text"
        placeholder="Entrez l'url de votre site web."
        [ngClass]="{
          'is-invalid':
            !form.get('website')?.valid && form.get('website')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="!form.get('website')?.valid && form.get('website')?.touched"
      >
        <ul style="padding-left: 10px">
          <li>Il faut que le site web commence par https://</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="checkbox ms-1">
      <input
        #checked
        id="checkbox1"
        type="checkbox"
        formControlName="acceptTerms"
      />
      <label for="checkbox1"
        >En cliquant sur le bouton ci-dessus, vous acceptez nos
        <a
          class="color-primary underline-link"
          target="_blank"
          [href]="termsOfUse"
          >conditions d’utilisation</a
        >
        et notre<a
          class="color-primary underline-link"
          target="_blank"
          href="https://relead.tn/politique-de-confidentialite/"
        >
          politique de confidentialité.</a
        >
      </label>
    </div>
  </div>
</form>
