import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { CalendarView } from "angular-calendar";
import { Observable, Subject, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { CampaignStatusEnum } from "src/app/shared/enum/campaign-status";
import { DateFilter } from "src/app/shared/models/date-filte.interface";
import { SummaryStatics } from "src/app/shared/models/statistics/summary-statics";
import { invokeMainDashboard } from "src/app/store/admin/admin.actions";
import {
  adminLoadingSelector,
  mainDashboardSelector,
} from "src/app/store/admin/admin.selectors";
import { resultAllAdsByStatusAndPublisherSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import { invokeAllAdsByStatusAndPublisher } from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { AppStateInterface } from "src/app/store/appState.interface";
import { loadingLocationPartnersSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { invokeAllSummaryChartInDateBetween, resultSummaryStatics } from "src/app/store/statistics/statistics.actions";
import { summaryStatisticsSelector } from "src/app/store/statistics/statistics.selectors";
import { EventColor } from "calendar-utils";
import { RoleEnum } from "src/app/shared/models/user/role.interface";
import { TranslateService } from "@ngx-translate/core";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
import {
  ExportData,
  exportDataType,
  SummaryChartAdmin,
} from "src/app/shared/data/exportData";
import { AdminMainDashboard } from "src/app/shared/models/admin/admin-main-dashboard.interface";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { FilterCalendar } from "src/app/shared/models/advertising-campaigns/filter-calendar.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { ToastrService } from "ngx-toastr";
import { sendMailSelector } from "src/app/store/mailing/mailing.selectors";
import { resultSindingMail } from "src/app/store/mailing/mailing.actions";
const colors: Record<string, EventColor> = {
  red: {
    primary: "#ad2121",
    secondary: "#FAE3E3",
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF",
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#FDF1BA",
  },
};
@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent extends BaseComponent implements OnInit {
  @Output() resultChartObs = new EventEmitter<SummaryStatics>();
  basicTable: any;
  consumerCard = {
    value: 227742,
    title: "dashboard.admin.cards.consumers",
    unit: "info.lastMonth",
    icon: "assets/identity/icon-consumer.svg",
    class: "photo",
    changes: 0,
    growth: 0,
    growthUnit: "info.today",
    hasYesterdayGrowth: true,
    growthYesterday: 0,
    growthYesterdayUnit: "info.yesterday",
  };
  advertiserCard = {
    value: 57,
    title: "dashboard.admin.cards.advertisers",
    unit: "info.lastMonth",
    icon: "assets/identity/icon-advertisers.svg",
    class: "card info-card",
    changes: 0,
    growth: 0,
    growthUnit: "info.thisMonth",
    hasYesterdayGrowth: false,
  };
  locationCard = {
    value: 53,
    title: "dashboard.admin.cards.lps",
    unit: "info.lastMonth",
    icon: "assets/identity/icon-lp.svg",
    class: "card info-card",
    changes: 0,
    growth: 0,
    growthUnit: "info.thisMonth",
    hasYesterdayGrowth: false,
  };
  adsCard = {
    value: 94,
    title: "dashboard.admin.cards.ads",
    unit: "info.lastMonth",
    icon: "assets/identity/ad.svg",
    class: "card info-card",
    changes: 0,
    growth: 0,
    growthUnit: "info.thisMonth",
    hasYesterdayGrowth: false,
  };
  zonesCard = {
    value: 10,
    title: "dashboard.admin.cards.zones",
    unit: "info.thisMonth",
    icon: "assets/identity/zone.svg",
    class: "card info-card",
    changes: 0,
    growth: 0,
    growthUnit: "info.thisMonth",
    hasYesterdayGrowth: false,
    growthYesterday: 0,
    growthYesterdayUnit: "info.thisMonth",
  };
  regionsCard = {
    value: 2,
    title: "dashboard.admin.cards.regions",
    unit: "info.thisMonth",
    icon: "assets/identity/region.svg",
    class: "card info-card",
    changes: 0,
    growth: 0,
    growthUnit: "info.thisMonth",
    hasYesterdayGrowth: false,
    growthYesterday: 0,
    growthYesterdayUnit: "info.thisMonth",
  };
  dailyChart = {
    title: "dashboard.admin.summmaryChart",
    style: null,
    yaxisTitle: "Number Of Views",
    xaxisTitle: "Days",
    seriesTitles: ["Views", "Unique views", "Clicks", "Completed"],
  };
  locationPartnerCheckIsLoading = true;
  mainDashboard$: Observable<AdminMainDashboard | null>;
  mainDashboard: AdminMainDashboard | null;
  isLoading$: Observable<boolean | null>;
  isLoading: boolean | null
  form: FormGroup;
  startDate: Date;
  endDate: Date;
  receivedChart: any;
  receivedLocation: ExportData[];
  //receivedTableLocationPartnerPreview: LocationPartnerCheck[];
  summaryChartResult$: Observable<SummaryStatics | null>;
  resultSummaryChart: SummaryStatics | null;
  dates: Date[];
  views: number = 1227953;
  loadingTable$: Observable<boolean | null>;
  loadingTable : boolean| null = false
  resultCampaignsByStatusAndPublisherRole$: Observable<
    AdvertisingCampaignInterface[] | null
  >;
  adsByStatusAndPublisherRole: AdvertisingCampaignInterface[] = [];

  hoveredDate: NgbDate | null = null;

  fromDate: any;
  toDate: any;

  adsDate: Date[] = [];

  start: NgbDateStruct;
  end: NgbDateStruct;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();
  refresh = new Subject<void>();

  events: any = [];

  activeDayIsOpen: boolean = true;
  color: any;
  details: AdvertisingCampaignInterface;
  filterCalendar: FilterCalendar = {
    ...initialState.filterCalendar,
  };
  splineChart: ExportData[] = SummaryChartAdmin;
  resultSendStatics$: Observable<SuccessDto | null>;
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    private translate: TranslateService,
    private toastr: ToastrService
  ) {
    super(store);

    this.mainDashboard$ = this.store
      .pipe(select(mainDashboardSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.summaryChartResult$ = this.store
      .pipe(select(summaryStatisticsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.isLoading$ = this.store
      .pipe(select(adminLoadingSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loadingTable$ = this.store
      .pipe(select(loadingLocationPartnersSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultCampaignsByStatusAndPublisherRole$ = this.store
      .pipe(select(resultAllAdsByStatusAndPublisherSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultSendStatics$ = this.store
      .pipe(select(sendMailSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.form = this.fb.group({
      date: [[null, null]],
    });
  }
  ngOnInit(): void {
    this.store.dispatch(resultSindingMail({ successMessage: null }));
    this.store.dispatch(resultSummaryStatics({summaryStatistic : null}))
    this.resultSendStatics$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.store.dispatch(resultSindingMail({ successMessage: null }));
        this.toastr.success(
          this.translate.instant("response.success." + result.message),
          this.translate.instant("response.successTitle")
        );
      }
    });
    //this.store.dispatch(invokeMainDashboard());
    this.mainDashboard$.subscribe((result: AdminMainDashboard | null) => {
      if (result != null) {
        this.consumerCard.value = result?.consumers;
        this.adsCard.value = result?.ads;
        this.advertiserCard.value = result?.advertisers;
        this.locationCard.value = result?.locations;
        this.locationCard.changes = result?.locationsChanges;
        this.advertiserCard.changes = result?.advertisersChanges;
        this.adsCard.changes = result?.adsChanges;
        this.consumerCard.changes = result?.consumersChanges;
        this.advertiserCard.growth = result?.advertisersGrowth;
        this.adsCard.growth = result?.adsGrowth;
        this.locationCard.growth = result?.locationPartnersGrowth;
        this.consumerCard.growth = result?.consumersToday;
        this.consumerCard.growthYesterday = result?.consumersYesterday;
        this.regionsCard.value = result.regions;
        this.zonesCard.value = result.zones;
        this.views = result.views;
      }
    });
    this.isLoading$.subscribe((data) => {
      this.isLoading = data;
    });
    this.loadingTable$.subscribe((data)=>{this.loadingTable = data})
    this.summaryChartResult$.subscribe((result: SummaryStatics | null) => {
      if (result != null) {
        this.resultChartObs.emit(result);
        this.resultSummaryChart = result;
        const csvRows = [];
        let headers;
        headers = Object.keys(this.resultSummaryChart);
        for (let i = 0; i < this.resultSummaryChart.dates.length; i++) {
          const cols = [
            this.resultSummaryChart.dates[i],
            this.resultSummaryChart.views[i],
            this.resultSummaryChart.redirection[i],
            this.resultSummaryChart.peopleFinishAds[i],
            this.resultSummaryChart.uniqueViews[i],
          ];
          csvRows.push(cols.join(","));
        }
        for (let i = 0; i < this.splineChart.length; i++) {
          if (this.splineChart[i].type == exportDataType.SUMMARY_CHART_ADMIN)
            this.splineChart[i].data = [headers, ...csvRows].join("\n");
        }
      } else {
        this.getChartInDate();
      }
    });
    this.store.dispatch(
      invokeAllAdsByStatusAndPublisher({
        filterCalendar: {
          ...this.filterCalendar,
          roleName: RoleEnum.ROLE_ADVERTISER,
          status: [CampaignStatusEnum.ONGOING, CampaignStatusEnum.WAITING],
        },
      })
    );
    this.resultCampaignsByStatusAndPublisherRole$.subscribe(
      (result: AdvertisingCampaignInterface[] | null) => {
        if (result) {
          this.adsByStatusAndPublisherRole = result;
          for (let i = 0; i < this.adsByStatusAndPublisherRole.length; i++) {
            this.events.push({
              start: this.adsByStatusAndPublisherRole[i].startingDate,
              end: this.adsByStatusAndPublisherRole[i].endingDate,
              title: this.adsByStatusAndPublisherRole[i].title,
              status: this.adsByStatusAndPublisherRole[i].status,
              ad: this.adsByStatusAndPublisherRole[i],
              color: { ...colors[i] },
              allDay: true,
            });
          }
        }
      }
    );
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  getChartInDate() {
    this.startDate = this.form.get("date")?.value[0];
    this.endDate = this.form.get("date")?.value[1];
    const dateFilter: DateFilter = {
      startDate: this.startDate,
      endDate: this.endDate,
    };
    this.dates = [this.startDate, this.endDate];
    this.store.dispatch(
      invokeAllSummaryChartInDateBetween({ dateFilter: dateFilter })
    );
  }
  chart(chart: any) {
    this.receivedChart = chart;
  }
  locationPartnerPreview(locationPreview: ExportData[]) {
    this.receivedLocation = locationPreview;
  }
  tableLocationPartnerPreview(tableLocationPartnerPreview: any) {
    // this.receivedTableLocationPartnerPreview = tableLocationPartnerPreview;
  }
  summaryStatics(summaryStatics: any) {
    this.resultSummaryChart = summaryStatics;
  }
  ads(date: NgbDate) {}
  isDateDisabled(date: NgbDate): boolean {
    // You can add your logic here to disable specific dates
    return true; // By default, no dates are disabled
  }

  isRange(date: NgbDate) {
    const d = new Date(date.year, date.month - 1, date.day).toISOString();
    let result: any;
    for (let i = 0; i < this.events.length; i++) {
      if (
        d >= new Date(this.events[i].start)?.toISOString() &&
        d <= new Date(this.events[i].end)?.toISOString()
      ) {
        result = this.events[i];
      }
    }
    return result;
  }
  title(date: NgbDateStruct) {
    const d = new Date(date.year, date.month - 1, date.day).toISOString();
    let result: any[] = [];
    for (let i = 0; i < this.events.length; i++) {
      if (
        d === new Date(this.events[i].start)?.toISOString() ||
        d === new Date(this.events[i].end)?.toISOString() ||
        (d > new Date(this.events[i].start)?.toISOString() &&
          d < new Date(this.events[i].end)?.toISOString())
      ) {
        result.push(this.events[i].title + " ");
      }
    }
    return result.toString();
  }
}
