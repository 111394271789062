import { Component, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { getAdCompaignByHashedIdSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import { AppStateInterface } from "src/app/store/appState.interface";

@Component({
  selector: "app-paymee-cancel",
  templateUrl: "./payment-cancel.component.html"
})
export class PaymentCancelComponent extends BaseComponent implements OnInit {
  adDetails$: Observable<AdvertisingCampaignInterface | null>;
  adDetails: AdvertisingCampaignInterface;
  constructor(private store : Store<AppStateInterface>) {
    super(store)
    this.adDetails$ = this.store
    .pipe(select(getAdCompaignByHashedIdSelector))
    .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.adDetails$.subscribe((data) => {
      if (data) this.adDetails = data;
    });
  }
}
