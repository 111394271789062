import { createReducer, on } from "@ngrx/store";
import {
  invokeAllFacturesForClientFromIberis,
  invokeAllFacturesFromIberis,
  resultDownloadInvoice,
  resultFacturesForClientFromIberis,
  resultFacturesFromIberis,
  resultMarkInvoice,
  resultNextInvoiceNumber,
} from "./facture.actions";
import { FactureStateInterface } from "./factureState.interface";

export const initialState: Readonly<FactureStateInterface> = {
  resultFacturesIberis: null,
  nextInvoiceNumber: null,
  loading: false,
  invoice: null,
  resultMarkInvoice : null
};
export const FactureReducer = createReducer(
  initialState,
  on(invokeAllFacturesFromIberis , (state , {params ,start, length, draw})=>({
    ...state,
    loading : true
  })),
  on(invokeAllFacturesForClientFromIberis , (state , {params , start, length, draw , contactHashedId})=>({
    ...state, 
    loading : true
  })),
  on(resultFacturesFromIberis, (state, { resultFacturesIberis }) => ({
    ...state,
    resultFacturesIberis: resultFacturesIberis,
    loading : false
  })),
  on(resultNextInvoiceNumber, (state, { nextInvoiceNumber }) => ({
    ...state,
    nextInvoiceNumber: nextInvoiceNumber,
    loading : false
  })),
  on(resultDownloadInvoice, (state, { invoice }) => ({
    ...state,
    invoice: invoice,
    loading : false
  })),
  on(resultFacturesForClientFromIberis, (state , {resultFacturesIberis})=>({
    ...state,
    resultFacturesIberis : resultFacturesIberis,
    loading : false
  })),
  on(resultMarkInvoice , (state , {resultMarkInvoice})=>({
    ...state,
    resultMarkInvoice : resultMarkInvoice,
    loading : false
  }))
);
