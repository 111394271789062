<div appClickOutside (clickOutside)="onOutsideClick($event, j)">
  <a
    id="dropdownMenuButton"
    type="button"
    data-bs-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    (click)="openOptions(j)"
    style="margin-bottom: 7px"
  >
    <div style="font-weight: bolder">...</div>
  </a>
  <div
    style="position: absolute"
    class="dropdown-menu end-0"
    [class.show]="showOption[j]"
    aria-labelledby="dropdownMenuButton"
  >
    <a class="dropdown-item p-0" *ngIf="options">
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item list-group-item-action media"
          *ngFor="let option of options; let i = index"
          type="button"
          (click)="onClickEmitOption(option, value)"
        >
          <div class="d-flex">
            <i
              [class]="option.icon"
              class="m-r-5 m-y-3"
              style="margin-top: 3px"
            ></i>
            <a
              style="padding-right: 10px"
            >
              {{ option.name | translate }}
            </a>
          </div>
        </li>
      </ul>
    </a>
  </div>
</div>
