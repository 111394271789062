import { createAction, props } from "@ngrx/store";
import { AdminMainDashboard } from "src/app/shared/models/admin/admin-main-dashboard.interface";
import { AdminProfileInterface } from "src/app/shared/models/admin/admin-profile.interface";
import {
  CollaboratorInterface,
  CreateUpdateCollaboratorInterface,
} from "src/app/shared/models/admin/collaborator.interface";
import { SideBarNewsInterface } from "src/app/shared/models/admin/side-bar-news.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";

export const invokeMainDashboard = createAction(
  "[invoke Main Dashboard] invoke Main Dashboard"
);

export const setMainDashboard = createAction(
  "[Set Main Dashboard] set Main Dashboard",
  props<{ mainDashboard: AdminMainDashboard | null }>()
);

export const invokeCollaborators = createAction(
  "[invoke Collaborators] invoke Collaborators"
);

export const setCollaborators = createAction(
  "[Set Collaborators] set Collaborators",
  props<{ collaborators: CollaboratorInterface[] }>()
);

export const invokeCreateCollaborator = createAction(
  "[invoke Create Collaborator] invoke Create Collaborator",
  props<{ collaborator: CreateUpdateCollaboratorInterface }>()
);

export const invokeUpdateCollaborator = createAction(
  "[invoke Update Collaborator] invoke Update Collaborator",
  props<{ email: string; collaborator: CreateUpdateCollaboratorInterface }>()
);

export const setCrudCollaboratorSuccess = createAction(
  "[Set crud Collaborator success] set crud Collaborator success",
  props<{ success: string | null }>()
);

export const invokeDeleteCollaborator = createAction(
  "[invoke Delete Collaborator] invoke Delete Collaborator",
  props<{ email: string }>()
);

export const invokeAdminProfile = createAction(
  "[invoke Get Profile] invoke Get Profile"
);

export const setProfileSuccess = createAction(
  "[Get Profile success] Get Profile success",
  props<{ profile: AdminProfileInterface }>()
);

export const invokeUpdateAdminProfile = createAction(
  "[invoke Update Admin Profile] invoke Update Admin Profile",
  props<{ profile: AdminProfileInterface }>()
);

export const setUpdateProfileSuccess = createAction(
  "[Update Admin Profile success] Update Admin Profile success",
  props<{ success: string | null }>()
);

export const invokeSideBarNews = createAction(
  "[invoke Side Bar News] invoke Side Bar News"
);

export const setSideBarNews = createAction(
  "[Set SideBarNews success] Set SideBarNews success",
  props<{ sideBarNews: SideBarNewsInterface }>()
);