import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { LayoutService } from "src/app/shared/services/layout/layout.service";
import { NavService } from "src/app/shared/services/nav.service";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeDashboardSettings } from "src/app/store/user/user.actions";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit {
  public dark: boolean =
    this.layout.config.settings.layout_version == "dark-only" ? true : false;

  collapseSidebar: boolean = true;
  constructor(
    private navServices: NavService,
    public layout: LayoutService,
    private router: Router,
    private store: Store<AppStateInterface>
  ) {}

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  layoutToggle() {
    this.dark = !this.dark;
    this.store.dispatch(
      invokeDashboardSettings({
        dashboardSettings: {
          ...this.layout.config,
          settings: {
            ...this.layout.config.settings,
            layout_version: this.dark ? "dark-only" : "light-only",
          },
        },
      })
    );
  }

  searchToggle() {
    this.navServices.search = true;
  }
  goSettings() {
    this.router.navigate(["/dashboard/params"]);
    //window.location.href = window.location.origin + ;
  }
  ngOnInit(): void {}
}
