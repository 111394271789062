<div class="container-fluid">
  <div class="card">
    <div class="card-header pb-0">
      <div class="media-space-between">
        <h4 *ngIf="releadRouters; else fsi">
          {{ "routers.relead.title" | translate }}
        </h4>
        <ng-template #fsi>
          <h4>{{ "routers.fsiRouters.title" | translate }}</h4>
        </ng-template>
        <div class="media-space-between">
          <form class="d-inline-flex m-r-10" [formGroup]="formFilter">
            <div
                class="faq-form mr-3"
                style="color: darkgrey; display: flex; margin-left: 10px"
              >
                <i class="search-icon" data-feather="search"></i>
                <div>
                  <div class="input-group" style="width: 16rem">
                    <input
                      id="table-complete-search"
                      type="text"
                      class="form-control"
                      [placeholder]="'placeholder.searchMac' | translate"
                      formControlName="MAC"
                      (ngModelChange)="searchTerm()"
                    />
                    <span class="input-group-text p-0" style="background-color: transparent;">
                      <app-general-filter
                      [filter]="filterRouter"
                      (optionSelected)="saveOption($event)"
                      >
                      </app-general-filter>
                    </span>
                  </div>
                </div>
              </div>
          </form>
          <button
            class="btn btn-primary"
            type="button"
            style="float: right"
            data-bs-toggle="modal"
            data-original-title="test"
            data-bs-target="#exampleModal"
            (click)="createRouter()"
          >
            <i class="icon-plus"></i>
            {{ "buttons.addRouter" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive data-table">
        <app-list-routers></app-list-routers>
      </div>
    </div>
  </div>
</div>
