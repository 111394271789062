<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card box-shadow-title">
        <div class="card-header p-b-40">
          <div class="media">
            <h4>{{ "admin.campaigns.calendar.title" | translate }}</h4>
            <div class="media-body text-end">
              <form class="d-inline-flex" [formGroup]="formFilter">
                <div style="margin-left: 5px; width: 16.5rem">
                  <div
                    class="input-group date w-auto"
                    id="dt-date"
                    data-target-input="nearest"
                  >
                    <div
                      class="input-group-text bg-primary"
                      data-target="#dt-date"
                      [owlDateTimeTrigger]="dt"
                    >
                      <i
                        class="icofont icofont-ui-calendar"
                        style="color: white; font-size: 12px"
                      ></i>
                    </div>
                    <input
                      width="max-content"
                      class="form-control datetimepicker-input digits"
                      type="text"
                      name="daterange"
                      [placeholder]="'placehorderPickDate' | translate"
                      [selectMode]="'range'"
                      [owlDateTimeTrigger]="dt"
                      [owlDateTime]="dt"
                      formControlName="date"
                      (ngModelChange)="selectDate()"
                    />
                  </div>
                  <owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>
                </div>
                <div
                  class="faq-form mr-3"
                  style="color: darkgrey; display: flex; margin-left: 10px"
                >
                  <i class="search-icon" data-feather="search"></i>
                  <div>
                    <div class="input-group" style="width: 18.5rem">
                      <input 
                        id="table-complete-search"
                        type="text"
                        class="form-control"
                        [placeholder]="
                          'placeholder.searchByCampaignTitle' | translate
                        "
                        formControlName="adTitle"
                        (ngModelChange)="searchTerm()"
                      />
                      <span
                        class="input-group-text p-0"
                        style="background-color: transparent"
                      >
                        <app-general-filter
                          [filter]="filter"
                          (optionSelected)="saveOption($event)"
                        ></app-general-filter>
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-primary m-l-10"
                  type="button"
                  (click)="removeFilter()"
                >
                  <i class="fa fa-repeat me-2"></i>
                  {{ "buttons.removeFilter" | translate }}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="!(loading$ | async)">
          <div class="row">
            <div class="d-flex justify-content-between col-12">
              <div class="btn-group">
                <div
                  class="btn btn-primary"
                  mwlCalendarPreviousView
                  [view]="view"
                  [(viewDate)]="viewDate"
                  (viewDateChange)="closeOpenMonthViewDay()"
                >
                  {{ "admin.campaigns.calendar.previous" | translate }}
                </div>
                <div
                  class="btn btn-outline-primary"
                  mwlCalendarToday
                  [(viewDate)]="viewDate"
                >
                  {{ "admin.campaigns.calendar.today" | translate }}
                </div>
                <div
                  class="btn btn-primary"
                  mwlCalendarNextView
                  [view]="view"
                  [(viewDate)]="viewDate"
                  (viewDateChange)="closeOpenMonthViewDay()"
                >
                  {{ "admin.campaigns.calendar.next" | translate }}
                </div>
              </div>
              <h3>
                {{ viewDate | calendarDate : view + "ViewTitle" : "en" }}
              </h3>
              <div class="btn-group">
                <div
                  class="btn btn-primary"
                  (click)="setView(CalendarView.Month)"
                  [class.active]="view === CalendarView.Month"
                >
                  {{ "admin.campaigns.calendar.month" | translate }}
                </div>
                <div
                  class="btn btn-primary"
                  (click)="setView(CalendarView.Week)"
                  [class.active]="view === CalendarView.Week"
                >
                  {{ "admin.campaigns.calendar.week" | translate }}
                </div>
                <div
                  class="btn btn-primary"
                  (click)="setView(CalendarView.Day)"
                  [class.active]="view === CalendarView.Day"
                >
                  {{ "admin.campaigns.calendar.day" | translate }}
                </div>
              </div>
            </div>
          </div>
          <br />
          <div [ngSwitch]="view" class="col-12">
            <mwl-calendar-month-view
              *ngSwitchCase="CalendarView.Month"
              [viewDate]="viewDate"
              [events]="events"
              [refresh]="refresh"
              [activeDayIsOpen]="activeDayIsOpen"
              (dayClicked)="dayClicked($event.day)"
              (eventClicked)="handleEvent('Clicked', $event.event)"
            >
            </mwl-calendar-month-view>
            <mwl-calendar-week-view
              *ngSwitchCase="CalendarView.Week"
              [viewDate]="viewDate"
              [events]="events"
              [refresh]="refresh"
              (eventClicked)="handleEvent('Clicked', $event.event)"
            >
            </mwl-calendar-week-view>
            <mwl-calendar-day-view
              *ngSwitchCase="CalendarView.Day"
              [viewDate]="viewDate"
              [events]="events"
              [refresh]="refresh"
              (eventClicked)="handleEvent('Clicked', $event.event)"
            >
            </mwl-calendar-day-view>
          </div>
        </div>
        <div *ngIf="loading$ | async">
          <ng-container style="float: right" class="flex">
            <div class="loader-box">
              <div class="loader-34"></div>
            </div>
            <h6
              class="col align-self-center f-w-600"
              style="text-align: center"
            >
              {{ "fetching" | translate }}
            </h6>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #modalContent let-modal id="approveAdvertiserModal">
  <app-details-ad [detailsAd]="details"></app-details-ad>
</ng-template>
