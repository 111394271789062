import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import {
  ActionInterface,
  ActionReturned,
  OptionActionEnum,
  advertiserActions,
  updateAdvertiserActions,
} from "src/app/shared/data/actions-data";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/app/shared/directive/sortable.directive";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import { ResultFilterAdvertiser } from "src/app/shared/models/advertiser/result-filter-advertiser.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { IColumn } from "src/app/shared/models/table/column.interface";
import { BadgeService } from "src/app/shared/services/badge.service";
import {
  approveAdvertiserByEmail,
  invokeAdvertiserFilter,
  invokeAllAdvertiserByFilter,
  invokeApproveAdvertiser,
  resultDeleteAdvertiser,
} from "src/app/store/advertiser/advertiser.actions";
import { initialState } from "src/app/store/advertiser/advertiser.reducers";
import {
  advertiserProfileDetailsSelector,
  approveAdvertiserSelector,
  filterAdvertiserSelector,
  loadingFilterSelector,
  resultDeleteAdvertiserSelector,
  resultFilterAdvertiserSelector,
} from "src/app/store/advertiser/advertiser.selectors";
import { AppStateInterface } from "src/app/store/appState.interface";
import { DetailsModalComponent } from "src/app/shared/components/details-modal/details-modal.component";
import { ToastrService } from "ngx-toastr";
import {
  advertiserFilter,
  FilterInterface,
} from "src/app/shared/data/filter-data";
import {
  AdvertiserStatusEnum,
  AdvertiserStatusEnumMapping,
} from "src/app/shared/enum/advertiser-status";
import { UpdateComponent } from "src/app/shared/components/drob-zone-options/modal/update/update.component";
import { TypeCards } from "src/app/shared/enum/typeCards";
import { saveUpdateFields } from "src/app/store/settings/settings.actions";
import { FilterListAdvertiser } from "src/app/shared/models/advertiser/filter-list-advertiser.interface";
import { invokeApiDistroyed } from "src/app/store/apiState.interface";
import { ResultCreateClient } from "src/app/shared/models/iberis/client/resultCreateClient";
import { resultCreateUpdateClientSelector } from "src/app/store/clientIberis/clientIberis.selectors";
import { UpdateAdvertiserActivationComponent } from "./update-advertiser-activation/update-advertiser-activation.component";
import { DeleteAdvertiserComponent } from "./delete-advertiser/delete-advertiser.component";
import { UpdateAdvertiserStatusComponent } from "./update-advertiser-status/update-advertiser-status.component";
import { invokeSideBarNews } from "src/app/store/admin/admin.actions";

@Component({
  selector: "app-advertisers",
  templateUrl: "./advertisers.component.html",
  styleUrls: ["./advertisers.component.scss"],
})
export class AdvertisersComponent extends BaseComponent implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers!: QueryList<NgbdSortableHeader>;
  isFiltring: boolean | null;
  approveInProgress: boolean | null;
  advertiserToApprove: string;
  statusToChange: boolean;
  emailAdvertiser: string;
  pageSize: number = 10;
  pageNumber: number = 1;
  columns: IColumn[] = [
    {
      key: "companyName",
      label: "admin.advertisers.companyName",
      sorter: true,
      width: 25,
    },
    {
      key: "name",
      label: "admin.advertisers.fullName",
      sorter: true,
      width: 20,
    },
    {
      key: "phone",
      label: "admin.advertisers.phone",
      sorter: true,
      width: 10,
    },
    {
      key: "email",
      label: "admin.settings.access.collaborator.email",
      sorter: true,
      width: 30,
    },
    {
      key: "creationDate",
      label: "admin.advertisers.creationDate",
      sorter: true,
      width: 15,
    },
  ];
  advertiserFilter$: Observable<FilterListAdvertiser>;
  advertiserFilter: FilterListAdvertiser = {
    ...initialState.filter,
    pager: {
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
    },
  };
  resultFilterAdvertiser$: Observable<ResultFilterAdvertiser | null>;
  resultFilterAdvertiser: ResultFilterAdvertiser;

  resultApproveAdvertiser$: Observable<SuccessDto | null>;
  resultDeleteAdvertiser$: Observable<SuccessDto | null>;

  advertiserInfo$: Observable<AdvertiserDetailsInterface | null>;
  advertiserInfo: AdvertiserDetailsInterface | null;
  active = 1;
  submitted: boolean;
  validate: boolean;
  formFilter: FormGroup;
  form: FormGroup;
  options: ActionInterface[] = advertiserActions;
  optionSelected: string | null;
  loading$: Observable<boolean | null>;

  filterAdvertiser: FilterInterface = advertiserFilter;
  fields: ActionInterface[] = updateAdvertiserActions;
  clientCreatedInIberis$: Observable<ResultCreateClient | null>;
  selectedAdvertiser: AdvertiserDetailsInterface | null = null;

  advertiserActions = advertiserActions
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    public badgeService: BadgeService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private toastr: ToastrService
  ) {
    super(store);
    this.advertiserFilter$ = this.store
      .pipe(select(filterAdvertiserSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultFilterAdvertiser$ = this.store
      .pipe(select(resultFilterAdvertiserSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultApproveAdvertiser$ = this.store
      .pipe(select(approveAdvertiserSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultDeleteAdvertiser$ = this.store
      .pipe(select(resultDeleteAdvertiserSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.advertiserInfo$ = this.store
      .pipe(select(advertiserProfileDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingFilterSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.clientCreatedInIberis$ = this.store
      .pipe(select(resultCreateUpdateClientSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.formFilter = this.fb.group({
      email: [""],
    });
    this.form = this.fb.group({
      oldEmail: ["", Validators.required],
      newEmail: ["", Validators.required],
    });
  }
  ngOnInit(): void {
    this.filterAdvertiser.name.map(
      (e) =>
        (e.data = Object.keys(AdvertiserStatusEnum)
          .filter((value) => isNaN(Number(value)) === true)
          .map(
            (key: string | any) =>
              AdvertiserStatusEnumMapping[
                key as keyof typeof AdvertiserStatusEnum
              ]
          ))
    );
    this.loading$.subscribe((data) => {});
    this.resultFilterAdvertiser$.subscribe((result: ResultFilterAdvertiser | null) => {
      if (result) {
        this.resultFilterAdvertiser = result;
      } else {
        this.store.dispatch(
          invokeAdvertiserFilter({
            filter: {
              ...this.advertiserFilter,
              status: [],
              pager: {
                pageSize: this.pageSize,
                pageNumber: this.pageNumber,
              },
            },
          })
        );
      }
    });
    this.store.dispatch(approveAdvertiserByEmail({ successMessage: null }));

    this.resultApproveAdvertiser$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.toastr.success(
          this.translate.instant("response.success." + result.message),
          this.translate.instant("response.successTitle")
        );

        this.store.dispatch(
          invokeAdvertiserFilter({
            filter: {
              ...this.advertiserFilter,
              pager: {
                pageSize: this.pageSize,
                pageNumber: this.pageNumber,
              },
            },
          })
        );
        this.modalService.dismissAll();
        this.store.dispatch(approveAdvertiserByEmail({ successMessage: null }));
        this.selectedAdvertiser = null;
      }
    });

    this.resultDeleteAdvertiser$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.toastr.success(
          this.translate.instant("response.success." + result.message),
          this.translate.instant("response.successTitle")
        );

        this.store.dispatch(
          invokeAdvertiserFilter({
            filter: {
              ...this.advertiserFilter,
              pager: {
                pageSize: this.pageSize,
                pageNumber: this.pageNumber,
              },
            },
          })
        );
        this.modalService.dismissAll();
        this.store.dispatch(resultDeleteAdvertiser({ successMessage: null }));
      }
    });

    this.clientCreatedInIberis$.subscribe(
      (result: ResultCreateClient | null) => {
        if (result) {
          if (this.selectedAdvertiser) {
            this.store.dispatch(
              invokeApproveAdvertiser({
                dto: {
                  email: this.selectedAdvertiser.email,
                  status: AdvertiserStatusEnum.ENABLED,
                  iberis: result.data.client.hashed_id,
                },
              })
            );
            this.store.dispatch(invokeSideBarNews())
          }
        }
      }
    );
    this.advertiserInfo$.subscribe(
      (result: AdvertiserDetailsInterface | null) => {
        if (result) {
          this.advertiserInfo = result;
        }
      }
    );
    this.advertiserFilter$.subscribe((result: FilterListAdvertiser) => {
      if (result) {
        this.advertiserFilter = result;
        this.pageNumber = result.pager.pageNumber;

        this.store.dispatch(invokeAllAdvertiserByFilter());
      }
    });
  }

  get newEmail() {
    return this.form.get("newEmail")?.value;
  }
  filter() {
    this.store.dispatch(invokeApiDistroyed());
    this.pageNumber = 1;
    this.isFiltring = true;
    this.store.dispatch(
      invokeAdvertiserFilter({
        filter: {
          ...this.advertiserFilter,
          email: this.formFilter.get("email")?.value,
          pager: {
            ...this.advertiserFilter.pager,
            pageNumber: this.pageNumber,
          },
        },
      })
    );
  }
  removeFilter() {
    this.formFilter.reset({
      companyName: "",
      email: "",
      lastName: "",
      firstName: "",
      companyAddress: "",
      companyActivity: "",
    });
    this.isFiltring = false;
    this.store.dispatch(
      invokeAdvertiserFilter({
        filter: {
          ...initialState.filter,
          pager: {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
          },
        },
      })
    );
  }

  onSort({ column, direction }: SortEvent) {
    this.store.dispatch(invokeApiDistroyed());
    this.headers.forEach((header) => {
      if (column != header.sortable) {
        header.direction = "";
      }
    });
    var col: string | null = column;
    var sort = null;
    switch (direction) {
      case "asc":
        sort = true;
        break;
      case "desc":
        sort = false;
        break;
      default:
        sort = true;
        col = null;
    }

    this.store.dispatch(
      invokeAdvertiserFilter({
        filter: {
          ...this.advertiserFilter,
          sortName: col,
          isAscending: sort,
        },
      })
    );
  }
  itemsPerPageChange(pageSize: number) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeAdvertiserFilter({
        filter: {
          ...this.advertiserFilter,
          pager: {
            ...this.advertiserFilter.pager,
            pageSize: pageSize,
          },
        },
      })
    );
  }
  get changePageSize() {
    return this.advertiserFilter.pager.pageSize;
  }
  set changePageSize(pageSize: number) {
    this.itemsPerPageChange(pageSize);
  }
  changePage(event: number) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeAdvertiserFilter({
        filter: {
          ...this.advertiserFilter,
          pager: {
            ...this.advertiserFilter.pager,
            pageNumber: event,
          },
        },
      })
    );
  }

  searchTerm() {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeAdvertiserFilter({
        filter: {
          ...this.advertiserFilter,
          email: this.formFilter.get("email")?.value,
          pager: {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
          },
        },
      })
    );
  }
  saveOption(options: AdvertiserStatusEnum[] | null) {
    this.store.dispatch(invokeApiDistroyed());
    let status: any[] = [];
    options?.map((option) => {
      status.push(
        Object.keys(AdvertiserStatusEnum).find(
          (key) =>
            AdvertiserStatusEnumMapping[key as AdvertiserStatusEnum] === option
        )
      );
    });
    this.store.dispatch(
      invokeAdvertiserFilter({
        filter: {
          ...this.advertiserFilter,
          status: status,
          pager: {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
          },
        },
      })
    );
  }
  saveAction(action: ActionReturned, advertiser: AdvertiserDetailsInterface) {
    switch (action.option.type) {
      /*case OptionActionEnum.ADMIN_ADVERTISER_DESACTIVATE: {
        const modal = this.modalService.open(
          UpdateAdvertiserActivationComponent,
          {
            centered: true,
          }
        );
        modal.componentInstance.name = "pop-up.advertiser.title";
        modal.componentInstance.title = action.option.name;
        modal.componentInstance.advertiser = advertiser;
        modal.componentInstance.activation = AdvertiserStatusEnum.DISABLED;
        break;
      }
      case OptionActionEnum.ADMIN_ADVERTISER_ACTIVATE: {
        const modal = this.modalService.open(
          UpdateAdvertiserActivationComponent,
          {
            centered: true,
          }
        );
        this.selectedAdvertiser = advertiser;
        modal.componentInstance.advertiser = advertiser;
        modal.componentInstance.activation = AdvertiserStatusEnum.ENABLED;
        modal.componentInstance.name = "pop-up.advertiser.title";
        modal.componentInstance.title = action.option.name;
        break;
      }*/
      case OptionActionEnum.ADMIN_ADVERTISER_UPDATE_STATUS: {
        const modal = this.modalService.open(
          UpdateAdvertiserStatusComponent,
          {
            centered: true,
          }
        );
        this.selectedAdvertiser = advertiser;
        modal.componentInstance.advertiser = advertiser;
        modal.componentInstance.title = action.option.name;
        break;
      }
      case OptionActionEnum.ADMIN_ADVERTISER_DELETE: {
        const modal = this.modalService.open(DeleteAdvertiserComponent, {
          centered: true,
        });
        modal.componentInstance.email = advertiser.email;
        break;
      }
      case OptionActionEnum.ADMIN_ADVERTISER_DETAILS: {
        const modal = this.modalService.open(DetailsModalComponent, {
          centered: true,
          size: "lg",
        });
        modal.componentInstance.details = advertiser;
        modal.componentInstance.title = action.option.name;
        break;
      }
      case OptionActionEnum.ADMIN_ADVERTISER_UPDATE: {
        this.store.dispatch(saveUpdateFields({ options: this.fields }));
        const modalRef = this.modalService.open(UpdateComponent, {
          centered: true,
          size: "xl",
        });
        modalRef.componentInstance.isCol4 = true;
        modalRef.componentInstance.type = TypeCards.ADMIN_ADVERTISER_UPDATE;
        modalRef.componentInstance.title = "actions.advertiser.updateAdvertiser";
        modalRef.componentInstance.advertiser = advertiser;
        modalRef.componentInstance.email = advertiser.email;
        modalRef.closed.subscribe(() =>
          this.store.dispatch(
            invokeAdvertiserFilter({
              filter: {
                ...this.advertiserFilter,
              },
            })
          )
        );
      }
    }
  }
  getOptions(status: AdvertiserStatusEnum): ActionInterface[] {
    if (status == AdvertiserStatusEnum.EMAIL_VERIFICATION)
      return advertiserActions.filter(
        (value) => value.type != OptionActionEnum.ADMIN_ADVERTISER_ACTIVATE
      );
      return advertiserActions
  }
  onClickshowEditModal(editAdvertiser: any, email: string) {
    this.form.patchValue({ oldEmail: email });
    const modalRef = this.modalService.open(editAdvertiser);
    this.emailAdvertiser = email;
  }
  onClickShowAdvertiserDetails(
    showAdvertiserDetailsModal: any,
    advertiser: AdvertiserDetailsInterface
  ) {
    const modalRef = this.modalService.open(showAdvertiserDetailsModal, {
      centered: true,
      size: "lg",
    });
    modalRef.componentInstance.details = advertiser;
    this.advertiserInfo = advertiser;
  }
  saveFilter() {}
}
