<div class="modal-header justify-content-start">
  <h4 class="modal-title" id="exampleModalLabel">
    {{ title | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal()"
  ></button>
</div>
<div class="modal-body">
  <h5>
    <p style="font-size: 14px">
      {{ "pop-ups_Message.approve.part1" | translate }}
      {{ name | translate }} {{ "pop-ups_Message.approve.status" | translate }}:
      <b>{{ reference }}</b> ?
    </p>
    <span class="text-danger">
      <b>{{ "pop-ups_Message.delete.part2" | translate }}</b>
    </span>
  </h5>
</div>

<div class="modal-footer">
  <button class="btn btn-outline-primary" (click)="closeModal()">
    {{ "buttons.close" | translate }}
  </button>
  <button class="btn btn-primary m-r-20" (click)="onClickUpdateStatus()">
    <loading-button
      [loading]="loading"
      [value]="'buttons.update'"
      [icon]="null"
    ></loading-button>
  </button>
</div>
