import { createAction, props } from "@ngrx/store";
import { RoutersFilterInterface } from "src/app/shared/models/router/routersFilter.interface";
import { RouterInterface } from "src/app/shared/models/router/router.interface";
import { RoutersResultInterface } from "src/app/shared/models/router/routersResult.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { RouterToUpdateInterface } from "src/app/shared/models/router/router-update.interface";

export const invokeRouterFilter = createAction(
  "[Invoke Router By Filter] Invoke router by filter",
  props<{ routerFilter: RoutersFilterInterface }>()
);
export const invokeRouters = createAction("[Routers Data] invoke Routers");

export const getRoutersSuccess = createAction(
  "[Set Router By Filter] Set router by filter",
  props<{ routersResult: RoutersResultInterface}>()
);

export const invokeCreateRouter = createAction(
  "[Invoke Create Router] Invoke create router",
  props<{ createRouter: RouterInterface }>()
);
export const routerSuccessMessage = createAction(
  "[Message Router Success] Message router success",
  props<{ successMessage: SuccessDto | null }>()
);

export const invokeUpdateRouter = createAction(
  "[Invoke Update Router] Invoke update router",
  props<{oldMAC: string, routerDto: RouterInterface }>()
);
export const updateRouterSuccess = createAction(
  "[Update Router Success] Update router success",
  props<{ successMessage: string | null }>()
);

export const invokeDeleteRouter = createAction(
  "[Invoke Delete Router] Invoke Delete Router",
  props<{ MAC: string }>()
);

export const deleteRouterSuccess = createAction(
  "[Delete Router Success] Delete Router Success",
  props<{ successMessage: SuccessDto | null }>()
);

export const invokeGetRouterByMac = createAction(
  "[Invoke Create Router By Mac] Invoke Create Router By Mac",
  props<{ MAC: string }>()
);
export const getRouterByMac = createAction(
  "[Set Create Router By Mac] Set Create Router By Mac",
  props<{ routerDetail: RouterInterface | null }>()
);
export const invokeIsExistByMac = createAction(
  "[ Invoke check router exist ] Invoke check router exist ",
  props<{Mac : string}>()
)
export const resultIsExistByMac = createAction(
  "[ Result router exist by mac address ] Result router exist by mac address",
  props<{isExist : boolean | null}>()
)