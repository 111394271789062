import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SessionTimeOut, SessionTimeOutInterface, SessionTimeOutToShow } from "src/app/shared/enum/sessionTimeOut";
import { RouterInterface } from "src/app/shared/models/router/router.interface";
import { BadgeService } from "src/app/shared/services/badge.service";

@Component({
  selector: "app-details-router",
  templateUrl: "./details-router.component.html",
})
export class DetailsRouterComponent implements OnInit {
  @Input() router: RouterInterface;
  @Input() title: string;
  constructor(
    public badgeService: BadgeService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {}
  onClickClose() {
    this.modalService.dismissAll();
  }
  sessionTimeOut(sessionTimeOut: SessionTimeOut | null){
    const key = Object.keys(SessionTimeOutToShow).find(
      (key) => SessionTimeOut[key as keyof typeof SessionTimeOut] === sessionTimeOut
    );
    return SessionTimeOutToShow[key as SessionTimeOut];
  }
}