import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Constants } from "../../data/constants";
import { environment } from "../../../../environments/environment";
import {
  CheckPaymentKonnectInterface,
  PostKonnectInterface,
  ResultKonnectInterface,
} from "../../models/payment/konnect.interface";

@Injectable({
  providedIn: "root",
})
export class KonnectService {
  constructor(private http: HttpClient) {}

  postPayment(
    paymee: PostKonnectInterface
  ): Observable<ResultKonnectInterface> {
    return this.http.post<ResultKonnectInterface>(
      `${
        environment.PAYMENT.APP_KONNECT.URL + Constants.KONNECT_END_POINT
      }/payments/init-payment`,
      paymee
    );
  }

  getpaymentInfo(token: string): Observable<CheckPaymentKonnectInterface> {
    return this.http.get<CheckPaymentKonnectInterface>(
      `${
        environment.PAYMENT.APP_KONNECT.URL + Constants.KONNECT_END_POINT
      }/payments/` + token
    );
  }
}
