import { createAction, props } from "@ngrx/store";
import { createEstimate } from "src/app/shared/models/iberis/commande/createEstimate";
import { ResultCreateEstimate } from "src/app/shared/models/iberis/commande/resultCreateEstimate";
import { ResultListOrdersFromIberis } from "src/app/shared/models/iberis/commande/resultListOrdersFromIberis";
import { ResultSynthesisEstimate } from "src/app/shared/models/iberis/commande/resultSynthesisEstimate";
import { MarkEstimate } from "src/app/shared/models/iberis/commande/validEstimate";
import { CommonParamsIberis} from "src/app/shared/models/iberis/paramsForGetAll";
import { ResultInvoiceEstimate } from "src/app/shared/models/iberis/resultInvoiceEstimate";

export const invokeListOrdersFromIberis = createAction(
  "[ Invoke List Orders From Iberis] Invoke list orders from iberis ",
  props<{params : CommonParamsIberis, start: number, length: number , draw: number}>()
)
export const invokeListOrdersFromIberisByStatus = createAction(
  "[ Invoke Orders From Iberis by status ] Invoke orders from iberis by status",
  props<{params : CommonParamsIberis, status : number , start: number, length: number , draw: number}>()
)
export const resultListOrdersFromIberis = createAction(
  "[ Result List Orders Form Iberis ] Result List orders from iberis ",
  props<{resultListOrdersFromIberis : ResultListOrdersFromIberis | null}>()
)
export const invokeDownloadEstimate = createAction(
  "[ Invoke Download Estimate ] invoke Download estimate",
  props<{params: CommonParamsIberis , estimateId: string}>()
)
export const resultEstimate = createAction(
  "[Result Estimate ]Result estimate ",
  props<{estimate : Blob}>()
)
export const invokeListOrdersFromIberisForSpecificAdvertiser = createAction(
  "[ Invoke Orders From Iberis for specific advertiser ] Invoke orders from iberis for specific advertiser",
  props<{params : CommonParamsIberis, contactHashedId: string, start: number, length: number , draw: number}>()
)
export const invokeListOrdersFromIberisByStatusForSpecificAdvertiser = createAction(
  "[ Invoke Orders From Iberis by status for specific advertiser ] Invoke orders from iberis by status for specific advertiser",
  props<{params : CommonParamsIberis, contactHashedId: string, status : number , start: number, length: number , draw: number}>()
)
export const invokeCreateEstimate = createAction(
  "[ Invoke Create Estimate ] Invoke create estimate ",
  props<{params: CommonParamsIberis ,createEstimate : createEstimate}>()
)
export const resultCreateEstimate = createAction(
  "[ Estimate Created ] Estimate created ",
  props<{resultCreateEStimate : ResultCreateEstimate | null}>()
)
export const invokeInvoiceEstimate = createAction(
  "[ Invoke Invoice Estimate ] Invoke invoice estimate", 
  props<{params : CommonParamsIberis, estimateId : string | null}>()
)
export const resultInvoiceEstimate = createAction(
  "[ Result Invoice Estimate ] Result invoice estimate" ,
  props<{resultInvoiceEstimate : ResultInvoiceEstimate | null}>()
)
export const invokeUpdateEstimate = createAction(
  "[ Invoke Update Estimate ] Invoke update estimate",
  props<{params: CommonParamsIberis ,createEstimate : createEstimate , estimateId : string}>()
)
export const resultUpdateEstimate = createAction(
  "[ Estimate updated ] Estimate updated ",
  props<{resultUpdateEstimate : ResultCreateEstimate | null}>()
)
export const invokeMarkEstimate = createAction(
  "[ Invoke mark estimate ] Invoke mark estimate ",
  props<{params : CommonParamsIberis , estimateId : string}>()
)
export const resultMarkEstimate = createAction(
  "[ Invoke Mark Estimate as valid ] Invoke mark estimate as valid ",
  props<{markEstimate : MarkEstimate | null}>()
)
export const invokeSynthesisEstimate = createAction(
  "[ Invoke Synthesis Of An Estimate ] Invoke synthesis of an estimate",
  props<{params : CommonParamsIberis, estimateId : string}>()
)
export const resultSynthesisEstimate = createAction(
  "[ Result Synthesis Of An Estimate ] Result synthesis of an estimate",
  props<{resultSynthesisEstimate : ResultSynthesisEstimate | null}>()
)