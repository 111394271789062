import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ChangePassword } from "../models/user/change-password.interface";
import { Observable } from "rxjs";
import { Constants } from "../data/constants";
import { SuccessDto } from "../models/success-dto";
import { TokenHistoryInterface, TokenHistoryResultInterface } from "../models/user/token.interface";
import { SubRoleInterface } from "../models/sub-role/subRole.interface";
import { Pager } from "../models/pagination";

@Injectable({
  providedIn: "root",
})
export class UserService {
  
  endPoint = "users/api/";

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient) {}

  GetUsersByFilter(formFilter: any) {
    return this.http.post(
      environment.APP_PORT + this.endPoint + "user/GetUsersByFilter",
      formFilter
    );
  }
  CreateUser(user: any) {
    return this.http.post(
      environment.APP_PORT + this.endPoint + "user/CreateUser",
      user
    );
  }
  UpdateUser(user: any) {
    return this.http.put(
      environment.APP_PORT + this.endPoint + "user/UpdateUser",
      user
    );
  }

  GetUserById(id: string) {
    return this.http.get(
      environment.APP_PORT + this.endPoint + "user/GetUserById/" + id
    );
  }
  DeleteUser(id: string) {
    return this.http.delete(
      environment.APP_PORT + this.endPoint + "user/DeleteUser/" + id
    );
  }
  forgotPassword(email: string | null): Observable<SuccessDto> {
    return this.http.post<SuccessDto>(
      `${environment.APP_PORT + Constants.USER_ENDPOINT}/forgotPassword/` +
        email,
      null
    );
  }
  invokeCheckTokenForResetPassword(token: string | null, email:string | null): Observable<SuccessDto> {
    return this.http.get<SuccessDto>(
      `${environment.APP_PORT + Constants.USER_ENDPOINT}/checkTokenResetPassword/` + email + '/' + token
    );
  }
  resetPassword(token: string, password: string): Observable<SuccessDto> {
    let params = new HttpParams();
    params = params.append("resetToken", token);
    params = params.append("password", password);
    return this.http.post<SuccessDto>(
      `${environment.APP_PORT + Constants.USER_ENDPOINT}/resetPassword`,
      null,
      {
        params,
      }
    );
  }
  UpdatePassword(
    changePassword: ChangePassword
  ): Observable<SuccessDto | null> {
    return this.http.post<SuccessDto | null>(
      `${environment.APP_PORT + Constants.USER_ENDPOINT}/changePassword`,
      changePassword
    );
  }
  checkIfUserExistByEmail(email: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.APP_PORT + Constants.USER_ENDPOINT}/exists/` + email
    );
  }
  checkIfUserExistByCompanyName(companyName: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.APP_PORT + Constants.USER_ENDPOINT}/exists/byCompanyName/` + companyName
    );
  }
  getHistoryUserTokens(
    email: string,
    pager: Pager
  ): Observable<TokenHistoryResultInterface> {
    return this.http.post<TokenHistoryResultInterface>(
      `${
        environment.APP_PORT + Constants.USER_ENDPOINT
      }/getTokenHistoryByUser/` + email,
      pager
    );
  }

  getSubRoleByUser(email: string): Observable<SubRoleInterface | null> {
    return this.http.get<SubRoleInterface | null>(
      `${
        environment.APP_PORT + Constants.USER_ENDPOINT
      }/getSubRoleByUser/${email}`
    );
  }
}
