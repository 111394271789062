<div class="card fill-card">
  <div class="card-body d-flex align-items-baseline">
    <i class="icofont icofont-ui-calendar" style="color: white; font-size: 12px;"></i>
    <p style="color: white; margin-left: 10px; font-size: 12px">
      {{ "dashboard.help." + type | translate }}
      <a style="color: #ff69b4" type="button" target="_blank" [href]="link">
        {{ "dashboard.link" | translate }}
      </a>
    </p>
  </div>
</div>
