import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { Store } from "@ngrx/store";
import { AuthService } from "../../shared/services/auth.service";
import { AppStateInterface } from "../appState.interface";
import {
  invokeLogin,
  loginSuccess,
  invokeAccount,
  setAccount,
  invokeLogout,
  invokeLogoutAllSessions,
  logoutSuccess,
  logoutError,
} from "./auth.actions";
import { JwtService } from "src/app/shared/services/jwt.service";
import { setAPIStatus } from "../apiState.interface";

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private jwtService: JwtService
  ) {}

  invokeLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeLogin),
      mergeMap(({ email, password, ipAddress }) => {
        return this.authService
          .Login({ email: email, password: password, ipAddress: ipAddress })
          .pipe(
            map((data: any) =>
              loginSuccess(data.token ? { token: data.token } : { token: null })
            ),
            catchError((error) => {
              const actions = [
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error ?? "Check in a while !",
                    apiStatus: "error",
                  },
                }),
                logoutError(),
              ];
              return of(...actions);
            })
          );
      })
    )
  );

  invokeAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAccount),
      mergeMap((state) => {
        return this.authService.CurrentUser().pipe(
          map((data: any) =>
            setAccount({
              account: {
                user: data,
                role: this.jwtService.getRoleName(
                  localStorage.getItem("token")
                ),
              },
            })
          ) /*,
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )*/
        );
      })
    )
  );

  invokeLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeLogout),
      mergeMap(() => {
        return this.authService.Logout().pipe(
          map((data: any) =>
            logoutSuccess({
              logout: true,
              token: null,
            })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error ?? "Check in a while !",
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeLogoutAllSessions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeLogoutAllSessions),
      mergeMap(() => {
        return this.authService.LogoutAllSessions().pipe(
          map((data: any) => logoutSuccess({ logout: true, token: null })),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error ?? "Check in a while !",
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
}
