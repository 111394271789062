export enum PaymentStatus {
    PAID = "PAID",
    UNPAID = "UNPAID"
}
export enum PaymentStatusToShow {
    PAID = "filter.paymentFilter.paid",
    UNPAID = "filter.paymentFilter.unpaid",
}

export interface PaymentStatusInterface {
  id: PaymentStatus;
  value: string;
}