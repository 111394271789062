import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { RouterStatus, RouterStatusInterface, RouterStatusToShow } from "src/app/shared/enum/router-status";

@Component({
  selector: "app-router-specification",
  templateUrl: "./router-specification.component.html"
})
export class RouterSpecificationComponent implements OnInit {
  @Input("form") form: FormGroup;
  @Input() isUpdate : boolean
  @Input("routerType") routerType: boolean;
  routerStatus: RouterStatusInterface[];
  constructor() {}

  ngOnInit(): void {
    this.routerStatus = Object.keys(RouterStatus)
      .filter((value) => isNaN(Number(value)) === true)
      .map((key: string | any) => ({
        id: RouterStatus[key as keyof typeof RouterStatus],
        value: RouterStatusToShow[key as keyof typeof RouterStatus],
      }));
  }
}
