import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loading-button',
  templateUrl: './loading-button.component.html',
})
export class LoadingButtonComponent implements OnInit {

  @Input("loading") loading : any;
  @Input("value") value : string;
  @Input("icon") icon : string | null
  constructor() { }

  ngOnInit() {
  }

}
