import { createReducer, on } from "@ngrx/store";
import { resultPaymentReceived } from "./payments-received.actions";
import { PaymentReceivedStateInterface } from "./payments-received.interface";

export const initialState: Readonly<PaymentReceivedStateInterface> = {
    resultPaymentReceived : null,
};
export const PaymentReceivedReducer = createReducer(
  initialState,
  on(resultPaymentReceived, (state, { resultPaymentReceived }) => ({
    ...state,
    resultPaymentReceived: resultPaymentReceived,
  })),
);