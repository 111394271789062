<div class="form-check checkbox checkbox-primary mb-0">
  <input
    class="form-check-input"
    id="checkbox-primary-3"
    [checked]="CheckZoneSelectedAll()"
    (click)="selectAllZones($event)"
    type="checkbox"
  />
  <label class="form-check-label label" for="checkbox-primary-3">
    {{ "select.selectAllZones" | translate }}
  </label>
</div>
<ng-select
  [placeholder]="'select.selectZone' | translate"
  [items]="getLocationPartnersZoneNames"
  [closeOnSelect]="false"
  [hideSelected]="true"
  (change)="onSelectCalculateCost()"
  [(ngModel)]="selectedZones"
  [clearSearchOnAdd]="true"
  [multiple]="true"
></ng-select>
<div *ngIf="calculateTotalLPCostByCampanyNames.length > 0">
  <div class="col-xxl-12 col-lg-12 box-col-12 box-container">
    <ng-container *ngFor="let item of calculateTotalLPCostByCampanyNames"
      ><span
        class="m-l-5 m-b-5"
        data-bs-toggle="tooltip"
        placement="bottom"
        [ngbTooltip]="tooltipAvailability"
        (mouseenter)="locationSelected = item"
        [ngClass]="
          'badge ' + badgeService.getBadgeForLocationPartnerAvailability(item)
        "
        >{{ item.companyName }}
      </span></ng-container
    >
  </div>
</div>
<p *ngIf="notAvailableExists" class="txt-warning m-15">{{ "newCampaign.step1.availibilityNotice" | translate }}</p>

<ng-template #tooltipAvailability>
  <div>
    {{
      "enumMapping.locationAvailibility." +
        locationSelected.availabilities.availabilityStatus | translate
    }}
  </div>
</ng-template>
