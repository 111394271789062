import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { ZoneInterface } from "src/app/shared/models/zone/zone";
import { ZoneService } from "src/app/shared/services/zone.service";
import { setAPIStatus } from "../apiState.interface";
import {
  createUpdateZoneSuccess,
  deleteZoneSuccess,
  invokeAllZoneNames,
  invokeAllZones,
  invokeCreateZone,
  invokeDeleteZone,
  invokeUpdateZone,
  setAllZoneNames,
  setAllZones,
} from "./zone.actions";
import { SuccessDto } from "src/app/shared/models/success-dto";

@Injectable()
export class ZoneEffect {
  constructor(private actions$: Actions, private zoneService: ZoneService) {}

  invokeAllZones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAllZones),
      mergeMap((state) => {
        return this.zoneService.getAllZones().pipe(
          map((data: ZoneInterface[] | null) => setAllZones({ zones: data })),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeCreateZone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCreateZone),
      mergeMap((state) => {
        return this.zoneService.createZone(state.zone).pipe(
          mergeMap((data: SuccessDto) => {
            const actions = [
              createUpdateZoneSuccess({ message: data.message }),
              invokeAllZones(),
              invokeAllZoneNames()
            ];
            return of(...actions);
          }),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeUpdateZone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateZone),
      mergeMap((state) => {
        return this.zoneService.updateZone(state.zone, state.oldName).pipe(
          mergeMap((data: SuccessDto) => {
            const actions = [
              createUpdateZoneSuccess({ message: data.message }),
              invokeAllZones(),
              invokeAllZoneNames()
            ];
            return of(...actions);
          }),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeDeleteZone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeDeleteZone),
      mergeMap((state) => {
        return this.zoneService.deleteZone(state.zoneName).pipe(
          mergeMap((data: SuccessDto) => {
            const actions = [
              deleteZoneSuccess({ message: data.message }),
              invokeAllZones(),
              invokeAllZoneNames()
            ];
            return of(...actions);
          }),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeAllZoneNames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAllZoneNames),
      mergeMap((state) => {
        return this.zoneService.getAllZoneNames().pipe(
          map((data: string[] | null) => setAllZoneNames({ zoneNames: data })),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
}
