import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../data/constants";
import { CalculateCost } from "../models/calcul/calculateCost";
import { TotalCostWithDiscount } from "../models/calcul/totalCost";
import { FilterLocationPartnersToCreateCampaign } from "../models/location-partners/filterBySubCategoriesAndTagLocationPartner";
import { LocationPartnerInterface } from "../models/location-partners/locationPartner.interface";
import { LocationPartnersFilterInterface } from "../models/location-partners/locationPartnersFilter.interface";
import { LocationPartnersResultInterface } from "../models/location-partners/locationPartnersResult.interface";
import { ResultMainStaticsForLP } from "../models/location-partners/resultMainStaticsLP";
import { SuccessDto } from "../models/success-dto";
import { PricingInterface } from "src/app/shared/models/location-partners/pricing";
import { LPInfo } from "../models/location-partners/lpInfo";
import { Pager } from "../models/pagination";
import { FilterLastWeekForLPReview } from "../models/location-partners/filterLastWeekForLPReview";
import { CreateLPInterface } from "../models/location-partners/createLP";
import { CalculateAverageViewsDto } from "../models/location-partners/CalculateAverageViewsDto";
import {
  LocationPartnersImpressionsFilterInterface,
  LocationPartnersImpressionsResult,
} from "../models/location-partners/locationPartnersImpressions";
import { CalculateViews } from "../models/calcul/calculateViews";
import { GrantPremiumAccess } from "../models/location-partners/GrantPremiumAccess";
import { UpdatePaymentLP } from "../models/update-payment-LP.interface";
import { PricingMethod } from "../enum/pricing-method";
import { AdvertisingCampaignsPaymentLP } from "../models/advertising-campaigns/advertising-campaigns-payment-LP.interface";

@Injectable({
  providedIn: "root",
})
export class LocationPartnersService {
  constructor(private http: HttpClient) {}

  getAllLocationPartnerByFilter(
    localisationPartnerFilter: LocationPartnersFilterInterface
  ): Observable<LocationPartnersResultInterface> {
    return this.http.post<LocationPartnersResultInterface>(
      `${environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT}/filter`,
      localisationPartnerFilter
    );
  }

  getAllLocationPartnersByCompanyNames(
    companyNames: string[] | null
  ): Observable<LocationPartnerInterface[]> {
    return this.http.post<LocationPartnerInterface[]>(
      `${
        environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT
      }/allLocationPartnersByCompanyNames`,
      companyNames
    );
  }

  getLocationPartnerByCompanyName(
    companyName: string | null
  ): Observable<LPInfo> {
    return this.http.get<LPInfo>(
      `${environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT}/info/` +
        companyName
    );
  }

  getLPInfo(): Observable<LPInfo | null> {
    return this.http.get<LPInfo>(
      `${
        environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT
      }/infoByToken`
    );
  }

  getAllLocationPartnersCompanyNames(): Observable<string[]> {
    return this.http.get<string[]>(
      `${
        environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT
      }/allCompanyNames`
    );
  }

  deleteAccount(email: string): Observable<SuccessDto> {
    return this.http.delete<SuccessDto>(
      `${environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT}/${email}`
    );
  }
  filterLocationPartnersToCreateCampaign(
    filter: FilterLocationPartnersToCreateCampaign
  ): Observable<LocationPartnerInterface[] | null> {
    return this.http.post<LocationPartnerInterface[] | null>(
      `${
        environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT
      }/filterLocationPartnersToCreateCampaign`,
      filter
    );
  }
  calculateTotalLPCostByCampanyNames(
    calculateCost: CalculateCost
  ): Observable<TotalCostWithDiscount> {
    return this.http.post<TotalCostWithDiscount>(
      `${
        environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT
      }/calculate/cost/locations`,
      calculateCost
    );
  }

  createLocationPartner(
    createLocationPartner: CreateLPInterface
  ): Observable<SuccessDto> {
    return this.http.post<SuccessDto>(
      `${environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT}/create`,
      createLocationPartner
    );
  }
  updateLocationPartner(
    updateLocationPartner: LocationPartnerInterface | null,
    email: string | null
  ): Observable<SuccessDto> {
    return this.http.patch<SuccessDto>(
      `${
        environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT
      }/fullProfile/` + email,
      updateLocationPartner
    );
  }
  getlLocationPartnerByAdTitle(
    adTitle: string | null
  ): Observable<LocationPartnerInterface[] | null> {
    return this.http.get<LocationPartnerInterface[]>(
      `${
        environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT
      }/allLocationPartners/` + adTitle
    );
  }
  getMainStaticsForLP(
    authentification: string
  ): Observable<ResultMainStaticsForLP> {
    return this.http.get<ResultMainStaticsForLP>(
      `${environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT}/main`
    );
  }
  getPricingByCompanyName(
    companyName: string
  ): Observable<PricingInterface | null> {
    return this.http.get<PricingInterface | null>(
      `${
        environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT
      }/pricing/${companyName}`
    );
  }
  filterLocationPartnerReview(
    pager: Pager
  ): Observable<FilterLastWeekForLPReview | null> {
    return this.http.post<FilterLastWeekForLPReview>(
      `${environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT}/review`,
      pager
    );
  }

  getLocationPartnersByRegion(
    regionNames: String[]
  ): Observable<string[][] | null> {
    return this.http.post<string[][]>(
      `${environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT}/region`,
      regionNames
    );
  }
  updateLocationAccount(
    Authorization: string,
    locationPartnerDto: LocationPartnerInterface
  ): Observable<SuccessDto | null> {
    return this.http.patch<SuccessDto>(
      `${environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT}/profile`,
      locationPartnerDto
    );
  }
  getLocationPartnersImpressions(
    filter: LocationPartnersImpressionsFilterInterface
  ): Observable<LocationPartnersImpressionsResult> {
    return this.http.post<LocationPartnersImpressionsResult>(
      `${
        environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT
      }/impressions/filter`,
      filter
    );
  }

  calculateAndUpdateViewsAverage(
    calculateAverageViews: CalculateAverageViewsDto | null,
    sendMail: boolean
  ): Observable<SuccessDto> {
    const endPoint =
      "/calculateAndUpdateViewsAverage" + (sendMail ? "/sendMail" : "");
    return this.http.post<SuccessDto>(
      `${
        environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT + endPoint
      }`,
      calculateAverageViews
    );
  }

  grantPremiumAccessForLP(
    grantPremiumAccess: GrantPremiumAccess
  ): Observable<SuccessDto | null> {
    return this.http.patch<SuccessDto>(
      `${
        environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT
      }/GrantPremiumAccessLP`,
      grantPremiumAccess
    );
  }
  LPAdsBeforeEndDate(
    endDate: Date | null
  ): Observable<LocationPartnerInterface | null> {
    return this.http.get<LocationPartnerInterface>(
      `${
        environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT
      }/LPHasAds/endDate/${endDate}`
    );
  }

  updatePaymentStatusLP(
    updatePaymentStatusLP: UpdatePaymentLP | null
  ): Observable<SuccessDto | null> {
    return this.http.patch<SuccessDto>(
      `${
        environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT
      }/updatePaymentStatusLp`,
      updatePaymentStatusLP
    );
  }

  getIsPaidAds(companyNames: string[]): Observable<boolean[] | null> {
    return this.http.post<boolean[] | null>(
      `${environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT}/getIsPaid`,
      companyNames
    );
  }

  calculateViewsOrUniqueViewsBasedOnNotPaidAds(
    pricingMethode: PricingMethod | null
  ): Observable<number> {
    return this.http.get<number>(
      `${
        environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT
      }/views/uniqueViews/notRemuneratedAds/${pricingMethode}`
    );
  }

  getAllLocationPartnerByFilterForPayment(
    localisationPartnerFilter: LocationPartnersFilterInterface
  ): Observable<LocationPartnersResultInterface> {
    return this.http.post<LocationPartnersResultInterface>(
      `${
        environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT
      }/locationPartner/payment/filter`,
      localisationPartnerFilter
    );
  }

  locationPartnerAdvertisingCampignFilter(
    locationPartnerCampaignsFilter: LocationPartnersFilterInterface | null,
    isWithViews: boolean
  ): Observable<AdvertisingCampaignsPaymentLP | null> {
    return this.http.post<AdvertisingCampaignsPaymentLP | null>(
      `${
        environment.APP_PORT + Constants.LOCATION_PARTNER_ENDPOINT
      }/advertisingCampaigns/payment/filter/${isWithViews}`,
      locationPartnerCampaignsFilter
    );
  }
}
