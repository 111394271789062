import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Constants } from "src/app/shared/data/constants";
import { PartnerStatus } from "src/app/shared/enum/partner-status";
import { PaymentFrequency } from "src/app/shared/enum/payment-frequency";
import { PricingMethod } from "src/app/shared/enum/pricing-method";
import { IberisActivities } from "src/app/shared/models/iberis/activities";
import { LocationPartnerInterface } from "src/app/shared/models/location-partners/locationPartner.interface";
import { BadgeService } from "src/app/shared/services/badge.service";

@Component({
  selector: "app-details-lp",
  templateUrl: "./details-lp.component.html",
})
export class DetailsLpComponent implements OnInit {
  @Input() locationPartner: LocationPartnerInterface;
  @Input() title: string;
  partnerStatus = PartnerStatus;
  pricingMethod = PricingMethod;
  paymentFrequencyToShow = PaymentFrequency;
  companyActivities: IberisActivities[];
  constructor(
    private modalService: NgbModal,
    public badgeService: BadgeService,
    protected translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.translate.currentLang == "fr")
      this.companyActivities = Constants.IBERIS_ACTIVITIES_FR;
    else this.companyActivities = Constants.IBERIS_ACTIVITIES_EN;
    this.translate.onLangChange.subscribe(
      (langChangeEvent: LangChangeEvent) => {
        if (langChangeEvent.lang == "fr")
          this.companyActivities = Constants.IBERIS_ACTIVITIES_FR;
        else this.companyActivities = Constants.IBERIS_ACTIVITIES_EN;
      }
    );
    this.locationPartner = {
      ...this.locationPartner,
      companyActivity: this.companyActivities.find(
        (value) => this.locationPartner.companyActivity == value.hashed_id
      )?.title,
    };
  }
  closeModal() {
    this.modalService.dismissAll();
  }
}
