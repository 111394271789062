<div class="card border-style">
  <div class="card-body">
    <div *ngIf="isLoading">
      <div class="loader-box">
        <div class="loader-34"></div>
      </div>
      <h6
        class="col align-self-center f-w-600 m-t-3"
        style="text-align: center"
      >
        {{ "fetching" | translate }}
      </h6>
    </div>
    <div *ngIf="!isLoading">
      <div class="col-12 col-md-12">
        <div class="media">
          <img src="../../../../{{ icon }}" alt="" style="margin-right: 10px" />
          <div class="media-body">
            <h5 class="f-w-600 media">
              <a style="margin-right: 5px" style="font-size: 15px"
                >{{ title | translate }}
                <span style="font-size: smaller" class="color-darkGray">
                  | {{ "info.total" | translate }}</span
                >
              </a>
            </h5>
            <div class="col">
              <h6 class="f-w-600">{{ value | toKValue }}</h6>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row" style="margin-top: 10px" *ngIf="role == 'ROLE_ADMIN'">
        <ul style="margin-right: 10px">
          <li>
            <div class="row">
              <div class="media">
                <div style="margin: 0 10px">
                  <h6>
                    <span
                      [ngClass]="{
                        'font-danger small pt-1 fw-bold': changes < 0,
                        'font-success small pt-1 fw-bold': changes >= 0
                      }"
                    >
                      <i
                        class="fa-solid fa-arrow-up-right fa-rotate-180"
                        *ngIf="changes < 0"
                      ></i><
                      <i
                        class="fa-solid fa-arrow-up-right"
                        *ngIf="changes >= 0"
                      ></i>
                      <i
                        class="widget-icon"
                        [ngClass]="{
                          'icon-arrow-down-left font-danger': changes < 0,
                          'icon-arrow-up-right font-success ': changes >= 0
                        }"
                      ></i> 
                      {{ changes | number : "1.2-2" }}
                      %</span
                    >
                  </h6>
                </div>
                <div>{{ "info.since" | translate }} {{ unit | translate }}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>-->
    </div>
  </div>
</div>
