import { Component, Input, OnInit } from "@angular/core";
import { JwtService } from "../../services/jwt.service";
import { LocalStorageService } from "../../services/local-storage.service";

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
})
export class CardComponent implements OnInit {
  @Input() isLoading: boolean | null = true;
  @Input() hasMoreInfo!: boolean;
  @Input() moreInfoContent?: string;
  @Input() value!: number | string;
  @Input() hasChanges = true;
  @Input() changes!: number;
  @Input() growth!: number;
  @Input() hasGrowth = true;
  @Input() growthUnit = "";
  @Input() hasYesterdayGrowth!: boolean;
  @Input() growthYesterday!: number;
  @Input() growthYesterdayUnit = "";
  @Input() icon = "";
  @Input() title = "";
  @Input() unit = "";
  @Input() class = "";

  role: string;
  constructor(
    public jwtService: JwtService,
    private localStorageService: LocalStorageService
  ) {}
  ngOnInit(): void {
    const token = this.localStorageService.getItem("token");
    this.role = this.jwtService.getRoleName(token);
  }
}
