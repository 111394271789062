<div class="widget-joins widget-arrow" *ngIf="newAvailable.length != 0">
  <div
    class="widget-card rounded-2"
    style="padding: 16px 20px"
    *ngIf="this.title != undefined"
  >
    <div *ngFor="let item of newAvailable">
      <div class="card-header pb-0">
        <div class="media-space-between">
          <h5 class="text-cente m-b-10 m-r-10">
            {{ title }}
          </h5>
          <!--<h5 class="text-cente m-b-10 m-r-10" *ngIf="!isEditable">
        {{ title | translate }}
      </h5>
      <input
        *ngIf="isEditable"
        formControlName="fields"
        (blur)="updateField()"
        (keyup.enter)="updateField()"
        id="titleForm"
        class="form-control m-b-10"
      />-->
          <!--<a type="button" (click)="toggleEditMode()" class="m-b-10"
        ><i class="icon-pencil"></i>
      </a>-->
          <a
            draggable="true"
            type="button"
            class="trash-icon font-danger"
            color="red"
            style="margin-left: auto"
            (click)="removeItem(item)"
          >
            <app-feather-icon [icon]="'trash-2'"></app-feather-icon>
          </a>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="d-flex">
            <input
              style="padding: 0 10px"
              readonly
              type="text"
              [placeholder]="placeholder"
              class="form-control"
            />
            <!--<input
        *ngIf="isEditablePlaceholder"
        type="text"
        [placeholder]="placeholder"
        formControlName="placeholder"
        (blur)="updatePlaceholder()"
        (keyup.enter)="updatePlaceholder()"
        class="form-control"
      />-->
            <a
              type="button"
              style="width: 23px"
              (click)="toggleUpdate(updateField, item)"
              class="m-b-10 m-t-15 m-l-10"
              ><i class="icofont icofont-edit-alt"></i>
            </a>
          </div>
          <div class="checkbox-animated checkbox-position m-t-5">
            <label
              class="d-block"
              for="checkbox"
              style="margin-right: 34px; margin-bottom: 0"
            >
              <input
                class="checkbox_animated"
                id="checkbox"
                [checked]="required"
                (change)="saveRequired($event, item)"
                type="checkbox"
              />
              {{ "required" | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #updateField let-modal id="addCampaignModal">
  <div class="modal-header">
    <h4 style="margin-bottom: 0">{{ title }}</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="closeModal()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="d-flex">
      <div
        class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xxl-7 text-dark m-r-10"
        type="button"
      >
        <div class="widget-joins widget-arrow">
          <div class="border-box">
            <div class="tab-pane fade show active profile-overview">
              <ul
                ngbNav
                #nav="ngbNav"
                class="nav nav-tabs border-tab line"
                [(activeId)]="active"
                [destroyOnHide]="false"
              >
                <li [ngbNavItem]="1">
                  <a ngbNavLink>
                    <h5>{{ "leadGeneration.update.section1" | translate }}</h5>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="input-group">
                      <div class="col">
                        <label
                          class="form-label label"
                          for="exampleFormControlText"
                        >
                          {{ "leadGeneration.update.label" | translate }}
                        </label>
                        <input
                          class="form-control"
                          id="exampleFormControlText"
                          type="text"
                          required=""
                          [value]="title | translate"
                          (input)="onChangeLabel($event)"
                        />
                      </div>
                    </div>
                    <div class="input-group m-t-20">
                      <div class="col">
                        <label
                          class="form-label label"
                          for="exampleFormControlText"
                        >
                          {{ "leadGeneration.update.placeholder" | translate }}
                        </label>
                        <input
                          class="form-control"
                          id="exampleFormControlText"
                          type="text"
                          required=""
                          [value]="placeholder"
                          (input)="onchangePlaceholder($event)"
                        />
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2" [destroyOnHide]="true">
                  <a ngbNavLink>
                    <h5>{{ "leadGeneration.update.section2" | translate }}</h5>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="checkbox-animated">
                      <label class="d-block label" for="checkbox">
                        <input
                          class="checkbox_animated"
                          id="checkbox"
                          [checked]="required"
                          (change)="saveRequiredFromUpdate($event)"
                          type="checkbox"
                        />
                        {{ "required" | translate }}
                      </label>
                    </div>
                    <div class="col" *ngIf="required">
                      <input
                        class="form-control"
                        type="text"
                        [value]="formData['required']"
                        (input)="onInputChange($event, 'required')"
                      />
                    </div>
                    <div class="col">
                      <input
                        class="form-control m-t-20"
                        type="text"
                        [value]="formData['type']"
                        (input)="onInputChange($event, 'type')"
                      />
                    </div>
                  </ng-template>
                </li>
                <li
                  [ngbNavItem]="3"
                  [destroyOnHide]="true"
                  *ngIf="
                    type == customType.CHOIXMULTIPLE ||
                    type == customType.CASEACHOUCHER
                  "
                >
                  <a ngbNavLink>
                    <h5>{{ "leadGeneration.update.section3" | translate }}</h5>
                  </a>
                  <ng-template ngbNavContent>
                    <h5 style="color: #ff69b4" class="m-b-15">
                      {{
                        "leadGeneration.personalFields.addOption" | translate
                      }}
                      <a
                        type="button"
                        class="trash-icon font-danger"
                        color="red"
                        style="margin-left: auto"
                        (click)="ajouterOption()"
                      >
                        <i
                          class="icofont icofont-plus"
                          style="color: #ff69b4"
                        ></i>
                      </a>
                    </h5>
                    <div *ngFor="let item of values; let i = index">
                      <div
                        class="checkbox-animated media m-b-5"
                        *ngIf="type == customType.CHOIXMULTIPLE"
                      >
                        <input
                          class="checkbox_animated"
                          id="checkbox"
                          [checked]="false"
                          [type]="typeInterface"
                        />
                        <textarea
                          [value]="item"
                          style="font-size: 12px; min-width: 90%;"
                          (input)="adjustTextareaHeight()"
                          (input)="changeOptions($event, i)"
                          (blur)="toggleEdit(i)"
                          (keyup.enter)="toggleEdit(i)"
                          class="custom-textarea-input-case"
                          (focus)="selectTextareaText($event)"
                        >
                          {{ item | translate }}
                        </textarea>
                        <div style="margin-left: auto">
                          <a
                            type="button"
                            class="trash-icon font-danger"
                            color="red"
                            style="margin-left: 10px"
                            (click)="removeOption(i)"
                          >
                            <i class="icofont icofont-trash"></i>
                          </a>
                        </div>
                      </div>
                      <div
                        *ngIf="type == customType.CASEACHOUCHER"
                        class="radio-animated media m-b-5"
                      >
                        <input
                          class="radio_animated"
                          id="radio"
                          [checked]="false"
                          [type]="typeInterface"
                        />
                        <textarea
                          [value]="item"
                          style="font-size: 12px; min-width: 90%"
                          (input)="adjustTextareaHeight()"
                          (input)="changeOptions($event, i)"
                          (blur)="toggleEdit(i)"
                          class="custom-textarea-input-case"
                          (focus)="selectTextareaText($event)"
                        >
                          {{ item | translate }}
                        </textarea>
                        <div style="margin-left: auto">
                          <a
                            type="button"
                            class="trash-icon font-danger"
                            color="red"
                            style="margin-left: 10px"
                            (click)="removeOption(i)"
                          >
                            <i class="icofont icofont-trash"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
              </ul>
              <div class="card-body pt-0">
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5 text-dark"
        style="width: 40.89%"
        type="button"
      >
        <div class="widget-joins widget-arrow">
          <div class="border-box">
            <h4>{{ "leadGeneration.update.preview" | translate }}</h4>
          </div>
        </div>
        <div class="widget-joins widget-arrow">
          <div class="border-box border-top-0">
            <div class="input-group">
              <div class="col">
                <label
                  class="form-label label d-flex"
                  for="exampleFormControlText"
                >
                  {{ title | translate }}
                  <div *ngIf="required" style="color: red" class="m-l-3">*</div>
                </label>
                <input
                  *ngIf="placeholder == undefined; else exist"
                  class="form-control"
                  id="exampleFormControlText"
                  type="text"
                  required=""
                  [placeholder]="placeholder"
                />
                <ng-template #exist>
                  <input
                    class="form-control"
                    id="exampleForpmControlText"
                    type="text"
                    required=""
                    [placeholder]="placeholder"
                  />
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-primary" (click)="closeModal()">
      {{ "buttons.close" | translate }}
    </button>
    <button class="btn btn-primary" (click)="saveChanges(); closeModal()">
      {{ "buttons.confirm" | translate }}
    </button>
  </div>
</ng-template>
