import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysBetweenDates'
})
export class DaysBetweenDatesPipe implements PipeTransform {
  transform(startDate: Date | null, endDate: Date | null): number {
    const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
    var diffDays = 0;
    if(startDate && endDate)
      diffDays = Math.round(Math.abs((endDate.getTime() - startDate.getTime()) / oneDay)+1);
    return diffDays;
  }
}