import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";

import { AdminRoutingModule } from "./admin-routing.module";
import { SharedModule } from "src/app/shared/shared.module";
import { MainComponent } from "./main/main.component";
import { LocationPartnersComponent } from "./location-partners/location-partners.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { RouterComponent } from "./router/router.component";
import { CreateRouterStepperComponent } from "./router/create-router-stepper/create-router-stepper.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UpdateRouterComponent } from "./router/update-router/update-router.component";
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from "@danielmoncada/angular-datetime-picker";
import { DatepickerModule } from "ng2-datepicker";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ClipboardModule } from "ngx-clipboard";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { AdvertisersComponent } from "./advertisers/advertisers.component";
import { AdsCompaignsComponent } from "./ads-compaigns/ads-compaigns.component";
import { ConsumersComponent } from "./consumers/consumers.component";
import { LocationPartnerPreviewComponent } from "./main/location-partner-preview/location-partner-preview.component";
import { CreateLocationPartnerComponent } from "./location-partners/create-location-partner/create-location-partner.component";
import { ArchwizardModule } from "angular-archwizard";
import { PersonalInformationComponent } from "./location-partners/create-location-partner/personal-information/personal-information.component";
import { CompanyInformationComponent } from "./location-partners/create-location-partner/company-information/company-information.component";
import { LocationInformationComponent } from "./location-partners/create-location-partner/location-information/location-information.component";
import { CompanySpecificationComponent } from "./location-partners/create-location-partner/company-specification/company-specification.component";
import { NgxAbstractControlAsModule } from "ngx-abstract-control-as";
import { NgxMaskModule } from "ngx-mask";
import { LocationStatusComponent } from "./location-partners/create-location-partner/location-status/location-status.component";
import { GoogleMapsModule } from "@angular/google-maps";
import { AccessInformationComponent } from "./router/create-router/access-information/access-information.component";
import { RouterSpecificationComponent } from "./router/create-router/router-specification/router-specification.component";
import { CalendarComponent } from "./ads-compaigns/calendar/calendar.component";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { FlatpickrModule } from "angularx-flatpickr";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { ReleadRouterComponent } from "./router/create-router/router-specification/relead-router/relead-router.component";
import { FsiRouterComponent } from "./router/create-router/router-specification/fsi-router/fsi-router.component";
import { LocationsComponent } from "../../shared/components/locations/locations.component";
import { PaymentComponent } from "./location-partners/payment/payment.component";
import { CampagnesLPComponent } from "./location-partners/campagnes-lp/campagnes-lp.component";
import { CalculatorComponent } from "./ads-compaigns/calculator/calculator.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { ListRoutersComponent } from "./router/list-routers/list-routers.component";
import { DetailsRouterComponent } from "./router/list-routers/details-router/details-router.component";
import { UpdateLocationPartnerComponent } from "./location-partners/update-location-partner/update-location-partner.component";
import { UpdateCampaignLPComponent } from "./location-partners/campagnes-lp/update-campaign-lp/update-campaign-lp.component";
import { UpdateCampaignComponent } from "./ads-compaigns/update-campaign/update-campaign.component";
import { UpdateCampaignStatusComponent } from "./ads-compaigns/update-campaign-status/update-campaign-status.component";
import { PremiumAccessComponent } from "./location-partners/premium-access/premium-access.component";
import { UpdatePaymentStatusComponent } from "./location-partners/payment/update-payment-status/update-payment-status.component";
import { LocationStatisticsComponent } from "./location-partners/location-statistics/location-statistics.component";
import { LocationPartnerInformationComponent } from "./router/create-router/location-partner-information/location-partner-information.component";
import { EstimatesComponent } from "./advertisers/estimates/estimates.component";
import { InvoicesComponent } from "./advertisers/invoices/invoices.component";
import { AdvertiserBillingInfoComponent } from "./advertisers/update-advertiser/advertiser-billing-info/advertiser-billing-info.component";
import { AdvertiserCompanyInfoComponent } from "./advertisers/update-advertiser/advertiser-company-info/advertiser-company-info.component";
import { AdvertiserPersoInfoComponent } from "./advertisers/update-advertiser/advertiser-perso-info/advertiser-perso-info.component";
import { UpdateAdvertiserComponent } from "./advertisers/update-advertiser/update-advertiser.component";
import { UpdateAdvertiserActivationComponent } from "./advertisers/update-advertiser-activation/update-advertiser-activation.component";
import { DeleteAdvertiserComponent } from "./advertisers/delete-advertiser/delete-advertiser.component";
import { UpdateAdvertiserStatusComponent } from './advertisers/update-advertiser-status/update-advertiser-status.component';

@NgModule({
  declarations: [
    MainComponent,
    LocationPartnersComponent,
    RouterComponent,
    CreateRouterStepperComponent,
    UpdateRouterComponent,
    AdvertisersComponent,
    AdsCompaignsComponent,
    ConsumersComponent,
    LocationPartnerPreviewComponent,
    CreateLocationPartnerComponent,
    PersonalInformationComponent,
    CompanyInformationComponent,
    LocationInformationComponent,
    CompanySpecificationComponent,
    LocationStatusComponent,
    AccessInformationComponent,
    RouterSpecificationComponent,
    LocationPartnerInformationComponent,
    CalendarComponent,
    ReleadRouterComponent,
    FsiRouterComponent,
    LocationsComponent,
    PaymentComponent,
    CampagnesLPComponent,
    CalculatorComponent,
    ListRoutersComponent,
    DetailsRouterComponent,
    UpdateLocationPartnerComponent,
    UpdateCampaignLPComponent,
    UpdateCampaignComponent,
    UpdateCampaignStatusComponent,
    PremiumAccessComponent,
    UpdatePaymentStatusComponent,
    LocationStatisticsComponent,
    EstimatesComponent,
    InvoicesComponent,
    UpdateAdvertiserComponent,
    AdvertiserPersoInfoComponent,
    AdvertiserCompanyInfoComponent,
    AdvertiserBillingInfoComponent,
    UpdateAdvertiserActivationComponent,
    DeleteAdvertiserComponent,
    UpdateAdvertiserStatusComponent,
  ],
  imports: [
    AdminRoutingModule,
    SharedModule,
    NgApexchartsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DatepickerModule,
    ClipboardModule,
    NgxSliderModule,
    ArchwizardModule,
    NgxAbstractControlAsModule,
    NgxMaskModule.forRoot(),
    GoogleMapsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    FlatpickrModule.forRoot(),
    CarouselModule,
  ],
  providers: [],
  exports: [CalendarComponent, UpdateLocationPartnerComponent, SharedModule],
})
export class AdminModule {}
