export enum RenumerationEnum {
  RENUMERATED = "RENUMERATED",
  NOT_RENUMERATED = "NOT_RENUMERATED",
  PILOT_PHASE = "PILOT_PHASE"
}

export const RenumerationEnumMapping: Record<RenumerationEnum, string> = {
  [RenumerationEnum.RENUMERATED]: "enumMapping.renumeration.Renumerated",
  [RenumerationEnum.NOT_RENUMERATED]: "enumMapping.renumeration.NotRenumerated",
  [RenumerationEnum.PILOT_PHASE] : "enumMapping.renumeration.pilotPhase"
};
