import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { map, Observable, of, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AdDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-details.interface";
import {
  adDetailsSelector,
  getAdCompaignByHashedIdSelector,
} from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import { invokeAdDetails } from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { AppStateInterface } from "src/app/store/appState.interface";
import { ObjectiveTypeEnum } from "src/app/shared/enum/objectives";
import { HttpEvent, HttpEventType, HttpResponse } from "@angular/common/http";
import { uploadFileProgressSelector } from "src/app/store/file/file.selectors";
import { NgbProgressbarConfig } from "@ng-bootstrap/ng-bootstrap";
import {
  invokeUploadFileWithProgress,
  setFileName,
} from "src/app/store/file/file.actions";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
import { Constants } from "src/app/shared/data/constants";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { environment } from "src/environments/environment";
import { ExtensionService } from "src/app/shared/services/extension/extension.service";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-step2",
  templateUrl: "./step2.component.html",
  styleUrls: ["./step2.component.scss"],
})
export class Step2Component extends BaseComponent implements OnInit {
  //@Output("videoSrc") videoSrc = new EventEmitter<File>();

  @Input("objectiveName") objectiveName: ObjectiveTypeEnum;
  webTraficObjective: ObjectiveTypeEnum = ObjectiveTypeEnum.WEB_SITE_TRAFFIC;
  appPrpomotionObjective: ObjectiveTypeEnum =
    ObjectiveTypeEnum.APP_PROMOTION_AND_INSTALL;
  WebLinkFormControl: FormControl<any> = new FormControl(null, [
    Validators.required,
    Validators.minLength(3),
    Validators.pattern("^(https?|http)://.*"),
  ]);
  @Output("step2FormControl") step2FormControl = new EventEmitter<
    FormControl<any>
  >();
  invalidLink: boolean = false;

  @Input() webSite: string | null;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileName: EventEmitter<string> = new EventEmitter<string>();

  receivedWebTraffic: boolean = false;
  receivedVideoLink: any;
  fileSelected: File;

  showError: boolean = false;
  isShown: boolean = false;

  adDetails$: Observable<AdDetailsInterface>;

  uploadFileProgress$: Observable<HttpEvent<any> | null | HttpResponse<any>>;
  files: File[] = [];
  getAdDetails: AdDetailsInterface = initialState.adDetails;

  showProgress: boolean = false;
  uploadProgress: number = 0;
  showAlert: boolean = false;
  videoGuideLink = Constants.VIDEO_GUIDE_LINK;
  adByHashedId$: Observable<AdvertisingCampaignInterface | null>;
  videoLink: string;
  campaignHashedID: string;
  constructor(
    private store: Store<AppStateInterface>,
    configPB: NgbProgressbarConfig,
    private extensionService: ExtensionService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
  ) {
    super(store);
    configPB.max = 100;
    configPB.striped = true;
    configPB.animated = true;
    configPB.type = "primary";
    configPB.height = "20px";

    this.adDetails$ = this.store
      .pipe(select(adDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.uploadFileProgress$ = this.store
      .pipe(select(uploadFileProgressSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.adByHashedId$ = this.store
      .pipe(select(getAdCompaignByHashedIdSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    
    this.campaignHashedID =
      this.activatedRoute.snapshot.params["campaignHasedID"];
  }
  loadImage(): Observable<Blob> {
    return of(environment.CDN_RELEAD + this.videoLink).pipe(
      map((data) => {
        const blob = new Blob([data], {
          type: `video/${this.extensionService.getExtension(this.videoLink)}`,
        });
        return blob;
      })
    );
  }
  ngOnInit(): void {
    this.WebLinkFormControl.setValue(this.webSite);
    this.adByHashedId$.subscribe((data) => {
      if (data && this.campaignHashedID) {
        this.videoLink = data.videoLink;
        this.WebLinkFormControl.setValue(data.redirectionLink);
        if(data.videoLink && this.files.length == 0){
          this.loadImage().subscribe((i) => {
            const myFile = new File(
              [i],
              this.translate.instant("campaignDetails.video") + "-" + data.title,
              {
                type: i.type,
              }
            );
            this.files.push(myFile);
          });
        }
      }
    });
    this.step2FormControl.emit(this.WebLinkFormControl);
    this.uploadFileProgress$.subscribe(
      (data: HttpEvent<any> | HttpResponse<any> | null) => {
        if (data?.type == HttpEventType.UploadProgress) {
          this.showProgress = true;
          const total = data?.total ?? 1;
          this.uploadProgress = Math.round((data.loaded / total) * 100);
        }
        if (data?.type == HttpEventType.Response) {
          this.fileName.emit(
            (data as HttpResponse<{ filename: string }>)?.body?.filename ?? ""
          );
          this.store.dispatch(setFileName({ filename: null }));
          setTimeout(() => {
            this.showProgress = false;
          }, 2000);
        }
      }
    );
    this.WebLinkFormControl?.valueChanges.subscribe((value) => {
      this.valueChanged.emit(value);
      this.store.dispatch(
        invokeAdDetails({
          invokeAdDetails: { ...this.getAdDetails, webLink: value },
        })
      );
    });

    this.adDetails$.subscribe((result: AdDetailsInterface) => {
      this.getAdDetails = result;
      if (!this.isShown) this.onSelect([result.videoFile] as File[]);
    });
  }

  onSelect(event: File[]) {
    if (event[0]) {
      if (event[0].size > 5242880) {
        this.showError = true;
        this.showAlert = true;
      } else {
        this.files = [event[0]];
        this.isShown = true;
        this.store.dispatch(
          invokeAdDetails({
            invokeAdDetails: { ...this.getAdDetails, videoFile: event[0] },
          })
        );
        const formData = new FormData();
        formData.append("file", this.files[0], this.files[0].name);
        this.store.dispatch(invokeUploadFileWithProgress({ file: formData }));
      }
    }
  }

  onRemove(_t33: any) {
    this.files = [];
    this.store.dispatch(
      invokeAdDetails({
        invokeAdDetails: { ...this.getAdDetails, videoFile: this.files[0] },
      })
    );
  }
}
