import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../../data/constants";
import { ResultNextInvoiceNumber } from "../../models/iberis/facture/nextInvoiceNumber";
import { ResultFacturesIberis } from "../../models/iberis/facture/resultFacturesIberis";
import { MarkInvoice } from "../../models/iberis/markInvoice";
import { CommonParamsIberis } from "../../models/iberis/paramsForGetAll";

@Injectable({
  providedIn: "root",
})
export class FactureService {
  constructor(private http: HttpClient) {}
  getFacturesFromIberis(
    params: CommonParamsIberis,
    start: number, length: number , draw: number,
  ): Observable<ResultFacturesIberis> {
    let param = new HttpParams();
    param = param.append("start", start);
    param = param.append("length", length);
    param = param.append("draw", draw);
    return this.http.get<ResultFacturesIberis>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/sales/invoices?${param}`
    );
  }
  getNextInvoiceNumber(
    params: CommonParamsIberis
  ): Observable<ResultNextInvoiceNumber> {
    return this.http.get<ResultNextInvoiceNumber>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/sales/invoice/next`
    );
  }
  downloadInvoice(param: CommonParamsIberis, invoiceId: string) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/pdf",
        Accept: "application/pdf"
      })
    };
    return this.http.get(
      `${
        environment.APP_PORT_IBERIS +
        param.lang +
        Constants.IBERIS_END_POINT +
        param.companyId
      }/sales/invoice/${invoiceId}/download`, {observe : "response", responseType: 'blob'}
    );
  }
  getFacturesFromIberisForClient(
    params: CommonParamsIberis,
    start: number, length: number , draw: number,
    contactHashedId : string
  ): Observable<ResultFacturesIberis> {
    let param = new HttpParams();
    param = param.append("contactHashedId", contactHashedId);
    param = param.append("start", start);
    param = param.append("length", length);
    param = param.append("draw", draw);
    return this.http.get<ResultFacturesIberis>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/sales/invoices?${param}`
    );
  }

  markInvoice(
    params: CommonParamsIberis,
    invoiceId: string | null
  ): Observable<MarkInvoice> {
    return this.http.get<MarkInvoice>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/sales/invoice/${invoiceId}/mark
      `
    );
  }
}
