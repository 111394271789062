import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { catchError, map } from "rxjs";
import { AppStateInterface } from "src/app/store/appState.interface";
import { logoutSuccess, setAccount } from "src/app/store/auth/auth.actions";
import { JwtService } from "../jwt.service";

export const authGuard = (next: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const authService = inject(JwtService);
  const store = inject(Store<AppStateInterface>);
  authService.checkValidity().subscribe({
    next: (response) => {
      if (response) return true;

      store.dispatch(logoutSuccess({ logout: false, token: null }));
      store.dispatch(setAccount({ account: { role: null, user: null } }));
      localStorage.removeItem("token");
      router.navigate(["/", "auth", "login"]);
      return false;
    },
    error: (error) => {
      localStorage.removeItem("token");
      router.navigate(["/", "auth", "login"]);
      return false;
    },
  });
};
