import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from "@angular/core";
import * as feather from "feather-icons";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";
import { NavService } from "src/app/shared/services/nav.service";
import { LayoutService } from "src/app/shared/services/layout/layout.service";
import { slider } from "../../../data/animation/route-animations";
import { RoleEnum } from "src/app/shared/models/user/role.interface";
import { JwtService } from "src/app/shared/services/jwt.service";
import { Breadcrumb } from "src/app/shared/models/bread-crumb.interface";
import { select, Store } from "@ngrx/store";
import { AppStateInterface } from "src/app/store/appState.interface";
import { dashboardSettingsSelector } from "src/app/store/user/user.selectors";
import { Observable, takeUntil } from "rxjs";
import { DashboardSettingsInterface } from "src/app/shared/models/dashboard-settings.interface";
import { BaseComponent } from "src/app/base.component";
import { getDashboardSettings } from "src/app/store/user/user.actions";
import { NavBarInfo } from "src/app/shared/data/sibeBarInfo";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  animations: [slider],
})
export class DashboardComponent extends BaseComponent implements OnInit {
  @Output() icon = new EventEmitter<string>();
  footerDark: boolean;
  footerLight: boolean;
  footerFix: boolean;
  Scorlled: boolean;
  public show: boolean = true;
  public width = window.innerWidth;
  public screenwidth: any = window.innerWidth;
  breadcrumb: Breadcrumb = {
    title: "",
    items: [],
    active_item: "",
    icon: "",
  };
  dashBoardSettings$: Observable<DashboardSettingsInterface | null>;
  role: string;
  constructor(
    public navServices: NavService,
    public layout: LayoutService,
    public route: ActivatedRoute,
    private router: Router,
    private store: Store<AppStateInterface>,
    private jwtService: JwtService
  ) {
    super(store);
    this.dashBoardSettings$ = this.store
      .pipe(select(dashboardSettingsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    if (
      window.location.pathname == "/dashboard" &&
      localStorage.getItem("token") != null
    ) {
      const role = this.jwtService.getRoleName(localStorage.getItem("token"));
      switch (role) {
        case RoleEnum.ROLE_ADMIN: {
          this.router.navigate(["dashboard/admin"]);
          break;
        }
        case RoleEnum.ROLE_ADVERTISER: {
          this.router.navigate(["dashboard/advertiser"]);
          break;
        }
        case RoleEnum.ROLE_LOCATION: {
          this.router.navigate(["dashboard/location-partner"]);
          break;
        }
        default:
          this.router.navigate([""]);
      }
    }
    this.router.events.subscribe((data) => {
      this.breadcrumbConfig();
    });
    this.role = this.jwtService.getRoleName(this.jwtService.getToken());
  }

  ngOnInit(): void {
    this.store.dispatch(getDashboardSettings());

    this.dashBoardSettings$.subscribe((data) => {
      if (data != null) this.layout.config = data;
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.screenwidth = event.target.innerWidth;

    if (this.screenwidth < 991) {
      return (this.layout.config.settings.sidebar = "compact-wrapper");
    } else {
      let newLayout = this.layout.config.settings.sidebar;
      return (newLayout =
        this.layout.config.settings.sidebar || "horizontal-wrapper");
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  public getRouterOutletState(outlet: any) {
    return outlet.isActivated ? outlet.activatedRoute : "";
  }
  containsNumber(item: string): boolean {
    return /\d/.test(item);
  }
  breadcrumbConfig() {
    let subUrls = window.location.pathname
      .split("/")
      .filter((item) => !this.containsNumber(item));
    this.breadcrumb.title = this.capitalizeString([
      subUrls[subUrls.length - 1],
    ])[0];
    this.breadcrumb.active_item = this.capitalizeString([
      subUrls.pop() ?? "",
    ])[0];
    subUrls.shift();
    this.breadcrumb.items = this.capitalizeString(subUrls);
    if (this.breadcrumb.active_item == "Main") {
      this.breadcrumb.icon = "home";
    }
    switch (this.role) {
      case RoleEnum.ROLE_LOCATION: {
        NavBarInfo.locationPartner.map((e) => {
          if (e.title != undefined && this.breadcrumb.active_item) {
            if (
              e.title
                .toLowerCase()
                .includes(this.breadcrumb.active_item.toLowerCase())
            ) {
              this.breadcrumb.icon = e.icon;
            }
          }
        });
        break;
      }
      case RoleEnum.ROLE_ADVERTISER: {
        NavBarInfo.advertiser.map((e) => {
          if (e.title != undefined && this.breadcrumb.active_item) {
            if (
              e.title
                .toLowerCase()
                .includes(this.breadcrumb.active_item.toLowerCase())
            ) {
              this.breadcrumb.icon = e.icon;
            }
            if (
              this.breadcrumb.active_item == "Payment success" ||
              this.breadcrumb.active_item == "Payment cancel"
            ) {
              this.breadcrumb.icon = "project";
            }
          }
        });
        break;
      }
      case RoleEnum.ROLE_ADMIN: {
        NavBarInfo.admin.map((e) => {
          if (e.title != undefined && this.breadcrumb.items != undefined) {
            if (this.breadcrumb.active_item == "User history") {
              this.breadcrumb.icon = "project";
            }
            if (this.breadcrumb.active_item == "Consumers") {
              this.breadcrumb.icon = "users";
            }
            if (this.breadcrumb.active_item == "Main") {
              this.breadcrumb.icon = "home";
            }
            else if(this.breadcrumb.items.length>0){
              if (
                e.title
                  .toLowerCase()
                  .includes(
                    this.breadcrumb.items[
                      this.breadcrumb.items.length - 1
                    ].toLowerCase()
                  )
              ) {
                //
                this.breadcrumb.icon = e.icon;
              }
            }
          }
          if (this.breadcrumb.active_item == "breadcrumb.Main") {
            this.breadcrumb.icon = "home";
          }
        });
        break;
      }
    }
  }
  capitalizeString(item: string[] | undefined): string[] {
    return (
      item?.map(
        (str) =>
          str
            .replace(/-/g, " ") // replace all "-" with " "
            .replace(/^\w/, (c) => c.toUpperCase()) // capitalize the first letter
      ) ?? [""]
    );
  }
  ngDoCheck() {
    if (window.location.pathname == "/page-layout/footer-dark") {
      this.footerDark = true;
      this.footerLight = false;
      this.footerFix = false;
      this.Scorlled = false;
    } else if (window.location.pathname == "/page-layout/footer-light") {
      this.footerLight = true;
      this.footerDark = false;
      this.footerFix = false;
      this.Scorlled = false;
    } else if (window.location.pathname == "/page-layout/footer-fixed") {
      this.footerFix = true;
      this.footerLight = false;
      this.footerDark = false;
      this.Scorlled = false;
    } else if (window.location.pathname == "/page-layout/hide-nav-scroll") {
      this.Scorlled = true;
      this.footerFix = false;
      this.footerLight = false;
      this.footerDark = false;
    }
  }

  @HostListener("window:scroll", [])
  scrollHandler() {
    let number =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (window.location.pathname == "/page-layout/hide-nav-scroll") {
      if (number > 600) {
        this.show = true;
      } else if (number === 0) {
        this.show = true;
      } else {
        this.show = false;
      }
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData["animation"]
    );
  }
}
