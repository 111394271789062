<div class="row">
  <div *ngIf="loading">
    <ng-container style="float: right" class="flex">
      <div class="loader-box">
        <div class="loader-34"></div>
      </div>
      <h6 class="col align-self-center f-w-600" style="text-align: center">
        {{ "fetching" | translate }}
      </h6>
    </ng-container>
  </div>
  <div
    *ngIf="count == 0 && !loading"
    class="d-flex justify-content-center m-t-20"
  >
    {{ "noData" | translate }}
  </div>
  <ng-container *ngFor="let item of ad; let j = index">
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 box-col-4">
      <div class="card py-4" style="border-radius: 9px">
        <div class="card-body">
          <div class="media-space-between">
            <h4 class="f-w-600">{{ item.title }}</h4>
            <span
              [ngClass]="
                'badge ' +
                badgeService.getBadgeForAdvertisingCampaingStatus(item.status)
              "
            >
              {{ "enumMapping.campaigns.status." + item.status | translate }}
            </span>
          </div>
          <div class="row mb-4" style="margin-top: 15px">
            <div class="col-4"><p class="f-w-600">Objective :</p></div>
            <div class="col-8 mb-2">
              <p>
                <b>{{
                  "enumMapping.objectives." + item.objectiveName | translate
                }}</b>
              </p>
            </div>

            <div class="col-4">
              <p class="f-w-600">{{ "dashboard.vues" | translate }}</p>
            </div>
            <div class="col-8 mb-2">
              {{ item.views }}
            </div>

            <div class="col-4">
              <p class="f-w-600">
                {{ "campaignDetails.startDate" | translate }} :
              </p>
            </div>
            <div class="col-8 mb-2">
              {{ item.startingDate | localizedDate }}
            </div>

            <div class="col-4">
              <p class="f-w-600">
                {{ "campaignDetails.endDate" | translate }} :
              </p>
            </div>
            <div class="col-8 mb-2">{{ item.endingDate | localizedDate }}</div>

            <div class="col-4">
              <p class="f-w-600">
                {{ "campaignDetails.location" | translate }} :
              </p>
            </div>
            <div class="col-8">
              <owl-carousel-o
                class="main-scroll"
                [options]="owlcarouselOptions"
              >
                <ng-container *ngFor="let location of item.locationPartners">
                  <ng-template carouselSlide class="blocHorizontal">
                    <span
                      [ngClass]="'badge badge-light-info'"
                      style="margin-bottom: 5px; width: 190px"
                    >
                      {{ location }}
                    </span>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </div>

            <br />
          </div>
          <button
            class="btn btn-primary px-3"
            style="margin-left: auto; display: block"
            [routerLink]="[
              '/',
              'dashboard',
              'advertiser',
              'insights',
              'statistics',
              item.advertisingCampaignHashedId
            ]"
          >
            {{ "buttons.seeMore" | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
