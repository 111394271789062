import { createReducer, on } from "@ngrx/store";
import {
  deleteRouterSuccess,
  getRouterByMac,
  invokeRouterFilter,
  getRoutersSuccess,
  routerSuccessMessage,
  updateRouterSuccess,
  invokeCreateRouter,
  resultIsExistByMac,
} from "./router.action";
import { RouterState } from "./routerState.interface";

export const initialState: Readonly<RouterState> = {
  loading: false,
  routersFilter: {
    MAC: null,
    IP: null,
    status: [],
    companyName: null,
    startDatePurchase: null,
    endDatePurchase: null,
    startDateProduction: null,
    endDateProduction: null,
    sortName: null,
    isAscending: true,
    pager: {
      pageNumber: 5,
      pageSize: 1,
    },
    type: null,
  },
  routersResult: {
    count: 0,
    routers: [],
  },
  routerDetail: null,
  successMessage: null,
  updateSuccessMessage: null,
  isExist : null
};

export const RouterReducer = createReducer(
  initialState,
  on(invokeRouterFilter, (state, { routerFilter }) => ({
    ...state,
    routersFilter: routerFilter,
    loading : true
  })),
  on(getRoutersSuccess, (state, { routersResult }) => ({
    ...state,
    routersResult: routersResult,
    loading: false,
  })),
  on(getRouterByMac, (state, { routerDetail }) => ({
    ...state,
    routerDetail: routerDetail,
    loading: false,
  })),
  on(updateRouterSuccess, (state, { successMessage }) => ({
    ...state,
    updateSuccessMessage: successMessage,
    loading: false,
  })),
  on(invokeCreateRouter, (state, { createRouter }) => ({
    ...state,
    loading: true,
  })),
  on(routerSuccessMessage, (state, { successMessage }) => ({
    ...state,
    successMessage: successMessage,
    loading: false,
  })),
  on(deleteRouterSuccess, (state, { successMessage }) => ({
    ...state,
    successMessage: successMessage,
    loading: false,
  })),
  on(resultIsExistByMac , (state , {isExist})=>({
    ...state,
    isExist : isExist
  }))
);
