<div class="container-fluid">
  <div class="page-title">
    <div class="col-sm-12">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active">
          <div [innerHTML]="sanitizedSvgContent"></div>
        </li>
        <li class="breadcrumb-item" *ngFor="let item of items">{{ ("breadcrumb." + item) | translate }}</li>
        <li class="breadcrumb-item active">{{("breadcrumb." +active_item) | translate}}</li>
      </ol>
    </div>
    <!--<div class="row">
      <div class="col-sm-6">
        <h3>{{ title }}</h3>
      </div>
    </div>-->
  </div>
</div>
