<div class="col-12">
  <div class="row">
    <ng-container>
      <app-welcome-card
        [result]="resultUniqueConsumers"
        [loading$]="isLoading"
        [firstName]="(profile$ | async)?.firstName"
        [lastName]="(profile$ | async)?.lastName"
      ></app-welcome-card>
      <div class="col-md-4 col-xl-3 col-sm-6">
        <app-card
          [unit]="adsCard.unit"
          [title]="'dashboard.ads' | translate"
          [icon]="adsCard.icon"
          [value]="adsCard.value"
          [class]="'eye'"
          [isLoading]="isLoading"
        >
        </app-card>
      </div>
      <div class="col-md-4 col-xl-3 col-sm-6">
        <app-card
          [title]="'dashboard.vues' | translate"
          [unit]="impression.unit"
          [icon]="impression.icon"
          [value]="impression.value"
          [class]="'eye'"
          [isLoading]="isLoading"
        >
        </app-card>
      </div>
      <div class="col-md-4 col-xl-3 col-sm-6">
        <app-card
          [title]="'dashboard.completed' | translate"
          [unit]="completedVideos.unit"
          [icon]="completedVideos.icon"
          [value]="completedVideos.value"
          [class]="'eye'"
          [isLoading]="isLoading"
        >
        </app-card>
      </div>
      <div class="col-md-4 col-xl-3 col-sm-6">
        <app-card
          [title]="'dashboard.click' | translate"
          [unit]="clicks.unit"
          [icon]="clicks.icon"
          [value]="clicks.value"
          [class]="'eye'"
          [isLoading]="isLoading"
        >
        </app-card>
      </div>
    </ng-container>
    <app-help [type]="'advertiser'" [link]="bookMeeting"></app-help>
    <!--meilleur resultat-->
    <app-best-advertising-campaign
      *ngIf="
        isExistAdvertisingCampaign;
      "
    ></app-best-advertising-campaign>
    <div *ngIf="!isExistAdvertisingCampaign">
      <div class="card">
        <div class="card-body m-auto">
          <img
            src="assets/identity/noCampaign.svg"
            alt="noCampaign"
            class="d-flex m-auto my-3"
          />
          <h2 class="div-center mb-3">
            {{"dashboard.noAdvertising.title" | translate}}
          </h2>
          <p class="div-center">{{"dashboard.noAdvertising.part1" | translate}}</p>
          <p class="div-center">{{"dashboard.noAdvertising.part2" | translate}}</p>
          <button
            class="btn btn-primary d-flex m-auto"
            [routerLink]="['../advertising-campaigns/create-ad']"
          >
            {{"buttons.promote" | translate}}
          </button>
        </div>
      </div>
    </div>
    <div class="dartboard" style="position: fixed; top: 25rem; z-index: 2500">
      <div class="dartboard-button-secondary col-5 card-hover">
        <p class="m-b-0" >
          <a type="button" style="color: #ff69b4" class="font-a" [href]="blogLink" target="_blank">
            {{ "dashboard.dartboard.link" | translate }}
          </a>
          {{ "dashboard.dartboard.text" | translate }}
        </p>
      </div>
      <img
        class="img-dartboard col-1"
        src="assets/identity/dartboard.svg"
        alt="dartboard"
      />
    </div>
  </div>
</div>
<!-- latest lanched advertising campaigns -->
<app-table-latest-campaign
  (isExist)="isExistCampaign($event)"
></app-table-latest-campaign>
