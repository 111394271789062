export enum CampaignStatusEnum {
  WAITING = "WAITING",
  ONGOING = "ONGOING",
  FINISHED = "FINISHED",
  DRAFT = "DRAFT",
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
  AWAITING_APPROVAL = "AWAITING_APPROVAL"
}

export const CampaignStatusEnumMapping: Record<CampaignStatusEnum, string> = {
  [CampaignStatusEnum.WAITING]: "enumMapping.campaigns.status.filter.WAITING",
  [CampaignStatusEnum.ONGOING]: "enumMapping.campaigns.status.filter.ONGOING",
  [CampaignStatusEnum.FINISHED]: "enumMapping.campaigns.status.filter.FINISHED",
  [CampaignStatusEnum.DRAFT]: "enumMapping.campaigns.status.filter.DRAFT",
  [CampaignStatusEnum.AWAITING_PAYMENT] : "enumMapping.campaigns.status.filter.AWAITING_PAYMENT",
  [CampaignStatusEnum.AWAITING_APPROVAL] : "enumMapping.campaigns.status.filter.AWAITING_APPROVAL"
};

export const CommandeStatusForADEnumMapping: Record<CampaignStatusEnum, string> = {
  [CampaignStatusEnum.WAITING]: "enumMapping.campaigns.status.filter.PAID",
  [CampaignStatusEnum.ONGOING]: "enumMapping.campaigns.status.filter.ONGOING",
  [CampaignStatusEnum.FINISHED]: "enumMapping.campaigns.status.filter.FINISHED",
  [CampaignStatusEnum.DRAFT]: "enumMapping.campaigns.status.filter.ORDER",
  [CampaignStatusEnum.AWAITING_PAYMENT] : "enumMapping.campaigns.status.filter.AWAITING_PAYMENT",
  [CampaignStatusEnum.AWAITING_APPROVAL] : "enumMapping.campaigns.status.filter.AWAITING_APPROVAL"
};

export interface CampaignStatusInterface {
  id: CampaignStatusEnum;
  value: string;
}
