import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { BadgeService } from "src/app/shared/services/badge.service"
import { environment } from "src/environments/environment";

@Component({
  selector: "app-details-ad",
  templateUrl: "./details-ad.component.html",
})
export class DetailsAdComponent implements OnInit {
  @Input() detailsAd: AdvertisingCampaignInterface;
  cdnURL = environment.CDN_RELEAD;
  constructor(
    public badgeService: BadgeService,
    private modalService: NgbModal,
  ) {
  }
  ngOnInit(): void {
  }
  closeModal() {
    this.modalService.dismissAll();
  }
}
