import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdsCompaignsComponent } from "./ads-compaigns/ads-compaigns.component";
import { AdvertisersComponent } from "./advertisers/advertisers.component";
import { CalendarComponent } from "./ads-compaigns/calendar/calendar.component";
import { ConsumersComponent } from "./consumers/consumers.component";
import { LocationPartnersComponent } from "./location-partners/location-partners.component";
import { MainComponent } from "./main/main.component";
import { CreateRouterStepperComponent } from "./router/create-router-stepper/create-router-stepper.component";
import { RouterComponent } from "./router/router.component";
import { PaymentComponent } from "./location-partners/payment/payment.component";
import { CampagnesLPComponent } from "./location-partners/campagnes-lp/campagnes-lp.component";
import { CalculatorComponent } from "./ads-compaigns/calculator/calculator.component";
import { BugReportComponent } from "src/app/shared/components/bug-report/bug-report.component";
import { AdStatisticsComponent } from "../advertiser/insights/ad-statistics/ad-statistics.component";
import { LocationStatisticsComponent } from "./location-partners/location-statistics/location-statistics.component";
import { DetailsDiscutionSupportComponent } from "src/app/shared/components/bug-report/details-discution-support/details-discution-support.component";
import { ProfileComponent } from "src/app/shared/components/profile/profile.component";
import { EstimatesComponent } from "./advertisers/estimates/estimates.component";
import { InvoicesComponent } from "./advertisers/invoices/invoices.component";
import { LoggerComponent } from "./logger/logger.component";

var routingAnimation = localStorage.getItem("animate");

const routes: Routes = [
  {
    path: "",
    redirectTo: "main",
    pathMatch: "full",
  },
  {
    path: "main",
    component: MainComponent,
    data: {
      animation: [routingAnimation],
      title: "Main page | Relead ",
      description: " ",
    },
  },
  {
    path: "location-partners/repository",
    component: LocationPartnersComponent,
    data: {
      animation: [routingAnimation],
      title: "Location partners list | Relead ",
    },
  },
  {
    path: "location-partners/repository/statistics",
    component: LocationStatisticsComponent,
    data: {
      animation: [routingAnimation],
      title: "Location partner statistics | Relead ",
      queryParams: {
        title: "companyName",
      },
    },
  },
  {
    path: "location-partners/payment",
    component: PaymentComponent,
    data: {
      animation: [routingAnimation],
      title: "payments list | Relead ",
    },
  },
  {
    path: "location-partners/campagnes",
    component: CampagnesLPComponent,
    data: {
      animation: [routingAnimation],
      title: "Location partners campaigns list | Relead ",
    },
  },
  {
    path: "location-partners/campagnes/statistics/:campaignHasedID",
    component: AdStatisticsComponent,
    data: {
      animation: [routingAnimation],
    },
  },
  {
    path: "routers/:status",
    component: RouterComponent,
    data: {
      animation: [routingAnimation],
      title: "Routers list | Relead ",
    },
  },
  {
    path: "routers/create",
    component: CreateRouterStepperComponent,
  },
  {
    path: "routers/update/:mac",
    component: CreateRouterStepperComponent,
  },
  {
    path: "consumers",
    component: ConsumersComponent,
    data: {
      animation: [routingAnimation],
      title: "Consumers | Relead ",
    },
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./settings/admin-settings.module").then(
        (m) => m.AdminSettingsModule
      ),
  },
  {
    path: "advertisers/repository",
    component: AdvertisersComponent,
    data: {
      animation: [routingAnimation],
      title: "Advertisers list | Relead ",
    },
  },
  {
    path: "advertisers/commandes",
    component: EstimatesComponent,
    data: {
      animation: [routingAnimation],
      title: "Estimates list | Relead ",
    },
  },
  {
    path: "advertisers/factures",
    component: InvoicesComponent,
    data: {
      animation: [routingAnimation],
      title: "Invoices list | Relead ",
    },
  },
  {
    path: "campaigns/pipeline",
    component: AdsCompaignsComponent,
    data: {
      animation: [routingAnimation],
      title: "Advertising campaigns list | Relead ",
    },
  },
  {
    path: "campaigns/pipeline/statistics/:campaignHasedID",
    component: AdStatisticsComponent,
    data: {
      animation: [routingAnimation],
    },
  },
  {
    path: "campaigns/simulateur",
    component: CalculatorComponent,
    data: {
      animation: [routingAnimation],
      title: "Calculator | Relead ",
    },
  },
  {
    path: "campaigns/calendar",
    component: CalendarComponent,
    data: {
      animation: [routingAnimation],
      title: "Calendar | Relead ",
    },
  },
  {
    path: "support/:status",
    component: BugReportComponent,
    data: {
      title: "Support | Relead ",
    },
  },
  {
    path: "support-details",
    component: DetailsDiscutionSupportComponent,
    data: {
      queryParams: {
        ref: "ref",
      },
      animation: [routingAnimation],
      title: "See the details of the discussion | Relead  ",
    },
  },
  {
    path: "profile",
    component: ProfileComponent,
    data: {
      animation: [routingAnimation],
      title: "Company information | Relead ",
    },
  },
  {
    path: "user-history",
    component: LoggerComponent,
    data: {
      animation: [routingAnimation],
      title: "User History | Relead ",
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
