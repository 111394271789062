<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div class="d-flex justify-content-center">
            <div
              class="img-contour d-flex justify-content-center align-items-center"
            >
              <img
                src="assets/identity/logo-footer.png"
                width="60"
                height="60"
                alt="icon relead"
              />
            </div>
          </div>
          <form class="theme-form login-form">
            <h4 style="text-align: center">Créez votre compte</h4>
            <h6 style="text-align: center">Bienvenue à Relead !</h6>

            <div class="card-body">
              <ng-container class="flex" *ngIf="result == enumeration.LOADING">
                <div class="loader-box">
                  <div class="loader-34"></div>
                </div>
                <h6
                  class="col align-self-center f-w-600"
                  style="text-align: center"
                >
                  {{ "fetching" | translate }}
                </h6>
              </ng-container>
              <ng-container *ngIf="result == enumeration.VALIDATED">
                <div class="d-flex justify-content-center pb-4">
                  <img
                    src="assets/identity/get-started.png"
                    alt=""
                    class="img-130"
                  />
                </div>

                <h5 style="text-align: center">
                  Félicitations ! Votre compte a été vérifié avec succès. Il ne
                  reste plus qu'à attendre la validation de l'administrateur.
                </h5>
              </ng-container>
              <ng-container *ngIf="result == enumeration.NOT_VALIDATED">
                <div class="d-flex justify-content-center pb-4">
                  <img src="assets/identity/oops.png" alt="" class="img-100" />
                </div>

                <h5 style="text-align: center">
                  Votre clé d'activation n'est pas valide, veuillez régénérer
                  votre clé.
                </h5>
                <div
                  class="d-flex justify-content-center align-items-center"
                >
                  <button
                    type="button"
                    class="btn btn-primary"
                    (click)="regenerateToken()"
                  >
                    Régénerer
                  </button>
                </div>
              </ng-container>
              <ng-container *ngIf="result == enumeration.NOT_FOUND">
                <div class="d-flex justify-content-center pb-4">
                  <img src="assets/identity/oops.png" alt="" class="img-100" />
                </div>

                <h5 style="text-align: center">
                  Ce clé d'activation n'existe pas!
                </h5>
              </ng-container>
              <ng-container *ngIf="result == enumeration.REGENERATED">
                <div class="d-flex justify-content-center pb-4">
                  <img
                    src="assets/identity/contact-us.png"
                    alt=""
                    class="img-110"
                  />
                </div>

                <h5 style="text-align: center">
                  Consultez l'E-mail de validation que vous avez reçu.
                </h5>
              </ng-container>
              <ng-container *ngIf="result == enumeration.CANT_REGENERATE">
                <div class="d-flex justify-content-center pb-4">
                  <img
                    src="assets/identity/oops.png"
                    alt=""
                    class="img-100"
                  />
                </div>

                <h5 style="text-align: center">
                  Désolé, il n'est pas possible de régénérer cette clé. Veuillez
                  contacter l'administration pour obtenir de l'aide.
                </h5>
              </ng-container>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
