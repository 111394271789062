import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const consumersState = (state: AppStateInterface) =>
  state.consumersState;



export const resultDeviceTypeSelector = createSelector(
  consumersState,
  (state) => state.deviceType
);
export const resultDeviceBrandSelector = createSelector(
  consumersState,
  (state) => state.deviceBrand
);
export const resultOsFamilySelector = createSelector(
  consumersState,
  (state) => state.osFamily
);
export const resultBrowserFamilySelector = createSelector(
  consumersState,
  (state) => state.browserFamily
);

export const countConsumersBetweenDatesSelector = createSelector(
  consumersState,
  (state) => state.consumerCountByDate
);
export const countConsumersForAdminSelector = createSelector(
  consumersState,
  (state) => state.consumersLoading
);
export const consumersGenderDetailsSelector = createSelector(
  consumersState,
  (state) => state.genderStatistics
);
export const consumerDemographiqueDetailsSelector = createSelector(
  consumersState,
  (state) => state.genderAgeStatics
);
export const consumerAgeRangeDetailsSelector = createSelector(
  consumersState,
  (state) => state.ageStatics
);
export const loadingConsumerSelector = createSelector(
  consumersState,
  (state) => state.loading
)

export const loadingGenderSelector = createSelector(
  consumersState,
  (state) => state.loadingGender
)