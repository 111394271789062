import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AdvertiserDashboard } from "../models/advertiser/advertiser-dashboard.interface";
import { AdvertiserDetailsInterface } from "../models/advertiser/profile.interface";
import { Constants } from "../data/constants";
import { Observable } from "rxjs";
import { Advertiser } from "../models/advertiser/advertiser.interface";
import { SuccessDto } from "../models/success-dto";
import { ResultFilterAdvertiser } from "../models/advertiser/result-filter-advertiser.interface";
import { FilterListAdvertiser } from "../models/advertiser/filter-list-advertiser.interface";
import { subRole } from "../models/sub-role/subRole";
import { ApproveDeactivateAdvertiser } from "../models/advertiser/approve-deactivate-advertiser.interface";

@Injectable({
  providedIn: "root",
})
export class AdvertiserService {
  
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };
  constructor(private http: HttpClient) {}

  getMainDashboard() {
    return this.http.get<AdvertiserDashboard>(
      `${environment.APP_PORT + Constants.ADVERTISER_ENDPOINT}/main`
    );
  }

  getProfileDetails() {
    return this.http.get<AdvertiserDetailsInterface>(
      `${environment.APP_PORT + Constants.ADVERTISER_ENDPOINT}/info`
    );
  }

  register(register: Advertiser): Observable<SuccessDto> {
    return this.http.post<SuccessDto>(
      `${environment.APP_PORT + Constants.ADVERTISER_ENDPOINT}/register`,
      register
    );
  }
  getAdvertiserByFilter(filter: FilterListAdvertiser): Observable<ResultFilterAdvertiser>{
    return this.http.post<ResultFilterAdvertiser>(
      `${
        environment.APP_PORT + Constants.ADVERTISER_ENDPOINT
      }/filter`,
      filter
    )
  }
  updateAdvertiserStatus(dto : ApproveDeactivateAdvertiser | null): Observable<SuccessDto>{
    return this.http.patch<SuccessDto>(
      `${
        environment.APP_PORT + Constants.ADVERTISER_ENDPOINT
      }/status`, dto
    )
  }
  

  confirmEmailForRegistration(token : string): Observable<any>{
    return this.http.post<any>(
      `${
        environment.APP_PORT + Constants.ADVERTISER_ENDPOINT
      }/confirm-registration/` + token, null
    )
  }
  regenerateConfirmRegestration(token: string): Observable<SuccessDto> {
    return this.http.post<SuccessDto>(
      `${
        environment.APP_PORT + Constants.ADVERTISER_ENDPOINT
      }/regenerate-confirm-registration/` + token,null
  )
  }
  advertiserByAdHashedId(campaignHashedId : string): Observable<AdvertiserDetailsInterface>{
    return this.http.get<AdvertiserDetailsInterface>(
      `${
        environment.APP_PORT + Constants.ADVERTISER_ENDPOINT
      }/publisher/details/campaignHashedId/${campaignHashedId}`
  )}
  deleteAdvertiser(email : string) : Observable<SuccessDto>{
    return this.http.delete<SuccessDto>(
      `${
        environment.APP_PORT + Constants.ADVERTISER_ENDPOINT
      }/${email}`
    )
  }
  updateAdvertiser(email : string, advertiser: AdvertiserDetailsInterface) : Observable<SuccessDto>{
    return this.http.patch<SuccessDto>(
      `${
        environment.APP_PORT + Constants.ADVERTISER_ENDPOINT
      }/profile/email/${email}`,advertiser
    )
  }
  updatesRoleForAdvertiser(email : string, subRole: subRole) : Observable<SuccessDto>{
    return this.http.patch<SuccessDto>(
      `${
        environment.APP_PORT + Constants.ADVERTISER_ENDPOINT
      }/updateSubRole/${email}`,subRole
    )
  }
  getIdIberis() : Observable<string>{
    return this.http.get(
      `${
        environment.APP_PORT + Constants.ADVERTISER_ENDPOINT
      }/ClientId`, {responseType: 'text'}
    )
  }

  getActivatedAdvertisers() : Observable<AdvertiserDetailsInterface[] | null>{
    return this.http.get<AdvertiserDetailsInterface[] | null>(
      `${
        environment.APP_PORT + Constants.ADVERTISER_ENDPOINT
      }/allActivatedAdvertisers`,
    )
  }
}
