import { Pipe, PipeTransform } from "@angular/core";
import { DecimalPipe } from "@angular/common";

@Pipe({
  name: "toKValue",
})
export class ToKValue implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}
  transform(num: number | string): string {
    if (typeof num === "number") {
      if (num >= 1000) {
        return this.decimalPipe.transform(num / 1000, "1.0-1") + "K";
      } else {
        if (Number(num.toFixed(0)) - num != 0) return num.toFixed(2).toString();
        else return num.toString();
      }
    }else {
      return num;
    }
  }
}
