<form [formGroup]="addForm" class="media">
  <input
    *ngIf="type != null"
    type="text"
    formControlName="title"
    placeholder="Saisir un titre "
    class="form-control"
    [value]="titleField"
    (ngModelChange)="onChangeSaveOption()"
    style="margin-right: 10px"
  />
  <div
    class="card-body animate-chk form-control border-0"
    *ngIf="type === typeFildSelected.CASEACHOUCHER"
  >
    <div class="row m-l-15">
      <div class="media m-t-5" *ngFor="let option of options; let i = index">
        <input
          type="radio"
          id="edo-ani"
          type="radio"
          name="rdo-ani"
          class="radio_animated"
        />
        <textarea
          (click)="toggleEditModeRadio(i)"
          [value]="formRadio[i.toString()] | translate"
          style="font-size: 12px"
          (input)="adjustTextareaHeight()"
          (input)="onInputChange($event, i.toString())"
          class="custom-textarea-input-case"
          (focus)="selectTextareaText($event)"
        >
          {{ formRadio[i.toString()] | translate }}
        </textarea>
        <div style="margin-left: auto">
          <a
            type="button"
            class="font-danger font-primary"
            color="red"
            (click)="addOption(i)"
          >
            <i class="icofont icofont-plus"></i>
          </a>
          <a
            type="button"
            class="trash-icon font-danger"
            color="red"
            style="margin-left: 10px"
            (click)="removeOption(i.toString())"
          >
            <i class="icofont icofont-trash"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div
    class="card-body animate-chk form-control border-0"
    *ngIf="type === typeFildSelected.CHOIXMULTIPLE"
  >
    <div class="row m-l-15">
      <div
        class="media m-t-5"
        *ngFor="let option of multichoiseOptions; let i = index"
      >
        <input
          class="checkbox_animated"
          id="chk-ani"
          type="checkbox"
          name="chk-ani"
        />
        <textarea
          (click)="toggleEditModeCheckbox(i)"
          [value]="formDataCheckbox[i.toString()] | translate"
          style="font-size: 12px"
          (input)="adjustTextareaHeight()"
          (input)="onInputChangeChekbox($event, i.toString())"
          class="custom-textarea-input-case"
          (focus)="selectTextareaText($event)"
        >
          {{ formRadio[i.toString()] | translate }}
        </textarea>
        <div style="margin-left: auto">
          <a
            type="button"
            class="font-danger font-primary"
            (click)="addcheck(i)"
          >
            <i class="icofont icofont-plus"></i>
          </a>
          <a
            type="button"
            class="trash-icon font-danger"
            title="Ajouter une option"
            color="red"
            style="margin-left: 10px"
            (click)="removeCheck(i.toString())"
          >
            <i class="icofont icofont-trash"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</form>
