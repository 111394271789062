<div class="modal-header">
  <div>
    <h4 class="modal-title" id="exampleModalLabel">
      {{ "admin.campaigns.simulator.selectLocations" | translate }}
    </h4>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="onClickDismissModal()"
  ></button>
</div>
<div class="modal-body">
  <div class="media-space-between m-b-10">
    <div class="form-check checkbox checkbox-primary mb-0">
      <input
        class="form-check-input m-r-10"
        id="checkbox-primary-2"
        [checked]="selectedLps.length == totalLPs"
        (click)="selectAllRegions($event)"
        type="checkbox"
      />
      <label class="form-check-label label" for="checkbox-primary-2">{{
        "admin.campaigns.simulator.selectAllLocations" | translate
      }}</label>
    </div>
    <div class="col-xl-5 col-5">
      <div class="faq-form" style="color: darkgrey">
        <i class="search-icon" data-feather="search"></i>
        <div>
          <div class="input-group">
            <input
              id="table-complete-search"
              type="text"
              class="form-control"
              [placeholder]="'placeholder.searchByCompanyName' | translate"
              [(ngModel)]="lp"
              (input)="searchTerm($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngFor="let region of regionNames; let i = index" class="row m-t-10">
    <div class="form-check checkbox checkbox-primary mb-0">
        <input
          class="form-check-input m-r-10"
          [id]="region"
          [checked]="checkRegionSelectedAll(locationPartnersByRegion[i])"
          (click)="saveAllRegionLps($event, region)"
          type="checkbox"
        />
        <label class="form-check-label label" [for]="region"><h6 class="f-w-600">
          {{ region }}
        </h6></label>
      
    </div>
    <div *ngFor="let lp of locationPartnersByRegion[i]" class="col-4">
      <div class="form-check checkbox checkbox-primary mb-0">
        <input
          class="form-check-input m-r-10"
          [id]="lp"
          [checked]="selectedLps.includes(lp)"
          (change)="saveLPs($event, lp)"
          type="checkbox"
        />
        <label class="form-check-label label" [for]="lp">{{ lp }}</label>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="submitLps()">
    {{ "buttons.confirm" | translate }}
  </button>
</div>
