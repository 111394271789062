<div class="card">
  <div class="card-header row">
    <form [formGroup]="formFilter" style="width: 100%">
      <div class="media-space-between">
        <h4>{{ "inseights.title" | translate }}</h4>
        <div class="media-space-between">
          <div style="margin-left: 5px">
            <div
              class="input-group date"
              id="dt-date"
              data-target-input="nearest"
            >
              <div
                class="input-group-text bg-primary"
                data-target="#dt-date"
                [owlDateTimeTrigger]="dt"
              >
                <i
                  class="icofont icofont-ui-calendar"
                  style="color: white; font-size: 12px"
                ></i>
              </div>
              <input
                style="width: 14rem"
                formControlName="date"
                class="form-control datetimepicker-input digits"
                type="text"
                name="daterange"
                [placeholder]="'placehorderPickDate' | translate"
                [selectMode]="'range'"
                [owlDateTimeTrigger]="dt"
                [owlDateTime]="dt"
              />
            </div>
            <owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>
          </div>
          <div
            class="w-auto faq-form mx-2"
            style="color: darkgrey; display: flex"
          >
            <div class="input-group">
              <input
                style="width: 16rem"
                id="table-complete-search"
                type="text"
                class="form-control"
                name="searchByTitle"
                [placeholder]="'placeholder.searchByCampaignTitle' | translate"
                formControlName="title"
              />
              <span
                class="input-group-text p-0"
                style="background-color: transparent"
              >
                <app-general-filter
                  [filter]="filterAds"
                  (optionSelected)="resultOptionSelected($event)"
                >
                </app-general-filter>
              </span>
            </div>
          </div>
          <ul
            ngbNav
            #navDisplay="ngbNav"
            [activeId]="1"
            class="nav-border-primary d-flex justify-content-center"
            style="justify-content: right; display: contents"
          >
            <li [ngbNavItem]="1" [destroyOnHide]="true">
              <a ngbNavLink type="button" style="padding: 4px 10px">
                <img src="assets/identity/table.svg" alt="table" width="20px" />
              </a>
              <ng-template ngbNavContent>
                <div class="table-responsive data-table">
                  <table class="table table-striped table-border-vertical">
                    <thead>
                      <tr>
                        <ng-container *ngFor="let column of columns">
                          <th
                            sortable
                            [class]="{ sortable: column.sorter }"
                            sortable="{{ column.key }}"
                            (sort)="column.sorter ? onSort($event) : null"
                          >
                            {{ column.label | translate }}
                          </th>
                        </ng-container>
                        <th>{{ "dashboard.options" | translate }}</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="!(loadingAds$ | async)">
                      <ng-container>
                        <tr
                          *ngFor="
                            let adCompaign of resultAdvertisingCompainFilter;
                            let j = index
                          "
                        >
                          <td>
                            <a
                              type="button"
                              [routerLink]="[
                                '/',
                                'dashboard',
                                'advertiser',
                                'insights',
                                'statistics',
                                adCompaign.advertisingCampaignHashedId
                              ]"
                              style="color: black"
                            >
                              <td>
                                {{ adCompaign.title }}
                              </td>
                            </a>
                          </td>
                          <td class="img-content-box">
                            <i class="icon-eye"></i>
                            {{ adCompaign.views }}
                          </td>
                          <td>
                            {{
                              objectiveTypeEnumMapping[adCompaign.objectiveName]
                                | translate
                            }}
                          </td>
                          <td width="20%">
                            <app-column-location-partners
                              [j]="j"
                              [locationPartners]="adCompaign.locationPartners"
                            ></app-column-location-partners>
                          </td>
                          <td>
                            {{ adCompaign.startingDate | localizedDate }}
                          </td>
                          <td>
                            {{ adCompaign.endingDate | localizedDate }}
                          </td>
                          <td>
                            <span
                              [ngClass]="
                                'badge ' +
                                badgeService.getBadgeForAdvertisingCampaingStatus(
                                  adCompaign.status
                                )
                              "
                            >
                              {{
                                "enumMapping.campaigns.status." +
                                  adCompaign.status | translate
                              }}
                            </span>
                          </td>
                          <td>
                            <app-drob-zone-options
                              [options]="getOptions(adCompaign)"
                              (optionSelected)="saveAction($event, adCompaign)"
                            ></app-drob-zone-options>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                  <div *ngIf="loadingAds$ | async">
                    <ng-container style="float: right" class="flex">
                      <div class="loader-box">
                        <div class="loader-34"></div>
                      </div>
                      <h6
                        class="col align-self-center f-w-600"
                        style="text-align: center"
                      >
                        {{ "fetching" | translate }}
                      </h6>
                    </ng-container>
                  </div>
                  <div
                    *ngIf="count == 0 && !(loadingAds$ | async)"
                    class="d-flex justify-content-center m-t-20"
                  >
                    {{ "noData" | translate }}
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="2" [destroyOnHide]="true">
              <a ngbNavLink type="button" style="padding: 4px 10px">
                <img src="assets/identity/card.svg" alt="card" width="20px" />
              </a>
              <ng-template ngbNavContent>
                <app-card-insights
                  [count] = "count"
                  [loading]="loadingAds$| async"
                  [ad]="resultAdvertisingCompainFilter"
                ></app-card-insights>
              </ng-template>
            </li>
          </ul>
        </div>
      </div>
    </form>
    <div style="display: flex; justify-content: end">
      <button
        class="btn btn-primary m-r-5 m-t-10"
        type="button"
        (click)="removeFilter()"
      >
        <i class="fa fa-repeat me-2"></i>
        {{ "buttons.removeFilter" | translate }}
      </button>
    </div>
    <div class="m-t-10">
      <div [ngbNavOutlet]="navDisplay" class="mt-2"></div>
      <!--all advertising compaign-->
      <div class="table-responsive data-table">
        <form>
          <div class="d-flex">
            <div class="col-3"></div>
            <div class="col-6">
              <div class="d-flex justify-content-center p-3">
                <ngb-pagination
                  [collectionSize]="
                    (resultFilterAdvertisingCompaign$ | async)?.count ?? 0
                  "
                  [(page)]="advertisingCompaignFilter.pager.pageNumber"
                  [pageSize]="advertisingCompaignFilter.pager.pageSize"
                  [rotate]="true"
                  [boundaryLinks]="true"
                  (pageChange)="changePage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <div class="col-3">
              <div class="d-flex justify-content-end p-3">
                <select
                  class="form-select"
                  style="width: auto"
                  name="pageSize"
                  [(ngModel)]="changePageSize"
                >
                  <option [ngValue]="5">
                    5 {{ "pagination.itemsPerPage" | translate }}
                  </option>
                  <option [ngValue]="10">
                    10 {{ "pagination.itemsPerPage" | translate }}
                  </option>
                  <option [ngValue]="15">
                    15 {{ "pagination.itemsPerPage" | translate }}
                  </option>
                  <option [ngValue]="20">
                    20 {{ "pagination.itemsPerPage" | translate }}
                  </option>
                  <option
                    [ngValue]="
                      (resultFilterAdvertisingCompaign$ | async)?.count ?? 0
                    "
                  >
                    {{ "pagination.allItems" | translate }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
