<form [formGroup]="form">
  <!-- ... nested form controls ... -->
  <h5 style="text-align: left"> {{"addLP.thirdStep.title" | translate}} </h5>

  <div class="form-group">
    <label class="label">{{"addLP.thirdStep.category" | translate}}*</label>
    <div class="input-group">
      <select
        class="form-select form-control"
        formControlName="category"
        (change)="onChangeCategory()"
      >
        <option [ngValue]="null" disabled selected> {{"addLP.thirdStep.selectCategory" | translate}} </option>
        <option *ngFor="let item of allCategoryNames$ | async" [ngValue]="item">
          {{ item }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group" *ngIf="(subCategoriesByCategory$ | async)">
    <label class="label">{{"addLP.thirdStep.subCategories" | translate}}*</label>
      <ng-select
        formControlName="subCategories"
        [items]="subCategoriesByCategory$ | async"
        bindLabel="tags"
        bindValue="id"
        [multiple]="true"
        (clear)="true"
        [placeholder]=" 'addLP.thirdStep.selectSubCategories' | translate "
      >
      </ng-select>
  </div>

  <div class="form-group">
    <label class="label">{{"addLP.thirdStep.tags" | translate}}</label>
    <ng-select
      formControlName="tags"
      [items]="allTagNames$ | async"
      bindLabel="tags"
      bindValue="id"
      [multiple]="true"
      (clear)="true"
      [placeholder]=" 'addLP.thirdStep.selectTags' | translate"
    >
    </ng-select>
  </div>
</form>
