import { createReducer, on } from "@ngrx/store";
import { invokeListOrdersFromIberis, invokeListOrdersFromIberisByStatus, invokeListOrdersFromIberisByStatusForSpecificAdvertiser, invokeListOrdersFromIberisForSpecificAdvertiser, resultCreateEstimate, resultEstimate, resultInvoiceEstimate, resultListOrdersFromIberis,resultMarkEstimate, resultSynthesisEstimate, resultUpdateEstimate} from "./commande.actions";
import { CommandeInterface } from "./commande.interface";
export const initialState: Readonly<CommandeInterface> = {
  resultListOrdersFromIberis : null,
  loading : false,
  estimate : null,
  resultCreateEStimate : null, 
  resultInvoiceEstimate : null,
  markEstimate : null,
  resultSynthesisEstimate : null,
  resultUpdateEstimate : null,
};
export const CommandeReducer = createReducer(
  initialState,
  on(invokeListOrdersFromIberis, (state , {params , start, length, draw})=>({
    ...state,
    loading : true
  })),
  on(invokeListOrdersFromIberisForSpecificAdvertiser , (state,{params ,contactHashedId, start, length, draw})=>({
    ...state,
    loading : true
  })),
  on(invokeListOrdersFromIberisByStatus, (state , {params ,status, start, length, draw})=>({
    ...state,
    loading : true
  })),
  on(invokeListOrdersFromIberisByStatusForSpecificAdvertiser, (state , {params ,contactHashedId ,status, start, length, draw})=>({
    ...state,
    loading : true
  })),
  
  on(resultListOrdersFromIberis , (state, {resultListOrdersFromIberis}) =>({
    ...state,
    resultListOrdersFromIberis : resultListOrdersFromIberis,
    loading : false
  })),
  on(resultEstimate, (state, {estimate})=>({
    ...state,
    estimate: estimate,
    loading : false
  })),
  on(resultCreateEstimate, (state, {resultCreateEStimate})=>({
    ...state,
    resultCreateEStimate : resultCreateEStimate,
    loading : false
  })),
  on(resultUpdateEstimate, (state, {resultUpdateEstimate})=>({
    ...state,
    resultUpdateEstimate : resultUpdateEstimate,
    loading : false
  })),
  on(resultInvoiceEstimate, (state ,{resultInvoiceEstimate})=>({
    ...state ,
    resultInvoiceEstimate : resultInvoiceEstimate,
    loading : false
  })),
  on(resultMarkEstimate, (state , {markEstimate})=>({
    ...state,
    markEstimate : markEstimate
  })),
  on(resultSynthesisEstimate,(state , {resultSynthesisEstimate})=>({
    ...state,
    resultSynthesisEstimate : resultSynthesisEstimate
  }))
);
