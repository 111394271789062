<google-map
  #mapElement
  id="map"
  [options]="mapOptions"
  height="400px"
  width="500px"
>
  <map-marker
    #marker="mapMarker"
    [options]="markerOptions"
    [position]="markerPosition"
    (mapMouseout)="changeMarkerPosition(marker)"
  >
  </map-marker>
</google-map>
