<div class="modal-header justify-content-start">
  <h4 *ngIf="modalTitle; else default">
    {{ modalTitle | translate }}
  </h4>
  <ng-template #default>
    <h4 class="modal-title" id="exampleModalLabel">
      {{ "actions.upload" | translate }}
    </h4>
  </ng-template>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal()"
  ></button>
</div>
<div class="modal-body">
  <ngx-dropzone
    [multiple]="false"
    (change)="onSelect($event.addedFiles)"
    [accept]= accept
  >
    <ngx-dropzone-label>
      <div>
        <div class="dz-message needsclick">
          <i class="icon-cloud-up"></i>
          <p style="color: black">
            {{ "dropzoneFile.title" | translate }}
          </p>
          <p class="dz-message needsclick" style="color: black">
            {{ "dropzoneFile.rulePart1" | translate }}
            <strong [ngClass]="{ 'txt-danger': showError }"
              >{{ "dropzoneFile.rulePart2" | translate }} 4MB</strong
            >
          </p>
        </div>
      </div>
    </ngx-dropzone-label>
    <ngx-dropzone-preview
      *ngFor="let f of files"
      [removable]="true"
      [file]="f"
      [attr.data-type]="f.type"
      (removed)="onRemove()"
    >
    <ngx-dropzone-label style="align-self: flex-end; margin: 0;">
      <div class="ligne-top"></div>
      <div class="new-dropdown-label">
        {{ f.name.split(".")[0] | castFileName }} .{{ f.type.split("/")[1] }}
      </div>
    </ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ngx-dropzone>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-primary" (click)="closeModal()">
    {{ "buttons.close" | translate }}
  </button>
  <button
    class="btn btn-primary"
    (click)="onClickSaveSynthesisReport()"
    [disabled]="filename == null"
  >
    {{ "buttons.submit" | translate }}
  </button>
</div>
