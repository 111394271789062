import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { NgxDropzoneChangeEvent } from "ngx-dropzone";
import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from "ngx-image-cropper";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeUploadFile } from "src/app/store/file/file.actions";

@Component({
  selector: "app-update-picture",
  templateUrl: "./update-picture.component.html",
})
export class UpdatePictureComponent extends BaseComponent implements OnInit {
  @ViewChild("fileInput") fileInput: ElementRef;

  @Output() closeTheModal: EventEmitter<boolean> = new EventEmitter();
  imageChangedEvent: any = "";
  croppedImage: File;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  maxSize: Dimensions = { height: 500, width: 1000 };

  @Input() isUploaded: boolean = false;
  startCropImage = new EventEmitter<void>();

  constructor(private store: Store<AppStateInterface>) {
    super(store);
  }

  ngOnInit() {}

  savePicture() {
    this.startCropImage.emit();
    const formData = new FormData();
    formData.append("files", this.croppedImage as File, this.croppedImage.name);
    this.store.dispatch(invokeUploadFile({ files: formData }));
  }

  downloadPicture() {
    this.startCropImage.emit();
    const url = window.URL.createObjectURL(this.croppedImage);
    const link = document.createElement("a");
    link.href = url;
    link.download = this.imageChangedEvent.target.files[0].name;
    link.click();
    window.URL.revokeObjectURL(url);
  }
  selectFile(event: NgxDropzoneChangeEvent) {
    const createFile = (file = event.addedFiles[0]) => file;

    const createFileList = (file: File) => {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      return dataTransfer.files;
    };

    const fileList = createFileList(createFile());
    this.fileInput.nativeElement.files = fileList;
    this.fileInput.nativeElement.dispatchEvent(new Event("change"));
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.isUploaded = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = new File(
      [event.blob ?? new Blob()],
      this.imageChangedEvent.target.files[0].name
    );
  }

  imageLoaded(event: any) {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {}

  loadImageFailed() {}

  closeModal() {
    this.closeTheModal.emit(true);
  }
}
