import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../data/constants";
import {
  AllSubCategoryInterface,
  CreateUpdateSubCategoryInterface,
} from "../models/sub-category/sub-category";
import { SuccessDto } from "../models/success-dto";

@Injectable({
  providedIn: "root",
})
export class SubCategoryService {
  constructor(private http: HttpClient) {}
  getAllSubCategoryNames(): Observable<string[]> {
    return this.http.get<string[]>(
      `${
        environment.APP_PORT + Constants.SUB_CATEGORY_ENDPOINT
      }/allSubCategoryNames`
    );
  }

  getSubCategoriesByCategoryName(categoryName: string): Observable<string[]> {
    return this.http.get<string[]>(
      `${
        environment.APP_PORT + Constants.SUB_CATEGORY_ENDPOINT
      }/getByCategoryName/` + categoryName
    );
  }

  getAllSubCategories(): Observable<AllSubCategoryInterface[]> {
    return this.http.get<AllSubCategoryInterface[]>(
      `${environment.APP_PORT + Constants.SUB_CATEGORY_ENDPOINT}/allSubCategories`
    );
  }

  createSubCategory(
    category: CreateUpdateSubCategoryInterface
  ): Observable<SuccessDto> {
    return this.http.post<SuccessDto>(
      `${environment.APP_PORT + Constants.SUB_CATEGORY_ENDPOINT}/create`,
      category
    );
  }

  updateSubCategory(
    subCategory: CreateUpdateSubCategoryInterface,
    oldName: string
  ): Observable<SuccessDto> {
    return this.http.patch<SuccessDto>(
      `${
        environment.APP_PORT + Constants.SUB_CATEGORY_ENDPOINT + "/" + oldName
      }`,
      subCategory
    );
  }

  deleteSubCategory(subCategoryName: string): Observable<SuccessDto> {
    return this.http.delete<SuccessDto>(
      `${environment.APP_PORT + Constants.SUB_CATEGORY_ENDPOINT}/` +
        subCategoryName
    );
  }
}
