import { state } from "@angular/animations";
import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const advertiserState = (state: AppStateInterface) =>
  state.advertiserState;

export const advertiserMainDashbordSelector = createSelector(
  advertiserState,
  (state) => state.dashboard
);

export const advertiserProfileDetailsSelector = createSelector(
  advertiserState,
  (state) => state.profileDetails
);
export const advertiserLoadingSelector = createSelector(
  advertiserState,
  (state) => state.loading
);
export const loadingFilterSelector = createSelector(
  advertiserState,
  (state) => state.loadingFilter
);
export const advertiserSuccessRegister = createSelector(
  advertiserState,
  (state) => state.registerSuccess
);

export const resultUpdateAdvertiserSlector = createSelector(
  advertiserState,
  (state) => state.editProfileSuccessMessage
);
export const advertisserAccountSelector = createSelector(
  advertiserState,
  (state) => state.account
);
export const filterAdvertiserSelector = createSelector(
  advertiserState,
  (state) => state.filter
);
export const resultFilterAdvertiserSelector = createSelector(
  advertiserState,
  (state) => state.resultFilterAdvertiser
);

export const confirmEmailSelectorSuccess = createSelector(
  advertiserState,
  (state) => state.confirmEmailRegistrationSuccess
);
export const confirmEmailSelectorError = createSelector(
  advertiserState,
  (state) => state.confirmEmailRegistrationFailed
);

export const regenerateConfirmRegestrationSuccessSelector = createSelector(
  advertiserState,
  (state) => state.regenerateTokenSuccess
);
export const regenerateConfirmRegestrationFailedSelector = createSelector(
  advertiserState,
  (state) => state.regenerateTokenFailed
);

export const advertiserByAdHashedIdSelector = createSelector(
  advertiserState,
  (state) => state.profileDetails
);
export const resultDeleteAdvertiserSelector = createSelector(
  advertiserState,
  (state) => state.resultDeleteAdvertiser
);

export const selectedAdvertiserSelector = createSelector(
  advertiserState,
  (state) => state.selectedAdvertiser
);
export const resultUpdateSubRoleForAdvertiserSelector = createSelector(
  advertiserState,
  (state) => state.successMessage
);
export const resultIdIberisSelector = createSelector(
  advertiserState,
  (state) => state.idIberis
);

export const saveFieldsLeadGenerationSelector = createSelector(
  advertiserState,
  (state) => state.fieldLeadGeneration
);

export const saveLeadGenerationSelector = createSelector(
  advertiserState,
  (state) => state.saveFormLeadGeneration
);

export const saveNewFieldsLeadGenerationSelector = createSelector(
  advertiserState,
  (state) => state.newFieldsLeadGeneration
);

export const approveAdvertiserSelector = createSelector(
  advertiserState,
  (state) => state.resultActivateDesactivate
);

export const loadingApproveAdvertiserSelector = createSelector(
  advertiserState,
  (state) => state.loadingApprove
);

export const invokeLoadingMainSelector = createSelector(
  advertiserState,
  (state) => state.loadingMain
);

export const advertisersSelector = createSelector(
  advertiserState,
  (state) => state.publishers
);

export const createAdvertiserSelector = createSelector(
  advertiserState,
  (state) => state.registerSuccess
)