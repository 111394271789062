import { createAction, props } from "@ngrx/store";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import { LeadGeneration } from "src/app/shared/models/leadGeneration/leadGeneration";
import { SendFiles } from "src/app/shared/models/send-files.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";

export const invokeSendLeadGenerationForm = createAction(
  "[Invoke Sending mail to the admin ] Invoke sending mail to the admin",
  props<{ leadGeneration: LeadGeneration | null}>()
);
export const resultSindingMail = createAction(
    "[ Result Sending Mail To The Admin ] Result sending mail to the admin",
    props<{ successMessage: SuccessDto | null }>()
)
export const invokeSendRequestToDownloadLead = createAction(
  "[Invoke Sending mail to the admin to notify them that an advertiser wants to download this lead generation form] Invoke Sending mail to the admin to notify them that an advertiser wants to download this lead generation form",
  props<{advertiser : AdvertiserDetailsInterface}>()
)
export const invokeSendFilesToUser = createAction(
  "[Invoke Sending email to the user that includes the downloaded files] Invoke Send an email to the user that includes the downloaded files",
  props<{sendFiles : SendFiles[] | null, title : string | null}>()
)
export const invokeNotifyAdminWhenAdvertisingCampaignCreated = createAction(
  "[ Invoke Notify Admin When Advertising Campaign Created ] Invoke notify admin when advertising campaign created",
  props<{title : string , email : string}>()
)
export const invokeNotifyAdminWhenAdvertiserCreated = createAction(
  "[ Invoke Notify Admin When Advertiser Created ] Invoke notify admin when advertiser created",
  props<{email : string , firstName : string , lastName :string}>()
)
export const invokeCampaignStarts = createAction(
  "[ Invoke Send an email to the advertiser when the admin mark his ad campaign as ongoing ] Invoke send an email to the advertiser when the admin mark his ad campaign as ongoing",
  props<{email : string}>()
)
export const invokeMarkAdAsFinished = createAction(
  "[ Invoke Send an email to the advertiser when the admin mark his ad campaign as finished ] Invoke send an email to the advertiser when the admin mark his ad campaign as finished",
  props<{email : string}>()
)

export const invokeActivateAdvertiserAccount = createAction(
  "[ Invoke Send an email to the advertiser when the admin approve his ad ] invoke Send an email to the advertiser when the admin approve his ad",
  props<{email : string}>()
)