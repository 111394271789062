<form [formGroup]="form">
  <div class="form-group">
    <label class="label"> {{"routers.supplier" | translate}} *</label>
    <div class="input-group">
      <select
        class="form-select form-control digits"
        formControlName="supplier"
      >
        <option [ngValue]="null" disabled selected>
          {{"addRouter.routerSpecificationStep.placeholderSupplier" | translate}}
        </option>
        <option
          class="form-select"
          *ngFor="let status of internetProviverStatus"
          [value]="status.id"
        >
          {{ status.value }}
        </option>
      </select>
    </div>
  </div>
</form>
