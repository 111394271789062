import { Component, Input, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { IColumn } from "src/app/shared/models/table/column.interface";
import {
  advertisingCampaignSelector,
  loadingAdvertisingCampaignTableSelector,
} from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeLatestAdvertisingCampaigns } from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import {
  RenumerationEnum,
  RenumerationEnumMapping,
} from "src/app/shared/enum/renumeration.enum";
import { BadgeService } from "src/app/shared/services/badge.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { OwlOptions } from "ngx-owl-carousel-o";
import {
  getUniqueViewsByListAds,
  invokeUniqueViewsByAdTitleForLP,
} from "src/app/store/statistics/statistics.actions";
import {
  listUniqueViewsSelector,
  loadingStatisticsSelector,
} from "src/app/store/statistics/statistics.selectors";
import { PricingInterface } from "src/app/shared/models/location-partners/pricing";
import { pricingByCompanyNameSelector, resultLPInfoSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { VideoAdComponent } from "src/app/shared/components/video-ad/video-ad.component";
import { PricingMethod } from "src/app/shared/enum/pricing-method";
import { PaymentFrequency } from "src/app/shared/enum/payment-frequency";
import { JwtService } from "src/app/shared/services/jwt.service";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { invokePricingByCompanyName } from "src/app/store/locationPartner/location-partner.action";
import { invokeLPInfo, resultLPInfo } from "src/app/store/locationPartner/actions/get-locations.actions";
import { LPInfo } from "src/app/shared/models/location-partners/lpInfo";
import { PartnerStatus } from "src/app/shared/enum/partner-status";

@Component({
  selector: "app-latest-ad",
  templateUrl: "./latest-ad.component.html",
})
export class LatestAdComponent extends BaseComponent implements OnInit {
  renumerationEnumMapping = RenumerationEnumMapping;
  @Input("companyName") companyName: Observable<string>;
  loading$: Observable<boolean | null>;
  columns: IColumn[] = [
    {
      key: "nomCampagne",
      label: "campaignDetails.campaignName",
      sorter: true,
    },
    {
      key: "Impressions",
      label: "campaignDetails.impressions",
      sorter: true,
    },
    {
      key: "Vues uniques",
      label: "campaignDetails.uniqueViews",
      sorter: true,
    },
    {
      key: "Statut",
      label: "campaignDetails.status",
      sorter: true,
    },
    {
      key: "Date de début",
      label: "campaignDetails.startDate",
      sorter: true,
    },
    {
      key: "Date de fin",
      label: "campaignDetails.endDate",
      sorter: false,
    },
    {
      key: "Rémunération",
      label: "campaignDetails.remuniration",
      sorter: false,
    },
    {
      key: "Montant de Rémunération",
      label: "campaignDetails.remunirationAmount",
      sorter: false,
    },
  ];
  advertisingCampaing$: Observable<AdvertisingCampaignInterface[] | null>;
  advertisingCampaing: AdvertisingCampaignInterface[] = [];
  titles: string[] = [];

  listAdvertisingCampaignsUniqueViews$: Observable<number[] | null>;
  listAdvertisingCampaignsUniqueViews: number[] = [];

  pricing$: Observable<PricingInterface | null>;
  pricing: PricingInterface = {
    price: 0,
    pricingMethod: PricingMethod.NONE,
    paymentFrequency: PaymentFrequency.NONE,
    pilotPhaseEndDate: new Date(),
  };

  videoLink: string;
  LPcompanyName: string;
  owlcarouselOptions: OwlOptions = {
    loop: false,
    nav: false,
    autoWidth: true,
    margin: 195,
    responsive: {
      0: {
        items: 1,
      },
      50: {
        items: 1,
        margin: 65,
      },
      150: {
        items: 2,
        margin: 125,
      },
    },
  };
  loadingUniqueViews$: Observable<boolean | null>;
  currentLocationPartner$ : Observable<LPInfo | null>
  currentLP : LPInfo | null
  renumerationEnum = RenumerationEnumMapping
  constructor(
    private store: Store<AppStateInterface>,
    public badgeService: BadgeService,
    private modalService: NgbModal,
    private jwtService: JwtService
  ) {
    super(store);
    this.advertisingCampaing$ = this.store
      .pipe(select(advertisingCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.listAdvertisingCampaignsUniqueViews$ = this.store
      .pipe(select(listUniqueViewsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.pricing$ = this.store
      .pipe(select(pricingByCompanyNameSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingAdvertisingCampaignTableSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loadingUniqueViews$ = this.store
      .pipe(select(loadingStatisticsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.currentLocationPartner$ = this.store
      .pipe(select(resultLPInfoSelector))
      .pipe(takeUntil(this.ngDestroyed$))
  }

  ngOnInit(): void {
    this.store.dispatch(resultLPInfo({lpInfo : null}))
    this.currentLocationPartner$.subscribe((result)=>{
      if(result){
        this.currentLP = result
      }else{
        this.store.dispatch(invokeLPInfo())
      }
    })
    this.store.dispatch(getUniqueViewsByListAds({ uniqueViews: null }));
    this.companyName.subscribe((data) => {
      if (data != null) {
        this.LPcompanyName = data;
        this.store.dispatch(invokePricingByCompanyName({ companyName: data }));
      }
    });
    this.advertisingCampaing$.subscribe(
      (result: AdvertisingCampaignInterface[] | null) => {
        if (result != null) {
          this.advertisingCampaing = result;
          this.titles = result?.map((value) => value.advertisingCampaignHashedId) ?? [];
          this.store.dispatch(
            invokeUniqueViewsByAdTitleForLP({
              titles: this.titles,
            })
          );
        }
      }
    );
    this.listAdvertisingCampaignsUniqueViews$.subscribe((views) => {
      if (views) {
        this.listAdvertisingCampaignsUniqueViews = views;
      }
    });
    this.pricing$.subscribe((pricing: PricingInterface | null) => {
      if (pricing) {
        this.pricing = pricing;
        this.store.dispatch(invokeLatestAdvertisingCampaigns());
      }
    });
  }
  onClickshowModal(videoLink: string) {
    const modalRef = this.modalService.open(VideoAdComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.closeTheModal.subscribe((value: boolean) =>
      modalRef.close()
    );
    modalRef.componentInstance.videoLink = environment.CDN_RELEAD + videoLink;
  }

  getRenumeration(ad: AdvertisingCampaignInterface): RenumerationEnum {
    if(this.currentLP?.partnerStatus == PartnerStatus.INTERNET_PROVIDER_PAYMENT){
      return RenumerationEnum.NOT_RENUMERATED
    }
    else{
      if (ad.startingDate && this.pricing.pilotPhaseEndDate) {
        if (this.pricing.pilotPhaseEndDate > ad.startingDate)
          return RenumerationEnum.PILOT_PHASE;
        else return RenumerationEnum.RENUMERATED;
      }
      return RenumerationEnum.NOT_RENUMERATED;
    }
  }
}
