import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, withLatestFrom } from "rxjs";
import { setAPIStatus } from "../apiState.interface";
import { select, Store } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";
import { FixedChargeService } from "../../shared/services/fixed-charge.service";
import {
  getAllFixedCharges,
  invokeAllFixedCharges,
  invokeDeleteFixedCharge,
  invokeUpdateFixedCharge,
  crudFixedChargeSuccess,
  invokeCreateFixedCharge,
} from "./fixed-charge.actions";
import { FixedChargeInterface } from "../../shared/models/fixed-charges/fixed-charges.interface";
import { SuccessDto } from "../../shared/models/success-dto";

@Injectable()
export class FixedChargeEffect {
  constructor(
    private actions$: Actions,
    private store: Store<AppStateInterface>,
    private fixedChargeService: FixedChargeService
  ) {}

  invokeGetAllFixedCharge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAllFixedCharges),
      mergeMap((state) => {
        return this.fixedChargeService.getAllFixedCharges().pipe(
          map((data: FixedChargeInterface[] | null) =>
            getAllFixedCharges({ fixedCharges: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeCreateFixedCharge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCreateFixedCharge),
      mergeMap((state) => {
        return this.fixedChargeService
          .createFixedCharge(state.fixedCharge)
          .pipe(
            map((data: SuccessDto) =>
              crudFixedChargeSuccess({ message: data.message })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeUpdateFixedCharge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateFixedCharge),
      mergeMap((state) => {
        return this.fixedChargeService
          .updateFixedCharge(state.name, state.fixedCharge)
          .pipe(
            map((data: SuccessDto) =>
              crudFixedChargeSuccess({ message: data.message })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeDeleteFixedCharge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeDeleteFixedCharge),
      mergeMap((state) => {
        return this.fixedChargeService
          .deleteFixedCharge(state.fixedChargeName)
          .pipe(
            map((data: SuccessDto) =>
              crudFixedChargeSuccess({ message: data.message })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
}
