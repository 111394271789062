<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
      <div class="card profile-greeting" >
        <div class="card-body">
          <div class="media">
            <div class="media-body">
              <div class="greeting-user">
                <h2 class="m-b-15" *ngIf="role != Role.ROLE_ADMIN ; else other">{{ "welcome" | translate }}, {{firstName}} {{lastName}} </h2>
                <ng-template #other>
                  <h1>{{ "welcome" | translate }}, admin </h1>
                </ng-template>
                <p style="width: 58%">
                  {{ welcomeCardData.description | translate }}
                </p>
                <button
                class="btn btn-outline-white_color m-0 m-t-15"
                  *ngIf="welcomeCardData.buttonName != null"
                  [routerLink]="[welcomeCardData.path]"
                >{{welcomeCardData.buttonName | translate }}</button>
              </div>
            </div>
          </div>
          <div class="cartoon-img">
            <img class="img-fluid" [src]="welcomeCardData.img" alt="Relead" width="300px"/>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
      <div class="card pb-0 o-hidden earning-card" >
        <div class="card-header earning-back"></div>
        <div class="card-body">
          <div class="earning-content">
            <img
              class="rounded-circle"
              [src]="welcomeCardData.iconCardStatistique"
              alt=""
            />
            <h5 class="f-w-600">
              {{ welcomeCardData.titleCardStatistique | translate }}
            </h5>
            <div *ngIf="loading$ ; else loadingFalse">
              <div class="loader-box">
                <div class="loader-34"></div>
              </div>
            </div>
            <ng-template #loadingFalse>
              <h6>{{ result | toKValue}}</h6>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
</div>
