import { ApexLegend } from "ng-apexcharts";

export class Style {
  public static browserFamilyRepartitionLegend: ApexLegend = {
    customLegendItems: [
      "chrome",
      "safari",
      "edge",
      "brave",
      "opera",
      "firefox",
      "other",
    ],
    position: "bottom",
  };
  public static browserFamily : any = [
    "Chrome",
    "Safari",
    "Edge",
    "Brave",
    "Opera",
    "Firefox",
    "Other",
  ]
  public static browserDeviceRepartitionLegend: ApexLegend = {
    customLegendItems: [
      "bot",
      "mobile",
      "pc",
      "tablet",
      "other",
    ],
    position: "bottom",
  };
  public static browserDevice : string[] = [
    "Bot",
    "Mobile",
    "PC",
    "Charts.browserDevice.tablet",
    "Charts.browserDevice.other",
  ]
  public static ageRange = [
    'Charts.ageRange.inf18',
    'Charts.ageRange.between18And25',
    'Charts.ageRange.between26and35',
    'Charts.ageRange.between36and45',
    'Charts.ageRange.sup46',
  ];
  public static osFamily: string[] = [
    'Linux',
    'Windows',
    'IOS',
    'Android',
    'Other',
  ];
  public static deviceBrand: any =[
    'Apple',
    'Huawei',
    'Infinix',
    'Nokia',
    'OPPO',
    'Sumsung',
    'Xiaomi',
    'Teckno',
    'iko',
    'Itel',
    'MicroMax',
    'Condor',
    'Other'
  ] 
  public static gender = [
    'Charts.male',
    'Charts.female',
  ];
}
