import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const billingsState = (state: AppStateInterface) =>
  state.billingsState;

export const billingSelector = createSelector(
    billingsState,
  (state) => state.billingInfo
);

export const loadingBillingSelector = createSelector(
    billingsState,
  (state) => state.loading
);

export const messagseBillingSelector = createSelector(
    billingsState,
  (state) => state.successMessage
);
