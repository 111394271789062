import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, delay, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { SortColumn, SortDirection } from '../../directive/sortable.directive';
import { RouterInterface } from '../../models/router/router.interface';
import { LocationPartnerInterface } from '../../models/location-partners/locationPartner.interface';
import { DecimalPipe } from '@angular/common';
import { matches } from 'lodash';

interface SearchResult {
  description: string;
  ref: string;
}
interface State {
  page: number;
  pageSize: number;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
  searchTerm: string;
}
const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

/*function sort(basicTable: LocationPartner[] | RouterInterface[], column: SortColumn, direction: string): LocationPartner[]|RouterInterface[] {
  if (direction === '' || column === '') {
    return basicTable;
  } else {
    return [...basicTable].sort((a: any, b: any) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}*/
@Injectable({
  providedIn: 'root'
})
export class TableService {
  private _search$ = new Subject<void>();
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _description$ = new BehaviorSubject<string>('');
  private _ref$ = new BehaviorSubject<string>('');
  private _state: State = {
    sortColumn: '',
    sortDirection: '',
    searchTerm: '',
    page: 1,
    pageSize: 10,
  };
  constructor() {
    
  }
  get searchTerm() { return this._state.searchTerm; }
  set sortColumn(sortColumn: SortColumn) { this._set({sortColumn}); }
  set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }
  set searchTerm(searchTerm: string) { this._set({searchTerm}); }
  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }
}
