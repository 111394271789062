import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BaseComponent } from "src/app/base.component";
import { Observable, takeUntil } from "rxjs";
import { AdvertiserDetailsInterface } from "../../../../../../shared/models/advertiser/profile.interface";
import { select, Store } from "@ngrx/store";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  advertiserProfileDetailsSelector,
  saveLeadGenerationSelector,
  saveNewFieldsLeadGenerationSelector,
} from "src/app/store/advertiser/advertiser.selectors";
import {
  invokeProfileDetails,
  saveFieldsLeadGeneration,
  saveLeadGeneration,
} from "src/app/store/advertiser/advertiser.actions";
import { FormBuilder, FormGroup } from "@angular/forms";
import { LeadGeneration } from "../../../../../../shared/models/leadGeneration/leadGeneration";
import { FieldsLeadGeneration } from "../../../../../../shared/models/leadGeneration/fieldsLeadGeneration";
import { TypeFieldsLeadGeneration } from "../../../../../../shared/enum/typeFieldsLeadGeneration";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import {
  defaultFieldsData,
  FildsData,
} from "src/app/shared/data/newFieldsFormData";
@Component({
  selector: "app-form-drag-drop",
  templateUrl: "./form-drag-drop.component.html",
  styleUrls: ["./form-drag-drop.component.scss"],
})
export class FormDragDropComponent extends BaseComponent implements OnInit {
  @Input() icon = "";
  @Input() title = "";
  @Input() available: FildsData[] = [];
  newAvailable: FildsData[] = [];
  @Input() fields: FildsData[] = [];
  @Input() type: TypeFieldsLeadGeneration;
  @Input() index: number;
  @Input() valuesCheckBox: string[] = [];
  @Input() valuesRadio: string[] = [];
  values: string[] = []
  copyFromValues : string[] = []
  @Output() leadGeneration = new EventEmitter<FieldsLeadGeneration[]>();
  advertiserInfo$: Observable<AdvertiserDetailsInterface | null>;
  advertiserInfo: AdvertiserDetailsInterface | null;

  isEditablePlaceholder: boolean = false;
  formUpdate: FormGroup;
  placeholder: string;
  required: boolean = true;
  formLeadGeneration: LeadGeneration = {
    title: "",
    description: "",
    fields: [],
    buttonName: "",
    adTitle: "",
    owner: "",
    creationDate: null,
  };
  fieldsLeadGeneration: FieldsLeadGeneration = {
    title: "",
    errorMessage: [],
    isRequired: true,
    placeholder: "",
    type: null,
    values: null,
  };
  listFields: FieldsLeadGeneration[] = [];
  saveLeadGeneration$: Observable<LeadGeneration | null>;
  formData: any = {};
  customErreurMessage: any[] = [];
  active = 1;

  customType = TypeFieldsLeadGeneration;
  typeInterface: string;
  fieldToUpdate: FildsData;

  isEditable: { [key: number]: boolean } = { 1: false };
  formRadio: { [key: string]: string } = {};
  defaultItems = defaultFieldsData;

  saveNewFieldsLeadGeneration$ : Observable<FildsData[] | null>
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private translate: TranslateService,
    private elementRef: ElementRef
  ) {
    super(store);
    this.advertiserInfo$ = this.store
      .pipe(select(advertiserProfileDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.saveLeadGeneration$ = this.store
      .pipe(select(saveLeadGenerationSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.saveNewFieldsLeadGeneration$ = this.store
      .pipe(select(saveNewFieldsLeadGenerationSelector))
      .pipe(takeUntil(this.ngDestroyed$))
    this.formUpdate = this.fb.group({
      fields: [""],
      placeholder: [""],
    });
  }

  ngOnInit(): void {
    if (this.title != undefined) {
      this.placeholder =
        this.translate.instant("leadGeneration.fields.placeholder") +
        this.title.toLowerCase();
    }
    if (this.type == this.customType.CASEACHOUCHER) {
      this.typeInterface = "radio";
    } else if (this.type == this.customType.CHOIXMULTIPLE) {
      this.typeInterface = "checkbox";
    }
    if (this.title != undefined) {
      this.formData["required"] =
        this.translate.instant("leadGeneration.fields.placeholder") +
        this.title.toLowerCase();
    }
    if (this.title != undefined) {
      this.formData["type"] =
        this.translate.instant("leadGeneration.fields.valid") +
        this.title.toLowerCase();
    }
    this.customErreurMessage = Object.values(this.formData)
    if (!this.required) {
      this.customErreurMessage.shift();
    }
    this.saveLeadGeneration$.subscribe((data) => {
      if (data) {
        this.formLeadGeneration = data;
      }
    });
    this.saveNewFieldsLeadGeneration$.subscribe((data) => {
      if (data) {
        this.fields = data;
      }
    });
    for(let i =0; i<this.fields.length; i++){
      if (this.fields[i].title.includes(this.title)){
        this.values = this.fields[i].values
      }
    }
    let item: FildsData = {
      type: this.type,
      title: this.title,
      logo: this.icon,
      values : this.values
    };
    this.newAvailable = [...this.newAvailable, item];
    this.fieldsLeadGeneration = {
      title: this.title,
      isRequired: this.required,
      placeholder: this.placeholder,
      type: this.type,
      errorMessage: this.customErreurMessage,
      values: this.values,
    };
    
    if (this.fieldsLeadGeneration.title != undefined) {
      this.store.dispatch(
        saveFieldsLeadGeneration({
          fieldLeadGeneration: this.fieldsLeadGeneration,
        })
      );
    }
    this.advertiserInfo$.subscribe(
      (result: AdvertiserDetailsInterface | null) => {
        if (result != null) {
          this.advertiserInfo = result;
        } else {
          this.store.dispatch(invokeProfileDetails());
        }
      }
    );
  }
  removeItem(event: FildsData | any) {
    /*if (this.fields.includes(event)) {
      this.fields.push({
        key: this.title,
        displayName: this.title,
        icon: this.icon,
      });
    } else {
      this.assignments.push({
        key: event,
        displayName: event,
        icon: this.icon,
      });
    }*/
    this.defaultItems.push({
      title: event.title,
      logo: event.logo,
      type: event.type,
      values : event.values
    });
    this.newAvailable.splice(event.previousIndex, 1);
    for (let i = 0; i < this.formLeadGeneration.fields.length; i++) {
      if (this.formLeadGeneration.fields[i].title.includes(event.title)) {
        this.formLeadGeneration = {
          ...this.formLeadGeneration,
          fields: [...this.formLeadGeneration.fields].filter((_, index) => index !== i),
        };
        this.leadGeneration.emit(this.formLeadGeneration.fields);
      }
    }
    this.store.dispatch(saveLeadGeneration({saveFormLeadGeneration : this.formLeadGeneration}))
  }
  /*toggleEditMode() {
    this.isEditable = !this.isEditable;
    this.fieldsLeadGeneration.placeholder = this.placeholder
  }
  toggleEditPlaceholder(){
    this.isEditablePlaceholder = !this.isEditablePlaceholder
    this.placeholder = this.formUpdate.get("placeholder")?.value
  }*/

  toggleUpdate(updateField: any, item: FildsData) {
    const modal = this.modalService.open(updateField, {
      centered: true,
      size: "xl",
    });
    this.fieldToUpdate = item;
  }
  saveOption(field: FieldsLeadGeneration) {
    this.title = field.title;
    this.required = field.isRequired;
    this.placeholder = field.placeholder;
  }
  closeModal() {
    this.modalService.dismissAll();
  }
  onChangeLabel(event: Event) {
    this.title = (event.target as HTMLInputElement).value;
  }
  onchangePlaceholder(event: Event) {
    this.placeholder = (event.target as HTMLInputElement).value;
  }
  saveRequired(event: Event , item : FildsData) {
    this.required = (event.target as HTMLInputElement).checked;
    if(!this.required){
      delete this.formData['required']
    }else{
      this.formData["required"] =
        this.translate.instant("leadGeneration.fields.placeholder") +
        this.title.toLowerCase();
    }
    this.fieldToUpdate = item
    if(this.fieldToUpdate != undefined){
      this.saveChanges()
    }
  }
  saveRequiredFromUpdate(event: Event) {
    this.required = (event.target as HTMLInputElement).checked;
    if(!this.required){
      delete this.formData['required']
    }else{
      this.formData["required"] =
        this.translate.instant("leadGeneration.fields.placeholder") +
        this.title.toLowerCase();
    }
  }
  onInputChange(event: Event, fieldName: string) {
    this.formData[fieldName] = (event.target as HTMLInputElement).value;
  }
  saveChanges() {
    this.customErreurMessage = Object.values(this.formData)
    for (let i = 0; i < this.formLeadGeneration.fields.length; i++) {
      if (
        this.formLeadGeneration.fields[i].title.includes(
          this.fieldToUpdate.title
        )
      ) {
        this.listFields = [...this.formLeadGeneration.fields];
        let newField = {
          title: this.title,
          placeholder: this.placeholder,
          isRequired: this.required,
          type: this.type,
          errorMessage: this.customErreurMessage,
          values: this.values,
        };
        this.listFields[i] = newField;
        this.formLeadGeneration = {
          ...this.formLeadGeneration,
          fields: this.listFields,
        };
        this.leadGeneration.emit(this.formLeadGeneration.fields);
      }
    }
    this.store.dispatch(saveLeadGeneration({saveFormLeadGeneration : this.formLeadGeneration}))
  }
  ajouterOption() {
    this.values = [...this.values, this.translate.instant("leadGeneration.customOptions")];
  }
  toggleEdit(i: number) {
    if(this.copyFromValues.length != 0){
      this.values = [...this.copyFromValues]
    }
    this.copyFromValues = []
  }
  changeOptions(event: Event, i: number) {
    this.copyFromValues = [...this.values]
    this.copyFromValues[i] = (event.target as HTMLInputElement).value;
  }
  removeOption(i: number) {
    let removed = [...this.values];
    removed.splice(i);
    this.values = removed;
  }

  adjustTextareaHeight(): void {
    const textarea: HTMLTextAreaElement =
      this.elementRef.nativeElement.querySelector(".custom-textarea-input-case");
    if(textarea != null){
      textarea.style.height = "25px";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }
  selectTextareaText(event: any) {
    event.target.select();
  }
}
