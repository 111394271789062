import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { AdDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-details.interface";
import { ObjectiveTypeEnumMapping } from "src/app/shared/enum/objectives";
import { AppStateInterface } from "src/app/store/appState.interface";
import { select, Store } from "@ngrx/store";
import { BaseComponent } from "src/app/base.component";
import { Observable, takeUntil } from "rxjs";
import { adDetailsSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import {
  applyPromotionCodeSuccessSelector,
  checkPromotionCodeErrorSelector,
  checkPromotionCodeSuccessSelector,
  getAdHavePromotionCodeSelector,
} from "src/app/store/promotion-code/promotion-code.selectors";
import {
  applyPromotionCodeSuccess,
  checkPromotionCodeError,
  checkPromotionCodeSuccess,
  getAdHavePromotionCode,
  invokeAdHavePromotionCode,
  invokeApplyPromotionCode,
  invokeCheckPromotionCode,
} from "src/app/store/promotion-code/promotion-code.actions";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { PromotionCodeLiteInterface } from "src/app/shared/models/promotion-code/check-promotion-code.interface";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { BadgeService } from "src/app/shared/services/badge.service";
import { DiscountTypeEnum } from "src/app/shared/enum/discount-type.enum";
import { createEstimate } from "src/app/shared/models/iberis/commande/createEstimate";
import { DatePipe } from "@angular/common";
import { Constants } from "src/app/shared/data/constants";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";
import {
  invokeMarkEstimate,
  invokeUpdateEstimate,
  resultUpdateEstimate,
} from "src/app/store/commande/commande.actions";
import { resultUpdateEstimateSelector } from "src/app/store/commande/commande.selectors";
import { ResultCreateEstimate } from "src/app/shared/models/iberis/commande/resultCreateEstimate";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { invokeAdDetails } from "src/app/store/advertisingCampaign/advertisingCampaign.actions";

@Component({
  selector: "app-step3",
  templateUrl: "./step3.component.html",
  styleUrls: ["./step3.component.scss"],
})
export class Step3Component extends BaseComponent implements OnInit {
  adDetails: AdDetailsInterface = {
    ...initialState.adDetails,
  };
  @Input() campaignHashedID: string;
  objectiveTypeEnumMapping = ObjectiveTypeEnumMapping;
  dueDate: Date = new Date();

  params: CommonParamsIberis = {
    lang: "fr",
    companyId: environment.IBERIS.IBERIS_COMPANY_ID,
  };

  @Input() idIberis: string;

  adDetails$: Observable<AdDetailsInterface | null>;

  showSuccessAlert: boolean = true;

  promotionInput: FormControl = new FormControl();

  promotionExists$: Observable<PromotionCodeLiteInterface | null>;

  verifySucceed$: Observable<PromotionCodeLiteInterface | null>;
  verifySucceed: PromotionCodeLiteInterface | null = null;

  verifyFailed$: Observable<string | null>;
  verifyFailed: string | null;

  promotionApplied: PromotionCodeLiteInterface | null = null;

  isExist: boolean = false;
  promtionVerified: boolean = false;

  applyPromotionCodeSucceed$: Observable<string | null>;
  applyPromotionCodeSucceed: string | null = null;

  updateEstimate: createEstimate;
  resultUpdateEstimate$: Observable<ResultCreateEstimate | null>;

  @Input() condition: string = "";
  promotionForm: FormGroup;
  constructor(
    private store: Store<AppStateInterface>,
    private translate: TranslateService,
    private toastr: ToastrService,
    public badgeService: BadgeService,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder
  ) {
    super(store);
    this.adDetails$ = this.store
      .pipe(select(adDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.promotionExists$ = this.store
      .pipe(select(getAdHavePromotionCodeSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.verifySucceed$ = this.store
      .pipe(select(checkPromotionCodeSuccessSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.verifyFailed$ = this.store
      .pipe(select(checkPromotionCodeErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.applyPromotionCodeSucceed$ = this.store
      .pipe(select(applyPromotionCodeSuccessSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.resultUpdateEstimate$ = this.store
      .pipe(select(resultUpdateEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    /*this.campaignHashedID =
      this.activatedRoute.snapshot.params["campaignHasedID"];*/
    this.promotionForm = this.fb.group({
      promotion: [null],
    });
  }

  tva: number = 0;
  priceHT: number = 0;
  total: number = 0;
  discount: number = 0;
  impressions: number = 0;

  ngOnInit(): void {
    this.adDetails$.subscribe((data: AdDetailsInterface | null) => {
      if (data) {
        if (data.promotion) {
          this.promotionApplied = data.promotion;
          this.promotionForm.patchValue({
            promotion: this.promotionApplied.promotionCode,
          });
        }
        if (data.totalCost != undefined && data.startDate) {
          this.dueDate.setDate(
            (new Date(data.startDate).getDate() ?? new Date().getDate()) + 30
          );
          this.priceHT = data.totalCost;
          if (!this.isExist) {
            this.tva = this.priceHT * 0.19;
          }
          this.updateTotale();
          this.impressions = data.totalImpressions;

          this.adDetails = data;
        }
        if (data.campaignHashedID != this.campaignHashedID) {
          this.campaignHashedID = data.campaignHashedID;
        }
        if(this.promotionApplied == null && this.campaignHashedID){
          this.store.dispatch(
            invokeAdHavePromotionCode({ havePromotion: this.campaignHashedID })
          );
        }
      }
    });
    this.store.dispatch(getAdHavePromotionCode({ result: null }));
    this.promotionExists$.subscribe((value) => {
      if (
        value != null &&
        (this.promotionApplied == null || this.promtionVerified)
      ) {
        if (value.discountValue != 0) this.isExist = true;

        this.promotionApplied = value;
        this.promotionForm.patchValue({
          promotion: this.promotionApplied.promotionCode,
        });
        this.updateTotale();
        this.store.dispatch(
          invokeAdDetails({
            invokeAdDetails: {
              ...this.adDetails,
              promotion: this.promotionApplied,
            },
          })
        );
      }
    });
    this.verifySucceed$.subscribe((value) => {
      if (value != null) {
        this.promtionVerified = true;
        this.verifySucceed = value;
        this.store.dispatch(
          invokeApplyPromotionCode({
            code: this.promotionForm.get("promotion")?.value,
            campaignHashedId: this.campaignHashedID,
          })
        );
        this.store.dispatch(checkPromotionCodeSuccess({ result: null }));

        if (this.promotionApplied && this.promotionApplied.discountValue == 0) {
          this.promotionApplied = value;
        }
      }
    });
    this.verifyFailed$.subscribe((value) => {
      if (value != null) {
        this.promtionVerified = false;
        this.verifyFailed = value;
        this.toastr.error(
          this.translate.instant("response.error." + value),
          this.translate.instant("response.errorTitle")
        );
        this.store.dispatch(checkPromotionCodeError({ result: null }));
      }
    });
    this.applyPromotionCodeSucceed$.subscribe((value) => {
      if (value != null) {
        this.isExist = true;
        this.store.dispatch(
          invokeAdDetails({
            invokeAdDetails: {
              ...this.adDetails,
              promotion: this.verifySucceed,
              totalCost: this.priceHT,
            },
          })
        );
        this.updateTotale();
        this.applyPromotionToIberis();
        this.toastr.success(
          this.translate.instant("response.success." + value),
          this.translate.instant("response.successTitle")
        );
        this.store.dispatch(applyPromotionCodeSuccess({ result: null }));
      }
    });

    this.resultUpdateEstimate$.subscribe(
      (value: ResultCreateEstimate | null) => {
        if (value != null) {
          this.store.dispatch(
            invokeMarkEstimate({
              estimateId: this.adDetails.estimateId,
              params: this.params,
            })
          );
        }
        this.store.dispatch(
          resultUpdateEstimate({ resultUpdateEstimate: null })
        );
      }
    );
  }
  applyPromotionToIberis() {
    var discountType: number = 1;
    var discountValue: number = 0;
    if (this.verifySucceed) {
      switch (this.verifySucceed?.discountType) {
        case DiscountTypeEnum.AMOUNT: {
          discountType = 2;
          discountValue = this.verifySucceed?.discountValue;
          break;
        }
        case DiscountTypeEnum.PERCENTAGE: {
          discountType = 1;
          discountValue = this.verifySucceed?.discountValue;
          break;
        }
      }
      this.updateEstimate = {
        date: this.datePipe.transform(Constants.DATE_IBERIS, "yyyy-MM-dd"),
        due: this.datePipe.transform(Constants.DUE_DATE_IBERIS, "yyyy-MM-dd"),
        estimate_number: this.adDetails.estimateNumber.substring(
          this.adDetails.estimateNumber.lastIndexOf("-") + 1
        ),
        object: "Campagne " + this.adDetails.title,
        notes: this.campaignHashedID,
        tax_type: 1,
        client_id: this.idIberis,
        use_conditions: 1,
        show_stamp: 1,
        show_billing: 1,
        show_delivery: 0,
        show_bank: 1,
        bank_id: environment.IBERIS.BANK_ID,
        show_conditions: 1,
        show_description: 1,
        show_pictures: 1,
        choice: 1,
        currency_rate: 1,
        language: this.params.lang,
        conditions: "",
        reference: "",
        discount: "",
        additional_contact_id: "",
        show_unity: 1,
        items: {
          //item_hashed_id: "",
          item: "Campagne " + this.adDetails.title,
          description:
            this.datePipe.transform(this.adDetails.startDate, "yyyy-MM-dd ") +
            "-" +
            this.datePipe.transform(this.adDetails.endDate, "yyyy-MM-dd ") +
            "\n" +
            this.adDetails.locations,
          discountType: discountType,
          discountAmount: discountValue,
          qte: 1,
          price: this.adDetails.totalCost,
          taxes: [{ tax_hashed_id: environment.IBERIS.TAX_HASHED_ID }],
        },
        useInput: {
          input_hashed_id: environment.IBERIS.TIMBRE_FISCAL_HASHED_ID,
          value: 1,
        },
      };
      this.store.dispatch(
        invokeUpdateEstimate({
          params: this.params,
          createEstimate: this.updateEstimate,
          estimateId: this.adDetails.estimateId,
        })
      );
    }
  }

  calculateDays(): number {
    if (this.adDetails?.startDate && this.adDetails?.endDate) {
      const start = new Date(this.adDetails?.startDate);
      const end = new Date(this.adDetails?.endDate);
      const timeDiff = Math.abs(end.getTime() - start.getTime());
      return Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
    } else return 0;
  }

  applyCode() {
    const input: string = this.promotionForm.get("promotion")?.value;
    this.verifySucceed = null;
    this.verifyFailed = null;
    if (input != null && this.adDetails.estimateId) {
      this.store.dispatch(invokeCheckPromotionCode({ code: input }));
    }
  }

  getUnit(type: DiscountTypeEnum | undefined): string {
    switch (type) {
      case DiscountTypeEnum.AMOUNT:
        return "TND";
      case DiscountTypeEnum.PERCENTAGE:
        return "%";
      default:
        return "";
    }
  }

  updateTotale() {
    this.total =
      Number(this.priceHT.toFixed(3)) + Number(this.tva.toFixed(3)) + 1;
    switch (this.promotionApplied?.discountType) {
      case DiscountTypeEnum.AMOUNT: {
        this.discount = this.promotionApplied.discountValue;
        const newPriceHT = this.priceHT - this.promotionApplied.discountValue;
        this.tva = newPriceHT * 0.19;
        this.total =
          Number(newPriceHT.toFixed(3)) + Number(this.tva.toFixed(3)) + 1;
        break;
      }
      case DiscountTypeEnum.PERCENTAGE: {
        if (this.promotionApplied.discountValue != 0) {
          var percentageToCalculate: number =
            1 - this.promotionApplied.discountValue / 100;
          const newPriceHT = this.priceHT * percentageToCalculate;
          this.tva = newPriceHT * 0.19;
          this.total =
            Number(newPriceHT.toFixed(3)) + Number(this.tva.toFixed(3)) + 1;
          this.discount =
            this.priceHT * (this.promotionApplied.discountValue / 100);
        }
      }
    }
    this.cdr.detectChanges();
  }
}
