import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AdvertisingCampaignInterface } from 'src/app/shared/models/advertising-campaigns/advertising-campaign.interface';
import { SelectedLps } from 'src/app/shared/models/location-partners/selectedLps';
import { getAdCompaignByHashedIdSelector } from 'src/app/store/advertisingCampaign/advertiserCampaign.selectors';
import { AppStateInterface } from 'src/app/store/appState.interface';
import { selectedLPs } from 'src/app/store/locationPartner/location-partner.action';
import { saveSelectedLPsSelector } from 'src/app/store/locationPartner/location-partner.selectors';

@Component({
  selector: 'app-ads-locations',
  templateUrl: './ads-locations.component.html',
})
export class AdsLocationsComponent implements OnInit {

  adCompaignByHashedId$: Observable<AdvertisingCampaignInterface | null>;
  locations: string[] = [];
  selectedLp : string | null
  saveSelectedLP$: Observable<SelectedLps | null>;
  constructor(private store: Store<AppStateInterface>, private modalService : NgbModal) { 
    this.adCompaignByHashedId$ = this.store
    .pipe(select(getAdCompaignByHashedIdSelector))
  
    this.saveSelectedLP$ = this.store
    .pipe(select(saveSelectedLPsSelector))
  }

  ngOnInit(): void {
    this.saveSelectedLP$.subscribe((data) => {
      if (data) {
        this.selectedLp = data.companyNames[0];
      }else{
        this.selectedLp = null
      }
    });
    this.adCompaignByHashedId$.subscribe(
      (result: AdvertisingCampaignInterface | null) => {
        if(result){
          this.locations = result.locationPartners
        }
      }
    );
  }
  saveLocation(event : any , location : string | null){
    if (event.target.checked) {
      this.selectedLp = location;
    }
  }
  submitLocation(){
    if(this.selectedLp){
      this.store.dispatch(
        selectedLPs({
          selectedLps: {
            companyNames: [this.selectedLp],
            isCalculator: false,
            count: 1,
          },
        })
      );
    }else{
      this.store.dispatch(
        selectedLPs({
          selectedLps: null
        })
      );
    }
    this.onClickDismissModal()
  }
  onClickDismissModal(){
    this.modalService.dismissAll()
  }
}
