import { NgModule } from "@angular/core";
var routingAnimation = localStorage.getItem("animate");
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "./shared/components/layout/dashboard/dashboard.component";
import { authContent } from "./shared/routes/auth";
import { AuthComponent } from "./shared/components/layout/auth/auth.component";
import { dashboardContent } from "./shared/routes/dashboard";
import { GuestGuardService } from "./shared/services/guard/guest-guard.service";
import { authGuard } from "./shared/services/guard/auth-guard.service";

const routes: Routes = [
  {
    path: "",
    redirectTo: "auth",
    pathMatch: "full",
  },
  {
    path: "",
    canActivate: [GuestGuardService],
    component: AuthComponent,
    children: authContent,
    data: {
      title: 'Relead | Boost your business',
      description: 'Relead - reach your potential customers and grow your business through Wi-Fi advertising.'
    }
  },
  {
    path: "dashboard",
    canActivate: [authGuard],
    component: DashboardComponent,
    children: dashboardContent,
  },
  {
    path: "**",
    redirectTo: "",
    data: {
      title: 'Not found | Relead',
      description: 'Welcome to the 404 not found page of the application'
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
