export enum RouterStatus {
  ONLINE = "ONLINE",
  OUTOFORDER = "OUTOFORDER",
  IN_STOCK = "IN_STOCK",
  READY_TO_GO = "READY_TO_GO",
  ON_SITE = "ON_SITE"
}
export enum RouterStatusToShow {
  ONLINE = "enumMapping.router.filter.ONLINE",
  OUTOFORDER = "enumMapping.router.filter.OUTOFORDER",
  IN_STOCK = "enumMapping.router.filter.IN_STOCK",
  READY_TO_GO="enumMapping.router.filter.READY_TO_GO",
  ON_SITE ="enumMapping.router.filter.ON_SITE"
}
export interface RouterStatusInterface {
  id: RouterStatus;
  value: string;
}