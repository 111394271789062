import { Menu } from "../models/side-bar-menu.interface";

export interface navBarInfoInteface {
  locationPartner: Menu[];
  advertiser: Menu[];
  admin: Menu[];
}
export const NavBarInfo: navBarInfoInteface = {
  locationPartner: [
    {
      title: "sideBar.dashboard",
      icon: "home",
      type: "link",
      path: "/dashboard/location-partner/main",
    },
    {
      title: "sideBar.location.promote",
      icon: "editors",
      type: "link",
      path: "/dashboard/location-partner/promote",
    },
    {
      title: "sideBar.location.statistics",
      icon: "charts",
      type: "link",
      path: "/dashboard/location-partner/statistics",
    },
    {
      title: "sideBar.location.remuneration",
      icon: "jobsearch",
      type: "link",
      path: "/dashboard/location-partner/remuneration",
    },
    {
      title: "sideBar.support",
      icon: "ticket",
      type: "link",
      path: "/dashboard/location-partner/support",
    },
  ],
  advertiser: [
    {
      title: "sideBar.dashboard",
      icon: "home",
      type: "link",
      path: "/dashboard/advertiser/main",
    },
    {
      title: "sideBar.advertiser.create ad",
      icon: "editors",
      type: "link",
      path: "/dashboard/advertiser/advertising-campaigns/create-ad",
    },
    {
      title: "sideBar.advertiser.insights",
      icon: "charts",
      type: "link",
      path: "/dashboard/advertiser/insights",
    },
    {
      title: "sideBar.advertiser.myOrders",
      icon: "cart",
      type: "link",
      path: "/dashboard/advertiser/orders",
    },
    {
      title: "sideBar.advertiser.payments",
      icon: "project",
      type: "link",
      path: "/dashboard/advertiser/payments",
    },
    {
      title: "sideBar.bug report",
      icon: "ticket",
      type: "link",
      path: "/dashboard/advertiser/support",
    },
  ],
  admin: [
    {
      path: "/dashboard/admin/main",
      icon: "home",
      title: "sideBar.dashboard",
      type: "link",
    },
    {
      title: "sideBar.admin.advertisers.title",
      icon: "account",
      type: "sub",
      path: "/dashboard/admin/advertisers",
      children: [
        {
          path: "/dashboard/admin/advertisers/repository",
          title: "sideBar.admin.advertisers.repository",
          type: "link",
        },
        {
          path: "/dashboard/admin/advertisers/commandes",
          title: "sideBar.admin.advertisers.orders",
          type: "link",
        },
        {
          path: "/dashboard/admin/advertisers/factures",
          title: "sideBar.admin.advertisers.billings",
          type: "link",
        },
      ],
    },
    {
      path: "/dashboard/admin/location-partners",
      icon: "maps",
      title: "sideBar.admin.location partners.title",
      type: "sub",
      children: [
        {
          path: "/dashboard/admin/location-partners/repository",
          title: "sideBar.admin.location partners.repository",
          type: "link",
        },
        {
          path: "/dashboard/admin/location-partners/payment",
          title: "sideBar.admin.location partners.payment",
          type: "link",
        },
        {
          path: "/dashboard/admin/location-partners/campagnes",
          title: "sideBar.admin.location partners.campaigns",
          type: "link",
        },
      ],
    },
    {
      title: "sideBar.admin.campaigns.title",
      icon: "gallery",
      type: "sub",
      path: "/dashboard/admin/campaigns",
      children: [
        {
          path: "/dashboard/admin/campaigns/pipeline",
          title: "sideBar.admin.campaigns.pipeline",
          type: "link",
        },
        {
          path: "/dashboard/admin/campaigns/simulateur",
          title: "sideBar.admin.campaigns.simulator",
          type: "link",
        },
        {
          path: "/dashboard/admin/campaigns/calendar",
          title: "sideBar.admin.campaigns.calendar",
          type: "link",
        },
        /*{
          path: "/dashboard/admin/ads-campaigns/Codes-promos",
          title: "sideBar.admin.campaigns.promos",
          type: "link",
        },*/
      ],
    },
    {
      path: "/dashboard/admin/routers",
      icon: "learning",
      title: "sideBar.admin.routers.title",
      type: "sub",
      children: [
        {
          path: "/dashboard/admin/routers/relead",
          title: "sideBar.admin.routers.relead",
          type: "link",
        },
        {
          path: "/dashboard/admin/routers/fsi",
          title: "sideBar.admin.routers.fsi",
          type: "link",
        },
      ],
    },
    {
      path: "/dashboard/admin/consumers",
      icon: "users",
      title: "sideBar.admin.consumers",
      type: "link",
    },
    {
      path: "/dashboard/admin/support",
      title: "sideBar.admin.support.title",
      icon: "ticket",
      type: "sub",
      children: [
        {
          path: "/dashboard/admin/support/advertiser",
          title: "sideBar.admin.support.advertisers",
          type: "link",
        },
        {
          path: "/dashboard/admin/support/lp",
          title: "sideBar.admin.support.lp",
          type: "link",
        },
      ],
    },
    {
      path: "/dashboard/admin/settings",
      icon: "learning",
      title: "sideBar.admin.settings.title",
      type: "sub",
      children: [
        {
          path: "/dashboard/admin/settings/access-role",
          title: "sideBar.admin.settings.access&Role",
          type: "link",
        },
        {
          path: "/dashboard/admin/settings/fixed-charges",
          title: "sideBar.admin.settings.fixedCharges",
          type: "link",
        },
        {
          path: "/dashboard/admin/settings/promotion-codes",
          title: "sideBar.admin.settings.promotionCode",
          type: "link",
        },
        {
          path: "/dashboard/admin/settings/objectives",
          title: "sideBar.admin.settings.objectives",
          type: "link",
        },
        {
          path: "/dashboard/admin/settings/regions",
          title: "sideBar.admin.settings.regions",
          type: "link",
        },
        {
          path: "/dashboard/admin/settings/zones",
          title: "sideBar.admin.settings.zones",
          type: "link",
        },
        {
          path: "/dashboard/admin/settings/categories",
          title: "sideBar.admin.settings.categories",
          type: "link",
        },
        {
          path: "/dashboard/admin/settings/sub-categories",
          title: "sideBar.admin.settings.subCategories",
          type: "link",
        },
        {
          path: "/dashboard/admin/settings/tags",
          title: "sideBar.admin.settings.tags",
          type: "link",
        },
        {
          path: "/dashboard/admin/settings/impressions",
          title: "sideBar.admin.settings.impressions",
          type: "link",
        },
      ],
    },
    {
      path: "/dashboard/admin/user-history",
      icon: "page",
      title: "sideBar.admin.history",
      type: "link",
    },
    /*{
      path: "/dashboard/admin/calculator",
      icon: "others",
      title: "Calculator",
      type: "link",
    },
    {
      path: "/dashboard/admin/calendar",
      icon: "calender",
      title: "Calendar",
      type: "link",
    },*/
  ],
};
