import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import { HasedObjectiveEnum, ObjectiveTypeEnum } from "src/app/shared/enum/objectives";
import { environment } from "src/environments/environment";
import {
  CampaignsData,
  CampaignCard,
} from "src/app/shared/data/campaigns-data";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppStateInterface } from "src/app/store/appState.interface";
import { AdDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-details.interface";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
@Component({
  selector: "app-advertising-campaign",
  templateUrl: "./advertising-campaign.component.html",
  styleUrls: ["./advertising-campaign.component.scss"],
})
export class AdvertisingCampaignComponent implements OnInit, AfterViewInit {
  @Output("selectedGif") selectedGif = new EventEmitter<string>();
  active = 1;

  @Output() objectiveName: ObjectiveTypeEnum =
    ObjectiveTypeEnum.BRAND_AWARENESS_AND_REACH;

  campaignsData: CampaignCard[] = CampaignsData;
  isDisabled : boolean
  adDetails: AdDetailsInterface = { ...initialState.adDetails };
  HasedObjectiveEnum = HasedObjectiveEnum
  constructor(private router: Router, private store : Store<AppStateInterface>) {}
  ngAfterViewInit(): void {
    this.selectedGif.emit(
      environment.CDN_RELEAD +
        "iahF4npEpwWVXiajWT76GOqJBQWg748JvQzw3qVn06zZ2_T1uyod6.mp4"
    );
  }

  ngOnInit(): void {}
  OnSelectCard(objective: ObjectiveTypeEnum, isDisabled : boolean) {
    this.objectiveName = objective;
    this.isDisabled = isDisabled
  }
  changeGif(gif: string) {
    this.selectedGif.emit("assets/gifs/loading.gif");

    setTimeout(() => {
      this.selectedGif.emit(environment.CDN_RELEAD + gif);
    }, 500);
  }

  OnSelectBrand() {
    throw new Error("Method not implemented.");
  }

}
