import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { map, Observable, of, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { SuccessDto } from "src/app/shared/models/success-dto";
import {
  invokeUpdateAdCampaignDetails,
  updateAdCampaignDetails,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { AppStateInterface } from "src/app/store/appState.interface";
import { updateAdCampaignDetailsSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { HttpEvent, HttpResponse } from "@angular/common/http";
import { LocationPartnerInterface } from "src/app/shared/models/location-partners/locationPartner.interface";
import { setFileName, invokeUploadFile } from "src/app/store/file/file.actions";
import { uploadFileSelector } from "src/app/store/file/file.selectors";
import { invokeLocationsToCreateCampaign } from "src/app/store/locationPartner/actions/get-locations.actions";
import { locationPartnersToCreateCampaignResultSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { catchServerErrorSelector } from "src/app/store/user/user.selectors";
import { catchServerError } from "src/app/store/user/user.actions";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { ExtensionService } from "src/app/shared/services/extension/extension.service";
@Component({
  selector: "app-update-campaign-lp",
  templateUrl: "./update-campaign-lp.component.html",
  styleUrls: ["./update-campaign-lp-component.scss"]
})
export class UpdateCampaignLPComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  @Input() ad: AdvertisingCampaignInterface;
  validate: boolean;
  showAlert: boolean = false;
  showProgress: boolean = false;
  uploadProgress: number = 0;
  files: File[] = [];
  showError: boolean = false;
  isShown: boolean = false;
  filename: string;
  approveInProgress: boolean;
  uploadFile$: Observable<string | null>;
  uploadFileProgress$: Observable<HttpEvent<any> | null | HttpResponse<any>>;
  submitted: boolean;
  update$: Observable<SuccessDto | null>;
  updated: string;

  locationPartnersResult$: Observable<LocationPartnerInterface[] | null>;
  locations: string[];
  loading: boolean = false;
  catchServerErreur$: Observable<boolean | null>;
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private extensionService: ExtensionService,
    private translate: TranslateService
  ) {
    super(store);
    this.form = fb.group({
      title: [null, [Validators.required]],
      locations: [[], [Validators.required]],
    });
    this.update$ = this.store
      .pipe(select(updateAdCampaignDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.uploadFile$ = this.store
      .pipe(select(uploadFileSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.locationPartnersResult$ = this.store
      .pipe(select(locationPartnersToCreateCampaignResultSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.catchServerErreur$ = this.store
      .pipe(select(catchServerErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.store.dispatch(invokeLocationsToCreateCampaign());
    this.form.patchValue({ redirectionLink: this.ad.redirectionLink });
    this.form.patchValue({ startDate: this.ad.startingDate });
    this.form.patchValue({ endDate: this.ad.endingDate });

    this.locationPartnersResult$.subscribe(
      (result: LocationPartnerInterface[] | null) => {
        if (result != null) {
          this.locations = result.map((value) => value.companyName);

          this.form.patchValue({ locations: this.ad.locationPartners });
          this.form.patchValue({ title: this.ad.title });
        }
      }
    );
    this.loadImage().subscribe((i) => {
      const myFile = new File(
        [i],
        this.translate.instant("campaignDetails.video") + "-" + this.ad.title,
        {
          type: i.type,
        }
      );
      this.files.push(myFile);
    });
    this.uploadFile$.subscribe((result: string | null) => {
      if (result != null) {
        this.filename = result;
        this.submitted = true;
        this.store.dispatch(setFileName({ filename: null }));
      }
    });
    this.catchServerErreur$.subscribe((result) => {
      if (result) {
        this.loading = false;
        this.store.dispatch(catchServerError({ serverError: null }));
      }
    });
    this.update$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.updated = result.message;
        this.store.dispatch(updateAdCampaignDetails({ successMessage: null }));
        this.close();
        this.loading = false;
      }
    });
  }
  loadImage(): Observable<Blob> {
    return of(environment.CDN_RELEAD + this.ad.videoLink).pipe(
      map((data) => {
        const blob = new Blob([data], {
          type: `video/${this.extensionService.getExtension(
            this.ad.videoLink
          )}`,
        });
        return blob;
      })
    );
  }
  update() {
    let adCampaign: any = {
      title: this.form.get("title")?.value,
      startingDate: this.ad.startingDate,
      endingDate: this.ad.endingDate,
      publishDate: this.ad.publishDate,
      redirectionLink: this.ad.redirectionLink,
      status: this.ad.status,
      locationPartners: this.form.get("locations")?.value,
      views: this.ad.views,
      objectiveName: this.ad.objectiveName,
      orderReference: this.ad.orderReference,
      synthesisReport: this.ad.synthesisReport,
    };
    if (this.files.length == 0) {
      adCampaign.videoLink = this.ad.videoLink;
    } else {
      adCampaign.videoLink = this.filename;
    }
    this.store.dispatch(
      invokeUpdateAdCampaignDetails({
        campaignHashedId: this.ad.advertisingCampaignHashedId,
        adCompaign: adCampaign,
      })
    );

    this.uploadProgress = 0;
    this.showProgress = false;
    this.loading = true;
  }
  onSelect(event: File[]) {
    if (event[0].size > 4194304) this.showError = true;
    else {
      this.files = event;
      this.isShown = true;
    }
    const formData = new FormData();
    formData.append("files", this.files[0], this.files[0].name);
    this.store.dispatch(invokeUploadFile({ files: formData }));
  }
  onRemove() {
    this.files = [];
  }
  clear() {
    this.onRemove();
    this.form.reset({
      title: "",
      description: "",
    });
  }
  close() {
    this.modalService.dismissAll();
  }
}
