<div class="modal-header">
  <h4 class="modal-title" id="exampleModalLabel">
    {{ title | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="onClickCloseModal()"
  ></button>
</div>
<div class="modal-body">
  <div class="media">
    <button
      *ngFor="let field of saveOption; let i = index"
      type="button"
      [ngClass]="
        isCol4
          ? 'col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 m-0 p-r-0'
          : 'm-0 p-r-0 w-25'
      "
      [disabled]="field.disabled"
      style="color: black; border: none; background-color: transparent;"
      (click)="onClickOpenModal(field.type)"
    >
      <div class="widget-joins widget-arrow">
        <div class="widget-card rounded" style="padding: 27px">
          <i
            [class]="field.icon"
            class="items-center m-b-10"
            style="font-size: 2rem"
          ></i>
          <h5 class="items-center">{{ field.name | translate }}</h5>
        </div>
      </div>
    </button>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-primary" (click)="onClickCloseModal()">
    {{ "buttons.close" | translate }}
  </button>
  <button
    *ngIf="type != typeCard.ADMIN_LOCATION_CREATE"
    class="btn btn-primary"
    [disabled]="
      routerToEdit == null &&
      email == null &&
      advertiser == null
    "
    (click)="onClickUpdate()"
  >
    {{ "buttons.update" | translate }}
  </button>
</div>

<!--[disabled]="
      routerToEdit == null &&
      locationPartnerToEdit == null &&
      updateAdvertiserInformation == null
    " -->
