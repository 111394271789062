<form [formGroup]="form">
  <!-- ... nested form controls ... -->
  <h5 style="text-align: left"> {{"addLP.4thStep.title" | translate}} </h5>

  <!--<div class="form-group">
    <label>Address*</label>
    <div class="input-group">
      <input
        autoComplete="address"
        formControlName="address"
        class="form-control"
        type="text"
        required=""
        #address
        (change)="searchAddress()"
        placeholder="address"
      />
    </div>
  </div>-->

  <div class="row align-items-center">
    <div class="col-6">
      <div class="col-12">
        <div class="form-group">
          <label class="label">{{"admin.locations.repository.latitude" | translate}}*</label>
          <div class="input-group">
            <input
              formControlName="latitude"
              class="form-control"
              type="number"
              required=""
              [placeholder]="'admin.locations.repository.latitude' | translate"
            />
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label class="label">{{"admin.locations.repository.longitude" | translate}}*</label>
          <div class="input-group">
            <input
              formControlName="longitude"
              class="form-control"
              type="number"
              required=""
              [placeholder]="'addLP.4thStep.placeholder.longitude' | translate"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div>
        <ng-template #popContent>
          <app-popover-map
            width="400px"
            [mapOptions]="mapOptions"
            [markerOptions]="markerOptions"
            [markerPosition]="markerPosition"
            (changePosition)="changeMarkerPosition($event)"
          ></app-popover-map>
        </ng-template>
        <div class="border-style">
          <a
            data-container="body"
            data-bs-toggle="popover"
            placement="right"
            [ngbPopover]="popContent"
            popoverClass="popover-width"
            [autoClose]="false"
            [animation]="true"
            type="button"
            class="card"
            style="margin-bottom: 0%"
            #p="ngbPopover"
            popoverTitle="Google maps"
          >
            <div class="card-body">
              <img class="img" src="assets/svgIcon/mapIcon.svg" alt="SVG" />
              <figcaption style="text-align: center">MAP</figcaption>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label class="label">{{"addLP.4thStep.zone" | translate}}*</label>
    <div class="input-group">
      <select class="form-select form-control" formControlName="zone">
        <option [ngValue]="null" disabled selected>{{"addLP.4thStep.placeholder.zone" | translate}}</option>
        <option *ngFor="let item of zoneNames$ | async" [ngValue]="item">
          {{ item }}
        </option>
      </select>
    </div>
  </div>
</form>
