<form
  [formGroup]="editProfileForm"
  (ngSubmit)="onSubmitEditProfile()"
  *ngIf="this.editProfileForm"
>
  <div class="card-body">
    <div class="row m-t-30">
      <!-- firstName -->
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
        <div class="mb-3">
          <label class="form-label">{{
            "profile.profileDetails.firstName" | translate
          }}</label>
          <input class="form-control" type="text" formControlName="firstName" />
        </div>
      </div>
      <!-- Lastname -->
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
        <div class="mb-3">
          <label class="form-label">{{
            "profile.profileDetails.lastName" | translate
          }}</label>
          <input class="form-control" type="text" formControlName="lastName" />
        </div>
      </div>
      <!-- Phone -->
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
        <div class="mb-3">
          <label class="form-label">{{
            "profile.profileDetails.phone" | translate
          }}</label>
          <input
            class="form-control"
            [mask]="'99 999 999'"
            type="text"
            formControlName="phone"
          />
        </div>
      </div>
      <!-- Email -->
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
        <div class="mb-3">
          <label class="form-label">{{
            "profile.profileDetails.email" | translate
          }}</label>
          <input class="form-control" type="email" formControlName="email" />
        </div>
      </div>
      <!-- Company name -->
      <div
        class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4"
        *ngIf="role != roleName.ROLE_LOCATION"
      >
        <div class="mb-3">
          <label class="form-label">
            {{ "profile.profileDetails.companyName" | translate }}
          </label>
          <input
            class="form-control"
            type="text"
            formControlName="companyName"
          />
        </div>
      </div>
      <!-- Company activity -->
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
        <div class="mb-3">
          <label class="form-label">{{
            "profile.profileDetails.activity" | translate
          }}</label>
          <select
            class="form-select"
            formControlName="companyActivity"
            id="validationCustom04"
          >
            <option selected="" disabled="" value>
              {{ "placeholder.placeholderSelect" | translate }}
            </option>
            <option
              *ngFor="let item of companyActivities"
              [ngValue]="item.hashed_id"
            >
              {{ item.title }}
            </option>
          </select>
        </div>
      </div>
      <!-- Company address -->
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
        <div class="mb-3">
          <label class="form-label">{{
            "profile.profileDetails.companyAddress" | translate
          }}</label>
          <input
            class="form-control"
            type="text"
            formControlName="companyAddress"
          />
        </div>
      </div>
      <!--Company role-->
      <div
        class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4"
        *ngIf="role == roleName.ROLE_ADVERTISER"
      >
        <div class="mb-3">
          <label class="form-label">{{
            "profile.profileDetails.role" | translate
          }}</label>
          <input
            class="form-control"
            type="text"
            formControlName="companyRole"
          />
        </div>
      </div>
      <!-- Website -->
      <div
        class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4"
        *ngIf="role == roleName.ROLE_ADVERTISER"
      >
        <div class="mb-3">
          <label class="form-label">{{
            "profile.profileDetails.webSite" | translate
          }}</label>
          <input class="form-control" type="text" formControlName="website" />
        </div>
      </div>
      <!--<div
        class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4"
        *ngIf="role == roleName.ROLE_LOCATION"
      >
        <div class="mb-3">
          <label class="form-label">{{
            "profile.profileDetails.longitude" | translate
          }}</label>
          <input class="form-control" type="text" formControlName="longitude" />
        </div>
      </div>
      <div
        class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4"
        *ngIf="role == roleName.ROLE_LOCATION"
      >
        <div class="mb-3">
          <label class="form-label">{{
            "profile.profileDetails.latitude" | translate
          }}</label>
          <input class="form-control" type="text" formControlName="latitude" />
        </div>
      </div>-->
      <div
        class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4"
        *ngIf="role == roleName.ROLE_ADVERTISER"
      >
        <div class="mb-3">
          <label class="form-label"
            >{{ "profile.profileDetails.birthday" | translate }}
          </label>
          <div class="input-group">
            <div
              class="input-group date"
              id="dt-date"
              data-target-input="nearest"
            >
              <input
                class="form-control datetimepicker-input digits"
                formControlName="birthday"
                type="text"
                [owlDateTime]="dt"
                readonly
                placeholder="yyyy-mm-dd"
              />
              <div
                class="input-group-text bg-primary"
                data-target="#dt-date"
                [owlDateTimeTrigger]="dt"
              >
                <i
                  class="icofont icofont-ui-calendar"
                  style="color: white; font-size: 12px"
                ></i>
              </div>
            </div>
            <owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-end">
    <button class="btn btn-primary" type="submit">
      {{ "buttons.update" | translate }}
    </button>
  </div>
</form>
