import { Component, Input, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { cloneDeep } from "lodash";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { LocationSearchMethod } from "src/app/shared/enum/locationSearchMethod";
import { FilterLocationPartnersToCreateCampaign } from "src/app/shared/models/location-partners/filterBySubCategoriesAndTagLocationPartner";
import {
  AllRegionInterface,
  RegionInterface,
} from "src/app/shared/models/region/region";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeFilterLocationsToCreateCampaign } from "src/app/store/locationPartner/actions/get-locations.actions";
import { initialState } from "src/app/store/locationPartner/location-partner.reducers";
import { locationPartnersToCreateCampaignFilterSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { invokeAllRegions } from "src/app/store/region/region.actions";
import { allRegionSelector } from "src/app/store/region/region.selectors";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-region-selection",
  templateUrl: "./region-selection.component.html",
  styleUrls: ["./region-selection.component.scss"],
})
export class RegionSelectionComponent extends BaseComponent implements OnInit {
  locationPartnersToCreateCampaignFilter$: Observable<FilterLocationPartnersToCreateCampaign>;
  locationPartnersToCreateCampaignFilter: FilterLocationPartnersToCreateCampaign =
    {
      ...initialState.locationPartnersToCreateCampaignFilter,
    };

  //region
  allRegions$: Observable<AllRegionInterface[] | null>;
  getAllRegions: AllRegionInterface[] = [];
  regionNames: string[] = [];
  listRegion: string[] = [];
  urlCDN = environment.CDN_RELEAD;

  @Input() method: Observable<LocationSearchMethod>;

  constructor(private store: Store<AppStateInterface>) {
    super(store);

    this.allRegions$ = this.store
      .pipe(select(allRegionSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.locationPartnersToCreateCampaignFilter$ = this.store
      .pipe(select(locationPartnersToCreateCampaignFilterSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.locationPartnersToCreateCampaignFilter$.subscribe((filter) => {
      this.locationPartnersToCreateCampaignFilter = filter;
    });
    this.method?.subscribe((method) => {
      this.locationPartnersToCreateCampaignFilter = {
        ...this.locationPartnersToCreateCampaignFilter,
        locationsSearchMethod: method,
        regions: this.listRegion,
      };
      this.store.dispatch(
        invokeFilterLocationsToCreateCampaign({
          filterLocationPartnersToCreateCampaign:
            this.locationPartnersToCreateCampaignFilter,
        })
      );
    });
    this.allRegions$.subscribe((result: AllRegionInterface[] | null) => {
      if (result != null) {
        this.getAllRegions = result;
        this.regionNames = result.map((region) => region.name);
        this.listRegion = cloneDeep(this.regionNames);
        this.invokeByRegions(this.regionNames);
      } else {
        this.store.dispatch(invokeAllRegions());
      }
    });
  }

  //***** Regions *******//
  //#region

  //check box (region selection)
  selectAllRegions(event: any) {
    if (event.target.checked) this.listRegion = cloneDeep(this.regionNames);
    else this.listRegion = [];

    this.invokeByRegions(this.listRegion);
  }

  CheckRegionSelectedAll(): boolean {
    return this.regionNames.length == this.listRegion.length;
  }

  //cards animation
  regionExists(item: string): boolean {
    const regionIndex: number = this.listRegion.findIndex(
      (value) => value == item
    );
    if (regionIndex >= 0) return true;
    else return false;
  }

  addRemoveRegion(event: Event, item: string) {
    event.preventDefault();
    const regionIndex: number = this.listRegion.findIndex(
      (value) => value == item
    );
    let regions = [...this.listRegion];
    if (regionIndex >= 0) {
      regions.splice(regionIndex, 1);
      this.listRegion = regions;
    } else this.listRegion = [...this.listRegion, item];
    this.invokeByRegions(this.listRegion);
  }

  invokeByRegions(regions: string[]) {
    this.store.dispatch(
      invokeFilterLocationsToCreateCampaign({
        filterLocationPartnersToCreateCampaign: {
          ...this.locationPartnersToCreateCampaignFilter,
          regions: regions,
        },
      })
    );
  }
  //#endregion
}
