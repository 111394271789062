import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, takeUntil } from "rxjs";
import { setAPIStatus, invokeComponentDistroyed } from "../apiState.interface";
import { invokeBugReport, createBugReport, invokeFilterBugReportByUser, filterBugReportByUser, invokeUpdateBugReportStatus, resultUpdateBugReportStatus, invokeBugReportByRef, resultBugReportByRef } from "./bug-report.action";
import { BugReportService } from "src/app/shared/services/bug-report.service";
import { ResultFilterbugReportInterface } from "src/app/shared/models/bugReport/result-filter-bug-report.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { BugReportInterface } from "src/app/shared/models/bugReport/bug-report.interface";

@Injectable()
export class BugReportEffect {
  constructor(
    private actions$: Actions,
    private bugReportService: BugReportService
  ) {}

  invokeBugReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeBugReport),
      mergeMap((state) => {
        return this.bugReportService.createBugReport(state.bugReport).pipe(
          map((data: SuccessDto| null ) => createBugReport({ successDto: data })),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeFilterListBugReport$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeFilterBugReportByUser),
    mergeMap((data) => {
      return this.bugReportService.filterListBugReport(data.filterBugReport).pipe(
        map((data: ResultFilterbugReportInterface[]) =>
          filterBugReportByUser({ resultFilterBugReportDto: data })
        ),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error,
                apiStatus: "error",
              },
            })
          )
        ),
        takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
      );
    })
  )
);
invokeResultUpdateBugReportStatus$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeUpdateBugReportStatus),
    mergeMap((data) => {
      return this.bugReportService.updateBugReportStatus(data.title, data.status).pipe(
        map((data: SuccessDto | null) =>
          resultUpdateBugReportStatus({ successDto: data })
        ),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error,
                apiStatus: "error",
              },
            })
          )
        )
      );
    })
  )
);
  
invokeBugReportByRef$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeBugReportByRef),
    mergeMap((data) => {
      return this.bugReportService.getBugReportByRef(data.ref).pipe(
        map((data: BugReportInterface | null) =>
          resultBugReportByRef({ support: data })
        ),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error,
                apiStatus: "error",
              },
            })
          )
        )
      );
    })
  )
);
}
