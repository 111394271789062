import { createAction, props } from "@ngrx/store";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";
import { CreateSupplier } from "src/app/shared/models/iberis/supplier/createSupplier";
import { ResultCreateSupplier } from "src/app/shared/models/iberis/supplier/resultCreateSupplier";
export const invokeCreateSupplierInIberis = createAction(
  "[ Invoke Create A Supplier In Iberis During The Creation Of A Partner Location ] Invoke create a supplier in Iberis during the creation of a partner location",
  props<{ params: CommonParamsIberis; createSupplier: CreateSupplier }>()
);
export const resultCreateSupplier = createAction(
  " [ Result Create A Supplier In Iberis During The Creation Of A Partner Location ] Result create a supplier in Iberis during the creation of a partner location",
  props<{resultCreateSupplier : ResultCreateSupplier | null}>()
);
