<div class="modal-header justify-content-start">
  <img [src]="showlogo()" width="7%" class="m-r-10" />
  <h4 class="modal-title" id="exampleModalLabel">
    {{ title | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal()"
  ></button>
</div>
<div class="modal-body" style="padding-left: 2rem;">
  <div>
    <h6 style="color: #ff69b4">
      <U>{{ "modalDetails.campaignDetails" | translate }}</U>
    </h6>
    <div class="grid-column">
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "profile.profileDetails.companyName" | translate }}
      </h5>
      <p class="m-b-0">{{ details?.companyName ?? ("undefined"| translate) }}</p>
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "profile.profileDetails.activity" | translate }}
      </h5>
      <p class="m-b-0">{{ details?.companyActivity ?? ("undefined"| translate) }}</p>
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "profile.profileDetails.companyAddress" | translate }}
      </h5>
      <p class="m-b-0">{{ details?.companyAddress ?? ("undefined"| translate) }}</p>
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "profile.profileDetails.webSite" | translate }}
      </h5>
      <p class="m-b-0" *ngIf="details?.website == null; else exist">
        {{ "undefined"| translate }}
      </p>
      <ng-template #exist>
        <p class="m-b-0" *ngIf="details && details?.website!='' ; else none">
          <ng-container *ngFor="let chunk of details.website | newline : 40">
            {{ chunk }}<br>
          </ng-container>
        </p>
      </ng-template>
      <h5 class="f-w-600" style="font-size: 12px;">{{ "profile.profileDetails.role" | translate }}</h5>
      <p class="m-b-0">{{ details?.companyRole ?? ("undefined"| translate) }}</p>
      <ng-template #none>
        <p class="m-b-0">
          {{"undefined" | translate}}
        </p>
      </ng-template>
    </div>
  </div>
  <div style="margin-top: 10px">
    <h6 style="color: #ff69b4">
      <U>{{ "modalDetails.personalDetails" | translate }}</U>
    </h6>
    <div class="grid-column">
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "profile.profileDetails.fullName" | translate }}
      </h5>
      <p class="m-b-0">
        {{ details?.firstName ?? ("undefined"| translate) }} {{ details?.lastName ?? ("undefined"| translate) }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px;">{{ "profile.profileDetails.tel" | translate }}</h5>
      <p class="m-b-0">{{ details?.phone ?? ("undefined"| translate) }}</p>
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "profile.profileDetails.email" | translate }}
      </h5>
      <p class="m-b-0">
        {{ details?.email ?? ("undefined"| translate) }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "profile.profileDetails.birthday" | translate }}
      </h5>
      <p class="m-b-0">{{ (details?.birthday | localizedDate) ?? "--/--" }}</p>
    </div>
  </div>
  <div style="margin-top: 10px">
    <h6 style="color: #ff69b4">
      <U>{{ "profile.billingInformation.title" | translate }}</U>
    </h6>
    <div class="grid-column">
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "profile.billingInformation.legalName" | translate }}
      </h5>
      <p class="m-b-0">{{ billingInfo?.legalName ?? ("undefined"| translate) }}</p>
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "profile.billingInformation.matricule" | translate }}
      </h5>
      <p class="m-b-0">{{ billingInfo?.taxRegistration ?? ("undefined"| translate) }}</p>
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "profile.billingInformation.address" | translate }}
      </h5>
      <p class="m-b-0">{{ billingInfo?.address ?? ("undefined"| translate) }}</p>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-primary" (click)="closeModal()">
    {{ "buttons.close" | translate }}
  </button>
</div>
