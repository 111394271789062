<div class="modal-header">
  <div>
    <h4 class="modal-title" id="exampleModalLabel">
      {{ title | translate }}
    </h4>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="onClickDismissAll()"
  ></button>
</div>
<div class="modal-body">
  <div class="card-body animate-chk form-control border-0">
    <p>
      {{ "location.payment.confirmPayment" | translate }}
      <b>{{ companyName }}</b> {{ "location.payment.from" | translate }}
      <ng-template *ngIf="dateRange[0]">
        <b>{{ dateRange[0] | localizedDate }}</b>
        {{ "location.payment.to" | translate }}
        <b>{{ dateRange[1] | localizedDate }}</b></ng-template
      >
    </p>
    <div class="row m-l-15">
      <div class="media m-b-5" *ngFor="let ads of advertisingCampaigns; let i = index">
        <label class="d-block" [for]="ads.advertisingCampaignHashedId">
          <input
            class="checkbox_animated"
            [id]="ads.advertisingCampaignHashedId"
            type="checkbox"
            [name]="ads.advertisingCampaignHashedId"
            [checked]="campaignsHashedId.includes(ads.advertisingCampaignHashedId)"
            (change)="onChangeSave($event, ads.advertisingCampaignHashedId)"
          />
          {{ ads.title }}
        </label>
      </div>
    </div>
  </div>
  <!-- <ng-template #pickDates>
    <p>
      {{ "location.payment.pickDates1" | translate }}
      <b>{{ companyName }}</b> {{ "location.payment.pickDates2" | translate }}
    </p>
  </ng-template> -->
</div>
<div class="modal-footer">
  <button class="btn btn-outline-primary" (click)="onClickDismissAll()">
    {{ "buttons.close" | translate }}
  </button>
  <button class="btn btn-primary" [disabled]="campaignsHashedId.length == 0" (click)="onClickUpdatePaymentStatus()">
    {{ "buttons.confirm" | translate }}
  </button>
</div>
