export interface ExportType{
    type : ExportTypeEnum , 
    icon : string
}
export enum ExportTypeEnum {
    PNG,
    CSV
}
export const ExportTypeData : ExportType[] = [
    {
        type : ExportTypeEnum.PNG,
        icon : "assets/identity/png.svg"
    },
    {
        type : ExportTypeEnum.CSV,
        icon : "assets/identity/csv.svg"
    },
]