import { HttpEvent, HttpResponse } from "@angular/common/http";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { map, Observable, of, Subject, takeUntil } from "rxjs";
import { ObjectiveTypeEnum } from "src/app/shared/enum/objectives";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { LocationPartnerInterface } from "src/app/shared/models/location-partners/locationPartner.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { ExtensionService } from "src/app/shared/services/extension/extension.service";
import { updateAdCampaignDetailsSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import {
  invokeUpdateAdCampaignDetails,
  updateAdCampaignDetails,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeUploadFile, setFileName } from "src/app/store/file/file.actions";
import { uploadFileSelector } from "src/app/store/file/file.selectors";
import { invokeLocationsToCreateCampaign } from "src/app/store/locationPartner/actions/get-locations.actions";
import { locationPartnersToCreateCampaignResultSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { catchServerError } from "src/app/store/user/user.actions";
import { catchServerErrorSelector } from "src/app/store/user/user.selectors";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-update-campaign",
  templateUrl: "./update-campaign.component.html",
  styleUrls: ["./update-campaign.component.scss"],
})
export class UpdateCampaignComponent implements OnInit, OnDestroy {
  form: FormGroup;
  @Input() ad: AdvertisingCampaignInterface;
  validate: boolean;
  showAlert: boolean = false;
  showProgress: boolean = false;
  uploadProgress: number = 0;
  files: File[] = [];
  showError: boolean = false;
  isShown: boolean = false;
  filename: string;
  approveInProgress: boolean;
  uploadFile$: Observable<string | null>;
  uploadFileProgress$: Observable<HttpEvent<any> | null | HttpResponse<any>>;
  submitted: boolean;
  update$: Observable<SuccessDto | null>;
  updated: string;

  locationPartnersResult$: Observable<LocationPartnerInterface[] | null>;
  locations: string[];
  objectiveEnum = ObjectiveTypeEnum;

  loading: boolean = false;
  catchServerErreur$: Observable<boolean | null>;

  videoLink: string;
  ngDestroyed$: Subject<void> = new Subject<void>();
  constructor(
    private fb: FormBuilder,
    private store: Store<AppStateInterface>,
    private modalService: NgbModal,
    private extensionService: ExtensionService,
    private translate: TranslateService
  ) {
    this.form = fb.group({
      redirectionLink: [null],
      startDate: [null],
      endDate: [null],
      locations: [[], [Validators.required]],
    });
    this.update$ = this.store
      .pipe(select(updateAdCampaignDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.uploadFile$ = this.store
      .pipe(select(uploadFileSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.locationPartnersResult$ = this.store
      .pipe(select(locationPartnersToCreateCampaignResultSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.catchServerErreur$ = this.store
      .pipe(select(catchServerErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }
  ngOnDestroy(): void {
    this.ngDestroyed$.next();
    this.ngDestroyed$.unsubscribe();
  }
  loadImage(): Observable<Blob> {
    return of(environment.CDN_RELEAD + this.ad.videoLink).pipe(
      map((data) => {
        const blob = new Blob([data], {
          type: `video/${this.extensionService.getExtension(
            this.ad.videoLink
          )}`,
        });
        return blob;
      })
    );
  }
  ngOnInit(): void {
    this.catchServerErreur$.subscribe((result) => {
      if (result) {
        this.loading = false;
        this.store.dispatch(catchServerError({ serverError: null }));
      }
    });

    this.store.dispatch(invokeLocationsToCreateCampaign());
    this.form.patchValue({ redirectionLink: this.ad.redirectionLink });
    this.form.patchValue({ startDate: this.ad.startingDate });
    this.form.patchValue({ endDate: this.ad.endingDate });

    this.loadImage().subscribe((i) => {
      const myFile = new File(
        [i],
        this.translate.instant("campaignDetails.video") + "-" + this.ad.title,
        {
          type: i.type,
        }
      );
      this.files.push(myFile);
    });

    this.locationPartnersResult$.subscribe(
      (result: LocationPartnerInterface[] | null) => {
        if (result != null) {
          this.locations = result.map((value) => value.companyName);

          this.form.patchValue({ locations: this.ad.locationPartners });
        }
      }
    );
    this.videoLink = environment.CDN_RELEAD + this.ad.videoLink;
    this.uploadFile$.subscribe((result: string | null) => {
      if (result != null) {
        this.filename = result;
        this.submitted = true;
        this.store.dispatch(setFileName({ filename: null }));
      }
    });
    this.update$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.updated = result.message;
        this.loading = false;
        this.store.dispatch(updateAdCampaignDetails({ successMessage: null }));
        this.close();
      }
    });
  }
  update() {
    this.loading = true;
    let videoLink : string
    if (this.filename) {
      videoLink = this.filename;
    } else {
      videoLink = this.ad.videoLink;
    }
    let adCampaign: any = {
      title: this.ad.title,
      startingDate: this.form.get("startDate")?.value,
      endingDate: this.form.get("endDate")?.value,
      publishDate: this.ad.publishDate,
      redirectionLink: this.form.get("redirectionLink")?.value,
      status: this.ad.status,
      locationPartners: this.form.get("locations")?.value,
      views: this.ad.views,
      objectiveName: this.ad.objectiveName,
      orderReference: this.ad.orderReference,
      synthesisReport: this.ad.synthesisReport,
      videoLink: videoLink,
    };
    if (this.ad.advertisingCampaignHashedId) {
      this.store.dispatch(
        invokeUpdateAdCampaignDetails({
          campaignHashedId: this.ad.advertisingCampaignHashedId,
          adCompaign: adCampaign,
        })
      );
    }
    this.uploadProgress = 0;
    this.showProgress = false;
  }
  onSelect(event: File[]) {
    if (event[0].size > 5242880) this.showError = true;
    else {
      this.files = event;
      this.isShown = true;
    }
    const formData = new FormData();
    formData.append("files", this.files[0], this.files[0].name);
    this.store.dispatch(invokeUploadFile({ files: formData }));
  }
  onRemove() {
    this.files = [];
  }
  close() {
    this.onRemove();
    this.modalService.dismissAll();
  }
}
