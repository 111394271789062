import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import {
  AllRegionInterface,
  RegionInterface,
} from "src/app/shared/models/region/region";
import { RegionService } from "src/app/shared/services/region.service";
import { setAPIStatus } from "../apiState.interface";
import {
  createUpdateRegionSuccess,
  deleteRegionSuccess,
  invokeAllRegionByZones,
  invokeAllRegionNames,
  invokeAllRegions,
  invokeCreateRegion,
  invokeDeleteRegion,
  invokeUpdateRegion,
  resultListRegionByZones,
  setAllRegionNames,
  setAllRegions,
} from "./region.actions";
import { SuccessDto } from "src/app/shared/models/success-dto";

@Injectable()
export class RegionEffect {
  constructor(
    private actions$: Actions,
    private regionService: RegionService
  ) {}

  invokeCreateRegion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCreateRegion),
      mergeMap((state) => {
        return this.regionService.createRegion(state.region).pipe(
          map((data: SuccessDto) =>
            createUpdateRegionSuccess({ message: data.message })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeUpdateRegion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateRegion),
      mergeMap((state) => {
        return this.regionService
          .updateRegion(state.region, state.oldName)
          .pipe(
            map((data: SuccessDto) =>
              createUpdateRegionSuccess({ message: data.message })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeDeleteRegion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeDeleteRegion),
      mergeMap((state) => {
        return this.regionService.deleteRegion(state.regionName).pipe(
          map((data: SuccessDto) =>
            deleteRegionSuccess({ message: data.message })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeAllRegions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAllRegions),
      mergeMap((state) => {
        return this.regionService.getAllRegions().pipe(
          map((data: AllRegionInterface[] | null) =>
            setAllRegions({ region: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeAllRegionNames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAllRegionNames),
      mergeMap((state) => {
        return this.regionService.getAllRegionNames().pipe(
          map((data: RegionInterface[] | null) =>
            setAllRegionNames({ regionNames: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeListRegionsByZones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAllRegionByZones),
      mergeMap((state) => {
        return this.regionService.getAllRegionsByZones(state.zones).pipe(
          map((data: string[]) =>
          resultListRegionByZones({ regionsByZones: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
}
