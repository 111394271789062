<div class="modal-header">
  <h4 class="modal-title">
    {{ "campaignDetails.updateStatusCampaign" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal()"
  ></button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="form-group">
      <label class="label">{{ "updateStatus.status" | translate }}</label>
      <div class="input-group">
        <select
          class="form-select form-control digits"
          formControlName="status"
          required=""
        >
          <option [value]="null" selected>
            {{ "updateStatus.selectStatus" | translate }}
          </option>
          <option
            class="form-select"
            *ngFor="let status of campaignStatus"
            [value]="status.id"
          >
            {{ status.value | translate }}
          </option>
        </select>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-primary" (click)="closeModal()">
    {{ "buttons.close" | translate }}
  </button>
  <button class="btn btn-primary" (click)="updateStatus()">
    <loading-button
      [loading]="loading"
      [value]="'buttons.confirm'"
      [icon]="null"
    ></loading-button>
  </button>
</div>
