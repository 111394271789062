import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import { forkJoin, map, Observable, of, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeSendFilesToUser,
  resultSindingMail,
} from "src/app/store/mailing/mailing.actions";
import { ExportData } from "../../data/exportData";
import { ConsumerCountByDate } from "../../models/consumers/consumerCountByDate";
import { SendFiles } from "../../models/send-files.interface";
import { SummaryStatics } from "../../models/statistics/summary-statics";
import { JwtService } from "../../services/jwt.service";
import {
  loadingSendingEmailSelector,
  sendMailSelector,
} from "src/app/store/mailing/mailing.selectors";
import {
  ExportType,
  ExportTypeData,
  ExportTypeEnum,
} from "../../data/exportType";
import { SuccessDto } from "../../models/success-dto";
@Component({
  selector: "app-export",
  templateUrl: "./export.component.html",
  styleUrls: ["./export.component.scss"],
})
export class ExportComponent extends BaseComponent implements OnInit {
  @Input("data") data: ExportData[];
  @Input("title") title: string | null;
  //@Input("tableLocationPreview") tableLocationPreview: LocationPartnerCheck[];
  @Input("summaryStatics") summaryStatics: SummaryStatics | null;
  @Input("consumerStatistics") consumerStatistics: ConsumerCountByDate | null;
  @Input("dates") dates: Date[] | null;
  @Input("buttonName") buttonName: string;
  @Input("icon") icon: string;
  @Input("objectiveName") objectiveName: string;
  loading: boolean | null = false;
  loadingPNG: boolean | null = false;
  exportOptions: string[] = [];
  form: FormGroup;
  sendFiles: SendFiles = {
    fileName: "",
    fileContent: null,
    fileType: "",
  };
  totalFilesFoSend: SendFiles[] = [];
  fileContent: Uint8Array;
  theTitle: string | null;
  loading$: Observable<boolean | null>;
  isPng: boolean = false;
  isCsv: boolean = false;
  activeMultiSelect: ExportTypeEnum[] = [];
  exportType: ExportType[] = ExportTypeData;
  resultSendStatics$: Observable<SuccessDto | null>;
  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private translate: TranslateService,
    private store: Store<AppStateInterface>,
    private jwtService: JwtService,
  ) {
    super(store);
    this.form = fb.group({
      data: [""],
    });
    this.loading$ = this.store
      .pipe(select(loadingSendingEmailSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultSendStatics$ = this.store
      .pipe(select(sendMailSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.loading$.subscribe((data) => {
      this.loading = data;
    });
    this.resultSendStatics$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.store.dispatch(resultSindingMail({ successMessage: null }));
        this.modalService.dismissAll();
      }
    });
  }
  showExportFile(exportFile: any) {
    const modalRef = this.modalService.open(exportFile, {
      centered: true,
    });
  }

  toPng(): Observable<SendFiles[]> {
    const observables = this.data.map((e) => {
      const specificData = document.getElementById(e.id);
      if (specificData) {
        return new Observable<SendFiles>((observer) => {
          html2canvas(specificData).then((canvasScreenshot) => {
            const sendFiles: SendFiles = {
              fileName: `${this.translate.instant(e.name)}.png`,
              fileType: "png",
              fileContent: canvasScreenshot.toDataURL(),
            };

            observer.next(sendFiles);
            observer.complete();
          });
        });
      } else {
        return of([]);
      }
    });

    return forkJoin(observables).pipe(map((files) => files.flat()));
  }
  /*exportTopdf() {
    const specificData = document.getElementById(this.form.get("data")?.value);
    this.loading = true;
    if(specificData){
      html2canvas(specificData, { scale: 3 }).then((canvas) => {
        const paddingTop = 100;
        const paddingRight = 50;
        const paddingBottom = 100;
        const paddingLeft = 50;
  
        const canvasWidth = canvas.width + paddingLeft + paddingRight;
        const canvasHeight = canvas.height + paddingTop + paddingBottom;
  
        const newCanvas = document.createElement("canvas");
        newCanvas.width = canvasWidth;
        newCanvas.height = canvasHeight;
        const ctx = newCanvas.getContext("2d");
        if (ctx) {
          ctx.fillStyle = "#ffffff"; // Background color
          ctx.fillRect(0, 0, canvasWidth, canvasHeight);
          ctx.drawImage(canvas, paddingLeft, paddingTop);
        }
        const pdf = new jsPDF("p", "mm", "a4");
        const imgData = newCanvas.toDataURL("image/png");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        if (this.dates != null) {
          pdf.save(
            `${this.title}-${this.dates.map((i) =>
              i.toLocaleDateString("fr")
            )}.pdf`
          );
        } else {
          pdf.save(`${this.title}.pdf`);
        }
        this.loading = false;
        this.modalService.dismissAll();
      });
    }
  }*/
  exportToCsv(): SendFiles[] {
    this.isCsv = true;
    this.isPng = false;
    this.totalFilesFoSend = [];
    this.loading = true;
    this.data.map((e) => {
      if (e.data) {
        const csvData = e.data;
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
        if (e.dates != null) {
          if (e.dates[0] != null) {
            saveAs(
              blob,
              `${this.translate.instant(e.name)}-${this.title}-${e.dates.map(
                (i) => i.toLocaleDateString("fr")
              )}.csv`
            );
          }
        }
        this.sendFiles = {
          fileName: `${this.translate.instant(e.name)}.csv`,
          fileType: "csv",
          fileContent: e.data,
        };
        this.totalFilesFoSend = [...this.totalFilesFoSend, this.sendFiles];
      }
    });
    return this.totalFilesFoSend;
  }

  onClickSendMail() {
    this.loading = true;
    if (this.title != undefined) {
      this.theTitle = this.title;
    } else {
      this.theTitle = this.jwtService.getCompanyName();
    }
    this.activeMultiSelect.map((e) => {
      if (e == ExportTypeEnum.CSV) {
        this.totalFilesFoSend = [
          ...this.totalFilesFoSend,
          ...this.exportToCsv(),
        ];
        if (this.activeMultiSelect.length == 1) {
          this.store.dispatch(
            invokeSendFilesToUser({
              sendFiles: this.totalFilesFoSend,
              title: this.theTitle,
            })
          );
          this.activeMultiSelect = [];
          this.totalFilesFoSend = [];
        }
      }
      if (e == ExportTypeEnum.PNG) {
        this.toPng().subscribe((list) => {
          this.totalFilesFoSend = [...this.totalFilesFoSend, ...list];
          this.store.dispatch(
            invokeSendFilesToUser({
              sendFiles: this.totalFilesFoSend,
              title: this.theTitle,
            })
          );
          this.activeMultiSelect = [];
          this.totalFilesFoSend = [];
        });
      }
    });
  }
  saveOption(option: ExportTypeEnum) {
    if (!this.activeMultiSelect.includes(option)) {
      this.activeMultiSelect = [...this.activeMultiSelect, option];
    } else {
      this.activeMultiSelect = this.activeMultiSelect.filter(
        (item) => item !== option
      );
    }
  }
}
