import { createAction, props } from "@ngrx/store";
import { CreateUpdatePromotionCodeInterface } from "../../shared/models/promotion-code/promotion-code.interface";
import { ResultPromotionCodesInterface } from "../../shared/models/promotion-code/result-promotion-code.interface";
import { FilterPromotionCodeInterface } from "../../shared/models/promotion-code/filter-promotion-code.interface";
import { SuccessDto } from "../../shared/models/success-dto";
import { PromotionCodeLiteInterface } from "../../shared/models/promotion-code/check-promotion-code.interface";

export const invokePromotionCodeFilter = createAction(
  "[Invoke PromotionCode By Filter] Invoke Promotion code by filter",
  props<{ filter: FilterPromotionCodeInterface }>()
);
export const invokePromotionCodes = createAction(
  "[Promotion Codes Data] invoke Promotion Codes"
);

export const getPromotionCodesSuccess = createAction(
  "[Set Promotion Code By Filter] Set Promotion code by filter",
  props<{ result: ResultPromotionCodesInterface }>()
);

export const invokeUpdatePromotionCode = createAction(
  "[Invoke Update Promotion Code] Invoke update Promotion code",
  props<{ code: string; promotionCode: CreateUpdatePromotionCodeInterface }>()
);
export const invokeCreatePromotionCode = createAction(
  "[Invoke Create Promotion Code] Invoke create Promotion code",
  props<{ create: CreateUpdatePromotionCodeInterface }>()
);

export const invokePromotionCodeSuccess = createAction(
  "[Message Promotion Code Success] Message Promotion code success",
  props<{ successMessage: SuccessDto | null }>()
);

export const invokeDeletePromotionCode = createAction(
  "[Invoke Delete Promotion Code] Invoke Delete Promotion Code",
  props<{ code: string }>()
);

export const invokeAdHavePromotionCode = createAction(
  "[Invoke Advertising campaing have Promotion Code] invoke Advertising campaing have Promotion Code",
  props<{ havePromotion: string }>()
);

export const getAdHavePromotionCode = createAction(
  "[Result Advertising campaing have Promotion Code] Result Advertising campaing have Promotion Code",
  props<{ result: PromotionCodeLiteInterface | null }>()
);

export const invokeCheckPromotionCode = createAction(
  "[Invoke Check PromotionCode validity] Invoke Check Promotion code validity",
  props<{ code: string }>()
);
export const checkPromotionCodeSuccess = createAction(
  "[Invoke Check PromotionCode validity Success] Invoke Check Promotion code validity Success",
  props<{ result: PromotionCodeLiteInterface | null }>()
);
export const checkPromotionCodeError = createAction(
  "[Invoke Check PromotionCode validity Error] Invoke Check Promotion code validity Error",
  props<{ result: string | null }>()
);

export const invokeApplyPromotionCode = createAction(
  "[Invoke Apply PromotionCode] Invoke Apply Promotion code",
  props<{ code: string; campaignHashedId: string }>()
);
export const applyPromotionCodeSuccess = createAction(
  "[Invoke Apply PromotionCode Success] Invoke Apply Promotion code Success",
  props<{ result: string | null }>()
);
