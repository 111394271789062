import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { LocationsComponent } from "src/app/shared/components/locations/locations.component";
import { ObjectiveStatusInterface } from "src/app/shared/enum/objectives";
import {
  ObjectiveTypeEnum,
  ObjectiveTypeEnumMapping,
  ObjectiveMapping,
} from "src/app/shared/enum/objectives";
import { TotalCostWithDiscount } from "src/app/shared/models/calcul/totalCost";
import { SelectedLps } from "src/app/shared/models/location-partners/selectedLps";
import { BadgeService } from "src/app/shared/services/badge.service";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  loadingViewsSelector,
  saveSelectedLPsSelector,
} from "src/app/store/locationPartner/location-partner.selectors";
import { calculateTotalLPCostByCampanyNamesSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { CalculateCost } from "src/app/shared/models/calcul/calculateCost";
import { LocationSearchMethod } from "src/app/shared/enum/locationSearchMethod";
import { IColumn } from "src/app/shared/models/table/column.interface";
import { TotalCost } from "src/app/shared/models/calcul/totalCost";
import {
  calculateTotalLPCostByCampanyNames,
  selectedLPs,
  invokeCalculateTotalLPCostByCampanyNames,
} from "src/app/store/locationPartner/location-partner.action";
import { NewCampaignCalculatorService } from "src/app/shared/services/calculations/new-campaign.service";
import { catchServerErrorSelector } from "src/app/store/user/user.selectors";
import { catchServerError } from "src/app/store/user/user.actions";
@Component({
  selector: "app-calculator",
  templateUrl: "./calculator.component.html",
})
export class CalculatorComponent extends BaseComponent implements OnInit {
  locationSelected: TotalCost;

  form: FormGroup;
  allObjectiveNames: ObjectiveStatusInterface[];
  selectedLps: SelectedLps = {
    companyNames: [],
    isCalculator: false,
    count: 0,
  };

  startDate: Date | null;
  endDate: Date | null;
  showSelectedLPs$: Observable<SelectedLps | null>;
  calculateTotalLpCost$: Observable<TotalCostWithDiscount | null>;
  totalCost: TotalCost[] = [];
  calculateCost: CalculateCost = {
    calculationMethod: LocationSearchMethod.LOCATION_PARTNER,
    names: this.selectedLps.companyNames,
    objectiveName: ObjectiveTypeEnum.APP_PROMOTION_AND_INSTALL,
    startDate: null,
    endDate: null,
  };

  columns: IColumn[] = [
    {
      key: "",
      label: "",
      sorter: true,
    },
    {
      key: "average unique views",
      label: "admin.campaigns.simulator.averageUniqueViews",
      sorter: true,
    },
    {
      key: "average views",
      label: "admin.campaigns.simulator.averageViews",
      sorter: true,
    },
    {
      key: "Cost per day",
      label: "admin.campaigns.simulator.costPerDay",
      sorter: true,
    },
    {
      key: "Margin per day",
      label: "admin.campaigns.simulator.marginPerDay",
      sorter: true,
    },
    {
      key: "Selling price",
      label: "admin.campaigns.simulator.sellingPrice",
      sorter: true,
    },
  ];
  objective = ObjectiveMapping;
  total = {
    totalAverageUniqueViewsPerDay: 0,
    totalAverageViewsPerDay: 0,
    totalCostPerDay: 0,
    totalMarginPerDay: 0,
    totalAverageViews: 0,
    totalPrice: 0,
  };
  dateInBetween: number = 0;
  loading: boolean = false;
  loading$: Observable<boolean | null>;
  loadingFromServerErreur$: Observable<boolean>;
  today = new Date();

  catchServerErreur$: Observable<boolean | null>;
  costPerImpression: number = 0;
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    public badgeService: BadgeService,
    private store: Store<AppStateInterface>,
    private newCampaignCalculatorService: NewCampaignCalculatorService
  ) {
    super(store);
    this.form = this.fb.group({
      objective: ["", [Validators.required]],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
    });
    this.showSelectedLPs$ = this.store
      .pipe(select(saveSelectedLPsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.calculateTotalLpCost$ = this.store
      .pipe(select(calculateTotalLPCostByCampanyNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingViewsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.catchServerErreur$ = this.store
      .pipe(select(catchServerErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.allObjectiveNames = Object.keys(ObjectiveTypeEnum)
      .filter((value) => isNaN(Number(value)) === true)
      .map((key: string | any) => ({
        id: ObjectiveTypeEnum[key as keyof typeof ObjectiveTypeEnum],
        value: ObjectiveTypeEnumMapping[key as keyof typeof ObjectiveTypeEnum],
      }));
  }
  ngOnInit(): void {
    this.catchServerErreur$.subscribe((result) => {
      if (result) {
        this.loading = false;
        this.store.dispatch(catchServerError({ serverError: null }));
      }
    });
    this.form.valueChanges.subscribe((value) => {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      if (value.startDate != null && value.endDate != null) {
        const oneDay = 24 * 60 * 60 * 1000;
        this.dateInBetween = Math.round(
          Math.abs(
            (value.endDate.getTime() - value.startDate.getTime()) / oneDay
          ) + 1
        );
      }
    });
    /*this.store.dispatch(
      calculateTotalLPCostByCampanyNames({ totalCostWithDiscount: null })
    );*/
    //this.loadingFromServerErreur$.subscribe((data)=>{this.loading = data ;
    this.loading$.subscribe((data) => {
      if (data) this.loading = data;
    });
    this.store.dispatch(selectedLPs({ selectedLps: null }));
    this.showSelectedLPs$.subscribe((result: SelectedLps | null) => {
      if (result) {
        this.totalCost = [];
        this.total = {
          totalAverageUniqueViewsPerDay: 0,
          totalAverageViewsPerDay: 0,
          totalCostPerDay: 0,
          totalMarginPerDay: 0,
          totalAverageViews: 0,
          totalPrice: 0,
        };
        this.selectedLps.companyNames = result.companyNames;
      }
    });
    this.calculateTotalLpCost$.subscribe(
      (result: TotalCostWithDiscount | null) => {
        this.loading = false;
        if (result) {
          this.totalCost = result.totalCostPerDay;

          this.total.totalPrice =
            this.newCampaignCalculatorService.calculateTotalCost(
              result,
              this.dateInBetween
            );

          this.total.totalAverageUniqueViewsPerDay =
            this.newCampaignCalculatorService.calculateAverageUniqueViewsPerDay(
              result
            );

          this.total.totalAverageViewsPerDay =
            this.newCampaignCalculatorService.calculateImpressionsPerDay(
              result
            );

          this.total.totalAverageViews =
            this.newCampaignCalculatorService.calculateTotalImpressions(result, this.dateInBetween);

          this.total.totalMarginPerDay =
            this.newCampaignCalculatorService.calculateMarginPerDay(result);

          this.total.totalCostPerDay =
            this.newCampaignCalculatorService.calculateCostPerDay(result);

          if (this.total.totalAverageViews != 0)
            this.costPerImpression =
              this.total.totalPrice / this.total.totalAverageViews;
        }
      }
    );
  }
  onClickSelectLps() {
    const modal = this.modalService.open(LocationsComponent, {
      centered: true,
      size: "lg",
    });
    this.selectedLps.isCalculator = true;
    modal.componentInstance.selectedLPs = this.selectedLps.isCalculator;
  }
  onClickCalculate() {
    this.calculateCost = {
      calculationMethod: LocationSearchMethod.LOCATION_PARTNER,
      names: this.selectedLps.companyNames,
      objectiveName: this.objective[this.form.get("objective")?.value],
      startDate: this.form.get("startDate")?.value,
      endDate: this.form.get("endDate")?.value,
    };
    this.store.dispatch(
      invokeCalculateTotalLPCostByCampanyNames({
        calculateCost: this.calculateCost,
      })
    );
  }
  onClickReset() {
    this.loading = false;
    this.form.reset({
      objective: "",
      startDate: null,
      endDate: null,
    });
    this.selectedLps.companyNames = [];
    this.totalCost = [];
    this.total = {
      totalAverageUniqueViewsPerDay: 0,
      totalAverageViewsPerDay: 0,
      totalCostPerDay: 0,
      totalMarginPerDay: 0,
      totalAverageViews: 0,
      totalPrice: 0,
    };
    this.store.dispatch(
      calculateTotalLPCostByCampanyNames({ totalCostWithDiscount: null })
    );
  }
}
