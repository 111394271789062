import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-case-fsi',
  templateUrl: './case-fsi.component.html'
})
export class CaseFsiComponent implements OnInit {

  bookMeeting: string;
  constructor() { }

  ngOnInit(): void {
    this.bookMeeting = environment.links.bookMeetingLP;
  }

}
