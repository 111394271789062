<div class="modal-header">
    <div>
      <h3 class="modal-title" id="exampleModalLabel">{{ "select.selectZone" | translate}}</h3>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="onClickDismissModal()"></button>
  </div>
  <div class="modal-body">
    <div class="media-space-between m-b-10">
      <div class="form-check checkbox checkbox-primary mb-0">
        <input
          class="form-check-input m-r-10"
          id="checkbox-primary-2"
          [checked]="CheckZoneSelectedAll()"
          (click)="selectAllZones($event)"
          type="checkbox"
        />
        <label class="form-check-label" for="checkbox-primary-2"
          >{{ "select.selectAllZones" | translate}}</label
        >
      </div>
      <div class=" col-xl-5 col-5">
        <div class="faq-form" style="color: darkgrey">
          <i class="search-icon" data-feather="search"></i>
          <div>
            <div class="input-group">
              <input
                id="table-complete-search"
                type="text"
                class="form-control"
                [placeholder]="'placeholder.search' | translate"
                [(ngModel)]="zone"
                (ngModelChange)="searchTerm(zone)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let zone of getAllZones; let i = index" class="row m-t-10">
      <div class="col-4">
        <div class="form-check checkbox checkbox-primary mb-0">
          <input
            class="form-check-input m-r-10"
            [id]="zone"
            [checked]="selectedZones.includes(zone)"
            (change)="saveZones($event,zone)"
            type="checkbox"
          />
          <label class="form-check-label" [for]="zone">{{ zone }}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="submitZones()">{{ "buttons.confirm" | translate}}</button>
  </div>
  