<div class="container-fluid">
  <div class="card">
    <div class="card-header pb-0">
      <div class="media-space-between">
        <h4>{{ "admin.locations.campaigns.title" | translate }}</h4>
        <div class="media-body">
          <form class="media-space-between" [formGroup]="formFilter">
            <div class="m-r-10">
              <div
                class="faq-form mr-3"
                style="color: darkgrey; display: flex; margin-left: 10px"
              >
                <i class="search-icon" data-feather="search"></i>
                <div>
                  <div class="input-group">
                    <input
                      id="table-complete-search"
                      type="text"
                      class="form-control"
                      [placeholder]="
                        'placeholder.searchByCampaignTitle' | translate
                      "
                      style="width: 17rem"
                      formControlName="title"
                      (ngModelChange)="searchTerm()"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="m-r-10">
              <button
                class="btn btn-primary"
                type="button"
                style="width: max-content"
                (click)="addCampaign(addCampaignModal)"
              >
                {{ "buttons.addCampaign" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive data-table">
        <form>
          <table class="table table-striped table-border-vertical">
            <thead>
              <tr>
                <ng-container *ngFor="let column of columns">
                  <th
                    sortable
                    class="sortable"
                    sortable="{{ column.key }}"
                    (sort)="onSort($event)"
                  >
                    {{ column.label | translate }}
                  </th>
                </ng-container>
                <th>
                  {{ "admin.locations.campaigns.status" | translate }}
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody *ngIf="!(loading$ | async)">
              <ng-container
                *ngFor="let ad of resultAdvertisingCompainFilter; let i = index"
              >
                <tr>
                  <ng-template #none>
                    <td>
                      {{ "undefined" | translate }}
                    </td>
                  </ng-template>
                  <td *ngIf="ad.title != null; else none">
                    <a
                      type="button"
                      [routerLink]="[
                        '/',
                        'dashboard',
                        'admin',
                        'location-partners',
                        'campagnes',
                        'statistics',
                        ad.advertisingCampaignHashedId
                      ]"
                      style="color: black"
                    >
                      <td>
                        {{ ad.title }}
                      </td>
                    </a>
                  </td>
                  <td *ngIf="ad.locationPartners.length != 0; else none">
                    {{ ad.locationPartners }}
                  </td>
                  <td *ngIf="ad.objectiveName != null; else none">
                    {{
                      "enumMapping.objectives." + ad.objectiveName | translate
                    }}
                  </td>
                  <td *ngIf="ad.publishDate != null; else none">
                    {{ ad.publishDate | localizedDate : "short" }}
                  </td>
                  <td *ngIf="ad.status != null; else none">
                    <span
                      [ngClass]="
                        'badge ' +
                        badgeService.getBadgeForAdvertisingCampaingStatus(
                          ad.status
                        )
                      "
                    >
                      {{
                        "enumMapping.campaigns.status." + ad.status | translate
                      }}
                    </span>
                  </td>
                  <td>
                    <app-drob-zone-options
                      [options]="options"
                      (optionSelected)="saveOption($event, ad)"
                    ></app-drob-zone-options>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <div
            *ngIf="this.count == 0 && !(loading$ | async)"
            class="d-flex justify-content-center m-t-20"
          >
            {{ "noData" | translate }}
          </div>
          <div *ngIf="loading$ | async">
            <ng-container style="float: right" class="flex">
              <div class="loader-box">
                <div class="loader-34"></div>
              </div>
              <h6
                class="col align-self-center f-w-600"
                style="text-align: center"
              >
                {{ "fetching" | translate }}
              </h6>
            </ng-container>
          </div>
        </form>
      </div>
      <div class="table-responsive data-table">
        <form>
          <div class="d-flex">
            <div class="col-3"></div>
            <div class="col-6">
              <div class="d-flex justify-content-center p-3">
                <ngb-pagination
                  [collectionSize]="
                    (resultFilterAdvertisingCompaign$ | async)?.count ?? 0
                  "
                  [(page)]="pageNumber"
                  [pageSize]="advertisingCompaignFilter.pager.pageSize"
                  [rotate]="true"
                  [boundaryLinks]="true"
                  (pageChange)="changePage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <div class="col-3">
              <div class="d-flex justify-content-end p-3">
                <select
                  class="form-select"
                  style="width: auto"
                  name="pageSize"
                  [(ngModel)]="changePageSize"
                >
                  <option [ngValue]="5">
                    5 {{ "pagination.itemsPerPage" | translate }}
                  </option>
                  <option [ngValue]="10">
                    10 {{ "pagination.itemsPerPage" | translate }}
                  </option>
                  <option [ngValue]="15">
                    15 {{ "pagination.itemsPerPage" | translate }}
                  </option>
                  <option [ngValue]="20">
                    20 {{ "pagination.itemsPerPage" | translate }}
                  </option>
                  <option
                    [ngValue]="
                      (resultFilterAdvertisingCompaign$ | async)?.count ?? 0
                    "
                  >
                    {{ "pagination.allItems" | translate }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ng-template #addCampaignModal let-modal id="addCampaignModal">
  <form
    [ngClass]="{ 'was-validated': validate }"
    class="form theme-form"
    [formGroup]="form"
    novalidate
    (ngSubmit)="onSubmitCreateCampaign()"
  >
    <div class="modal-header">
      <h4 class="modal-title" id="exampleModalLabel">
        {{ "buttons.addCampaign" | translate }}
      </h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label class="label">{{
          "admin.campaigns.simulator.addLocations" | translate
        }}</label>
        <div class="input-group">
          <select class="form-select form-control digits" formControlName="lp">
            <option [value]="null" selected>
              {{
                "addRouter.locationInformation.placeholderLocation" | translate
              }}
            </option>
            <option
              class="form-select"
              *ngFor="let companyName of resultAllCompanyNames"
            >
              {{ companyName }}
            </option>
          </select>
        </div>
      </div>
      <div class="m-t-20">
        <div class="col">
          <label class="label">{{ "addCampaign.video" | translate }}</label>
          <ngx-dropzone
            [multiple]="false"
            (change)="onSelect($event.addedFiles)"
            accept="video/*"
          >
            <ngx-dropzone-label>
              <div>
                <div class="dz-message needsclick">
                  <i class="icon-cloud-up"></i>
                  <p style="color: black">
                    {{ "dropzoneFile.title" | translate }}
                  </p>
                  <p class="dz-message needsclick" style="color: black">
                    {{ "dropzoneFile.rulePart1" | translate }}
                    <strong [ngClass]="{ 'txt-danger': showError }"
                      >{{ "dropzoneFile.rulePart2" | translate }} 5MB</strong
                    >
                  </p>
                </div>
              </div>
            </ngx-dropzone-label>
            <ngx-dropzone-preview
              *ngFor="let f of files"
              [file]="f"
              [removable]="true"
              (removed)="onRemove()"
            >
              <ngx-dropzone-label style="align-self: flex-end; margin: 0">
                <div class="ligne-top"></div>
                <div class="new-dropdown-label">
                  {{ f.name.split(".")[0] | castFileName }} .{{
                    f.type.split("/")[1]
                  }}
                </div>
              </ngx-dropzone-label>
            </ngx-dropzone-preview>
          </ngx-dropzone>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-primary"
        data-bs-dismiss="modal"
        (click)="modal.dismiss('Cross click')"
      >
        {{ "buttons.close" | translate }}
      </button>
      <!--<button type="submit" class="btn btn-primary">
        <span
          *ngIf="approveInProgress"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
        {{ "buttons.confirm" | translate }}
      </button>-->
      <button class="btn btn-primary" [disabled]="!submitted">
        <loading-button
          [loading]="loadingCreate"
          [value]="'buttons.confirm' | translate"
          [icon]="null"
        ></loading-button>
      </button>
    </div>
  </form>
</ng-template>
