import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const bugReportState = (state: AppStateInterface) =>
  state.bugReportState;

export const bugReportSelector = createSelector(
  bugReportState,
  (state) => state.successDto
);

export const loadingBugReport = createSelector(
  bugReportState,
  (state) => state.loading
);
export const filterBugReportSelector = createSelector(
  bugReportState,
  (state) => state.filterBugReportDto
)
export const resultFilterBugReportSelector = createSelector(
  bugReportState,
  (state) => state.resultFilterBugReportDto
)
export const resultUpdateBugReportStatusSelector = createSelector(
  bugReportState,
  (state) => state.successDto
)
export const resultBugReportByRefSelector = createSelector(
  bugReportState,
  (state) => state.support
)