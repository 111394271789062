import { createAction, props } from "@ngrx/store";
import { DateFilter } from "src/app/shared/models/date-filte.interface";
import { LocationAndDateFilter } from "src/app/shared/models/LocationAndDateFilter";
import { GenderAgeStatistics } from "src/app/shared/models/statistics/gender-age-statistics";
import { GenderStatistics } from "src/app/shared/models/statistics/gender-statistics";
import { GlobalStatistics } from "src/app/shared/models/statistics/global-statistics";
import { ResultGlobalStatisticsForLP } from "src/app/shared/models/statistics/resultGlobalStaticsForLP";
import { RetentionRate } from "src/app/shared/models/statistics/retentionRate";
import { SummaryStatics } from "src/app/shared/models/statistics/summary-statics";

export const resultSummaryStatics = createAction(
  "[ Result Summary Chart In Specidfic Day ] Result summary chart in a specific day",
  props<{ summaryStatistic: SummaryStatics | null }>()
);

export const invokeSummaryStaticsInDateBetweenForAd = createAction(
  "[ Invoke Summary Statics In Date between For A Particular Ad ] Invoke summary statics in date betwenn for a particular ad ",
  props<{ campaignHashedId: string | null; filter: LocationAndDateFilter }>()
);
export const invokeAllSummaryChartInDateBetween = createAction(
  "[ Invoke Summary Statics In Date between ] Invoke Summary Statics In Date between ",
  props<{ dateFilter: DateFilter }>()
);
export const invokeSummaryChartInDayForAd = createAction(
  "[ Invoke Summary Statistics In A Specific Day For A Particular Ad ] Invoke summary statistics in a specific day for a particular ad ",
  props<{ campaignHashedId: string | null; day: string | null , locationPartner : string | null}>()
);

export const invokeSummaryStaticsInDateBetweenForLP = createAction(
  "[ Invoke Summary Chart Between Two Dates For LP] Invoke summary chart betwwen wo dates for lp",
  props<{ companyName: string | null; dateFilter: DateFilter }>()
);


export const InvokeViewsByGenderForAd = createAction(
  "[ Invoke All Views By Gender For Particular Ad ] Invoke all views by gender for particular ad ",
  props<{ campaignHashedId: string }>()
);
export const getViewsByGenderForAd = createAction(
  "[ Get All Views By Gender For Particular Ad ] Get all views by gender for particular ad ",
  props<{ gender: GenderStatistics | null }>()
);

export const invokeViewsByAgeRangeAndGenderByAd = createAction(
  "[ Invoke Views By Age And Gender For Particular Ad ] Invoke views by age and gender for particular ad ",
  props<{ campaignHashedId: string | null , filter: LocationAndDateFilter }>()
);
export const viewsByAgeRangeAndGenderByAd = createAction(
  "[ Get Views By Age And Gender For Particular Ad ] Get views by age and gender for particular ad ",
  props<{ genderAgeStatistics: GenderAgeStatistics | null }>()
);
export const invokeRedirectionByAgeAndGenderForAd = createAction(
  "[ Invoke All Clicks By Gender And Age Range For A Particular Ad ] Invoke all clicks by gender and age range for a particular ad",
  props<{ campaignHashedId: string | null }>()
);
export const redirectionByAgeAndGenderForAd = createAction(
  "[ Get All Clicks By Gender And Age Range For A Particular Ad ] Get all clicks by gender and age range for a particular ad",
  props<{ genderAgeStatistics: GenderAgeStatistics | null }>()
);
export const invokeUniqueViewsByGenderAndAgeForAd = createAction(
  "[ Invoke Unique Views By Age And Gender For Particular Ad ] Invoke unique views by age and gender for particular ad",
  props<{ campaignHashedId: string | null , filter : LocationAndDateFilter}>()
);
export const uniqueViewsByGenderAndAgeForAd = createAction(
  "[ Get Unique Views By Gender And Age For Particular Ad] Get unique views by age and gender for particular ad",
  props<{ genderAgeStatistics: GenderAgeStatistics | null }>()
);

export const invokeGlobalStatisticsByAdHashedId = createAction(
  "[ Invoke Global Statistics For A Particular Ad ] Invoke global statistics for a particular ad",
  props<{ campaignHashedId: string | null , locationPartner : string | null }>()
);
export const globalStatisticsByAdHashedId = createAction(
  "[ Get Global Statistics For A Particular Ad ] Get global statistics for a particular ad ",
  props<{ globalStatistics: GlobalStatistics | null }>()
);

export const invokeCompletedByAgeRangeAndGenderForAd = createAction(
  "[ Invoke All Finish By Gender And Age Range For A Particular Ad ] Invoke all finish by gender and age range for a particular ad ",
  props<{ campaignHashedId: string | null, filter : LocationAndDateFilter }>()
);
export const completedByAgeRangeAndGenderForAd = createAction(
  "[ Get All Finish By Gender And Age Range For A Particular Ad ] Get all finish by gender and age range for a particular ad ",
  props<{ genderAgeStatistics: GenderAgeStatistics | null }>()
);

export const invokeGlobalStatisticsForLP = createAction(
  "[ Invoke Global Statics For Particular Location Partner ] Invoke global statics for particular location partner",
  props<{ companyName: string }>()
);
export const resultGlobalStatisticsForLP = createAction(
  "[ Result Global Statics For Location Partner ] Result global statics for particular Location Partner",
  props<{ resultGlobalStatisticsForLP: ResultGlobalStatisticsForLP | null }>()
);
export const invokeUniqueConsumers = createAction(
  "[ Invoke Unique Consummers By Location Partner ] Invoke unique consummers by location partner ",
  props<{ email: string }>()
);
export const resultUniqueConsumers = createAction(
  "[ Result Unique Consummers By Location Partner ] Result unique consummers by location partner ",
  props<{ uniqueConsommers: number | null }>()
);

export const invokeConsumersByAgeAndGenderForLP = createAction(
  "[ Invoke Consumers By Gender Demographic For Location Partner ] Invoke consumers by gender demographic for location partner ",
  props<{ companyName: string}>()
);
export const resultConsumersByAgeAndGenderForLP = createAction(
  "[ Result Consumers By Gender Demographic For Location Partner For map] Result consumers by gender demographic for location partner for map",
  props<{ genderAgeStatistics: GenderAgeStatistics | null }>()
);

export const invokeViewsByGenderDemographicForLocationPartner = createAction(
  "[ Invoke Views By Gender Demographic For Location Partner ] Invoke views by gender demographic for location partner ",
  props<{ companyName: string, forMap: boolean, dateFilter : DateFilter }>()
);
export const resultViewsByGenderDemographicForLocationPartner = createAction(
  "[ Result Views By Gender Demographic For Location Partner ] Result views by gender demographic for location partner ",
  props<{ genderAgeStatistics: GenderAgeStatistics | null }>()
);


export const invokeCompletedByGenderDemographicForLocationPartner =
  createAction(
    "[ Invoke Completed By Gender Demographic For Location Partner ] Invoke completed by gender demographic for location partner",
    props<{ companyName: string , dateFilter : DateFilter}>()
  );
export const resultCompletedByGenderDemographicForLocationPartner =
  createAction(
    "[ Result Completed By Gender Demographic For Location Partner ] Result completed by gender demographic for location partner",
    props<{ genderAgeStatistics: GenderAgeStatistics | null }>()
  );
export const invokeUniqueViewsByGenderDemographicForLP = createAction(
  "[ Invoke Unique Views By Gender Demographic For LP ] Invoke unique views by gender demographic for location partner",
  props<{ companyName: string , dateFilter : DateFilter}>()
);
export const resultUniqueViewsByGenderDemographicForLocationPartner =
  createAction(
    "[ Result Unique Views By Gender Demographic For Location Partner ] Result unique views by gender demographic for location partner",
    props<{ genderAgeStatistics: GenderAgeStatistics | null }>()
  );

export const invokeUniqueViewsByListAds = createAction(
  "[ Invoke Unique Views By lis Ads ] Invoke Unique Views By lis Ads",
  props<{ titles: string[] }>()
);
export const getUniqueViewsByListAds = createAction(
  "[ Get Views By Age And Gender For Particular Ad ] Get views by age and gender for particular ad ",
  props<{ uniqueViews: number[] | null }>()
);
export const invokeRetentionRateByWeeks = createAction(
  "[  Invoke Retention Rate By Weeks] Invoke retention rate By Weeks",
  props<{ companyName: string , dateFilter : DateFilter}>()
);
export const resultRetentionRateByWeeks = createAction(
  "[ Result retension rate By weeks ] Result retention rate by weeks",
  props<{ retentionRate: RetentionRate | null }>()
);
export const invokeRetainedConsumers = createAction(
  "[ Invoke retained consumers ] invoke retained consumers",
  props<{ email: string }>()
);
export const resultRetainedConsumers = createAction(
  "[ Result retained consumers ] Result retained consumers",
  props<{ consumers: number }>()
);
export const invokeRetentionTime = createAction(
  "[ Invoke retension time ] Invoke retension time",
  props<{ email: string }>()
);
export const resultRetentionTime = createAction(
  "[ Result retention time ] result retension time",
  props<{ retentionTime: number[] | null }>()
);
export const invokeUniqueViewsByAdTitleForLP = createAction(
  "[ Invoke Unique Views By ad title for lp] Invoke unique views by ad title for lp",
  props<{titles: string[]}>()
)

export const invokeSummaryChartInDayForLP = createAction(
  "[ Invoke statics to the summary chart in a specific day for a particular location partner ] Invoke statics to the summary chart in a specific day for a particular location partner",
  props<{ companyName: string | null; day: string | null }>()
)

export const invokeSummaryChartInDay = createAction(
  "[ Invoke Statistics in a specific day ] Invoke statistics in a specific day ",
  props<{day : string | null}>()
)