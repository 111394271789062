import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";

@Component({
  selector: "app-personal-information",
  templateUrl: "./personal-information.component.html"
})
export class PersonalInformationComponent
  extends BaseComponent
  implements OnInit
{
  submitted: boolean = false;

  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;

  @Input("form") form: FormGroup;
  @Input("personalInformationSubmitted")
  personalInformationSubmitted: Observable<boolean>;

  constructor(private store: Store<AppStateInterface>) {
    super(store);
  }

  ngOnInit(): void {
    this.personalInformationSubmitted.subscribe((data) => {
      if (data) {
        this.form.get("firstName")!.markAsTouched();
        this.form.get("firstName")!.updateValueAndValidity();

        this.form.get("lastName")!.markAsTouched();
        this.form.get("lastName")!.updateValueAndValidity();

        this.form.get("email")!.markAsTouched();
        this.form.get("email")!.updateValueAndValidity();

        this.form.get("phone")!.markAsTouched();
        this.form.get("phone")!.updateValueAndValidity();

        this.form.get("password")!.markAsTouched();
        this.form.get("password")!.updateValueAndValidity();

        this.form.get("confirmPassword")!.markAsTouched();
        this.form.get("confirmPassword")!.updateValueAndValidity();
      }
    });
  }

  clickShowPassword() {
    this.showPassword = !this.showPassword;
  }

  clickShowConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
