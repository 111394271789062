<div class="row">
  <div class="col-4 col-xl-4 col-xxl-3" style="color: #575757">
    <div class="card rounded-2 h-100">
      <div class="card-body">
        <h5 style="font-weight: 600; font-size: 12px; padding: 15px 0 0">
          {{ "leadGeneration.titleDropShamp" | translate }}
          <!--<i class="icofont icofont-info-circle" style="color: darkgrey"></i>-->
        </h5>
        <p style="margin: 0; padding: 10px 0">
          {{ "leadGeneration.subTitle" | translate }}
        </p>
        <div class="example-container">
          <div
            id="items"
            cdkDropList
            [cdkDropListData]="defaultItems"
            cdkDropListConnectedTo="form"
            class="example-list"
            (cdkDropListDropped)="drop($event)"
          >
            <div
              class="example-box d-flex"
              *ngFor="let item of defaultItems"
              cdkDrag
              cdkDragHandle
            >
              <i [class]="item.logo" class="m-r-15"></i>
              {{ item.title | translate }}
              <!--<a
        type="button"
        title="Ajouter au formulaire"
        color="red"
        (click)="onClickAddToForm($event,item.title)"
      >
        <app-feather-icon [icon]="'plus'"></app-feather-icon>
      </a>-->
            </div>
          </div>
        </div>
        <div>
          <p style="padding: 10px 0; margin: 0">
            {{ "leadGeneration.personalFields.title" | translate }}
          </p>
          <p>
            <b>{{
              "leadGeneration.personalFields.allPersonalFields" | translate
            }}</b>
          </p>
          <a
            type="button"
            class="d-flex align-items-baseline ps-2 color-darkGray"
            [title]="
              'leadGeneration.personalFields.addPersonalFields' | translate
            "
            (click)="onClickShowModal(showModal)"
            data-bs-toggle="modal"
            data-bs-target="#moreDetailsModal"
          >
            <i class="icofont icofont-plus m-r-5"></i>
            <p>
              {{
                "leadGeneration.personalFields.addPersonalFields" | translate
              }}
            </p>
          </a>
          <div class="example-container">
            <div
              id="items"
              cdkDropList
              [cdkDropListData]="newItems"
              cdkDropListConnectedTo="form"
              class="example-list"
              (cdkDropListDropped)="drop($event)"
            >
              <div
                class="example-box"
                *ngFor="let item of newItems"
                [cdkDragData]="item"
                cdkDrag
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-8 col-xl-8 col-xxl-9">
    <div class="d-flex justify-content-center">
      <div class="img-contour d-flex justify-content-center align-items-center">
        <img
          src="assets/identity/logo-footer.png"
          width="60"
          height="60"
          alt="icon relead"
        />
      </div>
    </div>
    <form
      class="theme-form login-form form-control w-100 m-b-50 rounded-2"
      [formGroup]="formUpdate"
    >
      <div class="media justify-content-center">
        <textarea
          formControlName="title"
          style="font-size: 24px; padding: 0; height: 45px; font-weight: 600"
          (input)="adjustTextareaHeight()"
          (ngModelChange)="updateTitle()"
          class="custom-textarea"
          (focus)="selectTextareaText($event)"
        >
          {{ formTitle | translate }}
        </textarea>
      </div>
      <div class="media justify-content-center">
        <textarea
          formControlName="description"
          style="font-size: 18px; padding: 0; height: 45px"
          (input)="adjustTextareaHeight()"
          (ngModelChange)="updateDescription()"
          (focus)="selectTextareaText($event)"
          class="custom-textarea"
        >
          {{ description | translate }}
        </textarea>
      </div>
      <div class="form-group">
        <div class="example-container">
          <div
            id="form"
            #availableDropsite
            cdkDropList
            [cdkDropListData]="draggbleItems"
            class="example-list"
            (cdkDropListDropped)="drop($event)"
            [cdkDropListEnterPredicate]="listPredicate"
          >
            <div
              class="example-box"
              style="
                font-size: 12px;
                color: currentColor;
                padding-top: 10px;
                border: none;
              "
              *ngFor="let item of draggbleItems"
              cdkDrag
              [cdkDragData]="item"
            >
              <app-form-drag-drop
                [title]="item.title | translate"
                [available]="item"
                [icon]="item.logo"
                [type]="item.type"
                (leadGeneration)="saveLeadGeneration($event)"
              ></app-form-drag-drop>
            </div>
          </div>
          <div
            class="form-control"
            style="
              margin-top: 10px;
              font-size: small;
              border-style: dashed;
              border-color: #878585;
            "
          >
            {{ "leadGeneration.dragDropZone.title" | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-primary d-flex m-auto" type="submit">
          <textarea
            formControlName="buttonName"
            style="
              font-size: 14px;
              padding: 0;
              min-height: 20px;
              height: 20px;
              color: white;
              min-width: 10px;
            "
            (input)="adjustTextareaHeight()"
            (ngModelChange)="updateButtonName()"
            class="custom-textarea"
          >
          {{ buttonName | translate }}
        </textarea
          >
        </button>
      </div>
    </form>
  </div>
</div>
<ng-template #showModal let-modal id="showModal">
  <div class="modal-header d-block">
    <h4 class="text-center" id="exampleModalLabel" style="color: #575757">
      <b>{{ "leadGeneration.personalFields.addPersonalFields" | translate }}</b>
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body p-b-0">
    <div class="justify-content-center position-sticky row">
      <div
        *ngFor="let newFild of newFilds; let i = index"
        class="border-style rounded col-3"
        [ngClass]="{ 'p-l-0': i > 0 }"
      >
        <a
          type="button"
          class="card"
          (click)="onClickSaveOption(newFild)"
          style="color: #131313"
        >
          <div
            class="card-body"
            [ngClass]="{
              'border-active rounded p-l-0': nameFieldSelected == newFild.title
            }"
          >
            <i
              [class]="newFild.logo"
              class="items-center m-b-5 m-t-8"
              style="font-size: 2rem"
            ></i>
            <p class="text-center">{{ newFild.title | translate }}</p>
          </div>
        </a>
      </div>
      <div class="m-b-20">
        <app-add-filds
          (title)="getTitle($event)"
          (dataCheckbox)="getDataCheckbox($event)"
          (dataRadio)="getDataRadio($event)"
          [type]="typeFildSelected"
        ></app-add-filds>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      data-bs-dismiss="modal"
      (click)="modal.dismiss('Cross click')"
    >
      {{ "buttons.close" | translate }}
    </button>
    <button
      *ngIf="
        typeFildSelected == enumTypeFildSelected.CASEACHOUCHER ||
          typeFildSelected == enumTypeFildSelected.CHOIXMULTIPLE;
        else nullValues
      "
      type="button"
      class="btn btn-primary"
      data-bs-dismiss="modal"
      (click)="onClickAddfield()"
      [disabled]="
        !title || (valuesCheckBox.length == 0 && valuesRadio.length == 0)
      "
    >
      {{ "buttons.add" | translate }}
    </button>
    <ng-template #nullValues>
      <button
        type="button"
        class="btn btn-primary"
        data-bs-dismiss="modal"
        (click)="onClickAddfield()"
        [disabled]="!title"
      >
        {{ "buttons.add" | translate }}
      </button>
    </ng-template>
  </div>
</ng-template>
