import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "newline",
})
export class NewlinePipe implements PipeTransform {
  transform(value: string, length: number): string[] {
    if (!value) {
      return [];
    }
    const regex = new RegExp(`.{1,${length}}`, "g");
    const chunks = value.match(regex) || [value];
    const lastIndex = chunks.length - 1;

    return chunks.map((chunk, index) => (index === lastIndex ? chunk : chunk));
  }
}
