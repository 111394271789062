<div class="modal-header">
  <h4 class="modal-title">{{ title | translate }}</h4>
  <button type="button" class="btn-close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
  <h5>
    <p style="font-size: 14px">
      {{ "pop-ups_Message.grantPremium.part1" | translate }}
      <b>{{ locationPartner.companyName }}</b
      >{{ "pop-ups_Message.grantPremium.part2" | translate }}
    </p>
  </h5>
  <label class="label col-form-label">
    {{ "pop-ups_Message.grantPremium.choosePeriode" | translate }}
  </label>
  <form [formGroup]="form" class="w-auto needs-validation" novalidate>
    <div class="input-group">
      <div class="input-group date" id="dt-date" data-target-input="nearest">
        <input
          required=""
          class="form-control datetimepicker-input digits"
          type="text"
          formControlName="date"
          (ngModelChange)="selectDate()"
          [ngClass]="{ 'is-invalid': grantPremiumAccess.endDatePremium == null && submitted }"
          [owlDateTime]="dt"
          readonly
          placeholder="yyyy-mm-dd"
        />
        <div
          class="input-group-text bg-primary"
          data-target="#dt-date"
          [owlDateTimeTrigger]="dt"
        >
          <i class="icofont icofont-ui-calendar" style="color: white; font-size: 12px;"></i>
        </div>
      </div>
      <owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>
    </div>
    <span *ngIf="grantPremiumAccess.endDatePremium == null && submitted" class="text-danger">{{
        "pop-ups_Message.grantPremium.selectDate" | translate
      }}</span>
  </form>
  <!--<div *ngIf="loading$ | async">
        <ng-container style="float: right" class="flex">
          <div class="loader-box">
            <div class="loader-34"></div>
          </div>
          <h6
            class="col align-self-center f-w-600"
            style="text-align: center"
          >
            {{ "fetching" | translate }}
          </h6>
        </ng-container>
      </div>-->
</div>
<div class="modal-footer">
  <button class="btn btn-outline-primary" (click)="closeModal()">
    {{ "buttons.close" | translate }}
  </button>
  <button class="btn btn-primary" (click)="onClickUpdateStatus()">
    {{ "buttons.update" | translate }}
  </button>
</div>
