<div class="video-body">
  <video
    #myVideo
    controls
    loop
    autoplay
    style="padding: 10px; border-radius: 25px; object-fit: fill"
    src=""
    [width]="phone.width"
    [height]="height"
  ></video>
  <button
    *ngIf="!loadingVideo && muteExist"
    class="muteButton"
    style="outline: none"
    type="button"
    (click)="toggleMute()"
  >
    <div class="design-mute-button">
      <img
        #muteIcon
        src="assets/identity/muted-button.svg"
        alt="unmuted-button"
      />
    </div>
  </button>
  <!--<div *ngIf="loadingVideo" class="loading-video">
    <img
      style="padding: 14px"
      [width]="phone.width"
      [height]="height"
      src="assets/gifs/loading.gif"
      alt="gif"
    />
  </div>-->
  <img src="assets/identity/iphone.png" #phone [height]="height" alt="img1" />
</div>
