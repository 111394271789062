import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { DashboardSettingsInterface } from "src/app/shared/models/dashboard-settings.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { ChangePassword } from "src/app/shared/models/user/change-password.interface";
import { LocalStorageService } from "src/app/shared/services/local-storage.service";
import { UserService } from "src/app/shared/services/user.service";
import { setAPIStatus } from "../apiState.interface";
import {
  invokeCheckIfExistByEmail,
  invokeForgotPassword,
  invokeResetPassword,
  invokeDashboardSettings,
  invokeUserChangePassword,
  setCheckUserExistsByEmail,
  setForgotPasswordSuccess,
  setResetPassword,
  setDashboardSettings,
  getDashboardSettings,
  userSuccessMessage,
  invokeUserHistoryTokens,
  setUserHistoryTokens,
  invokeSubRoleByUser,
  resultSubRoleByUser,
  setForgotPasswordFailed,
  invokeCheckTokenForResetPassword,
  checkTokenForResetPasswordSuccess,
  invokeCheckIfExistByCompanyName,
  setCheckUserExistsByCompanyName,
} from "./user.actions";
import {
  TokenHistoryInterface,
  TokenHistoryResultInterface,
} from "src/app/shared/models/user/token.interface";
import { SubRoleInterface } from "src/app/shared/models/sub-role/subRole.interface";

@Injectable()
export class UserEffect {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private localStorageService: LocalStorageService
  ) {}

  invokeChangePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUserChangePassword),
      mergeMap((state) => {
        return this.userService.UpdatePassword(state.changePassword).pipe(
          map((data: SuccessDto | null) =>
            userSuccessMessage({ successMessage: data })
          ),
          catchError((result) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: result.error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeForgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeForgotPassword),
      mergeMap((state) => {
        return this.userService.forgotPassword(state.email).pipe(
          map((data: SuccessDto | null) =>
            setForgotPasswordSuccess({ successMessage: data })
          ),
          catchError((result) => {
            const actions = [
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: result.error,
                  apiStatus: "error",
                },
              }),
              setForgotPasswordFailed({
                error: { apiResponseMessage: result.error, apiStatus: "error" },
              }),
            ];
            return of(...actions);
          })
        );
      })
    )
  );

  invokeCheckTokenForResetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCheckTokenForResetPassword),
      mergeMap((state) => {
        return this.userService
          .invokeCheckTokenForResetPassword(state.token, state.email)
          .pipe(
            map((data: SuccessDto | null) =>
              checkTokenForResetPasswordSuccess({ successMessage: data })
            ),
            catchError((result) => {
              const actions = [
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                }),
                setForgotPasswordFailed({
                  error: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                }),
              ];
              return of(...actions);
            })
          );
      })
    )
  );

  invokeResetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeResetPassword),
      mergeMap((state) => {
        return this.userService.resetPassword(state.token, state.password).pipe(
          map((data: SuccessDto | null) =>
            setResetPassword({ successMessage: data })
          ),
          catchError((result) => {
            const actions = [
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: result.error,
                  apiStatus: "error",
                },
              }),
              setForgotPasswordFailed({
                error: { apiResponseMessage: result.error, apiStatus: "error" },
              }),
            ];
            return of(...actions);
          })
        );
      })
    )
  );
  invokeCheckUserExistByEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCheckIfExistByEmail),
      mergeMap((state) => {
        return this.userService.checkIfUserExistByEmail(state.email).pipe(
          map((data: boolean) => setCheckUserExistsByEmail({ isExist: data })),
          catchError((result) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: result.error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
  invokeCheckUserExistByCompanyName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCheckIfExistByCompanyName),
      mergeMap((state) => {
        return this.userService
          .checkIfUserExistByCompanyName(state.companyName)
          .pipe(
            map((data: boolean) =>
              setCheckUserExistsByCompanyName({ isExistByCompanyName: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeDashboardSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeDashboardSettings),
      mergeMap((state) => {
        return this.localStorageService
          .setSettings(state.dashboardSettings)
          .pipe(
            map((data: DashboardSettingsInterface | null) =>
              setDashboardSettings({ dashboardSettings: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
  getDashboardSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDashboardSettings),
      mergeMap((state) => {
        return this.localStorageService.getSettings().pipe(
          map((data: DashboardSettingsInterface | null) =>
            setDashboardSettings({ dashboardSettings: data })
          ),
          catchError((result) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: result.error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeUserHistoryTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUserHistoryTokens),
      mergeMap((state) => {
        return this.userService
          .getHistoryUserTokens(state.email, state.pager)
          .pipe(
            map((data: TokenHistoryResultInterface) =>
              setUserHistoryTokens({ result: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  geSubRoleByUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeSubRoleByUser),
      mergeMap((state) => {
        return this.userService.getSubRoleByUser(state.email).pipe(
          map((data: SubRoleInterface | null) =>
            resultSubRoleByUser({ subRole: data })
          ),
          catchError((result) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: result.error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
}
