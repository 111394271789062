<form [formGroup]="form">
  <div class="form-group">
    <label>{{"routers.ip" | translate}} *</label>
    <div class="input-group">
      <input
        formControlName="FASIP"
        class="form-control"
        type="text"
        required=""
        [placeholder]=" 'addRouter.routerSpecificationStep.placeholderIp' | translate "
      />
    </div>
  </div>
  <div class="form-group">
    <label>{{"routers.reverseTunnel" | translate}}*</label>
    <div class="input-group">
      <input
        formControlName="reverseTunnel"
        class="form-control"
        type="number"
        required=""
        [placeholder]="'addRouter.routerSpecificationStep.placeholderReverseTunnel' | translate"
      />
    </div>
  </div>
  <div class="form-group">
    <label>{{"routers.sessiontimeOut" | translate}}*</label>
    <select
      class="form-select form-control digits"
      formControlName="sessionTimeOut"
      required=""
    >
      <option [value]="null" selected>
        {{"addRouter.routerSpecificationStep.placeholderSessionTimeOut" | translate}}
      </option>
      <option
        class="form-select"
        [value]="sessionTimeOut.id"
        *ngFor="let sessionTimeOut of sessionTimeOutStatus"
      >
        {{ sessionTimeOut.value }}
      </option>
    </select>
  </div>
  <div class="form-group">
    <label>{{"routers.reference" | translate}}*</label>
    <select
      class="form-select form-control digits"
      formControlName="reference"
      required=""
    >
      <option [value]="null" selected> {{"addRouter.routerSpecificationStep.placeholderReference" | translate}} </option>
      <option
        class="form-select"
        *ngFor="let routerReference of routerReferenceStatus"
        [value]="routerReference.id"
      >
        {{ routerReference.value }}
      </option>
    </select>
  </div>
</form>
