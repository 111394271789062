import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ApexXAxis, ApexYAxis, ChartComponent } from "ng-apexcharts";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { GenderAgeStatistics } from "src/app/shared/models/statistics/gender-age-statistics";
import { RetentionRate } from "src/app/shared/models/statistics/retentionRate";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  consumerDemographiqueDetailsSelector, loadingGenderSelector,
} from "src/app/store/consumers/consumers.selectors";
import { Style } from "src/app/utils/style/style";
import { ChartOptions } from "../spline/spline.component";
import { DatePipe } from "@angular/common";
import { loadingStatisticsSelector } from "src/app/store/statistics/statistics.selectors";
import { consumerDemographiqueDetails } from "src/app/store/consumers/consumers.actions";
let primary_color = "#DF2281";
let secondary_color = "#895BF1";
@Component({
  selector: "app-column-chart",
  templateUrl: "./column-chart.component.html",
})
export class ColumnChartComponent extends BaseComponent implements OnInit {
  @Input("optionColumnChart") optionColumnChart: Observable<string>;
  @Input("resultOsFamily") resultOsFamily: Observable<number[] | null>;
  @Input("resultGenderAgeStatics")
  resultGenderAgeStatics: Observable<GenderAgeStatistics | null>;
  @Input("resultRetentionRate")
  resultRetentionRate: Observable<RetentionRate | null>;
  @ViewChild("chartApex", { static: false }) chartApex: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any;
  length = 0;
  isLoading$: Observable<boolean | null>;
  xaxis: ApexXAxis = {};
  yaxis: ApexYAxis = {};
  @Input() title = "";
  @Input() style: any;
  @Input() xaxisTitle = "";
  @Input() yaxisTitle = "";
  @Input() seriesTitles: string[] = [];
  @Input() labels: any;
  adTitle: string | null;
  isLoading: boolean | null = true;
  lodingGenderConsumers$ : Observable<boolean | null>
  consumerDemographicDetails$: Observable<GenderAgeStatistics | null>;
  
  constructor(
    private store: Store<AppStateInterface>,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private datePipe: DatePipe,
  ) {
    super(store);
    this.lodingGenderConsumers$ = this.store
      .pipe(select(loadingGenderSelector))
      .pipe(takeUntil(this.ngDestroyed$))
    this.isLoading$ = this.store
      .pipe(select(loadingStatisticsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.consumerDemographicDetails$ = this.store
      .pipe(select(consumerDemographiqueDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.chartOptions = {
      series: [],
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: true,
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      xaxis: {
        title: { text: "" },
        categories: [],
      },

      yaxis: {
        title: {
          text: "",
        },
      },
    };
  }
  ngOnInit(): void {
    this.length == 0
    this.lodingGenderConsumers$.subscribe((data)=>{
      this.isLoading = data
    })
    if (this.resultOsFamily != null) {
      this.resultOsFamily.subscribe((data) => {
        this.configChartOsFamily(data);
        if (data != undefined) {
          this.isLoading = false
          this.length = data?.length;
        }
      });
    }
    if (this.resultGenderAgeStatics != null) {
      this.resultGenderAgeStatics.subscribe((data) => {
        this.isLoading = false
        this.configChart(data);
        this.length == 0
        if (data != null) {
          data.female.map((e) => {
            this.length += e;
          });
          data.male.map((e) => {
            this.length += e;
          });
        }
        
      });
    }
    this.consumerDemographicDetails$.subscribe(
      (result: GenderAgeStatistics | null) => {
        if (result != null) {
          this.length == 0
          this.isLoading = false
          this.configChart(result);
          result.female.concat(result.male).forEach(e => {
            this.length += e;
          });
          this.store.dispatch(consumerDemographiqueDetails({genderAgeStatics : null}))
        }
      }
    );
    this.adTitle = this.route.snapshot.queryParamMap.get("title");

    if (this.resultRetentionRate != undefined) {
      this.resultRetentionRate.subscribe((data) => {
        if (data) {
          this.isLoading = false
          this.length = data.retentionRate?.length;
          this.configChartRetentionRate(data);
        }
      });
    }
  }
  configChart(result: any) {
    this.chartOptions = {
      ...this.chartOptions,
      series: [
        {
          name: this.translate.instant("Charts.male"),
          data: result.male,
          color: secondary_color,
        },
        {
          name: this.translate.instant("Charts.female"),
          data: result?.female,
          color: primary_color,
        },
      ],
      xaxis: {
        title: { text: this.translate.instant("Charts.years") },
        categories: Style.ageRange.map((e) => this.translate.instant(e)),
      },

      yaxis: {
        title: {
          text: this.translate.instant(this.yaxisTitle),
        },
      },
    };
  }
  configChartOsFamily(result: number[] | null) {
    this.chartOptions = {
      ...this.chartOptions,
      series: [
        {
          name: "value",
          data: result,
          color: primary_color,
        },
      ],
      xaxis: {
        title: { text: "Years" },
        categories: Style.osFamily,
      },

      yaxis: {
        title: {
          text: this.yaxisTitle,
        },
      },
    };
  }
  configChartRetentionRate(result: RetentionRate | null) {
    let weeks: [string | null, string | null][] | undefined = result?.weeks.map(
      (date: [string, string]) => {
        return [this.pipeTheDate(date[0]), this.pipeTheDate(date[1])];
      }
    );
    this.chartOptions = {
      ...this.chartOptions,
      series: [
        {
          name: this.translate.instant("Charts.retentionRate.percentage"),
          data: result?.retentionRate.map((e) => e.toFixed(2)),
          color: primary_color,
        },
      ],
      xaxis: {
        title: { text: this.translate.instant("Charts.retentionRate.weeks") },
        categories: weeks,
      },

      yaxis: {
        title: {
          text: this.translate.instant("Charts.retentionRate.yaxisTitle"),
        },
      },
    };
  }
  pipeTheDate(value: string): string | null {
    return this.datePipe.transform(
      value,
      "mediumDate",
      undefined,
      this.translate.currentLang
    );
  }
}
