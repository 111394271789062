import { createReducer, on } from "@ngrx/store";
import { RoleState } from "./roleState.interface";
import {
  invokeCreateSubRole,
  invokeDeleteSubRole,
  invokeGroupAuthorities,
  invokeRoles,
  invokeSubRole,
  invokeSubRolesNames,
  invokeUpdateSubRole,
  resultAllSubRoles,
  resultDeleteSubRole,
  setCreateUpdateSubRoleSuccess,
  setGroupAuthorities,
  setRoles,
  setSubRole,
  setSubRolesNames,
} from "./role.actions";

export const initialState: Readonly<RoleState> = {
  roleLoading: false,
  roles: [],

  subRoleLoading: false,
  subRole: null,
  subRolesNames:[],

  groupAuthoritiesLoading: false,
  groupAuthorities: [],

  success: null,
  deleteSuccess: null,
  resultSubRoles : null
};

export const RoleReducer = createReducer(
  initialState,
  on(setRoles, (state, { roles }) => ({
    ...state,
    roleLoading: false,
    roles: roles,
  })),
  on(invokeRoles, (state) => ({
    ...state,
    roleLoading: true,
  })),

  on(setSubRole, (state, { subRole }) => ({
    ...state,
    subRoleLoading: false,
    subRole: subRole,
  })),
  on(invokeSubRole, (state) => ({
    ...state,
    subRoleLoading: true,
  })),

  on(invokeSubRolesNames, (state) => ({
    ...state,
    subRoleLoading: true,
  })),
  on(setSubRolesNames, (state, { subRolesNames }) => ({
    ...state,
    subRoleLoading: false,
    subRolesNames:subRolesNames
  })),

  on(setGroupAuthorities, (state, { groupAuthorities }) => ({
    ...state,
    groupAuthoritiesLoading: false,
    groupAuthorities: groupAuthorities,
  })),
  on(invokeGroupAuthorities, (state) => ({
    ...state,
    groupAuthoritiesLoading: true,
  })),

  on(invokeCreateSubRole, (state) => ({
    ...state,
    subRoleLoading: true,
  })),
  on(invokeUpdateSubRole, (state) => ({
    ...state,
    subRoleLoading: true,
  })),
  on(setCreateUpdateSubRoleSuccess, (state, { message }) => ({
    ...state,
    success: message,
  })),
  on(invokeDeleteSubRole, (state) => ({
    ...state,
    subRoleLoading: true,
  })),
  on(resultDeleteSubRole, (state, { message }) => ({
    ...state,
    deleteSuccess: message,
  })),
  on(resultAllSubRoles, (state , {resultSubRoles})=> ({
    ...state,
    resultSubRoles : resultSubRoles
  }))
);
