import { createReducer, on } from "@ngrx/store";
import {
  completedByAgeRangeAndGenderForAd,
  getUniqueViewsByListAds,
  getViewsByGenderForAd,
  globalStatisticsByAdHashedId,
  invokeGlobalStatisticsForLP,
  invokeViewsByAgeRangeAndGenderByAd,
  redirectionByAgeAndGenderForAd,
  resultCompletedByGenderDemographicForLocationPartner,
  resultGlobalStatisticsForLP,
  resultRetentionRateByWeeks,
  resultSummaryStatics,
  resultUniqueConsumers,
  resultUniqueViewsByGenderDemographicForLocationPartner,
  resultViewsByGenderDemographicForLocationPartner,
  uniqueViewsByGenderAndAgeForAd,
  viewsByAgeRangeAndGenderByAd,
  resultRetainedConsumers,
  resultRetentionTime,
  invokeUniqueConsumers,
  invokeAllSummaryChartInDateBetween,
  invokeSummaryStaticsInDateBetweenForAd,
  invokeSummaryStaticsInDateBetweenForLP,
  invokeRetentionRateByWeeks,
  invokeUniqueViewsByAdTitleForLP,
  invokeRetainedConsumers,
  invokeRetentionTime,
  resultConsumersByAgeAndGenderForLP,
  invokeSummaryChartInDayForLP,
  invokeSummaryChartInDayForAd,
  invokeCompletedByGenderDemographicForLocationPartner,
  invokeUniqueViewsByGenderDemographicForLP,
  invokeViewsByGenderDemographicForLocationPartner,
  invokeCompletedByAgeRangeAndGenderForAd,
  invokeUniqueViewsByGenderAndAgeForAd,
  invokeSummaryChartInDay,
} from "./statistics.actions";
import { StatisticsStateInterface } from "./statisticsState.interface";

export const initialState: Readonly<StatisticsStateInterface> = {
  loading: false,
  ageStatistics: null,
  gender: null,
  summaryStatistics: null,
  loadingSummaryStatistics: true,
  genderAgeStatistics: null,
  genderAgeStatisticsForMap : null,
  globalStatistics: null,
  resultGlobalStatisticsForLP: null,
  uniqueConsommers: null,
  loadingConsumers: false,
  listUniqueViews: null,
  retentionRate: null,
  loadingGlobalStatics: true,
  consumers: 0,
  retentionTime: [],
  loadingRetentionTime: true,
  loadingRetainedConsumer: true,
};

export const StatisticsReducer = createReducer(
  initialState,
  on(invokeAllSummaryChartInDateBetween, (state) => ({
    ...state,
    loadingSummaryStatistics: true,
  })),
  on(invokeSummaryChartInDay, (state)=>({
    ...state,
    loadingSummaryStatistics : true
  })),
  on(resultSummaryStatics, (state, { summaryStatistic }) => ({
    ...state,
    summaryStatistics: summaryStatistic,
    loadingSummaryStatistics: false,
    loadingInDay : false
  })),
  on(invokeSummaryStaticsInDateBetweenForAd, (state) => ({
    ...state,
    loadingSummaryStatistics: true,
  })),
  on(invokeSummaryStaticsInDateBetweenForLP, (state) => ({
    ...state,
    loadingSummaryStatistics: true,
  })),
  on(getViewsByGenderForAd, (state, { gender }) => ({
    ...state,
    gender: gender,
    loading: false,
  })),
  on(viewsByAgeRangeAndGenderByAd, (state, { genderAgeStatistics }) => ({
    ...state,
    genderAgeStatistics: genderAgeStatistics,
    loading: false,
  })),
  on(redirectionByAgeAndGenderForAd, (state, { genderAgeStatistics }) => ({
    ...state,
    genderAgeStatistics: genderAgeStatistics,
    loading: false,
  })),
  on(uniqueViewsByGenderAndAgeForAd, (state, { genderAgeStatistics }) => ({
    ...state,
    genderAgeStatistics: genderAgeStatistics,
    loading: false,
  })),
  on(globalStatisticsByAdHashedId, (state, { globalStatistics }) => ({
    ...state,
    globalStatistics: globalStatistics,
    loading: false,
  })),
  on(completedByAgeRangeAndGenderForAd, (state, { genderAgeStatistics }) => ({
    ...state,
    genderAgeStatistics: genderAgeStatistics,
    loading: false,
  })),
  on(invokeViewsByAgeRangeAndGenderByAd, (state) => ({
    ...state,
    loading: true,
  })),
  on(invokeGlobalStatisticsForLP, (state, { companyName }) => ({
    ...state,
    loadingGlobalStatics: true,
  })),
  on(resultGlobalStatisticsForLP, (state, { resultGlobalStatisticsForLP }) => ({
    ...state,
    resultGlobalStatisticsForLP: resultGlobalStatisticsForLP,
    loadingGlobalStatics: false,
  })),
  on(invokeUniqueConsumers, (state) => ({
    ...state,
    loadingConsumers: true,
  })),
  on(resultUniqueConsumers, (state, { uniqueConsommers }) => ({
    ...state,
    uniqueConsommers: uniqueConsommers,
    loadingConsumers: false,
  })),
  on(
    resultViewsByGenderDemographicForLocationPartner,
    (state, { genderAgeStatistics }) => ({
      ...state,
      genderAgeStatistics: genderAgeStatistics,
      loading : false
    })
  ),
  on(
    resultConsumersByAgeAndGenderForLP,
    (state, { genderAgeStatistics }) => ({
      ...state,
      genderAgeStatisticsForMap: genderAgeStatistics,
      loading : false
    })
  ),
  on(
    resultCompletedByGenderDemographicForLocationPartner,
    (state, { genderAgeStatistics }) => ({
      ...state,
      genderAgeStatistics: genderAgeStatistics,
      loading : false
    })
  ),

  on(
    resultUniqueViewsByGenderDemographicForLocationPartner,
    (state, { genderAgeStatistics }) => ({
      ...state,
      genderAgeStatistics: genderAgeStatistics,
      loading: false
    })
  ),
  on(invokeUniqueViewsByAdTitleForLP, (state,{titles })=>({
    ...state,
    loading: true
  })),
  on(getUniqueViewsByListAds, (state, { uniqueViews }) => ({
    ...state,
    listUniqueViews: uniqueViews,
    loading : false
  })),
  on(invokeRetentionRateByWeeks, (state, { companyName,dateFilter  }) => ({
    ...state,
    loading: true,
  })),
  on(resultRetentionRateByWeeks, (state, { retentionRate }) => ({
    ...state,
    retentionRate: retentionRate,
    loading: false,
  })),
  on(invokeRetainedConsumers, (state, { email }) => ({
    ...state,
    loadingRetainedConsumer: true,
  })),
  on(resultRetainedConsumers, (state, { consumers }) => ({
    ...state,
    consumers: consumers,
    loadingRetainedConsumer: false,
  })),
  on(invokeRetentionTime, (state, { email }) => ({
    ...state,
    loadingRetentionTime: true,
  })),
  on(resultRetentionTime, (state, { retentionTime }) => ({
    ...state,
    retentionTime: retentionTime,
    loadingRetentionTime: false,
  })),
  on(invokeSummaryChartInDayForAd, (state,{campaignHashedId, day})=>({
    ...state,
    loadingSummaryStatistics : true
  })),
  on(invokeSummaryChartInDayForLP, (state,{companyName, day})=>({
    ...state,
    loadingSummaryStatistics : true
  })),
  on(invokeCompletedByGenderDemographicForLocationPartner, (state, {companyName})=>({
    ...state,
    loading : true
  })),
  on(invokeUniqueViewsByGenderDemographicForLP, (state, {companyName})=>({
    ...state,
    loading : true
  })),
  on(invokeViewsByGenderDemographicForLocationPartner, (state, {companyName})=>({
    ...state,
    loading : true
  })),
  on(invokeCompletedByAgeRangeAndGenderForAd, (state, {campaignHashedId})=>({
    ...state,
    loading : true
  })),
  on(invokeUniqueViewsByGenderAndAgeForAd, (state, {campaignHashedId})=>({
    ...state,
    loading : true
  })),
);
