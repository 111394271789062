import { TypeFieldsLeadGeneration } from "../enum/typeFieldsLeadGeneration";

export interface FildsData {
  title: string;
  logo: string;
  type: TypeFieldsLeadGeneration | null;
  values: string[]
}

export const NewFildsData: FildsData[] = [
  {
    title: "leadGeneration.personalFields.types.date",
    logo: "icofont icofont-ui-calendar",
    type: TypeFieldsLeadGeneration.DATE,
    values : []
  },
  {
    title: "leadGeneration.personalFields.types.multiChoise",
    logo: "icofont icofont-justify-all",
    type: TypeFieldsLeadGeneration.CHOIXMULTIPLE,
    values : []
  },
  {
    title: "leadGeneration.personalFields.types.paragraph",
    logo: "icofont icofont-paragraph",
    type: TypeFieldsLeadGeneration.PARAGRAPHE,
    values : []
  },
  {
    title: "leadGeneration.personalFields.types.radio",
    logo: "icofont icofont-check-circled",
    type: TypeFieldsLeadGeneration.CASEACHOUCHER,
    values : []
  },
];
export const defaultFieldsData : FildsData[] = [
  {
    title: "leadGeneration.fields.name",
    logo: "icofont icofont-ui-user",
    type: TypeFieldsLeadGeneration.TEXT,
    values : []
  },
  {
    title: "leadGeneration.fields.tel",
    logo: "icofont icofont-telephone",
    type: TypeFieldsLeadGeneration.NUMBER,
    values : []
  },
  {
    title: "leadGeneration.fields.email",
    logo: "icofont icofont-ui-email",
    type: TypeFieldsLeadGeneration.TEXT,
    values : []
  },
  {
    title: "leadGeneration.fields.birthday",
    logo: "icofont icofont-ui-calendar",
    type: TypeFieldsLeadGeneration.DATE,
    values : []
  },
  {
    title: "leadGeneration.fields.profession",
    logo: "icofont icofont-teacher",
    type: TypeFieldsLeadGeneration.TEXT,
    values : []
  },
]
export const InitialFieldsLeadGeneration : FildsData[] = [
  {
    title: "leadGeneration.fields.name",
    logo: "icofont icofont-ui-user",
    type: TypeFieldsLeadGeneration.TEXT,
    values : []
  },
  {
    title: "leadGeneration.fields.tel",
    logo: "icofont icofont-telephone",
    type: TypeFieldsLeadGeneration.NUMBER,
    values : []
  },
  {
    title: "leadGeneration.fields.email",
    logo: "icofont icofont-ui-email",
    type: TypeFieldsLeadGeneration.TEXT,
    values : []
  },
  {
    title: "leadGeneration.fields.birthday",
    logo: "icofont icofont-ui-calendar",
    type: TypeFieldsLeadGeneration.DATE,
    values : []
  },
  {
    title: "leadGeneration.fields.profession",
    logo: "icofont icofont-teacher",
    type: TypeFieldsLeadGeneration.TEXT,
    values : []
  },
]