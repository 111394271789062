import { createAction, props } from "@ngrx/store";
import { ResultNextInvoiceNumber } from "src/app/shared/models/iberis/facture/nextInvoiceNumber";
import { ResultFacturesIberis } from "src/app/shared/models/iberis/facture/resultFacturesIberis";
import { MarkInvoice } from "src/app/shared/models/iberis/markInvoice";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";

export const invokeAllFacturesFromIberis = createAction(
    "[Invoke all invoices from Iberis ]",
    props<{params: CommonParamsIberis, start: number, length: number , draw: number}>()
)
export const resultFacturesFromIberis = createAction(
    "[ Result invoices from iberis ] result invoices from iberis ",
    props<{resultFacturesIberis: ResultFacturesIberis | null}>()
)
export const invokeAllFacturesForClientFromIberis = createAction(
    "[Invoke all invoices for client from Iberis ] Invoke all invoices for client from Iberis",
    props<{params: CommonParamsIberis, start: number, length: number , draw: number, contactHashedId: string}>()
)
export const resultFacturesForClientFromIberis = createAction(
    "[ Result invoices from iberis ] result invoices from iberis ",
    props<{resultFacturesIberis: ResultFacturesIberis | null}>()
)
export const invokeNextInvoiceNumber = createAction(
    "[ Invoke next invoice number] Invoke next invoice number ",
    props<{params : CommonParamsIberis}>()
)
export const resultNextInvoiceNumber = createAction(
    "[ Result Next Invoice Number ] Result next invoice Number ",
    props<{nextInvoiceNumber : ResultNextInvoiceNumber | null}>()
)
export const invokeDownloadInvoice = createAction(
    "[ Invoke download Invoice ] Invoke download invoice", 
    props<{params: CommonParamsIberis, invoiceId : string}>()
)
export const resultDownloadInvoice = createAction(
    "[ Result download Invoice ] Result download invoice",
    props<{invoice : Blob}>()
)
export const invokeMarkInvoice = createAction(
    "[Invoke Mark Invoice ] Invoke mark invoice",
    props<{params: CommonParamsIberis, invoiceId: string}>()
  )
  export const resultMarkInvoice = createAction(
    "[ Result Mark Invoice ] Result mark invoice ",
    props<{resultMarkInvoice : MarkInvoice | null}>()
  )