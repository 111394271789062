import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store, select } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { FilterLocationPartnersToCreateCampaign } from "src/app/shared/models/location-partners/filterBySubCategoriesAndTagLocationPartner";
import { SelectedLps } from "src/app/shared/models/location-partners/selectedLps";
import { AllRegionInterface } from "src/app/shared/models/region/region";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeLocationPartnersByZone } from "src/app/store/locationPartner/location-partner.action";
import { initialState } from "src/app/store/locationPartner/location-partner.reducers";
import { resultLocationPartnerByRegionSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { invokeAllRegions } from "src/app/store/region/region.actions";
import { allRegionSelector } from "src/app/store/region/region.selectors";

@Component({
  selector: "app-select-export-locations",
  templateUrl: "./select-export-locations.component.html"
})
export class SelectExportLocationsComponent
  extends BaseComponent
  implements OnInit
{
  getLocationPartnersCompanyNames: string[] | null;
  selectedLocations: string[] = [];
  allRegions$: Observable<AllRegionInterface[] | null>;
  getAllRegions: AllRegionInterface[];
  regionNames: string[] = [];
  listRegion: string[][] = [];
  locationPartnersToCreateCampaignFilter: FilterLocationPartnersToCreateCampaign =
    {
      ...initialState.locationPartnersToCreateCampaignFilter,
    };
  locationPartnersToCreateCampaignFilter$: Observable<FilterLocationPartnersToCreateCampaign>;

  locationPartnersByRegion$: Observable<string[][] | null>;
  locationPartnersByRegion: string[][] = [];

  checked: boolean = false;
  selectedLps: string[] = [];
  saveSelectedLPs$: Observable<SelectedLps | null>;
  @Output() closeTheModal: EventEmitter<string[]> = new EventEmitter();

  constructor(
    private store: Store<AppStateInterface>,
    private modalService: NgbModal
  ) {
    super(store);
    this.allRegions$ = this.store
      .pipe(select(allRegionSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.locationPartnersByRegion$ = this.store
      .pipe(select(resultLocationPartnerByRegionSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.allRegions$.subscribe((result: AllRegionInterface[] | null) => {
      if (result != null) {
        this.getAllRegions = result;
        this.regionNames = result.map((e) => e.name);
        this.store.dispatch(
          invokeLocationPartnersByZone({ regionNames: this.regionNames })
        );
      } else {
        this.store.dispatch(invokeAllRegions());
      }
    });
    this.locationPartnersByRegion$.subscribe((result: string[][] | null) => {
      if (result) {
        this.locationPartnersByRegion = result;
      }
    });
  }
  selectAllRegions(event: any) {
    if (event.target.checked) {
      this.checked = true;
      Object.values(this.locationPartnersByRegion).map((value, index) => {
        this.locationPartnersByRegion[index].map((e) => {
          this.selectedLps.push(e);
        });
      });
    } else {
      this.checked = false;
      Object.values(this.locationPartnersByRegion).map((value, index) => {
        this.locationPartnersByRegion[index].map((e) => {
          this.selectedLps = this.selectedLps.filter((lp) => lp != e);
        });
      });
    }
  }

  CheckRegionSelectedAll(): boolean {
    return this.regionNames.length == this.listRegion.length;
  }

  searchTerm() {}
  submitLps() {
    this.closeTheModal.emit(this.selectedLps);
  }
  saveLPs(event: any, lp: string) {
    if (event.target.checked) this.selectedLps.push(lp);
    else this.selectedLps = this.selectedLps.filter((location) => location != lp);
  }
}
