import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-campaign-dates",
  templateUrl: "./campaign-dates.component.html",
})
export class CampaignDatesComponent implements OnInit {
  @Input() startDate: Date | null;
  @Input() endDate: Date | null;
  @Output() datesSelected = new EventEmitter<{
    startDate: Date | null;
    endDate: Date | null;
  }>();
  today = new Date();
  constructor() {}

  ngOnInit(): void {}
  dateChanges(event: Date, arg1: "endDate" | "startDate") {
    switch (arg1) {
      case "startDate":
        this.datesSelected.emit({
          startDate: event,
          endDate: this.endDate,
        });
        break;
      case "endDate":
        this.datesSelected.emit({
          startDate: this.startDate,
          endDate: event,
        });
        break;
    }
  }
}
