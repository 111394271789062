import { createAction, props } from "@ngrx/store";
import {
  CreateUpdateTagInterface,
  TagInterface,
} from "../../shared/models/tag/tag.interface";
export const invokeAllTagNames = createAction(
  "[ Invoke All Tag Names ] Invoke all tag names "
);
export const setAllTagNames = createAction(
  "[ Set All Tag Names ] Set all tag names ",
  props<{ tagNames: string[] | null }>()
);

export const invokeAllTags = createAction(
  "[ Invoke Get All Tags ] invoke get all tags"
);
export const setAllTags = createAction(
  "[ Get All Tags ] get all tags",
  props<{ tags: TagInterface[] | null }>()
);

//Create
export const invokeCreateTag = createAction(
  "[ Invoke Create Tag ] invoke create tag",
  props<{ tag: CreateUpdateTagInterface }>()
);
export const createUpdateTagSuccess = createAction(
  "[ Create Update Tag Success] create update tag success",
  props<{ message: string | null }>()
);

//Update
export const invokeUpdateTag = createAction(
  "[ Invoke Update Tag ] invoke update tag",
  props<{ tag: CreateUpdateTagInterface; oldName: string }>()
);

//Delete tag
export const invokeDeleteTag = createAction(
  "[ Invoke Delete Tags ] invoke delete Tag",
  props<{ tagName: string }>()
);
export const deleteTagSuccess = createAction(
  "[ Delete Tag Success ] delete tag success",
  props<{ message: string | null }>()
);
