import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  getAllLocationPartnersSelector,
  locationPartnersByCompanyNamesSelector,
  saveSelectedLocationsForMapSelector,
} from "src/app/store/locationPartner/location-partner.selectors";
import { LocationPartnerInterface } from "../../models/location-partners/locationPartner.interface";
import { resultViewsGenderAgeStaticsForLPForMapSelector } from "src/app/store/statistics/statistics.selectors";
import { GenderAgeStatistics } from "../../models/statistics/gender-age-statistics";
import {
  invokeConsumersByAgeAndGenderForLP,
  resultConsumersByAgeAndGenderForLP,
} from "src/app/store/statistics/statistics.actions";
import { Style } from "src/app/utils/style/style";
import {
  invokeLocationPartnersByCompanyNames,
  locationPartnerByAdTitle,
  setLocationPartnersByCompanyNames,
} from "src/app/store/locationPartner/actions/get-locations.actions";
import { ChartOptions } from "../chart/spline/spline.component";
import { TranslateService } from "@ngx-translate/core";
import { ChartComponent } from "ng-apexcharts";
import { saveSelectedLocationsForMap, selectedLPs } from "src/app/store/locationPartner/location-partner.action";

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent
  extends BaseComponent
  implements AfterViewInit, OnInit
{
  /*   @ViewChild("mapSearchField") mapSearchField: ElementRef; */

  @ViewChild("mapElement") mapElement: ElementRef;
  @Input("cords") cords: Observable<google.maps.LatLngLiteral>;
  @Input("adTitle") adTitle: string | null = null;
  @Input("listLocations") listLocations: Observable<string[] | null>;

  selectedLocation: LocationPartnerInterface;
  @Output() genderAgeStatics = new EventEmitter<GenderAgeStatistics | null>();
  genderStatsForLp$: Observable<GenderAgeStatistics | null>;
  genderStatsForLp: GenderAgeStatistics;
  columnChart = {
    title: "Données démographiques",
    yaxisTitle: "Number Of Consumers %",
    xaxisTitle: "",
    xaxis: {
      categories: Style.ageRange,
    },

    seriesTitles: [],
  };

  map: google.maps.Map;
  locationPartnerByAdTitle$: Observable<LocationPartnerInterface[] | null>;

  locationPartnersByCompantNames$: Observable<
    LocationPartnerInterface[] | null
  >;
  latitude: number = 35.825603;
  longitude: number = 10.608395;
  title: string[] = [];
  marker: google.maps.Marker;
  markers: google.maps.Marker[] = [];
  length: number = 0;
  showSelectedLPs$: Observable<string[] | null>;
  constructor(
    private store: Store<AppStateInterface>,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    super(store);
    this.locationPartnerByAdTitle$ = this.store
      .pipe(select(getAllLocationPartnersSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.locationPartnersByCompantNames$ = this.store
      .pipe(select(locationPartnersByCompanyNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.genderStatsForLp$ = this.store
      .pipe(select(resultViewsGenderAgeStaticsForLPForMapSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.showSelectedLPs$ = this.store
      .pipe(select(saveSelectedLocationsForMapSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.chartOptions = {
      series: [],
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      xaxis: {
        title: { text: "" },
        categories: [],
      },

      yaxis: {
        title: {
          text: "",
        },
      },
    };
  }
  ngOnInit(): void {
    if (this.cords)
      this.cords.subscribe((position: google.maps.LatLngLiteral) => {
        if (position) {
          const image = {
            url: "assets/identity/marker-map.png",
            anchor: new google.maps.Point(32, 31),
          };
          this.marker = new google.maps.Marker({
            position,
            map: this.map,
            title: `title`,
            icon: image,
          });
          this.marker.setMap(this.map);
        }
      });
    this.store.dispatch(
      setLocationPartnersByCompanyNames({ locationPartners: null })
    );
    this.showSelectedLPs$.subscribe((result: string[] | null) => {
      if (result) {
        this.store.dispatch(
          invokeLocationPartnersByCompanyNames({
            companyNames: result,
          })
        );
        this.store.dispatch(saveSelectedLocationsForMap({locations : null}))
      }
    });
    if (this.listLocations) {

      this.listLocations.subscribe((locations: string[] | null) => {
        if (locations) {
          this.store.dispatch(
            setLocationPartnersByCompanyNames({ locationPartners: null })
          );
          this.store.dispatch(
            invokeLocationPartnersByCompanyNames({ companyNames: locations })
          );
        }
      });
    }

    this.locationPartnerByAdTitle$.subscribe(
      (result: LocationPartnerInterface[] | null) => {
        if (result != null) {
          this.setLocations(result);
          this.store.dispatch(
            locationPartnerByAdTitle({ allLocationPartners: null })
          );
        }
      }
    );

    this.genderStatsForLp$.subscribe((gender) => {
      if (gender != null) {
        this.configChart(gender); //this.genderAgeStatics.emit(gender);
        this.length = Object.values(gender).reduce(
          (total, value) => total + value,
          0
        );
      }
    });

    this.locationPartnersByCompantNames$.subscribe(
      (result: LocationPartnerInterface[] | null) => {
        if (result != null) {
          this.setLocations(result);
        }
      }
    );
  }
  ngAfterViewInit() {
    this.map = new google.maps.Map(this.mapElement.nativeElement, {
      mapTypeId: "roadmap",
      streetViewControl: false,
      center: { lat: 35.82143, lng: 10.634422 },
      zoom: 12,
    });
  }

  setLocations(locations: LocationPartnerInterface[]) {
    this.clearMarkers();
    const image = {
      url: "assets/identity/marker-map.png",
      anchor: new google.maps.Point(32, 31),
    };
    this.latitude = locations.map((e) => e.latitude)[0];
    this.longitude = locations.map((e) => e.longitude)[0];
    this.title = locations.map((e) => e.companyName);

    this.store.dispatch(
      resultConsumersByAgeAndGenderForLP({
        genderAgeStatistics: null,
      })
    );
    const dateFilter = {
      startDate: null,
      endDate: null,
    };
    //start show first locatuion stats
    if (locations.length != 0) {
      this.selectedLocation = locations[0];
      this.store.dispatch(
        invokeConsumersByAgeAndGenderForLP({
          companyName: locations[0].companyName,
        })
      );
      this.showPopover = true;
    }

    //end show first locatuion stats

    locations.forEach((k: LocationPartnerInterface) => {
      const location: { position: google.maps.LatLngLiteral; title: string } = {
        position: { lat: k.latitude, lng: k.longitude },
        title: k.companyName,
      };
      const marker = new google.maps.Marker({
        position: location.position,
        map: this.map,
        title: location.title,
        icon: image,
      });

      //start add listeners
      marker.addListener("click", () => {
        this.isLoadingChart = true;
        this.selectedLocation = k;
        this.store.dispatch(
          resultConsumersByAgeAndGenderForLP({
            genderAgeStatistics: null,
          })
        );
        this.store.dispatch(
          invokeConsumersByAgeAndGenderForLP({
            companyName: location.title,
          })
        );
        this.showPopover = true;
      });
      //end add listeners

      //add pins
      this.markers.push(marker);
      this.setMapOnAll(this.map);
    });
  }
  showPopover: boolean = false;
  cardContent: string = "";
  setMapOnAll(map: google.maps.Map) {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(map);
    }
  }
  clearMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
    this.markers = [];
  }

  //config chart
  public chartOptions: Partial<ChartOptions> | any;
  isLoadingChart: boolean = true;
  @ViewChild("chartApex", { static: false }) chartApex: ChartComponent;
  configChart(result: GenderAgeStatistics | null) {
    this.isLoadingChart = false;
    this.chartOptions = {
      ...this.chartOptions,
      series: [
        {
          name: this.translate.instant("Charts.male"),
          data: result?.male.map((m) => m.toFixed(2)),
          color: secondary_color,
        },
        {
          name: this.translate.instant("Charts.female"),
          data: result?.female.map((f) => f.toFixed(2)),
          color: primary_color,
        },
      ],
      xaxis: {
        categories: Style.ageRange.map((e) => this.translate.instant(e)),
      },
      yaxis: {
        title: {
          text:
            this.translate.instant(
              "inseights.details.technicalData.numberOfConsumers"
            ) + " (%)",
        },
      },
    };
  }
}
let primary_color = "#DF2281";
let secondary_color = "#895BF1";
