export enum TypeFieldsLeadGeneration{
    DATE = "DATE",
    PARAGRAPHE = "PARAGRAPHE",
    CHOIXMULTIPLE = "CHOIXMULTIPLE",
    CASEACHOUCHER = "CASEACHOUCHER",
    TEXT = "TEXT",
    NUMBER = "NUMBER"
}
export enum TypeFieldsLeadGenerationToShow{
    DATE = "Date",
    PARAGRAPHE = "Paragraphe",
    CHOIXMULTIPLE = "Choix multiple",
    CASEACHOUCHER = "Case à coucher",
    TEXT = "Text",
    NUMBER = "Number"
}
export interface TypeFieldsLeadGenerationInterface{
    id: TypeFieldsLeadGeneration,
    value: string
}