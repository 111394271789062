import { createReducer, on } from "@ngrx/store";
import {
  getAllFixedCharges,
  invokeUpdateFixedCharge,
  crudFixedChargeSuccess,
  invokeCreateFixedCharge,
  invokeDeleteFixedCharge,
  invokeAllFixedCharges,
} from "./fixed-charge.actions";
import { FixedChargeStateInterface } from "./fixed-chargeState.interface";

export const initialState: Readonly<FixedChargeStateInterface> = {
  fixedCharges: null,
  fetchLoading: null,
  successMessage: null,
  isLoading: false,
};
export const FixedChargeReducer = createReducer(
  initialState,
  on(invokeAllFixedCharges, (state) => ({
    ...state,
    fetchLoading: true,
  })),
  on(getAllFixedCharges, (state, { fixedCharges }) => ({
    ...state,
    fetchLoading: false,
    fixedCharges: fixedCharges,
  })),
  on(invokeCreateFixedCharge, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(invokeUpdateFixedCharge, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(invokeDeleteFixedCharge, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(crudFixedChargeSuccess, (state, { message }) => ({
    ...state,
    isLoading: false,
    successMessage: message,
  }))
);
