import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { Store } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";
import { setAPIStatus } from "../apiState.interface";
import { SupplierService } from "src/app/shared/services/supplier.service";
import { invokeCreateSupplierInIberis, resultCreateSupplier } from "./suppliers.actions";
import { ResultCreateSupplier } from "src/app/shared/models/iberis/supplier/resultCreateSupplier";

@Injectable()
export class SupplierEffect {
  constructor(
    private actions$: Actions,
    private store: Store<AppStateInterface>,
    private supplierService: SupplierService
  ) {}

  invokeListOrdersFromIberis$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeCreateSupplierInIberis),
    mergeMap((data) => {
      return this.supplierService.createEstimate(data.params,data.createSupplier).pipe(
        map((data: ResultCreateSupplier | null) =>
        resultCreateSupplier({ resultCreateSupplier: data })
        ),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error,
                apiStatus: "error",
              },
            })
          )
        )
      );
    })
  )
);

}
