import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FSIRouterStatus, FSIRouterStatusInterface, FSIRouterStatusToShow } from "src/app/shared/enum/fsi-router-status";
import {
  InternetProvider,
  InternetProviderInterface,
  InternetProvidersToShow,
} from "src/app/shared/enum/internet-providers";
@Component({
  selector: "app-fsi-router",
  templateUrl: "./fsi-router.component.html"
})
export class FsiRouterComponent implements OnInit {
  @Input("form") form: FormGroup;
  routerStatus: FSIRouterStatusInterface[];
  internetProviverStatus: InternetProviderInterface[];
  constructor() {}

  ngOnInit(): void {
    this.routerStatus = Object.keys(FSIRouterStatus)
      .filter((value) => isNaN(Number(value)) === true)
      .map((key: string | any) => ({
        id: FSIRouterStatus[key as keyof typeof FSIRouterStatus],
        value: FSIRouterStatusToShow[key as keyof typeof FSIRouterStatus],
      }));
    this.internetProviverStatus = Object.keys(InternetProvider)
      .filter((value) => isNaN(Number(value)) === true)
      .map((key: string | any) => ({
        id: InternetProvider[key as keyof typeof InternetProvider],
        value: InternetProvidersToShow[key as keyof typeof InternetProvider],
      }));
  }
}
