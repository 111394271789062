import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgApexchartsModule } from "ng-apexcharts";

import { HeaderComponent } from "./components/header/header/header.component";
import { DashboardComponent } from "./components/layout/dashboard/dashboard.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";

import { NavService } from "./services/nav.service";
import { FeatherIconComponent } from "./components/feather-icon/feather-icon.component";
import { RouterModule } from "@angular/router";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";
import { FooterComponent } from "./components/footer/footer.component";
import { BookmarkComponent } from "./components/header/header/bookmark/bookmark.component";
import { MaximizeComponent } from "./components/header/header/maximize/maximize.component";
import { AccountComponent } from "./components/header/header/account/account.component";
import { ModeComponent } from "./components/header/header/mode/mode.component";
import {
  NgbModule,
  NgbProgressbarConfig,
  NgbProgressbarModule,
} from "@ng-bootstrap/ng-bootstrap";
import { LoaderComponent } from "./components/loader/loader.component";
import { FullComponent } from "./components/layout/full/full.component";
import { AngularSvgIconModule } from "angular-svg-icon";
import { DecimalPipe } from "@angular/common";
import { SearchComponent } from "./components/header/header/search/search.component";
import { SearchCustomizeComponent } from "./components/header/header/search-customize/search-customize.component";
import { AuthComponent } from "./components/layout/auth/auth.component";
import { CardComponent } from "./components/card/card.component";
import { SplineComponent } from "./components/chart/spline/spline.component";
import { LanguageComponent } from "./components/header/header/language/language.component";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { SidebarTypeComponent } from "./components/header/header/sidebar-type/sidebar-type.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { UpdateProfileComponent } from "./components/profile/update-profile/update-profile.component";
import { OverviewComponent } from "./components/profile/overview/overview.component";
import { ChangePasswordComponent } from "./components/profile/change-password/change-password.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbdSortableHeader } from "./directive/sortable.directive";
import { PasswordValidatorComponent } from "./components/password-validator/password-validator.component";
import { PhonePreviewComponent } from "./components/phone-preview/phone-preview.component";
import { ToKValue } from "./pipe/to-k-value.pipe";
import { ColumnChartComponent } from "./components/chart/column-chart/column-chart.component";
import { DonutComponent } from "./components/chart/donut/donut.component";
import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";
import { PieComponent } from "./components/chart/pie/pie.component";
import { CardInseightsComponent } from "./components/card/card-inseights/card-inseights.component";
import { FormDragDropComponent } from "../components/advertiser/advertising-campaign/new-campaign/form/form-drag-drop/form-drag-drop.component";
import { SettingsComponent } from "./components/header/header/settings/settings.component";
import { MapComponent } from "./components/map/map.component";
import { ExportComponent } from "./components/export/export.component";
import { PopoverMapComponent } from "./components/popover-map/popover-map.component";
import { GoogleMapsModule } from "@angular/google-maps";
import { SplineFilterComponent } from "./components/filter/spline-filter/spline-filter.component";
import { ColumnLocationPartnersComponent } from "./components/column-location-partners/column-location-partners.component";
import { DaysBetweenDatesPipe } from "./pipe/days-between-dates.pipe";
import { CastFileNamePipe } from "./pipe/cast-file-name.pipe";
import { AdVideoPreviewComponent } from "./components/ad-video-preview/ad-video-preview.component";
import { NgxAbstractControlAsModule } from "ngx-abstract-control-as";
import { NgxMaskModule } from "ngx-mask";
import { UpdatePictureComponent } from "./components/profile/update-picture/update-picture.component";
import { NgxDropzoneModule } from "ngx-dropzone";
import { ImageCropperModule } from "ngx-image-cropper";
import { SupportStatusFilterComponent } from "./components/filter/support-status-filter/support-status-filter.component";
import { ClickOutsideDirective } from "./components/click-outside.directive";
import { BillingInfoComponent } from "./components/profile/billing-info/billing-info.component";
import { BugReportComponent } from "./components/bug-report/bug-report.component";
import { CreateBugReportComponent } from "./components/bug-report/create-bug-report/create-bug-report.component";
import { WelcomeCardComponent } from "./components/welcome-card/welcome-card.component";
import { HelpComponent } from "./components/help/help.component";
import {
  DateTimeAdapter,
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeIntl,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from "@danielmoncada/angular-datetime-picker";
import { AudienceComponent } from "./components/audience/audience.component";
import { TechnicalDataComponent } from "./components/technical-data/technical-data.component";
import { DrobZoneOptionsComponent } from "./components/drob-zone-options/drob-zone-options.component";
import { DetailsModalComponent } from "./components/details-modal/details-modal.component";
import { ApproveModalComponent } from "./components/modal/approve-modal/approve-modal.component";
import { DeleteComponent } from "./components/drob-zone-options/modal/delete/delete.component";
import { UpdateComponent } from "./components/drob-zone-options/modal/update/update.component";
import { BarChartComponent } from "./components/chart/bar-chart/bar-chart.component";
import { DetailsComponent } from "./components/drob-zone-options/modal/details/details.component";
import { VideoAdComponent } from "./components/video-ad/video-ad.component";
import { UploadFilesComponent } from "./components/upload-files/upload-files.component";
import { ModalComponent } from "./components/modal/modal.component";
import { AdvertisersComponent } from "./components/advertisers/advertisers.component";
import { DetailsAdComponent } from "./components/details-modal/details-ad/details-ad.component";
import { LocalizedDatePipe } from "./pipe/localized-date.pipe";
import { RegionComponent } from "./components/region/region.component";
import { ZoneComponent } from "./components/zone/zone.component";
import { SubCategoriesComponent } from "./components/sub-categories/sub-categories.component";
import { DetailsLpComponent } from "./components/details-modal/details-lp/details-lp.component";
import { GeneralFilterComponent } from "./components/general-filter/general-filter.component";
import { SliceStringPipe } from "./pipe/slice-string.pipe";
import { AdVideoPreviewStatisticsComponent } from "./components/ad-video-preview/ad-video-preview-statistics/ad-video-preview-statistics.component";
import { DetailsDiscutionSupportComponent } from "./components/bug-report/details-discution-support/details-discution-support.component";
import { DefaultIntl } from "./data/owl-date/default-intl";
import { LoadingButtonComponent } from "./components/loading-button/loading-button.component";
import { NewlinePipe } from "./pipe/new-line.pipe";
import { AdsLocationsComponent } from "./components/ads-locations/ads-locations.component";
@NgModule({
  declarations: [
    HeaderComponent,
    DashboardComponent,
    SidebarComponent,
    FeatherIconComponent,
    BreadcrumbComponent,
    TapToTopComponent,
    FooterComponent,
    BookmarkComponent,
    MaximizeComponent,
    AccountComponent,
    ModeComponent,
    LoaderComponent,
    FullComponent,
    SearchComponent,
    SearchCustomizeComponent,
    AuthComponent,
    CardComponent,
    SplineComponent,
    LanguageComponent,
    SidebarTypeComponent,
    LogoutComponent,
    ProfileComponent,
    UpdatePictureComponent,
    UpdateProfileComponent,
    OverviewComponent,
    ChangePasswordComponent,
    PhonePreviewComponent,
    NgbdSortableHeader,
    PasswordValidatorComponent,
    ToKValue,
    LocalizedDatePipe,
    SliceStringPipe,
    DaysBetweenDatesPipe,
    CastFileNamePipe,
    NewlinePipe,
    ColumnChartComponent,
    DonutComponent,
    ProgressBarComponent,
    PieComponent,
    CardInseightsComponent,
    FormDragDropComponent,
    SettingsComponent,
    MapComponent,
    ExportComponent,
    PopoverMapComponent,
    SplineFilterComponent,
    ColumnLocationPartnersComponent,
    AdVideoPreviewComponent,
    SupportStatusFilterComponent,
    ClickOutsideDirective,
    BillingInfoComponent,
    BugReportComponent,
    CreateBugReportComponent,
    WelcomeCardComponent,
    HelpComponent,
    AudienceComponent,
    TechnicalDataComponent,
    DrobZoneOptionsComponent,
    DetailsModalComponent,
    ApproveModalComponent,
    DeleteComponent,
    UpdateComponent,
    BarChartComponent,
    DetailsComponent,
    VideoAdComponent,
    UploadFilesComponent,
    ModalComponent,
    AdvertisersComponent,
    ClickOutsideDirective,
    DetailsAdComponent,
    RegionComponent,
    ZoneComponent,
    SubCategoriesComponent,
    DetailsLpComponent,
    GeneralFilterComponent,
    AdVideoPreviewStatisticsComponent,
    DetailsDiscutionSupportComponent,
    LoadingButtonComponent,
    AdsLocationsComponent,
  ],
  imports: [
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgApexchartsModule,
    AngularSvgIconModule.forRoot(),
    TranslateModule,
    NgSelectModule,
    GoogleMapsModule,
    NgxAbstractControlAsModule,
    NgxMaskModule.forRoot(),
    NgxDropzoneModule,
    ImageCropperModule,
    NgbProgressbarModule,
  ],
  providers: [
    {
      provide: OWL_DATE_TIME_LOCALE,
      deps: [TranslateService],
      useFactory: getLang,
    },
    {
      provide: OwlDateTimeIntl,
      useClass: DefaultIntl,
    },
    /* {
      provide:  DateTimeAdapter,
      useClass: DefaultDateTimeAdapter,

    }, */
    NavService,
    DecimalPipe,
    NgbProgressbarConfig,
    DatePipe,
  ],
  exports: [
    RouterModule,
    NgxDropzoneModule,
    TranslateModule,
    NgbProgressbarModule,
    LanguageComponent,
    BreadcrumbComponent,
    TapToTopComponent,
    FeatherIconComponent,
    DashboardComponent,
    LoaderComponent,
    NgbModule,
    AngularSvgIconModule,
    CardComponent,
    CardInseightsComponent,
    SplineComponent,
    ColumnChartComponent,
    DonutComponent,
    PieComponent,
    ProgressBarComponent,
    NgSelectModule,
    PhonePreviewComponent,
    NgbdSortableHeader,
    PasswordValidatorComponent,
    ToKValue,
    LocalizedDatePipe,
    SliceStringPipe,
    DaysBetweenDatesPipe,
    CastFileNamePipe,
    NewlinePipe,
    FormDragDropComponent,
    MapComponent,
    ExportComponent,
    PopoverMapComponent,
    SplineFilterComponent,
    ColumnLocationPartnersComponent,
    AdVideoPreviewComponent,
    BookmarkComponent,
    SupportStatusFilterComponent,
    BillingInfoComponent,
    BugReportComponent,
    WelcomeCardComponent,
    HelpComponent,
    AudienceComponent,
    TechnicalDataComponent,
    DrobZoneOptionsComponent,
    DetailsModalComponent,
    ClickOutsideDirective,
    DetailsAdComponent,
    DetailsLpComponent,
    GeneralFilterComponent,
    AdVideoPreviewStatisticsComponent,
    LoadingButtonComponent,
  ],
})
export class SharedModule {}

export function getLang(translate: TranslateService) {
  return translate.currentLang;
}
