import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ExtensionService {
  constructor() {}

  getExtension(url: string) {
    if (url) {
      const lastDotIndex = url.lastIndexOf(".");
      const extension = url.substring(lastDotIndex + 1);
      return extension;
    }else{
      return null
    }
  }
}
