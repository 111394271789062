import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { BaseComponent } from "src/app/base.component";
import { Observable, takeUntil } from "rxjs";
import { AppStateInterface } from "src/app/store/appState.interface";
import { select, Store } from "@ngrx/store";
import {
  loadingBugReport,
  filterBugReportSelector,
  resultFilterBugReportSelector,
} from "src/app/store/bug-report/bug-report.selectors";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  filterBugReportByUser,
  invokeFilterBugReportByUser,
} from "src/app/store/bug-report/bug-report.action";
import { IColumn } from "src/app/shared/models/table/column.interface";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/app/shared/directive/sortable.directive";
import { FilterListBugReport } from "src/app/shared/models/bugReport/filter-list-bug-report.interface";
import { ResultFilterbugReportInterface } from "src/app/shared/models/bugReport/result-filter-bug-report.interface";
import { initialState } from "src/app/store/bug-report/bug-report.reducers";
import { JwtService } from "src/app/shared/services/jwt.service";
import { BadgeService } from "src/app/shared/services/badge.service";
import {
  bugReportStatus,
  BugReportStatusInterface,
  bugReportToShow,
} from "src/app/shared/enum/bugReport-status";
import { RoleEnum } from "../../models/user/role.interface";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  ActionInterface,
  ActionReturned,
  bugRepportActions,
  OptionActionEnum,
} from "../../data/actions-data";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApproveModalComponent } from "../modal/approve-modal/approve-modal.component";
import { environment } from "src/environments/environment";
import { bugReportFilter, FilterInterface } from "../../data/filter-data";
import { invokeApiDistroyed } from "src/app/store/apiState.interface";
@Component({
  selector: "app-bug-report",
  templateUrl: "./bug-report.component.html",
})
export class BugReportComponent extends BaseComponent implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers!: QueryList<NgbdSortableHeader>;
  @Output() optionBugReportStatus = new EventEmitter<string>();
  loading$: Observable<boolean | null>;
  cdnURL = environment.CDN_RELEAD;
  columns: IColumn[] = [
    {
      key: "ref",
      label: "support.ref",
      sorter: true,
    },
    {
      key: "description",
      label: "support.subject",
      sorter: true,
    },
    {
      key: "creationDate",
      label: "support.lastUpdate",
      sorter: true,
    },
    {
      key: "fileName",
      label: "support.fileName",
      sorter: true,
    },
  ];
  filterListBugReport$: Observable<FilterListBugReport>;
  filterListBugReportByUser: FilterListBugReport = {
    ...initialState.filterBugReportDto,
  };
  isFiltring: boolean;
  resultFilterListBugReport$: Observable<
    ResultFilterbugReportInterface[] | null
  >;
  resultFilterListBugReport: ResultFilterbugReportInterface[] | null;
  formFilter: FormGroup;
  interface: BugReportStatusInterface[];
  isAdvertiser: boolean;
  role = RoleEnum;
  roleName: string;
  options: ActionInterface[] = bugRepportActions;
  currentUser: RoleEnum;

  requestFeat: string;
  filterSupport: FilterInterface = bugReportFilter;
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    public jwtService: JwtService,
    public badgeService: BadgeService,
    private route: Router,
    protected translate: TranslateService,
    private modalService: NgbModal
  ) {
    super(store);
    this.loading$ = this.store
      .pipe(select(loadingBugReport))
      .pipe(takeUntil(this.ngDestroyed$));
    this.filterListBugReport$ = this.store
      .pipe(select(filterBugReportSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultFilterListBugReport$ = this.store
      .pipe(select(resultFilterBugReportSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.formFilter = this.fb.group({
      title: [""],
    });

    this.roleName = this.jwtService.getRoleName(this.jwtService.getToken());
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isAdvertiser = this.route.url.includes("/advertiser");
        if (this.isAdvertiser) {
          this.currentUser = RoleEnum.ROLE_ADVERTISER;
          this.filterListBugReportByUser.roleName = RoleEnum.ROLE_ADVERTISER;
        } else {
          this.filterListBugReportByUser.roleName = RoleEnum.ROLE_LOCATION;
          this.currentUser = RoleEnum.ROLE_LOCATION;
        }
        if (this.roleName == RoleEnum.ROLE_ADMIN) {
          this.roleName = RoleEnum.ROLE_ADMIN;
          this.store.dispatch(
            invokeFilterBugReportByUser({
              filterBugReport: {
                ...this.filterListBugReportByUser,
              },
            })
          );
        } else {
          this.store.dispatch(
            invokeFilterBugReportByUser({
              filterBugReport: {
                ...this.filterListBugReportByUser,
                email: this.jwtService.getEmail(),
                roleName: this.currentUser,
              },
            })
          );
        }
      }
    });
  }
  ngOnInit(): void {
    this.filterSupport.name.map(
      (e) =>
        (e.data = Object.keys(bugReportStatus)
          .filter((value) => isNaN(Number(value)) === true)
          .map(
            (key: string | any) =>
              bugReportToShow[key as keyof typeof bugReportStatus]
          ))
    );
    this.requestFeat = environment.links.requestFeat;
    this.store.dispatch(
      filterBugReportByUser({ resultFilterBugReportDto: null })
    );
    this.resultFilterListBugReport$.subscribe(
      (result: ResultFilterbugReportInterface[] | null) => {
        this.resultFilterListBugReport = result;
      }
    );
    if (this.roleName == RoleEnum.ROLE_ADMIN) {
      if (this.isAdvertiser) {
        this.currentUser = RoleEnum.ROLE_ADVERTISER;
        this.filterListBugReportByUser.roleName = RoleEnum.ROLE_ADVERTISER;
      } else {
        this.filterListBugReportByUser.roleName = RoleEnum.ROLE_LOCATION;
        this.currentUser = RoleEnum.ROLE_LOCATION;
      }
    } else {
      this.filterListBugReportByUser.email = this.jwtService.getEmail();
    }
  }
  onSort({ column, direction }: SortEvent) {
    this.store.dispatch(invokeApiDistroyed());
    this.headers.forEach((header) => {
      if (column != header.sortable) {
        header.direction = "";
      }
    });
    var col: string | null = column;
    var sort = null;
    switch (direction) {
      case "asc":
        sort = true;
        break;
      case "desc":
        sort = false;
        break;
      default:
        sort = true;
        col = null;
    }
    if (direction != "")
      if (this.isAdvertiser) {
        this.filterListBugReportByUser.roleName = RoleEnum.ROLE_ADVERTISER;
      } else {
        this.filterListBugReportByUser.roleName = RoleEnum.ROLE_LOCATION;
      }
    if (this.roleName == RoleEnum.ROLE_ADMIN) {
      this.roleName = RoleEnum.ROLE_ADMIN;
      this.store.dispatch(
        invokeFilterBugReportByUser({
          filterBugReport: {
            ...this.filterListBugReportByUser,
            sortName: col,
            isAscending: sort,
          },
        })
      );
    } else {
      this.store.dispatch(
        invokeFilterBugReportByUser({
          filterBugReport: {
            ...this.filterListBugReportByUser,
            email: this.jwtService.getEmail(),
            sortName: col,
            isAscending: sort,
          },
        })
      );
    }
  }
  saveOption(option: string) {
    this.store.dispatch(invokeApiDistroyed());
    let filter: bugReportStatus | null = null;
    const KeyFound = Object.keys(bugReportToShow).find(
      (key) => bugReportToShow[key as bugReportStatus] === option
    );
    switch (KeyFound) {
      case bugReportStatus.IN_PROGRESS:
        filter = bugReportStatus.IN_PROGRESS;
        break;
      case bugReportStatus.RESOLVED:
        filter = bugReportStatus.RESOLVED;
        break;
      default:
        filter = null;
    }
    if (this.isAdvertiser) {
      this.filterListBugReportByUser.roleName = RoleEnum.ROLE_ADVERTISER;
    } else {
      this.filterListBugReportByUser.roleName = RoleEnum.ROLE_LOCATION;
    }
    if (this.roleName == RoleEnum.ROLE_ADMIN) {
      this.roleName = RoleEnum.ROLE_ADMIN;
      this.store.dispatch(
        invokeFilterBugReportByUser({
          filterBugReport: {
            ...this.filterListBugReportByUser,
            status: filter,
          },
        })
      );
    } else {
      this.store.dispatch(
        invokeFilterBugReportByUser({
          filterBugReport: {
            ...this.filterListBugReportByUser,
            email: this.jwtService.getEmail(),
            roleName: this.roleName,
            status: filter,
          },
        })
      );
    }
  }
  searchTerm() {
    this.store.dispatch(invokeApiDistroyed());
    this.isFiltring = true;
    if (this.isAdvertiser) {
      this.filterListBugReportByUser.roleName = RoleEnum.ROLE_ADVERTISER;
    } else {
      this.filterListBugReportByUser.roleName = RoleEnum.ROLE_LOCATION;
    }
    if (this.roleName == RoleEnum.ROLE_ADMIN) {
      this.roleName = RoleEnum.ROLE_ADMIN;
      this.store.dispatch(
        invokeFilterBugReportByUser({
          filterBugReport: {
            ...this.filterListBugReportByUser,
            title: this.formFilter.get("title")?.value,
          },
        })
      );
    } else {
      this.store.dispatch(
        invokeFilterBugReportByUser({
          filterBugReport: {
            ...this.filterListBugReportByUser,
            email: this.jwtService.getEmail(),
            roleName: this.roleName,
            title: this.formFilter.get("title")?.value,
          },
        })
      );
    }
  }
  saveAction(
    action: ActionReturned,
    bugReport: ResultFilterbugReportInterface
  ) {
    const modal = this.modalService.open(ApproveModalComponent, {
      centered: true,
    });
    modal.componentInstance.reference = bugReport.title;
    modal.componentInstance.status = bugReport.status;
    modal.componentInstance.name = "pop-up.bugReport.title";
    modal.componentInstance.title = action.option.name;
    modal.componentInstance.type = OptionActionEnum.ADMIN_BUG_REPORT
    modal.componentInstance.icon = false;
    modal.componentInstance.role = this.currentUser;
    modal.componentInstance.closeTheModal.subscribe((value: boolean) =>
      modal.close()
    );
  }
}
