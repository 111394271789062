<div
  class="product-wrapper"
  [class.sidebaron]="openSidebar"
  style="height: 30px"
  appClickOutside
  (clickOutside)="onOutsideClick()"
>
  <div class="product-grid">
    <div class="feature-prod'ucts">
      <div class="pro-filter-sec">
        <div
          class="product-sidebar"
          style="height: 45px"
          [class.open]="openSidebar"
        >
          <div class="filter-section">
            <div class="card" style="box-shadow: none">
              <a
                type="button"
                [ngClass]="(filter.type == filterType.CHART_FILTER) || (filter.type == filterType.ESTIMATE_FILTER) ? 'project-box' : 'project-box border-0 m-auto pt-1 pb-0'"
                style="background-color: transparent;"
                (click)="sidebarToggle()"
              >
                <i class="icon-filter" style="color: #ff69b4"></i>
              </a>
              <div class="left-filter">
                <div class="card-body filter-cards-view animate-chk">
                  <div class="product-filter">
                    <p class="m-0">{{ "filter.Options" | translate }}</p>
                    <hr style="margin: 0.3rem 0;" />
                    <div
                      class="dropdown"
                      *ngFor="let option of filter.name; let i = index"
                    >
                      <a
                        style="font-size: 12px"
                        id="dropdownMenuButton"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        (click)="openMenu(option.type, i)"
                      >
                        {{ option.nameField | translate }}
                        <i
                          *ngIf="activeOptions[option.type]"
                          class="fa fa-check font-primary"
                        ></i>
                      </a>
                      <hr
                        *ngIf="i < filter.name.length-1"
                        class="p-0"
                        style="margin: 0.3rem 0;"
                      />
                      <div
                        *ngIf="option.isDropdown"
                        class="dropdown-menu"
                        [class.show]="showOption[i]"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a class="dropdown-item">
                          <div class="filter-cards-view animate-chk">
                            <div class="product-filter">
                              <div class="list-group">
                                <ng-container *ngFor="let item of option.data ; let index = index">
                                  <span style="color: black" class="p-2">
                                    <a
                                      (click)="saveOption(item, option)"
                                      type="button"
                                      ><span>
                                        {{ item | translate }}
                                      </span>
                                      <i
                                        *ngIf="
                                          activeMultiSelect.includes(item);
                                          else singleChoise
                                        "
                                        class="fa fa-check font-primary"
                                      ></i>
                                      <ng-template #singleChoise>
                                        <i
                                          class="fa fa-check font-primary"
                                          *ngIf="active[i] == item"
                                        ></i>
                                      </ng-template>
                                    </a>
                                  </span>
                                  <hr *ngIf="index < (option.data.length - 1)" style="padding: 0; margin: 0" />
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
