import { createAction, props } from "@ngrx/store";
import { AdvertiserDashboard } from "src/app/shared/models/advertiser/advertiser-dashboard.interface";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import { Advertiser } from "src/app/shared/models/advertiser/advertiser.interface";
import { Account } from "src/app/shared/models/user/account";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { ResultFilterAdvertiser } from "src/app/shared/models/advertiser/result-filter-advertiser.interface";
import { Register } from "src/app/shared/models/advertiser/register.interface";
import { Publisher } from "src/app/shared/models/advertiser/publisher.interface";
import { FormUpdateAdvertiser } from "src/app/shared/models/advertiser/form-update-advertiser.interface";
import { ApproveDeactivateAdvertiser } from "src/app/shared/models/advertiser/approve-deactivate-advertiser.interface";
import { subRole } from "src/app/shared/models/sub-role/subRole";
import { FieldsLeadGeneration } from "src/app/shared/models/leadGeneration/fieldsLeadGeneration";
import { ApiStateInterface } from "../apiState.interface";
import { LeadGeneration } from "src/app/shared/models/leadGeneration/leadGeneration";
import { FilterListAdvertiser } from "src/app/shared/models/advertiser/filter-list-advertiser.interface";
import { FildsData } from "src/app/shared/data/newFieldsFormData";

//Advertiser Registration

export const invokeConfirmEmail = createAction(
  "[ Invoke Confirm Email For Registration ] Invoke confirm email For Registration",
  props<{ token: string }>()
);
export const confirmEmailForRegistrationSuccess = createAction(
  "[ Confirm Email For Registration ] Confirm email for registration",
  props<{ successMessage: SuccessDto | null }>()
);
export const confirmEmailForRegistrationFailed = createAction(
  "[ Confirm Email For Registration Failed ] Confirm email for registration Failed",
  props<{ result: ApiStateInterface }>()
);

export const regenerateConfirmRegestration = createAction(
  "[ Regenerate Confirm Email For Registration ] Confirm email for registration",
  props<{ token: string }>()
);
export const regenerateConfirmRegestrationSuccess = createAction(
  "[ Regenerate Confirm Email For Registration Success ] Confirm email for registration Success",
  props<{ successMessage: SuccessDto }>()
);

export const regenerateConfirmRegestrationFailed = createAction(
  "[ Regenerate Confirm Email For Registration Success ] Confirm email for registration Success",
  props<{ result: ApiStateInterface }>()
);

export const invokeRegister = createAction(
  "[Invoke register] Invoke register",
  props<{ advertiser: Advertiser }>()
);
export const registerSuccess = createAction(
  "[Set Register] Set register",
  props<{ successMessage: SuccessDto | null }>()
);

export const invokeMainDashboard = createAction(
  "[Invoke Main Dashboard] Invoke Advertiser's Dashboard"
);
export const setMainDashboard = createAction(
  "[Set Main Dashboard] get main dashboard",
  props<{ dashboardAdvertiser: AdvertiserDashboard | null }>()
);

export const invokeProfileDetails = createAction(
  "[Invoke Profile Details] invoke advertiser's profile"
);
export const setProfileDetails = createAction(
  "[set Profile Details] invoke advertiser's profile",
  props<{ profileDetails: AdvertiserDetailsInterface | null }>()
);

export const invokeAccount = createAction(
  "[Invoke Account] invoke Account advertisser"
);
export const setAccount = createAction(
  "[Set Account] set Account Advertisser",
  props<{ account: Account }>()
);

export const invokeAllAdvertiserByFilter = createAction(
  "[ Invoke All Advertiser By Filter ] Invoke all advertisers by filter",
);


export const invokeAdvertiserFilter = createAction(
  "[ Invoke Advertiser  Filter ] Invoke advertisers filter",
  props<{ filter: FilterListAdvertiser }>()
);
export const getAllAdvertiserByFilter = createAction(
  "[ get All Adertiser By Filter ] Get all advertiser by filter",
  props<{ resultFilterAdvertiser: ResultFilterAdvertiser | null }>()
);


export const invokeApproveAdvertiser = createAction(
  "[ Invoke Approve Particular Advertiser's Account Using His Email ] Invoke Approve Particular Advertiser's Account Using His Email",
  props<{ dto: ApproveDeactivateAdvertiser | null }>()
);
export const approveAdvertiserByEmail = createAction(
  "[ Approve Particular Advertiser's Account Using His Email] Approve Particular Advertiser's Account Using His Email",
  props<{ successMessage: SuccessDto | null }>()
);
export const invokeAdvertiserByAdHashedId = createAction(
  "[ Invoke Advertiser By AdHashedId ] Invoke Advertiser By AdHashedId",
  props<{ campaignHashedId: string }>()
);
export const advertiserByAdHashedId= createAction(
  "[ Advertiser By Title ] Advertiser By Title",
  props<{ profileDetails: AdvertiserDetailsInterface | null }>()
);
export const invokeDeleteAdvertiser = createAction(
  "[ Invoke Delete Advertiser ] Invoke Delete Advertiser",
  props<{ email: string }>()
);
export const resultDeleteAdvertiser = createAction(
  "[ result invoke Delete Advertiser ] result invoke Delete Advertiser",
  props<{ successMessage: SuccessDto | null }>()
);

export const selectedAdvertiser = createAction(
  "[ Save selected advertiser ] Save selected advertiser ",
  props<{ selectedAdvertiser: string[] | null }>()
);

export const invokeUpdateSubRoleForAdvertiser = createAction(
  "[ Invoke Update Sub Role For Advertiser ] Invoke update sub role for advertiser",
  props<{ email: string; subRole: subRole }>()
);
export const resultUpdateSubRoleForAdvertiser = createAction(
  "[ Result Update SubRole For Advertiser ] Result update sub role for advertiser",
  props<{ successMessage: SuccessDto | null }>()
);
export const invokeIdIberis = createAction(
  "[ Invoke Id Iberis] Invoke id iberis"
);
export const resultIdIberis = createAction(
  "[ Result Id Iberis] Result id iberis",
  props<{ idIberis: string | null }>()
);

// lead generation
export const saveFieldsLeadGeneration = createAction(
  "[ Invoke Save Fields Lead Generation ] Invoke save fields lead generation ",
  props<{ fieldLeadGeneration: FieldsLeadGeneration | null }>()
);
export const saveLeadGeneration = createAction(
  "[ save Lead generation ] save lead generattion",
  props<{ saveFormLeadGeneration: LeadGeneration | null }>()
);
export const saveNewFieldsLeadGeneration = createAction(
  "[ Save new fields lead generation ] save new fields lead generation",
  props<{ newFieldsLeadGeneration : FildsData[] | null}>()
)

export const invokeAllActivatedAdvertisers = createAction(
  "[ Invoke All Activated advertisers ] Invoke all activated advertisers"
);
export const resultAllActivatedAdvertisers = createAction(
  "[ Result all Activated advertisers ] Result all activated advertisers",
  props<{ publishers: AdvertiserDetailsInterface[] | null }>()
);

export const invokeUpdateAdvertiser = createAction(
  "[ Invoke Update Advertiser ] Invoke Update Advertiser",
  props<{ email: string; profileDetails: AdvertiserDetailsInterface }>()
);
export const resultUpdateAdvertiser = createAction(
  "[ Result Update Advertiser] Result Update Advertiser",
  props<{ successMessage: SuccessDto | null }>()
);
