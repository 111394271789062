<a
  *ngIf="objectiveName != 'GENERATION_DE_PROSPECTS'; else prospect"
  class="btn btn-outline-primary project-box"
  data-bs-toggle="modal"
  [ngbTooltip]="'export.title' | translate"
  data-bs-target="#exportFile"
  (click)="showExportFile(exportFile)"
>
  <i class="icon-export"></i>
</a>

<ng-template #prospect>
  <div class="dartboard">
    <button
      class="img-dartboard btn btn-primary media-body"
      data-bs-toggle="modal"
      data-bs-target="#exportFile"
      (click)="showExportFile(exportFile)"
    >
      <img src="assets/identity/{{ icon }}" alt="download" />
      <div class="content-secondary" style="width: max-content">
        {{ buttonName }}
      </div>
    </button>
  </div>
</ng-template>

<ng-template #exportFile let-modal id="exportFile">
  <div class="modal-header">
    <h4 class="modal-title" id="exampleModalLabel">
      {{ "title-export" | translate }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body p-b-0">
    <p style="text-align: left">
      {{ "exportDescription1" | translate }} <br>
      {{ "exportDescription2" | translate }}
    </p>
    <div class="media-space-between justify-content-center">
      <div
        *ngFor="let type of exportType"
        class="border-style ps-2"
        style="width: 30%"
      >
        <a type="button" class="card" (click)="saveOption(type.type)" fileSaver>
          <div
            class="card-body"
            [ngClass]="{
              'border-active rounded p-l-0': activeMultiSelect.includes(
                type.type
              )
            }"
          >
            <img class="img" [src]="type.icon" alt="PNG" style="width: 70%" />
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      data-bs-dismiss="modal"
      (click)="modal.dismiss('Cross click')"
    >
      {{ "buttons.close" | translate }}
    </button>
    <button
      type="button"
      [disabled]="activeMultiSelect.length == 0"
      class="btn btn-primary"
      (click)="onClickSendMail()"
    >
      <loading-button
        [loading]="loading"
        [value]="'buttons.confirm' | translate"
        [icon]="null"
      ></loading-button>
    </button>
  </div>
</ng-template>
