export interface Fields {
  nameField: string;
  isDropdown: boolean;
  type: typeFilter;
  isMultiSelect: boolean;
  data : string[]
}

export interface FilterInterface {
  name: Fields[];
  type: FilterEnum;
  isMultiSelect: boolean;
}

export interface ActionReturned {
  option: FilterInterface;
  value: any;
}

export enum FilterEnum {
  ADMIN_ADVERTISER_FILTER,

  ADMIN_CAMPAIGN_ADVERTISER_FILTER,

  ADMIN_ROUTER_FILTER,

  BUG_REPORT_FILTER,

  ADMIN_LOCATION_FILTER,
  PAYMENT_LP_FILTER,

  ESTIMATE_FILTER,
  CHART_FILTER = "CHART_FILTER",
}
export enum typeFilter {
  LOCATION,
  ZONE,
  CATEGORY,
  ADVERTISER,
  REGION,
  OPTION,
  PAYMENT,
  COLUMNCHART,
  SPLINECHART,
  STATUS,
  OBJECTIVE
}
export const advertiserFilter: FilterInterface = {
  name: [
    {
      nameField: "filterOptions.calendar.status",
      isDropdown: true,
      type: typeFilter.OPTION,
      isMultiSelect: true,
      data : []
    },
  ],
  type: FilterEnum.ADMIN_ADVERTISER_FILTER,
  isMultiSelect: true,
};
export const bugReportFilter: FilterInterface = {
  name: [
    {
      nameField: "filterOptions.calendar.status",
      isDropdown: true,
      type: typeFilter.OPTION,
      isMultiSelect: false,
      data : []
    },
  ],
  type: FilterEnum.BUG_REPORT_FILTER,
  isMultiSelect: true,
};
export const locationFilter: FilterInterface = {
  name: [
    {
      nameField: "enumMapping.lp.option",
      type: typeFilter.OPTION,
      isDropdown: true,
      isMultiSelect: false,
      data : []
    },
    {
      nameField: "enumMapping.lp.region",
      isDropdown: false,
      type: typeFilter.REGION,
      isMultiSelect: true,
      data : []
    },
    {
      nameField: "enumMapping.lp.zone",
      type: typeFilter.ZONE,
      isDropdown: false,
      isMultiSelect: true,
      data : []
    },
    /*{
      nameField: "enumMapping.lp.category",
      type: typeFilter.CATEGORY,
      isDropdown: false,
      isMultiSelect: true,
      data : []
    },*/
  ],
  type: FilterEnum.ADMIN_LOCATION_FILTER,
  isMultiSelect: true,
};
export const columnChartFilter: FilterInterface = {
  name: [
    {
      nameField: "filter.splineFilter.metrics",
      type: typeFilter.COLUMNCHART,
      isDropdown: true,
      isMultiSelect: false,
      data : []
    },
  ],
  type: FilterEnum.CHART_FILTER,
  isMultiSelect: false,
};
export const splineChartFilter: FilterInterface = {
  name: [
    {
      nameField: "filter.splineFilter.metrics",
      type: typeFilter.SPLINECHART,
      isDropdown: true,
      isMultiSelect: false,
      data : []
    },
  ],
  type: FilterEnum.CHART_FILTER,
  isMultiSelect: false,
};
export const paymentLPFilter: FilterInterface = {
  name: [
    {
      nameField: "filterOptions.calendar.status",
      isDropdown: true,
      type: typeFilter.OPTION,
      isMultiSelect: false,
      data : []
    },
  ],
  type: FilterEnum.PAYMENT_LP_FILTER,
  isMultiSelect: false,
};
export const advertisingCampaignsFilter: FilterInterface = {
  name: [
    {
      nameField: "filterOptions.calendar.status",
      isDropdown: true,
      type: typeFilter.OPTION,
      isMultiSelect: true,
      data : []
    },
  ],
  type: FilterEnum.ADMIN_CAMPAIGN_ADVERTISER_FILTER,
  isMultiSelect: false,
};
export const estimatesFilter: FilterInterface = {
  name: [
    {
      nameField: "filterOptions.calendar.status",
      isDropdown: true,
      type: typeFilter.OPTION,
      isMultiSelect: false,
      data : []
    },
  ],
  type: FilterEnum.ESTIMATE_FILTER,
  isMultiSelect: false,
};
export const routersFilter: FilterInterface = {
  name: [
    {
      nameField: "filterOptions.calendar.status",
      isDropdown: true,
      type: typeFilter.OPTION,
      isMultiSelect: true,
      data : []
    },
  ],
  type: FilterEnum.ADMIN_ROUTER_FILTER,
  isMultiSelect: false,
};
export const calendarFilter: FilterInterface = {
  name: [
    {
      nameField: "filterOptions.calendar.status",
      isDropdown: true,
      type: typeFilter.OPTION,
      isMultiSelect: true,
      data : []
    },
    {
      nameField: "filterOptions.calendar.locations",
      type: typeFilter.LOCATION,
      isDropdown: false,
      isMultiSelect: true,
      data : []
    },
    {
      nameField: "filterOptions.calendar.advertisers",
      isDropdown: false,
      type: typeFilter.ADVERTISER,
      isMultiSelect: true,
      data : []
    },
  ],
  type: FilterEnum.ADMIN_LOCATION_FILTER,
  isMultiSelect: true,
};
export const advertisingCampaignsFilter_Advertiser: FilterInterface = {
  name: [
    {
      nameField: "filterOptions.calendar.status",
      isDropdown: true,
      type: typeFilter.STATUS,
      isMultiSelect: true,
      data : []
    },
    {
      nameField: "campaignDetails.objective",
      isDropdown: true,
      type: typeFilter.OBJECTIVE,
      isMultiSelect: true,
      data : []
    },
  ],
  type: FilterEnum.ADMIN_CAMPAIGN_ADVERTISER_FILTER,
  isMultiSelect: false,
};
