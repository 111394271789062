import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../data/constants";
import { BillingInterface } from "../models/billing/billing.interface";
import { SuccessDto } from "../models/success-dto";
@Injectable({
  providedIn: "root",
})
export class BillingService {
  constructor(private http: HttpClient) {}

  createUpdateBillingInfo(
    billingInfo: BillingInterface | null
  ): Observable<SuccessDto> {
    return this.http.post<SuccessDto>(
      `${environment.APP_PORT + Constants.BILLING_ENDPOINT}/create-update`,
      billingInfo
    );
  }

  getBillingInfo(email: string): Observable<BillingInterface> {
    return this.http.get<BillingInterface>(
      `${environment.APP_PORT + Constants.BILLING_ENDPOINT}/get/${email}`
    );
  }
}
