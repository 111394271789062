import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { Account } from '../models/user/account';
import { Constants } from '../data/constants'
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  endPoint = environment.APP_PORT + Constants.USER_ENDPOINT;
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient) { }

  Login(user: any) {
    return this.http.post(
      this.endPoint + "/login",
      user
    );
  }

  CurrentUser() {
    return this.http.get<Account | null>(
      this.endPoint + "/info",
      this.httpOptions);
  }

  Logout() {
    return this.http.get<any>(
      this.endPoint + "/logoutUser"
    );
  }

  LogoutAllSessions() {
    return this.http.get<any>(
      this.endPoint + "/logoutUser/allSessions"
    );
  }
}
