import { createAction, props } from "@ngrx/store";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";
import { NextPayment } from "src/app/shared/models/iberis/paymentsReceived/nextPayment";
import { ParamsPaymentReceived } from "src/app/shared/models/iberis/paymentsReceived/paramsPaymentRecaived";
import { PaymentReceived } from "src/app/shared/models/iberis/paymentsReceived/paymentReceived";

export const invokePayment = createAction(
    "[ Invoke Payment ] Invoke payment ",
    props<{params : CommonParamsIberis , paymentReceived : ParamsPaymentReceived}>()
);
export const resultPaymentReceived = createAction(
    "[ Result Payment ] Result payment ",
    props<{resultPaymentReceived : PaymentReceived | null}>()
)