import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-access-information',
  templateUrl: './access-information.component.html'
})
export class AccessInformationComponent implements OnInit {
  @Input("form") form: FormGroup;
  @Input() isUpdate : boolean
  show: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }
  showPassword() {
    this.show = !this.show;
  }
}
