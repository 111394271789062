<div class="row">
  <ng-container>
    <app-welcome-card
      [result]="ads"
      [loading$]="loadingGlobalStatics$ | async"
      [firstName]="(profile$ | async)?.firstName"
      [lastName]="(profile$ | async)?.lastName"
    ></app-welcome-card>
    <div class="col-md-4 col-xl-3 col-sm-6">
      <app-card
        [title]="impressionCard.title"
        [icon]="impressionCard.icon"
        [value]="impressionCard.value"
        [class]="'eye'"
        [isLoading]="loadingGlobalStatics$ | async"
      >
      </app-card>
    </div>
    <div class="col-md-4 col-xl-3 col-sm-6">
      <app-card
        [title]="vueUniqueCard.title"
        [icon]="vueUniqueCard.icon"
        [value]="vueUniqueCard.value"
        [class]="'eye'"
        [changes]="vueUniqueCard.changes"
        [growth]="vueUniqueCard.growth"
        [hasYesterdayGrowth]="vueUniqueCard.hasYesterdayGrowth"
        [isLoading]="loadingGlobalStatics$ | async"
      >
      </app-card>
    </div>
    <div class="col-md-4 col-xl-3 col-sm-6">
      <app-card
        [title]="utilisateurUniqueCard.title"
        [icon]="utilisateurUniqueCard.icon"
        [value]="utilisateurUniqueCard.value"
        [class]="'eye'"
        [changes]="utilisateurUniqueCard.changes"
        [growth]="utilisateurUniqueCard.growth"
        [hasYesterdayGrowth]="utilisateurUniqueCard.hasYesterdayGrowth"
        [isLoading]="loadingUniqueConsumers$ | async"
      >
      </app-card>
    </div>
    <div class="col-md-4 col-xl-3 col-sm-6">
      <app-card
        [title]="routersCard.title"
        [icon]="routersCard.icon"
        [value]="routersCard.value"
        [class]="'eye'"
        [changes]="routersCard.changes"
        [growth]="routersCard.growth"
        [hasYesterdayGrowth]="routersCard.hasYesterdayGrowth"
        [isLoading]="loadingGlobalStatics$ | async"
      >
      </app-card>
    </div>
    <app-help [link]="bookMeeting" [type]="'lp'"></app-help>
  </ng-container>
  <app-audience [inputAudience$]="inputAudience" [isPremium] = 'true'></app-audience>
  <app-latest-ad [companyName]="LPcompanyName"></app-latest-ad>
</div>
