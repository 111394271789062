<div class="card">
  <div class="card-header pb-0">
    <div class="media-space-between">
      <h4>{{ "admin.locations.repository.title" | translate }}</h4>
      <div class="media-body">
        <form class="d-inline-flex" [formGroup]="formFilter">
          <div class="w-auto" style="margin-left: 5px">
            <div
              class="input-group date"
              id="dt-date"
              data-target-input="nearest"
              style="width: 17rem"
            >
              <div
                class="input-group-text bg-primary"
                data-target="#dt-date"
                [owlDateTimeTrigger]="dt"
              >
                <i
                  class="icofont icofont-ui-calendar"
                  style="color: white; font-size: 12px"
                ></i>
              </div>
              <input
                formControlName="date"
                class="form-control datetimepicker-input digits"
                type="text"
                name="daterange"
                [placeholder]="'placehorderPickDate' | translate"
                [selectMode]="'range'"
                [owlDateTimeTrigger]="dt"
                [owlDateTime]="dt"
                (ngModelChange)="FilterDate()"
              />
            </div>
            <owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>
          </div>
          <div class="m-r-10">
            <div
              class="faq-form mr-3"
              style="color: darkgrey; display: flex; margin-left: 10px"
            >
              <i class="search-icon" data-feather="search"></i>
              <div>
                <div class="input-group">
                  <input style="width: 14rem;"
                    id="table-complete-search"
                    type="text"
                    class="form-control"
                    [placeholder]="
                      'placeholder.searchByCompanyName' | translate
                    "
                    formControlName="companyName"
                    (ngModelChange)="searchCompanyName()"
                  />
                  <span
                    class="input-group-text p-0"
                    style="background-color: transparent"
                  >
                    <app-general-filter
                      [filter]="filterPayment"
                      (optionSelected)="saveOptionFilter($event)"
                    ></app-general-filter>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="table-responsive data-table">
      <form>
        <table class="table table-striped table-border-vertical">
          <thead>
            <tr>
              <th
                [rowSpan]="rowSpan"
                sortable
                class="sortable"
                sortable="companyName"
              >
                {{"admin.locations.repository.locations" | translate}}
              </th>
              <ng-container *ngFor="let column of columns">
                <th [rowSpan]="rowSpan">
                  {{ column.label | translate }}
                </th>
              </ng-container>
              <th [rowSpan]="rowSpan">
                {{ "buttons.actions" | translate }}
              </th>
            </tr>
          </thead>
          <tbody *ngIf="!loading">
            <ng-container
              *ngFor="
                let lp of locationPartners;
                let i = index
              "
            >
              <tr>
                <td>{{ lp.companyName }}</td>
                <td *ngIf="lp.pilotPhaseEndDate !=null ; else none">{{ lp.pilotPhaseEndDate | localizedDate }}</td>
                <ng-template #none>
                  <td>
                    {{ "undefined" | translate }}
                  </td>
                </ng-template>
                <td >
                  <div *ngIf="lp.pricingMethod != null ; else NONE">
                    <span *ngIf="lp.pricingMethod != pricingMethode.NONE; else NONE"
                    [ngClass]="
                      'badge ' +
                      badgeService.getBadgeByLocationPartnerPricingMethod(
                        lp.pricingMethod
                      )
                    "
                  > {{ "enumMapping.paymentMethode." + lp.pricingMethod | translate }}
                  </span>
                  </div>
                  <ng-template #NONE>
                    <span
                      [ngClass]="
                        'badge ' +
                        badgeService.getBadgeByLocationPartnerPricingMethod(
                          pricingMethode.NONE
                        )
                      "
                    >
                      {{ "UNDEFINED" | translate }}
                    </span>
                  </ng-template>
                </td>
                <td>{{ lp.price }}</td>
                <td>
                  <ng-container
                    *ngFor="
                      let item of advertsingCampaigns[i];
                      let index = index
                    "
                  >
                    <div class="p-2">
                      {{ item.title }}
                      <i
                        class="icofont icofont-check-circled color-success"
                        *ngIf="filterAdsForLocationPartners.isPaid[getIndex(i, index)]; else notPaid"
                      ></i>
                      <ng-template #notPaid>
                        <i
                          *ngIf="!filterAdsForLocationPartners.isPaid[getIndex(i, index)]"
                          class="icofont icofont-close-circled"
                          style="color: red"
                        ></i>
                      </ng-template>
                    </div>
                  </ng-container>
                </td>
                <td>
                  <ng-container
                    *ngFor="let item of advertsingCampaigns[i]"
                  >
                    <div class="p-2">
                      {{ item.startingDate | localizedDate }}
                    </div>
                  </ng-container>
                </td>
                <td>
                  <ng-container
                    *ngFor="let item of advertsingCampaigns[i]"
                  >
                    <div class="p-2">
                      {{ item.endingDate | localizedDate }}
                    </div>
                  </ng-container>
                </td>
                <td>
                  <ng-container
                    *ngFor="let item of advertsingCampaigns[i] ;let index = index"
                  >
                    <div class="p-2">
                      {{ filterAdsForLocationPartners.views[getIndex(i, index)] }}
                    </div>
                  </ng-container>
                </td>
                <td>
                  <ng-container
                    *ngFor="
                      let item of advertsingCampaigns[i];
                      let index = index
                    "
                  >
                    <div class="p-2">
                      {{ filterAdsForLocationPartners.uniqueViews[getIndex(i, index)] }}
                    </div>
                  </ng-container>
                </td>
                <td>
                  <ng-container
                    *ngFor="
                      let ad of advertsingCampaigns[i];
                      let index = index
                    "
                  >
                    <div class="p-2">
                      {{
                        (lp.pricingMethod == "VIEWS"
                          ? filterAdsForLocationPartners.views[getIndex(i, index)]*lp.price
                          : filterAdsForLocationPartners.uniqueViews[getIndex(i, index)] *
                            lp.price
                        ).toFixed(3)
                      }}
                    </div>
                  </ng-container>
                </td>
                <td>
                  <app-drob-zone-options
                    [options]="options"
                    (optionSelected)="saveOption($event, lp, i)"
                  ></app-drob-zone-options>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <div *ngIf="loading">
          <ng-container style="float: right" class="flex">
            <div class="loader-box">
              <div class="loader-34"></div>
            </div>
            <h6
              class="col align-self-center f-w-600"
              style="text-align: center"
            >
              {{ "fetching" | translate }}
            </h6>
          </ng-container>
        </div>
        <div *ngIf="count == 0 && !loading; " class="d-flex justify-content-center m-t-20">
          {{"noData" | translate}}
        </div>
        <div class="d-flex">
          <div class="col-3"></div>
          <div class="col-6">
            <div class="d-flex justify-content-center p-3">
              <ngb-pagination
                [collectionSize]="count!"
                [(page)]="locationPartnerFilter.pager.pageNumber"
                [pageSize]="locationPartnerFilter.pager.pageSize"
                [rotate]="true"
                [boundaryLinks]="true"
                (pageChange)="changePage($event)"
              >
              </ngb-pagination>
            </div>
          </div>
          <div class="col-3">
            <div class="d-flex justify-content-end p-3">
              <select
                class="form-select"
                style="width: auto"
                name="pageSize"
                [(ngModel)]="changePageSize"
              >
                <option [ngValue]="5">
                  5 {{ "pagination.itemsPerPage" | translate }}
                </option>
                <option [ngValue]="10">
                  10 {{ "pagination.itemsPerPage" | translate }}
                </option>
                <option [ngValue]="15">
                  15 {{ "pagination.itemsPerPage" | translate }}
                </option>
                <option [ngValue]="count">
                  {{ "pagination.allItems" | translate }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
