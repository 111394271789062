import { createReducer, on } from "@ngrx/store";
import { RegionStateInterafce } from "./regionState.interface";
import {
  setAllRegionNames,
  setAllRegions,
  createUpdateRegionSuccess,
  deleteRegionSuccess,
  resultListRegionByZones,
  saveSelectedRegion,
} from "./region.actions";

export const initialState: Readonly<RegionStateInterafce> = {
  loading: false,
  createUpdateSuccess: null,
  regions: null,
  regionNames: null,
  deleteSuccess: null,
  regionsByZones : [],
  selectedRegion : null
};

export const RegionReducer = createReducer(
  initialState,
  on(createUpdateRegionSuccess, (state, { message }) => ({
    ...state,
    loading: false,
    createUpdateSuccess: message,
  })),
/*   on(invokeDeleteRegion, (state) => ({
    ...state,
    loading: true,
  })), */
  on(deleteRegionSuccess, (state, { message }) => ({
    ...state,
    loading: false,
    deleteSuccess: message,
  })),
  on(setAllRegions, (state, { region }) => ({
    ...state,
    loading: false,
    regions: region,
  })),
  on(setAllRegionNames, (state, { regionNames }) => ({
    ...state,
    loading: false,
    regionNames: regionNames,
  })),
  on(resultListRegionByZones , (state , {regionsByZones}) =>({
    ...state,
    regionsByZones : regionsByZones
  })),
  on(saveSelectedRegion, (state, {selectedRegion})=>({
    ...state,
    selectedRegion : selectedRegion
  }))
);
