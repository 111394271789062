import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { Constants } from "../../data/constants";

@Injectable({
  providedIn: "root",
})
export class FileService {
  constructor(private http: HttpClient) {}
  uploadFile(files: FormData): Observable<any> {
    return this.http.post<string>(
      `${environment.APP_PORT}${Constants.FILE_ENDPOINT}/upload`,
      files
    );
  }

  uploadFileWithProgress(
    file: FormData
  ): Observable<HttpEvent<any> | HttpResponse<any> | null> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Platforme: "Relead",
    });
    return this.http.post<HttpEvent<any> | HttpResponse<any> | null>(
      `${environment.APP_PORT}${Constants.FILE_ENDPOINT}/uploadFile`,
      file,
      {
        headers: headers,
        reportProgress: true,
        observe: "events",
      }
    );
  }
  downloadFile(filename: string | null): Observable<HttpEvent<Blob> | null> {
    return this.http.get(
      `${environment.APP_PORT}${Constants.FILE_ENDPOINT}/download/${filename}`,
      { observe: "response", responseType: "blob" }
    );
  }
}
