import { createAction, props } from "@ngrx/store";
import { FilterLocationPartnersToCreateCampaign } from "src/app/shared/models/location-partners/filterBySubCategoriesAndTagLocationPartner";
import { LocationPartnerInterface } from "src/app/shared/models/location-partners/locationPartner.interface";
import { LocationPartnersFilterInterface } from "src/app/shared/models/location-partners/locationPartnersFilter.interface";
import { LocationPartnersImpressionsFilterInterface, LocationPartnersImpressionsResult } from "src/app/shared/models/location-partners/locationPartnersImpressions";
import { LocationPartnersResultInterface } from "src/app/shared/models/location-partners/locationPartnersResult.interface";
import { LPInfo } from "src/app/shared/models/location-partners/lpInfo";

//Get By Filter
export const invokeLocationPartnerFilter = createAction(
  "[invoke Location Partners By Filter] invoke filter",
  props<{ locationPartnerFilter: LocationPartnersFilterInterface }>()
);
export const invokeFilterLocationsForPayment = createAction(
  "[ Invoke Filter Location partners For Payments ] Invoke filter location partners for payments",
  props<{locationPartnerFilter: LocationPartnersFilterInterface}>()
)
export const invokeLocationPartners = createAction(
  "[Set Location Partners By Filter] set filter"
);
export const getLocationPartnersSuccess = createAction(
  "[Get Location Partners By Filter Success] get filter Success",
  props<{ locationPartnersResult: LocationPartnersResultInterface | null }>()
);

// get all companyNames
export const invokeLocationPartnersCompanyNames = createAction(
  "[invoke Location Partners CompanyNames] invoke CompanyNames"
);

export const setLocationPartnersCompanyNames = createAction(
  "[Set Location Partners CompanyNames] set CompanyNames",
  props<{ companyNames: string[] | null }>()
);

//get by list companyNames
export const invokeLocationPartnersByCompanyNames = createAction(
  "[invoke Location Partners by CompanyNames] invoke by CompanyNames",
  props<{ companyNames: string[] | null }>()
);

export const setLocationPartnersByCompanyNames = createAction(
  "[Set Location Partners by CompanyNames] set by CompanyNames",
  props<{ locationPartners: LocationPartnerInterface[] | null }>()
);

//get by companyName
export const invokeGetLocationPartnerByCompanyName = createAction(
  "[Invoke get LocationPartner By companyName] Invoke get LocationPartner By companyName",
  props<{ companyName: string | null }>()
);

//get for creating campaign
export const invokeFilterLocationsToCreateCampaign = createAction(
  "[Invoke locations Filter to create campaign] Invoke locations Filter to create campaign",
  props<{
    filterLocationPartnersToCreateCampaign: FilterLocationPartnersToCreateCampaign;
  }>()
);
export const invokeLocationsToCreateCampaign = createAction(
  "[Invoke locations to create campaign] Invoke locations to create campaign"
);

export const locationPartnersToCreateCampaignSuccess = createAction(
  "[Filter location Partners To Create Campaign Success]",
  props<{
    locationPartnersToCreateCampaign: LocationPartnerInterface[] | null;
  }>()
);

//get by AdTitle
export const invokeLocationPartnerByAdTitle = createAction(
  "[ Invoke Latitude And Longitude By Company Names ] Invoke latitude and longitude by company names",
  props<{ adTitle: string | null }>()
);
export const locationPartnerByAdTitle = createAction(
  "[ get longitude and latitude by company names ] Get longitude and latitude by company names",
  props<{ allLocationPartners: LocationPartnerInterface[] | null }>()
);

//get All Info
export const invokeLPInfo = createAction(
  "[ Invoke LP profile Info ] Invoke LP profile info"
);

export const resultLPInfo = createAction(
  "[ Result LP Info ] Result lp info",
  props<{ lpInfo: LPInfo | null }>()
);

//Impressions
export const invokeLocationPartnerImpressions = createAction(
  "[ Invoke Location Partners Impressions ] Invoke location partners Impressions "
);

export const invokeLocationPartnerImpressionsFilter = createAction(
  "[ Filter Location Partner impressions ] Filter location partner impressions ",
  props<{ filter: LocationPartnersImpressionsFilterInterface }>()
);

export const setLocationPartnerImpressions = createAction(
  "[ Result Location Partner impressions ] Result location partner impressions ",
  props<{ result: LocationPartnersImpressionsResult | null}>()
);
