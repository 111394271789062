import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { Store } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";
import { setAPIStatus } from "../apiState.interface";
import { FactureService } from "src/app/shared/services/iberis/facture.service";
import { invokeAllFacturesForClientFromIberis, invokeAllFacturesFromIberis, invokeDownloadInvoice, invokeMarkInvoice, invokeNextInvoiceNumber, resultDownloadInvoice, resultFacturesForClientFromIberis, resultFacturesFromIberis, resultMarkInvoice, resultNextInvoiceNumber } from "./facture.actions";
import { ResultFacturesIberis } from "src/app/shared/models/iberis/facture/resultFacturesIberis";
import { ResultNextInvoiceNumber } from "src/app/shared/models/iberis/facture/nextInvoiceNumber";
import { MarkInvoice } from "src/app/shared/models/iberis/markInvoice";
@Injectable()
export class FactureEffect {
  constructor(
    private actions$: Actions,
    private store: Store<AppStateInterface>,
    private factureService: FactureService
  ) {}

  invokeAllFacturesFromIberis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAllFacturesFromIberis),
      mergeMap((data) => {
        return this.factureService.getFacturesFromIberis(data.params, data.start, data.length, data.draw).pipe(
          map((data: ResultFacturesIberis | null) =>
            resultFacturesFromIberis({ resultFacturesIberis: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
  invokeNextInvoiceNumber$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeNextInvoiceNumber),
    mergeMap((data) => {
      return this.factureService.getNextInvoiceNumber(data.params).pipe(
        map((data: ResultNextInvoiceNumber | null) =>
          resultNextInvoiceNumber({ nextInvoiceNumber: data })
        ),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error,
                apiStatus: "error",
              },
            })
          )
        )
      );
    })
  )
);
invokeDownloadInvoice$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeDownloadInvoice),
    mergeMap((data) => {
      return this.factureService.downloadInvoice(data.params, data.invoiceId).pipe(
        map((data: any) =>
          resultDownloadInvoice({ invoice: data })
        ),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error,
                apiStatus: "error",
              },
            })
          )
        )
      );
    })
  )
);
invokeAllFacturesFromIberisForClient$ = createEffect(() =>
this.actions$.pipe(
  ofType(invokeAllFacturesForClientFromIberis),
  mergeMap((data) => {
    return this.factureService.getFacturesFromIberisForClient(data.params, data.start, data.length, data.draw ,data.contactHashedId).pipe(
      map((data: ResultFacturesIberis | null) =>
        resultFacturesForClientFromIberis({ resultFacturesIberis: data })
      ),
      catchError((error) =>
        of(
          setAPIStatus({
            apiStatus: {
              apiResponseMessage: error,
              apiStatus: "error",
            },
          })
        )
      )
    );
  })
)
);
invokeMarkInvoice$ = createEffect(() =>
this.actions$.pipe(
  ofType(invokeMarkInvoice),
  mergeMap((data) => {
    return this.factureService.markInvoice(data.params, data.invoiceId).pipe(
      map((data: MarkInvoice | null) =>
        resultMarkInvoice({ resultMarkInvoice: data })
      ),
      catchError((error) =>
        of(
          setAPIStatus({
            apiStatus: {
              apiResponseMessage: error,
              apiStatus: "error",
            },
          })
        )
      )
    );
  })
)
);

}
