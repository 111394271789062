import { createAction, props } from "@ngrx/store";
import { ConsumerCountByDate } from "src/app/shared/models/consumers/consumerCountByDate";
import { DateFilter } from "src/app/shared/models/date-filte.interface";
import { GenderAgeStatistics } from "src/app/shared/models/statistics/gender-age-statistics";
import { GenderStatistics } from "src/app/shared/models/statistics/gender-statistics";
import { AgeStatistics } from "src/app/shared/models/statistics/age-statistics";
import { BrowserFamily } from "src/app/shared/models/statistics/browserFamily";
import { DeviceType } from "src/app/shared/models/statistics/deviceType";
import { TechnicalCardSearchMethod } from "src/app/shared/models/statistics/technical-data";
import { DeviceBrand } from "src/app/shared/models/statistics/device-brand";
import { OsFamily } from "src/app/shared/models/statistics/osFamily-statics";


export const invokeBrowserFamily = createAction(
  "[ Invoke Browser Family For A Particular Ad ] Invoke browser family for a particular ad ",
  props<{ search: TechnicalCardSearchMethod }>()
);
export const setBrowserFamily = createAction(
  "[ Set Browser Family For A Particular Ad ] Set browser family for a particular ad ",
  props<{ browserFamily: BrowserFamily | null }>()
);
export const InvokeDeviceType = createAction(
  "[ Invoke Device Type For A Particular Ad ] Invoke device type for a particular ad ",
  props<{ search: TechnicalCardSearchMethod }>()
);
export const setDeviceType = createAction(
  "[ Get Device Type For A Particular Ad ] Get device type for a particular ad ",
  props<{ deviceType: DeviceType | null }>()
);
export const invokeDeviceBrand = createAction(
  "[ Invoke Device Brand for A Particular Ad ] Invoke device brand for a particular ad ",
  props<{ search: TechnicalCardSearchMethod }>()
);
export const setDeviceBrand = createAction(
  "[ Get Device Brand for A Particular Ad ] Get device brand for a particular ad",
  props<{ deviceBrand: Map<string, number> | null}>()
);
export const invokeOsFamily = createAction(
  "[ Invoke Os Family For Ad ] Invoke os family for ad ",
  props<{ search: TechnicalCardSearchMethod }>()
);
export const setOsFamily = createAction(
  "[ Os Family For Ad ] Os family for ad ",
  props<{ osFamily: OsFamily | null }>()
);


export const invokeCountConsumersBetweenDates = createAction(
  "[ Invoke Consumer Count By Date Between 2 dates ] Invoke consumer count by date between two dates",
  props<{ dateFilter: DateFilter }>()
);
export const consumerCountBetweenDates = createAction(
  "[Get Consumer Count By Date Between 2 dates ] Get consumer count by date between two dates ",
  props<{ consumerCountByDate: ConsumerCountByDate | null }>()
);


export const invokeConsumersGenderDetails = createAction(
  "[ Invoke Consumers Gender Details] Invoke consumers gender details"
);
export const consumersGenderDetails = createAction(
  "[ Get Consumers Gender Details] Get consumers gender details",
  props<{ genderStatistics: GenderStatistics | null}>()
);


export const invokeConsumerDemographicDetails = createAction(
  "[ Invoke All Consumers Age Range And Gender Details ] Invoke all consumers age range and gender details",
  props<{dateFilter : DateFilter}>()
)
export const consumerDemographiqueDetails = createAction(
  "[ Invoke All Consumers Age Range And Gender Details ] Get all consumers age range and gender details ",
  props<{genderAgeStatics : GenderAgeStatistics | null}>()
)


export const invokeConsumerAgeRangeDetails = createAction(
  "[ Invoke All Consumers Age Range Details ] Invoke all consumers age range details"
)
export const consumerAgeRangeDetails = createAction(
  "[ Get All Consumers Age Range Details ] Get all consumers age range details",
  props<{ageStatics: AgeStatistics | null}>()
)

export const invokeConsumersCountInDay = createAction(
  "[ Invoke Consumers count in day ] Invoke consumers count in day",
  props<{day : string | null}>()
)
