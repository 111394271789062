import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RoleInterface } from "../models/role/role.interface";
import { Constants } from "../data/constants";
import { SuccessDto } from "../models/success-dto";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class RoleService {
  constructor(private http: HttpClient) {}

  getAllRoles(): Observable<RoleInterface[]> {
    return this.http.get<RoleInterface[]>(
      `${environment.APP_PORT + Constants.ROLE_ENDPOINT + "/all"}`
    );
  }
}
