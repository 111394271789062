<div class="card">
  <div class="card-header pb-0">
    <div class="media-space-between">
      <h4>{{ "admin.advertisers.billing" | translate }}</h4>
      <!--<div class="media-body">
        <div class="d-inline-flex">
          <div
            class="faq-form mr-3"
            style="color: darkgrey; display: flex; margin-left: 10px"
          >
            <i class="search-icon" data-feather="search"></i>
            <div class="input-group">
              <input
                style="width: 17rem"
                id="table-complete-search"
                type="text"
                class="form-control"
                [placeholder]="
                  'placeholder.searchByCampaignTitle' | translate
                "
                [(ngModel)]="ad"
                (ngModelChange)="searchTerm(ad)"
              />
            </div>
          </div>
        </div>
      </div>-->
    </div>
  </div>
  <div class="card-body">
    <div class="table-responsive data-table">
      <form>
        <table class="table table-striped table-border-vertical">
          <thead>
            <tr>
              <ng-container *ngFor="let column of columns">
                <th>
                  {{ column.label | translate }}
                </th>
              </ng-container>
              <th>Action</th>
            </tr>
          </thead>
          <tbody *ngIf="!(loading$ | async)">
            <tr *ngFor="let invoice of resultInvoicesFromIberis; let i = index">
              <ng-template #NONE>
                <td>
                  {{ "undefined" | translate }}
                </td>
              </ng-template>
              <td *ngIf="invoice.invoice_number; else NONE">
                {{ invoice.invoice_number }}
              </td>
              <td
                *ngIf="
                  publisherAndStatus.firstName[i] ||
                    publisherAndStatus.lastName[i];
                  else NONE
                "
              >
                {{ publisherAndStatus.firstName[i] }}
                {{ publisherAndStatus.lastName[i] }}
              </td>
              <td *ngIf="publisherAndStatus.companyName[i]; else NONE">
                {{ publisherAndStatus.companyName[i] }}
              </td>
              <td *ngIf="invoice.object; else NONE">{{ invoice.object }}</td>
              <td>
                <span
                  [ngClass]="
                    'badge ' +
                    badgeService.getBadgeFactureIberisStatus(
                      statusIberis(invoice)
                    )
                  "
                >
                  {{ InvoiceStatus[statusIberis(invoice)] | translate }}
                </span>
              </td>

              <td *ngIf="invoice.date; else NONE">
                {{ invoice.date  }}
              </td>
              <td *ngIf="invoice.total_formatted; else NONE">
                {{ invoice.total_formatted }}
              </td>
              <td
                *ngIf="isDownloadInvoice && specificColumun == i; else default"
              >
                <ng-container style="float: right" class="flex">
                  <div class="loader-box">
                    <div class="loader-34"></div>
                  </div>
                </ng-container>
              </td>
              <ng-template #default>
                <td>
                  <app-drob-zone-options
                    [options]="options"
                    (optionSelected)="
                      saveOption(
                        $event,
                        invoice.hashed_id,
                        invoice.invoice_number,
                        i,
                        showModal
                      )
                    "
                  ></app-drob-zone-options>
                </td>
              </ng-template>
            </tr>
          </tbody>
        </table>
        <div *ngIf="loading$ | async">
          <ng-container style="float: right" class="flex">
            <div class="loader-box">
              <div class="loader-34"></div>
            </div>
            <h6
              class="col align-self-center f-w-600"
              style="text-align: center"
            >
              {{ "fetching" | translate }}
            </h6>
          </ng-container>
        </div>
        <div
          *ngIf="totalRecords == 0 && !(loading$ | async)"
          class="d-flex justify-content-center m-t-20"
        >
          {{ "noData" | translate }}
        </div>
        <div class="media">
          <div class="col-3"></div>
          <div class="col-6">
            <div class="d-flex justify-content-center p-3">
              <ngb-pagination
                [collectionSize]="totalRecords"
                [rotate]="true"
                [boundaryLinks]="true"
                [pageSize]="itemsPerPage"
                (pageChange)="changePage($event)"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #showModal let-modal id="showModal">
  <div class="modal-header">
    <h4 class="modal-title" id="exampleModalLabel">
      {{ "buttons.downloadInvoice" | translate }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div *ngIf="!(pdfUrl | async)">
      <ng-container style="float: right" class="flex">
        <div class="loader-box">
          <div class="loader-34"></div>
        </div>
        <h6 class="col align-self-center f-w-600" style="text-align: center">
          {{ "fetching" | translate }}
        </h6>
      </ng-container>
    </div>
    <iframe [src]="pdfUrl | async" width="100%" height="600px"></iframe>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      data-bs-dismiss="modal"
      (click)="modal.dismiss('Cross click')"
    >
      {{ "buttons.close" | translate }}
    </button>
  </div>
</ng-template>
