<div
  *ngIf="
    adDetails.objectiveName == localStoreObjective &&
    adDetails.validators.dates &&
    adDetails.validators.title
  "
>
  <div class="card">
    <div class="card-header p-b-0">
      <div class="d-flex align-items-baseline">
        <i class="icofont icofont-location-pin" style="font-size: 18px"></i>
        <h4 class="ps-2 m-b-0">
          {{ "newCampaign.step1.locations.title" | translate }}
        </h4>
      </div>
    </div>
    <div class="card-body">
      <form [formGroup]="localStoreForm">
        <h5 class="text-center">
          {{ "newCampaign.step1.locations.localStoreForm" | translate }}?
        </h5>
        <div class="d-flex m-auto pull-right">
          <div class="mb-3 input-group">
            <button
              class="btn btn-outline-primary"
              type="button"
              id="button-addon1"
              (click)="googleSearchSelect.toggle()"
            >
              <i class="icon-search"></i>
            </button>
            <input
              class="form-control"
              [(ngModel)]="searchGoogleMapsText"
              (keyup)="searchTerm($event)"
              type="text"
              autocomplete="off"
              formControlName="search"
              [placeholder]="'placeholder.search' | translate"
            />
          </div>
        </div>

        <ng-select
          #googleSearchSelect
          [items]="searchResult"
          [closeOnSelect]="false"
          [searchable]="false"
          (change)="onSelectSearches()"
          [(ngModel)]="selectedSearches"
          [multiple]="true"
          [placeholder]="'placeholder.placeholderSelect' | translate"
        >
        </ng-select>
      </form>
      <div class="card-locations mt-4">
        <app-map [cords]="cords" height="500px" width="100%"></app-map>
      </div>
    </div>
  </div>
</div>

<div
  class="card"
  *ngIf="
    adDetails.objectiveName != localStoreObjective &&
    adDetails.validators.dates &&
    adDetails.validators.title
  "
>
  <div class="card-header p-b-0">
    <div class="d-flex align-items-baseline">
      <i class="icofont icofont-location-pin" style="font-size: 18px"></i>
      <h4 class="ps-2 m-b-0">
        {{ "newCampaign.step1.locations.title" | translate }}
      </h4>
    </div>
  </div>
  <div class="card-body">
    <!--list regions-->
    <app-region-selection></app-region-selection>

    <div class="tabbed-card">
      <h6>{{ "newCampaign.step1.locations.chooseBy" | translate }}</h6>
      <ul
        ngbNav
        #nav="ngbNav"
        [activeId]="active"
        class="nav justify-content-center position-sticky"
      >
        <!--selecting by location-->
        <li [ngbNavItem]="1" [destroyOnHide]="true" class="mx-2">
          <a
            ngbNavLink
            class="btn btn-outline-primary"
            (click)="updateNav(locationSearchMethod.LOCATION_PARTNER)"
          >
            {{ "campaignDetails.location" | translate }}
          </a>
          <ng-template ngbNavContent>
            <app-by-location></app-by-location>
          </ng-template>
        </li>
        <!--selecting by zone-->
        <li [ngbNavItem]="2" [destroyOnHide]="true" class="mx-2">
          <a
            ngbNavLink
            class="btn btn-outline-primary"
            (click)="updateNav(locationSearchMethod.ZONE)"
            >{{ "newCampaign.step1.locations.byZone" | translate }}</a
          >
          <ng-template ngbNavContent>
            <div>
              <!--zone-->
              <h6 class="mt-2">{{ "enumMapping.lp.zone" | translate }}</h6>
              <app-by-zone></app-by-zone>
            </div>
          </ng-template>
        </li>
      </ul>
    </div>

    <div class="pt-0">
      <div [ngbNavOutlet]="nav"></div>
    </div>

    <!--All selected location-->

    <div class="card-locations mt-4">
      <app-map
        [listLocations]="listSelectedLocations"
        height="500px"
        width="100%"
      ></app-map>
    </div>
  </div>
</div>
