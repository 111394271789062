export enum RouterType {
    RELEAD_ROUTER = "RELEAD_ROUTER",
    FSI_ROUTER = "FSI_ROUTER",
  }
  
  export enum RouterTypeToShow {
    RELEAD_ROUTER = "Relead router",
    FSI_ROUTER = "FSI router",
  }
  
  export interface RouterTypeInterface {
    id: RouterType;
    value: string;
  }