import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeLogout,
  invokeLogoutAllSessions,
  logoutSuccess,
  setAccount,
} from "src/app/store/auth/auth.actions";
import { logoutSelector } from "src/app/store/auth/auth.selectors";
declare var require: any;
const Swal = require("sweetalert2");

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
})
export class LogoutComponent extends BaseComponent implements OnInit {
  @Input("modal") public modal: any;

  logout$: Observable<boolean>;
  constructor(
    private store: Store<AppStateInterface>,
    private router: Router,
    private modalService: NgbModal
  ) {
    super(store);
    this.logout$ = this.store
      .pipe(select(logoutSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.logout$.subscribe((data) => {
      if (data == true) {
        this.store.dispatch(logoutSuccess({ logout: false, token: null }));
        this.store.dispatch(
          setAccount({ account: { role: null, user: null } })
        );
        localStorage.removeItem("token");

        this.router.navigate(["/", "auth", "login"]);
        this.modalService.dismissAll();
        /*Swal.fire({
          title: "Logout Success Message",
          text: "You have been successfully logged out. Thank you for using our service. We hope to see you soon! Don't forget to log in again when you return.",
          icon: "success",
          confirmButtonText: "Exit",
          allowOutsideClick: false,
        }).then((result: any) => {
          
          if (result.isConfirmed) {
            this.router.navigate(["/", "auth", "login"]);
          } else if (result.isDenied) {
            Swal.fire("Changes are not saved", "", "info");
          }
        });*/
      }
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }
  logout() {
    this.store.dispatch(invokeLogout());
  }
  logoutAllSessions() {
    this.store.dispatch(invokeLogoutAllSessions());
  }
}
