import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const fileState = (state: AppStateInterface) => state.fileState;

export const uploadFileSelector = createSelector(
  fileState,
  (state) => state.filename
);

export const uploadFileProgressSelector = createSelector(
  fileState,
  (state) => state.progress
);

export const loadingFileSelector = createSelector(
  fileState,
  (state) => state.loading
);
export const downloadedFileSelector = createSelector(
  fileState,
  (state) => state.downloadedFile
)
