import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const settingsState = (state: AppStateInterface) => state.settingsState;

export const languageSelector = createSelector(
  settingsState,
  (state) => state.language
);
export const updateFieldsSelector = createSelector(
  settingsState,
  (state) => state.fields
);
export const saveOptionsSelector = createSelector(
  settingsState, 
  (state) => state.options
)