import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { BadgeService } from "../../services/badge.service";

@Component({
  selector: "app-column-location-partners",
  templateUrl: "./column-location-partners.component.html",
  styleUrls: ["./column-location-partners.component.scss"],
})
export class ColumnLocationPartnersComponent implements OnInit {
  @Input() j: number;
  @Input() locationPartners: string[];
  show: boolean[] = [false];
  constructor(public badgeService: BadgeService, private cdr : ChangeDetectorRef) {}

  ngOnInit(): void {}
  openMenu(j: number) {
    this.show[j] = !this.show[j];
  }
  public onOutsideClick(event: any, j: number): void {
    this.show[j] = false;
    this.cdr.detectChanges()
  }
}
