import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  CreateUpdatePromotionCodeInterface,
  PromotionCodeInterface,
} from "../models/promotion-code/promotion-code.interface";
import { ResultPromotionCodesInterface } from "../models/promotion-code/result-promotion-code.interface";
import { SuccessDto } from "../models/success-dto";
import { Constants } from "../data/constants";
import { environment } from "../../../environments/environment";
import { FilterPromotionCodeInterface } from "../models/promotion-code/filter-promotion-code.interface";
import { PromotionCodeLiteInterface } from "../models/promotion-code/check-promotion-code.interface";

@Injectable({
  providedIn: "root",
})
export class PromotionCodeService {
  constructor(private http: HttpClient) {}

  filter(
    filter: FilterPromotionCodeInterface
  ): Observable<ResultPromotionCodesInterface> {
    return this.http.post<ResultPromotionCodesInterface>(
      `${environment.APP_PORT + Constants.PROMOTION_CODE_END_POINT}/filter`,
      filter
    );
  }

  create(router: CreateUpdatePromotionCodeInterface): Observable<SuccessDto> {
    return this.http.post<SuccessDto>(
      `${environment.APP_PORT + Constants.PROMOTION_CODE_END_POINT}/create`,
      router
    );
  }

  update(
    code: string,
    promotionCode: CreateUpdatePromotionCodeInterface
  ): Observable<SuccessDto> {
    return this.http.patch<SuccessDto>(
      `${environment.APP_PORT + Constants.PROMOTION_CODE_END_POINT}/${code}`,
      promotionCode
    );
  }
  delete(code: string): Observable<SuccessDto> {
    return this.http.delete<SuccessDto>(
      `${environment.APP_PORT + Constants.PROMOTION_CODE_END_POINT}/${code}`
    );
  }

  havePromotion(
    campaignHashedId: string
  ): Observable<PromotionCodeLiteInterface | null> {
    return this.http.get<PromotionCodeLiteInterface | null>(
      `${
        environment.APP_PORT + Constants.PROMOTION_CODE_END_POINT
      }/havePromotion/${campaignHashedId}`
    );
  }

  applyCode(code: string, campaignHashedId: string): Observable<SuccessDto> {
    return this.http.get<SuccessDto>(
      `${
        environment.APP_PORT + Constants.PROMOTION_CODE_END_POINT
      }/apply/${code}/${campaignHashedId}`
    );
  }

  checkValidity(code: string): Observable<PromotionCodeLiteInterface> {
    return this.http.get<PromotionCodeLiteInterface>(
      `${
        environment.APP_PORT + Constants.PROMOTION_CODE_END_POINT
      }/checkValidity/${code}`
    );
  }
}
