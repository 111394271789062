import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { CampaignStatusEnum } from "src/app/shared/enum/campaign-status";
import { AdvertisingPaymentMethodEnum } from "src/app/shared/enum/paymentMethod";
import { AdDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-details.interface";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { MarkInvoice } from "src/app/shared/models/iberis/markInvoice";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";
import { ParamsPaymentReceived } from "src/app/shared/models/iberis/paymentsReceived/paramsPaymentRecaived";
import { ResultInvoiceEstimate } from "src/app/shared/models/iberis/resultInvoiceEstimate";
import { CheckPaymentKonnectInterface } from "src/app/shared/models/payment/konnect.interface";
import { CheckPaymentPaymeeInterface } from "src/app/shared/models/payment/paymee.interface";
import { CompanyIberisService } from "src/app/shared/services/iberis/company-iberis.service";
import { KonnectService } from "src/app/shared/services/payment/konnect.service";
import { PaymeeService } from "src/app/shared/services/payment/paymee.service";
import { invokeIdIberis } from "src/app/store/advertiser/advertiser.actions";
import { resultIdIberisSelector } from "src/app/store/advertiser/advertiser.selectors";
import {
  adDetailsSelector,
  getAdCompaignByHashedIdSelector,
} from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import {
  invokeAdvertisingCompaignByHashedId,
  invokeChangeAdStatus,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeInvoiceEstimate } from "src/app/store/commande/commande.actions";
import { resultInvoiceEstimateSelector } from "src/app/store/commande/commande.selectors";
import {
  invokeMarkInvoice,
  resultMarkInvoice,
} from "src/app/store/facture/facture.actions";
import { resultMarkInvoiceSelector } from "src/app/store/facture/facture.selectors";
import { invokePayment } from "src/app/store/payments-received/payments-received.actions";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-paymee-success",
  templateUrl: "./payment-success.component.html",
})
export class PaymentSuccessComponent extends BaseComponent implements OnInit {
  paymentMethod: AdvertisingPaymentMethodEnum;
  transaction: string;
  adDetailsByAdHashedId$: Observable<AdvertisingCampaignInterface | null>;
  adDetailsByAdHashedId: AdvertisingCampaignInterface;
  justificationFile: string | null;
  params: CommonParamsIberis = {
    lang: "fr",
    companyId: environment.IBERIS.IBERIS_COMPANY_ID,
  };
  paymentRef: string | null = null;
  estimateId: string | null;
  paymentToken: string = "";
  resultInvoiceEstimate$: Observable<ResultInvoiceEstimate | null>;
  resultInvoiceEstimate: string;
  resultMarkValid$: Observable<MarkInvoice | null>;
  resultMarkValid: MarkInvoice | null;
  paiementReceived: ParamsPaymentReceived;
  amount: number;
  idIberis$: Observable<string | null>;
  idIberis: string;
  adDetails$: Observable<AdDetailsInterface>;
  status = CampaignStatusEnum;
  adTitle: string;
  constructor(
    private paymeeService: PaymeeService,
    private konnectService: KonnectService,
    private route: ActivatedRoute,
    private store: Store<AppStateInterface>,
    private datePipe: DatePipe,
    private companyService: CompanyIberisService
  ) {
    super(store);
    this.estimateId = this.route.snapshot.queryParams["estimateId"];
    this.paymentToken = this.route.snapshot.queryParams["payment_ref"];
    /* if (this.path) {
      this.estimateId = this.path.split("?")[0];
      if (this.path.split("?")[1].split("=")[0] == "payment_ref")
      else this.paymentMethod = AdvertisingPaymentMethodEnum.PAYMEE;
      this.paymentToken = this.path.split("?")[1].split("=")[1]; 
      
      
      
    }*/
    this.route.queryParams.subscribe((params) => {
      this.transaction = params["transaction"];
      if (this.paymentToken)
        this.paymentMethod = AdvertisingPaymentMethodEnum.KONNECT;
      else this.paymentMethod = AdvertisingPaymentMethodEnum.BANK_TRANSFER;
      this.getpaymentInfo(this.paymentToken);
    });
    this.adDetailsByAdHashedId$ = this.store
      .pipe(select(getAdCompaignByHashedIdSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultInvoiceEstimate$ = this.store
      .pipe(select(resultInvoiceEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultMarkValid$ = this.store
      .pipe(select(resultMarkInvoiceSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.idIberis$ = this.store
      .pipe(select(resultIdIberisSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.adDetails$ = this.store
      .pipe(select(adDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    const newDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.adDetailsByAdHashedId$.subscribe((data) => {
      if (data) {
        this.adDetailsByAdHashedId = data;
        this.justificationFile = data.justificationFile;
      }
    });
    this.idIberis$.subscribe((result) => {
      if (result) {
        this.idIberis = result;
        if (this.paiementReceived) {
          this.paiementReceived.client_id = this.idIberis;
        }
      } else {
        this.store.dispatch(invokeIdIberis());
      }
    });
    this.resultInvoiceEstimate$.subscribe((result) => {
      if (result && newDate != null) {
        this.resultInvoiceEstimate = result.data.invoiceId;
        this.paiementReceived = {
          date: newDate,
          payment_number: "",
          notes: "Payment pour " + this.adDetailsByAdHashedId.title,
          bank_id: environment.IBERIS.BANK_ID,
          payment_method: environment.IBERIS.PAYMENT_METHOD,
          client_id: this.idIberis,
          currency_rate: 1,
          language: "fr",
          pay: {
            invoice_hashed_id: this.resultInvoiceEstimate,
            disbursement_hashed_id: null,
            amount: this.amount,
          },
        };
        this.store.dispatch(
          invokeMarkInvoice({
            params: this.params,
            invoiceId: this.resultInvoiceEstimate,
          })
        );
      }
    });
    this.resultMarkValid$.subscribe((result: MarkInvoice | null) => {
      if (result) {
        this.nextPayment();
      }
    });
    this.adDetails$.subscribe((adDetails: AdDetailsInterface) => {
      if (adDetails.title) {
        this.store.dispatch(
          invokeAdvertisingCompaignByHashedId({
            campaignHashedId: adDetails.campaignHashedID,
          })
        );
      }
    });
  }

  getpaymentInfo(token: string) {
    switch (this.paymentMethod) {
      case AdvertisingPaymentMethodEnum.PAYMEE: {
        this.paymeeService.getpaymentInfo(token).subscribe({
          next: (value: CheckPaymentPaymeeInterface) => {
            //compare amounts to see payment is it done successfully or not!
            //note is the ad title
            this.amount = value.data.received_amount;
            if (value.data.received_amount >= value.data.amount) {
              this.store.dispatch(
                invokeInvoiceEstimate({
                  params: this.params,
                  estimateId: this.estimateId,
                })
              );
            }
            this.store.dispatch(
              invokeAdvertisingCompaignByHashedId({
                campaignHashedId: value.data.note,
              })
            );
            this.store.dispatch(
              invokeChangeAdStatus({
                campaignHashedId: value.data.note,
                status: CampaignStatusEnum.AWAITING_APPROVAL,
              })
            );
          },
        });
        break;
      }
      case AdvertisingPaymentMethodEnum.KONNECT: {
        this.konnectService.getpaymentInfo(token).subscribe({
          next: (value: CheckPaymentKonnectInterface) => {
            //compare amounts to see payment is it done successfully or not!
            //note is the ad hashed id
            this.amount = value.payment.amount / 1000;
            if (value.payment.convertedAmount >= value.payment.amount) {
              this.store.dispatch(
                invokeInvoiceEstimate({
                  params: this.params,
                  estimateId: this.estimateId,
                })
              );
            }
            this.store.dispatch(
              invokeAdvertisingCompaignByHashedId({
                campaignHashedId: value.payment.orderId,
              })
            );
            this.store.dispatch(
              invokeChangeAdStatus({
                campaignHashedId: value.payment.orderId,
                status: CampaignStatusEnum.AWAITING_APPROVAL,
              })
            );
          },
        });
        break;
      }
    }
  }
  nextPayment() {
    if (this.paymentMethod == AdvertisingPaymentMethodEnum.KONNECT) {
      this.companyService.getIberisCompanyData(this.params).subscribe({
        next: (data) => {
          const payment_number = JSON.parse(
            data.data.company.payment_sale_naming_series
          );
          this.paiementReceived.payment_number = payment_number.number;
          this.store.dispatch(
            invokePayment({
              params: this.params,
              paymentReceived: this.paiementReceived,
            })
          );
        },
        error: (e) => {},
      });
    }
  }
}
