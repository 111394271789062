import { createReducer, on } from "@ngrx/store";
import { invokeUploadFile, invokeUploadFileWithProgress, resultDownloadFile, setFileName, setFileProgress } from "./file.actions";
import { FileStateInterface } from "./fileState.interface";
import { HttpEventType } from "@angular/common/http";

export const initialState: Readonly<FileStateInterface> = {
  loading: false,
  filename: null,
  progress: null,
  downloadedFile : null
};

export const FileReducer = createReducer(
  initialState,
  on(setFileName, (state, { filename }) => ({
    ...state,
    filename: filename,
    loading: false,
  })),
  on(invokeUploadFile, (state) => ({
    ...state,
    loading: true,
  })),
  on(invokeUploadFileWithProgress , (state ,{file})=>({
    ...state,
    loading : true
  })),
  on(setFileProgress, (state, {progress}) => ({
    ...state,
    progress: progress,
    loading: false,
  })),
  on(resultDownloadFile, (state, {downloadedFile})=>({
    ...state,
    downloadedFile : downloadedFile,
    loading : false
  }))
);
