<form [formGroup]="form">
  <div class="form-group">
    <label class="label">{{ "profile.billingInformation.legalName" | translate }}</label>
    <div class="input-group">
      <input
        formControlName="legalName"
        class="form-control"
        type="text"
        required=""
        [placeholder]="
          'profile.billingInformation.placeholderLegalName' | translate
        "
      />
    </div>
  </div>
  <div class="form-group m-t-20 m-b-20">
    <label class="label">{{ "profile.billingInformation.matricule" | translate }}</label>
    <div class="input-group">
      <input
        formControlName="taxRegistration"
        class="form-control"
        type="text"
        required=""
        [placeholder]="
          'profile.billingInformation.placeholderMatricule' | translate
        "
      />
    </div>
  </div>
  <div class="form-group">
    <label class="label">{{ "profile.billingInformation.address" | translate }}</label>
    <div class="input-group">
      <input
        formControlName="address"
        class="form-control"
        type="text"
        required=""
        [placeholder]="
          'profile.billingInformation.placeholderAdress' | translate
        "
      />
    </div>
  </div>
</form>
