import { createReducer, on } from "@ngrx/store";
import { invokeSendFilesToUser, invokeSendRequestToDownloadLead, resultSindingMail } from "./mailing.actions";
import { MailingStateInterface } from "./mailingState.interface";

export const initialState: Readonly<MailingStateInterface> = {
    leadGeneration : null,
    successMessage : null,
    loading : false
}
export const MailingReducer = createReducer(
    initialState,
    on(resultSindingMail , (state , {successMessage})=>({
        ...state,
        successMessage : successMessage,
        loading : false
    })),
    on(invokeSendRequestToDownloadLead , (state , {advertiser})=>({
        ...state,
        loading : true 
    })),
    on(invokeSendFilesToUser , (state , {sendFiles, title})=>({
        ...state,
        loading : true 
    }))
)