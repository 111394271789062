<form novalidate class="container-fluid p-0" [formGroup]="form">
  <aw-wizard
    #wizard
    [disableNavigationBar]="true"
    [navBarLocation]="'bottom'"
    navBarLayout="small"
  >
    <aw-wizard-step>
      <app-step1 [form]="form" [objectiveName]="objectiveName"></app-step1>
      <div class="stepper-ad">
        <button
          class="btn btn-outline-primary pull-left me-1"
          type="button"
          [disabled]="true"
        >
          {{ "buttons.return" | translate }}
        </button>
        <button
          class="btn btn-primary pull-right"
          type="button"
          [disabled]="!form.valid || totalCost == 0"
          (mouseenter)="checkValidation(1)"
          (click)="next(1)"
        >
          {{ "buttons.next" | translate }}
        </button>
      </div>
    </aw-wizard-step>
    <aw-wizard-step
      *ngIf="objectiveName == objectiveTypeEnum.GENERATION_DE_PROSPECTS"
    >
      <app-form
        [defaultItems]="defaultItems"
        [adTitle]="adDetails.title"
        (resultLeadGenerationForm)="saveLead($event)"
      ></app-form>
      <div class="stepper-ad">
        <button
          class="btn btn-outline-primary pull-left me-1"
          type="button"
          awPreviousStep
        >
          {{ "buttons.return" | translate }}
        </button>
        <button
          class="btn btn-primary pull-right"
          type="button"
          (click)="sendLeadGeneration()"
        >
          {{ "buttons.next" | translate }}
        </button>
      </div>
    </aw-wizard-step>
    <aw-wizard-step>
      <app-step2
        [objectiveName]="objectiveName"
        (valueChanged)="handleValueChanged($event)"
        (fileName)="handleFileName($event)"
      ></app-step2>
      <div class="stepper-ad">
        <button
          class="btn btn-outline-primary pull-left me-1"
          type="button"
          awPreviousStep
        >
          {{ "buttons.return" | translate }}
        </button>
        <button
          class="btn btn-primary pull-right"
          type="button"
          awNextStep
          (click)="next(2)"
          [disabled]="
            !formStep2.get('webSite')?.valid &&
            objectiveName == objectiveTypeEnum.WEB_SITE_TRAFFIC
          "
        >
          {{ "buttons.next" | translate }}
        </button>
      </div>
    </aw-wizard-step>

    <aw-wizard-step>
      <app-step3 [idIberis]="idIberis"></app-step3>
      <div class="stepper-ad">
        <button
          class="btn btn-outline-primary pull-left me-1"
          type="button"
          awPreviousStep
        >
          {{ "buttons.return" | translate }}
        </button>
        <button
          class="btn btn-primary pull-right"
          type="button"
          [disabled]="estimateNumber == ''"
          awNextStep
          (click)="changeStatus()"
        >
          {{ "buttons.next" | translate }}
        </button>
      </div>
    </aw-wizard-step>
    <aw-wizard-completion-step awEnableBackLinks>
      <app-step4
        [clientId]="idIberis"
        [campaignHashedId]="campaignHashedId"
        [createEstimate]="createEstimate"
        (resultPaymentMethod)="resultPaymentMethod($event)"
        (emitTotalCost)="totalCostFunction($event)"
      ></app-step4>
      <div class="stepper-ad">
        <button
          class="btn btn-outline-primary pull-left me-1"
          type="button"
          awPreviousStep
        >
          {{ "buttons.return" | translate }}
        </button>
        <button
          class="btn btn-primary pull-right"
          type="button"
          (click)="paymentSelected()"
          [disabled]="
            !paymentMethod || adDetails.validators.conditions == false
          "
          awNextStep
        >
          <loading-button
            [loading]="loading"
            [value]="'buttons.confirm' | translate"
            [icon]="null"
          ></loading-button>
        </button>
      </div>
    </aw-wizard-completion-step>
  </aw-wizard>
</form>
