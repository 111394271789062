import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-advertiser-perso-info',
  templateUrl: './advertiser-perso-info.component.html'
})
export class AdvertiserPersoInfoComponent implements OnInit {
  @Input("form") form: FormGroup;
  constructor() { }

  ngOnInit() {
    
  }

}
