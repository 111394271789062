import { createAction, props } from "@ngrx/store";
import { CreateClient } from "src/app/shared/models/iberis/client/createClient";
import { ResultCreateClient } from "src/app/shared/models/iberis/client/resultCreateClient";
import { ResultListClientsFromIberis } from "src/app/shared/models/iberis/client/resultListClientFromIbris";
import { CommonParamsIberis} from "src/app/shared/models/iberis/paramsForGetAll";

export const invokeClienByHashedId = createAction(
    "[ Invoke client using hashed id] Invoke the client from Iberis using their hashed ID",
    props<{params : CommonParamsIberis, clientId: string | null}>()
)
export const resultClientByHashedId = createAction(
    "[ Result client using hashed id ] Client's result from Iberis using their hashed ID",
    props<{resultClientByHashedId : ResultListClientsFromIberis | null}>()
)
export const invokeCreateClient = createAction(
    "[ Invoke create Client ] Invoke create client",
    props<{params : CommonParamsIberis, createClient : CreateClient}>()
)
export const resultCreateUpdateClient = createAction(
    "[ Result Create Client ] Result create client ",
    props<{createClient : ResultCreateClient | null}>()
)
export const invokeUpdateClient = createAction(
    "[ Invoke Update Client ] Invoke update client ",
    props<{params : CommonParamsIberis, updateClient : CreateClient , idClient : string | null}>()
)