<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div class="d-flex justify-content-center">
            <div
              class="img-contour d-flex justify-content-center align-items-center"
            >
              <img
                src="assets/identity/logo-footer.png"
                width="60"
                height="60"
                alt="icon relead"
              />
            </div>
          </div>
          <form
            class="theme-form login-form"
            novalidate
            [formGroup]="registrationForm"
            (ngSubmit)="register()"
          >
            <h4 style="text-align: center">Créez votre compte</h4>
            <h6 style="text-align: center">Bienvenue à Relead !</h6>

            <div class="card-body">
              <aw-wizard #wizard [disableNavigationBar]="true">
                <aw-wizard-step>
                  <h5 class="mt-4">Je suis :</h5>
                  <div class="btn-showcase">
                    <button class="btn btn-annonceur" type="button">
                      Annonceur
                    </button>
                    <button class="btn btn-agence" type="button" disabled>
                      Agence de communication
                    </button>
                  </div>
                  <button
                    class="btn btn-primary pull-right"
                    type="submit"
                    awNextStep
                  >
                    Suivant
                  </button>
                </aw-wizard-step>
                <aw-wizard-step formGroupName="personalInformation">
                  <app-personal-information
                    [form]="
                      registrationForm.get('personalInformation')! | asFormGroup
                    "
                    [personalInformationSubmitted]="
                      personalInformationSubmitted
                    "
                  ></app-personal-information>
                  <button
                    class="btn btn-primary pull-right"
                    type="button"
                    [disabled]="
                      !registrationForm.get('personalInformation')?.valid
                    "
                    (click)="personalInformationSubmitted.emit(true); nextStep()"
                  >
                    Suivant
                  </button>
                  <button
                    class="btn btn-outline-primary pull-left me-1"
                    type="button"
                    awPreviousStep
                  >
                    Retour
                  </button>
                </aw-wizard-step>
                <aw-wizard-step formGroupName="businessInformation">
                  <div class="tab">
                    <app-business-information
                      [CompanyActivity]="companyActivity"
                      [businessInformationSubmitted]="
                        businessInformationSubmitted
                      "
                      [form]="
                        registrationForm.get('businessInformation')!
                          | asFormGroup
                      "
                    ></app-business-information>
                  </div>
                  <button
                    class="btn btn-primary pull-right"
                    type="submit"
                    (click)="sendConfirmEmail()"
                    [disabled]="
                      !registrationForm.get('businessInformation')?.valid
                    "
                    awNextStep
                  >
                    Confirmer
                  </button>
                  <button
                    class="btn btn-outline-primary pull-left me-1"
                    type="button"
                    awPreviousStep
                  >
                    Retour
                  </button>
                </aw-wizard-step>
                <aw-wizard-completion-step awEnableBackLinks>
                  <div class="tab">
                    <div class="form-group">
                      <div class="d-flex justify-content-center pb-4">
                        <img
                          src="assets/identity/contact-us.png"
                          alt=""
                          class="img-110"
                        />
                      </div>

                      <h5 style="text-align: center">
                        Consultez le mail de validation que vous avez reçu.
                      </h5>
                      <h6
                        class="color-primary"
                        style="text-align: center; margin-bottom: 20px"
                      ></h6>
                    </div>
                  </div>
                </aw-wizard-completion-step>
              </aw-wizard>
            </div>
            <hr />
            <p>
              Vous êtes déjà inscrit ?<a
                class="ms-2 color-primary"
                href=""
                routerLink="../login"
                >Connexion</a
              >
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
