<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div class="d-flex justify-content-center">
            <div
              class="img-contour d-flex justify-content-center align-items-center"
            >
              <img
                src="assets/identity/logo-footer.png"
                width="60"
                height="60"
                alt="icon relead"
              />
            </div>
          </div>

          <!--Forgot password-->
          <div class="theme-form login-form needs-validation">
            <form
              *ngIf="step == 1"
              novalidate
              [ngClass]="{ 'was-validated': validate }"
              [formGroup]="formForgotPasswordStep1"
              (ngSubmit)="forgotPasswordStep1($event)"
            >
              <h4 style="text-align: center">
                Réinitialiser votre mot de passe
              </h4>

              <div class="form-group mt-4">
                <label *ngIf="!mailSended" style="color: black"
                  >Saisissez votre adresse e-mail</label
                >
                <div class="input-group">
                  <span class="input-group-text">
                    <img src="assets/identity/email.png" alt="icone email" />
                  </span>
                  <input
                    formControlName="email"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid':
                        emailStep &&
                        !formForgotPasswordStep1.get('email')?.valid &&
                        !formForgotPasswordStep1.get('email')?.touched
                    }"
                    type="email"
                    [readOnly]="mailSended"
                    required=""
                    placeholder="Entrez votre e-mail"
                  />
                  <div class="invalid-feedback">
                    <div *ngIf="emailStep && email?.errors?.['required']">
                      L'e-mail est requis.
                    </div>
                    <div></div>
                    <div *ngIf="emailStep && email?.errors?.['email']">
                      L'e-mail doit être une adresse e-mail valide.
                    </div>
                  </div>
                  <div *ngIf="email?.valid" class="valid-tooltip">
                    Bien vérifié.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <button
                  *ngIf="!mailSended"
                  [disabled]="sendEmailLoader$ | async"
                  class="btn btn-primary btn-block"
                  type="submit"
                >
                  <loading-button
                    [loading]="sendEmailLoader$ | async"
                    [value]="'Envoyer'"
                    [icon]="null"
                  ></loading-button>
                </button>
              </div>
            </form>

            <!--Update password-->
            <form
              novalidate
              [ngClass]="{ 'was-validated': validate }"
              [formGroup]="formResetPasswordStep2"
              (ngSubmit)="resetPasswordStep2($event)"
            >
              <div *ngIf="step == 1 && mailSended">
                <p style="text-align: left; margin-bottom: 20px">
                  Je n’ai rien reçu?
                  <a
                    class="color-primary underline-link"
                    (click)="forgotPasswordStep1($event)"
                    >Renvoyer</a
                  >
                </p>
                <div class="form-group m-t-4">
                  <label>Entrez le code que vous avez reçu</label>
                  <div class="input-group">
                    <span class="input-group-text"
                      ><i class="fa fa-key"></i
                    ></span>
                    <input
                      formControlName="token"
                      class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && token?.errors }"
                      type="text"
                      name="token"
                      required=""
                      placeholder="Entrez votre code, s'il vous plaît"
                    />
                    <div
                      *ngIf="submitted && token?.errors"
                      class="invalid-feedback"
                    >
                      <div class="invalid-feedback">
                        Entrez votre code de confirmation
                      </div>
                      <div *ngIf="token?.errors?.['required']">
                        Le jeton est requis.
                      </div>
                    </div>
                    <!-- <div *ngIf="email?.valid" class="valid-feedback">
                      Looks good.
                    </div> -->
                  </div>
                </div>
                <div class="form-group">
                  <button
                    (click)="nextStep()"
                    [disabled]="
                      (sendEmailLoader$ | async) ||
                      formResetPasswordStep2.get('token')?.invalid
                    "
                    class="btn btn-primary btn-block"
                    type="button"
                  >
                    <loading-button
                      [loading]="
                        (sendEmailLoader$ | async) &&
                        formResetPasswordStep2.get('token')?.valid
                      "
                      [value]="'Suivant'"
                      [icon]="null"
                    ></loading-button>
                  </button>
                </div>
              </div>
              <div *ngIf="step == 2">
                <h4 style="text-align: center">
                  Réinitialiser votre mot de passe
                </h4>
                <div class="form-group">
                  <label>Nouveau mot de passe</label>
                  <div class="input-group">
                    <span class="input-group-text"
                      ><i class="icon-lock"></i
                    ></span>
                    <input
                      formControlName="password"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && password?.errors
                      }"
                      type="password"
                      name="login[password]"
                      [type]="showPassword ? 'text' : 'password'"
                      required=""
                      placeholder="*********"
                      [ngClass]="{
                        'is-invalid':
                          !formResetPasswordStep2.get('password')?.valid &&
                          formResetPasswordStep2.get('password')?.touched
                      }"
                    />
                    <div
                      class="show-hide"
                      (click)="clickShowPassword()"
                      *ngIf="!showPassword"
                    >
                      <span class="show"></span>
                    </div>
                    <div
                      class="show-hide"
                      (click)="clickShowPassword()"
                      *ngIf="showPassword"
                    >
                      <span class="Hide"></span>
                    </div>
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        !formResetPasswordStep2.get('password')?.valid &&
                        formResetPasswordStep2.get('password')?.touched
                      "
                    >
                      Veuillez entrer un mot de passe valide
                      <ul style="padding-left: 10px">
                        <li>
                          - Au moins un caractère alphabétique (minuscule ou
                          majuscule) doit être présent.
                        </li>
                        <li>
                          - Au moins un chiffre numérique (0-9) doit être
                          présent.
                        </li>
                        <li>
                          - La longueur totale du mot de passe doit être d'au
                          moins 8 caractères et au plus 32 caractères.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Confirmer le nouveau mot de passe</label>
                  <div class="input-group">
                    <span class="input-group-text"
                      ><i class="icon-lock"></i
                    ></span>
                    <input
                      formControlName="confirmPassword"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && confirmPassword?.errors
                      }"
                      [type]="showConfirmPassword ? 'text' : 'password'"
                      required=""
                      placeholder="*********"
                      [ngClass]="{
                        'is-invalid':
                          !formResetPasswordStep2.get('confirmPassword')
                            ?.valid &&
                          formResetPasswordStep2.get('confirmPassword')?.touched
                      }"
                    />
                    <div
                      class="show-hide"
                      (click)="clickShowConfirmPassword()"
                      *ngIf="!showConfirmPassword"
                    >
                      <span class="show"></span>
                    </div>
                    <div
                      class="show-hide"
                      (click)="clickShowConfirmPassword()"
                      *ngIf="showConfirmPassword"
                    >
                      <span class="Hide"></span>
                    </div>
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        !formResetPasswordStep2.get('confirmPassword')?.valid &&
                        formResetPasswordStep2.get('confirmPassword')?.touched
                      "
                    >
                      Veuillez entrer un mot de passe valide
                      <ul style="padding-left: 10px">
                        <li>
                          - Au moins un caractère alphabétique (minuscule ou
                          majuscule) doit être présent.
                        </li>
                        <li>
                          - Au moins un chiffre numérique (0-9) doit être
                          présent.
                        </li>
                        <li>
                          - La longueur totale du mot de passe doit être d'au
                          moins 8 caractères et au plus 32 caractères.
                        </li>
                        <li>
                          - Le mot de passe de confirmation doit correspondre au
                          mot de passe.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <button
                    [disabled]="
                      (sendEmailLoader$ | async) ||
                      !formResetPasswordStep2.valid ||
                      submitted
                    "
                    class="btn btn-primary btn-block"
                    type="submit"
                  >
                    <loading-button
                      [loading]="submitted"
                      [value]="'Envoyer'"
                      [icon]="null"
                    ></loading-button>
                  </button>
                </div>
              </div>
              <hr />
              <p>
                Vous n'avez pas de compte ?<a
                  class="ms-2"
                  href=""
                  routerLink="../login"
                >
                  Se connecter</a
                >
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
