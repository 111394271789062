import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RoleEnum } from "src/app/shared/models/user/role.interface";
import { JwtService } from "src/app/shared/services/jwt.service";

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
})
export class AccountComponent implements OnInit {
  public userName: string;

  role: string;
  roleName: string;
  roleToShow = RoleEnum;
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private jwtService: JwtService
  ) {}
  ngOnInit(): void {
    this.role = this.jwtService.getRoleName(this.jwtService.getToken());
    switch (this.role) {
      case RoleEnum.ROLE_ADMIN:
        this.roleName = "admin";
        break;
      case RoleEnum.ROLE_ADVERTISER:
        this.roleName = "advertiser";
        break;
      case RoleEnum.ROLE_LOCATION:
        this.roleName = "location-partner";
        break;
      default:
        break;
    }
  }
  openLogoutModal(simpleContent: any) {
    const modalRef = this.modalService.open(simpleContent, { centered: true });
  }
  logout() {
    localStorage.clear();
    this.router.navigate(["/auth/login"]);
  }
}
