import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeAllZoneNames,
  saveSelectedZone,
} from "src/app/store/zone/zone.actions";
import {
  allZoneNamesSelector,
  saveSelectedZonesSelector,
} from "src/app/store/zone/zone.selectors";

@Component({
  selector: "app-zone",
  templateUrl: "./zone.component.html",
})
export class ZoneComponent extends BaseComponent implements OnInit {
  zone: string;
  getAllZoneNames$: Observable<string[] | null>;
  getAllZones: string[];
  selectedZones: string[] = [];
  selectedZones$: Observable<string[] | null>;
  constructor(
    private modalService: NgbModal,
    private store: Store<AppStateInterface>
  ) {
    super(store);
    this.getAllZoneNames$ = this.store
      .pipe(select(allZoneNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.selectedZones$ = this.store
      .pipe(select(saveSelectedZonesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.store.dispatch(invokeAllZoneNames());
    this.getAllZoneNames$.subscribe((data) => {
      if (data) this.getAllZones = data;
    });
    this.selectedZones$.subscribe((data) => {
      if (data) this.selectedZones = data;
    });
  }
  onClickDismissModal() {
    this.modalService.dismissAll();
  }
  CheckZoneSelectedAll() {
    return this.getAllZones.length == this.selectedZones.length;
  }
  selectAllZones(event: any) {
    if (event.target.checked) {
      Object.values(this.getAllZones).map((value, index) => {
        this.selectedZones = [...this.selectedZones, value];
      });
    } else {
      this.selectedZones = [];
    }
  }
  searchTerm(zone: string) {
    this.getAllZones.filter((val) => {
      return val === zone;
    });
  }
  submitZones() {
    this.store.dispatch(
      saveSelectedZone({ selectedZones: this.selectedZones })
    );
    this.onClickDismissModal();
  }
  saveZones(event: any, zone: string) {
    if (event.target.checked) {
      this.selectedZones = [...this.selectedZones, zone];
    } else {
      this.selectedZones = this.selectedZones.filter(
        (location) => location != zone
      );
    }
  }
}
