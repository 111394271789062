import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  CheckPaymentPaymeeInterface,
  PostPaymeeInterface,
  ResultPaymeeInterface,
} from "../../models/payment/paymee.interface";
import { Observable } from "rxjs";
import { Constants } from "../../data/constants";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PaymeeService {
  constructor(private http: HttpClient) {}

  postPayment(paymee: PostPaymeeInterface): Observable<ResultPaymeeInterface> {
    return this.http.post<ResultPaymeeInterface>(
      `${environment.PAYMENT.APP_PAYMEE.URL + Constants.PAYMEE_END_POINT}/create`,
      paymee
    );
  }

  getpaymentInfo(token: string): Observable<CheckPaymentPaymeeInterface> {
    return this.http.get<CheckPaymentPaymeeInterface>(
      `${environment.PAYMENT.APP_PAYMEE.URL + Constants.PAYMEE_END_POINT}/` +
        token +
        "/check"
    );
  }
}
