import { createAction, props } from "@ngrx/store";
import { ReleadUser } from "src/app/shared/models/user/relead-user.interface";
import { Account } from "../../shared/models/user/account";

export const invokeAccount = createAction("[Account] invoke Account");

export const setAccount = createAction(
  "[Account] set Account",
  props<{ account: Account }>()
);
export const invokeLogin = createAction(
  "[Login] invoke Login",
  props<{ email: string; password: string, ipAddress: string | null }>()
);
export const loginSuccess = createAction(
  "[Login Success] login User Success",
  props<{ token: string | null }>()
);
export const invokeLogout = createAction(
  "[Logout] logout User "
);

export const invokeLogoutAllSessions = createAction(
  "[Logout All Sessions] logout User All Sessions"
);

export const logoutSuccess = createAction(
  "[Logout Success] logout User Success",
  props<{ logout: boolean, token:string | null }>()
);

export const logoutError = createAction(
  "[Logout Error] logout User Error"
);
