<div class="card">
  <div class="card-header">
    <div class="media-space-between">
      <h4>{{ "estimates.title" | translate }}</h4>
      <!--<div class="d-inline-flex">
        <div
          class="faq-form mr-3"
          style="color: darkgrey; display: flex; margin-left: 10px"
        >
          <i class="search-icon" data-feather="search"></i>
          <div>
            <div class="input-group">
              <input
                id="table-complete-search"
                type="text"
                class="form-control"
                style="width: 17rem;"
                [placeholder]="'placeholder.searchByCampaignTitle' | translate"
                [(ngModel)]="ad"
                (ngModelChange)="searchTerm(ad)"
              />
              <span
                class="input-group-text p-0"
                style="background-color: transparent"
              >
                <app-general-filter
                  [filter]="filterAds"
                  (optionSelected)="filterByStatus($event)"
                >
                </app-general-filter>
              </span>
            </div>
          </div>
          
        </div>
      </div>-->
      <app-general-filter
        [filter]="filterAds"
        (optionSelected)="filterByStatus($event)"
      >
      </app-general-filter>
    </div>
  </div>
  <div class="card-body">
    <div>
      <form class="table-responsive data-table">
        <table class="table table-striped table-border-vertical">
          <thead>
            <tr>
              <ng-container *ngFor="let column of columns">
                <th>
                  {{ column.label | translate }}
                </th>
              </ng-container>
              <th>{{ "dashboard.status" | translate }}</th>
              <th>{{ "buttons.actions" | translate }}</th>
            </tr>
          </thead>
          <tbody *ngIf="!(loading$ | async)">
            <ng-container>
              <tr
                *ngFor="let order of resultEstimatesFromIberis; let j = index"
              >
                <ng-template #NONE>
                  <td>{{ "undefined" | translate }}</td>
                </ng-template>
                <td *ngIf="order.estimate_number; else NONE">
                  {{ order.estimate_number }}
                </td>
                <td *ngIf="order.date; else NONE">{{ order.date }}</td>
                <td *ngIf="order.due; else NONE">{{ order.due }}</td>
                <td *ngIf="order.object; else NONE">{{ order.object }}</td>
                <td>
                  <span
                    [ngClass]="
                      'badge ' +
                      badgeService.getBadgeByOrderStatus(adsStatus[j])
                    "
                  >
                    <div *ngIf="adsStatus[j] != null; else none">
                      {{ "enumMapping.Commande." + adsStatus[j] | translate }}
                    </div>
                    <ng-template #none>
                      {{ "enumMapping.UNDEFINED" | translate }}
                    </ng-template>
                  </span>
                </td>
                <td>
                  <div
                    *ngIf="
                      isLoadingDownload && specificColumun == j;
                      else default
                    "
                    class="loader-box"
                    style="height: 20px"
                  >
                    <div class="loader-34"></div>
                  </div>
                  <ng-template #default>
                    <app-drob-zone-options
                      [options]="getOptions(adsStatus[j])"
                      [isLoading]="isLoadingDownload"
                      (optionSelected)="saveAction($event, order, j)"
                    ></app-drob-zone-options>
                  </ng-template>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <div *ngIf="loading$ | async">
          <ng-container style="float: right" class="flex">
            <div class="loader-box">
              <div class="loader-34"></div>
            </div>
            <h6
              class="col align-self-center f-w-600"
              style="text-align: center"
            >
              {{ "fetching" | translate }}
            </h6>
          </ng-container>
        </div>
        <div
          *ngIf="totalRecords == 0 && !(loading$ | async)"
          class="d-flex justify-content-center m-t-20"
        >
          {{ "noData" | translate }}
        </div>
        <div class="table-responsive data-table">
          <form>
            <div class="d-flex">
              <div class="col-3"></div>
              <div class="col-6">
                <div class="d-flex justify-content-center p-3">
                  <ngb-pagination
                    [collectionSize]="totalRecords"
                    [rotate]="true"
                    [boundaryLinks]="true"
                    (pageChange)="changePage($event)"
                  >
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </form>
        </div>
      </form>
    </div>
  </div>
</div>
