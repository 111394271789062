import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, takeUntil } from "rxjs";
import {
  invokeCollaborators,
  invokeCreateCollaborator,
  invokeDeleteCollaborator,
  invokeAdminProfile,
  invokeMainDashboard,
  invokeUpdateCollaborator,
  setCollaborators,
  setCrudCollaboratorSuccess,
  setMainDashboard,
  setProfileSuccess,
  invokeUpdateAdminProfile,
  invokeSideBarNews,
  setSideBarNews,
} from "./admin.actions";
import { invokeComponentDistroyed, setAPIStatus } from "../apiState.interface";
import { AdminService } from "src/app/shared/services/admin.service";
import { CollaboratorInterface } from "src/app/shared/models/admin/collaborator.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { AdminProfileInterface } from "src/app/shared/models/admin/admin-profile.interface";
import { AdminMainDashboard } from "src/app/shared/models/admin/admin-main-dashboard.interface";
import { SideBarNewsInterface } from "src/app/shared/models/admin/side-bar-news.interface";

@Injectable()
export class AdminEffects {
  constructor(private actions$: Actions, private adminService: AdminService) {}

  invokeMainDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeMainDashboard),
      mergeMap((state) => {
        return this.adminService.getMainDashboard().pipe(
          map((data: AdminMainDashboard | null) =>
            setMainDashboard({ mainDashboard: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          ),
          takeUntil(this.actions$.pipe(
            ofType(invokeComponentDistroyed)
          ))
        );
      })
    )
  );

  invokeCollaborators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCollaborators),
      mergeMap((state) => {
        return this.adminService.getCollaborators().pipe(
          map((data: CollaboratorInterface[]) =>
            setCollaborators({ collaborators: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeCreateCollaborators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCreateCollaborator),
      mergeMap((state) => {
        return this.adminService.createCollaborator(state.collaborator).pipe(
          map((data: SuccessDto) =>
            setCrudCollaboratorSuccess({ success: data.message })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeUpdateCollaborator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateCollaborator),
      mergeMap((state) => {
        return this.adminService
          .updateCollaborator(state.email, state.collaborator)
          .pipe(
            map((data: SuccessDto) =>
              setCrudCollaboratorSuccess({ success: data.message })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeDeleteCollaborator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeDeleteCollaborator),
      mergeMap((state) => {
        return this.adminService.deleteCollaborator(state.email).pipe(
          map((data: SuccessDto) =>
            setCrudCollaboratorSuccess({ success: data.message })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeGetProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAdminProfile),
      mergeMap((state) => {
        return this.adminService.getProfile().pipe(
          map((data: AdminProfileInterface) =>
            setProfileSuccess({ profile: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeUpdateAdminProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateAdminProfile),
      mergeMap((state) => {
        return this.adminService.updateAdminProfile(state.profile).pipe(
          map((data: SuccessDto) =>
            setCrudCollaboratorSuccess({ success: data.message })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeSideBarNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeSideBarNews),
      mergeMap((state) => {
        return this.adminService.invokeSideBarNews().pipe(
          map((sideBarNews: SideBarNewsInterface) =>
          setSideBarNews({ sideBarNews: sideBarNews })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
}
