import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { setAPIStatus } from "../apiState.interface";
import { Store } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";
import { PaymentReceivedService } from "src/app/shared/services/iberis/payment-received.service";
import { invokePayment, resultPaymentReceived } from "./payments-received.actions";
import { PaymentReceived } from "src/app/shared/models/iberis/paymentsReceived/paymentReceived";
import { NextPayment } from "src/app/shared/models/iberis/paymentsReceived/nextPayment";

@Injectable()
export class PaymentReceivedEffect {
  constructor(
    private actions$: Actions,
    private store: Store<AppStateInterface>,
    private paymentReceivedService: PaymentReceivedService
  ) {}

  invokePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokePayment),
      mergeMap((state) => {
        return this.paymentReceivedService.createPaymentReceived(state.params, state.paymentReceived).pipe(
          map((data: PaymentReceived| null) =>
          resultPaymentReceived({ resultPaymentReceived: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
}
