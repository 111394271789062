<form [formGroup]="form">
  <div class="form-group">
    <label class="label">{{ "profile.profileDetails.companyName" | translate }}</label>
    <div class="input-group">
      <input
        formControlName="companyName"
        class="form-control"
        type="text"
        required=""
        [placeholder]="'placeholder.placeholder_CompanyName' | translate"
      />
    </div>
  </div>
  <div class="form-group m-t-20 m-b-20">
    <label class="label">{{ "profile.profileDetails.activity" | translate }}</label>
    <div class="input-group">
      <select
        class="form-select form-control"
        formControlName="companyActivity"
      >
        <option selected disabled [ngValue]="null">
          {{ "addLP.secondStep.placeholderActivity" | translate }}
        </option>
        <option
          class="form-select"
          *ngFor="let companyActivity of companyActivity"
          [value]="companyActivity.hashed_id"
        >
          {{ companyActivity.title }}
        </option>
      </select>
    </div>
  </div>
  <div class="form-group">
    <label class="label">{{ "profile.profileDetails.companyAddress" | translate }}</label>
    <div class="input-group">
      <input
        formControlName="companyAddress"
        class="form-control"
        type="text"
        required=""
        [placeholder]="'placeholder.placeholder_CompanyAddress' | translate"
      />
    </div>
  </div>
  <div class="form-group m-t-20 m-b-20">
    <label class="label">{{ "profile.profileDetails.role" | translate }}</label>
    <div class="input-group">
      <input
        formControlName="companyRole"
        class="form-control"
        type="text"
        required=""
        [placeholder]="'placeholder.placeholder_role' | translate"
      />
    </div>
  </div>
  <div class="form-group m-t-20 m-b-20">
    <label class="label">{{ "profile.profileDetails.webSite" | translate }}</label>
    <div class="input-group">
      <input
        formControlName="website"
        class="form-control"
        type="text"
        required=""
        [placeholder]="'placeholder.placeholder_Website' | translate"
      />
    </div>
  </div>
</form>
