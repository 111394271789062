<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">
    {{ "logOut.title" | translate }}
  </h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  ></button>
</div>

<div class="card-body">
  <h4 class="modal-body d-flex justify-content-center m-0">
    {{ "logOut.description" | translate }}
  </h4>
  <div class="p-3 pt-0">
      <ng-container>
        <p>
          {{ "logOut.allSessions1" | translate }}
          <b>{{ "logOut.allSessions2" | translate }}</b
          >?
          <a
            class="color-primary underline-link"
            (click)="logoutAllSessions()"
            >{{ "logOut.clickHere" | translate }}</a
          >
          <!-- <button
            class="btn btn-primary btn-xs"
            type="button"
            (click)="logoutAllSessions()"
          >
          {{ "logOut.clickHere" | translate }}
          </button> -->
        </p>
      </ng-container>
    </div>
  </div>
<div class="modal-footer">
  <button
    class="btn btn-outline-primary"
    type="button"
    data-bs-dismiss="modal"
    (click)="closeModal()"
  >
    {{ "logOut.no" | translate }}
  </button>
  <button class="btn btn-primary" type="button" (click)="logout()">
    <loading-button
    [loading]="logout$ | async"
    [value]="'logOut.yes'"
    [icon]="null"
  ></loading-button>
  </button>
</div>
