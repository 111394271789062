<div>
  <div
    class="dropdown"
    appClickOutside
    (clickOutside)="onOutsideClick($event, j)"
  >
    <a
      id="dropdownMenuButton"
      type="button"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      (click)="openMenu(j)"
    >
      <span
        style="margin-bottom: 6px"
        *ngFor="let locations of locationPartners; let i = index"
        [ngClass]="'badge badge-light-primary'"
      >
        <div *ngIf="i < 5">
          {{ locations }}
        </div>
      </span>
      <span
        style="margin-bottom: 6px"
        *ngIf="locationPartners.length > 6"
        [ngClass]="'badge badge-light-primary'"
      >
        ...
      </span>
    </a>
    <div *ngIf="locationPartners.length > 6"
      class="dropdown-menu position-right"
      [class.show]="show[j]"
      aria-labelledby="dropdownMenuButton"
    >
      <a
        class="dropdown-item d-flex"
        style="flex-wrap: wrap; justify-content: center"
      >
        <div *ngFor="let location of locationPartners">
          <span class="m-b-5 m-r-5" [ngClass]="'badge badge-light-primary'">
            {{ location }}
          </span>
        </div>
        </a
      >
    </div>
  </div>
</div>
<!--
  <div>
  <div
    appClickOutside
    (clickOutside)="onOutsideClick($event, j)"
  >
    <a
      id="dropdownMenuButton"
      type="button"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      (click)="openMenu(j)"
    >
      <span
        style="margin-bottom: 6px"
        *ngFor="let locations of locationPartners; let i = index"
        [ngClass]="'badge badge-light-info'"
      >
        <div *ngIf="i < 5">
          {{ locations }}
        </div>
      </span>
      <span
        style="margin-bottom: 6px"
        *ngIf="locationPartners.length > 6"
        [ngClass]="'badge badge-light-info'"
      >
        ...
      </span>
    </a>
          <div *ngIf="locationPartners.length > 6"
      class="dropdown-menu"
      style="position: absolute; right: 12%;"
      [class.show]="show[j]"
      aria-labelledby="dropdownMenuButton"
    >
      <a
        class="dropdown-item"
      >
        <div *ngFor="let location of locationPartners">
          <span class="m-b-5 m-r-5" [ngClass]="'badge badge-light-info'">
            {{ location }}
          </span>
        </div>
        </a
      >
    </div>
  </div>
</div>

-->