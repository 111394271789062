export enum SessionTimeOut {
  MIN5 = "MIN5",
  MIN30 = "MIN30",
  MIN45 = "MIN45",
  MIN90 = "MIN90",
  MIN120 = "MIN120",
  H24 = "H24",
}
export const SessionTimeOutToShow: Record<SessionTimeOut, string> = {
  [SessionTimeOut.MIN5]: "5 MIN",
  [SessionTimeOut.MIN30]: "30 MIN",
  [SessionTimeOut.MIN45]: "45 MIN",
  [SessionTimeOut.MIN90]: "90 MIN",
  [SessionTimeOut.MIN120]: "120 MIN",
  [SessionTimeOut.H24]: "324 H",
};
export interface SessionTimeOutInterface {
  id: SessionTimeOut;
  value: string;
}
