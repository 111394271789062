import { createAction, props } from "@ngrx/store";
import { BillingInterface } from "src/app/shared/models/billing/billing.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";

export const invokeBillingInfo = createAction(
  "[Invoque billing Info] Invoque billing Info", 
  props<{email : string}>()
);

export const invokeBillingInfoSuccess = createAction(
  "[Invoque billing Info Success] Invoque billing Info Success",
  props<{ billing: BillingInterface | null }>()
);

export const billingInfoSuccessMessage = createAction(
  "[billing Info Success message] billing Info Success ",
  props<{ successMessage: SuccessDto | null }>()
);

export const createUpdateBillingInfo = createAction(
  "[Create billing Info] Create billing Info",
  props<{ billing: BillingInterface }>()
)
