import { createReducer, on } from "@ngrx/store";
import { languageData } from "src/app/shared/data/languages";
import { invokeLanguage, saveUpdateFields, updateFields } from "./settings.actions";
import { SettingsStateInterface } from "./settingsState.interface";

const langFromLS = localStorage.getItem("lang");
export const initialState: Readonly<SettingsStateInterface> = {
  language: langFromLS
    ? languageData.find((l) => l.key == langFromLS) ?? languageData[1]
    : languageData[1],
  options : null ,
  fields : null
};

export const SettingReducer = createReducer(
  initialState,
  on(invokeLanguage, (state, { lang }) => ({
    ...state,
    language: lang,
  })),
  on(saveUpdateFields , (state , {options})=>({
    ...state,
    options : options
  })),
  on(updateFields, (state, {fields})=>({
    ...state,
    fields : fields
  }))
);
