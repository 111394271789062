<div
  class="product-wrapper"
  [class.sidebaron]="openSidebar"
  appClickOutside
  (clickOutside)="onOutsideClick($event)"
>
  <div class="product-grid">
    <div class="feature-prod'ucts">
      <div class="pro-filter-sec">
        <div
          class="product-sidebar"
          [class.open]="openSidebar"
          style="height: 45px"
        >
          <div class="filter-section">
            <div class="card" style="box-shadow: none; border: 0">
              <a type="button" class="project-box" (click)="sidebarToggle()">
                <i class="icon-filter" style="color: #ff69b4"></i>
              </a>
              <div class="left-filter">
                <div class="card-body filter-cards-view animate-chk">
                  <div class="product-filter">
                    <p style="font-size: 12px" class="m-0">
                      {{ "filter.Options" | translate }}
                    </p>
                    <hr />
                    <div class="dropdown">
                      <a
                        style="font-size: 12px"
                        id="dropdownMenuButton"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        (click)="openMenu()"
                      >
                        {{ "filter.splineFilter.metrics" | translate }}
                      </a>
                      <div
                        class="dropdown-menu"
                        style="right: -11%"
                        [class.show]="show"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a class="dropdown-item">
                          <div class="filter-cards-view animate-chk p-1 pt-2">
                            <div class="product-filter">
                              <div class="list-group">
                                <span style="color: black" class="p-2">
                                  <a
                                    style="font-size: 12px"
                                    (click)="
                                      saveOptionForSummaryChart('Charts.views')
                                    "
                                    type="button"
                                    >{{
                                      "filter.splineFilter.impressions"
                                        | translate
                                    }}
                                    <i
                                      *ngIf="activeImpression % 2 == 0"
                                      class="fa fa-check font-primary"
                                    ></i
                                  ></a>
                                </span>
                                <hr style="padding: 0; margin: 0" />
                                <span style="color: black" class="p-2">
                                  <a
                                    style="font-size: 12px"
                                    (click)="
                                      saveOptionForSummaryChart(
                                        'Charts.uniqueViews'
                                      )
                                    "
                                    type="button"
                                    >{{
                                      "filter.splineFilter.uniqueViews"
                                        | translate
                                    }}
                                    <i
                                      class="fa fa-check font-primary"
                                      *ngIf="activeUniqueViews % 2 == 0"
                                    ></i
                                  ></a>
                                </span>
                                <hr style="padding: 0; margin: 0" />
                                <span style="color: black" class="p-2">
                                  <a
                                    style="font-size: 12px"
                                    (click)="
                                      saveOptionForSummaryChart(
                                        'Charts.completed'
                                      )
                                    "
                                    type="button"
                                    >{{
                                      "Charts.completed"
                                        | translate
                                    }}
                                    <i
                                      class="fa fa-check font-primary"
                                      *ngIf="activeCompleted % 2 == 0"
                                    ></i
                                  ></a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
