import { createReducer, on } from "@ngrx/store";
import {
  createUpdateTagSuccess,
  deleteTagSuccess,
  invokeAllTagNames,
  invokeAllTags,
  setAllTagNames,
  setAllTags,
} from "./tag.actions";
import { TagStateInterface } from "./tagState.interface";

export const initialState: Readonly<TagStateInterface> = {
  loading: false,
  tagNames: null,
  createUpdateSuccess: null,
  deleteSuccess: null,
  tags: null,
};
export const TagReducer = createReducer(
  initialState,
  on(invokeAllTagNames, (state) => ({
    ...state,
    loading: true,
  })),
  on(setAllTagNames, (state, { tagNames }) => ({
    ...state,
    tagNames: tagNames,
    loading: false,
  })),
  on(invokeAllTags, (state) => ({
    ...state,
    loading: true,
  })),
  on(setAllTags, (state, { tags }) => ({
    ...state,
    tags: tags,
    loading: false,
  })),
  on(createUpdateTagSuccess, (state, { message }) => ({
    ...state,
    createUpdateSuccess: message,
    loading: false,
  })),
/*   on(invokeDeleteTag, (state) => ({
    ...state,
    loading: true,
  })), */
  on(deleteTagSuccess, (state, { message }) => ({
    ...state,
    deleteSuccess: message,
    loading: false,
  }))
);
