import { DOCUMENT } from "@angular/common";
import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { languageData, LanguageInterface } from "src/app/shared/data/languages";
import { DashboardSettingsInterface } from "src/app/shared/models/dashboard-settings.interface";
import { LayoutService } from "src/app/shared/services/layout/layout.service";
import { NavService } from "src/app/shared/services/nav.service";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeLanguage } from "src/app/store/settings/settings.actions";
import { languageSelector } from "src/app/store/settings/settings.selectors";
import { invokeDashboardSettings } from "src/app/store/user/user.actions";
import { dashboardSettingsSelector } from "src/app/store/user/user.selectors";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
})
export class SettingsComponent extends BaseComponent implements OnInit {
  dashBoardSettings$: Observable<DashboardSettingsInterface | null>;
  dashBoardSettings: DashboardSettingsInterface | null;
  //mode
  public dark: boolean =
    this.layout.config.settings.layout_version == "dark-only" ? true : false;

  public selectedAnimation: any;

  LayoutTypeLTR: boolean | null =
    this.layout.config.settings.layout_type == "ltr" ? true : false;
  //side bar
  public sidebarCompactType: boolean = true;
  public screenwidth: any = window.innerWidth;

  //language
  getLanguage$: Observable<LanguageInterface>;
  languages: LanguageInterface[] = languageData;

  elem: any;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;
  public layoutType: string = this.layout.config.settings.layout_type;

  langForm: FormControl;
  animationForm: FormControl<string | null> = new FormControl(
    localStorage.getItem("animate")
  );

  constructor(
    private store: Store<AppStateInterface>,
    private layout: LayoutService,
    private navServices: NavService,
    @Inject(DOCUMENT) private document: any,
    protected translate: TranslateService
  ) {
    super(store);
    this.dashBoardSettings$ = this.store
      .pipe(select(dashboardSettingsSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.getLanguage$ = this.store
      .pipe(select(languageSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.langForm = new FormControl();
    this.animationForm = new FormControl();
  }
  setLang: boolean = false;
  ngOnInit(): void {
    this.animationForm.patchValue(localStorage.getItem("animate"));
    this.animationForm.valueChanges.subscribe((value) => {
      if (value) {
        localStorage.setItem("animate", value);
        window.location.reload();
      }
    });

    //Language
    this.getLanguage$.subscribe((lang) => {
      if (!this.setLang) {
        this.langForm.patchValue(lang.key);
        this.setLang = true;
      }
    });
    this.langForm.valueChanges.subscribe((key: string) => {
      const languageSeleted: LanguageInterface =
        languageData.find((value) => value.key == key) ?? languageData[0];
      this.store.dispatch(invokeLanguage({ lang: languageSeleted }));
      this.translate.use(languageSeleted.key);
      localStorage.setItem("lang", languageSeleted.key);
    });
    this.languages = languageData;

    this.elem = document.documentElement;

    this.dashBoardSettings$.subscribe(
      (data: DashboardSettingsInterface | null) => {
        if (data != null) {
          this.layout.config = data;
          this.sidebarCompactType =
            data.settings.sidebar == "compact-wrapper" ? true : false;
          this.dark =
            data.settings.layout_version == "dark-only" ? true : false;
          this.LayoutTypeLTR =
            data.settings.layout_type == "ltr"
              ? true
              : data.settings.layout_type == "rtl"
              ? false
              : null;
          this.layout.runLayout_type();
        }
      }
    );
  }

  // mode
  layoutToggle(mode: any) {
    this.store.dispatch(
      invokeDashboardSettings({
        dashboardSettings: {
          ...this.layout.config,
          settings: {
            ...this.layout.config.settings,
            layout_version: this.dark ? "light" : "dark-only",
          },
        },
      })
    );
  }

  //Card full screen
  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
        localStorage.setItem("fullScreen", "on");
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
        localStorage.setItem("fullScreen", "on");
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
        localStorage.setItem("fullScreen", "on");
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
        localStorage.setItem("fullScreen", "on");
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
        localStorage.setItem("fullScreen", "off");
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
        localStorage.setItem("fullScreen", "off");
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
        localStorage.setItem("fullScreen", "off");
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
        localStorage.setItem("fullScreen", "off");
      }
    }
  }
  customizeLayoutType(val: any) {
    document.getElementsByTagName("html")[0].removeAttribute("dir");
    document.body?.classList.remove("box-layout");
    if (val == "rtl" || val == "ltr") {
      document.getElementsByTagName("html")[0].setAttribute("dir", val);
    } else {
      document.body?.classList.add("box-layout");
    }
    this.store.dispatch(
      invokeDashboardSettings({
        dashboardSettings: {
          ...this.layout.config,
          settings: {
            ...this.layout.config.settings,
            layout_type: val,
          },
        },
      })
    );
  }
  customizeSidebarType(val: any) {
    this.store.dispatch(
      invokeDashboardSettings({
        dashboardSettings: {
          ...this.layout.config,
          settings: {
            ...this.layout.config.settings,
            sidebar: val,
          },
        },
      })
    );
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.screenwidth = event.target.innerWidth;
  }
  getAnimation() {
    if (this.selectedAnimation) {
      localStorage.setItem(
        "animate",
        this.selectedAnimation || localStorage.getItem("animate")
      );
      window.location.reload();
    }
  }
}
