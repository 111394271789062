import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  WelcomeCard,
  WelcomeCardAdminData,
  WelcomeCardAdvertiserData,
  WelcomeCardLocationMainData,
  WelcomeCardLocationStatisticsData,
} from "../../data/welcomeCardAdvertiserData";
import { RoleEnum } from "../../models/user/role.interface";
import { JwtService } from "../../services/jwt.service";

@Component({
  selector: "app-welcome-card",
  templateUrl: "./welcome-card.component.html",
})
export class WelcomeCardComponent implements OnInit {
  @ViewChild("heightCard1") heightCard1: ElementRef;
  @ViewChild("heightCard2") heightCard2: ElementRef;
  @Input("result") result: number | string;
  @Input("loading$") loading$: boolean | null;
  @Input("firstName") firstName?: string;
  @Input("lastName") lastName?: string;
  welcomeCardData: WelcomeCard;
  role: string;
  statics: boolean;
  Role = RoleEnum;
  constructor(
    private jwtService: JwtService,
    private route: Router,
    protected translate: TranslateService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.statics = this.route.url.endsWith("/statistics");
      }
    });
  }
  ngOnInit(): void {
    
    this.role = this.jwtService.getRoleName(this.jwtService.getToken());
    switch (this.role) {
      case RoleEnum.ROLE_LOCATION:
        if (this.statics) {
          this.welcomeCardData = WelcomeCardLocationStatisticsData;
        } else {
          this.welcomeCardData = WelcomeCardLocationMainData;
        }
        break;
      case RoleEnum.ROLE_ADVERTISER:
        this.welcomeCardData = WelcomeCardAdvertiserData;
        break;
      case RoleEnum.ROLE_ADMIN:
        this.welcomeCardData = WelcomeCardAdminData;
    }
  }
}
