export enum InternetProvider {
  BEE_TUNISIE = "BEE_TUNISIE",
  OOREDOO = "OOREDOO",
  TELECOM = "TELECOM",
  ORANGE = "ORANGE",
  NONE = "NONE",
}

export enum InternetProvidersToShow {
  BEE_TUNISIE = "BEE Tunisie",
  OOREDOO = "Ooredoo",
  TELECOM = "Telecom",
  ORANGE = "Orange",
  NONE = "None",
}
export interface InternetProviderInterface {
  id: InternetProvider;
  value: string;
}
