<div class="modal-header">
  <h4 class="modal-title" id="exampleModalLabel">
    {{ "actions.advertiser.deleteAdvertiser" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal()"
  ></button>
</div>
<div class="modal-body">
  <h5 *ngIf="!havingAds">
    <p style="font-size: 14px">
      {{ "pop-ups_Message.delete.part1" | translate }}
      {{ "pop-up.advertiser.title" | translate }} :
      <b>{{ email }}</b>
    </p>
    <span class="text-danger">
      <b>{{ "pop-ups_Message.delete.part2" | translate }}</b>
    </span>
  </h5>
  <h5 *ngIf="havingAds">
    <p style="font-size: 14px">
      {{ "pop-ups_Message.cantDeleteAdvertiser" | translate }}
    </p>
  </h5>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-primary" (click)="closeModal()">
    {{ "buttons.close" | translate }}
  </button>
  <button *ngIf="!havingAds" class="btn btn-primary" (click)="onClickDelete()">
    <loading-button
      [loading]="loading"
      [value]="'buttons.confirm'"
      [icon]="null"
    ></loading-button>
  </button>
</div>
