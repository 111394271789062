export enum FSIRouterStatus {
    IN_STOCK = "IN_STOCK",
    READY_TO_GO = "READY_TO_GO",
    ON_SITE = "ON_SITE"
  }
  export enum FSIRouterStatusToShow {
    IN_STOCK = "In stock",
    READY_TO_GO="Ready to go",
    ON_SITE ="On site"
  }
  export interface FSIRouterStatusInterface {
    id: FSIRouterStatus;
    value: string;
  }