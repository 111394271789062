import { createAction, props } from "@ngrx/store";
import { AllRegionInterface, RegionInterface } from "src/app/shared/models/region/region";

//All regions details
export const invokeAllRegions = createAction(
    "[ Invoke Get All Regions ] invoke get all Regions"
);
export const setAllRegions = createAction(
    "[ Get All Regions ] get all Regions",
    props<{ region: AllRegionInterface[] | null}>()
)

//Create
export const invokeCreateRegion = createAction(
    "[ Invoke Create Region ] invoke create region",
    props<{ region: RegionInterface}>()
);
export const createUpdateRegionSuccess = createAction(
    "[ Create Update Region Success] create update region success",
    props<{ message: string | null}>()
);

//Update
export const invokeUpdateRegion = createAction(
    "[ Invoke Update Region ] invoke update region",
    props<{ region: RegionInterface, oldName: string}>()
);

//Delete region
export const invokeDeleteRegion = createAction(
    "[ Invoke Delete Regions ] invoke delete Region",
    props<{ regionName: string}>()
);
export const deleteRegionSuccess = createAction(
    "[ Delete Region Success ] delete region success",
    props<{ message: string | null}>()
)

//All names
export const invokeAllRegionNames = createAction(
    "[ Invoke Get All Regions Names ] invoke get all Region names"
);
export const setAllRegionNames = createAction(
    "[ Get All Regions Names ] get all Regions names",
    props<{ regionNames: RegionInterface[] | null}>()
)

//allRegionByListZones
export const invokeAllRegionByZones = createAction(
    "[ Invoke All Regions By zones ] Invoke all regions by zones",
    props<{zones : string []}>()
)
export const resultListRegionByZones = createAction(
    "[ Result All Regions By zones ] Result all regions by zones",
    props<{regionsByZones : string[]}>()
)

export const saveSelectedRegion = createAction(
    "[ Save selected region ] Save selected region ",
    props<{selectedRegion : string[] | null}>()
)