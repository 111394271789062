export enum Display {
    true = "true",
    false = "false",
  }
  
  export enum DisplayToShow {
    true = "enumMapping.display.true",
    false = "enumMapping.display.false",
  }
  export interface DisplayInterface {
    id: Display;
    value: string;
  }
  