import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-personal-information",
  templateUrl: "./personal-information.component.html",
})
export class PersonalInformationComponent implements OnInit {
  showPassword1: boolean = false;
  showConfirmPassword1: boolean = false;
  @Input() isEmailCheck: boolean = false;
  showPassword() {
    this.showPassword1 = !this.showPassword1;
  }
  showConfirmPassword() {
    this.showConfirmPassword1 = !this.showConfirmPassword1;
  }
  @Input("form") form: FormGroup;

  constructor() {}
  ngOnInit(): void {}
}
