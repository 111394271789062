export enum ChartStatus{
    VIEWS = "VIEWS",
    UNIQUEVIEWS = "UNIQUEVIEWS",
    ENGAGEMENT = "ENGAGEMENT"
}

export const ChartStatusToShow: Record<ChartStatus, string> = {
  [ChartStatus.VIEWS]: "filter.splineFilter.impressions",
  [ChartStatus.UNIQUEVIEWS]: "Charts.uniqueViews",
  [ChartStatus.ENGAGEMENT]: "Charts.completed",
};

export interface ChartStatusInterface {
  id: ChartStatus;
  value: string;
}