<div class="modal-header">
  <div>
    <h4 class="modal-title f-w-600" id="exampleModalLabel">
      {{ "actions.updateAdvertiser" | translate }} <b>{{ advertiser.email }}</b>
    </h4>
    <h5 class="m-b-0 m-t-10">
      {{
        "enumMapping.updateAdvertiserCards." + selectedOption.toString() | translate
      }}
    </h5>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="onClickCloseModal()"
  ></button>
</div>
<div class="modal-body">
  <form class="form theme-form" [formGroup]="personalInfoForm">
    <div
      *ngIf="
        selectedOption == section.ADMIN_ADVERTISER_UPDATE_PERSONAL_INFORMATION
      "
    >
      <app-advertiser-perso-info
        [form]="personalInfoForm! | asFormGroup"
      ></app-advertiser-perso-info>
    </div>
  </form>
  <form class="form theme-form" [formGroup]="companyInfoForm">
    <div
      *ngIf="
        selectedOption == section.ADMIN_ADVERTISER_UPDATE_COMPANY_INFORMATION
      "
    >
      <app-advertiser-company-info
        [form]="companyInfoForm! | asFormGroup"
      ></app-advertiser-company-info>
    </div>
  </form>
  <div *ngIf="selectedOption == section.ADMIN_ADVERTISER_UPDATE_BILLING">
    <form class="form theme-form" [formGroup]="billingInfoForm">
      <app-advertiser-billing-info
        [form]="billingInfoForm! | asFormGroup"
      ></app-advertiser-billing-info>
    </form>
  </div>
  <div class="media">
    <button class="btn btn-outline-primary m-t-20" (click)="onClickRetour()">
      {{ "buttons.return" | translate }}
    </button>
    <button
      class="btn btn-primary button-right m-t-20"
      type="submit"
      (click)="checkAndsaveChanges()"
    >
      {{ "buttons.confirm" | translate }}
    </button>
  </div>
</div>
