import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, withLatestFrom } from "rxjs";
import { setAPIStatus } from "../apiState.interface";
import { select, Store } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";
import { ObjectiveService } from "src/app/shared/services/objective.service";
import {
  getAllObjectives,
  invokeAllObjectives,
  invokeDeleteObjective,
  invokeUpdateObjective,
  resultDeleteObjective,
  updateObjectiveSuccess,
} from "./objective.actions";
import { ObjectiveInterface } from "src/app/shared/models/objectives/objectives";
import { SuccessDto } from "src/app/shared/models/success-dto";

@Injectable()
export class ObjectiveEffect {
  constructor(
    private actions$: Actions,
    private store: Store<AppStateInterface>,
    private objectiveService: ObjectiveService
  ) {}

  invokeGetAllObjectives$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAllObjectives),
      mergeMap((state) => {
        return this.objectiveService.getAllObjectives().pipe(
          map((data: ObjectiveInterface[] | null) =>
            getAllObjectives({ objective: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
  invokeUpdateObjective$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateObjective),
      mergeMap((state) => {
        return this.objectiveService.updateObjective(state.objective).pipe(
          map((data: SuccessDto) =>
            updateObjectiveSuccess({ message: data.message })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeDeleteObjective$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeDeleteObjective),
      mergeMap((state) => {
        return this.objectiveService.deleteObjective(state.objectiveName).pipe(
          map((data: SuccessDto) =>
          resultDeleteObjective({ message: data.message })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
}
