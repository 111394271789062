<div class="modal-header">
  <div>
    <h4 class="modal-title" id="exampleModalLabel">
      {{ "ads-locations.title" | translate }}
    </h4>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="onClickDismissModal()"
  ></button>
</div>
<div class="modal-body">
  <div *ngFor="let location of locations; let i = index">
    <div class="form-check checkbox checkbox-primary mb-0">
      <input
        [id]="location"
        type="checkbox"
        name="rdo-ani"
        class="form-check-input"
        [checked]="location==selectedLp"
        (click)="saveLocation($event, location)"
      />
      <label class="form-check-label label" [for]="location">{{
        location
      }}</label>
    </div>
  </div>
  <div class="form-check checkbox checkbox-primary mb-10">
    <input
      id="all"
      type="checkbox"
      name="rdo-ani"
      class="form-check-input m-b-10"
      [checked]="selectedLp == null"
      (click)="saveLocation($event, null)"
    />
    <label class="form-check-label label" for="all">{{
      "ads-locations.allLocations" | translate
    }}</label>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="submitLocation()">
    {{ "buttons.confirm" | translate }}
  </button>
</div>
