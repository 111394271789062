export interface ExportData {
  name: string;
  id: string;
  data : string,
  type : exportDataType | null,
  dates : Date[] | null
}
export enum exportDataType {
  INVOICES_SUMMARY,
  INVOICES_DISTRIBUTION_AGE_GENDER,
  SUMMARY_CHART_ADMIN,
  LP_REVIEW_ADMIN,
  TECHNICAL_DATA_NAVIGATOR,
  TECHNICAL_DATA_DEVICE_CATEGORY,
  TECHNICAL_DATA_OPERATING_SYSTEM,
  TECHNICAL_DATA_TOTAL_DISTRIBUTION,
  RETENTION_RATE
}
export const technicalData: ExportData[] = [
  {
    name: "inseights.details.technicalData.navigator",
    id: "inseights.details.technicalData.navigator",
    data : "",
    type: exportDataType.TECHNICAL_DATA_NAVIGATOR,
    dates : null
  },
  {
    name: "inseights.details.technicalData.deviceCategory",
    id: "inseights.details.technicalData.deviceCategory",
    data : "",
    type: exportDataType.TECHNICAL_DATA_DEVICE_CATEGORY,
    dates : null
  },
  {
    name: "inseights.details.technicalData.operatingSystem",
    id: "inseights.details.technicalData.operatingSystem",
    data : "",
    type: exportDataType.TECHNICAL_DATA_OPERATING_SYSTEM,
    dates : null
  },
  {
    name: "inseights.details.technicalData.totalContribution",
    id: "inseights.details.technicalData.totalContribution",
    data : "",
    type: exportDataType.TECHNICAL_DATA_TOTAL_DISTRIBUTION,
    dates : null
  }
];
export const audienceData : ExportData[] = [
  {
    name: "invoices.summary",
    id: "invoices.summary",
    data : "",
    type: exportDataType.INVOICES_SUMMARY,
    dates : null
  },
  {
    name: "invoices.distributionAgeGender",
    id: "invoices.distributionAgeGender",
    data : "",
    type: exportDataType.INVOICES_DISTRIBUTION_AGE_GENDER,
    dates : null
  },
]
export const SummaryChartAdmin : ExportData[] =[
  {
    name : "dashboard.admin.summmaryChart",
    id: "spline",
    data : "",
    type: exportDataType.SUMMARY_CHART_ADMIN,
    dates : null
  }
]
export const LocationOverviewData : ExportData[] =[
  {
    name : "dashboard.admin.lpReview.title",
    id: "dashboard.admin.lpReview.title",
    data : "",
    type: exportDataType.LP_REVIEW_ADMIN,
    dates : null
  }
]
export const RetentionRateData : ExportData[] =[
  {
    name : "location.statistics.retentionRate",
    id: "location.statistics.retentionRate",
    data : "",
    type: exportDataType.RETENTION_RATE,
    dates : null
  }
]