import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Constants } from '../data/constants';
import { Advertiser } from '../models/advertiser/advertiser.interface';
import { AdvertiserDetailsInterface } from '../models/advertiser/profile.interface';
import { LeadGeneration } from '../models/leadGeneration/leadGeneration';
import { SendFiles } from '../models/send-files.interface';
import { SuccessDto } from '../models/success-dto';

@Injectable({
  providedIn: 'root'
})
export class MailService {
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };
  constructor(private http: HttpClient) { }

  sendLeadGenerationByEmail
  (
    leadGeneration: LeadGeneration | null
  ): Observable<SuccessDto | null> {
    return this.http.post<any | null>(
      `${environment.APP_PORT + Constants.MAILS_END_POINT}/leadGenerationForm` ,
        leadGeneration
    );
  }
  sendRequestDownloadLead(advertiser: AdvertiserDetailsInterface): Observable<SuccessDto | null> {
    return this.http.post<SuccessDto | null>(
      `${environment.APP_PORT + Constants.MAILS_END_POINT}/request/downloadFormLead` ,
        advertiser
    );
  }
  sendFilesToUser(sendFiles : SendFiles[] | null , title : string | null): Observable<SuccessDto | null> {
    return this.http.post<SuccessDto | null>(
      `${environment.APP_PORT + Constants.MAILS_END_POINT}/sendDownloadedAttachment/${title}` ,
        sendFiles
    );
  }
  notifyAdminWhenAdvertisingCampaignCreated(title: string , email : string) : Observable<SuccessDto | null>{
    return this.http.post<SuccessDto | null>(
      `${environment.APP_PORT + Constants.MAILS_END_POINT}/adCreated/${title}/${email}`,null
    )
  }
  notifyAdminWhenAdvertiserCreated(email : string, firstName : string, lastName : string) : Observable<SuccessDto | null>{
    return this.http.post<SuccessDto | null>(
      `${environment.APP_PORT + Constants.MAILS_END_POINT}/newAdvertiser/${email}/${firstName}/${lastName}`,null
    )
  }
  notifyAdvertiserWhenHisCampaignStarts(email : string) : Observable<SuccessDto | null>{
    return this.http.post<SuccessDto | null>(
      `${environment.APP_PORT + Constants.MAILS_END_POINT}/campaignStarts/${email}`,null
    )
  }
  markAdAsFinished(email : string) : Observable<SuccessDto | null>{
    return this.http.post<SuccessDto | null>(
      `${environment.APP_PORT + Constants.MAILS_END_POINT}/markAdAsFinished/${email}`,null
    )
  }

  activateAdvertiserAccount(email : string){
    return this.http.post<SuccessDto | null>(
      `${environment.APP_PORT + Constants.MAILS_END_POINT}/approveAdvertiserAccount/${email}`,null
    )
  }
}
