import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AdDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-details.interface";
import { adDetailsSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
import { AppStateInterface } from "src/app/store/appState.interface";

@Component({
  selector: "app-ad-video-preview-statistics",
  templateUrl: "./ad-video-preview-statistics.component.html",
  styleUrls: ["./ad-video-preview-statistics.component.scss"],
})
export class AdVideoPreviewStatisticsComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Input() height: number;
  muting() {
    this.mute = !this.mute;
    if (this.mute) this.myVideo.nativeElement.volume = 0;
    else this.myVideo.nativeElement.volume = 0.7;
  }
  mute: boolean = true;
  //@Input("videoSrc") videoSrc: Observable<File>;
  @ViewChild("myVideo") myVideo: ElementRef;
  @ViewChild("phone") phone: ElementRef;
  @ViewChild("muteIcon") muteIcon: ElementRef<HTMLImageElement>;
  @Input("isUrl") isUrl: boolean = false;
  @Input("url") url: Observable<string>;
  @Input("muteExist") muteExist: boolean = true;

  @Input("changeMuting") changeMuting: Observable<any>;

  imgWidth: number;
  imgHeight: number;
  phoneHeight: number = 500;
  phoneWidth: number = 250;

  loadingVideo: boolean = true;
  constructor(
    private renderer: Renderer2,
    private store: Store<AppStateInterface>
  ) {
    super(store);
    this.adDetails$ = this.store
      .pipe(select(adDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngAfterViewInit() {
    const imgElement = this.phone.nativeElement;
    const videoElement = this.myVideo.nativeElement;
    this.renderer.listen(videoElement, "loadedmetadata", () => {
      const videoAspectRatio =
        videoElement.videoWidth / videoElement.videoHeight;
      const newImgHeight = imgElement.offsetWidth / videoAspectRatio;
      //imgElement.style.height = newImgHeight + "px";
    });

    videoElement.width = imgElement.offsetWidth;
  }
  adDetails$: Observable<AdDetailsInterface>;

  getAdDetails: AdDetailsInterface = initialState.adDetails;

  ngOnInit(): void {
    if (!this.isUrl)
      this.adDetails$.subscribe((result) => {
        if (result.videoFile) {
          this.getAdDetails = result;
          const url = URL.createObjectURL(result.videoFile);
          const video = this.myVideo.nativeElement;
          video.src = url;
          video.muted = true
          video.load();
        } else {
          const url = URL.createObjectURL(new Blob());
          const video = this.myVideo.nativeElement;
          video.src = url;
          video.muted = true
          video.load();
        }
      });
    this.url.subscribe((url) => {
      if (url) {
        this.loadingVideo = false;
        const video = this.myVideo.nativeElement;
        video.src = url;
        video.muted = true
        video.load();
      }
    });
    this.height = 350
    /*this.videoSrc.subscribe((src) => {
    if (src) {
      const url = URL.createObjectURL(src);
      const video = this.myVideo.nativeElement;
      video.src = url;
      video.load();
    }
  });*/
  }

  loadVideo(file: File | undefined) {
    if (file) {
      const url = URL.createObjectURL(file);
      const video = this.myVideo.nativeElement;
      video.src = url;
      video.load();
    }
  }
  toggleMute() {
    const video = this.myVideo.nativeElement;
    const muteIcon = this.muteIcon.nativeElement;
    muteIcon.src = video.muted
      ? "assets/identity/unmuted-button.svg"
      : "assets/identity/muted-button.svg";
    video.muted = !video.muted;
  }
}
