<div class="container-fluid">
  <div class="card">
    <div class="card-header">
      <div class="media-space-between">
        <h4 *ngIf="!role.ROLE_ADMIN; else other">
          {{ "sideBar.admin.support.title" | translate }}
        </h4>
        <ng-template #other>
          <h4 *ngIf="isAdvertiser && role.ROLE_ADMIN" class="col-2">
            {{ "sideBar.admin.support.advertisers" | translate }}
          </h4>
          <h4 *ngIf="!isAdvertiser && role.ROLE_ADMIN" class="col-3">
            {{ "sideBar.admin.support.lp" | translate }}
          </h4>
        </ng-template>
        <div class="media-body text-end">
          <form class="d-inline-flex" [formGroup]="formFilter">
            <div
              class="faq-form mr-3"
              style="color: darkgrey; display: flex; margin-left: 10px"
            >
              <i class="search-icon" data-feather="search"></i>
              <div>
                <div class="input-group">
                  <input
                    id="table-complete-search"
                    type="text"
                    class="form-control"
                    [placeholder]="'placeholder.searchSubject' | translate"
                    formControlName="title"
                    (ngModelChange)="searchTerm()"
                  />
                  <span
                    class="input-group-text p-0"
                    style="background-color: transparent"
                  >
                    <app-general-filter
                      [filter]="filterSupport"
                      (optionSelected)="saveOption($event)"
                    ></app-general-filter>
                  </span>
                </div>
              </div>
            </div>
            <a
              *ngIf="roleName != role.ROLE_ADMIN"
              class="btn btn-primary mx-2"
              target="_blank"
              [href]="requestFeat"
            >
              {{ "support.functionRequest" | translate }}
            </a>
            <button
              *ngIf="roleName != role.ROLE_ADMIN"
              class="btn btn-primary"
              [routerLink]="['createBugReport']"
            >
              {{ "support.newTicket.title" | translate }}
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive data-table">
        <table class="table table-striped table-border-vertical">
          <thead>
            <tr>
              <ng-container *ngFor="let column of columns">
                <th
                  sortable
                  class="sortable"
                  sortable="{{ column.key }}"
                  (sort)="onSort($event)"
                >
                  {{ column.label | translate }}
                </th>
              </ng-container>
              <th *ngIf="isAdvertiser && roleName == role.ROLE_ADMIN">
                {{ "support.advertiser" | translate }}
              </th>
              <th *ngIf="!isAdvertiser && roleName == role.ROLE_ADMIN">
                {{ "support.lp" | translate }}
              </th>
              <th>{{ "dashboard.status" | translate }}</th>
              <th *ngIf="roleName == role.ROLE_ADMIN">Actions</th>
            </tr>
          </thead>
          <tbody *ngIf="!(loading$ | async)">
            <ng-container>
              <tr *ngFor="let bugReport of resultFilterListBugReport">
                <td>
                  <div *ngIf="roleName == role.ROLE_ADMIN; else lpad">
                  <a
                  
                    type="button"
                    style="color: black"
                    [routerLink]="['../../support-details']"
                    [queryParams]="{
                      ref: bugReport.ref
                    }"
                    >{{ bugReport.ref }}</a
                  ></div>
                  <ng-template #lpad>
                    <a
                    type="button"
                    style="color: black"
                    [routerLink]="['details/']"
                    [queryParams]="{
                      ref: bugReport.ref
                    }"
                    >{{ bugReport.ref }}</a
                  >
                  </ng-template>
                </td>
                <td>
                  {{ bugReport.title }}
                </td>
                <td>
                  {{ bugReport.creationDate | localizedDate : "short" }}
                </td>
                <td>
                  <a
                    *ngIf="bugReport.fileName; else noFile"
                    class="color-primary underline-link"
                    target="_blank"
                    [href]="cdnURL + bugReport.fileName"
                    >{{ "dashboard.showDetails" | translate }}</a
                  >
                  <ng-template #noFile>{{
                    "support.noFile" | translate
                  }}</ng-template>
                </td>
                <td *ngIf="roleName == role.ROLE_ADMIN">
                  {{ bugReport.sender.email }}
                </td>
                <td>
                  <span
                    style="margin-bottom: 6px"
                    [ngClass]="
                      'badge ' +
                      badgeService.getBadgeByBugReportStatus(bugReport.status)
                    "
                  >
                    {{
                      "enumMapping.bugReport." + bugReport.status | translate
                    }}
                  </span>
                </td>
                <td *ngIf="roleName == role.ROLE_ADMIN">
                  <app-drob-zone-options
                    [options]="options"
                    (optionSelected)="saveAction($event, bugReport)"
                  ></app-drob-zone-options>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <div *ngIf="loading$ | async">
          <ng-container style="float: right" class="flex">
            <div class="loader-box">
              <div class="loader-34"></div>
            </div>
            <h6
              class="col align-self-center f-w-600"
              style="text-align: center"
            >
              {{ "fetching" | translate }}
            </h6>
          </ng-container>
        </div>
        <div
          *ngIf="resultFilterListBugReport?.length == 0 && !(loading$ | async)"
          class="d-flex justify-content-center m-t-20"
        >
          {{ "noData" | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
