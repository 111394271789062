<div class="container-fluid">
  <div class="row">
    <div class="col call-chat-body">
      <div class="row chat-box">
        <div class="col chat-right-aside">
          <div class="chat">
            <div class="chat-history chat-msg-box custom-scrollbar">
              <ul>
                <li>
                  <div class="card message my-message">
                    <div class="row">
                      <div class="col-1">
                        <img
                          class="rounded-circle float-start"
                          src="assets/identity/icone-Relead-final.png"
                          alt="relead"
                        />
                      </div>
                      <div class="col-9">
                        <h5 class="f-w-600">Relead</h5>
                        <p> {{"support.lastUpdate" | translate}} : {{bugReport.creationDate | localizedDate}}</p>
                      </div>
                      <div class="col-1">
                        <span
                        style="margin-bottom: 6px"
                        [ngClass]="
                          'badge ' +
                          badgeService.getBadgeByBugReportStatus(bugReport.status)
                        "
                      >
                        {{
                          "enumMapping.bugReport." + bugReport.status | translate
                        }}
                      </span>
                      </div>
                    </div>
                    <hr>
                    <div class="grid-3">
                        <p class="f-w-600" style="margin-bottom: 0.5rem;"> {{"pop-up.bugReport.category" | translate}} </p>
                        <p class="f-w-600" style="margin-bottom: 0.5rem;"> {{"pop-up.bugReport.priority" | translate}} </p>
                        <p class="f-w-600" style="margin-bottom: 0.5rem;"> {{"pop-up.bugReport.entreprise" | translate}} </p>
                        <p style="margin-bottom: 0.5rem;">Bugs</p>
                        <p style="margin-bottom: 0.5rem;"> {{"pop-up.bugReport.high" | translate}} </p>
                        <p style="margin-bottom: 0.5rem;"> {{bugReport.sender?.companyName}} </p>
                    </div>
                    <hr>
                    <div class="d-flex">
                      <p class="f-w-600 m-r-10 m-b-0">{{"pop-up.bugReport.subject" | translate}}</p>
                      <p style="margin-bottom: 0.5rem;">{{bugReport.title}}</p>
                    </div>
                    <hr>
                    <div class="d-flex">
                      <p class="f-w-600 m-r-10 m-b-0">{{"pop-up.bugReport.description" | translate}}</p>
                      <p style="margin-bottom: 0.5rem;">{{bugReport.description}}</p>
                    </div>
                    <hr>
                    <div class="d-flex align-tems-baseline">
                        <i class="icofont icofont-ui-file"></i>
                        <!--<p class="m-l-20"> {{bugReport.title}} </p>-->
                        <p class="m-l-20"> {{"pop-up.bugReport.attachmentNumber" | translate}} {{attachmentNumber}}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
