import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { Menu } from "../../models/side-bar-menu.interface";
import { RoleEnum } from "../../models/user/role.interface";
import { JwtService } from "../../services/jwt.service";
import { LocalStorageService } from "../../services/local-storage.service";
import { NavService } from "../../services/nav.service";
import { AppStateInterface } from "src/app/store/appState.interface";
import { Store, select } from "@ngrx/store";
import { invokeSideBarNews } from "src/app/store/admin/admin.actions";
import { Observable, Subject, takeUntil } from "rxjs";
import { SideBarNewsInterface } from "../../models/admin/side-bar-news.interface";
import { sideBarNewsSelector } from "src/app/store/admin/admin.selectors";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])

  dynamicHeightCondition(): string {
    if (this.role == "ROLE_ADMIN") {
      return `${window.innerHeight - 100}px`;
    } else {
      return ''; 
    }
  }

  public menuItems: Menu[] = [];
  public myBtnIdClicked: boolean = false;
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;
  role: string;
  redirection: string;
  contactRelead: string;
  sideBarNews$: Observable<SideBarNewsInterface>;
  ngDestroyed$: Subject<void> = new Subject<void>();
  constructor(
    private navService: NavService,
    private router: Router,
    private jwtService: JwtService,
    private localStorageService: LocalStorageService,
    private store: Store<AppStateInterface>
  ) {
    this.sideBarNews$ = this.store
      .pipe(select(sideBarNewsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }
  ngOnDestroy(): void {
    this.ngDestroyed$.next();
    this.ngDestroyed$.unsubscribe();
  }

  ngOnInit(): void {
    if (localStorage.getItem("token") != null) {
      const role = this.jwtService.getRoleName(localStorage.getItem("token"));

      switch (role) {
        case RoleEnum.ROLE_ADMIN: {
          this.store.dispatch(invokeSideBarNews());
          this.sideBarNews$.subscribe((value: SideBarNewsInterface) => {
            this.navService.adminItems.subscribe((menuItems) => {
              this.adminSettingUp(value, menuItems);
            });
          });

          this.redirection = "admin";
          break;
        }
        case RoleEnum.ROLE_ADVERTISER: {
          this.navService.advertiserItems.subscribe((menuItems) => {
            this.settingUp(menuItems);
          });
          this.redirection = "advertiser";
          break;
        }
        case RoleEnum.ROLE_LOCATION: {
          this.navService.locationPartnerItems.subscribe((menuItems) => {
            this.settingUp(menuItems);
          });
          this.redirection = "location";
          break;
        }
      }
    }
    const token = this.localStorageService.getItem("token");
    this.role = this.jwtService.getRoleName(token);
    if(this.role == RoleEnum.ROLE_ADVERTISER){
      this.contactRelead = environment.links.bookMeetingAdvertiser;
    }
    if(this.role == RoleEnum.ROLE_LOCATION){
      this.contactRelead = environment.links.bookMeetingLP;
    }
  }
  adminSettingUp(value: SideBarNewsInterface, menuItems: Menu[]) {
    var adminMenu: Menu[] = menuItems;
    if (value.ads != 0) {
      adminMenu.map((menu) => {
        if (menu.title == "sideBar.admin.campaigns.title") {
          menu.badgeType = "light-primary";
          menu.badgeValue = value.ads.toString();
        }
      });
    }
    if (value.support != 0) {
      adminMenu.map((menu) => {
        if (menu.title == "sideBar.admin.support.title") {
          menu.badgeType = "light-primary";
          menu.badgeValue = value.support.toString();
        }
      });
    }

    if (value.advertisers != 0) {
      adminMenu.map((menu) => {
        if (menu.title == "sideBar.admin.advertisers.title") {
          menu.badgeType = "light-primary";
          menu.badgeValue = value.advertisers.toString();
        }
      });
    }
    this.settingUp(adminMenu);
  }

  settingUp(menuItems: Menu[]) {
    this.menuItems = menuItems;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        menuItems.filter((items) => {
          if (items.path === event.url) {
            this.setNavActive(items);
          }
          if (!items.children) {
            return false;
          }

          items.children.filter((subItems: Menu) => {
            if (subItems.path === event.url) {
              this.setNavActive(subItems);
            }
            if (!subItems.children) {
              return false;
            }
            subItems.children.filter((subSubItems: Menu) => {
              if (subSubItems.path === event.url) {
                this.setNavActive(subSubItems);
              }
            });
            return;
          });
          return;
        });
      }
    });
  }
  close() {
    if (this.width < 992) {
      document.querySelector(".sidebar-wrapper")?.classList.add("close_icon");
    }
  }
  //  Active Nav
  setNavActive(item: Menu) {
    this.menuItems.filter((menuItem) => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter((submenuItems: Menu) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
          if (submenuItems.children)
            submenuItems.children.forEach((element: Menu) => {
              this.setNavActive(element);
            });
        });
      }
    });
  }

  toggletNavActive(item: Menu) {
    if (!item.active) {
      this.menuItems.forEach((a) => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) {
          return false;
        }
        a.children.forEach((b: any) => {
          if (a.children?.includes(item)) {
            b.active = false;
          }
        });
        return;
      });
    }
    item.active = !item.active;
  }

  sidebarToggle() {
    this.navService.collapseSidebar = !this.navService.collapseSidebar;
  }

  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -2598 || this.margin <= -2034) {
      if (this.width === 492) {
        this.margin = -3000;
      } else {
        this.margin = -3250;
      }
      this.leftArrowNone = false;
      this.leftArrowNone = false;
    } else {
      this.leftArrowNone = false;
      this.margin += -this.width;
    }
  }
}
