import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { TypeFieldsLeadGeneration } from "src/app/shared/enum/typeFieldsLeadGeneration";

@Component({
  selector: "app-add-filds",
  templateUrl: "./add-filds.component.html",
})
export class AddFildsComponent implements OnInit , OnChanges {
  @Output("title") title = new EventEmitter<string>();
  @Output("dataCheckbox") dataCheckbox = new EventEmitter<any>();
  @Output("dataRadio") dataRadio = new EventEmitter<any>();
  @Input("type") type: TypeFieldsLeadGeneration | null;
  typeInputField = TypeFieldsLeadGeneration;
  options: number[] = [1];
  multichoiseOptions: number[] = [1];
  addForm: FormGroup;
  isEditableRadio: { [key: number]: boolean } = { 1: false };
  isEditableCheckbox: { [key: number]: boolean } = { 1: false };
  formRadio: { [key: string]: string } = {};
  formDataCheckbox: { [key: string]: string } = {};
  finalValues: any[] = [];
  values: any = {};
  titleField: string;
  typeFildSelected = TypeFieldsLeadGeneration;
  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private elementRef: ElementRef
  ) {
    this.addForm = this.fb.group({
      title: [""],
      option: [null],
    });
  }
  initialState(){
    this.titleField = ""
    this.title.emit("")
    this.dataRadio.emit({})
    this.dataCheckbox.emit({})
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.type != null){
      if(changes){
        this.initialState()
      }
    }
  }
  ngOnInit(): void {
    this.formRadio[0] = "leadGeneration.customOptions";
    this.formDataCheckbox[0] = "leadGeneration.customOptions";
    if(this.type == null){
      this.titleField = ""
      this.title.emit("")
    }
  }
  onChangeSaveOption() {
    this.titleField = this.addForm.get("title")?.value;
    this.title.emit(this.titleField);
  }
  addOption(i : number) {
    this.options.push(this.options.length + 1);
    this.formRadio[(i+1).toString()] = this.translate.instant("leadGeneration.customOptions");
  }
  addcheck(i: number) {
    this.multichoiseOptions.push(this.multichoiseOptions.length + 1);
    this.formDataCheckbox[(i+1).toString()] = this.translate.instant("leadGeneration.customOptions");
  }
  toggleEditModeRadio(i: number) {
    if (this.formRadio[i] == undefined) {
      this.formRadio[i] = "";
    }
    this.isEditableRadio[i] = !this.isEditableRadio[i];
  }
  toggleEditModeCheckbox(i: number) {
    if (this.formDataCheckbox[i] == undefined) {
      this.formDataCheckbox[i] = "";
    }
    this.isEditableCheckbox[i] = !this.isEditableCheckbox[i];
  }
  onInputChange(event: Event, field: string) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.formRadio[field] = inputValue;
    this.dataRadio.emit(this.formRadio);
  }
  onInputChangeChekbox(event: Event, field: string) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.formDataCheckbox[field] = inputValue;
    this.dataCheckbox.emit(this.formDataCheckbox);
  }
  removeCheck(i: string) {
    if (this.formDataCheckbox.hasOwnProperty(i)) {
      delete this.formDataCheckbox[i];
      this.multichoiseOptions.splice(Number(i) - 1, 1);
      if (this.multichoiseOptions.length == 0) {
        this.multichoiseOptions = [1];
      }
      let newValue: any[] = [];
      Object.values(this.formDataCheckbox).filter((value, index) => {
        newValue = [...new Set([...newValue, value])];
      });
      this.values[this.titleField] = newValue;
      const entrees = Object.entries(this.values);
      const derniereEntree = entrees[entrees.length - 1];
      this.dataCheckbox.emit({ [derniereEntree[0]]: derniereEntree[1] });
      this.cdr.detectChanges();
    }
  }
  removeOption(i: string) {
    if (this.formRadio.hasOwnProperty(i)) {
      delete this.formRadio[i];
      this.options.splice(Number(i) - 1, 1);
      if (this.options.length == 0) {
        this.options = [1];
      }
      
      let newValue: any[] = [];
      Object.values(this.formRadio).filter((value, index) => {
        newValue = [...new Set([...newValue, value])];
      });
      this.values[this.titleField] = newValue;
      const entrees = Object.entries(this.values);
      const derniereEntree = entrees[entrees.length - 1];
      this.dataRadio.emit({ [derniereEntree[0]]: derniereEntree[1] });
      this.cdr.detectChanges();
    }
  }
  adjustTextareaHeight(): void {
    const textarea: HTMLTextAreaElement =
      this.elementRef.nativeElement.querySelector(".custom-textarea-input-case");
    textarea.style.height = "25px";
    textarea.style.height = `${textarea.scrollHeight}px`;
  }
  selectTextareaText(event: any) {
    event.target.select();
  }
}
