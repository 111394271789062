import { createAction, props } from "@ngrx/store";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { ObjectiveInterface } from "src/app/shared/models/objectives/objectives";

export const invokeAllObjectives = createAction(
    "[ Invoke Get All Objectives ] Invoke get all objectives"
);
export const getAllObjectives = createAction(
    "[ Get All Objectives ] Get all objectives ",
    props<{objective: ObjectiveInterface[] | null}>()
)

export const invokeUpdateObjective = createAction(
    "[ Invoke Update Objective By name ] Invoke Update objective ",
    props<{objective: ObjectiveInterface}>()
);
export const updateObjectiveSuccess = createAction(
    "[ Update Objective ] Invoke Objective ",
    props<{message: string | null}>()
)
export const invokeDeleteObjective = createAction(
    "[ Invoke delete Objective ] Invoke delete objective ",
    props<{objectiveName : string}>()
)
export const resultDeleteObjective = createAction(
    "[ Result delete objective ] Result delete objective ",
    props<{message: string | null}>()
)