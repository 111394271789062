export enum CommandeStatusEnum{
    DRAFT = "DRAFT",
    WAITING = "PAYÉ",
    AWAITING_PAYMENT = "AWAITING_PAYMENT",
    AWAITING_APPROVAL = "AWAITING_APPROVAL"
}

  
  export const CommandeStatusEnumMapping: Record<CommandeStatusEnum, string> = {
    [CommandeStatusEnum.DRAFT]: "enumMapping.Commande.filter.order",
    [CommandeStatusEnum.WAITING] : "enumMapping.Commande.filter.PAID",
    [CommandeStatusEnum.AWAITING_PAYMENT] : "enumMapping.Commande.filter.AWAITING_PAYMENT",
    [CommandeStatusEnum.AWAITING_APPROVAL] : "enumMapping.Commande.filter.AWAITING_APPROVAL",
  };

  export const CommandeStatusEnumMappingFilter: Record<CommandeStatusEnum, string> = {
    [CommandeStatusEnum.DRAFT]: "enumMapping.Commande.filter.order",
    [CommandeStatusEnum.WAITING] : "enumMapping.Commande.filter.PAID",
    [CommandeStatusEnum.AWAITING_PAYMENT] : "enumMapping.Commande.filter.AWAITING_PAYMENT",
    [CommandeStatusEnum.AWAITING_APPROVAL] : "enumMapping.Commande.filter.AWAITING_APPROVAL",
  };
  
  export interface CommandeStatusInterface {
    id: CommandeStatusEnum;
    value: string;
  }