import { createAction, props } from "@ngrx/store";
import { FixedChargeInterface } from "../../shared/models/fixed-charges/fixed-charges.interface";

export const invokeAllFixedCharges = createAction(
  "[ Invoke Get All FixedCharges ] Invoke get all fixedCharges"
);

export const getAllFixedCharges = createAction(
  "[ Get All FixedCharges ] Get all fixedCharges ",
  props<{ fixedCharges: FixedChargeInterface[] | null }>()
);

export const invokeCreateFixedCharge = createAction(
  "[ Invoke Create FixedCharge By name ] Invoke Create fixedCharge",
  props<{ fixedCharge: FixedChargeInterface }>()
);

export const invokeUpdateFixedCharge = createAction(
  "[ Invoke Update FixedCharge By name ] Invoke Update fixedCharge",
  props<{ name: string; fixedCharge: FixedChargeInterface }>()
);

export const crudFixedChargeSuccess = createAction(
  "[ Crud FixedCharge success ] Crud FixedCharge success",
  props<{ message: string | null }>()
);

export const invokeDeleteFixedCharge = createAction(
  "[ Invoke delete FixedCharge ] Invoke delete fixedCharge",
  props<{ fixedChargeName: string }>()
);
