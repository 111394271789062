import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RouterReference, RouterReferenceInterface } from 'src/app/shared/enum/router-reference';
import { SessionTimeOut, SessionTimeOutInterface, SessionTimeOutToShow } from 'src/app/shared/enum/sessionTimeOut';

@Component({
  selector: 'app-relead-router',
  templateUrl: './relead-router.component.html'
})
export class ReleadRouterComponent implements OnInit {

  @Input() "form" : FormGroup
  routerReferenceStatus: RouterReferenceInterface[];
  sessionTimeOutStatus : SessionTimeOutInterface[];
  constructor() { }

  ngOnInit(): void {
    this.routerReferenceStatus = Object.keys(RouterReference)
    .filter((value) => isNaN(Number(value)) === true)
    .map((key: string | any) => ({
      id: RouterReference[key as keyof typeof RouterReference],
      value: RouterReference[key as keyof typeof RouterReference],
    }));
    this.sessionTimeOutStatus = Object.keys(SessionTimeOut)
    .filter((value) => isNaN(Number(value)) === true)
    .map((key: string | any) => ({
      id: SessionTimeOut[key as keyof typeof SessionTimeOut],
      value: SessionTimeOutToShow[key as keyof typeof SessionTimeOut],
    }));
  }

}
