<div class="container-fluid">
  <div class="user-profile">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <ul
              ngbNav
              #nav="ngbNav"
              [(activeId)]="active"
              [destroyOnHide]="false"
              class="justify-content-end"
            >
              <li [ngbNavItem]="1">
                <a ngbNavLink class="btn btn-outline-primary m-r-10">
                  {{ "profile.overview" | translate }}
                </a>
                <ng-template ngbNavContent>
                  <app-overview
                    [companyActivities]="companyActivities"
                  ></app-overview>
                </ng-template>
              </li>
              <li [ngbNavItem]="2" [destroyOnHide]="true">
                <a ngbNavLink class="btn btn-outline-primary m-r-10">{{
                  "profile.updatePassword.title" | translate
                }}</a>
                <ng-template ngbNavContent>
                  <app-change-password></app-change-password>
                </ng-template>
              </li>
              <li [ngbNavItem]="3" [destroyOnHide]="true">
                <a ngbNavLink class="btn btn-outline-primary">{{
                  "buttons.updateProfile" | translate
                }}</a>
                <ng-template ngbNavContent>
                  <app-update-profile
                    [companyActivities]="companyActivities"
                  ></app-update-profile>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
