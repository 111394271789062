import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LayoutService } from "src/app/shared/services/layout/layout.service";

@Component({
  selector: "app-mode",
  templateUrl: "./mode.component.html",
})
export class ModeComponent implements OnInit {
  public dark: boolean =
    this.layout.config.settings.layout_version == "dark-only" ? true : false;

  constructor(public layout: LayoutService) {}

  ngOnInit(): void {}
  diplayMode(): string {
    return this.layout.config.settings.layout_version == "dark-only"
      ? "settings.lightMode.title"
      : "settings.darkMode.title";
  }
}
