import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeAllZoneNames } from "src/app/store/zone/zone.actions";
import { allZoneNamesSelector } from "src/app/store/zone/zone.selectors";

@Component({
  selector: "app-location-information",
  templateUrl: "./location-information.component.html",
  styleUrls: ["./location-information.component.scss"],
})
export class LocationInformationComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild(NgbPopover, { static: true }) popover: NgbPopover;
  @ViewChild("address") address: ElementRef;
  @Input("isUpdate") isUpdate : boolean 
  mapOptions: google.maps.MapOptions = {
    mapTypeId: "roadmap",
    streetViewControl: false,
    center: { lat: 33.886917, lng: 9.537499 },
    zoom: 6,
  };
  markerOptions: google.maps.MarkerOptions = {
    draggable: true,
  };
  markerPosition: google.maps.LatLngLiteral = { lat: 33.886917, lng: 9.537499 };

  @Input("form") form: FormGroup;
  zoneNames$: Observable<string[] | null>;

  constructor(private store: Store<AppStateInterface>) {
    super(store);

    this.zoneNames$ = this.store
      .pipe(select(allZoneNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    if(this.isUpdate){
      this.markerPosition.lat = this.form.get("latitude")?.value
      this.markerPosition.lng = this.form.get("longitude")?.value
    }
    this.store.dispatch(invokeAllZoneNames());
  }

  changeMarkerPosition(marker: { latitude: number; longitude: number }) {
    this.form.get("latitude")?.patchValue(marker.latitude);
    this.form.get("longitude")?.patchValue(marker.longitude);
  }
  formatPositionNumber(number: number | undefined): number {
    return Number(Number(number).toFixed(4));
  }
}
