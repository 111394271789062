import { createReducer, on } from "@ngrx/store";
import {
  applyPromotionCodeSuccess,
  checkPromotionCodeError,
  checkPromotionCodeSuccess,
  getAdHavePromotionCode,
  getPromotionCodesSuccess,
  invokePromotionCodeFilter,
  invokePromotionCodeSuccess,
} from "./promotion-code.actions";
import { PromotionCodeState } from "./promotion-codeState.interface";

export const initialState: Readonly<PromotionCodeState> = {
  loading: true,
  promotionCodeFilter: {
    startDate: null,
    endDate: null,
    sortName: null,
    isAscending: true,
    pager: {
      pageNumber: 1,
      pageSize: 10,
    },
  },
  promotionCodesResult: {
    count: 0,
    promotionCodes: [],
  },
  successMessage: null,
  checkPromotionCodeSuccess : null,
  checkPromotionCodeError : null,

  applyPromotionCodeSuccess : null,
  havePromotion: null
};

export const PromotionCodeReducer = createReducer(
  initialState,
  on(invokePromotionCodeFilter, (state, { filter }) => ({
    ...state,
    loading: true,
    promotionCodeFilter: filter,
  })),
  on(getPromotionCodesSuccess, (state, { result }) => ({
    ...state,
    promotionCodesResult: result,
    loading: false,
  })),

  on(invokePromotionCodeSuccess, (state, { successMessage }) => ({
    ...state,
    successMessage: successMessage,
    loading: false,
  })),

  on(checkPromotionCodeSuccess, (state, { result }) => ({
    ...state,
    checkPromotionCodeSuccess: result,
    checkPromotionCodeError: null,
  })),
  on(checkPromotionCodeError, (state, { result }) => ({
    ...state,
    checkPromotionCodeError: result,
    checkPromotionCodeSuccess: null,
  })),

  on(getAdHavePromotionCode, (state, { result }) => ({
    ...state,
    havePromotion: result,
  })),

  on(applyPromotionCodeSuccess, (state, { result }) => ({
    ...state,
    applyPromotionCodeSuccess: result,
  }))
);
