import { Component, OnInit, Output } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BadgeService } from "src/app/shared/services/badge.service";
import {
  invokeAddAdvertisingCampaign,
  invokeAdDetails,
  invokeAdvertisingCompaignByHashedId,
  invokeChangeAdStatus,
  invokeUpadateAdPaymentDetails,
  invokeUpdateAdCampaignDetails,
  SetAddAdvertisingCampaign,
  updateAdCampaignDetails,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import {
  uploadFileProgressSelector,
  uploadFileSelector,
} from "src/app/store/file/file.selectors";
import { AdDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-details.interface";
import {
  HasedObjectiveEnum,
  ObjectiveTypeEnum,
  ObjectiveTypeEnumMapping,
} from "src/app/shared/enum/objectives";
import {
  adCampaignSelector,
  addCampaignSelector,
  adDetailsSelector,
  getAdCompaignByHashedIdSelector,
  updateAdCampaignDetailsSelector,
} from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
import {
  TotalCost,
  TotalCostWithDiscount,
} from "src/app/shared/models/calcul/totalCost";
import { calculateTotalLPCostByCampanyNamesSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { LocationSearchMethod } from "src/app/shared/enum/locationSearchMethod";
import { HttpEvent, HttpResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { CampaignStatusEnum } from "src/app/shared/enum/campaign-status";
import { SuccessDto } from "src/app/shared/models/success-dto";
import {
  invokeCreateEstimate,
  invokeSynthesisEstimate,
  invokeUpdateEstimate,
  resultCreateEstimate,
} from "src/app/store/commande/commande.actions";
import { Constants } from "src/app/shared/data/constants";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";
import { ResultCreateEstimate } from "src/app/shared/models/iberis/commande/resultCreateEstimate";
import {
  resultCreateEstimateSelector,
  resultSynthesisEstimateSelector,
} from "src/app/store/commande/commande.selectors";
import { DatePipe } from "@angular/common";
import { AdvertisingPaymentMethodEnum } from "src/app/shared/enum/paymentMethod";
import { PaymeeService } from "src/app/shared/services/payment/paymee.service";
import { KonnectService } from "src/app/shared/services/payment/konnect.service";
import {
  invokeIdIberis,
  invokeProfileDetails,
  resultIdIberis,
  saveFieldsLeadGeneration,
} from "src/app/store/advertiser/advertiser.actions";
import {
  advertiserProfileDetailsSelector,
  resultIdIberisSelector,
} from "src/app/store/advertiser/advertiser.selectors";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  invokeNotifyAdminWhenAdvertisingCampaignCreated,
  invokeSendLeadGenerationForm,
  resultSindingMail,
} from "src/app/store/mailing/mailing.actions";
import { LeadGeneration } from "src/app/shared/models/leadGeneration/leadGeneration";
import { sendMailSelector } from "src/app/store/mailing/mailing.selectors";
import { createEstimate } from "src/app/shared/models/iberis/commande/createEstimate";
import { CalculateCost } from "src/app/shared/models/calcul/calculateCost";
import { LocationAvailabilityEnum } from "src/app/shared/enum/location-availability";
import {
  AdvertisingCampaignInterface,
  CreateAdvertisingCampaignInterface,
} from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { environment } from "src/environments/environment";
import { Data } from "src/app/shared/models/iberis/commande/data";
import { calculateTotalLPCostByCampanyNames } from "src/app/store/locationPartner/location-partner.action";
import { ResultSynthesisEstimate } from "src/app/shared/models/iberis/commande/resultSynthesisEstimate";
import {
  ErrorPostPaymeeInterface,
  PostPaymeeInterface,
  ResultPaymeeInterface,
} from "src/app/shared/models/payment/paymee.interface";
import { NewCampaignCalculatorService } from "src/app/shared/services/calculations/new-campaign.service";
import { defaultFieldsData } from "src/app/shared/data/newFieldsFormData";
import { AdPaymentDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-payment-details.interface";
import {
  ErrorPostKonnectInterface,
  PostKonnectInterface,
  ResultKonnectInterface,
} from "src/app/shared/models/payment/konnect.interface";
import { TypeFieldsLeadGeneration } from "src/app/shared/enum/typeFieldsLeadGeneration";
import { DiscountTypeEnum } from "src/app/shared/enum/discount-type.enum";
import { CompanyIberisService } from "src/app/shared/services/iberis/company-iberis.service";

@Component({
  selector: "app-new-campaign",
  templateUrl: "./new-campaign.component.html",
  styleUrls: ["./new-campaign.component.scss"],
})
export class NewCampaignComponent extends BaseComponent implements OnInit {
  objectiveName: any;
  submitted: boolean = false;
  uploadFileProgress$: Observable<HttpEvent<any> | HttpResponse<any> | null>;
  isNextButtonHidden: boolean = false;
  objectiveTypeEnumMapping = ObjectiveTypeEnumMapping;
  objectiveTypeEnum = ObjectiveTypeEnum;
  step = 1;

  adDetails$: Observable<AdDetailsInterface>;
  estimateId: string = "";
  adDetails: AdDetailsInterface = { ...initialState.adDetails };
  //
  calculateTotalLPCostByCampanyNames$: Observable<TotalCostWithDiscount | null>;
  calculateTotalLPCostByCampanyNames: TotalCost[] = [];
  selectedLocationsNames: string[];

  @Output() form: FormGroup;
  isNotValid: boolean;

  //upload file
  files: File[] = [];
  isUploaded: boolean;
  filename: string;
  uploadFile$: Observable<string | null>;

  /** create estimate */
  params: CommonParamsIberis = {
    lang: "fr",
    companyId: environment.IBERIS.IBERIS_COMPANY_ID,
  };
  createEstimate: createEstimate;
  createEstimates$: Observable<ResultCreateEstimate | null>;
  estimateNumber: string;
  nextEstimatedNumber: string;
  paymentMethodEnum = AdvertisingPaymentMethodEnum;
  profile$: Observable<AdvertiserDetailsInterface | null>;
  profile: AdvertiserDetailsInterface;
  paymeeToken: string;
  idIberis$: Observable<string | null>;
  idIberis: string;
  formLeadGeneration: LeadGeneration = {
    title: "",
    description: "",
    fields: [],
    buttonName: "",
    adTitle: "",
    owner: "",
    creationDate: null,
  };
  resultSendMailLeadGeneration$: Observable<SuccessDto | null>;
  resultLeadGeneration: string;

  isPublish: boolean = false;
  isShown: boolean = false;
  campaignHashedID: string;
  adByTitle$: Observable<AdvertisingCampaignInterface | null>;
  adByTitle: AdvertisingCampaignInterface;
  startDate: Date | null;
  endDate: Date | null;
  calculateCost: CalculateCost = {
    calculationMethod: LocationSearchMethod.LOCATION_PARTNER,
    names: [],
    objectiveName: ObjectiveTypeEnum.BRAND_AWARENESS_AND_REACH,
    startDate: null,
    endDate: null,
  };
  totalCostPerDay: number = 0;
  tableTotalCostPerDay: TotalCost[] = [];
  impressionsPerDay: number = 0;
  resultSynthesisEstimate$: Observable<ResultSynthesisEstimate | null>;
  resultSynthesisEstimate: Data;
  finalStep: number;
  createAdCampaign: CreateAdvertisingCampaignInterface;

  formStep2: FormGroup;
  paymentMethod: any;
  totalCost: number = 0;

  savePayment$: Observable<SuccessDto | null>;

  defaultItems = defaultFieldsData;
  loading: boolean;

  condition: string = "";

  isCreated: boolean = false;
  isCreated$: Observable<SuccessDto | null>;
  updateAdvertisingCampaign$: Observable<SuccessDto | null>;
  webSite: string | null = null;
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    public badgeService: BadgeService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private paymeeService: PaymeeService,
    private konnectService: KonnectService,
    private router: Router,
    private newCampaignCalculatorService: NewCampaignCalculatorService,
    private companyService: CompanyIberisService
  ) {
    super(store);
    this.profile$ = this.store
      .pipe(select(advertiserProfileDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.adDetails$ = this.store
      .pipe(select(adDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.uploadFile$ = this.store
      .pipe(select(uploadFileSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.calculateTotalLPCostByCampanyNames$ = this.store
      .pipe(select(calculateTotalLPCostByCampanyNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.uploadFileProgress$ = this.store
      .pipe(select(uploadFileProgressSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.createEstimates$ = this.store
      .pipe(select(resultCreateEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.idIberis$ = this.store
      .pipe(select(resultIdIberisSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultSendMailLeadGeneration$ = this.store
      .pipe(select(sendMailSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.adByTitle$ = this.store
      .pipe(select(getAdCompaignByHashedIdSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultSynthesisEstimate$ = this.store
      .pipe(select(resultSynthesisEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.savePayment$ = this.store
      .pipe(select(adCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.isCreated$ = this.store
      .pipe(select(addCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.updateAdvertisingCampaign$ = this.store
      .pipe(select(updateAdCampaignDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.form = this.fb.group({
      /* campaignInformations: this.fb.group({ */
      title: [
        null,
        [Validators.required, Validators.maxLength(32), Validators.minLength(3)],
      ],
      startingDate: [null, Validators.required],
      endingDate: [null, Validators.required],
      locationPartners: [[], Validators.required],
      subCategories: [[]],
      zones: [[]],
    });
    this.formStep2 = this.fb.group({
      webSite: [
        "https://",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.pattern("^https://.*"),
        ],
      ],
    });

    this.objectiveName = Object.keys(HasedObjectiveEnum).find(
      (key) =>
        HasedObjectiveEnum[key as ObjectiveTypeEnum] ===
        this.activatedRoute.snapshot.params["objective"]
    );
    this.campaignHashedID =
      this.activatedRoute.snapshot.params["campaignHasedID"];
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isPublish = this.route.url.includes("/payment");
        this.isShown = this.isPublish;
        if (this.isPublish) {
          if (
            this.objectiveName == this.objectiveTypeEnum.GENERATION_DE_PROSPECTS
          ) {
            this.step = 4;
          } else {
            this.step = 3;
          }
        }
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(SetAddAdvertisingCampaign({ adCreated: null }));
    this.isCreated = false;
    this.isCreated$.subscribe((data) => {
      if (data) {
        this.campaignHashedID = data.message;
        this.store.dispatch(
          invokeUpdateEstimate({
            params: this.params,
            estimateId: this.estimateId,
            createEstimate: {
              ...this.createEstimate,
              notes: this.campaignHashedID,
            },
          })
        );
        this.store.dispatch(
          invokeNotifyAdminWhenAdvertisingCampaignCreated({
            title: this.createAdCampaign.title,
            email: this.profile.email,
          })
        );
        this.isCreated = true;
        this.store.dispatch(
          invokeAdDetails({
            invokeAdDetails: {
              ...this.adDetails,
              campaignHashedID: this.campaignHashedID,
            },
          })
        );
      }
    });
    this.store.dispatch(resultIdIberis({ idIberis: null }));
    this.store.dispatch(invokeAdDetails({ invokeAdDetails: this.adDetails }));
    this.store.dispatch(invokeIdIberis());
    this.store.dispatch(
      calculateTotalLPCostByCampanyNames({ totalCostWithDiscount: null })
    );
    this.updateAdvertisingCampaign$.subscribe((data) => {
      if (data) {
        this.store.dispatch(updateAdCampaignDetails({ successMessage: null }));
      }
    });
    if (this.isPublish) {
      this.store.dispatch(
        invokeAdvertisingCompaignByHashedId({
          campaignHashedId: this.campaignHashedID,
        })
      );
    }
    if (this.isPublish) {
      this.adByTitle$.subscribe((result) => {
        if (result) {
          this.startDate = result.startingDate;
          this.endDate = result.endingDate;
          this.adByTitle = {
            ...result,
            startingDate: new Date(result.startingDate),
            endingDate: new Date(result.endingDate),
          };
          this.form.patchValue({ ...this.adByTitle });
          this.formStep2.patchValue({
            webSite: this.adByTitle.redirectionLink,
          });
          let calculationMethod: LocationSearchMethod =
            LocationSearchMethod.LOCATION_PARTNER;
          if (result.calculationMethod) {
            calculationMethod = result.calculationMethod;
          }
          this.filename = this.adByTitle.videoLink;

          const adDetails: AdDetailsInterface = {
            ...this.adDetails,
            endDate: result.endingDate,
            startDate: result.startingDate,
            locations: result.locationPartners,
            title: result.title,
            objectiveName: result.objectiveName,
            estimateId: result.estimateId,
            videoLink: result.videoLink,
            calculationMethod: calculationMethod,
            campaignHashedID: this.campaignHashedID,
            validators: {
              title: true,
              locations: true,
              zones: true,
              dates: true,
              conditions: false,
            },
          };
          this.objectiveName = result.objectiveName;
          if (
            result.objectiveName ==
            this.objectiveTypeEnum.GENERATION_DE_PROSPECTS
          ) {
            this.step = 4;
          } else {
            this.step = 3;
          }
          this.store.dispatch(
            invokeAdDetails({
              invokeAdDetails: adDetails,
            })
          );

          this.calculateCost.endDate = result.endingDate;
          this.calculateCost.startDate = result.startingDate;
          this.calculateCost.names = result.locationPartners;
          this.newCampaignCalculatorService.onSelectCalculateCost(
            adDetails,
            adDetails.locations
          );

          this.store.dispatch(
            invokeSynthesisEstimate({
              params: this.params,
              estimateId: result.estimateId,
            })
          );
        }
      });
    }
    this.adDetails$.subscribe((adDetails: AdDetailsInterface) => {
      this.adDetails = adDetails;
      this.startDate = this.adDetails.startDate;
      this.endDate = this.adDetails.endDate;
      //this.adTitle = adDetails.title;
      if (
        this.adDetails.objectiveName ==
        this.objectiveTypeEnum.GENERATION_DE_PROSPECTS
      ) {
        this.finalStep = 5;
      } else {
        this.finalStep = 4;
      }
    });

    this.resultSynthesisEstimate$.subscribe(
      (result: ResultSynthesisEstimate | null) => {
        if (result != null) {
          this.resultSynthesisEstimate = result.data.estimate;
          this.estimateId = result.data.estimate.hashed_id;
          this.estimateNumber = result.data.estimate.estimate_number.replace(
            "DV-",
            ""
          );
          this.store.dispatch(
            invokeAdDetails({
              invokeAdDetails: {
                ...this.adDetails,
                estimateNumber: this.estimateNumber,
                estimateId: result.data.estimate.hashed_id,
                startDate: this.startDate,
                endDate: this.endDate,
              },
            })
          );
        }
      }
    );
    this.resultSendMailLeadGeneration$.subscribe(
      (result: SuccessDto | null) => {
        if (result != null) {
          this.resultLeadGeneration = result.message;
          if (this.resultLeadGeneration == "MISSING_INFORMATION") {
            this.toastr.error(
              this.translate.instant(
                "response.error." + this.resultLeadGeneration
              ),
              this.translate.instant("response.errorTitle")
            );
          }
          this.store.dispatch(resultSindingMail({ successMessage: null }));
        }
      }
    );
    this.uploadFile$.subscribe((value: string | null) => {
      this.store.dispatch(
        invokeAdDetails({
          invokeAdDetails: {
            ...this.adDetails,
            justificationLink: value ?? "",
          },
        })
      );
    });
    this.profile$.subscribe((user) => {
      if (user) this.profile = user;
      else this.store.dispatch(invokeProfileDetails());
    });
    this.store.dispatch(resultCreateEstimate({ resultCreateEStimate: null }));
    this.calculateTotalLPCostByCampanyNames$.subscribe((value) => {
      if (value != null) {
        this.calculateTotalLPCostByCampanyNames = value.totalCostPerDay.filter(
          (value) =>
            value.availabilities.availabilityStatus !=
            LocationAvailabilityEnum.NOT_AVAILABLE
        );
        this.selectedLocationsNames =
          this.calculateTotalLPCostByCampanyNames.map(
            (location) => location.companyName
          );
        this.form
          .get("locationPartners")
          ?.patchValue(this.selectedLocationsNames);
        this.totalCostPerDay =
          this.newCampaignCalculatorService.calculateCostPerDay(value);
        this.impressionsPerDay =
          this.newCampaignCalculatorService.calculateImpressionsPerDay(value);

        const totalCost: number =
          this.newCampaignCalculatorService.calculateTotalCost(
            value,
            this.calculateDays()
          );
        const totalImpressions: number =
          this.newCampaignCalculatorService.calculateTotalImpressions(
            value,
            this.calculateDays()
          );
        const clicksPerDay: number =
          Math.trunc(this.impressionsPerDay * 0.05) ?? 0;
        const leadPerDay: number =
          Math.trunc(this.impressionsPerDay * 0.6) ?? 0;
        const totalClicks: number = Math.trunc(totalImpressions * 0.05) ?? 0;
        const totalLead: number = Math.trunc(totalImpressions * 0.6) ?? 0;
        if (
          this.selectedLocationsNames.length != this.adDetails.locations.length
        ) {
          this.condition = Constants.availibilityCondition;
        }
        this.store.dispatch(
          invokeAdDetails({
            invokeAdDetails: {
              ...this.adDetails,
              startDate: this.startDate,
              endDate: this.endDate,
              totalCostPerDay: this.totalCostPerDay,
              totalCost: totalCost,
              impressionsPerDay: this.impressionsPerDay,
              clicksPerDay: clicksPerDay,
              leadPerDay: leadPerDay,
              totalClicks: totalClicks,
              totalLead: totalLead,
              totalImpressions: totalImpressions,
              locations: this.selectedLocationsNames,
            },
          })
        );
      }
    });

    this.form.valueChanges.subscribe(() => {
      this.store.dispatch(
        invokeAdDetails({
          invokeAdDetails: {
            ...this.adDetails,
            title: this.form.get("title")?.value,
            startDate: this.form.get("startingDate")?.value,
            endDate: this.form.get("endingDate")?.value,
            objectiveName: this.objectiveName,
            locations: this.form.get("locationPartners")?.value,
            validators: {
              conditions: this.adDetails.validators.conditions,
              dates: this.datesValidator(),
              locations: this.locationsValidator(),
              title: this.titleValidator(),
              zones: this.zonesValidator(),
            },
          },
        })
      );
    });
    this.formStep2.valueChanges.subscribe(() => {
      if (this.objectiveName == ObjectiveTypeEnum.WEB_SITE_TRAFFIC) {
        this.webSite = this.formStep2.get("webSite")?.value;
      } else {
        this.webSite = null;
      }
    });
    if(!this.isPublish){
      this.companyService.getIberisCompanyData(this.params).subscribe({
        next: (data) => {
          const estimate_number = JSON.parse(
            data.data.company.estimate_naming_series
          );
          this.nextEstimatedNumber = estimate_number.number;
        },
        error: (e) => {},
      });
    }
    this.createEstimates$.subscribe((result) => {
      if (result?.data.estimate) {
        this.estimateId = result.data.estimate.hashed_id;
        this.estimateNumber = result.data.estimate.estimate_number;

        this.createAdCampaign = {
          title: this.form.get("title")?.value,
          startingDate: this.form.get("startingDate")?.value,
          endingDate: this.form.get("endingDate")?.value,
          locationPartners: this.selectedLocationsNames,
          videoLink: this.filename,
          objectiveName: this.objectiveName,
          status: CampaignStatusEnum.DRAFT,
          estimateId: this.estimateId,
          redirectionLink: this.webSite,
          views: 0,
          synthesisReport: null,
          calculationMethod: this.adDetails.calculationMethod,
        };
        this.store.dispatch(
          invokeAddAdvertisingCampaign({
            addCampaign: this.createAdCampaign,
          })
        );
        this.store.dispatch(
          invokeAdDetails({
            invokeAdDetails: {
              ...this.adDetails,
              locations: this.selectedLocationsNames,
              estimateId: result.data.estimate.hashed_id,
              estimateNumber: result.data.estimate.estimate_number.replace(
                "DV-",
                ""
              ),
            },
          })
        );
      }
    });
    this.idIberis$.subscribe((result) => {
      if (result) {
        this.idIberis = result;
      }
    });
  }
  calculateDays(): number {
    if (this.startDate && this.endDate) {
      const start = new Date(this.startDate);
      const end = new Date(this.endDate);
      const timeDiff = Math.abs(end.getTime() - start.getTime());
      return Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
    } else return 0;
  }
  handleValueChanged(value: any) {
    this.formStep2.get("webSite")?.setValue(value);
  }
  handleFileName(value: string) {
    this.filename = value;
  }
  getWebSite() {
    return this.formStep2.get("webSite")?.valid;
  }
  //*****Validators ******//
  titleValidator(): boolean {
    return this.form.get("title")?.valid ?? false;
  }
  datesValidator(): boolean {
    return (
      (this.form.get("startingDate")?.valid &&
        this.form.get("endingDate")?.valid) ??
      false
    );
  }
  locationsValidator(): boolean {
    return this.form.get("locationPartners")?.valid ?? false;
  }
  zonesValidator(): boolean {
    return this.form.get("zones")?.valid ?? false;
  }

  //****  pagination *****//
  checkValidation(): boolean {
    switch (this.step) {
      case 1:
        return (
          this.titleValidator() &&
          this.datesValidator() &&
          (this.form.get("locationPartners")?.valid || this.zonesValidator())
        );
      case 2:
        if (this.objectiveName == ObjectiveTypeEnum.WEB_SITE_TRAFFIC)
          return (
            (this.formStep2.get("webSite")?.valid ?? false) &&
            this.adDetails.videoFile != undefined
          );
        if (this.objectiveName == ObjectiveTypeEnum.GENERATION_DE_PROSPECTS)
          return (
            this.formLeadGeneration.description != "" &&
            this.formLeadGeneration.title != ""
          );
        else {
          return true;
        }
      case 3:
        return true;
      case 4:
        return true;
      default:
        return false;
    }
    //here we must check validations
  }
  saveLead(leadGeneration: LeadGeneration) {
    this.formLeadGeneration = leadGeneration;
  }
  next() {
    this.submitted = true;
    this.isNotValid = this.form.get("title")?.invalid ?? true;
    //var conditions: string = "";
    let estimateNumber = "";
    let number = "";
    if (this.isPublish) {
      number = this.resultSynthesisEstimate.estimate_number.replace("DV-", "");
      estimateNumber = number.substring(number.lastIndexOf("-") + 1);
    } else {
      estimateNumber = this.nextEstimatedNumber;
    }
    var discountType: number = 1;
    var discountValue: number = 0;
    switch (this.adDetails.promotion?.discountType) {
      case DiscountTypeEnum.AMOUNT: {
        discountType = 2;
        discountValue = this.adDetails.promotion?.discountValue;
        break;
      }
      case DiscountTypeEnum.PERCENTAGE: {
        discountType = 1;
        discountValue = this.adDetails.promotion?.discountValue;
        break;
      }
    }
    /*if (this.selectedLocationsNames.length != this.adDetails.locations.length)
      conditions = Constants.availibilityCondition;*/
    this.createEstimate = {
      date: this.datePipe.transform(Constants.DATE_IBERIS, "yyyy-MM-dd"),
      due: this.datePipe.transform(Constants.DUE_DATE_IBERIS, "yyyy-MM-dd"),
      estimate_number: estimateNumber,
      object: "Campagne " + this.adDetails.title,
      notes: this.campaignHashedID,
      tax_type: 1,
      client_id: this.idIberis,
      use_conditions: 0,
      show_stamp: 1,
      show_billing: 1,
      show_delivery: 0,
      show_bank: 1,
      bank_id: environment.IBERIS.BANK_ID,
      show_conditions: 1,
      show_description: 1,
      show_pictures: 1,
      choice: 1,
      currency_rate: 1,
      language: this.params.lang,
      conditions: this.condition,
      reference: "",
      discount: "",
      additional_contact_id: "",
      show_unity: 1,
      items: {
        //item_hashed_id: "",
        item: "Campagne " + this.adDetails.title,
        description:
          this.datePipe.transform(this.adDetails.startDate, "yyyy-MM-dd ") +
          "-" +
          this.datePipe.transform(this.adDetails.endDate, "yyyy-MM-dd ") +
          "\n" +
          this.selectedLocationsNames,
        discountType: discountType,
        discountAmount: discountValue,
        qte: 1,
        price: this.adDetails.totalCost,
        taxes: [{ tax_hashed_id: environment.IBERIS.TAX_HASHED_ID }],
      },
      useInput: {
        input_hashed_id: environment.IBERIS.TIMBRE_FISCAL_HASHED_ID,
        value: 1,
      },
    };
    this.submitted = true;
    if (
      this.adDetails.objectiveName != ObjectiveTypeEnum.GENERATION_DE_PROSPECTS
    ) {
      switch (this.step) {
        case 1:
          if (!this.checkValidation()) {
            break;
          } else {
            if (this.isPublish) {
              this.updateCamapaign();
            } else if (this.isCreated) {
              this.updateCampaignCreated();
            }
            this.step++;
            break;
          }
        case 2:
          if (!this.isPublish && !this.isCreated) {
            this.store.dispatch(
              invokeCreateEstimate({
                params: this.params,
                createEstimate: this.createEstimate,
              })
            );
          }
          if (this.isPublish) {
            this.updateCamapaign();
          } else if (this.isCreated) {
            this.updateCampaignCreated();
          }
          this.step++;
          break;
        case 3:
          if (this.adDetails.estimateNumber) {
            this.step++;
            if (
              this.paymentMethod != AdvertisingPaymentMethodEnum.BANK_TRANSFER
            ) {
              this.store.dispatch(
                invokeChangeAdStatus({
                  campaignHashedId: this.campaignHashedID,
                  status: CampaignStatusEnum.AWAITING_PAYMENT,
                })
              );
            }
          }
          break;
        case 4:
          break;
        default:
          this.step++;
          break;
      }
    } else {
      if (this.defaultItems.length < 5) {
        this.defaultItems = [
          {
            title: "leadGeneration.fields.name",
            logo: "icofont icofont-ui-user",
            type: TypeFieldsLeadGeneration.TEXT,
            values: [],
          },
          {
            title: "leadGeneration.fields.tel",
            logo: "icofont icofont-telephone",
            type: TypeFieldsLeadGeneration.NUMBER,
            values: [],
          },
          {
            title: "leadGeneration.fields.email",
            logo: "icofont icofont-ui-email",
            type: TypeFieldsLeadGeneration.TEXT,
            values: [],
          },
          {
            title: "leadGeneration.fields.birthday",
            logo: "icofont icofont-ui-calendar",
            type: TypeFieldsLeadGeneration.DATE,
            values: [],
          },
          {
            title: "leadGeneration.fields.profession",
            logo: "icofont icofont-teacher",
            type: TypeFieldsLeadGeneration.TEXT,
            values: [],
          },
        ];
      }
      this.store.dispatch(
        saveFieldsLeadGeneration({
          fieldLeadGeneration: null,
        })
      );
      switch (this.step) {
        case 1:
          if (!this.checkValidation()) {
            break;
          } else {
            if (this.isPublish) {
              this.updateCamapaign();
            } else if (this.isCreated) {
              this.updateCampaignCreated();
            }
            this.step++;
            break;
          }
        case 2:
          this.store.dispatch(
            invokeSendLeadGenerationForm({
              leadGeneration: this.formLeadGeneration,
            })
          );
          if (!this.checkValidation()) {
            break;
          } else {
            this.step++;
            break;
          }
        case 3:
          if (!this.isPublish && !this.isCreated) {
            this.store.dispatch(
              invokeCreateEstimate({
                params: this.params,
                createEstimate: this.createEstimate,
              })
            );
          }
          if (this.isPublish) {
            this.updateCamapaign();
          } else if (this.isCreated) {
            this.updateCampaignCreated();
          }
          this.step++;
          break;
        case 4:
          if (this.adDetails.estimateNumber) {
            this.step++;
            if (
              this.paymentMethod != AdvertisingPaymentMethodEnum.BANK_TRANSFER
            ) {
              this.store.dispatch(
                invokeChangeAdStatus({
                  campaignHashedId: this.campaignHashedID,
                  status: CampaignStatusEnum.AWAITING_PAYMENT,
                })
              );
            }
          }

          break;
        case 5:
          this.router.navigate([
            "/",
            "dashboard",
            "advertiser",
            "advertising-campaigns",
            "payment",
            "success",
          ]);
          break;
        default:
          this.step++;
          break;
      }
    }
  }
  previous() {
    if (this.step == 1) {
      this.router.navigate([
        "/",
        "dashboard",
        "advertiser",
        "advertising-campaigns",
        "create-ad",
      ]);
    } else {
      this.store.dispatch(
        invokeAdDetails({
          invokeAdDetails: {
            ...this.adDetails,
            startDate: this.startDate,
            endDate: this.endDate,
            estimateNumber: this.estimateNumber,
            totalCostPerDay: this.totalCostPerDay,
            //totalCost: this.totalCostPerDay * this.calculateDays(),
            impressionsPerDay: this.impressionsPerDay,
            validators: {
              ...this.adDetails.validators,
              conditions: false,
            },
          },
        })
      );
      this.step--;
      if (this.isPublish) {
        this.isShown = false;
      }
    }
  }
  updateCampaignCreated() {
    let file: string | null = null;
    if (this.adDetails.justificationLink) {
      file = this.adDetails.justificationLink;
    }
    const updateAdCampaign: AdvertisingCampaignInterface = {
      title: this.form.get("title")?.value,
      startingDate: this.form.get("startingDate")?.value,
      endingDate: this.form.get("endingDate")?.value,
      locationPartners: this.calculateTotalLPCostByCampanyNames.map(
        (e) => e.companyName
      ),
      videoLink: this.filename,
      objectiveName: this.objectiveName,
      status: this.createAdCampaign.status,
      estimateId: this.estimateId,
      redirectionLink: this.webSite,
      views: 0,
      synthesisReport: null,
      justificationFile: file,
      publishDate: new Date(),
      orderReference: this.estimateId,
      paymentMethod: this.adDetails.paymentMethod,
      calculationMethod: this.adDetails.calculationMethod,
      advertisingCampaignHashedId: this.campaignHashedID,
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      emailPublisher: this.profile.email,
    };
    const isEqual =
      this.createAdCampaign.locationPartners.length ===
        this.calculateTotalLPCostByCampanyNames.length &&
      this.createAdCampaign.locationPartners.every((partner) =>
        this.calculateTotalLPCostByCampanyNames
          .map((e) => e.companyName)
          .includes(partner)
      );
    switch (this.step) {
      case 1:
        {
          if (
            this.createAdCampaign.title != this.form.get("title")?.value ||
            this.createAdCampaign.startingDate !=
              this.form.get("startingDate")?.value ||
            this.createAdCampaign.endingDate !=
              this.form.get("endingDate")?.value ||
            !isEqual
          ) {
            this.store.dispatch(
              invokeUpdateEstimate({
                params: this.params,
                estimateId: this.estimateId,
                createEstimate: this.createEstimate,
              })
            );
            if (this.campaignHashedID) {
              this.store.dispatch(
                invokeUpdateAdCampaignDetails({
                  campaignHashedId: this.campaignHashedID,
                  adCompaign: updateAdCampaign,
                })
              );
            }
          }
        }
        break;
      case 2: {
        if (this.campaignHashedID) {
          this.store.dispatch(
            invokeUpdateAdCampaignDetails({
              campaignHashedId: this.campaignHashedID,
              adCompaign: updateAdCampaign,
            })
          );
        }
      }
    }
  }
  updateCamapaign() {
    const updateAdCampaign = {
      title: this.form.get("title")?.value,
      startingDate: this.form.get("startingDate")?.value,
      endingDate: this.form.get("endingDate")?.value,
      locationPartners: this.calculateTotalLPCostByCampanyNames.map(
        (e) => e.companyName
      ),
      videoLink: this.filename,
      objectiveName: this.objectiveName,
      status: this.adByTitle.status,
      estimateId: this.estimateId,
      redirectionLink: this.webSite,
      views: 0,
      synthesisReport: null,
      justificationFile: this.adByTitle.justificationFile,
      publishDate: this.adByTitle.publishDate,
      orderReference: this.adByTitle.orderReference,
      paymentMethod: this.adByTitle.paymentMethod,
      calculationMethod: this.adDetails.calculationMethod,
      advertisingCampaignHashedId: this.campaignHashedID,
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      emailPublisher: this.profile.email,
    };
    const isEqual =
      this.adByTitle.locationPartners.length ===
        this.calculateTotalLPCostByCampanyNames.length &&
      this.adByTitle.locationPartners.every((partner) =>
        this.calculateTotalLPCostByCampanyNames
          .map((e) => e.companyName)
          .includes(partner)
      );
    switch (this.step) {
      case 1:
        {
          if (
            this.adByTitle.title != this.form.get("title")?.value ||
            this.adByTitle.startingDate !=
              this.form.get("startingDate")?.value ||
            this.adByTitle.endingDate != this.form.get("endingDate")?.value ||
            !isEqual
          ) {
            if (this.campaignHashedID) {
              this.store.dispatch(
                invokeUpdateEstimate({
                  params: this.params,
                  estimateId: this.estimateId,
                  createEstimate: this.createEstimate,
                })
              );
              this.store.dispatch(
                invokeUpdateAdCampaignDetails({
                  campaignHashedId: this.campaignHashedID,
                  adCompaign: updateAdCampaign,
                })
              );
            }
          }
        }
        break;
      case 2: {
        if (
          this.campaignHashedID &&
          (this.adByTitle.redirectionLink !=
            this.formStep2.get("webSite")?.value ||
            this.adByTitle.videoLink != this.filename)
        ) {
          this.store.dispatch(
            invokeUpdateAdCampaignDetails({
              campaignHashedId: this.campaignHashedID,
              adCompaign: updateAdCampaign,
            })
          );
        }
      }
    }
  }
  resultPaymentMethod(result: AdvertisingPaymentMethodEnum | null) {
    this.paymentMethod = result;
  }
  totalCostFunction(data: number) {
    this.totalCost = data;
  }
  paymentSelected() {
    this.loading = true;
    this.store.dispatch(
      invokeAdDetails({
        invokeAdDetails: {
          ...this.adDetails,
          paymentMethod: this.paymentMethod,
        },
      })
    );
    if (this.estimateId) {
      switch (this.paymentMethod) {
        case AdvertisingPaymentMethodEnum.PAYMEE: {
          const payment: PostPaymeeInterface = {
            amount: this.totalCost,
            email: this.profile.email,
            first_name: this.profile.firstName,
            last_name: this.profile.lastName,
            note: this.campaignHashedID,
            order_id: this.estimateId,
            phone: this.profile.phone,
            return_url:
              environment.PAYMENT.RETURN_URL + "?estimateId=" + this.estimateId,
            webhook_url: environment.PAYMENT.WEBHOOK_URL,
            cancel_url: environment.PAYMENT.CANCEL_URL,
          };
          this.paymeeService.postPayment(payment).subscribe({
            next: (value: ResultPaymeeInterface) => {
              const payment: AdPaymentDetailsInterface = {
                justificationFile: null,
                paymentMethod: this.paymentMethod,
                paymentToken: value.data.token,
                campaignHashedId: this.campaignHashedID,
              };
              this.store.dispatch(
                invokeUpadateAdPaymentDetails({
                  payment: payment,
                })
              );
              window.location.href = value.data.payment_url;
            },
            error: (err: ErrorPostPaymeeInterface) => {
              this.toastr.error(
                err.details,
                this.translate.instant("response.errorTitle")
              );
            },
          });
          break;
        }

        case AdvertisingPaymentMethodEnum.KONNECT: {
          const payment: PostKonnectInterface = {
            receiverWalletId: environment.PAYMENT.APP_KONNECT.WALLET,
            amount: Math.floor(Number(this.totalCost.toFixed(3)) * 1000),
            email: this.profile.email,
            firstName: this.profile.firstName,
            lastName: this.profile.lastName,
            orderId: this.campaignHashedID,
            phoneNumber: this.profile.phone.toString(),
            token: "TND",
            type: "immediate",
            acceptedPaymentMethods: ["wallet", "bank_card", "e-DINAR"],
            lifespan: 20,
            checkoutForm: false,
            addPaymentFeesToAmount: false,
            silentWebhook: true,
            theme: "light",
            successUrl:
              environment.PAYMENT.RETURN_URL + "?estimateId=" + this.estimateId,
            webhook: environment.PAYMENT.WEBHOOK_URL,
            failUrl: environment.PAYMENT.CANCEL_URL,
          };
          this.konnectService.postPayment(payment).subscribe({
            next: (value: ResultKonnectInterface) => {
              const payment: AdPaymentDetailsInterface = {
                justificationFile: null,
                paymentMethod: this.paymentMethod,
                paymentToken: value.paymentRef,
                campaignHashedId: this.campaignHashedID,
              };
              this.store.dispatch(
                invokeUpadateAdPaymentDetails({
                  payment: payment,
                })
              );
              window.location.href = value.payUrl;
              this.loading = false;
            },
            error: (err: ErrorPostKonnectInterface) => {
              this.toastr.error(
                err.errors.map((value) => value.message).toString(),
                this.translate.instant("response.errorTitle")
              );
              this.loading = false;
            },
          });
          break;
        }
        case AdvertisingPaymentMethodEnum.BANK_TRANSFER: {
          this.router.navigate([
            "/",
            "dashboard",
            "advertiser",
            "advertising-campaigns",
            "payment-success",
          ]);
          break;
        }
        case AdvertisingPaymentMethodEnum.NONE:
          break;
      }
    }
  }
}
