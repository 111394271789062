import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Constants } from "../data/constants";
import { SuccessDto } from "../models/success-dto";
import { FixedChargeInterface } from "../models/fixed-charges/fixed-charges.interface";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class FixedChargeService {
  constructor(private http: HttpClient) {}

  getAllFixedCharges(): Observable<FixedChargeInterface[]> {
    return this.http.get<FixedChargeInterface[]>(
      `${environment.APP_PORT + Constants.FIXED_CHARGES}/all`
    );
  }

  createFixedCharge(fixedCharge: FixedChargeInterface): Observable<SuccessDto> {
    return this.http.post<SuccessDto>(
      `${environment.APP_PORT + Constants.FIXED_CHARGES}/create`,
      fixedCharge
    );
  }

  updateFixedCharge(
    oldName: string,
    fixedCharge: FixedChargeInterface
  ): Observable<SuccessDto> {
    return this.http.patch<SuccessDto>(
      `${environment.APP_PORT + Constants.FIXED_CHARGES + "/" + oldName}`,
      fixedCharge
    );
  }

  deleteFixedCharge(fixedChargeName: string): Observable<SuccessDto> {
    return this.http.delete<SuccessDto>(
      `${environment.APP_PORT + Constants.FIXED_CHARGES}/` + fixedChargeName
    );
  }
}
