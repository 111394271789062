<app-welcome-card
  [result]="views | toKValue"
  [loading$]="isLoading$ | async"
></app-welcome-card>
<div class="col-xl-12">
  <ng-container>
    <!-- Views Card -->
    <div class="row d-flex justify-content-center">
      <!-- Consumer Card -->
      <div class="col-12 col-md-6 col-xl-4 col-sm-6 col-xxl-4">
        <app-card
          [title]="consumerCard.title | translate"
          [unit]="consumerCard.unit"
          [value]="consumerCard.value"
          [icon]="consumerCard.icon"
          [class]="consumerCard.class"
          [changes]="consumerCard.changes"
          [growth]="consumerCard.growth"
          [growthUnit]="consumerCard.growthUnit"
          [hasYesterdayGrowth]="consumerCard.hasYesterdayGrowth"
          [growthYesterday]="consumerCard.growthYesterday"
          [growthYesterdayUnit]="consumerCard.growthYesterdayUnit"
          [isLoading]="isLoading"
        >
        </app-card>
      </div>
      <!-- Advertisers Card -->
      <div class="col-12 col-md-6 col-xl-4 col-sm-6 col-xxl-4">
        <app-card
          [title]="advertiserCard.title | translate"
          [unit]="advertiserCard.unit"
          [value]="advertiserCard.value"
          [icon]="advertiserCard.icon"
          [class]="advertiserCard.class"
          [changes]="advertiserCard.changes"
          [growth]="advertiserCard.growth"
          [growthUnit]="advertiserCard.growthUnit"
          [hasYesterdayGrowth]="advertiserCard.hasYesterdayGrowth"
          [isLoading]="isLoading"
        >
        </app-card>
      </div>
      <div class="col-12 col-md-6 col-xl-4 col-sm-6 col-xxl-4">
        <app-card
          [title]="adsCard.title | translate"
          [unit]="adsCard.unit"
          [icon]="adsCard.icon"
          [value]="adsCard.value"
          [class]="'eye'"
          [changes]="adsCard.changes"
          [growth]="adsCard.growth"
          [growthUnit]="adsCard.growthUnit"
          [hasYesterdayGrowth]="adsCard.hasYesterdayGrowth"
          [isLoading]="isLoading"
        >
        </app-card>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-md-6 col-xl-4 col-sm-6 col-xxl-4">
        <app-card
          [title]="regionsCard.title | translate"
          [unit]="regionsCard.unit"
          [value]="regionsCard.value"
          [icon]="regionsCard.icon"
          [class]="regionsCard.class"
          [changes]="regionsCard.changes"
          [growth]="regionsCard.growth"
          [growthUnit]="regionsCard.growthUnit"
          [hasYesterdayGrowth]="regionsCard.hasYesterdayGrowth"
          [isLoading]="isLoading"
        >
        </app-card>
      </div>
      <div class="col-12 col-md-6 col-xl-4 col-sm-6 col-xxl-4">
        <app-card
          [title]="zonesCard.title | translate"
          [unit]="zonesCard.unit"
          [value]="zonesCard.value"
          [icon]="zonesCard.icon"
          [class]="zonesCard.class"
          [changes]="zonesCard.changes"
          [growth]="zonesCard.growth"
          [growthUnit]="zonesCard.growthUnit"
          [hasYesterdayGrowth]="zonesCard.hasYesterdayGrowth"
          [isLoading]="isLoading"
        >
        </app-card>
      </div>
      <div class="col-12 col-md-6 col-xl-4 col-sm-6 col-xxl-4">
        <app-card
          [title]="locationCard.title | translate"
          [unit]="locationCard.unit"
          [value]="locationCard.value"
          [icon]="locationCard.icon"
          [class]="locationCard.class"
          [changes]="locationCard.changes"
          [growth]="locationCard.growth"
          [growthUnit]="locationCard.growthUnit"
          [hasYesterdayGrowth]="locationCard.hasYesterdayGrowth"
          [isLoading]="isLoading"
        >
        </app-card>
      </div>
    </div>
  </ng-container>
  <div class="d-flex">
    <div class="col-xl-8 col-sm-12 m-r-20">
      <!--Summary chart-->
      <div class="card rounded" id="dashboard.admin.summmaryChart">
        <div class="card-header">
          <div class="media-space-between">
            <h4 style="margin-top: 7px">{{ dailyChart.title | translate }}</h4>
            <div class="d-flex">
              <form [formGroup]="form" style="margin-right: 10px; width: 18rem">
                <div class="input-group">
                  <div
                    class="input-group-text bg-primary"
                    data-target="#dt-date"
                    [owlDateTimeTrigger]="dtPicker3"
                  >
                    <i
                      class="icofont icofont-ui-calendar"
                      style="color: white; font-size: 12px"
                    ></i>
                  </div>
                  <input
                    formControlName="date"
                    class="form-control"
                    type="text"
                    name="daterange"
                    style="font-size: small"
                    (ngModelChange)="getChartInDate()"
                    [placeholder]="'placehorderPickDate' | translate"
                    [selectMode]="'range'"
                    [owlDateTimeTrigger]="dtPicker3"
                    [owlDateTime]="dtPicker3"
                  />
                  <owl-date-time
                    [pickerType]="'calendar'"
                    #dtPicker3
                  ></owl-date-time>
                </div>
              </form>
              <app-export
                class="m-auto"
                [data]="splineChart"
                [title]="dailyChart.title | translate"
                [buttonName]="'export'"
                [summaryStatics]="resultSummaryChart"
              ></app-export>
            </div>
          </div>
        </div>
        <div class="card-body">
          <app-spline
            (chart)="chart($event)"
            (resultSummaryStatics)="summaryStatics($event)"
            [seriesTitles]="dailyChart.seriesTitles"
            [yaxisTitle]="dailyChart.yaxisTitle"
            [xaxisTitle]="dailyChart.xaxisTitle"
            [resultChartObs]="resultChartObs"
            [isMain] = "true"
          >
          </app-spline>
          <!--[obs]="dailyChartObs$.toPromise()" [obs]="dailyChartObs" <apx-chart [series]="series" [chart]="dailyChart.style" [dataLabels]="label" [xaxis]="xaxis" [yaxis]="yaxis" *ngIf="length!=0 && !isLoading"></apx-chart>-->
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-sm-12">
      <div class="card rounded">
        <div class="cal-date-widget card-body">
          <div class="cal-datepicker">
            <ngb-datepicker
              #dp
              [displayMonths]="1"
              [dayTemplate]="customDay"
              [ngModel]="events"
            >
            </ngb-datepicker>
            <ng-template
              #customDay
              let-date="date"
              let-currentMonth="currentMonth"
              let-selected="selected"
              let-disabled="disabled"
              let-focused="focused"
            >
              <span
                class="custom-day"
                [class.bg-primary]="isRange(date)"
                [class.focused]="focused"
                [class.text-muted]="disabled"
                [ngbTooltip]="title(date)"
              >
                {{ date.day }}
              </span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card rounded">
    <div class="card-header pb-0">
      <div class="media-space-between">
        <h4>{{ "dashboard.admin.lpReview.title" | translate }}</h4>
        <div class="d-flex align-items-end">
          <app-export
            class="m-auto"
            [data]="receivedLocation"
          ></app-export>
        </div>
      </div>
    </div>
    <app-location-partner-preview
      (locationPreview)="locationPartnerPreview($event)"
      (tableLocationPreview)="tableLocationPartnerPreview($event)"
      [loading]="loadingTable"
    ></app-location-partner-preview>
  </div>
</div>
