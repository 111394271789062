import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sliceString'
})
export class SliceStringPipe implements PipeTransform {
  transform(value : string, length : number): string {
    if(value.length > length) return value.substring(0,length) +"..."
    else return value;
  }
}