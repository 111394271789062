import { createReducer, on } from "@ngrx/store";
import {
  getAllObjectives,
  invokeAllObjectives,
  invokeUpdateObjective,
  resultDeleteObjective,
  updateObjectiveSuccess,
} from "./objective.actions";
import { ObjectiveStateInterface } from "./objectiveState.interface";

export const initialState: Readonly<ObjectiveStateInterface> = {
  objective: null,
  successMessage: null,
  isLoading: false,
};
export const ObjectiveReducer = createReducer(
  initialState,
  on(invokeAllObjectives, (state)=>({
    ...state,
    isLoading : true
  })),
  on(getAllObjectives, (state, { objective }) => ({
    ...state,
    isLoading : false,
    objective: objective,
  })),
  on(invokeUpdateObjective, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(updateObjectiveSuccess, (state, { message }) => ({
    ...state,
    isLoading: false,
    successMessage: message,
  })),
  on(resultDeleteObjective, (state, { message }) => ({
    ...state,
    isLoading: false,
    successMessage: message,
  }))
);
