import { Injectable } from "@angular/core";
import { OwlDateTimeIntl } from "@danielmoncada/angular-datetime-picker";
import { TranslateService } from "@ngx-translate/core";

// here is the default text string - just adjust the strings to reflect your preferred language
@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
    
    /** A label for the up second button (used by screen readers).  */
    override upSecondLabel= 'Ajouter une seconde';

    /** A label for the down second button (used by screen readers).  */
    override downSecondLabel= 'Moins une seconde';

    /** A label for the up minute button (used by screen readers).  */
    override upMinuteLabel= 'Ajouter une minute';

    /** A label for the down minute button (used by screen readers).  */
    override downMinuteLabel= 'Diminuer une minute';

    /** A label for the up hour button (used by screen readers).  */
    override upHourLabel= 'Ajouter une heur';

    /** A label for the down hour button (used by screen readers).  */
    override downHourLabel= 'Diminuer une heur';

    /** A label for the previous month button (used by screen readers). */
    override prevMonthLabel= 'Mois précédent';

    /** A label for the next month button (used by screen readers). */
    override nextMonthLabel= 'Mois prochain';

    /** A label for the previous year button (used by screen readers). */
    override prevYearLabel= 'Année précédente';

    /** A label for the next year button (used by screen readers). */
    override nextYearLabel= 'Année prochaine';

    /** A label for the previous multi-year button (used by screen readers). */
    override prevMultiYearLabel= '21 années précédentes';

    /** A label for the next multi-year button (used by screen readers). */
    override nextMultiYearLabel= '21 années prochaines';

    /** A label for the 'switch to month view' button (used by screen readers). */
    override switchToMonthViewLabel= 'Passer à la vue mensuelle';

    /** A label for the 'switch to year view' button (used by screen readers). */
    override switchToMultiYearViewLabel= 'Choisissez le mois et l année';

    /** A label for the cancel button */
    override cancelBtnLabel= 'Annuler';

    /** A label for the set button */
    override setBtnLabel= 'Confirmer';

    /** A label for the range 'from' in picker info */
    override rangeFromLabel= 'Depuis';

    /** A label for the range 'to' in picker info */
    override rangeToLabel= 'À';

    /** A label for the hour12 button (AM) */
    override hour12AMLabel= 'AM';

    /** A label for the hour12 button (PM) */
    override hour12PMLabel= 'PM';

    constructor(private translate : TranslateService){
        super();
        if(translate.currentLang == "en"){
            this.upSecondLabel= "Add a second";
            this.downSecondLabel= "Minus a second";
            this.upMinuteLabel= "Add a minute";
            this.downMinuteLabel= "Minus a minute";
            this.upHourLabel= "Add a hour";
            this.downHourLabel= "Minus a hour";
            this.prevMonthLabel= "Previous month";
            this.nextMonthLabel= "Next month";
            this.prevYearLabel= "Previous year";
            this.nextYearLabel= "Next year";
            this.prevMultiYearLabel= "Previous 21 years";
            this.nextMultiYearLabel= "Next 21 years";
            this.switchToMonthViewLabel= "Change to month view";
            this.switchToMultiYearViewLabel= "Choose month and year";
            this.cancelBtnLabel= "Cancel";
            this.setBtnLabel= "Set";
            this.rangeFromLabel= "From";
            this.rangeToLabel= "To";
            this.hour12AMLabel= "AM"
            this.hour12PMLabel= "PM"
        }
    }
};