export interface LanguageInterface {
  title: string;
  icon: string;
  key: string;
}
export const languageData: LanguageInterface[] = [
  {
    title: "settings.english",
    icon: "gb",
    key: "en",
  },
  {
    title: "settings.french",
    icon: "fr",
    key: "fr",
  },
];
