import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, withLatestFrom } from "rxjs";
import { RouterInterface } from "src/app/shared/models/router/router.interface";
import { RoutersResultInterface } from "src/app/shared/models/router/routersResult.interface";
import { RouterService } from "src/app/shared/services/router/router.service";
import { setAPIStatus } from "../apiState.interface";
import {
  invokeDeleteRouter,
  getRouterByMac,
  invokeCreateRouter,
  routerSuccessMessage,
  invokeGetRouterByMac,
  invokeUpdateRouter,
  getRoutersSuccess,
  invokeRouters,
  updateRouterSuccess,
  invokeIsExistByMac,
  resultIsExistByMac,
} from "./router.action";
import { AppStateInterface } from "../appState.interface";
import { select, Store } from "@ngrx/store";
import { routerFilterSelector as routersFilterSelector } from "./router.selectors";
import { SuccessDto } from "src/app/shared/models/success-dto";

@Injectable()
export class RouterEffect {
  constructor(
    private actions$: Actions,
    private routerService: RouterService,
    private store: Store<AppStateInterface>
  ) {}

  invokeGetRouterByFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeRouters),
      withLatestFrom(this.store.pipe(select(routersFilterSelector))),
      mergeMap(([, routerFilter]) => {
        return this.routerService.getRouterByFilter(routerFilter).pipe(
          map((data: RoutersResultInterface) =>
            getRoutersSuccess({ routersResult: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
  invokeCreateRouter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCreateRouter),
      mergeMap((state) => {
        return this.routerService.createRouter(state.createRouter).pipe(
          map((data: any) => routerSuccessMessage({ successMessage: data })),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
  invokeUpdateRouter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateRouter),
      mergeMap((state) => {
        return this.routerService
          .updateRouter(state.oldMAC, state.routerDto)
          .pipe(
            map((data: SuccessDto) =>
              updateRouterSuccess({ successMessage: data.message })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
  invokeGetRouterByMac$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeGetRouterByMac),
      mergeMap((state) => {
        return this.routerService.getRouterByMAC(state.MAC).pipe(
          map((data: RouterInterface | null) =>
            getRouterByMac({ routerDetail: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeDeleteRouterByMac$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeDeleteRouter),
      mergeMap((state) => {
        return this.routerService.deleteByMAC(state.MAC).pipe(
          map((data: SuccessDto | null) =>
            routerSuccessMessage({ successMessage: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeIsExistByMac$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeIsExistByMac),
    mergeMap((state) => {
      return this.routerService.isExistByMAC(state.Mac).pipe(
        map((data: boolean | null) =>
          resultIsExistByMac({ isExist: data })
        ),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error,
                apiStatus: "error",
              },
            })
          )
        )
      );
    })
  )
);
}
