import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { JwtService } from "../../services/jwt.service";
import { RoleEnum } from "../../models/user/role.interface";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
})
export class BreadcrumbComponent implements OnInit, OnChanges {
  @Input() title: any;
  @Input() items!: any[];
  @Input() active_item: any;
  @Input() icon: string | undefined;
  role: string;
  src: string;

  public svgContent: string;
  public sanitizedSvgContent: any;
  constructor(
    private jwtService: JwtService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {}
  ngOnChanges() {
    this.src = "assets/svgIcon/" + this.icon + ".svg";
    this.loadSvg(this.src);
  }
  ngOnInit(): void {
    const role = this.jwtService.getRoleName(localStorage.getItem("token"));
    switch (role) {
      case RoleEnum.ROLE_ADMIN:
        this.role = "admin";
        break;
      case RoleEnum.ROLE_ADVERTISER:
        this.role = "advertiser";
        break;
      case RoleEnum.ROLE_LOCATION:
        this.role = "location";
        break;
      default:
        break;
    }
  }
  loadSvg(svgPath: string) {
    this.http.get(svgPath, { responseType: "text" }).subscribe(
      (data) => {
        this.svgContent = this.modifySvgContent(data);
        this.sanitizedSvgContent = this.sanitizer.bypassSecurityTrustHtml(
          this.svgContent
        );
      },
      (error) => {
        console.error("Error loading SVG:", error);
      }
    );
  }

  modifySvgContent(svgContent: string): string {
    svgContent = svgContent.replace(/stroke="#130F26"/g, 'stroke="#FF69B4"');
    svgContent = svgContent.replace(/width="24"/g, 'width="16"');
    return svgContent;
  }
}
