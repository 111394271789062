<div class="card-body" id="dashboard.admin.lpReview.title">
  <div class="table-responsive data-table">
    <form id="dashboard.admin.lpReview.title">
      <table
        class="table table-striped table-border-vertical"
      >
        <thead>
          <tr>
            <th>
              {{ "dashboard.admin.lpReview.companyName" | translate }}
            </th>
            <ng-container *ngFor="let column of last7Days">
              <th>
                {{ column | localizedDate}}
              </th>
            </ng-container>
            <th> {{ "dashboard.admin.lpReview.averageViews" | translate }} </th>
          </tr>
        </thead>
        <tbody *ngIf="!loading">
          <tr
            *ngFor="
              let item of lastWeekLocationPartnerReview?.locationPartnerChecks
            "
          >
            <td>
              <ngb-highlight
                [result]="item.companyName"
              ></ngb-highlight>
            </td>
            <td *ngFor="let views of item.views">
              {{views}}
            </td>

            <td>
              {{item.average.toFixed(3)}}
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="loading">
        <ng-container style="float: right" class="flex">
          <div class="loader-box">
            <div class="loader-34"></div>
          </div>
          <h6
            class="col align-self-center f-w-600"
            style="text-align: center"
          >
            {{ "fetching" | translate }}
          </h6>
        </ng-container>
      </div>
      <div
          *ngIf="resultCountLOcationPartnerPreview == 0 && !loading"
          class="d-flex justify-content-center m-t-20"
        >
          {{ "noData" | translate }}
        </div>
      <div class="media">
        <div class="col-3"></div>
        <div class="col-6">
          <div class="d-flex justify-content-center p-3">
            <ngb-pagination
              [collectionSize]="resultCountLOcationPartnerPreview"
              [(page)]="pagerLocationPartnerPreview.pageNumber"
              [pageSize]="pagerLocationPartnerPreview.pageSize"
              [rotate]="true"
              [boundaryLinks]="true"
              (pageChange)="changePage($event)"
            >
            </ngb-pagination>
          </div>
        </div>
        <div class="col-3">
          <div class="d-flex justify-content-end p-3">
            <select
              class="form-select"
              style="width: auto"
              name="pageSize"
              [(ngModel)]="changePageSize"
            >
              <option [ngValue]="5">5 {{ "pagination.itemsPerPage" | translate }}</option>
              <option [ngValue]="10">10 {{ "pagination.itemsPerPage" | translate }}</option>
              <option [ngValue]="15">15 {{ "pagination.itemsPerPage" | translate }}</option>
              <option [ngValue]="resultCountLOcationPartnerPreview">
                {{ "pagination.allItems" | translate }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

