import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const categoryState = (state: AppStateInterface) =>
  state.categoryState;

export const allCategoryNamesSelector = createSelector(
    categoryState,
    (state) => state.categoryNames
);

export const loadingCategorySelector = createSelector(
    categoryState,
    (state) => state.loading
);

export const allCategoriesSelector = createSelector(
  categoryState,
  (state) => state.categories
);

export const createUpdateCategorySuccessSelector = createSelector(
  categoryState,
  (state) => state.createUpdateSuccess
);

export const deleteCategorySuccessSelector = createSelector(
  categoryState,
  (state) => state.deleteSuccess
);

export const saveSelectedCategorySelector = createSelector(
  categoryState,
  (state) => state.selectedCategories
)
