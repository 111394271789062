<div class="card">
  <div class="card-header p-b-0">
    <h4 class="m-20 m-l-0">{{ "dashboard.titleLastAds" | translate }}</h4>
  </div>
  <div class="card-body pt-0">
    <div class="table-responsive data-table" style="font-size: 12px">
      <table class="table table-striped table-border-vertical">
        <thead>
          <tr>
            <ng-container *ngFor="let column of columns">
              <th>
                {{ column.label | translate }}
              </th>
            </ng-container>
            <th>{{ "dashboard.options" | translate }}</th>
          </tr>
        </thead>
        <tbody *ngIf="!(loading$ | async)">
          <tr *ngFor="let item of latestAd; let j = index">
            <td>
              <a
                type="button"
                style="color: black"
                [routerLink]="[
                  '/',
                  'dashboard',
                  'advertiser',
                  'insights',
                  'statistics',
                  item.advertisingCampaignHashedId
                ]"
              >
                {{ item.title }}
              </a>
            </td>
            <td class="img-content-box">
              <i class="icon-eye"></i>
              {{ item.views }}
            </td>
            <td width="20%">
              <app-column-location-partners
                [locationPartners]="item.locationPartners"
                [j]="j"
              ></app-column-location-partners>
            </td>
            <td>
              {{ item.startingDate | localizedDate }}
            </td>
            <td>
              {{ item.endingDate | localizedDate }}
            </td>
            <td>
              <span
                [ngClass]="
                  'badge ' +
                  badgeService.getBadgeForAdvertisingCampaingStatus(item.status)
                "
              >
                {{ "enumMapping.campaigns.status." + item.status | translate }}
              </span>
            </td>
            <td>
              <app-drob-zone-options
                [options]="getOptions(item)"
                (optionSelected)="saveAction($event, item)"
              ></app-drob-zone-options>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        *ngIf="count == 0 && !(loading$ | async)"
        class="d-flex justify-content-center m-t-20"
      >
        {{ "noData" | translate }}
      </div>
      <div *ngIf="loading$ | async">
        <ng-container style="float: right" class="flex">
          <div class="loader-box">
            <div class="loader-34"></div>
          </div>
          <h6 class="col align-self-center f-w-600" style="text-align: center">
            {{ "fetching" | translate }}
          </h6>
        </ng-container>
      </div>
    </div>
  </div>
</div>
