<div class="row">
  <div class="col-xxl-8 col-lg-8 box-col-12">
    <div class="card">
      <div class="card-body">
        <app-billing-info
          [clientId]="clientId"
          [createEstimate]="createEstimate"
          [adDetails]="adDetails"
        ></app-billing-info>
      </div>
    </div>
    <div class="card">
      <div class="card-header p-b-0">
        <div class="d-flex align-items-baseline">
          <i class="icofont icofont-ssl-security" style="font-size: 18px"></i>
          <h4 class="ps-2 m-b-0 m-r-5">
            {{ "newCampaign.step4.conditionsTitle" | translate }}
          </h4>
          <!--<i class="icofont icofont-info-circle" style="color: darkgrey"></i>-->
        </div>
      </div>
      <div class="card-body">
        <div class="checkbox-animated">
          <label class="d-block" for="checkbox">
            <input
              onkeypress
              class="checkbox_animated"
              id="checkbox"
              type="checkbox"
              style="margin-left: 0; margin-bottom: 7px"
              (click)="onChecked($event)"
            />
            {{ "newCampaign.step4.conditions.part1" | translate }}
            <a
              class="color-primary underline-link"
              target="_blank"
              [href]="termsOfUse"
              >{{ "newCampaign.step4.conditions.part2" | translate }}</a
            >. {{ "newCampaign.step4.conditions.part3" | translate }}
          </label>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header p-b-0">
        <div class="d-flex align-items-baseline">
          <i class="icofont icofont-bill" style="font-size: 18px"></i>
          <h4 class="ps-2 m-b-0 m-r-5">
            {{ "newCampaign.step4.paymentTitle" | translate }}
          </h4>
          <!--<i class="icofont icofont-info-circle" style="color: darkgrey"></i>-->
        </div>
      </div>
      <div class="card-body">
        <div class="d-flex mt-2 justify-content-center">
          <div class="card-body">
            <div class="tabbed-card">
              <ul
                ngbNav
                #nav2="ngbNav"
                [activeId]="1"
                class="nav justify-content-center position-sticky"
              >
                <!--selecting by location-->
                <li [ngbNavItem]="active" [destroyOnHide]="true" class="mx-2">
                  <a
                    ngbNavLink
                    class="btn btn-outline-primary"
                    (click)="paymentSelected(null)"
                    >{{ "newCampaign.step4.bankCard" | translate }}</a
                  >
                  <ng-template ngbNavContent>
                    <div class="row justify-content-center position-sticky">
                      <!-- <div class="col-md-2 col-lg-2 col-xl-2 box-col-2">
                        <a ngbNavLink>
                          <div
                            data-bs-toggle="tooltip"
                            placement="bottom"
                            ngbTooltip="Paymee"
                            style="opacity: 20%;"
                            class="card mx-2 card-hover"
                            (click)="
                              activePayment = paymentMethodEnum.PAYMEE;
                              paymentSelected(paymentMethodEnum.PAYMEE)
                            " 
                            [ngClass]="{
                              'border-active rounded p-l-0':
                                activePayment == paymentMethodEnum.PAYMEE
                            }"
                          >
                            
                            <div class="d-flex">
                              <span
                                *ngIf="loadingEstimate"
                                class="spinner-border spinner-border-sm mr-1"
                              ></span>
                              <img
                                class="img"
                                src="assets/identity/logo_paymee.png"
                                alt="payment"
                              />
                            </div></div
                        ></a>
                      </div> -->
                      <div
                        class="col-md-3 col-lg-3 col-xl-3 col-xxl-2 box-col-2 col-3"
                      >
                        <a ngbNavLink>
                          <div
                            data-bs-toggle="tooltip"
                            placement="bottom"
                            ngbTooltip="Konnect"
                            (click)="
                              activePayment = paymentMethodEnum.KONNECT;
                              paymentSelected(paymentMethodEnum.KONNECT)
                            "
                            class="card mx-2 card-hover"
                            [ngClass]="{
                              'border-active rounded p-l-0':
                                activePayment == paymentMethodEnum.KONNECT
                            }"
                          >
                            <div class="d-flex">
                              <img
                                class="img"
                                src="assets/identity/konnect.png"
                                alt="Konnect"
                              />
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li
                  [ngbNavItem]="2"
                  [destroyOnHide]="true"
                  class="mx-2"
                  (click)="
                    paymentSelected(paymentMethodEnum.BANK_TRANSFER);
                    activePayment = paymentMethodEnum.BANK_TRANSFER
                  "
                >
                  <a ngbNavLink class="btn btn-outline-primary">{{
                    "newCampaign.step4.bankTransfer" | translate
                  }}</a>
                  <ng-template ngbNavContent>
                    <div class="mt-4">
                      <!--zone-->
                      <app-payment-text
                        [totalCost]="totalCost"
                        [estimateNumber]="adDetails.estimateNumber"
                      ></app-payment-text>
                      <div class="d-flex justify-content-center">
                        <button
                          class="btn btn-primary"
                          type="button"
                          (click)="uploadJustificationModal(justificationModal)"
                        >
                          {{
                            "newCampaign.step4.uploadJustification" | translate
                          }}
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </li>
              </ul>
            </div>
            <div class="pt-0 m-t-10">
              <div [ngbNavOutlet]="nav2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="m-t-50"></div>
  </div>

  <div class="col-xxl-4 col-lg-4 box-col-6">
    <div class="m-r-15" style="position: fixed; justify-items: center">
      <div class="card">
        <div class="card-body">
          <h4>{{ "newCampaign.step4.yourSelection" | translate }}</h4>
          <hr />
          <div class="row">
            <div class="col-12">
              <div class="grid-column">
                <label class="pb-2 col-form-label label f-w-600">
                  {{ "newCampaign.step4.price" | translate }} HT
                </label>
                <p>{{ priceHT.toFixed(3) }} TND</p>
              </div>
            </div>
            <div class="col-12">
              <div class="grid-column">
                <ng-container *ngIf="discount != 0">
                  <label class="col-form-label label f-w-600">{{
                    "newCampaign.step3.promo" | translate
                  }}</label>
                  <p>{{ "- " + discount.toFixed(3) + " TND" }}</p>
                </ng-container>
              </div>
            </div>
            <div class="col-12">
              <div class="grid-column">
                <label class="pb-2 col-form-label label f-w-600"
                  >19 % TVA</label
                >
                <p>{{ tva.toFixed(3) }} TND</p>
              </div>
            </div>
            <div class="col-12">
              <div class="grid-column">
                <label class="pb-2 col-form-label label f-w-600">
                  {{ "newCampaign.step3.taxStamp" | translate }}
                </label>
                <p>1.000 TND</p>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12">
                <div class="grid-column">
                  <h5 class="pb-2 col-form-label label f-w-600">
                    {{ "newCampaign.step4.total" | translate }}
                  </h5>
                  <h5>{{ this.totalCost.toFixed(3) }} TND</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Pop up update profil picture-->
<ng-template #justificationModal let-modal id="justificationModal">
  <div class="modal-header">
    <h4 class="modal-title" id="exampleModalLabel">
      {{ "newCampaign.step4.justificationFile" | translate }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div class="m-b-10" *ngIf="showError">
      <div
        class="alert alert-danger outline alert-dismissible fade show"
        role="alert"
      >
        <app-feather-icon [icon]="'alert-triangle'"></app-feather-icon>
        <p>
          {{ "dropzoneFile.rulePart1" | translate
          }}<b>{{ "dropzoneFile.rulePart2" | translate }}5MB</b>!
        </p>
        <button
          class="btn-close"
          type="button"
          aria-label="Close"
          (click)="showError = false"
        ></button>
      </div>
    </div>
    <ngx-dropzone
      [multiple]="false"
      (change)="onSelect($event.addedFiles)"
      accept="application/pdf,image/*"
    >
      <ngx-dropzone-label>
        <div>
          <div class="dz-message needsclick">
            <i class="icon-cloud-up"></i>
            <p class="text-dark">{{ "dropzoneFile.title" | translate }}</p>
            <p class="dz-message needsclick color-primary text-dark">
              ( {{ "dropzoneFile.rulePart1" | translate }}
              <strong [ngClass]="{ 'txt-danger': showError }"
                >{{ "dropzoneFile.rulePart2" | translate }} 5MB</strong
              >
              )
            </p>
          </div>
        </div>
      </ngx-dropzone-label>
      <ngx-dropzone-preview
        *ngFor="let f of files"
        [removable]="true"
        [file]="f"
        [attr.data-type]="f.type"
        (removed)="onRemove()"
      >
        <ngx-dropzone-label style="align-self: flex-end; margin: 0">
          <div class="ligne-top"></div>
          <div class="new-dropdown-label">
            {{ f.name.split(".")[0] | castFileName }} .{{
              f.type.split("/")[1]
            }}
          </div>
        </ngx-dropzone-label>
      </ngx-dropzone-preview>
    </ngx-dropzone>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      style="float: left"
      class="btn btn-primary"
      data-bs-dismiss="modal"
      (click)="uploadJustificationFile()"
      (click)="activePayment = paymentMethodEnum.BANK_TRANSFER; uploadProof()"
    >
      {{ "buttons.confirm" | translate }}
    </button>
  </div>
</ng-template>
