export enum PartnerStatus {
  REVENUE_SHARING = "REVENUE_SHARING",
  INTERNET_PROVIDER_PAYMENT = "INTERNET_PROVIDER_PAYMENT",
}

export enum PartnerStatusToShow {
  REVENUE_SHARING = "enumMapping.partnerStatus.REVENUE_SHARING",
  INTERNET_PROVIDER_PAYMENT = "enumMapping.partnerStatus.INTERNET_PROVIDER_PAYMENT",
}
export interface PartnerStatusInterface {
  id: PartnerStatus;
  value: string;
}
