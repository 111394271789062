import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { FilterLocationPartnersToCreateCampaign } from "src/app/shared/models/location-partners/filterBySubCategoriesAndTagLocationPartner";
import { AllRegionInterface } from "src/app/shared/models/region/region";
import { AppStateInterface } from "src/app/store/appState.interface";
import { initialState } from "src/app/store/locationPartner/location-partner.reducers";
import {
  resultLocationPartnerByRegionSelector,
  saveSelectedLPsSelector,
} from "src/app/store/locationPartner/location-partner.selectors";
import { invokeAllRegions } from "src/app/store/region/region.actions";
import { allRegionSelector } from "src/app/store/region/region.selectors";
import { ExportLocationComponent } from "../../../components/admin/settings/impressions/export-location/export-location.component";
import { SelectedLps } from "../../models/location-partners/selectedLps";
import {
  invokeLocationPartnersByZone,
  selectedLPs,
} from "src/app/store/locationPartner/location-partner.action";

@Component({
  selector: "app-locations",
  templateUrl: "./locations.component.html",
})
export class LocationsComponent extends BaseComponent implements OnInit {
  @Input() public isCalculator: boolean;
  getLocationPartnersCompanyNames: string[] | null;
  selectedLocations: string[] = [];
  allRegions$: Observable<AllRegionInterface[] | null>;
  regionNames: string[] = [];
  listRegion: string[][] = [];
  locationPartnersToCreateCampaignFilter: FilterLocationPartnersToCreateCampaign =
    {
      ...initialState.locationPartnersToCreateCampaignFilter,
    };
  locationPartnersToCreateCampaignFilter$: Observable<FilterLocationPartnersToCreateCampaign>;

  locationPartnersByRegion$: Observable<string[][] | null>;
  locationPartnersByRegion: string[][] = [];
  item: string;
  checked: boolean = false;
  selectedLps: string[] = [];
  saveSelectedLPs$: Observable<SelectedLps | null>;
  countSelected: number;
  saveLocations: string[][] = [];
  searchLP: string[] = [];
  totalLPs: number;
  lp: string;
  region: string;
  constructor(
    private store: Store<AppStateInterface>,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef
  ) {
    super(store);
    this.allRegions$ = this.store
      .pipe(select(allRegionSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.locationPartnersByRegion$ = this.store
      .pipe(select(resultLocationPartnerByRegionSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.saveSelectedLPs$ = this.store
      .pipe(select(saveSelectedLPsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.saveSelectedLPs$.subscribe((data) => {
      if (data) {
        this.selectedLps = data.companyNames;
        this.countSelected = data.count;
      }
    });
    this.allRegions$.subscribe((result: AllRegionInterface[] | null) => {
      if (result != null) {
        this.regionNames = result.map((e) => e.name);
        this.store.dispatch(
          invokeLocationPartnersByZone({ regionNames: this.regionNames })
        );
      } else {
        this.store.dispatch(invokeAllRegions());
      }
    });
    this.locationPartnersByRegion$.subscribe((result: string[][] | null) => {
      if (result) {
        this.saveLocations = result;
        this.locationPartnersByRegion = result;
        this.totalLPs = result.flat().length;
      }
    });
  }
  selectAllRegions(event: any) {
    /*if (event.target.checked) this.listRegion = cloneDeep(this.locationPartnersByRegion.map(e=>e));
    else this.listRegion = [];

    this.store.dispatch(invokeLocationPartnersByZone({regionNames: this.regionNames}))*/

    if (event.target.checked) {
      Object.values(this.locationPartnersByRegion).map((value, index) => {
        this.locationPartnersByRegion[index].map((e) => {
          this.selectedLps = [...this.selectedLps, e];
        });
      });
    } else {
      this.checked = false;
      this.selectedLps = [];
    }
  }

  checkRegionSelectedAll(region: string[]): boolean {
    if(region) return region.every((item) => this.selectedLps.includes(item));
    else return false
  }
  saveAllRegionLps(event: any, region : string) {
    
    const index : number = this.regionNames.indexOf(region)
    const locations : string[] = this.locationPartnersByRegion[index]
    if (event.target.checked) {
      this.selectedLps.push(...locations);
    } else {
      this.selectedLps = this.selectedLps.filter((item) => !locations.includes(item));
    }
  }

  searchTerm(event: Event) {
    if ((event.target as HTMLInputElement).value == "") {
      this.locationPartnersByRegion = this.saveLocations;
      this.cdr.detectChanges();
    } else {
      this.searchLP = [];
      Object.values(this.locationPartnersByRegion).map((value, index) => {
        this.saveLocations[index].map((e) => {
          if (
            e
              .toLowerCase()
              .includes((event.target as HTMLInputElement).value.toLowerCase())
          ) {
            this.searchLP = [...this.searchLP, e];
          }
        });
      });
      this.locationPartnersByRegion = [[...new Set(this.searchLP)]];
      this.cdr.detectChanges();
    }
  }
  submitLps() {
    this.store.dispatch(
      selectedLPs({
        selectedLps: {
          companyNames: this.selectedLps,
          isCalculator: false,
          count: this.selectedLps.length,
        },
      })
    );
    this.onClickDismissModal();
    if (this.isCalculator == false) {
      const open = this.modalService.open(ExportLocationComponent, {
        centered: true,
      });
    }
  }
  saveLPs(event: any, lp: string) {
    if (event.target.checked) {
      this.selectedLps = [...this.selectedLps, lp];
    } else {
      this.selectedLps = this.selectedLps.filter((location) => location != lp);
    }
  }
  onClickDismissModal() {
    this.modalService.dismissAll(LocationsComponent);
  }
}
