import { createReducer, on } from "@ngrx/store";
import {
  invokeMainDashboard,
  setMainDashboard,
  invokeProfileDetails,
  setProfileDetails,
  registerSuccess,
  invokeRegister,
  setAccount,
  getAllAdvertiserByFilter,
  invokeAdvertiserFilter,
  approveAdvertiserByEmail,
  confirmEmailForRegistrationSuccess,
  advertiserByAdHashedId,
  resultDeleteAdvertiser,
  selectedAdvertiser,
  resultUpdateAdvertiser,
  resultUpdateSubRoleForAdvertiser,
  resultIdIberis,
  confirmEmailForRegistrationFailed,
  regenerateConfirmRegestrationSuccess,
  regenerateConfirmRegestrationFailed,
  invokeConfirmEmail,
  regenerateConfirmRegestration,
  saveFieldsLeadGeneration,
  saveLeadGeneration,
  invokeApproveAdvertiser,
  resultAllActivatedAdvertisers,
  invokeAllActivatedAdvertisers,
  invokeUpdateAdvertiser,
  saveNewFieldsLeadGeneration,
  invokeAllAdvertiserByFilter,
} from "./advertiser.actions";
import { AdvertiserStateInterface } from "./advertiserState.interface";

export const initialState: Readonly<AdvertiserStateInterface> = {
  loading: true,
  dashboard: null,
  profileDetails: null,
  editProfileSuccessMessage: null,
  advertiser: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    website: "",
    phone: 0,
    companyName: "",
    companyActivity: "",
    companyAddress: "",
    logo: "",
    resetToken: "",
  },
  successMessage: null,
  account: null,
  loadingFilter: null,
  resultFilterAdvertiser: {
    count: 0,
    advertisers: [],
  },
  filter: {
    email: null,
    status: [],
    isAscending: null,
    pager: {
      pageNumber: 1,
      pageSize: 10,
    },
    sortName: null,
  },

  confirmEmailRegistrationSuccess: null,
  confirmEmailRegistrationFailed: null,

  regenerateTokenSuccess: null,
  regenerateTokenFailed: null,

  register: null,
  registerSuccess: null,
  publishers: null,
  selectedAdvertiser: null,
  idIberis: "",
  fieldLeadGeneration: null,
  saveFormLeadGeneration: null,
  newFieldsLeadGeneration: null,
  loadingApprove: false,
  dto: null,
  loadingMain: false,
  resultActivateDesactivate: null,
  resultDeleteAdvertiser: null,
};

export const AdvertiserReducer = createReducer(
  initialState,

  on(invokeRegister, (state, { advertiser }) => ({
    ...state,
    register: advertiser,
    loading: true,
  })),
  on(registerSuccess, (state, { successMessage }) => ({
    ...state,
    registerSuccess: successMessage,
    loading: false,
  })),

  on(invokeConfirmEmail, (state) => ({
    ...state,
    loading: true,
  })),
  on(confirmEmailForRegistrationSuccess, (state, { successMessage }) => ({
    ...state,
    confirmEmailRegistrationSuccess: successMessage,
    loading: false,
  })),
  on(confirmEmailForRegistrationFailed, (state, { result }) => ({
    ...state,
    confirmEmailRegistrationFailed: result,
    loading: false,
  })),

  on(regenerateConfirmRegestration, (state) => ({
    ...state,
    loading: true,
  })),
  on(regenerateConfirmRegestrationSuccess, (state, { successMessage }) => ({
    ...state,
    regenerateTokenSuccess: successMessage,
    loading: false,
  })),
  on(regenerateConfirmRegestrationFailed, (state, { result }) => ({
    ...state,
    regenerateTokenFailed: result,
    loading: false,
  })),
  on(invokeMainDashboard, (state) => ({
    ...state,
    loadingMain: true,
  })),
  on(setMainDashboard, (state, { dashboardAdvertiser }) => ({
    ...state,
    loadingMain: false,
    dashboard: dashboardAdvertiser,
  })),
  on(setProfileDetails, (state, { profileDetails }) => ({
    ...state,
    loading: false,
    profileDetails: profileDetails,
  })),
  on(invokeProfileDetails, (state) => ({
    ...state,
    loading: true,
  })),
  on(invokeUpdateAdvertiser, (state) => ({
    ...state,
    loading: true,
  })),
  on(resultUpdateAdvertiser, (state, { successMessage }) => ({
    ...state,
    loading: false,
    editProfileSuccessMessage: successMessage,
  })),
  on(setAccount, (state, { account }) => ({
    ...state,
    loading: false,
    account: account,
  })),
  on(invokeAdvertiserFilter, (state, { filter }) => ({
    ...state,
    filter: filter,
    loadingFilter: true,
  })),
  on(invokeAllAdvertiserByFilter, (state) => ({
    ...state,
    loadingFilter: true,
  })),
  on(getAllAdvertiserByFilter, (state, { resultFilterAdvertiser }) => ({
    ...state,
    resultFilterAdvertiser: resultFilterAdvertiser,
    loadingFilter: false,
  })),
  on(invokeApproveAdvertiser, (state, { dto }) => ({
    ...state,
    loadingApprove: true,
    dto: dto,
  })),
  on(approveAdvertiserByEmail, (state, { successMessage }) => ({
    ...state,
    loadingApprove: false,
    resultActivateDesactivate: successMessage,
  })),

  on(advertiserByAdHashedId, (state, { profileDetails }) => ({
    ...state,
    profileDetails: profileDetails,
  })),
  on(resultDeleteAdvertiser, (state, { successMessage }) => ({
    ...state,
    resultDeleteAdvertiser: successMessage,
  })),
  on(selectedAdvertiser, (state, { selectedAdvertiser }) => ({
    ...state,
    selectedAdvertiser: selectedAdvertiser,
  })),
  on(resultUpdateSubRoleForAdvertiser, (state, { successMessage }) => ({
    ...state,
    successMessage: successMessage,
  })),
  on(resultIdIberis, (state, { idIberis }) => ({
    ...state,
    idIberis: idIberis,
  })),
  on(saveFieldsLeadGeneration, (state, { fieldLeadGeneration }) => ({
    ...state,
    fieldLeadGeneration: fieldLeadGeneration,
  })),
  on(saveLeadGeneration, (state, { saveFormLeadGeneration }) => ({
    ...state,
    saveFormLeadGeneration: saveFormLeadGeneration,
  })),
  on(saveNewFieldsLeadGeneration, (state, { newFieldsLeadGeneration }) => ({
    ...state,
    newFieldsLeadGeneration: newFieldsLeadGeneration,
  })),

  on(invokeAllActivatedAdvertisers, (state) => ({
    ...state,
    loading: true,
  })),
  on(resultAllActivatedAdvertisers, (state, { publishers }) => ({
    ...state,
    publishers: publishers,
    loading: false,
  }))
);
