import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const routerState = (state: AppStateInterface) => state.routerState;

export const loadingRouterSelector = createSelector(
  routerState,
  (state) => state.loading
);
export const routerFilterSelector = createSelector(
  routerState,
  (state) => state.routersFilter
);

export const routersResultSelector = createSelector(
  routerState,
  (state) => state.routersResult
);
export const routerSuccessMessageSelector = createSelector(
  routerState,
  (state) => state.successMessage
);

export const routerUpdateSuccessMessageSelector = createSelector(
  routerState,
  (state) => state.updateSuccessMessage
);

export const routerByMacSelector = createSelector(
  routerState,
  (state) => state.routerDetail
);

export const resultRouterExistByMacSelector = createSelector(
  routerState, 
  (state) => state.isExist
)