<div class="card">
  <div class="card-header">
    <div class="media-space-between">
      <h4>{{ "invoices.title" | translate }}</h4>
      <!--<div class="media-body text-end">
        <div
          class="faq-form mr-3"
          style="color: darkgrey; display: flex; margin-left: 10px"
        >
          <i class="search-icon" data-feather="search"></i>
          <div class="input-group">
            <input
              style="width: 17rem"
              id="table-complete-search"
              type="text"
              class="form-control"
              [placeholder]="'placeholder.searchByCampaignTitle' | translate"
              [(ngModel)]="ad"
              (ngModelChange)="searchTerm(ad)"
            />
          </div>
        </div>
      </div>-->
    </div>
  </div>
  <div class="card-body">
    <div class="table-responsive data-table">
      <table class="table table-striped table-border-vertical">
        <thead>
          <tr>
            <ng-container *ngFor="let column of columns">
              <th style="max-width: max-content">
                {{ column.label | translate }}
                <!--<img
                  src="assets/identity/info.png"
                  class="img-info"
                  alt="info"
                />-->
              </th>
            </ng-container>
            <th>{{ "buttons.actions" | translate }}</th>
          </tr>
        </thead>
        <tbody *ngIf="!(loading$ | async)">
          <ng-container>
            <tr
              *ngFor="
                let invoice of resultListInvoicesFromIberis;
                let i = index
              "
            >
              <td>{{ invoice.invoice_number }}</td>
              <td>
                {{ invoice.date }}
              </td>
              <td>{{ invoice.object.split(" ")[1] }}</td>
              <td>
                <div class="media-body">
                  <button
                    class="btn btn-primary mx-2"
                    data-bs-toggle="modal"
                    data-bs-target="#approveAdvertiserModal"
                    (click)="
                      onClickshowModal(
                        approveAdvertiserModal,
                        invoice.hashed_id,
                        invoice.invoice_number
                      )
                    "
                  >
                    <i class="icon-eye"></i>
                    {{ "buttons.showInvoice" | translate }}
                  </button>
                  <button
                    class="btn btn-outline-primary"
                    (click)="
                      onClickDownload(
                        invoice.hashed_id,
                        invoice.invoice_number,
                        i
                      )
                    "
                  >
                    <loading-button
                      [loading]="isDownloadInvoice && specificColumun == i"
                      [value]="'buttons.downloadInvoice' | translate"
                      [icon]="'icofont icofont-download-alt'"
                    ></loading-button>
                  </button>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <div *ngIf="loading$ | async">
        <ng-container style="float: right" class="flex">
          <div class="loader-box">
            <div class="loader-34"></div>
          </div>
          <h6 class="col align-self-center f-w-600" style="text-align: center">
            {{ "fetching" | translate }}
          </h6>
        </ng-container>
      </div>
      <div
        *ngIf="totalRecords == 0 && !(loading$ | async)"
        class="d-flex justify-content-center m-t-20"
      >
        {{ "noData" | translate }}
      </div>
      <div class="media">
        <div class="col-3"></div>
        <div class="col-6">
          <div class="d-flex justify-content-center p-3">
            <ngb-pagination
              [collectionSize]="totalRecords"
              [rotate]="true"
              [boundaryLinks]="true"
              (pageChange)="changePage($event)"
            >
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #approveAdvertiserModal let-modal id="approveAdvertiserModal">
  <div class="modal-header">
    <h4 class="modal-title" id="exampleModalLabel">
      {{ "buttons.downloadInvoice" | translate }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div *ngIf="!(pdfUrl | async)">
      <ng-container style="float: right" class="flex">
        <div class="loader-box">
          <div class="loader-34"></div>
        </div>
        <h6 class="col align-self-center f-w-600" style="text-align: center">
          {{ "fetching" | translate }}
        </h6>
      </ng-container>
    </div>
    <iframe [src]="pdfUrl | async" width="100%" height="600px"></iframe>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      data-bs-dismiss="modal"
      (click)="modal.dismiss('Cross click')"
    >
      {{ "buttons.close" | translate }}
    </button>
  </div>
</ng-template>
