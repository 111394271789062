import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { LocationPartnerRoutingModule } from "./location-partner-routing.module";
import { MainComponent } from "./main/main.component";
import { PromoteComponent } from "./promote/promote.component";
import { RemunerationComponent } from "./remuneration/remuneration.component";
import { StatisticsComponent } from "./statistics/statistics.component";
import { SharedModule } from "src/app/shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LatestAdComponent } from './main/latest-ad/latest-ad.component';
import { CarouselModule } from "ngx-owl-carousel-o";
import { UnlockedScreensComponent } from './statistics/unlocked-screens/unlocked-screens.component';
import { OwlDateTimeModule } from "@danielmoncada/angular-datetime-picker";
import { CaseFsiComponent } from './remuneration/case-fsi/case-fsi.component';

@NgModule({
  declarations: [
    MainComponent,
    PromoteComponent,
    RemunerationComponent,
    StatisticsComponent,
    LatestAdComponent,
    UnlockedScreensComponent,
    CaseFsiComponent,
  ],
  imports: [
    CommonModule,
    LocationPartnerRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    OwlDateTimeModule
  ],
  exports:[PromoteComponent]
})
export class LocationPartnerModule {}
