import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { LocationAvailabilityEnum } from "src/app/shared/enum/location-availability";
import {
  ObjectiveTypeEnum,
  ObjectiveTypeEnumMapping,
} from "src/app/shared/enum/objectives";
import { AdDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-details.interface";
import {
  TotalCost,
  TotalCostWithDiscount,
} from "src/app/shared/models/calcul/totalCost";
import { FilterLocationPartnersToCreateCampaign } from "src/app/shared/models/location-partners/filterBySubCategoriesAndTagLocationPartner";
import { BadgeService } from "src/app/shared/services/badge.service";
import { adDetailsSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeLocationsToCreateCampaign } from "src/app/store/locationPartner/actions/get-locations.actions";
import { initialState as locationInitialState } from "src/app/store/locationPartner/location-partner.reducers";
import {
  locationPartnersToCreateCampaignFilterSelector,
  calculateTotalLPCostByCampanyNamesSelector,
} from "src/app/store/locationPartner/location-partner.selectors";
import { NewCampaignCalculatorService } from "src/app/shared/services/calculations/new-campaign.service";
import { initialState as adsInitialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
@Component({
  selector: "app-step1",
  templateUrl: "./step1.component.html",
  styleUrls: ["./step1.component.scss"],
})
export class Step1Component extends BaseComponent implements OnInit {
  castString(strings: string[]): string {
    return strings.map((string) =>  " " + string).toString();
  }
  active = 1;
  @Input() form: FormGroup;
  @Input() objectiveName: ObjectiveTypeEnum;
  localStoreObjective: ObjectiveTypeEnum =
    ObjectiveTypeEnum.LOCAL_STORE_PROMOTIONS;

  //Dates
  objectiveTypeEnum = ObjectiveTypeEnum
  objectiveTypeEnumMapping = ObjectiveTypeEnumMapping;

  totalCostPerDay: string;
  impressionsPerDay: number = 0;

  totalCost: string;

  adDetails$: Observable<AdDetailsInterface>;

  adDetails: AdDetailsInterface = adsInitialState.adDetails;

  calculateTotalLPCostByCampanyNames$: Observable<TotalCostWithDiscount | null>;
  calculateTotalLPCostByCampanyNames: TotalCost[] = [];
  selectedLocationsNames: string[];

  locationPartnersToCreateCampaignFilter$: Observable<FilterLocationPartnersToCreateCampaign>;
  locationPartnersToCreateCampaignFilter: FilterLocationPartnersToCreateCampaign =
    {
      ...locationInitialState.locationPartnersToCreateCampaignFilter,
    };
  allSelectionsByMethod: string[] = [];

  showAllLocations: boolean = false;
  constructor(
    private store: Store<AppStateInterface>,
    public badgeService: BadgeService,
    private newCampaignCalculatorService: NewCampaignCalculatorService
  ) {
    super(store);
    this.adDetails$ = this.store
      .pipe(select(adDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.locationPartnersToCreateCampaignFilter$ = this.store
      .pipe(select(locationPartnersToCreateCampaignFilterSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.calculateTotalLPCostByCampanyNames$ = this.store
      .pipe(select(calculateTotalLPCostByCampanyNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.adDetails$.subscribe((result: AdDetailsInterface) => {
      this.adDetails = result;
      this.impressionsPerDay = result.impressionsPerDay ?? 0;
      this.totalCostPerDay = result.totalCostPerDay.toFixed(3) ?? 0;

      this.totalCost = result.totalCost.toFixed(3) ?? 0
      if (this.adDetails.locations.length == 0)
        this.selectedLocationsNames = [];
    });
    this.locationPartnersToCreateCampaignFilter$.subscribe(
      (result: FilterLocationPartnersToCreateCampaign) => {
        if (
          result.locationsSearchMethod != null &&
          result.regions?.length > 0
        ) {
          this.store.dispatch(invokeLocationsToCreateCampaign());
          this.locationPartnersToCreateCampaignFilter = result;
        }
      }
    );

    this.calculateTotalLPCostByCampanyNames$.subscribe(
      (value: TotalCostWithDiscount | null) => {
        if (value != null) {
          this.allSelectionsByMethod = value.totalCostPerDay.map(
            (lp) => lp.companyName
          );
          this.calculateTotalLPCostByCampanyNames =
            value.totalCostPerDay.filter(
              (lp: TotalCost) =>
                lp.availabilities.availabilityStatus !=
                LocationAvailabilityEnum.NOT_AVAILABLE
            );
          this.selectedLocationsNames =
            this.calculateTotalLPCostByCampanyNames.map(
              (location) => location.companyName
            );
        }
      }
    );
  }

  datesSelected(event: { startDate: Date | null; endDate: Date | null }) {
    this.form.controls["startingDate"].setValue(event.startDate);
    this.form.controls["endingDate"].setValue(event.endDate);
    const adDetailsWithNewDates: AdDetailsInterface = {
      ...this.adDetails,
      startDate: event.startDate,
      endDate: event.endDate,
    };
    if (event.startDate && event.endDate)
      this.newCampaignCalculatorService.onSelectCalculateCost(
        adDetailsWithNewDates,
        this.allSelectionsByMethod
      );
  }
}
