<div class="modal-header justify-content-start">
  <h4 class="modal-title" id="exampleModalLabel">
    {{ "actions.campaignDetails" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal()"
  ></button>
</div>
<div class="modal-body" style="padding-left: 2rem">
  <div class="grid-column">
    <ng-template #none>
      <p class="m-b-0">{{ "undefined" | translate }}</p>
    </ng-template>
    <h5 class="f-w-600" style="font-size: 12px;">
      {{ "campaignDetails.campaignName" | translate }}
    </h5>
    <p class="m-b-0" *ngIf="detailsAd.title; else none">
      {{ detailsAd.title }}
    </p>
    <h5 class="f-w-600" style="font-size: 12px;">
      {{ "campaignDetails.campaignHashedId" | translate }}
    </h5>
    <p class="m-b-0" *ngIf="detailsAd.advertisingCampaignHashedId; else none">
      {{ detailsAd.advertisingCampaignHashedId }}
    </p>
    <h5 class="f-w-600" style="font-size: 12px;">{{ "advertiserEmail" | translate }}</h5>
    <p class="m-b-0" *ngIf="detailsAd.emailPublisher; else none">
      {{ detailsAd.emailPublisher }}
    </p>
    <h5 class="f-w-600" style="font-size: 12px;">
      {{ "campaignDetails.objective" | translate }}
    </h5>
    <p class="m-b-0" *ngIf="detailsAd.objectiveName; else none">
      {{ "enumMapping.objectives." + detailsAd.objectiveName | translate }}
    </p>
    <h5 class="f-w-600" style="font-size: 12px;">
      {{ "campaignDetails.location" | translate }}
    </h5>
    <p class="m-b-0" *ngIf="detailsAd.locationPartners; else none">
      <span
        [ngClass]="'badge badge-light-primary'"
        *ngFor="let item of detailsAd.locationPartners"
        class="m-b-5"
      >
        <div class="row col-12 m-r-10 m-l-10">
          {{ item }}
        </div>
      </span>
    </p>
    <h5 class="f-w-600" style="font-size: 12px;">
      {{ "campaignDetails.startDate" | translate }}
    </h5>
    <p class="m-b-0" *ngIf="detailsAd.startingDate; else none">
      {{ detailsAd.startingDate | localizedDate }}
    </p>
    <h5 class="f-w-600" style="font-size: 12px;">
      {{ "campaignDetails.endDate" | translate }}
    </h5>
    <p class="m-b-0" *ngIf="detailsAd.endingDate; else none">
      {{ detailsAd.endingDate | localizedDate }}
    </p>
    <h5 class="f-w-600" style="font-size: 12px;">
      {{ "campaignDetails.redirectionLink" | translate }}
    </h5>
    <p class="m-b-0" *ngIf="detailsAd.redirectionLink; else none">
      <ng-container *ngFor="let chunk of detailsAd.redirectionLink | newline : 40">
        {{ chunk }}<br>
      </ng-container>
    </p>
    <h5 class="f-w-600" style="font-size: 12px;">
      {{ "campaignDetails.synthesisReport" | translate }}
    </h5>
    <p class="m-b-0" *ngIf="detailsAd.synthesisReport; else none">
      <a
        class="color-primary underline-link"
        target="_blank"
        [href]="cdnURL + detailsAd.synthesisReport"
        >{{ "dashboard.showDetails" | translate }}</a
      >
    </p>
    <h5 class="f-w-600" style="font-size: 12px;">
      {{ "campaignDetails.status" | translate }}
    </h5>
    <p class="m-b-0" *ngIf="detailsAd.status; else none">
      <span
        [ngClass]="
          'badge ' +
          badgeService.getBadgeForAdvertisingCampaingStatus(detailsAd.status)
        "
      >
        {{ "enumMapping.campaigns.status." + detailsAd.status | translate }}
      </span>
    </p>

    <h5 class="f-w-600" style="font-size: 12px;">
      {{ "campaignDetails.paymentMethod" | translate }}
    </h5>
    <p class="m-b-0" *ngIf="detailsAd.paymentMethod; else none">
      {{ "enumMapping.adPaymentMethod." + detailsAd.paymentMethod | translate }}
    </p>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-primary" (click)="closeModal()">
    {{ "buttons.close" | translate }}
  </button>
  <button
    class="btn btn-primary"
    (click)="closeModal()"
    [routerLink]="['/dashboard/admin/campaigns/pipeline/statistics', detailsAd.advertisingCampaignHashedId]"
  >
    {{ "buttons.adDetails" | translate }}
  </button>
</div>
