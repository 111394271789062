import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AdvertiserRoutingModule } from "./advertiser-routing.module";
import { SharedModule } from "src/app/shared/shared.module";
import { MainComponent } from "./main/main.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InsightsComponent } from "./insights/insights.component";
import { AdvertisingCampaignComponent } from "./advertising-campaign/advertising-campaign.component";
import { NewCampaignComponent } from "./advertising-campaign/new-campaign/new-campaign.component";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { GoogleMapsModule } from "@angular/google-maps";
import { OwlDateTimeModule } from "@danielmoncada/angular-datetime-picker";
import { Step2Component } from "./advertising-campaign/new-campaign/step2/step2.component";
import { Step3Component } from "./advertising-campaign/new-campaign/step3/step3.component";
import { Step4Component } from "./advertising-campaign/new-campaign/step4/step4.component";
import { FormComponent } from "./advertising-campaign/new-campaign/form/form.component";
import { AdStatisticsComponent } from "./insights/ad-statistics/ad-statistics.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AddFildsComponent } from "./advertising-campaign/new-campaign/form/add-filds/add-filds.component";
import { CardInsightsComponent } from "./insights/card-insights/card-insights.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { TableLatestCampaignComponent } from "./main/table-latest-campaign/table-latest-campaign.component";
import { BestAdvertisingCampaignComponent } from "./main/best-advertising-campaign/best-advertising-campaign.component";
import { Step1Component } from "./advertising-campaign/new-campaign/step1/step1.component";
import { ByZoneComponent } from "./advertising-campaign/new-campaign/step1/campaign-locations/by-zone/by-zone.component";
import { ByLocationComponent } from "./advertising-campaign/new-campaign/step1/campaign-locations/by-location/by-location.component";
import { RegionSelectionComponent } from "./advertising-campaign/new-campaign/step1/campaign-locations/region-selection/region-selection.component";
import { CampaignDatesComponent } from "./advertising-campaign/new-campaign/step1/campaign-dates/campaign-dates.component";
import { CampaignLocationsComponent } from "./advertising-campaign/new-campaign/step1/campaign-locations/campaign-locations.component";
import { PaymentTextComponent } from "./advertising-campaign/new-campaign/step4/payment-text/payment-text.component";
import { PaymentSuccessComponent } from "./advertising-campaign/payment/payment-success/payment-success.component";
import { PaymentCancelComponent } from "./advertising-campaign/payment/payment-cancel/payment-cancel.component";
import { EstimatesComponent } from './estimates/estimates.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { UpdateCampaignComponent } from './advertising-campaign/update-campaign/update-campaign.component';
import { ArchwizardModule } from "angular-archwizard";

@NgModule({
  declarations: [
    MainComponent,
    InsightsComponent,
    AdvertisingCampaignComponent,
    NewCampaignComponent,
    Step1Component,
    ByZoneComponent,
    ByLocationComponent,
    Step2Component,
    Step3Component,
    Step4Component,
    FormComponent,
    AdStatisticsComponent,
    AddFildsComponent,
    CardInsightsComponent,
    TableLatestCampaignComponent,
    BestAdvertisingCampaignComponent,
    RegionSelectionComponent,
    CampaignDatesComponent,
    CampaignLocationsComponent,
    PaymentTextComponent,
    PaymentSuccessComponent,
    PaymentCancelComponent,
    EstimatesComponent,
    InvoicesComponent,
    UpdateCampaignComponent,
  ],
  imports: [
    CommonModule,
    AdvertiserRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule,
    GoogleMapsModule,
    OwlDateTimeModule,
    NgApexchartsModule,
    DragDropModule,
    CarouselModule,
    ArchwizardModule,
  ],
})
export class AdvertiserModule {}
