import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/app/shared/directive/sortable.directive";
import { IColumn } from "src/app/shared/models/table/column.interface";
import { RoleEnumMapping } from "src/app/shared/models/user/role.interface";
import { FilterUserHistoryInterface } from "src/app/shared/models/user-history/filter-user-history.interface";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeUsersHistory,
  setUsersHistoryFilter,
} from "src/app/store/userHistory/userHistory.actions";
import { initialState } from "src/app/store/userHistory/userHistory.reducers";
import {
  usersHistoryFilterSelector,
  usersHistoryLoadingSelector,
  usersHistoryResultSelector,
} from "src/app/store/userHistory/userHistory.selectors";
import { ResultUserHistoryInterface } from "src/app/shared/models/user-history/result-user-history.interface";
import { invokeApiDistroyed } from "src/app/store/apiState.interface";

const FILTER_PAG_REGEX = /[^0-9]/g;

@Component({
  selector: "app-logger",
  templateUrl: "./logger.component.html",
})
export class LoggerComponent extends BaseComponent implements OnInit {
  toNumber(number: string): number {
    return Number(number);
  }
  formatInput(input: HTMLInputElement) {
    input.value = input.value.replace(FILTER_PAG_REGEX, "");
  }
  @ViewChildren(NgbdSortableHeader) headers!: QueryList<NgbdSortableHeader>;
  pageSize: number = 15;
  pageNumber: number = 1;
  sortedColumns: IColumn[] = [
    {
      key: "email",
      label: "admin.history.email",
      sorter: true,
    },
  ];
  columns : IColumn[] = [
    {
      key: "roleName",
      label: "admin.history.role",
      sorter: false,
    },
    {
      key: "ipAddress",
      label: "admin.history.address",
      sorter: false,
    },
    {
      key: "action",
      label: "Action",
      sorter: false,
    },
    {
      key: "endPoint",
      label: "admin.history.service",
      sorter: false,
    },
  ]
  dateColumn : IColumn[]=[
    {
      key: "creationDate",
      label: "admin.history.date",
      sorter: false,
    },
  ]
  filter: FilterUserHistoryInterface = {
    ...initialState.filter,
    pager: {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    },
  };
  loading$: Observable<boolean | null>;
  formFilter: FormGroup;
  roleEnumMapping = RoleEnumMapping;
  historyResult$: Observable<ResultUserHistoryInterface>;
  historyResult: ResultUserHistoryInterface = {
    count: 0,
    history: [],
  };
  historyFilter$: Observable<FilterUserHistoryInterface>;
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder
  ) {
    super(store);

    this.historyResult$ = this.store
      .pipe(select(usersHistoryResultSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.historyFilter$ = this.store
      .pipe(select(usersHistoryFilterSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.loading$ = this.store
      .pipe(select(usersHistoryLoadingSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.formFilter = this.fb.group({
      email: [null],
      date: [[null, null]],
    });
  }

  ngOnInit(): void {
    this.loading$.subscribe((data) => {});
    this.store.dispatch(invokeUsersHistory());

    this.historyResult$.subscribe((data) => (this.historyResult = data));

    this.historyFilter$.subscribe((filter) => {
      this.filter = filter;
      this.store.dispatch(invokeUsersHistory());
    });

    this.formFilter.valueChanges.subscribe((form) => {
      if (form.date[0] != null && form.date[1] != null) {
        this.store.dispatch(invokeApiDistroyed());
        this.store.dispatch(
          setUsersHistoryFilter({
            filter: {
              ...this.filter,
              email: form.email,
              startDate: form.date[0],
              endDate: form.date[1],
            },
          })
        );
      } else
        this.store.dispatch(
          setUsersHistoryFilter({
            filter: { ...this.filter, email: form.email },
          })
        );
    });
  }
  onSort({ column, direction }: SortEvent) {
    this.store.dispatch(invokeApiDistroyed());
    this.headers.forEach((header) => {
      if (column != header.sortable) {
        header.direction = "";
      }
    });
    var col: string | null = column;
    var sort = null;
    switch (direction) {
      case "asc":
        sort = true;
        break;
      case "desc":
        sort = false;
        break;
      default:
        sort = null;
        col = null;
    }

    this.store.dispatch(
      setUsersHistoryFilter({
        filter: {
          ...this.filter,
          sortName: column,
          isAscending: sort,
        },
      })
    );
  }
  itemsPerPageChange(pageSize: number) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      setUsersHistoryFilter({
        filter: {
          ...this.filter,
          pager: {
            ...this.filter.pager,
            pageSize: pageSize,
          },
        },
      })
    );
  }
  get changePageSize() {
    return this.filter.pager.pageSize;
  }
  set changePageSize(pageSize: number) {
    this.itemsPerPageChange(pageSize);
  }
  changePage(event: number) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      setUsersHistoryFilter({
        filter: {
          ...this.filter,
          pager: {
            ...this.filter.pager,
            pageNumber: event,
          },
        },
      })
    );
  }

  removeFilter() {
    this.formFilter.reset({
      email: "",
      date: [],
    });
    this.store.dispatch(
      setUsersHistoryFilter({
        filter: {
          ...this.filter,
          email: this.formFilter.get("formFilter")?.value,
          pager: {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
          },
        },
      })
    );
  }
}
