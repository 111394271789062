import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { BillingInterface } from "src/app/shared/models/billing/billing.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { JwtService } from "src/app/shared/services/jwt.service";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  billingSelector,
  messagseBillingSelector,
} from "src/app/store/billing/billing..selectors";
import {
  invokeBillingInfo,
  billingInfoSuccessMessage,
  createUpdateBillingInfo,
  invokeBillingInfoSuccess,
} from "src/app/store/billing/billing.actions";
import {
  invokeClienByHashedId,
  invokeUpdateClient,
  resultClientByHashedId,
  resultCreateUpdateClient,
} from "src/app/store/clientIberis/clientIberis.actions";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";
import { ResultListClientsFromIberis } from "src/app/shared/models/iberis/client/resultListClientFromIbris";
import {
  loadingClientSelector,
  resultClientByHashedIdSelector,
} from "src/app/store/clientIberis/clientIberis.selectors";
import { CreateClient } from "src/app/shared/models/iberis/client/createClient";
import { ResultCreateClient } from "src/app/shared/models/iberis/client/resultCreateClient";
import { resultCreateUpdateClientSelector } from "src/app/store/clientIberis/clientIberis.selectors";
import {
  invokeMarkEstimate,
  invokeUpdateEstimate,
} from "src/app/store/commande/commande.actions";
import { createEstimate } from "src/app/shared/models/iberis/commande/createEstimate";
import { AdDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-details.interface";
import { ResultCreateEstimate } from "src/app/shared/models/iberis/commande/resultCreateEstimate";
import { resultUpdateEstimateSelector } from "src/app/store/commande/commande.selectors";
import { Router } from "@angular/router";
import { invokeIdIberis } from "src/app/store/advertiser/advertiser.actions";
import { resultIdIberisSelector } from "src/app/store/advertiser/advertiser.selectors";
import { environment } from "src/environments/environment";
import { RoleEnum } from "src/app/shared/models/user/role.interface";
@Component({
  selector: "app-billing-info",
  templateUrl: "./billing-info.component.html",
})
export class BillingInfoComponent extends BaseComponent implements OnInit {
  @Input() totalFields: number;
  @Input() clientId: string | null;
  @Input() createEstimate: createEstimate;
  @Input() adDetails: AdDetailsInterface;
  billingInfoForm: FormGroup<any>;
  billingInfo$: Observable<BillingInterface | null>;
  billingInfo: BillingInterface;
  billingInfoSuccessMessage$: Observable<SuccessDto | null>;
  missingFieldsPercentage: number = 0;
  email: string;
  params: CommonParamsIberis = {
    lang: "fr",
    companyId: environment.IBERIS.IBERIS_COMPANY_ID,
  };
  client$: Observable<ResultListClientsFromIberis | null>;
  client: ResultListClientsFromIberis | null;
  newInformation: CreateClient;
  resultUpdateClient$: Observable<ResultCreateClient | null>;
  resultUpdateClient: ResultCreateEstimate | null;
  resultUpdateEstimate$: Observable<ResultCreateEstimate | null>;
  resultUpdateEstimate: ResultCreateEstimate | null;
  isProfile: boolean | null;
  idIberis$: Observable<string | null>;
  loading$: Observable<boolean | null>;
  loading: boolean | null;

  disableButton: boolean = true;
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private jwtService: JwtService,
    private route: Router
  ) {
    super(store);
    this.billingInfo$ = this.store
      .pipe(select(billingSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.billingInfoSuccessMessage$ = this.store
      .pipe(select(messagseBillingSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.client$ = this.store
      .pipe(select(resultClientByHashedIdSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultUpdateClient$ = this.store
      .pipe(select(resultCreateUpdateClientSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultUpdateEstimate$ = this.store
      .pipe(select(resultUpdateEstimateSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.idIberis$ = this.store
      .pipe(select(resultIdIberisSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingClientSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.billingInfoForm = this.fb.group({
      legalName: [null],
      taxRegistration: [null],
      address: [null],
      email: [null],
      phone: [null],
    });

    this.route.events.subscribe((event) => {
      this.isProfile = this.route.url.includes("/profile");
    });
  }

  ngOnInit(): void {
    this.billingInfoForm.valueChanges.subscribe((value) => {
      if (
        this.billingInfoForm.get("legalName")?.touched ||
        this.billingInfoForm.get("taxRegistration")?.touched ||
        this.billingInfoForm.get("address")?.touched
      )
        this.disableButton = false;
    });

    this.store.dispatch(resultCreateUpdateClient({ createClient: null }));
    this.store.dispatch(
      resultClientByHashedId({ resultClientByHashedId: null })
    );
    this.loading$.subscribe((data) => {
      this.loading = data;
    });
    this.idIberis$.subscribe((result) => {
      if (result) {
        this.clientId = result;
      } else {
        if (
          this.jwtService.getRoleName(this.jwtService.getToken()) ==
          RoleEnum.ROLE_ADVERTISER
        ) {
          this.store.dispatch(invokeIdIberis());
        }
      }
    });
    this.email = this.jwtService.getEmail();
    this.store.dispatch(invokeBillingInfoSuccess({ billing: null }));
    this.billingInfo$.subscribe((billing) => {
      if (billing) {
        this.billingInfo = billing;
        this.billingInfoForm.patchValue(billing);
      } else {
        this.store.dispatch(invokeBillingInfo({ email: this.email }));
      }
    });
    this.billingInfoSuccessMessage$.subscribe((message: SuccessDto | null) => {
      if (message != null) {
        this.store.dispatch(
          billingInfoSuccessMessage({ successMessage: null })
        );
        this.store.dispatch(invokeBillingInfo({ email: this.email }));
      }
    });
    this.client$.subscribe((data) => {
      if (data != null) {
        this.client = data;
        this.newInformation = {
          first_name: this.client.data.client.first_name,
          last_name: this.client.data.client.last_name,
          company: this.client.data.client.organisation,
          display_name: this.client.data.client.display_name,
          delivery_address: this.client.data.client.delivery,
          delivery_country: "QNMV",
          delivery_state: "",
          delivery_zip: "", //code postal
          bill_address: this.client.data.client.billing,
          bill_country: "QNMV",
          bill_state: "",
          bill_zip: "",
          email: this.client.data.client.email,
          fiscal_id: this.client.data.client.fiscal_id,
          phone: this.client.data.client.phone,
          note: this.client.data.client.note,
          website: this.client.data.client.website,
          activity_id: this.client.data.client.hashed_activity_id,
          currency_id: this.client.data.client.hashed_currency_id,
          deadline_id:
            this.client.data.client.hashed_default_invoice_deadline_id,
          title: this.client.data.client.title,
          reference: this.client.data.client.reference,
        };
        if (this.billingInfoForm.get("address")?.value) {
          this.newInformation.bill_address =
            this.billingInfoForm.get("address")?.value;
          this.newInformation.delivery_address =
            this.billingInfoForm.get("address")?.value;
        }
        if (this.billingInfoForm.get("legalName")?.value) {
          this.newInformation.company =
            this.billingInfoForm.get("legalName")?.value;
        }
        if (this.billingInfoForm.get("taxRegistration")?.value) {
          this.newInformation.fiscal_id =
            this.billingInfoForm.get("taxRegistration")?.value;
        }
        this.store.dispatch(
          invokeUpdateClient({
            params: this.params,
            updateClient: this.newInformation,
            idClient: this.clientId,
          })
        );
      }
    });
    this.resultUpdateClient$.subscribe((data) => {
      if (data) {
        this.loading = false;
        this.toastr.success(
          this.translate.instant("response.success." + data.status.message),
          this.translate.instant("response.successTitle")
        );
        this.store.dispatch(resultCreateUpdateClient({ createClient: null }));
        if (this.isProfile == false) {
          this.store.dispatch(
            invokeUpdateEstimate({
              params: this.params,
              createEstimate: {
                ...this.createEstimate,
                estimate_number: this.adDetails.estimateNumber.substring(this.adDetails.estimateNumber.lastIndexOf("-")+1),
              },
              estimateId: this.adDetails.estimateId,
            })
          );
        }
      }
    });
  }
  submitBilling() {
    this.store.dispatch(
      invokeClienByHashedId({ params: this.params, clientId: this.clientId })
    );
    this.store.dispatch(
      createUpdateBillingInfo({ billing: this.billingInfoForm.value })
    );
  }
}
