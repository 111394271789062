import { createReducer, on } from "@ngrx/store";
import {
  consumerAgeRangeDetails,
  consumerCountBetweenDates,
  consumerDemographiqueDetails,
  consumersGenderDetails,
  invokeBrowserFamily,
  invokeConsumerDemographicDetails,
  invokeConsumersCountInDay,
  invokeCountConsumersBetweenDates,
  invokeDeviceBrand,
  InvokeDeviceType,
  invokeOsFamily,
  setBrowserFamily,
  setDeviceBrand,
  setDeviceType,
  setOsFamily,
} from "./consumers.actions";
import { ConsumersStateInterface } from "./consumersState.interface";

export const initialState: Readonly<ConsumersStateInterface> = {
  consumerCountByDate: null,
  consumersLoading: true,
  browserFamily: null,
  deviceBrand: null,
  deviceType: null,
  osFamily: null,
  genderStatistics: null,
  genderAgeStatics: null,
  ageStatics: null,
  day : null,
  loading: false, 
  loadingGender : true
};
export const ConsumersReducer = createReducer(
  initialState,
  on(consumerCountBetweenDates, (state, { consumerCountByDate }) => ({
    ...state,
    consumerCountByDate: consumerCountByDate,
    consumersLoading: false,
  })),
  on(invokeBrowserFamily, (state, { search }) => ({
    ...state,
    loading: true,
  })),
  on(setBrowserFamily, (state, { browserFamily }) => ({
    ...state,
    browserFamily: browserFamily,
    loading: false,
  })),
  on(InvokeDeviceType, (state, { search }) => ({
    ...state,
    loading: true,
  })),
  on(setDeviceType, (state, { deviceType }) => ({
    ...state,
    deviceType: deviceType,
    loading: false,
  })),
  on(setDeviceBrand, (state, { deviceBrand }) => ({
    ...state,
    deviceBrand: deviceBrand,
    loading: false,
  })),
  on(invokeOsFamily, (state, { search }) => ({
    ...state,
    loading: true,
  })),
  on(setOsFamily, (state, { osFamily }) => ({
    ...state,
    osFamily: osFamily,
    loading: false,
  })),
  on(invokeDeviceBrand, (state) => ({
    ...state,
    loading: true,
  })),

  on(invokeCountConsumersBetweenDates, (state) => ({
    ...state,
    consumersLoading: true,
  })),
  on(consumersGenderDetails, (state, { genderStatistics }) => ({
    ...state,
    genderStatistics: genderStatistics,
  })),
  on(invokeConsumerDemographicDetails, (state, { dateFilter }) => ({
    ...state,
    loadingGender : true
  })),
  on(consumerDemographiqueDetails, (state, { genderAgeStatics }) => ({
    ...state,
    genderAgeStatics: genderAgeStatics,
    loadingGender : false
  })),
  on(consumerAgeRangeDetails, (state, { ageStatics }) => ({
    ...state,
    ageStatics: ageStatics,
  })),
  on(invokeConsumersCountInDay, (state, { day }) => ({
    ...state,
    consumersLoading : true
  })),
);
