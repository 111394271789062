<div class="p-50">
  <h3>{{ "settings.title" | translate }}</h3>
  <form class="mega-inline border-style mt-4">
    <div class="row">
      <!--Language-->
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <div class="card" style="height: 80%">
          <div class="media p-20">
            <img src="assets/identity/Language.png" alt="langage" />
            <h4 class="ps-2">{{ "settings.language" | translate }}</h4>
          </div>
          <div class="media-body px-4 pb-4">
            <div class="col-sm-12">
              <select
                class="form-select"
                [formControl]="langForm"
              >
                <option [value]="lang.key" *ngFor="let lang of languages">
                  <i class="flag-icon flag-icon-{{ lang.icon }}"></i
                  ><span> {{ lang.title | translate }} </span>
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!--Dark mode-->
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <div class="card" style="height: 80%">
          <div class="media p-20">
            <img src="assets/identity/darkMode.png" alt="langage" />
            <h4 class="ps-2">{{ "settings.darkMode.title" | translate }}</h4>
            <div class="media-body text-end icon-state">
              <label class="switch icon-state">
                <input
                  class="mode"
                  type="checkbox"
                  [checked]="dark"
                  (click)="layoutToggle('dark')"
                /><span class="switch-state"> </span>
              </label>
            </div>
          </div>
          <div class="media-body px-4 pb-4">
            <div class="col-sm-12">
              <p>
                {{ "settings.darkMode.description" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--Card full screen-->
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <div class="card" style="height: 80%">
          <div class="media p-20">
            <img src="assets/identity/fullScreen.png" alt="langage" />
            <h4 class="ps-2">{{ "settings.fullScreen.title" | translate }}</h4>
            <div class="media-body text-end icon-state">
              <label class="switch">
                <input type="checkbox" (click)="toggleFullScreen()" /><span
                  class="switch-state"
                ></span>
              </label>
            </div>
          </div>
          <div class="media-body px-4 pb-4">
            <div class="col-sm-12">
              <p>
                {{ "settings.fullScreen.description" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--Animation-->
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <div class="card" style="height: 80%">
          <div class="media p-20">
            <img
              src="assets/identity/animation.png"
                width="19px"
              alt="langage"
            />
            <h4 class="ps-2">{{ "settings.animation" | translate }}</h4>
          </div>
          <div class="media-body px-4 pb-4">
            <div class="col-sm-12">
              <select
                class="form-select"
                [formControl]="animationForm"
              >
                <option [value]="null" >{{ "settings.default" | translate }}</option>
                <option [value]="'isRight'">{{ "settings.right" | translate }}</option>
                <option [value]="'isLeft'">{{ "settings.left" | translate }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!--side bare-->
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <div class="card" style="height: 80%">
          <div class="media p-20">
            <img src="assets/identity/sideBar.png" alt="sideBar" />
            <h4 class="ps-2">{{ "settings.sideBar" | translate }}</h4>
          </div>
          <div class="media-body px-4 pb-4">
            <div class="col-sm-12">
              <div class="media" (click)="p.open()">
                <ng-template #popContent>
                  <img
                    src="/assets/identity/sidebar-top.png"
                    alt=""
                    style="
                      width: 50%;
                      margin: auto;
                      place-items: center;
                      display: grid;
                    "
                  />
                </ng-template>
                <a
                  [ngbPopover]="popContent"
                  [animation]="true"
                  placement="top"
                  triggers="manual"
                  #p="ngbPopover"
                  popoverTitle="Side Bar Top :"
                ></a>
                <label class="col-form-label">Top</label>
                <div class="media-body text-end icon-state">
                  <label class="switch">
                    <input
                      data-attr="normal-sidebar"
                      [class.d-none]="screenwidth < 991"
                      (click)="customizeSidebarType('horizontal-wrapper')"
                      [checked]="!sidebarCompactType"
                      [disabled]="!sidebarCompactType"
                      type="checkbox"
                    /><span class="switch-state"></span>
                  </label>
                </div>
              </div>
              <div class="media" (click)="side.open()">
                <ng-template #popContentSide>
                  <img
                    src="assets/identity/sidebar-left.png"
                    alt=""
                    style="
                      width: 50%;
                      margin: auto;
                      place-items: center;
                      display: grid;
                    "
                  />
                </ng-template>
                <a
                  [ngbPopover]="popContentSide"
                  [animation]="true"
                  placement="top"
                  triggers="manual"
                  #side="ngbPopover"
                  popoverTitle="Side Bar Side :"
                ></a>
                <label class="col-form-label">Side</label>
                <div class="media-body text-end icon-state">
                  <label class="switch">
                    <input
                      data-attr="compact-sidebar"
                      (click)="customizeSidebarType('compact-wrapper')"
                      [checked]="sidebarCompactType"
                      [disabled]="sidebarCompactType"
                      type="checkbox"
                    /><span class="switch-state"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Layout type-->
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
        <div class="card" style="height: 80%">
          <div class="media p-20">
            <img
              style="margin-right: 5px"
              src="assets/identity/layout.png"
              alt="layoutTyoe"
            />
            <h4 class="ps-2">{{ "settings.layout" | translate }}</h4>
          </div>
          <div class="media-body px-4 pb-4">
            <div class="col-sm-12">
              <!--<div class="d-flex justify-content-between">
                <div (click)="rtl.open()">
                  <ng-template #popContentrtl>
                    <img
                      src="assets/identity/layout-rtl.png"
                      alt=""
                      style="
                        width: 60%;
                        margin: auto;
                        place-items: center;
                        display: grid;
                      "
                    />
                  </ng-template>
                  <a
                    [ngbPopover]="popContentrtl"
                    [animation]="true"
                    placement="top"
                    triggers="manual"
                    #rtl="ngbPopover"
                    popoverTitle="Right To Left:"
                  ></a>
                  <img
                    style="margin-right: 5px"
                    src="assets/identity/rlt.png"
                    alt="rtl"
                  />
                  <label class="col-form-label">RTL</label>
                </div>
                <div class="media-body text-end icon-state">
                  <label class="switch">
                    <input
                      type="checkbox"
                      class="left-arrow"
                      id="left-arrow"
                      [checked]="LayoutTypeLTR == false"
                      [disabled]="LayoutTypeLTR == false"
                      [class.d-none]="leftArrowNone"
                      (click)="customizeLayoutType('rtl')"
                    /><span class="switch-state"></span>
                  </label>
                </div>
              </div>-->
              <div class="d-flex justify-content-between">
                <div (click)="LTR.open()">
                  <ng-template #popContentLTR>
                    <img
                      src="/assets/identity/layout-ltr.png"
                      alt=""
                      style="
                        width: 60%;
                        margin: auto;
                        place-items: center;
                        display: grid;
                      "
                    />
                  </ng-template>
                  <a
                    [ngbPopover]="popContentLTR"
                    [animation]="true"
                    placement="top"
                    triggers="manual"
                    #LTR="ngbPopover"
                    popoverTitle="Left To Right :"
                  ></a>
                  <img
                    style="margin-right: 5px"
                    src="assets/identity/ltr.svg"
                    alt="ltr"
                  />
                  <label class="col-form-label">LTR</label>
                </div>
                <div class="media-body text-end icon-state">
                  <label class="switch">
                    <input
                      type="checkbox"
                      class="right-arrow"
                      id="right-arrow"
                      [checked]="LayoutTypeLTR"
                      [disabled]="LayoutTypeLTR"
                      [class.d-none]="rightArrowNone"
                      (click)="customizeLayoutType('ltr')"
                    /><span class="switch-state"></span>
                  </label>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div (click)="box.open()">
                  <ng-template #popContentbox>
                    <img
                      src="/assets/identity/layout-box.png"
                      alt=""
                      style="
                        width: 60%;
                        margin: auto;
                        place-items: center;
                        display: grid;
                      "
                    />
                  </ng-template>
                  <a
                    [ngbPopover]="popContentbox"
                    [animation]="true"
                    placement="top"
                    triggers="manual"
                    #box="ngbPopover"
                    popoverTitle="Box :"
                  ></a>
                  <img
                    style="margin-right: 5px"
                    src="assets/identity/box.png"
                    alt="box"
                  />
                  <label class="col-form-label">Box</label>
                </div>
                <div class="media-body text-end icon-state">
                  <label class="switch">
                    <input
                      type="checkbox"
                      [checked]="LayoutTypeLTR == null"
                      [disabled]="LayoutTypeLTR == null"
                      (click)="customizeLayoutType('box-layout')"
                    /><span class="switch-state"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
