import { createAction, props } from "@ngrx/store";
import { PricingInterface } from "src/app/shared/models/location-partners/pricing";
import { CalculateCost } from "src/app/shared/models/calcul/calculateCost";
import { TotalCostWithDiscount } from "src/app/shared/models/calcul/totalCost";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { CreateLPInterface } from "src/app/shared/models/location-partners/createLP";
import { SelectedLps } from "src/app/shared/models/location-partners/selectedLps";
import { LocationPartnerInterface } from "src/app/shared/models/location-partners/locationPartner.interface";
import { CalculateViews } from "src/app/shared/models/calcul/calculateViews";
import { GrantPremiumAccess } from "src/app/shared/models/location-partners/GrantPremiumAccess";
import { UpdatePaymentLP } from "src/app/shared/models/update-payment-LP.interface";
import { PricingMethod } from "src/app/shared/enum/pricing-method";
import { LocationPartnersFilterInterface } from "src/app/shared/models/location-partners/locationPartnersFilter.interface";
import { AdvertisingCampaignsPaymentLP } from "src/app/shared/models/advertising-campaigns/advertising-campaigns-payment-LP.interface";

export const invokeCreateLocationPartner = createAction(
  "[Invoke Create LocationPartner] Invoke create LocationPartner",
  props<{ createLocationPartner: CreateLPInterface }>()
);
export const createLocationPartnerSuccess = createAction(
  "[Create Location Partner Success] Create Location Partner success",
  props<{ successMessage: SuccessDto | null }>()
);


export const invokeDeleteLocationPartner = createAction(
  "[Invoke Delete Location Partner] Invoke Delete Location Partner",
  props<{ email: string }>()
);
export const deleteLocationPartnerSuccess = createAction(
  "[Delete LocationPartner Success] Delete LocationPartner Success",
  props<{ successMessage: SuccessDto | null }>()
);


export const invokeCalculateTotalLPCostByCampanyNames = createAction(
  "[ Invoke Calculate Total Location Partners Cost By Campany Names] Invoke Calculate Total Location Partners Cost By Campany Names",
  props<{ calculateCost: CalculateCost}>()
);
export const calculateTotalLPCostByCampanyNames = createAction(
  "[ Calculate Total Location Partners Cost By Campany Names ] Calculate total location partners cost by campany names",
  props<{ totalCostWithDiscount: TotalCostWithDiscount | null}>()
);


export const invokePricingByCompanyName = createAction(
  "[ Invoke Location Partner Pricing By CompanyName ] Invoke Location Partner Pricing By CompanyName",
  props<{ companyName: string }>()
);
export const setPricingByCompanyName = createAction(
  "[ set Location Partner Pricing By CompanyName ] set Location Partner Pricing By CompanyName",
  props<{ pricing: PricingInterface | null }>()
);
/*  export const invokeLPdetails = createAction(
  "[ Invoke LP Details ] Invoke lp details ",
  props<{ lpDetails: LPdetails | null }>()
); 
 */


export const invokeLocationPartnersByZone = createAction(
  "[ Invoke Location Partners By Region ] Invoke Location Partners by Region",
  props<{ regionNames: string[] }>()
);
export const resultLocationPartnerByRegion = createAction(
  "[ Result Location Partners By Region ] Result lps by Region",
  props<{ lps: string[][] | null }>()
);

export const selectedLPs = createAction(
  "[ Save Selected LPs ] Save selected LPs ",
  props<{ selectedLps: SelectedLps | null }>()
);

// calute Views
export const calculateViewsByCompany = createAction(
  "[ Calculate Views By Company ] Calculate views by company",
  props<{resultViews : CalculateViews | null}>()
) 

export const invokeGrantPremiumAccessForLP = createAction(
  "[ Invoke Grant Premium Access For LP ] Invoke grant premium access for LP",
  props<{grantPremiumAccess : GrantPremiumAccess}>()
)
export const resultGrantPremiumAccessForLP = createAction(
  "[ Result Grant Premium Access For LP ] Result grant premium access for LP",
  props<{successMessage : SuccessDto | null}>()
)

export const invokeUpdatePaymentStatusLP = createAction(
  "[ Invoke Update Payment Status LP ] Invoke Payment Status LP",
  props<{updatePaymentStatusLP : UpdatePaymentLP | null}>()
)
export const resultSuccess = createAction(
  "[ Result Success Message ] Result success message ",
  props<{successMessage : SuccessDto | null}>()
)

export const invokeCalculateViewsOrUniqueViewsBasedOnNotPaidAds = createAction(
  "[ Invoke Calculate Views Or Unique Views Based On Not Paid Ads ] Invoke calculate views or unique views based on not paid ads",
  props<{pricingMethode : PricingMethod | null}>()
)
export const resultCalculateViewsOrUniqueViewsBasedOnNotPaidAds = createAction(
  "[ Result Calculate Views Or Unique Views Based On Not Paid Ads ] Result calculate views or unique views based on not paid ads",
  props<{views : number | null}>()
)

export const invokeLocationPartnerAdvertisingCampignFilter = createAction(
  "[ Invoke Location Partner's Advertisign Campaigns filter for payment ] Invoke location partner's advertisign campaigns filter for payment",
  props<{locationPartnerCampaignsFilter: LocationPartnersFilterInterface | null , isWithViews : boolean}>()
)
export const resultLocationPartnerAdvertisingCampignFilter = createAction(
  "[ Result Location Partner's Advertisign Campaigns filter for payment ] Result location partner's advertisign campaigns filter for payment",
  props<{advertisingCampaignsPaymentLP : AdvertisingCampaignsPaymentLP | null}>()
)

export const saveSelectedLocationsForMap = createAction(
  "[ Save Selected locations for map ] Save selected location for map ",
  props<{ locations: string[] | null }>()
);