import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdvertisingCampaignComponent } from "./advertising-campaign/advertising-campaign.component";
import { InsightsComponent } from "./insights/insights.component";
import { MainComponent } from "./main/main.component";
import { ProfileComponent } from "../../shared/components/profile/profile.component";
import { AdStatisticsComponent } from "./insights/ad-statistics/ad-statistics.component";
import { BugReportComponent } from "src/app/shared/components/bug-report/bug-report.component";
import { CreateBugReportComponent } from "src/app/shared/components/bug-report/create-bug-report/create-bug-report.component";
import { PaymentSuccessComponent } from "./advertising-campaign/payment/payment-success/payment-success.component";
import { PaymentCancelComponent } from "./advertising-campaign/payment/payment-cancel/payment-cancel.component";
import { NewCampaignComponent } from "./advertising-campaign/new-campaign/new-campaign.component";
import { DetailsDiscutionSupportComponent } from "src/app/shared/components/bug-report/details-discution-support/details-discution-support.component";
import { EstimatesComponent } from "./estimates/estimates.component";
import { InvoicesComponent } from "./invoices/invoices.component";
import { UpdateCampaignComponent } from "./advertising-campaign/update-campaign/update-campaign.component";

var routingAnimation = localStorage.getItem("animate");

const routes: Routes = [
  {
    path: "",
    redirectTo: "main",
    pathMatch: "full",
  },
  {
    path: "main",
    component: MainComponent,
    data: {
      animation: [routingAnimation],
      title: 'Overview | Relead ',
    },
  },
  {
    path: "support",
    component: BugReportComponent,
    data: {
      animation: [routingAnimation],
      title: 'Report a technical problem | Relead',
    },
  },
  {
    path: "insights",
    component: InsightsComponent,
    data: {
      animation: [routingAnimation],
      title: 'Advertising insights | Relead ',
    },
  },
  {
    path: "profile",
    component: ProfileComponent,
    data: {
      animation: [routingAnimation],
      title: 'Company information | Relead ',
    },
  },
  {
    path: "orders",
    component: EstimatesComponent,
    data: {
      animation: [routingAnimation],
      title: "Advertiser estimates list | Relead ",
    },
  },
  {
    path: "payments",
    component: InvoicesComponent,
    data: {
      animation: [routingAnimation],
      title: "Advertiser invoices list | Relead ",
    },
  },
  {
    path: "advertising-campaigns/create-ad",
    component: AdvertisingCampaignComponent,
    data: {
      animation: [routingAnimation],
      title: 'Add new campaign | Relead ',
    },
  },
  {
    path: "advertising-campaigns/create-ad/:objective",
    component: NewCampaignComponent,
    data: {
      animation: [routingAnimation],
      title: 'Add new campaign | Relead ',
    },
  },
  {
    path: "advertising-campaigns/update-ad/:campaignHasedID",
    component: UpdateCampaignComponent,
    data: {
      animation: [routingAnimation],
      title: 'Update advertising campaign | Relead  ',
    },
  },
  {
    path: "advertising-campaigns/create-ad/payment/:campaignHasedID",
    component: NewCampaignComponent,
    data: {
      animation: [routingAnimation],
      title: 'Add new campaign | Relead ',
    },
  },
  {
    path: "advertising-campaigns/payment-success",
    component: PaymentSuccessComponent,
    data: {
      animation: [routingAnimation],
    },
  },
  {
    path: "advertising-campaigns/payment-cancel",
    component: PaymentCancelComponent,
    data: {
      animation: [routingAnimation],
    },
  },
  {
    path: "insights/statistics/:campaignHasedID",
    component: AdStatisticsComponent,
    data: {
      animation: [routingAnimation],
      title: 'Advertising statistics | Relead  ',
      description: " ",
    },
  },
  {
    path: "support/createBugReport",
    component: CreateBugReportComponent,
    data: {
      animation: [routingAnimation],
      title: 'Create bug report | Relead  ',
    },
  },
  {
    path: "support/details",
    component: DetailsDiscutionSupportComponent,
    data: {
      queryParams: {
        ref: "ref",
      },
      animation: [routingAnimation],
      title: 'See the details of the discussion | Relead  ',
    },
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdvertiserRoutingModule {}
