<span class="d-flex align-items-center">
  <div
    class="loader-box m-r-10"
    style="height: auto !important; float: left"
    *ngIf="loading"
  >
    <div class="loader-button-34"></div>
  </div>
  <i *ngIf="!loading && icon!=null" [class]="icon" class="m-r-10"></i>
  <div style="float: right">{{ value | translate }}</div>
</span>
