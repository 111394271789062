<form [formGroup]="form">
  <div *ngIf="isUpdate">
    <div class="form-group">
      <label class="label"
        >{{ "admin.locations.repository.adminDisplay" | translate }}*</label
      >
      <select class="form-select form-control" formControlName="showingAdmin">
        <option [ngValue]="null" disabled selected>
          {{ "admin.locations.repository.adminDisplay" | translate }}
        </option>
        <option *ngFor="let item of display" [value]="item.id">
          {{ item.value | translate }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label class="label"
        >{{ "admin.locations.repository.advertiserDisplay" | translate }}*</label
      >
      <select class="form-select form-control" formControlName="showingAdvertiser">
        <option [ngValue]="null" disabled selected>
          {{ "admin.locations.repository.advertiserDisplay" | translate }}
        </option>
        <option *ngFor="let item of display" [value]="item.id">
          {{ item.value | translate }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label class="label"
        >{{ "admin.locations.repository.expenses" | translate }}*</label
      >
      <div class="input-group">
        <input
          formControlName="expenses"
          class="form-control"
          type="text"
          required=""
          [placeholder]="
            'addLP.partnerStatus.placeholderExpenses' | translate
          "
        />
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="label">{{ "addLP.partnerStatus.title" | translate }}*</label>
    <div class="input-group">
      <select class="form-select form-control" formControlName="partnerStatus">
        <option [ngValue]="null" disabled selected>
          {{ "addLP.partnerStatus.selectPartner" | translate }}
        </option>
        <option *ngFor="let item of partnerStatus" [ngValue]="item.id">
          {{ item.value | translate }}
        </option>
      </select>
    </div>
  </div>
  <div *ngIf="getPartnerStatus == partnerStatusEnum.REVENUE_SHARING">
    <div class="form-group">
      <label class="label"
        >{{ "addLP.partnerStatus.PilotPhase" | translate }}*</label
      >
      <input
        formControlName="date"
        class="form-control"
        type="text"
        name="daterange"
        [placeholder]="'placehorderPickDate' | translate"
        [selectMode]="'range'"
        [owlDateTimeTrigger]="ranges"
        [owlDateTime]="ranges"
      />
      <owl-date-time [pickerType]="'calendar'" #ranges></owl-date-time>
    </div>
    <div *ngIf="isUpdate">
      <div class="form-group">
        <label class="label"
          >{{ "admin.locations.repository.price" | translate }}*</label
        >
        <div class="input-group">
          <input
            formControlName="price"
            class="form-control"
            type="text"
            required=""
            [placeholder]="'admin.locations.repository.price' | translate"
          />
        </div>
      </div>
      <div class="form-group">
        <label class="label"
          >{{ "admin.locations.repository.pricingMethod" | translate }}*</label
        >
        <select
          class="form-select form-control"
          formControlName="pricingMethod"
        >
          <option [ngValue]="null" disabled selected>
            {{ "addLP.partnerStatus.selectPricingMethod" | translate }}
          </option>
          <option *ngFor="let item of pricingMethod" [value]="item.id">
            {{ item.value | translate }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div
    class="form-group"
    *ngIf="
      isUpdate ||
      getPartnerStatus == partnerStatusEnum.INTERNET_PROVIDER_PAYMENT
    "
  >
    <label class="label"
      >{{ "admin.locations.repository.priceFrequency" | translate }}*</label
    >
    <select class="form-select form-control" formControlName="paymentFrequency">
      <option [ngValue]="null" disabled selected>
        {{ "addLP.partnerStatus.selectPaymentFrequency" | translate }}
      </option>
      <option *ngFor="let item of paymentFrequency" [value]="item.id">
        {{ item.value | translate }}
      </option>
    </select>
  </div>
</form>
