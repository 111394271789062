import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { CommandeService } from "src/app/shared/services/commande.service";
import { invokeCreateEstimate, invokeDownloadEstimate, invokeInvoiceEstimate, invokeListOrdersFromIberis, invokeListOrdersFromIberisByStatus, invokeListOrdersFromIberisByStatusForSpecificAdvertiser, invokeListOrdersFromIberisForSpecificAdvertiser, invokeMarkEstimate, invokeSynthesisEstimate, invokeUpdateEstimate, resultCreateEstimate, resultEstimate, resultInvoiceEstimate, resultListOrdersFromIberis, resultSynthesisEstimate, resultUpdateEstimate } from "./commande.actions";
import { setAPIStatus } from "../apiState.interface";
import { ResultListOrdersFromIberis } from "src/app/shared/models/iberis/commande/resultListOrdersFromIberis";
import { ResultCreateEstimate } from "src/app/shared/models/iberis/commande/resultCreateEstimate";
import { ResultInvoiceEstimate } from "src/app/shared/models/iberis/resultInvoiceEstimate";
import { MarkEstimate } from "src/app/shared/models/iberis/commande/validEstimate";
import { resultMarkEstimate } from "./commande.actions";
import { ResultSynthesisEstimate } from "src/app/shared/models/iberis/commande/resultSynthesisEstimate";
@Injectable()
export class CommandeEffect {
  constructor(
    private actions$: Actions,
    private commandeService: CommandeService,
  ) {}

invokeListOrdersFromIberis$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeListOrdersFromIberis),
    mergeMap((data) => {
      return this.commandeService.filterListOrdersFromIberis(data.params,data.start,data.length, data.draw).pipe(
        map((data: ResultListOrdersFromIberis) =>
        resultListOrdersFromIberis({ resultListOrdersFromIberis: data })
        ),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error,
                apiStatus: "error",
              },
            })
          )
        )
      );
    })
  )
);
invokeListOrdersFromIberisByStatus$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeListOrdersFromIberisByStatus),
    mergeMap((data) => {
      return this.commandeService.filterListOrdersFromIberisByStatus(data.params, data.status,data.start,data.length, data.draw).pipe(
        map((data: ResultListOrdersFromIberis) =>
        resultListOrdersFromIberis({ resultListOrdersFromIberis: data })
        ),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error,
                apiStatus: "error",
              },
            })
          )
        )
      );
    })
  )
);

invokeDownlaodEstimate$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeDownloadEstimate),
    mergeMap((data) => {
      return this.commandeService.downloadEstimate(data.params, data.estimateId).pipe(
        map((data: any) =>
        resultEstimate({ estimate: data })
        ),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error,
                apiStatus: "error",
              },
            })
          )
        )
      );
    })
  )
);
invokeListOrdersFromIberisForSpecificAdvertiser$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeListOrdersFromIberisForSpecificAdvertiser),
    mergeMap((data) => {
      return this.commandeService.filterListOrdersFromIberisForSpecificAdvertiser(data.params, data.contactHashedId ,data.start,data.length, data.draw).pipe(
        map((data: ResultListOrdersFromIberis) =>
        resultListOrdersFromIberis({ resultListOrdersFromIberis: data })
        ),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error,
                apiStatus: "error",
              },
            })
          )
        )
      );
    })
  )
);
invokeListOrdersFromIberisByStatusForSpecificAdvertiser$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeListOrdersFromIberisByStatusForSpecificAdvertiser),
    mergeMap((data) => {
      return this.commandeService.filterListOrdersFromIberisByStatusForSpecificAdvertiser(data.params,data.contactHashedId, data.status,data.start,data.length, data.draw).pipe(
        map((data: ResultListOrdersFromIberis) =>
        resultListOrdersFromIberis({ resultListOrdersFromIberis: data })
        ),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error,
                apiStatus: "error",
              },
            })
          )
        )
      );
    })
  )
);

invokeCreateEstimate$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeCreateEstimate),
    mergeMap((data) => {
      return this.commandeService.createEstimate(data.params,data.createEstimate).pipe(
        map((data: ResultCreateEstimate | null) =>
        resultCreateEstimate({ resultCreateEStimate: data })
        ),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error,
                apiStatus: "error",
              },
            })
          )
        )
      );
    })
  )
);

invokeInvoiceEstimate$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeInvoiceEstimate),
    mergeMap((data) => {
      return this.commandeService.invoiceEstimate(data.params,data.estimateId).pipe(
        map((data: ResultInvoiceEstimate | null) =>
        resultInvoiceEstimate({ resultInvoiceEstimate: data })
        ),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error,
                apiStatus: "error",
              },
            })
          )
        )
      );
    })
  )
);
invokeUpdateEstimate$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeUpdateEstimate),
    mergeMap((data) => {
      return this.commandeService.updateEstimate(data.params,data.createEstimate, data.estimateId).pipe(
        map((data: ResultCreateEstimate | null) =>
        resultUpdateEstimate({ resultUpdateEstimate: data })
        ),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error,
                apiStatus: "error",
              },
            })
          )
        )
      );
    })
  )
);

invokeMarkEstimate$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeMarkEstimate),
    mergeMap((data) => {
      return this.commandeService.markEstimate(data.params,data.estimateId).pipe(
        map((data: MarkEstimate | null) =>
        resultMarkEstimate({ markEstimate: data })
        ),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error,
                apiStatus: "error",
              },
            })
          )
        )
      );
    })
  )
);

invokeSynthesisEstimate$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeSynthesisEstimate),
    mergeMap((data) => {
      return this.commandeService.synthesisEstimate(data.params,data.estimateId).pipe(
        map((data: ResultSynthesisEstimate | null) =>
        resultSynthesisEstimate({resultSynthesisEstimate: data })
        ),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error,
                apiStatus: "error",
              },
            })
          )
        )
      );
    })
  )
);
}
