<div class="media profile-media">
  <i [ngClass]="'flag-icon flag-icon-' + actualLang.icon"></i>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li *ngFor="let lang of languages" (click)="switchLang(lang)">
    <a
      ><i class="flag-icon flag-icon-{{ lang.icon }}"></i
      ><span> {{ lang.title | translate }} </span></a
    >
  </li>
</ul>
