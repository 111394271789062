import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const subCategoryState = (state: AppStateInterface) =>
  state.subCategoryState;

export const allSubCategoryNamesSelector = createSelector(
  subCategoryState,
  (state) => state.subcategoryNames
);

export const loadingSubCategorySelector = createSelector(
  subCategoryState,
  (state) => state.loading
);
export const subCategoriesByCategoryNameSelector = createSelector(
  subCategoryState,
  (state) => state.subCategoriesByCategoryName
)


export const allSubCategoriesSelector = createSelector(
  subCategoryState,
  (state) => state.subCategories
);

export const createUpdateSubCategorySuccessSelector = createSelector(
  subCategoryState,
  (state) => state.createUpdateSuccess
);

export const deleteSubCategorySuccessSelector = createSelector(
  subCategoryState,
  (state) => state.deleteSuccess
);

export const selectedSubCategoriesSelector = createSelector(
  subCategoryState,
  (state) => state.selectedSubCategories
)