import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-video-ad",
  templateUrl: "./video-ad.component.html",
})
export class VideoAdComponent implements OnInit {
  @Input() public videoLink: string;
  @Output() closeTheModal: EventEmitter<boolean> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  closeModal() {
    this.closeTheModal.emit(true);
  }
}
