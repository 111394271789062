<div class="modal-header justify-content-start">
  <h4 class="modal-title" id="exampleModalLabel">
    {{ title | translate }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="onClickClose()"></button>
</div>
<div class="modal-body" style="padding-left: 2rem;">
  <ng-template #none>
    {{"undefined" | translate}}
  </ng-template>
  <div>
    <h6 style="color: #ff69b4">
      <U>{{ "addRouter.routerSpecificationStep.title" | translate }}</U>
    </h6>
    <div class="grid-column">
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "routers.userName" | translate }}
      </h5>
      <p class="m-b-0" *ngIf="router.userName; else none">{{ router.userName }}</p>
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "routers.password" | translate }}
      </h5>
      <p class="m-b-0">{{ router.password ?? "undefined" | translate }}</p>
    </div>
  </div>
  <div style="margin-top: 10px">
    <h6 style="color: #ff69b4">
      <U>{{ "modalDetails.personalDetails" | translate }}</U>
    </h6>
    <div class="grid-column">
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "routers.ip" | translate }}
      </h5>
      <p class="m-b-0">{{ router.ip ?? "undefined" | translate}}</p>
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "routers.@mac" | translate }}
      </h5>
      <p class="m-b-0" *ngIf="router.mac !=''; else none">
        {{ router.mac }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "routers.dateOfPurshase" | translate }}
      </h5>
      <p class="m-b-0">
        {{ (router.dateOfPurchase | localizedDate) ?? "--/--" }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "routers.reference" | translate }}
      </h5>
      <p class="col-5 margin-p">{{ router.reference ?? "undefined" | translate}}</p>
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "routers.sessiontimeOut" | translate }}
      </h5>
      <p class="m-b-0">
        {{sessionTimeOut(router.sessionTimeOut)}}
      </p>
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "routers.acquisitionPrice" | translate }}
      </h5>
      <p class="m-b-0">
        {{ router.acquisitionPrice ?? 0}}
      </p>
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "routers.reverseTunnel" | translate }}
      </h5>
      <p class="m-b-0">
        {{ router.reverseTunnel ?? 0}}
      </p>
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "routers.serialNumber" | translate }}
      </h5>
      <p class="m-b-0">
        {{ router.serialNumber ?? "undefined" | translate }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "routers.ssid" | translate }}
      </h5>
      <p class="m-b-0">
        {{ router.ssid ?? "undefined" | translate}}
      </p>
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "routers.status" | translate }}
      </h5>
      <p class="m-b-0">
        <span
          [ngClass]="
            'badge ' + badgeService.getBadgeByRouterStatus(router.status)
          "
        >
          {{ "enumMapping.router." + router.status | translate }}
        </span>
      </p>
    </div>
  </div>
  <div style="margin-top: 10px">
    <h6 style="color: #ff69b4">
      <U>{{ "addRouter.locationInformation.title" | translate }}</U>
    </h6>
    <div class="grid-column">

      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "routers.productionDate" | translate }}
      </h5>
      <p class="m-b-0">
        {{ (router.productionDate | localizedDate) ?? "--/--" }}
      </p>
      <h5 class="f-w-600" style="font-size: 12px;">
        {{ "routers.locations" | translate }}
      </h5>
      <p class="m-b-0">{{ router.locationPartner ?? "undefined" | translate  }}</p>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    (click)="onClickClose()"
    class="btn btn-outline-primary"
    data-bs-dismiss="modal"
    #closeModal
  >
    {{ "buttons.close" | translate }}
  </button>
</div>