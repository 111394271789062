import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../../data/constants";
import { CompanyData } from "../../models/iberis/companyData";
import { CommonParamsIberis } from "../../models/iberis/paramsForGetAll";

@Injectable({
  providedIn: "root",
})
export class CompanyIberisService {
  constructor(private http: HttpClient) {}

  getIberisCompanyData(params: CommonParamsIberis): Observable<CompanyData> {
    return this.http.get<CompanyData>(
      `${
        environment.APP_PORT_IBERIS +
        params.lang +
        Constants.IBERIS_END_POINT +
        params.companyId
      }/data`
    );
  }
}
