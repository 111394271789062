<div class="card">
  <div class="card-header">
    <h4 class="m-t-15">{{ "dashboard.titleLastAds" |translate }}</h4>
  </div>
  <div class="card-body pt-0">
    <div class="table-responsive data-table" style="font-size: 12px">
      <table class="table table-striped table-border-vertical">
        <thead>
          <tr>
            <ng-container *ngFor="let column of columns">
              <th>
                {{ column.label | translate }}
              </th>
            </ng-container>
            <th>Action</th>
          </tr>
        </thead>
        <tbody *ngIf="!(loading$ | async)">
          <tr *ngFor="let ad of advertisingCampaing; let j = index">
            <td>{{ ad.title }}</td>
            <td class="img-content-box">
              <i class="icon-eye"></i>
              {{ ad.views }}
            </td>
            <td class="img-content-box">
              <ng-container
                *ngIf="
                  listAdvertisingCampaignsUniqueViews.length != 0;
                  else loadingViews
                "
              >
                <i class="icon-eye"></i>
                {{ listAdvertisingCampaignsUniqueViews[j] }}
              </ng-container>
              <ng-template #loadingViews>
                <div class="loader-box">
                  <div class="loader-34"></div>
                </div>
              </ng-template>
            </td>
            <td>
              <span
                [ngClass]="
                  'badge ' +
                  badgeService.getBadgeForAdvertisingCampaingStatus(ad.status)
                "
              >
                {{ "enumMapping.campaigns.status."+ad.status | translate }}
              </span>
            </td>
            <td>{{ ad.startingDate | localizedDate }}</td>
            <td>{{ ad.endingDate | localizedDate }}</td>
            <td>
              <span
                [ngClass]="
                  'badge ' +
                  badgeService.getBadgeForLPRenumeration(getRenumeration(ad))
                "
              >
                {{renumerationEnumMapping[getRenumeration(ad)] | translate }}
              </span>
            </td>
            <td *ngIf="renumerationEnumMapping[getRenumeration(ad)] == renumerationEnum.RENUMERATED; else notRenumerated">
              {{
                (pricing.pricingMethod == "VIEWS"
                  ? ad.views * pricing.price
                  : (listAdvertisingCampaignsUniqueViews[j]) * pricing.price).toFixed(3)
              }}
              TND
            </td>
            <ng-template #notRenumerated>
              <td>
                0.000 TND
              </td>
            </ng-template>
            <td>
              <button
                class="btn btn-outline-primary"
                (click)="onClickshowModal(ad.videoLink)"
              >
                {{ "buttons.preview" | translate}}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="advertisingCampaing.length == 0 && !(loading$ | async);" class="d-flex justify-content-center m-t-20">
        {{"noData" | translate}}
      </div>
      <div *ngIf="loading$ | async">
        <ng-container style="float: right" class="flex">
          <div class="loader-box">
            <div class="loader-34"></div>
          </div>
          <h6 class="f-w-600" style="text-align: center">
            {{ "fetching" | translate }}
          </h6>
        </ng-container>
      </div>
    </div>
  </div>
</div>
