import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { setAPIStatus } from "../apiState.interface";
import { BillingService } from "src/app/shared/services/billing.service";
import { billingInfoSuccessMessage, createUpdateBillingInfo, invokeBillingInfo, invokeBillingInfoSuccess,  } from "./billing.actions";
import { BillingInterface } from "src/app/shared/models/billing/billing.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";

@Injectable()
export class BillingEffect {
  constructor(
    private actions$: Actions,
    private billingService: BillingService
  ) {}
  invokeBillingInfo$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeBillingInfo),
    mergeMap((state) => {
      return this.billingService.getBillingInfo(state.email).pipe(
        map((data: BillingInterface| null ) => invokeBillingInfoSuccess({ billing: data })),
        catchError((error) =>
          of(
            setAPIStatus({
              apiStatus: {
                apiResponseMessage: error,
                apiStatus: "error",
              },
            })
          )
        )
      );
    })
  )
);


  createBillingInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createUpdateBillingInfo),
      mergeMap((state) => {
        return this.billingService.createUpdateBillingInfo(state.billing).pipe(
          map((data: SuccessDto| null ) => billingInfoSuccessMessage({ successMessage: data })),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
}
