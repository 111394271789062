import { createReducer, on } from "@ngrx/store";
import { invokeUpdateClient, resultClientByHashedId, resultCreateUpdateClient } from "./clientIberis.actions";
import { ClientIberisStateInterface } from "./clientIberisState.interface";

export const initialState: Readonly<ClientIberisStateInterface>={
    resultClientByHashedId : null,
    createClient : null,
    loading : false
}
export const ClientIberisReducer = createReducer(
    initialState,
    on(resultClientByHashedId, (state , {resultClientByHashedId})=>({
        ...state,
        resultClientByHashedId : resultClientByHashedId
    })),
    on(invokeUpdateClient, (state , {})=>({
        ...state,
        loading : true
    })),
    on(resultCreateUpdateClient, (state , {createClient})=>({
        ...state,
        createClient : createClient, 
        loading: false
    }))
)