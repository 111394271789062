import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const promotionCodeState = (state: AppStateInterface) =>
  state.promotionCodeState;

export const loadingPromotionCodeSelector = createSelector(
  promotionCodeState,
  (state) => state.loading
);
export const promotionCodeFilterSelector = createSelector(
  promotionCodeState,
  (state) => state.promotionCodeFilter
);

export const promotionCodesResultSelector = createSelector(
  promotionCodeState,
  (state) => state.promotionCodesResult
);
export const promotionCodeSuccessMessageSelector = createSelector(
  promotionCodeState,
  (state) => state.successMessage
);

export const checkPromotionCodeSuccessSelector = createSelector(
  promotionCodeState,
  (state) => state.checkPromotionCodeSuccess
);
export const checkPromotionCodeErrorSelector = createSelector(
  promotionCodeState,
  (state) => state.checkPromotionCodeError
);

export const getAdHavePromotionCodeSelector = createSelector(
  promotionCodeState,
  (state) => state.havePromotion
);

export const applyPromotionCodeSuccessSelector = createSelector(
  promotionCodeState,
  (state) => state.applyPromotionCodeSuccess
);
