import { HttpEvent, HttpResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";

export const invokeUploadFile = createAction(
  "[Invoke Upload File] Invoke upload file",
  props<{ files: FormData}>()
);

export const setFileName = createAction(
  "[Upload File] upload file",
  props<{ filename: string | null}>()
);


export const invokeUploadFileWithProgress = createAction(
  "[Invoke Upload File With Progress] Invoke upload file with progress",
  props<{ file: FormData}>()
);

export const setFileProgress = createAction(
  "[Upload File] upload file",
  props<{ progress: HttpEvent<any>| HttpResponse<any> | null}>()
);

export const invokeDownloadFile = createAction(
  "[ Download File ] Download file",
  props<{filename : string | null}>()
)
export const resultDownloadFile = createAction(
  "[ Download file ] result download file",
  props<{downloadedFile: HttpEvent<Blob> | null}>()
)
