<div class="container-fluid">
  <div class="row">
    <form [formGroup]="form">
      <!--step1-->
      <app-step1
        *ngIf="step == 1"
        [form]="form"
        [objectiveName]="objectiveName"
        [hidden]="isShown"
      ></app-step1>
      <div
        *ngIf="
          adDetails.objectiveName != objectiveTypeEnum.GENERATION_DE_PROSPECTS;
          else prospect
        "
      >
        <!--step2-->
        <div *ngIf="step == 2">
          <app-step2
            [objectiveName]="objectiveName"
            [webSite]="formStep2.get('webSite')?.value"
            (valueChanged)="handleValueChanged($event)"
            (fileName)="handleFileName($event)"
            [hidden]="isShown"
          ></app-step2>
        </div>

        <!--step3-->
        <div *ngIf="step == 3">
          <app-step3 [idIberis]="idIberis" [condition]="condition" [campaignHashedID]="campaignHashedID"></app-step3>
        </div>

        <!--step4-->
        <div *ngIf="step == 4">
          <app-step4
            [clientId]="idIberis"
            [createEstimate]="createEstimate"
            [campaignHashedId]="campaignHashedID"
            (resultPaymentMethod)="resultPaymentMethod($event)"
            (emitTotalCost)="totalCostFunction($event)"
          ></app-step4>
        </div>
      </div>
    </form>
    <ng-template #prospect>
      <div *ngIf="step == 2">
        <app-form
          [defaultItems]="defaultItems"
          [adTitle]="adDetails.title"
          (resultLeadGenerationForm)="saveLead($event)"
          [hidden]="isShown"
        ></app-form>
      </div>
      <!--step3-->
      <div *ngIf="step == 3">
        <app-step2
          [objectiveName]="objectiveName"
          [webSite]="form.get('videoLink')?.value"
          (valueChanged)="handleValueChanged($event)"
          [hidden]="isShown"
        ></app-step2>
      </div>
      <!--step4-->
      <div *ngIf="step == 4">
        <app-step3 [idIberis]="idIberis" [condition]="condition" [campaignHashedID]="campaignHashedID"></app-step3>
      </div>
      <!--step5-->
      <div *ngIf="step == 5">
        <app-step4
          [clientId]="idIberis"
          [createEstimate]="createEstimate"
          [campaignHashedId]="campaignHashedID"
          (resultPaymentMethod)="resultPaymentMethod($event)"
          (emitTotalCost)="totalCostFunction($event)"
        ></app-step4>
      </div>
    </ng-template>
  </div>
  <!--stepper-->
  <div class="col-12 card-fo d-flex">
    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="previous()"
      [disabled]="isPublish && step ==1"
    >
      {{ "buttons.return" | translate }}
    </button>
    <ul id="progressbar">
      <li
        [class.active]="step == 1 || step == 2 || step == 3 || step == 4"
      ></li>
      <li [class.active]="step == 2 || step == 3 || step == 4"></li>
      <li [class.active]="step == 3 || step == 4"></li>
      <li [class.active]="step == 4"></li>
    </ul>
    <button
      class="btn btn-primary"
      data-bs-toggle="tooltip"
      placement="top"
      [ngbTooltip]="
        !checkValidation()
          ? ('newCampaign.unvalidNext' | translate)
          : ('newCampaign.validNext' | translate) + (step + 1)
      "
      *ngIf="step == 1"
      type="button"
      [disabled]="!form.valid"
      (mouseenter)="checkValidation()"
      (click)="next()"
    >
      {{ "buttons.next" | translate }}
    </button>
    <button
      class="btn btn-primary"
      data-bs-toggle="tooltip"
      placement="top"
      [ngbTooltip]="
        step == 2
          ? ('newCampaign.toPurchaseOrder' | translate)
          : ('newCampaign.toPaiment' | translate)
      "
      *ngIf="
        step >= 2 &&
        step < finalStep &&
        adDetails.objectiveName == objectiveTypeEnum.WEB_SITE_TRAFFIC
      "
      type="button"
      (mouseenter)="checkValidation()"
      (click)="next()"
      [disabled]="!formStep2.valid && step == 2"
    >
      {{ "buttons.next" | translate }}
    </button>
    <button
      class="btn btn-primary"
      data-bs-toggle="tooltip"
      placement="top"
      [ngbTooltip]="
        step == 2
          ? ('newCampaign.toPurchaseOrder' | translate)
          : ('newCampaign.toPaiment' | translate)
      "
      *ngIf="
        step > 2 &&
        step < finalStep &&
        adDetails.objectiveName != objectiveTypeEnum.WEB_SITE_TRAFFIC
      "
      [disabled]="adDetails.estimateId == '' && step == finalStep - 1"
      type="button"
      (mouseenter)="checkValidation()"
      (click)="next()"
    >
      {{ "buttons.next" | translate }}
    </button>
    <button
      class="btn btn-primary"
      data-bs-toggle="tooltip"
      placement="top"
      [ngbTooltip]="
        step == 2
          ? ('newCampaign.toPurchaseOrder' | translate)
          : ('newCampaign.toPaiment' | translate)
      "
      *ngIf="
        step == 2 &&
        step < finalStep &&
        adDetails.objectiveName != objectiveTypeEnum.WEB_SITE_TRAFFIC
      "
      type="button"
      (mouseenter)="checkValidation()"
      (click)="next()"
    >
      {{ "buttons.next" | translate }}
    </button>
    <button
      class="btn btn-primary"
      *ngIf="step == finalStep"
      type="button"
      (click)="paymentSelected()"
      [disabled]="!paymentMethod || adDetails.validators.conditions == false"
    >
      <loading-button
        [loading]="loading"
        [value]="'buttons.confirm' | translate"
        [icon]="null"
      ></loading-button>
    </button>
  </div>
</div>
