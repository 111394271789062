import { createReducer, on } from "@ngrx/store";
import { billingInfoSuccessMessage, createUpdateBillingInfo, invokeBillingInfoSuccess } from "./billing.actions";
import { BillingsStateInterface } from "./billingState.interface";

export const initialState: Readonly<BillingsStateInterface> = {
  billingInfo: null,
  loading: false,
  successMessage : null
};

export const BillingReducer = createReducer(
  initialState,
  on(createUpdateBillingInfo, (state, { billing }) => ({
    ...state,
    loading: true
  })),
  on(billingInfoSuccessMessage, (state, { successMessage }) => ({
    ...state,
    successMessage: successMessage,
    loading : false
    
  })),
  on(invokeBillingInfoSuccess, (state, { billing }) => ({
    ...state,
    billingInfo: billing
  })),
);
