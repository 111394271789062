import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { setAPIStatus } from "../apiState.interface";
import { SubCategoryService } from "src/app/shared/services/sub-category.service";
import {
  createUpdateSubCategorySuccess,
  deleteSubCategorySuccess,
  getSubCategoriesByCategory,
  invokeAllSubCategories,
  invokeAllSubGategoryNames,
  invokeCreateSubCategory,
  invokeDeleteSubCategory,
  invokeSubCategoriesByCategory,
  invokeUpdateSubCategory,
  setAllSubCategories,
  setAllSubCategoryNames,
} from "./subCategory.actions";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { AllSubCategoryInterface } from "src/app/shared/models/sub-category/sub-category";

@Injectable()
export class SubCategoryEffect {
  constructor(
    private actions$: Actions,
    private subCategoryService: SubCategoryService
  ) {}

  invokeAllSubCategoryNames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAllSubGategoryNames),
      mergeMap((state) => {
        return this.subCategoryService.getAllSubCategoryNames().pipe(
          map((data: string[] | null) =>
            setAllSubCategoryNames({ subcategoryNames: data })
          ),
          catchError((result) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: result.error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeSubCategoriesNamesByCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeSubCategoriesByCategory),
      mergeMap((state) => {
        return this.subCategoryService
          .getSubCategoriesByCategoryName(state.categoryName)
          .pipe(
            map((data: string[] | null) =>
              getSubCategoriesByCategory({ subCategoriesByCategoryName: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeAllSubCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAllSubCategories),
      mergeMap((state) => {
        return this.subCategoryService.getAllSubCategories().pipe(
          map((data: AllSubCategoryInterface[]) =>
            setAllSubCategories({ subCategories: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeCreateSubCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCreateSubCategory),
      mergeMap((state) => {
        return this.subCategoryService
          .createSubCategory(state.subCategory)
          .pipe(
            map((data: SuccessDto) =>
              createUpdateSubCategorySuccess({ message: data.message })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeUpdateSubCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateSubCategory),
      mergeMap((state) => {
        return this.subCategoryService
          .updateSubCategory(state.subCategory, state.oldName)
          .pipe(
            map((data: SuccessDto) =>
              createUpdateSubCategorySuccess({ message: data.message })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeDeleteSubCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeDeleteSubCategory),
      mergeMap((state) => {
        return this.subCategoryService
          .deleteSubCategory(state.subCategoryName)
          .pipe(
            map((data: SuccessDto) =>
              deleteSubCategorySuccess({ message: data.message })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
}
