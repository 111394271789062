import { Routes } from "@angular/router";
import { SettingsComponent } from "../components/header/header/settings/settings.component";
import { AdminGuardService } from "../services/guard/admin-guard.service";
import { AdvertiserGuardService } from "../services/guard/advertiser-guard.service";
import { LocationPartnerGuardService } from "../services/guard/location-partner-guard.service";

export const dashboardContent: Routes = [
  {
    path: "admin",
    canActivate:[AdminGuardService],
    loadChildren: () => import("../../components/admin/admin.module").then((m) => m.AdminModule),
    data: {
      title: 'Dashboard Admin | Relead ',
      description: 'Access your account to promote your business utilizing Wi-Fi marketing.'
    }
  },
  {
    path: "advertiser",
    canActivate:[AdvertiserGuardService],
    loadChildren: () => import("../../components/advertiser/advertiser.module").then((m) => m.AdvertiserModule),
  },
  {
    path: "location-partner",
    canActivate:[LocationPartnerGuardService],
    loadChildren: () => import("../../components/location-partner/location-partner.module").then((m) => m.LocationPartnerModule),
  },
  {
    path: "params",
    component: SettingsComponent,
  },
];