import { Subject } from "rxjs";
import { Component, OnDestroy } from "@angular/core";
import { invokeComponentDistroyed } from "./store/apiState.interface";
import { Store } from "@ngrx/store";
import { AppStateInterface } from "./store/appState.interface";

@Component({
  selector: "app-base-component",
  template: "",
})
export class BaseComponent implements OnDestroy {
  ngDestroyed$: Subject<void> = new Subject<void>();

  constructor(private storing: Store<AppStateInterface>) {}

  ngOnDestroy(): void {
    this.ngDestroyed$.next();
    this.ngDestroyed$.unsubscribe();

    this.storing.dispatch(invokeComponentDistroyed());
  }
}
