<div class="card">
  <div class="card-header pb-0">
    <div class="media-space-between">
      <h4>{{ "admin.advertisers.title" | translate }}</h4>
      <div class="media-body">
        <form class="d-inline-flex" [formGroup]="formFilter">
          <div
            class="faq-form mr-3"
            style="color: darkgrey; display: flex; margin-left: 10px"
          >
            <i class="search-icon" data-feather="search"></i>
            <div>
              <div class="input-group">
                <input
                  id="table-complete-search"
                  type="text"
                  class="form-control"
                  [placeholder]="'placeholder.searchEmail' | translate"
                  formControlName="email"
                  (ngModelChange)="searchTerm()"
                />
                <span
                  class="input-group-text p-0"
                  style="background-color: transparent"
                >
                  <app-general-filter
                    [filter]="filterAdvertiser"
                    (optionSelected)="saveOption($event)"
                  ></app-general-filter>
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="table-responsive data-table">
      <form>
        <table class="table table-striped table-border-vertical">
          <thead>
            <tr>
              <ng-container *ngFor="let column of columns">
                <th
                  class="h-w-{{ column.width }}"
                  sortable="{{ column.key }}"
                  (sort)="onSort($event)"
                >
                  {{ column.label | translate }}
                </th>
              </ng-container>
              <th>{{ "admin.advertisers.status" | translate }}</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody *ngIf="!(loading$ | async)">
            <tr *ngFor="let advertiser of resultFilterAdvertiser.advertisers">
              <ng-template #none>
                <td>
                  {{ "undefined" | translate }}
                </td>
              </ng-template>
              <td *ngIf="advertiser.companyName != null ; else none">
                <p >{{ advertiser.companyName }}</p>
              </td>
              <td *ngIf="advertiser.firstName != null && advertiser.lastName != null; else none">
                <p >{{ advertiser.firstName + " " + advertiser.lastName }}</p>
              </td>
              <td *ngIf="advertiser.phone != null ; else none">
                <p >{{ advertiser.phone }}</p>
              </td>
              <td *ngIf="advertiser.phone != null ; else none">
                <p >{{ advertiser.email }}</p>
              </td>
              <td *ngIf="advertiser.phone != null ; else none">
                <p >{{ advertiser.creationDate | localizedDate : "short" }}</p>
              </td>
              <td>
                <span
                  [ngClass]="
                    'badge ' +
                    badgeService.getBadgeByAdvertiserStatus(
                      advertiser.status
                    )
                  "
                >
                  {{ "enumMapping.advertiser.status." + advertiser.status | translate }}
                </span>
              </td>
              <td>
                <!--<app-drob-zone-options
                  [options]="getOptions(advertiser.status)"
                  (optionSelected)="saveAction($event, advertiser)"
                ></app-drob-zone-options>-->
                <app-drob-zone-options
                  [options]="advertiserActions"
                  (optionSelected)="saveAction($event, advertiser)"
                ></app-drob-zone-options>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="resultFilterAdvertiser.count == 0 && !(loading$ | async);" class="d-flex justify-content-center m-t-20">
          {{"noData" | translate}}
        </div>
        <div *ngIf="loading$ | async">
          <ng-container style="float: right" class="flex">
            <div class="loader-box">
              <div class="loader-34"></div>
            </div>
            <h6
              class="col align-self-center f-w-600"
              style="text-align: center"
            >
              {{ "fetching" | translate }}
            </h6>
          </ng-container>
        </div>
        <div class="media">
          <div class="col-3"></div>
          <div class="col-6">
            <div class="d-flex justify-content-center p-3">
              <ngb-pagination
                [collectionSize]="resultFilterAdvertiser.count!"
                [pageSize]="advertiserFilter.pager.pageSize"
                [rotate]="true"
                [boundaryLinks]="true"
                (pageChange)="changePage($event)"
              >
              </ngb-pagination>
            </div>
          </div>
          <div class="col-3">
            <div class="d-flex justify-content-end p-3">
              <select
                class="form-select"
                style="width: auto"
                name="pageSize"
                [(ngModel)]="changePageSize"
              >
                <option [ngValue]="5">
                  5 {{ "pagination.itemsPerPage" | translate }}
                </option>
                <option [ngValue]="10">
                  10 {{ "pagination.itemsPerPage" | translate }}
                </option>
                <option [ngValue]="15">
                  15 {{ "pagination.itemsPerPage" | translate }}
                </option>
                <option [ngValue]="resultFilterAdvertiser.count">
                  {{ "pagination.allItems" | translate }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
