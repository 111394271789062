<div class="card center-white-content">
  <div class="card-body m-30 ">
    <h2 class="mb-4 f-w-700">
      {{ "location.remuneration.caseFsi.title" | translate }}
    </h2>
    <p class="f-w-600">
      {{ "location.remuneration.caseFsi.body" | translate }}
    </p>
    <a
        class="btn btn-outline-white f-w-600"
        target="_blank"
        type="button"
        [href]="bookMeeting"
      >
        {{ "location.remuneration.caseFsi.button" | translate }}
      </a>
  </div>
</div>
