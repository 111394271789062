<div class="modal-header">
  <h4 class="modal-title" id="exampleModalLabel">Photo de profil</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal()"
  ></button>
</div>
<div class="modal-body">
  <ngx-dropzone
    *ngIf="!isUploaded"
    (change)="selectFile($event)"
    [multiple]="true"
  >
    <ngx-dropzone-label>
      <div>
        <div class="dz-message needsclick">
          <i class="icon-cloud-up"></i>
          <h6>Drop files here or click to upload.</h6>
          <span class="dz-message needsclick"
            >Drop file here or click to upload.</span
          >
        </div>
      </div>
    </ngx-dropzone-label>
  </ngx-dropzone>

  <div class="image-cropper-btn">
    <input
      class="btn btn-outline-primary"
      placeholder="Choose Your Image"
      type="file"
      hidden
      #fileInput
      (change)="fileChangeEvent($event)"
    />
  </div>
  <div *ngIf="isUploaded">
    <div class="row">
      <div class="col-12">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [containWithinAspectRatio]="containWithinAspectRatio"
          [aspectRatio]="4 / 4"
          [resizeToWidth]="256"
          [cropperMinWidth]="128"
          [resizeToHeight]="500"
          [onlyScaleDown]="true"
          [roundCropper]="true"
          (startCropImage)="(startCropImage)"
          [canvasRotation]="canvasRotation"
          [transform]="transform"
          [alignImage]="'center'"
          [style.display]="showCropper ? null : 'none'"
          format="png"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded($event)"
          (cropperReady)="cropperReady($event)"
          (loadImageFailed)="loadImageFailed()"
        >
        </image-cropper>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    style="float: left"
    class="btn btn-outline-primary"
    data-bs-dismiss="modal"
    (click)="closeModal()"
  >
    {{ "buttons.close" | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="savePicture()">
    {{ "buttons.submit" | translate }}
  </button>
</div>
