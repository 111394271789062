import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(private title: Title, private meta: Meta) { }
  updateTitle(title: string): void {
    if (title) {
      this.title.setTitle(title);
    }
  }

  updateDescription(description: string): void {
    if (description) {
      this.meta.updateTag({name: 'description', content: description});
    }
  }
}
