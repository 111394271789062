import { createReducer, on } from "@ngrx/store";
import { ZoneStateInterafce } from "./zoneState.interface";
import {
  createUpdateZoneSuccess,
  deleteZoneSuccess,
  invokeAllZones,
  saveSelectedZone,
  setAllZoneNames,
  setAllZones,
} from "./zone.actions";

export const initialState: Readonly<ZoneStateInterafce> = {
  loading: false,
  zones: null,
  zoneNames: null,
  createUpdateSuccess: null,
  deleteSuccess: null,
  selectedZones : null
};

export const ZoneReducer = createReducer(
  initialState,
  on(invokeAllZones , (state)=>({
    ...state,
    loading : true
  })),
  on(setAllZones, (state, { zones }) => ({
    ...state,
    loading: false,
    zones: zones,
  })),

  on(createUpdateZoneSuccess, (state, { message }) => ({
    ...state,
    loading: false,
    createUpdateSuccess: message,
  })),

/*   on(invokeDeleteZone, (state) => ({
    ...state,
    loading: true,
  })), */
  on(deleteZoneSuccess, (state, { message }) => ({
    ...state,
    loading: false,
    deleteSuccess: message,
  })),
  on(setAllZoneNames, (state, { zoneNames }) => ({
    ...state,
    loading: false,
    zoneNames: zoneNames,
  })),
  on(saveSelectedZone , (state , {selectedZones})=>({
    ...state, 
    selectedZones : selectedZones
  }))
);
