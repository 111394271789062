import { createReducer, on } from "@ngrx/store";
import { createBugReport, filterBugReportByUser, invokeBugReport, resultBugReportByRef, resultUpdateBugReportStatus } from "./bug-report.action";
import { BugReportStateInterface } from "./bug-reportState.interface";

export const initialState: Readonly<BugReportStateInterface> = {
  bugReport: [],
  loading: false,
  resultFilterBugReportDto: [],
  filterBugReportDto: {
    status: null ,
    title: null,
    email: null,
    sortName: null,
    isAscending:null,
    description: '',
    roleName : ""
  },
  successDto :  null,
  support : null
};

export const BugReportReducer = createReducer(
  initialState,
  on(invokeBugReport, (state, {bugReport})=>({
    ...state,
    loading : true
  })),
  on(createBugReport, (state, { successDto }) => ({
    ...state,
    successDto: successDto,
    loading : false
  })),
  on(filterBugReportByUser, (state, {resultFilterBugReportDto})=> ({
    ...state,
    resultFilterBugReportDto : resultFilterBugReportDto
  })), 
  on(resultUpdateBugReportStatus, (state, {successDto})=>({
    ...state,
    successDto : successDto
  })),
  on(resultBugReportByRef, (state, {support})=>({
    ...state,
    support : support
  }))
);

