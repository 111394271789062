import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const adminState = (state: AppStateInterface) => state.adminState;

export const mainDashboardSelector = createSelector(
  adminState,
  (state) => state.mainDashboard
);

export const adminLoadingSelector = createSelector(
  adminState,
  (state) => state.loading
);

export const collaboratorsSelector = createSelector(
  adminState,
  (state) => state.collaborators
);

export const crudCollaboratorSuccessSelector = createSelector(
  adminState,
  (state) => state.success
);
export const adminProfileSelector = createSelector(
  adminState,
  (state) => state.profile
);


export const sideBarNewsSelector = createSelector(
  adminState,
  (state) => state.sideBarNews
);