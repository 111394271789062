import { ObjectiveTypeEnum } from "../enum/objectives";

export interface CampaignCard {
  title: string;
  logo: string;
  ribbon?: {
    text: string;
    color: string;
  } | null;
  disabled: boolean;
  objective: ObjectiveTypeEnum;
  gifName: string;
  class : string
}

export const CampaignsData: CampaignCard[] = [
  {
    title: "enumMapping.objectives.BRAND_AWARENESS_AND_REACH",
    logo: "assets/identity/brande.svg",
    disabled: false,
    ribbon: null,
    objective: ObjectiveTypeEnum.BRAND_AWARENESS_AND_REACH,
    gifName: "iahF4npEpwWVXiajWT76GOqJBQWg748JvQzw3qVn06zZ2_T1uyod6.mp4",
    class : "col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 box-col-4"
  },
  {
    title: "enumMapping.objectives.WEB_SITE_TRAFFIC",
    logo: "assets/identity/webSiteTrafic.svg",
    disabled: false,
    ribbon: null,
    objective: ObjectiveTypeEnum.WEB_SITE_TRAFFIC,
    gifName:"WkXzdcmJyDjVeNajDClLw_Kczdj4D2ZzH_OtfrQhOV05zNp3YbNv2.mp4",
    class : "col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 box-col-4"
  },
  {
    title: "enumMapping.objectives.GENERATION_DE_PROSPECTS",
    logo: "assets/identity/prospect.svg",
    disabled: false,
    ribbon:{
        text:"ribbonsText.new",
        color: "success"
    },
    objective: ObjectiveTypeEnum.GENERATION_DE_PROSPECTS,
    gifName:"_0UD6QwTaz3TklwMkYqGs_PiCdjMJXCcgKOk6PtBbrZSJuweeLgQ2.mp4",
    class : "col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 box-col-4"
  },
  {
    title: "enumMapping.objectives.APP_PROMOTION_AND_INSTALL",
    logo: "assets/identity/appPromotion.svg",
    disabled: true,
    ribbon:{
        text:"ribbonsText.comingSoon",
        color: "primary"
    },
    objective: ObjectiveTypeEnum.APP_PROMOTION_AND_INSTALL,
    gifName:"PAalMh8HULtDn6kBHUDjq_o7Tc_e4hqcTphSSD2CZ1CxO_QXnuS3e.mp4",
    class : "col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4 box-col-4"
  },
  {
    title: "enumMapping.objectives.LOCAL_STORE_PROMOTIONS",
    logo: "assets/identity/localStore.svg",
    disabled: true,
    ribbon:{
        text:"ribbonsText.comingSoon",
        color: "primary"
    },
    objective: ObjectiveTypeEnum.LOCAL_STORE_PROMOTIONS,
    gifName:"9NVZ_mzoJUtcGuMJFfNyMeRJNhVGuQEBfNyygJDRBKdtHlm_h1G56.mp4",
    class : "col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 box-col-4"
  },
];
