import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../data/constants";
import { DashboardSettingsInterface } from "../models/dashboard-settings.interface";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  constructor(private http: HttpClient) {}

  saveItem(title: string, value: string): void {
    localStorage.setItem(title, value);
  }

  getItem(title: string): string {
    const result = localStorage.getItem(title);
    return result ? result : "";
  }

  clearItem(title: string): void {
    localStorage.removeItem(title);
  }

  clear(): void {
    localStorage.clear();
  }

  getSettings(): Observable<DashboardSettingsInterface | null> {
    const result = JSON.parse(localStorage.getItem("settings") ?? "null");
    return new Observable<DashboardSettingsInterface | null>((observer) => {
      observer.next(result);
    });
  }
  setSettings(
    settings: DashboardSettingsInterface | null
  ): Observable<DashboardSettingsInterface | null> {
    localStorage.setItem("settings", JSON.stringify(settings));
    return new Observable<DashboardSettingsInterface | null>((observer) => {
      observer.next(settings);
    });
  }
}
