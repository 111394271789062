import { Component, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { LocationAvailabilityEnum } from "src/app/shared/enum/location-availability";
import { ObjectiveTypeEnum } from "src/app/shared/enum/objectives";
import { AdDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-details.interface";
import {
  TotalCost,
  TotalCostWithDiscount,
} from "src/app/shared/models/calcul/totalCost";
import { LocationPartnerInterface } from "src/app/shared/models/location-partners/locationPartner.interface";
import { BadgeService } from "src/app/shared/services/badge.service";
import { adDetailsSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  calculateTotalLPCostByCampanyNamesSelector,
  locationPartnersToCreateCampaignResultSelector,
} from "src/app/store/locationPartner/location-partner.selectors";

import { CalculateCost } from "src/app/shared/models/calcul/calculateCost";
import { cloneDeep } from "lodash";
import { LocationSearchMethod } from "src/app/shared/enum/locationSearchMethod";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
import { NewCampaignCalculatorService } from "src/app/shared/services/calculations/new-campaign.service";

@Component({
  selector: "app-by-location",
  templateUrl: "./by-location.component.html",
})
export class ByLocationComponent extends BaseComponent implements OnInit {
  notAvailableExists: boolean = false;
  getLocationPartnersCompanyNames: string[] | null;

  selectedLocations: string[] = [];

  calculateTotalLPCostByCampanyNames$: Observable<TotalCostWithDiscount | null>;
  calculateTotalLPCostByCampanyNames: TotalCost[] = [];

  locationSelected: TotalCost;
  partiallyAvailableLocationEnum = LocationAvailabilityEnum.PARTIALLY;

  //companyNamesBySubCategoriesAndTag
  locationPartnersToCreateCampaignResult$: Observable<
    LocationPartnerInterface[] | null
  >;
  locationPartnersResult: LocationPartnerInterface[];

  adDetails$: Observable<AdDetailsInterface>;
  adDetails: AdDetailsInterface = initialState.adDetails;
  calculateCost: CalculateCost = {
    calculationMethod: LocationSearchMethod.LOCATION_PARTNER,
    names: [],
    objectiveName: ObjectiveTypeEnum.BRAND_AWARENESS_AND_REACH,
    startDate: null,
    endDate: null,
  };

  constructor(
    public badgeService: BadgeService,
    private store: Store<AppStateInterface>,
    public newCampaignCalculatorService: NewCampaignCalculatorService
  ) {
    super(store);
    this.adDetails$ = this.store
      .pipe(select(adDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.locationPartnersToCreateCampaignResult$ = this.store
      .pipe(select(locationPartnersToCreateCampaignResultSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.calculateTotalLPCostByCampanyNames$ = this.store
      .pipe(select(calculateTotalLPCostByCampanyNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.calculateTotalLPCostByCampanyNames$.subscribe(
      (value: TotalCostWithDiscount | null) => {
        this.notAvailableExists = false;
        if (value != null) {
          this.calculateTotalLPCostByCampanyNames = value.totalCostPerDay;
          this.selectedLocations = this.calculateTotalLPCostByCampanyNames.map(
            (location) => location.companyName
          );
          value.totalCostPerDay.map((lp) => {
            if (
              lp.availabilities.availabilityStatus ==
              LocationAvailabilityEnum.NOT_AVAILABLE
            )
              this.notAvailableExists = true;
          });
        }
      }
    );
    this.adDetails$.subscribe((adDetails) => {
      this.adDetails = adDetails;
      if (this.adDetails != adDetails) {
        if (this.adDetails.calculationMethod != adDetails.calculationMethod)
          this.selectedLocations = [];
        this.newCampaignCalculatorService.onSelectCalculateCost(
          this.adDetails,
          this.selectedLocations
        );
      }
    });

    //get locations filtred by region
    this.locationPartnersToCreateCampaignResult$.subscribe(
      (result: LocationPartnerInterface[] | null) => {
        if (result != null) {
          this.locationPartnersResult = result;
          this.getLocationPartnersCompanyNames = result.map(
            (value) => value.companyName
          );
        }
      }
    );
  }

  selectAllLocations(event: any) {
    if (event.target.checked)
      this.selectedLocations = cloneDeep(
        this.getLocationPartnersCompanyNames ?? []
      );
    else this.selectedLocations = [];

    this.newCampaignCalculatorService.onSelectCalculateCost(
      this.adDetails,
      this.selectedLocations
    );
  }

  CheckLocationSelectedAll(): boolean {
    return (
      this.getLocationPartnersCompanyNames?.length ==
      this.selectedLocations.length
    );
  }
}
