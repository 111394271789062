import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, withLatestFrom } from "rxjs";
import { AppStateInterface } from "../appState.interface";
import { select, Store } from "@ngrx/store";
import { setAPIStatus } from "../apiState.interface";
import { RoleService } from "../../shared/services/role.service";
import { SubRoleService } from "../../shared/services/sub-role.service";
import { GroupAuthorityService } from "../../shared/services/group-authority.service";
import {
  invokeAllSubRoles,
  invokeCreateSubRole,
  invokeDeleteSubRole,
  invokeGroupAuthorities,
  invokeRoles,
  invokeSubRole,
  invokeSubRolesNames,
  invokeUpdateSubRole,
  resultAllSubRoles,
  resultDeleteSubRole,
  setCreateUpdateSubRoleSuccess,
  setGroupAuthorities,
  setRoles,
  setSubRole,
  setSubRolesNames,
} from "./role.actions";
import { RoleInterface } from "../../shared/models/role/role.interface";
import { GroupAuthorityInterface } from "src/app/shared/models/group-authority/group-authority.interface";
import {
  SubRoleInterface,
  SubRoleUsersInterface,
} from "src/app/shared/models/sub-role/subRole.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { resultSubRoleByUser } from "../user/user.actions";
import { subRole } from "src/app/shared/models/sub-role/subRole";

@Injectable()
export class RoleEffect {
  constructor(
    private actions$: Actions,
    private roleService: RoleService,
    private subRoleService: SubRoleService,
    private groupAuthorityService: GroupAuthorityService,
    private store: Store<AppStateInterface>
  ) {}

  invokeRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeRoles),
      mergeMap((state) => {
        return this.roleService.getAllRoles().pipe(
          map((data: RoleInterface[]) => setRoles({ roles: data })),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeSubRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeSubRole),
      mergeMap((state) => {
        return this.subRoleService.getSubRole(state.roleName, state.name).pipe(
          map((data: SubRoleInterface) => setSubRole({ subRole: data })),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeSubRolesNames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeSubRolesNames),
      mergeMap((state) => {
        return this.subRoleService.getSubRolesNames().pipe(
          map((data: string[]) => setSubRolesNames({ subRolesNames: data })),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeGroupAuthorities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeGroupAuthorities),
      mergeMap((state) => {
        return this.groupAuthorityService.getAllGroupAuthorities().pipe(
          map((data: GroupAuthorityInterface[]) =>
            setGroupAuthorities({ groupAuthorities: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeCreateSubRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCreateSubRole),
      mergeMap((state) => {
        return this.subRoleService.createSubRole(state.createSubRole).pipe(
          map((data: SuccessDto) =>
            setCreateUpdateSubRoleSuccess({ message: data.message })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeUpdateSubRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateSubRole),
      mergeMap((state) => {
        return this.subRoleService
          .updateSubRole(state.roleName, state.name, state.subRole)
          .pipe(
            map((data: SuccessDto) =>
              setCreateUpdateSubRoleSuccess({ message: data.message })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeAllSubRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAllSubRoles),
      mergeMap((state) => {
        return this.subRoleService.allSubRoles().pipe(
          map((data: subRole[] | null) =>
            resultAllSubRoles({ resultSubRoles: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeDeleteSubRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeDeleteSubRole),
      mergeMap((state) => {
        return this.subRoleService
          .deleteSuRole(state.roleName, state.subRoleName)
          .pipe(
            map((data: SuccessDto) =>
              resultDeleteSubRole({ message: data.message })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
}
