import { createReducer, on } from "@ngrx/store";
import { AuthStateInterface } from "./authState.interface";

import {
  invokeLogin,
  loginSuccess,
  logoutError,
  logoutSuccess,
  setAccount,
} from "./auth.actions";

export const initialState: Readonly<AuthStateInterface> = {
  account: {
    user: null,
    role: null,
  },
  token: null,
  loading: null,
  logout: false,
};

export const AuthReducer = createReducer(
  initialState,
  on(invokeLogin, (state) => ({
    ...state,
    loading: true,
  })),
  on(setAccount, (state, { account }) => ({
    ...state,
    loading: false,
    account: account,
  })),
  on(loginSuccess, (state, { token }) => ({
    ...state,
    loading: false,
    token: token,
  })),
  on(logoutSuccess, (state, { logout, token }) => ({
    ...state,
    loading: false,
    logout: logout,
    token: token,
  })),
  on(logoutError, (state) => ({
    ...state,
    loading: false,
  }))
);
