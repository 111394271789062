import { createReducer, on } from "@ngrx/store";
import {
  invokeUsersHistory,
  setUsersHistory,
  setUsersHistoryFilter,
} from "./userHistory.actions";
import { UserHistoryStateInterface } from "./userHistoryState.interface";

export const initialState: Readonly<UserHistoryStateInterface> = {
  filter: {
    email: null,
    endDate: null,
    startDate: null,
    sortName: null,
    isAscending:null,
    pager: {
      pageNumber: 1,
      pageSize: 15,
    },
  },
  history: {
    count: 0,
    history: [],
  },
  loading: false,
};

export const UserHistoryReducer = createReducer(
  initialState,
  on(setUsersHistoryFilter, (state, { filter: historyFilter }) => ({
    ...state,
    filter: historyFilter,
  })),
  on(invokeUsersHistory, (state) => ({
    ...state,
    loading: true,
  })),
  on(setUsersHistory, (state, { history }) => ({
    ...state,
    loading: false,
    history: history,
  })),
);
