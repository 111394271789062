import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeLocationPartnersCompanyNames } from "src/app/store/locationPartner/actions/get-locations.actions";
import { locationPartnersComapyNamesSelector } from "src/app/store/locationPartner/location-partner.selectors";

@Component({
  selector: "app-location-partner-information",
  templateUrl: "./location-partner-information.component.html",
})
export class LocationPartnerInformationComponent
  extends BaseComponent
  implements OnInit
{
  @Input("form") form: FormGroup;
  @Input() isUpdate: boolean;
  locationSelected: string | null = null;
  allCompanyNames: Observable<string[] | null>;
  resultAllCompanyNames: string[] | null;
  constructor(private store: Store<AppStateInterface>) {
    super(store);
    this.allCompanyNames = this.store
      .pipe(select(locationPartnersComapyNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.store.dispatch(invokeLocationPartnersCompanyNames());
    this.allCompanyNames.subscribe((result) => {
      if (result != null) {
        this.resultAllCompanyNames = result;
        if (this.form.get("locationPartner")?.value)
          this.locationSelected = this.form.get("locationPartner")?.value;
      }
    });
  }
}
