import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { setAPIStatus } from "../apiState.interface";
import { AppStateInterface } from "../appState.interface";
import { Store } from "@ngrx/store";
import { MailService } from "src/app/shared/services/mail.service";
import {
  invokeActivateAdvertiserAccount,
  invokeCampaignStarts,
  invokeMarkAdAsFinished,
  invokeNotifyAdminWhenAdvertiserCreated,
  invokeNotifyAdminWhenAdvertisingCampaignCreated,
  invokeSendFilesToUser,
  invokeSendLeadGenerationForm,
  invokeSendRequestToDownloadLead,
  resultSindingMail,
} from "./mailing.actions";
import { SuccessDto } from "src/app/shared/models/success-dto";
@Injectable()
export class MailingEffect {
  constructor(
    private actions$: Actions,
    private mailingService: MailService,
    private store: Store<AppStateInterface>
  ) {}

  invokeSendLeadGenerationForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeSendLeadGenerationForm),
      mergeMap((state) => {
        return this.mailingService
          .sendLeadGenerationByEmail(state.leadGeneration)
          .pipe(
            map((data: SuccessDto | null) =>
              resultSindingMail({ successMessage: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeSendRequestDownloadLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeSendRequestToDownloadLead),
      mergeMap((state) => {
        return this.mailingService
          .sendRequestDownloadLead(state.advertiser)
          .pipe(
            map((data: SuccessDto | null) =>
              resultSindingMail({ successMessage: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
  invokeSendDownloadedFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeSendFilesToUser),
      mergeMap((state) => {
        return this.mailingService
          .sendFilesToUser(state.sendFiles, state.title)
          .pipe(
            map((data: SuccessDto | null) =>
              resultSindingMail({ successMessage: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeNotifyAdminWhenAdvertisingCampaignCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeNotifyAdminWhenAdvertisingCampaignCreated),
      mergeMap((state) => {
        return this.mailingService
          .notifyAdminWhenAdvertisingCampaignCreated(state.title, state.email)
          .pipe(
            map((data: SuccessDto | null) =>
              resultSindingMail({ successMessage: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeNotifyAdminWhenAdvertiserCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeNotifyAdminWhenAdvertiserCreated),
      mergeMap((state) => {
        return this.mailingService
          .notifyAdminWhenAdvertiserCreated(
            state.email,
            state.firstName,
            state.lastName
          )
          .pipe(
            map((data: SuccessDto | null) =>
              resultSindingMail({ successMessage: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeCampaignStarts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCampaignStarts),
      mergeMap((state) => {
        return this.mailingService
          .notifyAdvertiserWhenHisCampaignStarts(state.email)
          .pipe(
            map((data: SuccessDto | null) =>
              resultSindingMail({ successMessage: null })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeMarkAdAsFinished$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeMarkAdAsFinished),
    mergeMap((state) => {
      return this.mailingService
        .markAdAsFinished(state.email)
        .pipe(
          map((data: SuccessDto | null) =>
            resultSindingMail({ successMessage: null })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
    })
  )
);

invokeActivateAdvertiserAccount$ = createEffect(() =>
  this.actions$.pipe(
    ofType(invokeActivateAdvertiserAccount),
    mergeMap((state) => {
      return this.mailingService
        .activateAdvertiserAccount(state.email)
        .pipe(
          map((data: SuccessDto | null) =>
            resultSindingMail({ successMessage: null })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
    })
  )
);
}
