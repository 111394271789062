import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { JwtService } from "src/app/shared/services/jwt.service";
import {
  confirmEmailForRegistrationSuccess,
  invokeConfirmEmail,
  regenerateConfirmRegestration,
} from "src/app/store/advertiser/advertiser.actions";
import {
  advertiserLoadingSelector,
  confirmEmailSelectorError,
  confirmEmailSelectorSuccess,
  regenerateConfirmRegestrationFailedSelector,
  regenerateConfirmRegestrationSuccessSelector,
} from "src/app/store/advertiser/advertiser.selectors";
import { ApiStateInterface } from "src/app/store/apiState.interface";
import { AppStateInterface } from "src/app/store/appState.interface";

@Component({
  selector: "app-confirm-email",
  templateUrl: "./confirm-email.component.html",
  styleUrls: ["./confirm-email.component.scss"],
})
export class ConfirmEmailComponent extends BaseComponent implements OnInit {
  token: string;
  loading$: Observable<boolean | null>;

  result: AccountVerificationEnum = AccountVerificationEnum.LOADING;
  enumeration = AccountVerificationEnum;

  expired: boolean = false;
  validated: boolean = false;
  notValidated: boolean = false;
  notFound: boolean = false;
  regenerated: boolean = false;
  notRegenerated: boolean = false;

  confirmEmailSuccess$: Observable<SuccessDto | null>;
  confirmEmailError$: Observable<ApiStateInterface | null>;

  regenerationSuccess$: Observable<SuccessDto | null>;
  regenerationError$: Observable<ApiStateInterface | null>;
  loading: boolean | null = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppStateInterface>,
    private toastr: ToastrService,
    private jwtService: JwtService
  ) {
    super(store);
    this.loading$ = this.store
      .pipe(select(advertiserLoadingSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.confirmEmailSuccess$ = this.store
      .pipe(select(confirmEmailSelectorSuccess))
      .pipe(takeUntil(this.ngDestroyed$));
    this.confirmEmailError$ = this.store
      .pipe(select(confirmEmailSelectorError))
      .pipe(takeUntil(this.ngDestroyed$));

    this.regenerationSuccess$ = this.store
      .pipe(select(regenerateConfirmRegestrationSuccessSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.regenerationError$ = this.store
      .pipe(select(regenerateConfirmRegestrationFailedSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    if (!this.route.snapshot.queryParams["token"]) this.router.navigate(["/"]);
    this.token = this.route.snapshot.queryParams["token"];
    this.store.dispatch(invokeConfirmEmail({ token: this.token }));

    this.confirmEmailSuccess$.subscribe((success: SuccessDto | null) => {
      if (success) {
        this.result = AccountVerificationEnum.VALIDATED;
        this.validated = true;
      }
    });

    this.confirmEmailError$.subscribe((error: ApiStateInterface | null) => {
      if (error) {
        let err: HttpErrorResponse = error.apiResponseMessage;
        if (err.status == 404) this.result = AccountVerificationEnum.NOT_FOUND;
        if (err.status == 500)
          this.result = AccountVerificationEnum.NOT_VALIDATED;
      }
    });

    this.regenerationSuccess$.subscribe((success: SuccessDto | null) => {
      if (success) {
        this.result = AccountVerificationEnum.REGENERATED;
      }
    });
    this.regenerationError$.subscribe((error: ApiStateInterface | null) => {
      if (error) {
        let err: HttpErrorResponse = error.apiResponseMessage;
          this.result = AccountVerificationEnum.CANT_REGENERATE;
      }
    });

    this.loading$.subscribe((loading: boolean | null) => {
      if (loading) this.result = AccountVerificationEnum.LOADING;
    });
  }

  regenerateToken() {
    this.store.dispatch(regenerateConfirmRegestration({ token: this.token }));
  }
}

export enum AccountVerificationEnum {
  LOADING,
  NOT_FOUND,
  NOT_VALIDATED,
  CANT_REGENERATE,
  VALIDATED,
  REGENERATED,
}
