import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../data/constants";
import { AllRegionInterface, RegionInterface } from "../models/region/region";
import { SuccessDto } from "../models/success-dto";

@Injectable({
  providedIn: "root",
})
export class RegionService {
  constructor(private http: HttpClient) {}

  createRegion(region: RegionInterface): Observable<SuccessDto> {
    return this.http.post<SuccessDto>(
      `${
        environment.APP_PORT +
        Constants.REGION_ENDPOINT +
        "/create/" +
        region.name +
        "/" +
        region.logo
      }`,
      null
    );
  }

  updateRegion(
    region: RegionInterface,
    oldName: string
  ): Observable<SuccessDto> {
    return this.http.patch<SuccessDto>(
      `${
        environment.APP_PORT +
        Constants.REGION_ENDPOINT +
        "/" +
        oldName +
        "/" +
        region.name +
        "/" +
        region.logo
      }`,
      null
    );
  }
  deleteRegion(regionName: string): Observable<SuccessDto> {
    return this.http.delete<SuccessDto>(
      `${environment.APP_PORT + Constants.REGION_ENDPOINT}/` + regionName
    );
  }

  getAllRegions(): Observable<AllRegionInterface[]> {
    return this.http.get<AllRegionInterface[]>(
      `${environment.APP_PORT + Constants.REGION_ENDPOINT}/allRegions`
    );
  }

  getAllRegionNames(): Observable<RegionInterface[]> {
    return this.http.get<RegionInterface[]>(
      `${environment.APP_PORT + Constants.REGION_ENDPOINT}/allRegionNames`
    );
  }
  getAllRegionsByZones(zones: string[]): Observable<string[]> {
    return this.http.post<string[]>(
      `${environment.APP_PORT + Constants.REGION_ENDPOINT}/listRegionByZones`,
      zones
    );
  }
}
