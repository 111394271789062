import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'castFileName'
})
export class CastFileNamePipe implements PipeTransform {
  transform(fileName : string): string {
    if(fileName.length > 10) return fileName.substring(0,10) +"..."
    else return fileName;
  }
}