export interface WelcomeCard {
  description: string;
  buttonName: string | null;
  path: string | null;

  titleCardStatistique: string;
  iconCardStatistique: string;
  img: string;
}

export const WelcomeCardAdminData: WelcomeCard = {
  description: "welcomCard.admin.description",
  buttonName: "welcomCard.admin.buttonName",
  path: "/dashboard/admin/campaigns/calendar",
  titleCardStatistique: "welcomCard.admin.titleCardStatistique",
  iconCardStatistique: "assets/identity/eye-icon.svg",
  img: "assets/identity/get-started.png",
};

export const WelcomeCardAdvertiserData: WelcomeCard = {
  description: "welcomCard.advertiser.description",
  buttonName: "welcomCard.advertiser.buttonName",
  path: "../advertising-campaigns/create-ad",

  titleCardStatistique: "welcomCard.advertiser.titleCardStatistique",
  iconCardStatistique: "assets/identity/click.svg",
  img: "assets/identity/get-started.png",
};

export const WelcomeCardLocationMainData: WelcomeCard = {
  description: "welcomCard.location.description",
  buttonName: "welcomCard.location.buttonName",
  path: "../promote",

  titleCardStatistique: "welcomCard.location.titleCardStatistique",
  iconCardStatistique: "assets/identity/click.svg",
  img: "assets/identity/get-started.png",
};
export const WelcomeCardLocationStatisticsData: WelcomeCard = {
  description: "welcomCard.statistic.description",
  buttonName: "welcomCard.statistic.buttonName",
  path: "../promote",
  titleCardStatistique: "welcomCard.statistic.titleCardStatistique",
  iconCardStatistique: "assets/identity/consummers.svg",
  img: "assets/identity/get-started-statics.svg",
};
