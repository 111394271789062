import { createAction, props } from "@ngrx/store";
import { CalculateAverageViewsDto } from "src/app/shared/models/location-partners/CalculateAverageViewsDto";
import { LocationPartnerInterface } from "src/app/shared/models/location-partners/locationPartner.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";

export const invokeUpdateLocationPartner = createAction(
  "[Invoke Update LocationPartner] Invoke update location partner",
  props<{
    email: string | null;
    locationPartnerDto: LocationPartnerInterface | null;
  }>()
);
export const updateLocationPartnerSuccess = createAction(
  "[Update Location Partner Success] Update location partner success",
  props<{ successMessage: SuccessDto | null }>()
);

//Update location
export const invokeUpdateAccount = createAction(
  "[Invoke Update LocationPartner's account] Invoke update location partner's account",
  props<{
    Authorization: string;
    locationPartnerDto: LocationPartnerInterface;
  }>()
);
export const updateLocationPartnerAccountSuccess = createAction(
  "[Update Location Partner's account Success] Update location partner's account success",
  props<{ successMessage: SuccessDto | null }>()
);

//Update average views
export const invokeCalculateAndUpdateViewAverage = createAction(
  "[ Invoke Calculate Views Average ] Invoke calculate views average",
  props<{
    calculateAverageViews: CalculateAverageViewsDto | null;
    sendMail: boolean;
  }>()
);
export const resultCalculateAndUpdateViewsAverage = createAction(
  "[ Calculate and update views Average Result] calculate and update views average Result",
  props<{ successMessage: string | null }>()
);
