<div class="card-body p-0">
  <form class="theme-form" [formGroup]="form">
    <div class="mb-3">
      <label class="col-form-label pt-0" for="exampleInputPassword1">
        {{ "profile.updatePassword.currentPassword" | translate }}
      </label>
      <input
        class="form-control"
        id="exampleInputPassword1"
        type="password"
        formControlName="currentPassword"
        [placeholder]="'profile.placeholders.password' | translate"
      />
    </div>
    <div class="mb-3">
      <label class="col-form-label pt-0" for="exampleInputPassword2">{{
        "profile.updatePassword.newPassword" | translate
      }}</label>

      <input
        class="form-control"
        id="exampleInputPassword2"
        [type]="showPassword ? 'text' : 'password'"
        formControlName="newPassword"
        [placeholder]="'profile.placeholders.newPassword' | translate"
        [ngClass]="{
          'is-invalid':
            !form.get('newPassword')?.valid && form.get('newPassword')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          !form.get('newPassword')?.valid && form.get('newPassword')?.touched
        "
      >
        {{ "erreurMsg.validPassword" | translate }}
        <ul style="padding-left: 10px">
          <li>- {{ "password.pattern.validator1" | translate }}</li>
          <li>- {{ "password.pattern.validator2" | translate }}</li>
          <li>- {{ "password.pattern.validator3" | translate }}</li>
        </ul>
      </div>
    </div>
    <div class="mb-3">
      <label class="col-form-label pt-0" for="exampleInputPassword3">{{
        "profile.updatePassword.confirmNewPassword" | translate
      }}</label>
      <input
        class="form-control"
        id="exampleInputPassword3"
        [type]="showPassword ? 'text' : 'password'"
        formControlName="reNewPassword"
        [placeholder]="'profile.placeholders.confirmPassword' | translate"
        [ngClass]="{
          'is-invalid':
            !form.get('reNewPassword')?.valid &&
            form.get('reNewPassword')?.touched
        }"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          !form.get('reNewPassword')?.valid &&
          form.get('reNewPassword')?.touched
        "
      >
        {{ "erreurMsg.validPassword" | translate }}
        <ul style="padding-left: 10px">
          <li>- {{ "password.pattern.validator1" | translate }}</li>
          <li>- {{ "password.pattern.validator2" | translate }}</li>
          <li>- {{ "password.pattern.validator3" | translate }}</li>
        </ul>
      </div>
    </div>
  </form>
</div>
<div class="text-sm-end">
  <button
    class="btn btn-primary"
    type="button"
    (click)="onSubmitChangePassword()"
    [disabled]="!form.valid"
  >
    {{ "buttons.update" | translate }}
  </button>
</div>
