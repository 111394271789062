export enum RoleEnum {
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_ADVERTISER = "ROLE_ADVERTISER",
  ROLE_LOCATION = "ROLE_LOCATION",
}

export const RoleEnumMapping: Record<RoleEnum, string> = {
  [RoleEnum.ROLE_ADMIN]: "Admin",
  [RoleEnum.ROLE_ADVERTISER]: "Advertiser",
  [RoleEnum.ROLE_LOCATION]: "Location partner",
};
export interface RoleNamesInterface {
  id: RoleEnum;
  value: string;
}
