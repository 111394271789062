import { Injectable } from '@angular/core';
import { CanActivate, Router } from "@angular/router";
import { RoleEnum } from '../../models/user/role.interface';
import { JwtService } from '../jwt.service';

@Injectable()
export class LocationPartnerGuardService implements CanActivate {

  constructor(
    private router: Router,
    private jwtService: JwtService
  ) {}

  canActivate() {
    if (localStorage.getItem("token") != null) {
      const role = this.jwtService.getRoleName(localStorage.getItem("token"));
      if (role == RoleEnum.ROLE_LOCATION) return true;
      else {
        this.router.navigate(["dashboard"]);
        return false;
      }
    } else {
      this.router.navigate(["dashboard"]);
      return false;
    }
  }
}
