import { createReducer, on } from "@ngrx/store";
import {
  createUpdateSubCategorySuccess,
  deleteSubCategorySuccess,
  getSubCategoriesByCategory,
  invokeAllSubCategories,
  invokeAllSubGategoryNames,
  saveSubCategories,
  setAllSubCategories,
  setAllSubCategoryNames,
} from "./subCategory.actions";
import { SubCategoryStateInterface } from "./subCategoryState.interface";

export const initialState: Readonly<SubCategoryStateInterface> = {
  loading: false,
  subcategoryNames: null,
  subCategoriesByCategoryName: null,
  createUpdateSuccess: null,
  deleteSuccess: null,
  subCategories: null,
  selectedSubCategories : []
};
export const SubCategoryReducer = createReducer(
  initialState,
  on(invokeAllSubGategoryNames, (state) => ({
    ...state,
    loading: true,
  })),
  on(setAllSubCategoryNames, (state, { subcategoryNames }) => ({
    ...state,
    subcategoryNames: subcategoryNames,
    loading: false,
  })),
  on(getSubCategoriesByCategory, (state, { subCategoriesByCategoryName }) => ({
    ...state,
    subCategoriesByCategoryName: subCategoriesByCategoryName,
  })),

  on(invokeAllSubCategories, (state) => ({
    ...state,
    loading: true,
  })),
  on(setAllSubCategories, (state, { subCategories }) => ({
    ...state,
    subCategories: subCategories,
    loading: false,
  })),
  on(createUpdateSubCategorySuccess, (state, { message }) => ({
    ...state,
    createUpdateSuccess: message,
    loading: false,
  })),
/*   on(invokeDeleteSubCategory, (state) => ({
    ...state,
    loading: true,
  })), */
  on(deleteSubCategorySuccess, (state, { message }) => ({
    ...state,
    loading: false,
    deleteSuccess: message,
  })),
  on(saveSubCategories, (state , {selectedSubCategories})=>({
    ...state,
    selectedSubCategories : selectedSubCategories
  }))
);
