import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const tagState = (state: AppStateInterface) => state.tagState;

export const allTagNamesSelector = createSelector(
  tagState,
  (state) => state.tagNames
);
export const loadingTagSelector = createSelector(
  tagState,
  (state) => state.loading
);

export const allTagsSelector = createSelector(
    tagState,
    (state) => state.tags
)

export const createUpdateTagSuccessSelector = createSelector(
    tagState,
    (state) => state.createUpdateSuccess
)

export const deleteTagSuccessSelector = createSelector(
    tagState,
    (state) => state.deleteSuccess
)
