import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, takeUntil } from "rxjs";
import { CategoryService } from "src/app/shared/services/category.service";
import { invokeComponentDistroyed, setAPIStatus } from "../apiState.interface";
import {
  createUpdateCategorySuccess,
  deleteCategorySuccess,
  invokeAllCategories,
  invokeAllCategoryNames,
  invokeCreateCategory,
  invokeDeleteCategory,
  invokeUpdateCategory,
  setAllCategories,
  setAllCategoryNames,
} from "./category.actions";
import { AllCategoryInterface } from "src/app/shared/models/category/category";
import { SuccessDto } from "src/app/shared/models/success-dto";

@Injectable()
export class CategoryEffect {
  constructor(
    private actions$: Actions,
    private categoryService: CategoryService
  ) {}

  invokeAllCategoryNames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAllCategoryNames),
      mergeMap((state) => {
        return this.categoryService.getAllCategoryNames().pipe(
          map((data: string[] | null) =>
            setAllCategoryNames({ categoryNames: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeAllCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAllCategories),
      mergeMap((state) => {
        return this.categoryService.getAllCategories().pipe(
          map((data: AllCategoryInterface[]) =>
            setAllCategories({ categories: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          ),
          /*takeUntil(this.actions$.pipe(
            ofType(invokeComponentDistroyed)
          ))*/
        );
      })
    )
  );

  invokeCreateCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCreateCategory),
      mergeMap((state) => {
        return this.categoryService.createCategory(state.category).pipe(
          map((data: SuccessDto) =>
            createUpdateCategorySuccess({ message: data.message })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeUpdateCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateCategory),
      mergeMap((state) => {
        return this.categoryService
          .updateCategory(state.category, state.oldName)
          .pipe(
            map((data: SuccessDto) =>
              createUpdateCategorySuccess({ message: data.message })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeDeleteCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeDeleteCategory),
      mergeMap((state) => {
        return this.categoryService.deleteCategory(state.categoryName).pipe(
          map((data: SuccessDto) =>
            deleteCategorySuccess({ message: data.message })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
  
}
