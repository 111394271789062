import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
} from "@angular/cdk/drag-drop";
import { FormBuilder, FormGroup } from "@angular/forms";
import { BaseComponent } from "src/app/base.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  TypeFieldsLeadGeneration,
  TypeFieldsLeadGenerationToShow,
} from "src/app/shared/enum/typeFieldsLeadGeneration";
import { MapItem } from "src/app/shared/models/drag-drop.interface";
import {
  FildsData,
  NewFildsData,
} from "src/app/shared/data/newFieldsFormData";
import { LeadGeneration } from "src/app/shared/models/leadGeneration/leadGeneration";
import { select, Store } from "@ngrx/store";
import { AppStateInterface } from "src/app/store/appState.interface";
import { JwtService } from "src/app/shared/services/jwt.service";
import { TranslateService } from "@ngx-translate/core";
import { saveFieldsLeadGenerationSelector } from "src/app/store/advertiser/advertiser.selectors";
import { Observable, takeUntil } from "rxjs";
import { FieldsLeadGeneration } from "src/app/shared/models/leadGeneration/fieldsLeadGeneration";
import {
  saveLeadGeneration,
  saveNewFieldsLeadGeneration,
} from "src/app/store/advertiser/advertiser.actions";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent extends BaseComponent implements OnInit {
  @Output("resultLeadGenerationForm") resultLeadGenerationForm =
    new EventEmitter<LeadGeneration>();

  fields: MapItem[] = []; // new item
  options: {
    id: TypeFieldsLeadGeneration;
    value: string;
  }[];
  addForm: FormGroup;
  title: string | null = null;
  newFilds: FildsData[] = NewFildsData;
  typeFildSelected: TypeFieldsLeadGeneration | null;
  enumTypeFildSelected = TypeFieldsLeadGeneration;
  nameFieldSelected: string | null;
  public htmlContent = "";

  formTitle: string = "leadGeneration.titleForm";
  description: string = "leadGeneration.description";
  formUpdate: FormGroup;
  saveLeadGeneration$: Observable<FieldsLeadGeneration | null>;
  @Input() adTitle: string;

  formLeadGeneration: LeadGeneration = {
    title: "",
    description: "",
    fields: [],
    buttonName: "",
    adTitle: "",
    owner: "",
    creationDate: null,
  };

  @Input() defaultItems: FildsData[];
  draggbleItems: any[] = ["leadGeneration.dragDropZone.title"];
  newItems: FildsData[] = [];

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private store: Store<AppStateInterface>,
    private jwtService: JwtService,
    private translate: TranslateService,
    private elementRef: ElementRef
  ) {
    super(store);
    this.saveLeadGeneration$ = this.store
      .pipe(select(saveFieldsLeadGenerationSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.addForm = this.fb.group({
      title: [""],
      option: [null],
    });
    this.formUpdate = this.fb.group({
      title: [this.translate.instant("leadGeneration.titleForm")],
      description: [this.translate.instant("leadGeneration.description")],
      buttonName: [this.translate.instant("buttons.send")],
    });
  }
  list: string[] = [];

  type: { [key: string]: any } = {
    "leadGeneration.fields.name": TypeFieldsLeadGeneration.TEXT,
    "leadGeneration.fields.tel": TypeFieldsLeadGeneration.NUMBER,
    "leadGeneration.fields.email": TypeFieldsLeadGeneration.TEXT,
    "leadGeneration.fields.birthday": TypeFieldsLeadGeneration.DATE,
    "leadGeneration.fields.profession": TypeFieldsLeadGeneration.TEXT,
  };

  valuesCheckBox: string[] = [];
  valuesRadio: string[] = [];
  values: string[] = [];
  newFieldForType: FildsData = {
    title: "",
    logo: "",
    type: TypeFieldsLeadGeneration.DATE,
    values: [],
  };
  types: TypeFieldsLeadGeneration[][] = [];
  buttonName: string = this.translate.instant("buttons.send");
  ngOnInit(): void {
    this.formLeadGeneration.creationDate = new Date();
    this.formLeadGeneration.adTitle = this.adTitle;
    this.formLeadGeneration.buttonName = this.buttonName;
    this.formLeadGeneration.owner = this.jwtService.getEmail();
    this.saveLeadGeneration$.subscribe((data) => {
      if (data) {
        this.formLeadGeneration = {
          ...this.formLeadGeneration,
          fields: [...this.formLeadGeneration.fields, data],
        };
        this.store.dispatch(
          saveLeadGeneration({
            saveFormLeadGeneration: this.formLeadGeneration,
          })
        );
        this.resultLeadGenerationForm.emit(this.formLeadGeneration);
      }
    });
    this.options = Object.keys(TypeFieldsLeadGeneration)
      .filter((value) => isNaN(Number(value)) === true)
      .map((key: string | any) => ({
        id: TypeFieldsLeadGeneration[
          key as keyof typeof TypeFieldsLeadGeneration
        ],
        value:
          TypeFieldsLeadGenerationToShow[
            key as keyof typeof TypeFieldsLeadGeneration
          ],
      }));
  }
  drop(event: CdkDragDrop<FildsData[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  /** Predicate function that allows items to be dropped into a list. */
  listPredicate(item: CdkDrag<String>) {
    return true;
  }
  /** Predicate function that doesn't allow items to be dropped into a list. */
  noReturnPredicate() {
    return false;
  }
  onClickShowModal(showModal: any) {
    const modalRef = this.modalService.open(showModal, {
      centered: true,
      size: "lg",
    });
  }
  getTitle(title: string) {
    this.title = title;
  }
  onClickAddfield() {
    if (this.typeFildSelected == this.enumTypeFildSelected.CASEACHOUCHER) {
      this.values = this.valuesRadio;
    }
    if (this.typeFildSelected == this.enumTypeFildSelected.CHOIXMULTIPLE) {
      this.values = this.valuesCheckBox;
    }
    if (this.title) {
      this.newItems = [
        ...this.newItems,
        {
          title: this.title,
          type: this.typeFildSelected,
          logo: "",
          values: this.values,
        },
      ];
      this.store.dispatch(
        saveNewFieldsLeadGeneration({
          newFieldsLeadGeneration: [...this.newItems],
        })
      );
      this.type[this.title] = this.typeFildSelected;
    }
    this.typeFildSelected = null;
    this.nameFieldSelected = null;
    this.modalService.dismissAll();
  }
  /*onClickAddToForm(event:any, item: string){
    this.form.push(item)
    this.available = this.form.map(v => v ? [this.toMapItem(v)] : []);
    this.assignments = this.assignments.filter(e=>e.findIndex(i=>i.displayName === item))
  }*/
  onClickSaveOption(option: FildsData) {
    this.newFieldForType = option;
    this.typeFildSelected = option.type;
    this.nameFieldSelected = option.title;
  }
  adjustTextareaHeight(): void {
    const textarea: HTMLTextAreaElement =
      this.elementRef.nativeElement.querySelector(".custom-textarea");
    textarea.style.height = "45px";
    textarea.style.height = `${textarea.scrollHeight}px`;
  }
  updateTitle() {
    this.formTitle = this.formUpdate.get("title")?.value;
    this.formLeadGeneration = {
      ...this.formLeadGeneration,
      title: this.formTitle,
    };
    this.resultLeadGenerationForm.emit(this.formLeadGeneration);
  }
  updateDescription() {
    this.description = this.formUpdate.get("description")?.value;
    this.formLeadGeneration = {
      ...this.formLeadGeneration,
      description: this.description,
    };
    this.resultLeadGenerationForm.emit(this.formLeadGeneration);
  }
  saveLeadGeneration(leadGeneration: FieldsLeadGeneration[]) {
    this.formLeadGeneration = {
      ...this.formLeadGeneration,
      fields: leadGeneration,
    };
    this.resultLeadGenerationForm.emit(this.formLeadGeneration);
  }
  updateButtonName() {
    this.buttonName = this.formUpdate.get("buttonName")?.value;
    this.formLeadGeneration = {
      ...this.formLeadGeneration,
      buttonName: this.buttonName,
    };
    this.store.dispatch(
      saveLeadGeneration({
        saveFormLeadGeneration: this.formLeadGeneration,
      })
    );
  }
  getType(name: string): TypeFieldsLeadGeneration | null {
    this.typeFildSelected = this.type[name];
    if (this.typeFildSelected == undefined) {
      this.typeFildSelected = this.newFieldForType.type;
    }
    return this.typeFildSelected;
  }
  getDataCheckbox(values: any) {
    this.valuesCheckBox = Object.values(values);
  }
  getDataRadio(values: any) {
    this.valuesRadio = Object.values(values);
  }
  selectTextareaText(event: any) {
    event.target.select();
  }
}
