import { createAction, props } from "@ngrx/store";
import { ResultFilterbugReportInterface } from "src/app/shared/models/bugReport/result-filter-bug-report.interface";
import { FilterListBugReport } from "src/app/shared/models/bugReport/filter-list-bug-report.interface";
import { bugReportStatus } from "src/app/shared/enum/bugReport-status";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { BugReportInterface } from "src/app/shared/models/bugReport/bug-report.interface";

export const invokeBugReport = createAction(
  "[Invoque Bug Report] Invoque bug report",
  props<{ bugReport: BugReportInterface |null }>()
);

export const createBugReport = createAction(
  "[Create Bug Report] Create bug report",
  props<{ successDto: SuccessDto | null}>()
);
export const invokeFilterBugReportByUser = createAction(
  "[Invoke Filter Bug Report By advertiser] invoke filter bug report by advertiser",
  props<{filterBugReport: FilterListBugReport}>()
)
export const filterBugReportByUser = createAction(
  "[ Filter Bug Report By Advertiser ] filter bug report by advertiser",
  props<{resultFilterBugReportDto: ResultFilterbugReportInterface[] | null}>()
)
export const invokeUpdateBugReportStatus = createAction(
  "[ Invoke Update Bug Report Status ] Invoke update bug report status ",
  props<{title : string , status: bugReportStatus}>()
)
export const resultUpdateBugReportStatus = createAction(
  "[ Result Update Bug Report Status ] Result update bug report status ",
  props<{successDto: SuccessDto | null}>()
)
export const invokeBugReportByRef = createAction(
  "[ Invoke Bug Report By Reference ] Invoke Bug report by reference",
  props<{ref : string | null}>()
)
export const resultBugReportByRef = createAction(
  "[ Result Bug Report By Reference ] Result Bug report by reference",
  props<{support : BugReportInterface | null}>()
)