export enum AdvertiserStatusEnum {
    ENABLED = "ENABLED",
    APPROVAL = "APPROVAL",
    DISABLED = "DISABLED",
    EMAIL_VERIFICATION = "EMAIL_VERIFICATION"
  }
  
  export const AdvertiserStatusEnumMapping: Record<AdvertiserStatusEnum, string> = {
    [AdvertiserStatusEnum.ENABLED]: "enumMapping.advertiser.enabled",
    [AdvertiserStatusEnum.APPROVAL]: "enumMapping.advertiser.approval",
    [AdvertiserStatusEnum.DISABLED] : "enumMapping.advertiser.disabled",
    [AdvertiserStatusEnum.EMAIL_VERIFICATION] : "enumMapping.advertiser.email-verification"
  };
  
  export interface AdvertiserStatusInterface {
    id: AdvertiserStatusEnum;
    value: string;
  }
  