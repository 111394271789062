import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, switchMap, withLatestFrom } from "rxjs";
import { setAPIStatus } from "../apiState.interface";
import {
  invokeDeletePromotionCode,
  invokeCreatePromotionCode,
  invokeUpdatePromotionCode,
  getPromotionCodesSuccess,
  invokePromotionCodes,
  invokePromotionCodeSuccess,
  invokeApplyPromotionCode,
  invokeCheckPromotionCode,
  checkPromotionCodeSuccess,
  applyPromotionCodeSuccess,
  invokeAdHavePromotionCode,
  getAdHavePromotionCode,
  checkPromotionCodeError,
} from "./promotion-code.actions";
import { AppStateInterface } from "../appState.interface";
import { select, Store } from "@ngrx/store";
import { SuccessDto } from "../../shared/models/success-dto";
import { PromotionCodeService } from "../../shared/services/promotion-code.service";
import { promotionCodeFilterSelector } from "./promotion-code.selectors";
import { ResultPromotionCodesInterface } from "../../shared/models/promotion-code/result-promotion-code.interface";
import { PromotionCodeLiteInterface } from "../../shared/models/promotion-code/check-promotion-code.interface";

@Injectable()
export class PromotionCodeEffect {
  constructor(
    private actions$: Actions,
    private promotionCodeService: PromotionCodeService,
    private store: Store<AppStateInterface>
  ) {}

  invokeGetPromotionCodeByFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokePromotionCodes),
      withLatestFrom(this.store.pipe(select(promotionCodeFilterSelector))),
      mergeMap(([, filter]) => {
        return this.promotionCodeService.filter(filter).pipe(
          map((data: ResultPromotionCodesInterface) =>
            getPromotionCodesSuccess({ result: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeCreatePromotionCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCreatePromotionCode),
      mergeMap((state) => {
        return this.promotionCodeService.create(state.create).pipe(
          map((data: any) =>
            invokePromotionCodeSuccess({ successMessage: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
  invokeUpdatePromotionCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdatePromotionCode),
      mergeMap((state) => {
        return this.promotionCodeService
          .update(state.code, state.promotionCode)
          .pipe(
            map((data: SuccessDto) =>
              invokePromotionCodeSuccess({ successMessage: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeDeletePromotionCodeByMac$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeDeletePromotionCode),
      mergeMap((state) => {
        return this.promotionCodeService.delete(state.code).pipe(
          map((data: SuccessDto | null) =>
            invokePromotionCodeSuccess({ successMessage: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeAdHavePromotionCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAdHavePromotionCode),
      mergeMap((state) => {
        return this.promotionCodeService.havePromotion(state.havePromotion).pipe(
          map((data: PromotionCodeLiteInterface | null) => getAdHavePromotionCode({ result: data })),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokecheckPromotionCodeValidity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCheckPromotionCode),
      mergeMap((state) => {
        return this.promotionCodeService.checkValidity(state.code).pipe(
          map((data: PromotionCodeLiteInterface) =>
            checkPromotionCodeSuccess({ result: data })
          ),
          catchError((error) =>
            of(
              checkPromotionCodeError({
                result: error.error?.message,
              })
            )
          )
        );
      })
    )
  );
  invokeApplyPromotionCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeApplyPromotionCode),
      mergeMap((state) => {
        return this.promotionCodeService
          .applyCode(state.code, state.campaignHashedId)
          .pipe(
            map((data: SuccessDto) =>
              applyPromotionCodeSuccess({ result: data.message })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
}
