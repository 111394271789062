import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import {
  invokeAllActivatedAdvertisers,
  selectedAdvertiser,
} from "src/app/store/advertiser/advertiser.actions";
import {
  advertisersSelector,
  selectedAdvertiserSelector,
} from "src/app/store/advertiser/advertiser.selectors";
import { AppStateInterface } from "src/app/store/appState.interface";
import { AdvertiserDetailsInterface } from "../../models/advertiser/profile.interface";
import { advertiserLoadingSelector } from "src/app/store/advertiser/advertiser.selectors";
@Component({
  selector: "app-advertisers",
  templateUrl: "./advertisers.component.html",
})
export class AdvertisersComponent extends BaseComponent implements OnInit {
  checked: boolean = false;
  advertisers$: Observable<string[] | null>;
  advertisers: string[] = [];
  savedAdvertisers: AdvertiserDetailsInterface[] = [];
  form: FormGroup;

  activatedAdvertisers$: Observable<AdvertiserDetailsInterface[] | null>;
  activatedAdvertisers: AdvertiserDetailsInterface[];
  loading$: Observable<boolean | null>;
  advertiser: string;
  constructor(
    private store: Store<AppStateInterface>,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    super(store);
    this.advertisers$ = this.store
      .pipe(select(selectedAdvertiserSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.activatedAdvertisers$ = this.store
      .pipe(select(advertisersSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(advertiserLoadingSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.form = this.fb.group({
      email: [""],
    });
  }
  ngOnInit(): void {
    this.advertisers$.subscribe((data) => {
      if (data) {
        this.advertisers = data;
      }
    });
    this.store.dispatch(invokeAllActivatedAdvertisers());
    this.activatedAdvertisers$.subscribe((result) => {
      if (result) {
        this.activatedAdvertisers = result;
        this.savedAdvertisers = this.activatedAdvertisers;
      }
    });
  }
  searchTerm(event: Event) {
    if ((event.target as HTMLInputElement).value == "") {
      this.savedAdvertisers = this.activatedAdvertisers;
      this.cdr.detectChanges();
    } else {
      this.savedAdvertisers = [];
      this.activatedAdvertisers.map((e) => {
        if (
          e.email &&
          e.firstName &&
          e.lastName &&
          (e.email
            .toLowerCase()
            .includes((event.target as HTMLInputElement).value.toLowerCase()) ||
            e.firstName
              .toLowerCase()
              .includes(
                (event.target as HTMLInputElement).value.toLowerCase()
              ) ||
            e.lastName
              .toLowerCase()
              .includes((event.target as HTMLInputElement).value.toLowerCase()))
        ) {
          this.savedAdvertisers = [...this.savedAdvertisers, e];
        }
      });
      this.cdr.detectChanges();
    }
  }
  submit() {
    this.store.dispatch(
      selectedAdvertiser({ selectedAdvertiser: this.advertisers })
    );
    const modal = this.modalService.dismissAll(AdvertisersComponent);
  }
  save(event: any, advertiser: string) {
    if (event.target.checked) {
      this.advertisers = [...this.advertisers, advertiser];
    } else {
      this.advertisers = this.advertisers.filter((item) => item != advertiser);
    }
  }
  onClickCloseModal() {
    this.modalService.dismissAll();
  }
}
