import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Constants } from '../data/constants';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import { BugReportInterface } from '../models/bugReport/bug-report.interface';
import { ResultFilterbugReportInterface } from '../models/bugReport/result-filter-bug-report.interface';
import { FilterListBugReport } from '../models/bugReport/filter-list-bug-report.interface';
import { bugReportStatus } from '../enum/bugReport-status';
import { SuccessDto } from '../models/success-dto';

@Injectable({
  providedIn: 'root'
})
export class BugReportService {

  constructor(private http: HttpClient) { }
  createBugReport(bugReport: BugReportInterface | null ): Observable<SuccessDto> {
    return this.http.post<SuccessDto>(
      `${
        environment.APP_PORT + Constants.BUG_REPORT_ENDPOINT
      }/create`
      ,bugReport
    );
  }
  filterListBugReport(filter: FilterListBugReport): Observable<ResultFilterbugReportInterface[]>{
    return this.http.post<ResultFilterbugReportInterface[]>(
      `${
        environment.APP_PORT + Constants.BUG_REPORT_ENDPOINT
      }/bugReportByUser/`,filter
    )
  }
  updateBugReportStatus(title: string, status: bugReportStatus): Observable<SuccessDto | null>{
    return this.http.patch<SuccessDto>(
      `${
        environment.APP_PORT + Constants.BUG_REPORT_ENDPOINT
      }/status/${title}/${status}`,null
    )
  }
  getBugReportByRef(ref: string | null): Observable<BugReportInterface | null>{
    return this.http.get<BugReportInterface>(
      `${
        environment.APP_PORT + Constants.BUG_REPORT_ENDPOINT
      }/bugReport/ref/${ref}`
    )
  }
}
