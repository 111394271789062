import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const zoneState = (state : AppStateInterface) =>
    state.zoneState;
export const allZonesSelector = createSelector(
    zoneState,
    (state) => state.zones
)

export const allZoneNamesSelector = createSelector(
    zoneState,
    (state) => state.zoneNames
)

export const createUpdateZoneSuccessSelector = createSelector(
    zoneState,
    (state) => state.createUpdateSuccess
)

export const deleteZoneSuccessSelector = createSelector(
    zoneState,
    (state) => state.deleteSuccess
)

export const loadingZoneSelector = createSelector(
    zoneState,
    (state) => state.loading
)

export const saveSelectedZonesSelector = createSelector(
    zoneState,
    (state) => state.selectedZones
)