import { createAction, props } from "@ngrx/store";
import {
  AllSubCategoryInterface,
  CreateUpdateSubCategoryInterface,
} from "../../shared/models/sub-category/sub-category";

export const invokeAllSubGategoryNames = createAction(
  "[ Invoke All Sub Category names ] Invoke all sub category names"
);
export const setAllSubCategoryNames = createAction(
  "[ Set All Sub Category names ] Get all sub category names",
  props<{ subcategoryNames: string[] | null }>()
);
export const invokeSubCategoriesByCategory = createAction(
  "[ Invoke All Sub Categories By Category name] Invoke All Sub Categories By Category name",
  props<{ categoryName: string }>()
);
export const getSubCategoriesByCategory = createAction(
  "[ All Sub Categories By Category name ] Sub categories by category name",
  props<{ subCategoriesByCategoryName: string[] | null }>()
);

export const invokeAllSubCategories = createAction(
  "[ Invoke All SubCategories ] Invoke all Sub categories"
);

export const setAllSubCategories = createAction(
  "[ Set All SubCategories ] Get all Sub categories",
  props<{ subCategories: AllSubCategoryInterface[] | null }>()
);

//Create
export const invokeCreateSubCategory = createAction(
  "[ Invoke Create sub-category ] invoke create sub-category",
  props<{ subCategory: CreateUpdateSubCategoryInterface }>()
);
export const createUpdateSubCategorySuccess = createAction(
  "[ Create Update sub-category Success] create update sub-category success",
  props<{ message: string | null }>()
);

//Update
export const invokeUpdateSubCategory = createAction(
  "[ Invoke Update sub-category ] invoke update sub-category",
  props<{ subCategory: CreateUpdateSubCategoryInterface; oldName: string }>()
);

//Delete
export const invokeDeleteSubCategory = createAction(
  "[ Invoke Delete sub-category ] invoke delete sub-category",
  props<{ subCategoryName: string }>()
);
export const deleteSubCategorySuccess = createAction(
  "[ DeleteSub sub-category Success ] delete sub-category success",
  props<{ message: string | null }>()
);

export const saveSubCategories = createAction(
  "[ Save Sub Categories ] Save sub categories ",
  props<{selectedSubCategories : string[]}>()
)