import { createReducer, on } from "@ngrx/store";
import { resultCreateSupplier } from "./suppliers.actions";
import { SupplierStateInterface } from "./supplierState.interface";

export const initialState: Readonly<SupplierStateInterface> = {
  resultCreateSupplier: null,
};
export const SupplierReducer = createReducer(
  initialState,
  on(resultCreateSupplier, (state, { resultCreateSupplier }) => ({
    ...state,
    resultCreateSupplier: resultCreateSupplier,
  }))
);
