import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const mailingState = (state: AppStateInterface) => state.mailingState;
export const sendMailSelector = createSelector(
  mailingState,
  (state) => state.successMessage
);
export const loadingSendingEmailSelector = createSelector(
  mailingState,
  (state) => state.loading
)