<form class="form theme-form" [formGroup]="billingInfoForm">
  <div class="row">
    <div class="col">
      <div class="mb-3 row">
        <label class="col-sm-4 col-form-label label"> {{"profile.billingInformation.legalName" | translate}} </label>
        <div class="col-sm-8">
          <input
            class="form-control"
            formControlName="legalName"
            type="text"
            [placeholder]= "'profile.billingInformation.placeholderLegalName' | translate"
          />
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-sm-4 col-form-label label">{{"profile.billingInformation.matricule" | translate}}</label>
        <div class="col-sm-8">
          <input
            class="form-control"
            formControlName="taxRegistration"
            type="text"
            [placeholder]= "'profile.billingInformation.placeholderMatricule' | translate"
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-sm-4 col-form-label label">{{"profile.billingInformation.address" | translate}}</label>
        <div class="col-sm-8">
          <input
            class="form-control"
            formControlName="address"
            type="text"
            [placeholder]="'profile.billingInformation.placeholderAdress' | translate"
          />
        </div>
      </div>
    </div>
  </div>
  <div  style="display: flex; justify-content: end">
    <button [disabled]="disableButton" class="btn btn-primary" type="button" (click)="submitBilling()">
      <loading-button [loading]="loading" [value]="'buttons.confirm'| translate" [icon]="null"></loading-button>
    </button>
  </div>
</form>
