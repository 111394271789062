import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeAllRegions,
  saveSelectedRegion,
} from "src/app/store/region/region.actions";
import {
  allRegionSelector,
  saveSelectedRegionSelector,
} from "src/app/store/region/region.selectors";
import { AllRegionInterface } from "../../models/region/region";

@Component({
  selector: "app-region",
  templateUrl: "./region.component.html",
})
export class RegionComponent extends BaseComponent implements OnInit {
  regionNames: string[] = [];
  listRegion: string[][] = [];
  region: string;
  allRegions$: Observable<AllRegionInterface[] | null>;
  getAllRegions: AllRegionInterface[];
  selectedRegion: string[] = [];
  saveSelectedRegion$: Observable<string[] | null>;
  constructor(
    private modalService: NgbModal,
    private store: Store<AppStateInterface>
  ) {
    super(store);
    this.allRegions$ = this.store
      .pipe(select(allRegionSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.saveSelectedRegion$ = this.store
      .pipe(select(saveSelectedRegionSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.store.dispatch(invokeAllRegions());
    this.allRegions$.subscribe((result: AllRegionInterface[] | null) => {
      if (result != null) this.getAllRegions = result;
    });
    this.saveSelectedRegion$.subscribe((data) => {
      if (data) {
        this.selectedRegion = data;
      }
    });
  }
  CheckRegionSelectedAll(): boolean {
    return this.getAllRegions.length == this.selectedRegion.length;
  }
  onClickDismissModal() {
    this.modalService.dismissAll();
  }
  selectAllRegions(event: any) {
    if (event.target.checked) {
      Object.values(this.getAllRegions).map((value, index) => {
        this.selectedRegion = [...this.selectedRegion, value.name];
      });
    } else {
      this.selectedRegion = [];
    }
  }
  searchTerm(region: string) {
    this.getAllRegions.filter((val) => {
      return val.name === region;
    });
  }
  submitRegion() {
    this.store.dispatch(
      saveSelectedRegion({ selectedRegion: this.selectedRegion })
    );
    this.onClickDismissModal();
  }
  saveRegion(event: any, lp: string) {
    if (event.target.checked) {
      this.selectedRegion = [...this.selectedRegion, lp];
    } else {
      this.selectedRegion = this.selectedRegion.filter(
        (location) => location != lp
      );
    }
  }
}
