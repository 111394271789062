import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MainComponent } from "./main/main.component";
import { StatisticsComponent } from "./statistics/statistics.component";
import { RemunerationComponent } from "./remuneration/remuneration.component";
import { PromoteComponent } from "./promote/promote.component";
import { BugReportComponent } from "src/app/shared/components/bug-report/bug-report.component";
import { CreateBugReportComponent } from "src/app/shared/components/bug-report/create-bug-report/create-bug-report.component";
import { UnlockedScreensComponent } from "./statistics/unlocked-screens/unlocked-screens.component";
import { ProfileComponent } from "src/app/shared/components/profile/profile.component";
import { DetailsDiscutionSupportComponent } from "src/app/shared/components/bug-report/details-discution-support/details-discution-support.component";

var routingAnimation = localStorage.getItem("animate");

const routes: Routes = [
  {
    path: "",
    redirectTo: "main",
    pathMatch: "full",
  },
  {
    path: "main",
    component: MainComponent,
    data: {
      animation: [routingAnimation],
      title: "Partner Dashboard | Relead ",
      description: " ",
    },
  },
  {
    path: "promote",
    component: PromoteComponent,
    data: {
      animation: [routingAnimation],
      title: 'Add new campaign | Relead ',
      description: " ",
    },
  },
  {
    path: "statistics",
    //component: StatisticsComponent,
    component: UnlockedScreensComponent,
    data: {
      animation: [routingAnimation],
      title: 'Advertising statistics | Relead  ',
      description: " ",
    },
  },
  {
    path: "remuneration",
    component: RemunerationComponent,
    data: {
      animation: [routingAnimation],
      title: "Remuneration information | Relead ",
      description: " ",
    },
  },
  {
    path: "support",
    component: BugReportComponent,
    data: {
      animation: [routingAnimation],
      title: 'Report a technical problem | Relead',
      description: " ",
    },
  },
  {
    path: "support/createBugReport",
    component: CreateBugReportComponent,
    data: {
      animation: [routingAnimation],
    },
  },
  {
    path: "profile",
    component: ProfileComponent,
    data: {
      animation: [routingAnimation],
      title: 'Company information | Relead ',
    },
  },
  {
    path: "support/details",
    component: DetailsDiscutionSupportComponent,
    data: {
      queryParams: {
        ref: "ref",
      },
      animation: [routingAnimation],
      title: 'See the details of the discussion | Relead  ',
    },
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LocationPartnerRoutingModule {}
