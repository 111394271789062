export enum SeriesTitles{
  VIEWS = "VIEWS",
  UNIQUE_VIEWS = "UNIQUE_VIEWS",
  //CLICKS ="CLICKS",
  COMPLETED="COMPLETED",
  CONSUMERS = "CONSUMERS"
}
export enum SeriesTitlesToShow {
  VIEWS = "Charts.views",
  UNIQUE_VIEWS = "Charts.uniqueViews",
  //CLICKS = "Clicks",
  COMPLETED = "Charts.completed",
  CONSUMERS = "Charts.consumers"
}
export interface SeriesTitlesInterface {
id: SeriesTitles;
value: string;
}