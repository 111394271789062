<div class="row">
  <div class="col-12 justify-items-center">
    <div class="card bg-secondary">
      <div class="card-body">
        <div class="media faq-widgets">
          <div class="media-body text-dark">
            <h6 class="f-w-600 text-dark">
              {{ "newCampaign.step4.justification.informations.title" | translate }}
            </h6>
            <ul class="m-b-10">
              <li> {{ "newCampaign.step4.justification.informations.line1" | translate }} </li>
              <li> {{ "newCampaign.step4.justification.informations.line2" | translate }} </li>
              <li> {{ "newCampaign.step4.justification.informations.line3" | translate }} </li>
            </ul>
            <h6 class="f-w-600 text-dark">{{ "newCampaign.step4.justification.operatingMode.title" | translate }}</h6>

            <ol>
              <p class="text-dark">
                <li>
                  {{ "newCampaign.step4.justification.operatingMode.line11" | translate }}
                  "ORDER N°: {{estimateNumber}}" {{ "newCampaign.step4.justification.operatingMode.line12" | translate }}
                </li>
                <li>{{ "newCampaign.step4.justification.operatingMode.line2" | translate }} {{ totalCost.toFixed(3) }} TND</li>
                <li>{{ "newCampaign.step4.justification.operatingMode.line31" | translate }}
                  <ul>
                    <li> {{ "newCampaign.step4.justification.operatingMode.line32" | translate }} ATTIJARI BANK</li>
                    <li> {{ "newCampaign.step4.justification.operatingMode.line33" | translate }} BOULEVARD YASSER ARRAFAT 4054 SAHLOUL TUNISIE</li>
                    <li> {{ "newCampaign.step4.justification.operatingMode.line34" | translate }} STE STRICS ADS</li>
                    <li> {{ "newCampaign.step4.justification.operatingMode.line35" | translate }} 04 022 108 0075538843 56</li>
                    <li> {{ "newCampaign.step4.justification.operatingMode.line36" | translate }} TN59 04022108007553884356</li>
                    <li> {{ "newCampaign.step4.justification.operatingMode.line37" | translate }} BSTUTNTTINT</li>
                  </ul>
                </li>
                <li>
                  {{ "newCampaign.step4.justification.operatingMode.line41" | translate }}
                  <a
                    class="txt"
                    href="mailto: preuve.paiement@relead.tn"
                    style="color: black"
                    >preuve.paiement@relead.tn</a
                  >
                  {{ "newCampaign.step4.justification.operatingMode.line42" | translate }}
                </li>
              </p>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
