import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MapGeocoder, MapGeocoderResponse } from "@angular/google-maps";
import { Store, select } from "@ngrx/store";
import { BehaviorSubject, Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { LocationAvailabilityEnum } from "src/app/shared/enum/location-availability";
import { LocationSearchMethod } from "src/app/shared/enum/locationSearchMethod";
import { ObjectiveTypeEnum } from "src/app/shared/enum/objectives";
import { AdDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-details.interface";
import {
  TotalCost,
  TotalCostWithDiscount,
} from "src/app/shared/models/calcul/totalCost";
import { searchGoogleResultInterface } from "src/app/shared/models/google-maps/search-google-result";
import { adDetailsSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import { invokeAdDetails } from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
import { AppStateInterface } from "src/app/store/appState.interface";
import { saveSelectedLocationsForMap, selectedLPs } from "src/app/store/locationPartner/location-partner.action";
import { calculateTotalLPCostByCampanyNamesSelector } from "src/app/store/locationPartner/location-partner.selectors";

@Component({
  selector: "app-campaign-locations",
  templateUrl: "./campaign-locations.component.html",
})
export class CampaignLocationsComponent
  extends BaseComponent
  implements OnInit
{
  objectiveName: ObjectiveTypeEnum;

  localStoreObjective: ObjectiveTypeEnum =
    ObjectiveTypeEnum.LOCAL_STORE_PROMOTIONS;
  localStoreForm: FormGroup<any>;

  active: number = 1;

  locationSearchMethod = LocationSearchMethod;

  emitUpdateNav = new BehaviorSubject<LocationSearchMethod>(
    LocationSearchMethod.LOCATION_PARTNER
  );

  calculateTotalLPCostByCampanyNames$: Observable<TotalCostWithDiscount | null>;
  calculateTotalLPCostByCampanyNames: TotalCost[] = [];

  adDetails$: Observable<AdDetailsInterface>;
  adDetails: AdDetailsInterface = initialState.adDetails;
  totalCostPerDay: number = 0!;
  impressionsPerDay: number = 0;

  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    private geocoder: MapGeocoder
  ) {
    super(store);
    this.adDetails$ = this.store
      .pipe(select(adDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.calculateTotalLPCostByCampanyNames$ = this.store
      .pipe(select(calculateTotalLPCostByCampanyNamesSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.localStoreForm = this.fb.group({
      longitude: [null],
      latitude: [null],
      search: [null, Validators.required],
      selected: [null],
    });
  }

  ngOnInit(): void {
    this.adDetails$.subscribe((result: AdDetailsInterface) => {
      this.adDetails = result;
      switch (result.calculationMethod) {
        case LocationSearchMethod.LOCATION_PARTNER:
          this.active = 1;
          break;
        case LocationSearchMethod.ZONE:
          this.active = 2;
          break;
        default:
          this.active = 1;
          break;
      }
    });

    this.calculateTotalLPCostByCampanyNames$.subscribe((value) => {
      if (value != null) {
        this.calculateTotalLPCostByCampanyNames = value.totalCostPerDay.filter(
          (lp: TotalCost) =>
            lp.availabilities.availabilityStatus !=
            LocationAvailabilityEnum.NOT_AVAILABLE
        );
        const locations: string[] = this.calculateTotalLPCostByCampanyNames
          .filter(
            (lp) =>
              lp.availabilities.availabilityStatus !=
              LocationAvailabilityEnum.NOT_AVAILABLE
          )
          .map((location) => location.companyName);
        this.store.dispatch(saveSelectedLocationsForMap({locations : locations}))
      }
    });
  }

  updateNav(method: LocationSearchMethod) {
    this.store.dispatch(
      invokeAdDetails({
        invokeAdDetails: {
          ...this.adDetails,
          calculationMethod: method,
          impressionsPerDay: 0,
          totalCostPerDay: 0,
          totalCost: 0,
          locations: [],
        },
      })
    );
  }

  @Output() listSelectedLocations = new EventEmitter<string[] | null>();
  @Output() cords = new EventEmitter<google.maps.LatLngLiteral>();
  searchGoogleMapsText: any;
  searchResultEmpty: any;

  selectedSearches: string[];

  searchGoogleResult: searchGoogleResultInterface[];
  searchResult: string[];
  searchTerm(text: any) {
    if (text != null) {
      this.geocoder
        .geocode({
          address: this.localStoreForm.get("search")?.value,
          region: "TN",
        })
        .subscribe((results: MapGeocoderResponse) => {
          if (results) {
            this.searchGoogleResult = results.results.map((value) => {
              return {
                searchName: value.formatted_address,
                latLng: {
                  lat: value.geometry.location.lat(),
                  lng: value.geometry.location.lng(),
                },
              };
            });
            this.searchResult = results.results.map(
              (value) => value.formatted_address
            );
            /* this.markerPosition = {
              lat: results.results[0].geometry.location.lat(),
              lng: results.results[0].geometry.location.lng(),
            }; */
          }
        });
    }
  }

  onSelectSearches() {
    const choosen: searchGoogleResultInterface | undefined =
      this.searchGoogleResult.find(
        (value) => value.searchName == this.selectedSearches[0]
      );
    if (choosen != undefined) {
      this.localStoreForm
        .get("latitude")
        ?.patchValue(this.formatPositionNumber(choosen.latLng.lat));
      this.localStoreForm
        .get("longitude")
        ?.patchValue(this.formatPositionNumber(choosen.latLng.lng));
      this.addMarker();
    }
  }
  formatPositionNumber(number: number | undefined): number {
    return Number(Number(number).toFixed(4));
  }

  addMarker(): any {
    if (this.localStoreForm.valid)
      this.cords.emit({
        lat: this.localStoreForm.get("latitude")?.value,
        lng: this.localStoreForm.get("longitude")?.value,
      });
  }
  //#endregion
}
