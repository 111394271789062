import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { map, Observable, of, Subject } from "rxjs";
import {
  invokeAddSynthesisReport, invokeUploadJustificationFile,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeUploadFile, setFileName } from "src/app/store/file/file.actions";
import { uploadFileSelector } from "src/app/store/file/file.selectors";
import { environment } from "src/environments/environment";
import { OptionActionEnum } from "../../data/actions-data";
import { ExtensionService } from "../../services/extension/extension.service";

@Component({
  selector: "app-upload-files",
  templateUrl: "./upload-files.component.html",
  styleUrls: ["./upload-files.component.scss"],
})
export class UploadFilesComponent implements OnInit, OnDestroy {
  @Output() closeTheModal: EventEmitter<boolean> = new EventEmitter();
  @Input() campaignHashedId: string;
  @Input() modalTitle : string
  @Input() action : OptionActionEnum
  showError: boolean = false;
  isShown: boolean = false;
  isUploaded: boolean;
  filename: string | null =null;
  files: File[] = [];
  uploadFile$: Observable<string | null>;
  ngDestroyed$: Subject<void> = new Subject<void>();
  savedFile : string
  accept : string
  constructor(
    private store: Store<AppStateInterface>,
    private modalService: NgbModal,
    private extensionService: ExtensionService,
    private translate : TranslateService
  ) {
    this.uploadFile$ = this.store
      .pipe(select(uploadFileSelector))
  }
  ngOnDestroy(): void {
    this.ngDestroyed$.next();
    this.ngDestroyed$.unsubscribe();
  }
  loadFile(): Observable<Blob> {
    let type : string
    switch(this.extensionService.getExtension(this.savedFile)){
      case 'pdf' :
      case 'pptx' : type = "application"; break
      case 'csv' : type = "text" ; break
      default : type = "image"
    }
    return of(environment.CDN_RELEAD + this.savedFile).pipe(
      map((data) => {
        const blob = new Blob([data], {
          type: `${type}/${this.extensionService.getExtension(this.savedFile)}`,
        });
        return blob;
      })
    );
  }
  ngOnInit(): void {
    if(this.savedFile){
      this.loadFile().subscribe((i) => {
        const myFile = new File(
          [i],
          this.campaignHashedId,
          {
            type: i.type,
          }
        );
        this.files.push(myFile);
      });
    }
    this.uploadFile$.subscribe((result: string | null) => {
      if (result != null) {
        this.filename = result;
        this.store.dispatch(setFileName({ filename: null }));
      }
    });
  }
  onSelect(event: File[]) {
    if (event[0].size > 4194304) this.showError = true;
    else {
      this.files = event;
      this.isShown = true;
    }
    const formData = new FormData();
    formData.append("files", this.files[0], this.files[0].name);
    this.store.dispatch(invokeUploadFile({ files: formData }));
  }
  onRemove() {
    this.files = [];
  }
  onClickSaveSynthesisReport() {
    switch(this.action){
      case OptionActionEnum.ADMIN_CAMPAIGN_ADD_SYNTHESIS_REPORT: {
        this.store.dispatch(
          invokeAddSynthesisReport({
            campaignHashedId: this.campaignHashedId,
            synthesisReport: this.filename,
          })
        );
        break;
      }
      case OptionActionEnum.CAMPAIGN_ADD_JUSTIFICATION_FILE: {
        this.store.dispatch(
          invokeUploadJustificationFile({
            campaignHashedId: this.campaignHashedId,
            justificationFile: this.filename,
          })
        );
        break;
      }
    }
    const modal = this.modalService.dismissAll(UploadFilesComponent);
  }
  closeModal() {
    this.closeTheModal.emit(true);
  }
}
