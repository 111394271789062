import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const commandeState = (state: AppStateInterface) => state.commandeState;

export const resultListOrdersFromIberisSelector = createSelector(
  commandeState,
  (state) => state.resultListOrdersFromIberis
)
export const resultDownloadEstimateSelector = createSelector(
  commandeState, 
  (state) => state.estimate
)
export const resultCreateEstimateSelector = createSelector(
  commandeState,
  (state) => state.resultCreateEStimate
)
export const resultUpdateEstimateSelector = createSelector(
  commandeState,
  (state) => state.resultUpdateEstimate
)
export const resultInvoiceEstimateSelector = createSelector(
  commandeState,
  (state) => state.resultInvoiceEstimate
)
export const loadingEstimateSelector = createSelector(
  commandeState,
  (state) => state.loading
)
export const resultMarkEstimateSelector = createSelector(
  commandeState,
  (state) => state.markEstimate
)
export const resultSynthesisEstimateSelector = createSelector(
  commandeState,
  (state) => state.resultSynthesisEstimate
)
