export enum bugReportStatus{
    IN_PROGRESS = "IN_PROGRESS",
    RESOLVED = "RESOLVED"
}
export enum bugReportToShow {
    IN_PROGRESS = "enumMapping.bugReport.filter.IN_PROGRESS",
    RESOLVED = "enumMapping.bugReport.filter.RESOLVED"
}

export interface BugReportStatusInterface {
  id: bugReportStatus;
  value: string;
}