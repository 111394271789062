import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { UpdatePaymentLP } from "src/app/shared/models/update-payment-LP.interface";
import { AppStateInterface } from "src/app/store/appState.interface"
import { invokeUpdatePaymentStatusLP } from "src/app/store/locationPartner/location-partner.action";

@Component({
  selector: "app-update-payment-status",
  templateUrl: "./update-payment-status.component.html",
})
export class UpdatePaymentStatusComponent
  implements OnInit
{
  @Input() title: string;
  @Input() companyName: string;
  @Input() advertisingCampaigns: AdvertisingCampaignInterface[] | null;
  @Input() dateRange: Date[];
  @Input() isPaid: boolean;
  @Input() companyNameFilter: string;
  updatePaymentStatusLP: UpdatePaymentLP = {
    companyName: "",
    advertisingCampaign: [],
  };
  campaignsHashedId: string[] = [];
  
  
  constructor(
    private modalService: NgbModal,
    private store: Store<AppStateInterface>,
  ) {
  }

  ngOnInit(): void {
    this.advertisingCampaigns?.map((e) => {
      this.campaignsHashedId = [...this.campaignsHashedId, e.advertisingCampaignHashedId];
    });

  }
  onClickDismissAll() {
    this.modalService.dismissAll();
  }
  onChangeSave(event: any, title: string) {
    if (event.target.checked) {
      this.campaignsHashedId = [...this.campaignsHashedId, title];
    } else {
      this.campaignsHashedId = this.campaignsHashedId.filter(
        (currentTitle) => currentTitle !== title
      );
    }
    
  }
  onClickUpdatePaymentStatus() {
    this.updatePaymentStatusLP = {
      companyName: this.companyName,
      advertisingCampaign: [...new Set(this.campaignsHashedId)],
    };
    if (this.dateRange != null) {
      this.store.dispatch(
        invokeUpdatePaymentStatusLP({
          updatePaymentStatusLP: this.updatePaymentStatusLP,
        })
      );
    }
  }
}
