import { createAction, props } from "@ngrx/store";
import { ActionInterface } from "src/app/shared/data/actions-data";
import { LanguageInterface } from "src/app/shared/data/languages";
import { OptionActionInterface } from "src/app/shared/models/option-action.interface";

export const invokeLanguage = createAction(
  "[ Invoke Language ] Invoke Language",
  props<{ lang: LanguageInterface }>()
);

export const saveUpdateFields = createAction(
  "[ Save Update fields ] Save update fields",
  props<{options : ActionInterface[]}>()
)

export const updateFields = createAction(
  "[ Update Fields ]Update fields",
  props<{ fields : OptionActionInterface }>()
);