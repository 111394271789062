import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const clientIberisState = (state: AppStateInterface) =>
  state.clientIberisState;
export const resultClientByHashedIdSelector = createSelector(
  clientIberisState,
  (state) => state.resultClientByHashedId
);
export const resultCreateUpdateClientSelector = createSelector(
  clientIberisState,
  (state) => state.createClient
)
export const loadingClientSelector = createSelector(
  clientIberisState,
  (state)=> state.loading
)
