<div class="modal-header">
  <div>
    <h4 class="modal-title" id="exampleModalLabel">
      {{"advertiser.select" | translate}}
    </h4>
  </div>
  <button type="button" class="btn-close" aria-label="Close" (click)="onClickCloseModal()"></button>
</div>
<div class="modal-body">
  <div class="media-space-between m-b-10">
    <div class=" col-xl-5 col-5">
      <div class="faq-form" style="color: darkgrey">
        <i class="search-icon" data-feather="search"></i>
        <form [formGroup]="form">
          <div class="input-group">
            <input
              id="table-complete-search"
              type="text"
              formControlName="email"
              class="form-control"
              [placeholder]="'placeholder.searchEmail_And_FullName' | translate"
              [(ngModel)]="advertiser"
              (input)="searchTerm($event)"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!(loading$ | async)">
    <div *ngFor="let advertiser of savedAdvertisers" class="col-6">
        <div class="form-check checkbox checkbox-primary mb-0">
          <input
            class="form-check-input m-r-10"
            [id]="advertiser.email"
            [checked]="advertisers.includes(advertiser.email)"
            (change)="save($event, advertiser.email)"
            type="checkbox"
          />
          <label class="form-check-label label" [for]="advertiser.email">{{ advertiser.email + " ("+ advertiser.firstName+" "+ advertiser.lastName+ ")"}}</label>
        </div>
      </div>
  </div>
  <div *ngIf="loading$ | async">
    <ng-container style="float: right" class="flex">
      <div class="loader-box">
        <div class="loader-34"></div>
      </div>
      <h6 class="col align-self-center f-w-600" style="text-align: center">
        {{ "fetching" | translate }}
      </h6>
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="submit()"> {{"buttons.confirm" | translate}} </button>
</div>
