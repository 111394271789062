<div class="modal-header">
  <div>
    <h3 class="modal-title" id="exampleModalLabel">
      {{ "select.selectRegion" | translate }}
    </h3>
  </div>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="onClickDismissModal()"
  ></button>
</div>
<div class="modal-body">
  <div class="media-space-between m-b-10">
    <div class="form-check checkbox checkbox-primary mb-0">
      <input
        class="form-check-input m-r-10"
        id="checkbox-primary-2"
        [checked]="CheckRegionSelectedAll()"
        (click)="selectAllRegions($event)"
        type="checkbox"
      />
      <label class="form-check-label" for="checkbox-primary-2">{{
        "select.selectAllRegions" | translate
      }}</label>
    </div>
    <div class=" col-xl-5 col-5">
      <div class="faq-form" style="color: darkgrey">
        <i class="search-icon" data-feather="search"></i>
        <div>
          <div class="input-group">
            <input
              id="table-complete-search"
              type="text"
              class="form-control"
              [placeholder]="'placeholder.search' | translate"
              [(ngModel)]="region"
              (ngModelChange)="searchTerm(region)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngFor="let region of getAllRegions" class="col-4 ">
    <div class="form-check checkbox checkbox-primary m-0">
        <input
          class="form-check-input m-r-10"
          [id]="region.name"
          [checked]="selectedRegion.includes(region.name)"
          (change)="saveRegion($event, region.name)"
          type="checkbox"
        />
        <label class="form-check-label" [for]="region.name">{{
          region.name
        }}</label>
      </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="submitRegion()">
    {{ "buttons.confirm" | translate }}
  </button>
</div>
