import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html'
})
export class HelpComponent implements OnInit {

  @Input() link : string
  @Input() type : string
  constructor() { }

  ngOnInit(): void {
  }

}
