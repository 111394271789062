import { Component, EventEmitter, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { ActionInterface, ActionReturned, billing_admin, OptionActionEnum } from "src/app/shared/data/actions-data";
import { InvoiceStatusEnum, InvoiceStatusEnumMapping } from "src/app/shared/enum/invoicesStatus";
import { AdsPublisherDetailsAndStatus } from "src/app/shared/models/advertising-campaigns/ads-publisher-details-and-status.interface";
import { Data_invoice } from "src/app/shared/models/iberis/facture/data_invoice";
import { ResultFacturesIberis } from "src/app/shared/models/iberis/facture/resultFacturesIberis";
import { CommonParamsIberis } from "src/app/shared/models/iberis/paramsForGetAll";
import { IColumn } from "src/app/shared/models/table/column.interface";
import { RoleEnum } from "src/app/shared/models/user/role.interface";
import { BadgeService } from "src/app/shared/services/badge.service";
import { JwtService } from "src/app/shared/services/jwt.service";
import { resultStatusesByListAdsSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import {
  invokeStatusesByListAds,
  resultStatusesByListAds,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeAllFacturesFromIberis,
  invokeDownloadInvoice,
  resultFacturesFromIberis,
} from "src/app/store/facture/facture.actions";
import {
  loadingInvoiceSelector,
  resultAllFacturesFromIberisSelector,
  resultDownloadInvoiceSelector,
} from "src/app/store/facture/facture.selectors";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-invoices",
  templateUrl: "./invoices.component.html",
})
export class InvoicesComponent extends BaseComponent implements OnInit {
  ad: string;
  columns: IColumn[] = [
    {
      key: "num",
      label: "admin.advertisers.billingNumber",
      sorter: true,
    },
    {
      key: "client",
      label: "admin.advertisers.responsable",
      sorter: true,
    },
    {
      key: "displayName",
      label: "admin.advertisers.companyName",
      sorter: true,
    },
    {
      key: "campanyName",
      label: "admin.advertisers.campaignName",
      sorter: true,
    },
    {
      key: "status",
      label: "admin.advertisers.status",
      sorter: true,
    },
    {
      key: "periode",
      label: "invoices.dateInvoice",
      sorter: true,
    },
    {
      key: "totals",
      label: "admin.advertisers.total",
      sorter: true,
    },
  ];
  params: CommonParamsIberis = {
    lang: "fr",
    companyId: environment.IBERIS.IBERIS_COMPANY_ID,
  };
  loading$: Observable<boolean>;
  listInvoicesFromIberis$: Observable<ResultFacturesIberis | null>;
  resultInvoicesFromIberis: Data_invoice[] = [];
  downloadInvoice$: Observable<any>;
  resultDownlaodInvoice: any;
  fileName: string;
  a: any;
  blob: Blob;
  totalRecords: number;
  itemsPerPage: number = 10;
  campaignsHashedIds: string[] = [];
  publisherAndStatus$: Observable<AdsPublisherDetailsAndStatus | null>;
  publisherAndStatus: AdsPublisherDetailsAndStatus ={
    firstName : [],
    lastName : [],
    companyName : [],
    status : []
  } ;
  InvoiceStatus = InvoiceStatusEnumMapping;
  isDownloadInvoice: boolean;
  specificColumun: number;
  options: ActionInterface[] = billing_admin;
  invoiceNumber : string
  pdfUrl = new EventEmitter<SafeResourceUrl>();
  constructor(
    private store: Store<AppStateInterface>,
    public badgeService: BadgeService,
    private jwtService: JwtService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) {
    super(store);
    this.downloadInvoice$ = this.store
      .pipe(select(resultDownloadInvoiceSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingInvoiceSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.listInvoicesFromIberis$ = this.store
      .pipe(select(resultAllFacturesFromIberisSelector))
      .pipe(takeUntil(this.ngDestroyed$));
      this.publisherAndStatus$ = this.store
      .pipe(select(resultStatusesByListAdsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.store.dispatch(
      resultStatusesByListAds({ publisherAndStatusDetails: null })
    );
    this.store.dispatch(
      resultFacturesFromIberis({ resultFacturesIberis: null })
    );
    this.listInvoicesFromIberis$.subscribe(
      (result: ResultFacturesIberis | null) => {
        if (result) {
          this.campaignsHashedIds = []
          this.totalRecords = result.data.invoices.recordsTotal;
          this.resultInvoicesFromIberis = result.data.invoices.data;
          result.data.invoices.data.map((e) => {
            if (e.object) {
              this.campaignsHashedIds = [...this.campaignsHashedIds, e.notes];
            }
          });
          this.store.dispatch(
            invokeStatusesByListAds({ campaignsHashedIds: this.campaignsHashedIds })
          );
        } else {
          if (
            this.jwtService.getRoleName(this.jwtService.getToken()) ==
            RoleEnum.ROLE_ADMIN
          ) {
            this.store.dispatch(
              invokeAllFacturesFromIberis({
                params: { ...this.params },
                start: 0,
                length: this.itemsPerPage,
                draw: this.itemsPerPage,
              })
            );
          }
        }
      }
    );
    this.publisherAndStatus$.subscribe((result) => {
      if (result) {
        this.publisherAndStatus = result;
      }
    });
    this.downloadInvoice$.subscribe((result) => {
      if (result) {
        this.resultDownlaodInvoice = result;
        this.blob = result.body as Blob;
        const pdf = URL.createObjectURL(this.blob);
        if (this.isDownloadInvoice) {
          
          const downloadLink = document.createElement("a");
          downloadLink.href = pdf;
          //TOFIX : ${invoice_Number}
          downloadLink.download = `${this.invoiceNumber}.pdf`;
          downloadLink.click();
          URL.revokeObjectURL(pdf);
          this.isDownloadInvoice = false;
        } else {
          this.pdfUrl.emit(this.sanitizer.bypassSecurityTrustResourceUrl(pdf));
        }
      }
    });
  }
  searchTerm(ad: string) {}
  changePage(event: number) {
    let start : number = 0
    if(event>1){
      start = this.itemsPerPage * (event-1)
    }else start = 0
    this.store.dispatch(
      invokeAllFacturesFromIberis({
        params: { ...this.params },
        start: start,
        length: this.itemsPerPage,
        draw: this.itemsPerPage,
      })
    );
  }
  statusIberis(invoice: Data_invoice) {
    if (invoice.unpaid == 0) {
      return InvoiceStatusEnum.PAYÉ;
    } else if (invoice.due > new Date()) {
      return InvoiceStatusEnum.EN_RETARD;
    } else {
      return InvoiceStatusEnum.VALIDE;
    }
  }
  down(hashed: string, invoice_Number: string, i: number) {
    this.specificColumun = i
    this.isDownloadInvoice = true;
    this.invoiceNumber = invoice_Number
    this.store.dispatch(
      invokeDownloadInvoice({ params: this.params, invoiceId: hashed })
    );
  }

  saveOption(action: ActionReturned, invoiceId: string, invoice_Number: string, i : number, showModal:any) {
    switch (action.option.type) {
      case OptionActionEnum.ADMIN_BILLING_SHOW: {
        this.isDownloadInvoice = false;
        this.store.dispatch(
          invokeDownloadInvoice({ params: this.params, invoiceId: invoiceId })
        );
        const modalRef = this.modalService.open(showModal, {
          centered: true,
          size : 'xl'
        });
        break;
      }
      case OptionActionEnum.ADMIN_BILLING_UPDATE: {
      this.down(invoiceId, invoice_Number, i)
        break;
      }
    }
  }
}
