<div class="d-flex justify-content-center align-items-center">
  <img src="assets/identity/get-started.png" alt="" />
</div>
<div
  *ngIf=" 
    paymentToken == undefined &&
    (justificationFile == '' ||
      justificationFile == null)
  "
>
  <div class="d-flex justify-content-center align-items-center">
    <h3>
      {{ "newCampaign.payment.success.withoutJustification.part1" | translate }}
      <b class="font-primary">{{ adTitle }}</b>
      {{ "newCampaign.payment.success.withoutJustification.part2" | translate }}
    </h3>
  </div>
  <div class="d-flex justify-content-center align-items-center">
    <p>
      {{ "newCampaign.payment.success.withoutJustification.part3" | translate }}
    </p>
  </div>
</div>
<div
  *ngIf="
    paymentToken != undefined ||
    (justificationFile != '' &&
      justificationFile != null)
  "
>
  <div class="d-flex justify-content-center align-items-center">
    <h3>
      {{ "newCampaign.payment.success.part1" | translate }}
      <b class="font-primary">{{ adTitle }}</b>
      {{ "newCampaign.payment.success.part2" | translate }}
    </h3>
  </div>
  <div class="d-flex justify-content-center align-items-center">
    <p>
      {{ "newCampaign.payment.success.part3" | translate }}
    </p>
  </div>
  <div
    class="d-flex justify-content-center align-items-center"
    *ngIf="paymentToken != undefined"
  >
    <a type="button" [routerLink]="['../../payments']">
      <b>{{ "newCampaign.payment.success.consultInvoice" | translate }}</b>
    </a>
  </div>
</div>
