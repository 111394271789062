export enum PaymentFrequency {
  ANNUAL = "ANNUAL",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  SEMI_ANNUALLY = "SEMI_ANNUALLY",
  NONE = "NONE",
}

export const PaymentFrequencyEnumMapping: Record<PaymentFrequency, string> = {
  [PaymentFrequency.ANNUAL]: "Annual",
  [PaymentFrequency.MONTHLY]: "Monthly",
  [PaymentFrequency.QUARTERLY]: "Quartely",
  [PaymentFrequency.SEMI_ANNUALLY]: "Semi Annually",
  [PaymentFrequency.NONE]: "None",
};

export interface PaymentFrequencyInterface {
  id: PaymentFrequency;
  value: string;
}
