<form [formGroup]="form">
  <!-- ... nested form controls ... -->
  <h5 style="text-align: left" *ngIf="!isUpdate">
    {{ "addRouter.locationInformation.title" | translate }}
  </h5>
  <div class="form-group">
    <label class="label"> {{ "routers.productionDate" | translate }} *</label>
    <div class="input-group">
      <div class="input-group date" id="dt-date" data-target-input="nearest">
        <div
          class="input-group-text bg-primary"
          data-target="#dt-date"
          [owlDateTimeTrigger]="dt"
        >
          <i
            class="icofont icofont-ui-calendar"
            style="color: white; font-size: 12px"
          ></i>
        </div>
        <input
          class="form-control datetimepicker-input digits"
          [owlDateTime]="dt"
          [owlDateTimeTrigger]="dt"
          [placeholder]="
            'addRouter.locationInformation.placeholderProductionDate'
              | translate
          "
          formControlName="dateOfProduction"
        />
      </div>
      <owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>
    </div>
  </div>
  <div class="form-group">
    <label class="label">{{ "routers.location" | translate }} *</label>
    <div class="input-group">
      <select
        class="form-select form-control digits"
        formControlName="locationPartner"
      >
        <option [value]="null" selected>
          {{ "addRouter.locationInformation.placeholderLocation" | translate }}
        </option>
        <option
          class="form-select"
          [value]="companyName"
          *ngFor="let companyName of resultAllCompanyNames"
        >
          {{ companyName }}
        </option>
      </select>
    </div>
  </div>
</form>
