import { Component, Input, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import { BrowserFamily } from "../../models/statistics/browserFamily";
import { setBrowserFamily } from "src/app/store/consumers/consumers.actions";
import { loadingConsumerSelector } from "src/app/store/consumers/consumers.selectors";

@Component({
  selector: "app-progress-bar",
  templateUrl: "./progress-bar.component.html",
})
export class ProgressBarComponent extends BaseComponent implements OnInit {
  @Input("resultbrowserFamily")
  resultbrowserFamily: Observable<BrowserFamily | null>;
  length = 0;
  percentage: number[] = [];
  result: number[] = [];
  keys: string[] = [];
  values: number[] = [];
  isLoading : boolean = true
  constructor(private store: Store<AppStateInterface>) {
    super(store);
  }
  ngOnInit(): void {
    this.store.dispatch(setBrowserFamily({ browserFamily: null }));
    this.resultbrowserFamily.subscribe((result) => {
      if (result != null) {
        this.isLoading = false
        this.percentage = []
        this.values = Object.values(result);
        this.length = this.values.reduce((acc, current) => acc + current, 0);
        const sortedEntries = Object.entries(result).sort((a, b) => b[1] - a[1]);
        const objetTrie = sortedEntries.reduce((acc: { [key: string]: number }, [key, value]: [string, any]) => {
          acc[key] = value;
          return acc;
        }, {});
        this.keys = Object.keys(objetTrie);
        
        Object.values(objetTrie).forEach(value => {
          this.percentage.push((value / this.length) * 100);
        });
        this.percentage = [...new Set(this.percentage)];
      }
    });
  }
}
