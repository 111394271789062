import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const selectFeature = (state: AppStateInterface) => state.authState;

export const accountSelector = createSelector(
  selectFeature,
  (state) => state.account
);

export const userIsLoggedInSelector = createSelector(
  selectFeature,
  (state) => state.token
);

export const authLoadingSelector = createSelector(
  selectFeature,
  (state) => state.loading
);

export const logoutSelector = createSelector(
  selectFeature,
  (state) => state.logout
);