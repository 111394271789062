import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, Subject, takeUntil } from "rxjs";
import {
  CampaignStatusEnum,
  CampaignStatusEnumMapping,
  CampaignStatusInterface,
} from "src/app/shared/enum/campaign-status";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { FilterListAdvertisingCompaign } from "src/app/shared/models/advertising-campaigns/filter-list-advertising-compaign.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { updateAdCampaignDetailsSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import { invokeChangeAdStatus } from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { initialState } from "src/app/store/advertisingCampaign/advertisingCampaign.reducers";
import { AppStateInterface } from "src/app/store/appState.interface";
import { invokeCampaignStarts, invokeMarkAdAsFinished } from "src/app/store/mailing/mailing.actions";

@Component({
  selector: "app-update-campaign-status",
  templateUrl: "./update-campaign-status.component.html"
})
export class UpdateCampaignStatusComponent implements OnInit {
  @Input() detailsAd: AdvertisingCampaignInterface;
  form: FormGroup;
  advertisingCompaignFilter: FilterListAdvertisingCompaign = {
    ...initialState.filter,
  };
  campaignStatus: CampaignStatusInterface[];
  loading : boolean = false
  updateStatus$ : Observable<SuccessDto | null>
  ngDestroyed$: Subject<void> = new Subject<void>();
  constructor(
    private store: Store<AppStateInterface>,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      status: [null],
    });
    this.updateStatus$ = this.store
    .pipe(select(updateAdCampaignDetailsSelector))
    .pipe(takeUntil(this.ngDestroyed$));
  }
  ngOnDestroy(): void {
    this.ngDestroyed$.next();
    this.ngDestroyed$.unsubscribe();
  }
  ngOnInit(): void {
    this.form.patchValue({ status: this.detailsAd.status });
    this.campaignStatus = Object.keys(CampaignStatusEnum)
      .filter((value) => isNaN(Number(value)) === true)
      .map((key: string | any) => ({
        id: CampaignStatusEnum[key as keyof typeof CampaignStatusEnum],
        value:
          CampaignStatusEnumMapping[key as keyof typeof CampaignStatusEnum],
      }));
      this.updateStatus$.subscribe((data) => {
        if (data) {
          this.loading = false
          switch(this.form.get("status")?.value){
            case CampaignStatusEnum.ONGOING : {
              this.store.dispatch(invokeCampaignStarts({email : this.detailsAd.emailPublisher}))
              break
            }
            case CampaignStatusEnum.FINISHED : {
              this.store.dispatch(invokeMarkAdAsFinished({email : this.detailsAd.emailPublisher}))
            }
          }
        }
      });
  }

  updateStatus() {
    this.loading = true
    this.store.dispatch(
      invokeChangeAdStatus({
        campaignHashedId: this.detailsAd.advertisingCampaignHashedId,
        status: this.form.get("status")?.value,
      })
    );
  }
  closeModal() {
    this.modalService.dismissAll();
  }
}
