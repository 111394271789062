import { createAction, props } from "@ngrx/store";
import {
  AllCategoryInterface,
  CreateUpdateCategoryInterface,
} from "../../shared/models/category/category";

export const invokeAllCategoryNames = createAction(
  "[ Invoke All Category Names ] Invoke all category names"
);

export const setAllCategoryNames = createAction(
  "[ Set All Category names ] Get all category names",
  props<{ categoryNames: string[] | null }>()
);

export const invokeAllCategories = createAction(
  "[ Invoke All Categories ] Invoke all categories"
);

export const setAllCategories = createAction(
  "[ Set All Categories ] Get all categories",
  props<{ categories: AllCategoryInterface[] | null }>()
);

//Create
export const invokeCreateCategory = createAction(
  "[ Invoke Create Category ] invoke create category",
  props<{ category: CreateUpdateCategoryInterface }>()
);
export const createUpdateCategorySuccess = createAction(
  "[ Create Update Category Success] create update category success",
  props<{ message: string | null }>()
);

//Update
export const invokeUpdateCategory = createAction(
  "[ Invoke Update Category ] invoke update category",
  props<{ category: CreateUpdateCategoryInterface; oldName: string }>()
);

//Delete category
export const invokeDeleteCategory = createAction(
  "[ Invoke Delete Category ] invoke delete Category",
  props<{ categoryName: string }>()
);
export const deleteCategorySuccess = createAction(
  "[ Delete Category Success ] delete category success",
  props<{ message: string | null }>()
);


//save category 
export const saveCategory = createAction(
  "[ Save Category ] Save category ",
  props<{selectedCategories : string[]}>()
)