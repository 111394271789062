import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-text',
  templateUrl: './payment-text.component.html',
  styleUrls: ['./payment-text.component.scss']
})
export class PaymentTextComponent implements OnInit {

  @Input() totalCost: number
  @Input() estimateNumber : string
  constructor() { }

  ngOnInit(): void {
  }

}
