<div class="header-wrapper row m-0">
  <div class="header-logo-wrapper col-auto p-0">
    <div class="logo-wrapper">
      <!--<a routerLink="/"
        ><img
          class="img-fluid image-dark"
          src="assets/images/logo/logo.png"
          alt=""
      /></a>-->
      <a routerLink="/" >
        <img
        class="img-fluid for-light"
        src="assets/images/logo/small-logo.png"
        alt=""
      />
      <img
        class="img-fluid for-dark"
        src="assets/images/logo/logo-relead white.png"
        alt=""
      /></a>
    </div>
    <div class="toggle-sidebar" (click)="sidebarToggle()">
      <div class="status_toggle sidebar-toggle d-flex">
        <svg-icon src="assets/svgIcon/menu.svg"></svg-icon>
      </div>
    </div>
  </div>
  <div class="left-side-header col ps-0 d-none d-md-block">
    <!-- <app-search></app-search> -->
  </div>
  <div class="nav-right col-10 col-sm-6 pull-right right-header p-0">
    <ul class="nav-menus">
      <li class="onhover-dropdown">
        <app-language></app-language>
      </li>
      <li>
        <a (click)="goSettings()" type="button">
          <svg-icon class="custom-svg" [ngbTooltip]="'settings.title' | translate" src="assets/svgIcon/buttons.svg" alt="settings">
          </svg-icon>
        </a>
      </li>
      <li class="mode" (click)="layoutToggle()">
        <app-mode></app-mode>
      </li>
      <li class="d-md-none resp-serch-input">
        <app-search-customize></app-search-customize>
      </li>
      <!--<li class="cart-nav onhover-dropdown">
        <app-cart></app-cart>
      </li>-->
      <!-- <li class="onhover-dropdown">
        <app-sidebar-type></app-sidebar-type>
      </li> -->
     <!--  <li class="onhover-dropdown">
        <app-bookmark></app-bookmark>
      </li> -->
      <li class="maximize">
        <app-maximize></app-maximize>
      </li>
      <li class="profile-nav onhover-dropdown pe-0 py-0 me-0">
        <app-account></app-account>
      </li>
    </ul>
  </div>
</div>
