import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Constants } from "../shared/data/constants";
import { environment } from "src/environments/environment";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.method === "JSONP" ||
      request.url.includes("/api/advertisers/register") ||
      request.url.includes("/api/users/login") ||
      request.url.includes("/api/users/resetPassword") ||
      request.url.includes("/api/mails/confirmEmail") ||
      request.url.includes("/api/users/exists") ||
      request.url.includes("/api/users/forgotPassword") ||
      request.url.includes("confirm-registration") ||
      request.url.includes("regenerate-confirm-registration") ||
      request.url.includes(`${Constants.FILE_ENDPOINT}/uploadFile`) ||
      request.url.includes("jsonip.com")
    ) {
      return next.handle(request);
    }
    if (request.url.includes(environment.APP_PORT_IBERIS)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${environment.IBERIS.TOKEN}`,
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
        },
      });
    } else if (request.url.includes("paymee.tn")) {
      request = request.clone({
        setHeaders: {
          Authorization: `${environment.PAYMENT.APP_PAYMEE.TOKEN}`,
        },
      });
    } else if (request.url.includes(".konnect.network/api/v2")) {
      request = request.clone({
        setHeaders: {
          "x-api-key": `${environment.PAYMENT.APP_KONNECT.TOKEN}`,
        },
      });
    } else {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          //Platforme: "Relead",
          "Ip-Address": `${localStorage.getItem("Ip-Address")}`,
        },
      });
    }

    // add authorization header with jwt token if available

    return next.handle(request);
  }
}
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
];
