import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RoleInterface } from "../models/role/role.interface";
import { Constants } from "../data/constants";
import { SuccessDto } from "../models/success-dto";
import { environment } from "src/environments/environment";
import {
  SubRoleInterface,
  SubRoleUsersInterface,
} from "../models/sub-role/subRole.interface";
import { subRole } from "../models/sub-role/subRole";

@Injectable({
  providedIn: "root",
})
export class SubRoleService {
  constructor(private http: HttpClient) {}

  getAllSubRoles(): Observable<SubRoleInterface[]> {
    return this.http.get<SubRoleInterface[]>(
      `${environment.APP_PORT + Constants.SUB_ROLE_ENDPOINT + "/all"}`
    );
  }

  getSubRole(roleName: string, name: string): Observable<SubRoleInterface> {
    return this.http.get<SubRoleInterface>(
      `${
        environment.APP_PORT +
        Constants.SUB_ROLE_ENDPOINT +
        "/" +
        roleName +
        "/" +
        name
      }`
    );
  }

  getSubRolesNames(): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.APP_PORT + Constants.SUB_ROLE_ENDPOINT}/names`
    );
  }

  createSubRole(createSubRole: SubRoleInterface): Observable<SuccessDto> {
    return this.http.post<SuccessDto>(
      `${environment.APP_PORT + Constants.SUB_ROLE_ENDPOINT + "/create"}`,
      createSubRole
    );
  }

  updateSubRole(roleName: string, name: string, subRole: SubRoleInterface) {
    return this.http.patch<SuccessDto>(
      `${
        environment.APP_PORT +
        Constants.SUB_ROLE_ENDPOINT +
        "/update/" +
        roleName +
        "/" +
        name
      }`,
      subRole
    );
  }
  allSubRoles(): Observable<subRole[]> {
    return this.http.get<subRole[]>(
      `${environment.APP_PORT + Constants.SUB_ROLE_ENDPOINT}/allSubRoles`
    );
  }

  deleteSuRole(roleName: string, subRoleName: string): Observable<SuccessDto> {
    return this.http.delete<SuccessDto>(
      `${environment.APP_PORT + Constants.SUB_ROLE_ENDPOINT}/` +
        roleName +
        "/" +
        subRoleName
    );
  }
}
