import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Constants } from '../../data/constants';
import { RoutersFilterInterface, } from '../../models/router/routersFilter.interface';
import { RouterInterface } from '../../models/router/router.interface';
import { RoutersResultInterface } from '../../models/router/routersResult.interface';
import { SuccessDto } from '../../models/success-dto';

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  constructor(private http: HttpClient) { }

  createRouter(router: RouterInterface): Observable<RouterInterface>{
    return this.http.post<RouterInterface>(
      `${environment.APP_PORT + Constants.ROUTER_ENDPOINT
    }/create`,router
    );
  }

  updateRouter(oldMAC: string,router: RouterInterface): Observable<SuccessDto> {
    return this.http.patch<SuccessDto>(
      `${environment.APP_PORT + Constants.ROUTER_ENDPOINT
      }/${oldMAC}`,
      router 
    );
  }
  deleteByMAC(MAC: string) : Observable<SuccessDto>{
    return this.http.delete<SuccessDto>(
      `${environment.APP_PORT + Constants.ROUTER_ENDPOINT
      }/`+MAC
    );
  }

  getRouterByMAC(MAC: string): Observable<RouterInterface> {
    return this.http.get<RouterInterface>(
      `${environment.APP_PORT + Constants.ROUTER_ENDPOINT
      }/`+MAC,
    );
  }
  
  getRouterByFilter(listRouterFilter: RoutersFilterInterface): Observable<RoutersResultInterface> {
    return this.http.post<RoutersResultInterface>(
      `${environment.APP_PORT + Constants.ROUTER_ENDPOINT}/filter`,listRouterFilter 
    )
  }

  isExistByMAC(MAC: string) : Observable<boolean | null>{
    return this.http.get<boolean | null>(
      `${environment.APP_PORT + Constants.ROUTER_ENDPOINT
      }/isExist/`+MAC
    );
  }
}
