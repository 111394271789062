import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { BaseComponent } from "src/app/base.component";
import {
  SeriesTitles,
  SeriesTitlesInterface,
  SeriesTitlesToShow,
} from "src/app/shared/models/chart/seriesTitles";
import { AppStateInterface } from "src/app/store/appState.interface";

@Component({
  selector: "app-spline-filter",
  templateUrl: "./spline-filter.component.html",
})
export class SplineFilterComponent extends BaseComponent implements OnInit {
  openSidebar: boolean = false;
  active = 1;
  show = false;
  activeImpression: number = 0;
  activeUniqueViews: number = 0;
  activeCompleted: number = 0;

  interface: SeriesTitlesInterface[];
  @Input("filter") filter: any;
  @Output() optionSelected = new EventEmitter<string>();
  @Output() optionColumnChartClicked = new EventEmitter<string>();
  @Input() type: string;
  constructor(private store: Store<AppStateInterface>, private cdr : ChangeDetectorRef) {
    super(store);
  }
  ngOnInit(): void {
    this.interface = Object.keys(SeriesTitles)
      .filter((value) => isNaN(Number(value)) === true)
      .map((key: string | any) => ({
        id: SeriesTitles[key as keyof typeof SeriesTitles],
        value: SeriesTitlesToShow[key as keyof typeof SeriesTitles],
      }));
  }
  sidebarToggle() {
    this.openSidebar = !this.openSidebar;
  }
  openMenu() {
    this.show = !this.show;
  }
  saveOptionForSummaryChart(option: string) {
    switch (option) {
      case SeriesTitlesToShow.COMPLETED:
        this.optionSelected.emit(SeriesTitlesToShow.COMPLETED);
        this.activeCompleted++;
        break;
      case SeriesTitlesToShow.UNIQUE_VIEWS:
        this.optionSelected.emit(SeriesTitlesToShow.UNIQUE_VIEWS);
        this.activeUniqueViews++;
        break;
      case SeriesTitlesToShow.VIEWS:
        this.optionSelected.emit(SeriesTitlesToShow.VIEWS);
        this.activeImpression++;
        break;
      default:
        break;
    }
  }
  public onOutsideClick(event: any): void {
    this.openSidebar = false;
    this.show = false;
    this.cdr.detectChanges()
  }
}
