export enum EstimateStatusEnum{
    UNPAID = "UNPAID",
    PAID = "PAID",
}

  
  export const EstimateStatusEnumMapping: Record<EstimateStatusEnum, string> = {
    [EstimateStatusEnum.UNPAID]: "enumMapping.estimate.unpaid",
    [EstimateStatusEnum.PAID] : "enumMapping.estimate.paid"
  };

  
  export interface EstimateStatusEnumInterface {
    id: EstimateStatusEnum;
    value: string;
  }