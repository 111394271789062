import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import { resultLocationPartnerReviewSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { Pager } from "src/app/shared/models/pagination";
import { FilterLastWeekForLPReview } from "src/app/shared/models/location-partners/filterLastWeekForLPReview";
import {
  ExportData,
  exportDataType,
  LocationOverviewData,
} from "src/app/shared/data/exportData";
import { TranslateService } from "@ngx-translate/core";
import { invokeLocationPartnerReview, resultLocationPartnerReview } from "src/app/store/locationPartner/actions/get-impressions.actions";
import { invokeApiDestroyedLocation_overview } from "src/app/store/apiState.interface";
@Component({
  selector: "app-location-partner-preview",
  templateUrl: "./location-partner-preview.component.html",
})
export class LocationPartnerPreviewComponent
  extends BaseComponent
  implements OnInit
{
  @Input() loading: boolean | null = false;
  @Output("locationPreview") locationPreview = new EventEmitter<ExportData[]>();
  @Output("tableLocationPreview") tableLocationPreview =
    new EventEmitter<any>();
  pageSize: number = 10;
  pageNumber: number = 1;
  pagerLocationPartnerPreview: Pager = {
    pageSize: this.pageSize,
    pageNumber: this.pageNumber,
  };
  locationPartnerReview$: Observable<FilterLastWeekForLPReview | null>;
  lastWeekLocationPartnerReview: FilterLastWeekForLPReview | null;
  resultCountLOcationPartnerPreview: number;

  public last7Days: Date[] = [];
  last7DaysString: string[] = [];
  location: ExportData[] = LocationOverviewData;
  constructor(
    private store: Store<AppStateInterface>,
    private translate: TranslateService
  ) {
    super(store);
    this.locationPartnerReview$ = this.store
      .pipe(select(resultLocationPartnerReviewSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.getLast7Days();
  }

  ngOnInit(): void {
    this.store.dispatch(
      resultLocationPartnerReview({ lastWeekReviewLP: null })
    );
    this.locationPartnerReview$.subscribe(
      (result: FilterLastWeekForLPReview | null) => {
        if (result != null) {
          this.lastWeekLocationPartnerReview = result;
          this.resultCountLOcationPartnerPreview = result.count;
          if(this.pagerLocationPartnerPreview.pageSize == undefined){
            this.pagerLocationPartnerPreview = {
              ...this.pagerLocationPartnerPreview,
              pageSize : result.count
            }
          }
          if (this.lastWeekLocationPartnerReview.locationPartnerChecks) {
            const csvRows: string[] = [];
            let headers: string[] = [];
            headers.push(
              this.translate.instant("dashboard.admin.lpReview.companyName")
            );
            this.last7DaysString.map((day) => {
              headers.push(day);
            });
            headers.push(
              this.translate.instant("dashboard.admin.lpReview.averageViews")
            );
            this.lastWeekLocationPartnerReview.locationPartnerChecks.forEach(
              (item) => {
                const row = [item.companyName, item.views, item.average];
                csvRows.push(row.join(","));
              }
            );
            for (let i = 0; i < this.location.length; i++) {
              if (this.location[i].type == exportDataType.LP_REVIEW_ADMIN)
                this.location[i].data = [headers, ...csvRows].join("\n");
            }
            this.locationPreview.emit(this.location);
          }
        } else {
          this.store.dispatch(
            invokeLocationPartnerReview({
              pager: this.pagerLocationPartnerPreview,
            })
          );
        }
      }
    );
  }
  get changePageSize() {
    return this.pagerLocationPartnerPreview.pageSize;
  }
  set changePageSize(pageSize: number) {
    this.itemsPerPageChange(pageSize);
  }
  itemsPerPageChange(pageSize: number) {
    this.store.dispatch(invokeApiDestroyedLocation_overview())
    this.pagerLocationPartnerPreview = {
      ...this.pagerLocationPartnerPreview,
      pageSize: pageSize,
    };
    this.store.dispatch(
      invokeLocationPartnerReview({
        pager: {
          ...this.pagerLocationPartnerPreview,
        },
      })
    );
  }
  changePage(event: number) {
    this.store.dispatch(invokeApiDestroyedLocation_overview())
    this.store.dispatch(
      invokeLocationPartnerReview({
        pager: {
          ...this.pagerLocationPartnerPreview,
          pageNumber: event,
        },
      })
    );
  }
  filter() {
    this.pageNumber = 1;
    //this.isFiltring = true;
    this.store.dispatch(
      invokeLocationPartnerReview({
        pager: {
          ...this.pagerLocationPartnerPreview,
          pageNumber: this.pageNumber,
        },
      })
    );
  }
  getLast7Days() {
    const today = new Date();
    for (let i = 0; i < 7; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      this.last7Days.push(date);
      this.last7DaysString.push(date.toString());
    }
    this.last7Days;
  }
}
