import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../data/constants";
import { SuccessDto } from "../models/success-dto";
import { ObjectiveInterface } from "../models/objectives/objectives";

@Injectable({
  providedIn: "root",
})
export class ObjectiveService {
  constructor(private http: HttpClient) {}
  getAllObjectives(): Observable<ObjectiveInterface[]> {
    return this.http.get<ObjectiveInterface[]>(
      `${environment.APP_PORT + Constants.OBJECTIVE_ENDPOINT}/allObjectives`
    );
  }
  updateObjective(objective: ObjectiveInterface): Observable<SuccessDto> {
    return this.http.patch<SuccessDto>(
      `${environment.APP_PORT + Constants.OBJECTIVE_ENDPOINT}/update`,
      objective
    );
  }
  deleteObjective(name: string): Observable<SuccessDto> {
    return this.http.delete<SuccessDto>(
      `${environment.APP_PORT + Constants.OBJECTIVE_ENDPOINT}/${name}`,
    );
  }
}
