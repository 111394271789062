import { createReducer, on } from "@ngrx/store";
import {
  setAllCategoryNames,
  invokeAllCategoryNames,
  invokeAllCategories,
  setAllCategories,
  createUpdateCategorySuccess,
  deleteCategorySuccess,
  saveCategory,
  invokeDeleteCategory,
} from "./category.actions";
import { CategoryStateInterface } from "./categoryState.interface";

export const initialState: Readonly<CategoryStateInterface> = {
  loading: false,
  categoryNames: null,
  categories: null,
  createUpdateSuccess: null,
  deleteSuccess: null,
  selectedCategories: [],
};
export const CategoryReducer = createReducer(
  initialState,
  on(invokeAllCategoryNames, (state) => ({
    ...state,
    loading: true,
  })),
  on(setAllCategoryNames, (state, { categoryNames }) => ({
    ...state,
    categoryNames: categoryNames,
    loading: false,
  })),
  on(invokeAllCategories, (state) => ({
    ...state,
    loading: true,
  })),
  on(setAllCategories, (state, { categories }) => ({
    ...state,
    categories: categories,
    loading: false,
  })),

  on(createUpdateCategorySuccess, (state, { message }) => ({
    ...state,
    createUpdateSuccess: message,
    loading: false,
  })),

  on(invokeDeleteCategory, (state) => ({
    ...state,
    loading: true,
  })),
  on(deleteCategorySuccess, (state, { message }) => ({
    ...state,
    loading: false,
    deleteSuccess: message,
  })),
  on(saveCategory, (state, { selectedCategories }) => ({
    ...state,
    selectedCategories: selectedCategories,
  }))
);
