import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActionInterface, ActionReturned } from "../../data/actions-data";

@Component({
  selector: "app-drob-zone-options",
  templateUrl: "./drob-zone-options.component.html",
})
export class DrobZoneOptionsComponent implements OnInit {
  @Input() j: number;
  @Input() options: ActionInterface[];
  @Input() value: string | null;
  @Input() isLoading: boolean | null = false;
  @Input() IndexJ : number
  @Output() optionSelected = new EventEmitter<ActionReturned>();
  showOption: boolean[] = [false];
  i: number;
  constructor(private cdr : ChangeDetectorRef) {}
  option: string;
  ngOnInit(): void {}
  openOptions(j: number) {
    this.showOption[j] = !this.showOption[j];
  }
  onClickEmitOption(option: ActionInterface, value: any) {
    this.optionSelected.emit({ option: option, value: value });
  }
  public onOutsideClick(event: any, j: number): void {
    this.showOption[j] = false;
    this.cdr.detectChanges()
  }
}
