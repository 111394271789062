import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../data/constants";
import { CreateUpdateZoneInterface, ZoneInterface } from "../models/zone/zone";
import { SuccessDto } from "../models/success-dto";

@Injectable({
  providedIn: "root",
})
export class ZoneService {
  constructor(private http: HttpClient) {}
  getAllZones(): Observable<ZoneInterface[]> {
    return this.http.get<ZoneInterface[]>(
      `${environment.APP_PORT + Constants.ZONE_ENDPOINT}/allZones`
    );
  }

  createZone(zone: CreateUpdateZoneInterface): Observable<SuccessDto> {
    return this.http.post<SuccessDto>(
      `${environment.APP_PORT + Constants.ZONE_ENDPOINT}/create`,
      zone
    );
  }

  getZonesByRegion(listRegion: string[]): Observable<string[][] | null> {
    return this.http.post<string[][]>(
      `${environment.APP_PORT + Constants.ZONE_ENDPOINT}/zoneByRegion`,
      listRegion
    );
  }

  updateZone(
    zone: CreateUpdateZoneInterface,
    oldName: string
  ): Observable<SuccessDto> {
    return this.http.patch<SuccessDto>(
      `${environment.APP_PORT + Constants.ZONE_ENDPOINT}/` + oldName,
      zone
    );
  }

  deleteZone(zoneName: string): Observable<SuccessDto> {
    return this.http.delete<SuccessDto>(
      `${environment.APP_PORT + Constants.ZONE_ENDPOINT}/` + zoneName
    );
  }
  
  getAllZoneNames(): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.APP_PORT + Constants.ZONE_ENDPOINT}/allZoneNames`
    );
  }
}
