import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  advertiserLoadingSelector,
  advertiserProfileDetailsSelector,
} from "src/app/store/advertiser/advertiser.selectors";
import { Observable, takeUntil } from "rxjs";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import {
  invokeUpdateAdvertiser,
  invokeProfileDetails,
} from "src/app/store/advertiser/advertiser.actions";
import { JwtService } from "src/app/shared/services/jwt.service";
import { resultLPInfoSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { RoleEnum } from "src/app/shared/models/user/role.interface";
import { LPInfo } from "src/app/shared/models/location-partners/lpInfo";
import { invokeUpdateAccount } from "src/app/store/locationPartner/actions/update.actions";
import { invokeLPInfo } from "src/app/store/locationPartner/actions/get-locations.actions";
import { TranslateService } from "@ngx-translate/core";
import { IberisActivities } from "src/app/shared/models/iberis/activities";
import { AdminProfileInterface } from "src/app/shared/models/admin/admin-profile.interface";
import { adminProfileSelector } from "src/app/store/admin/admin.selectors";
import {
  invokeAdminProfile,
  invokeUpdateAdminProfile,
} from "src/app/store/admin/admin.actions";

@Component({
  selector: "app-update-profile",
  templateUrl: "./update-profile.component.html",
})
export class UpdateProfileComponent extends BaseComponent implements OnInit {
  @Input() companyActivities: IberisActivities[];
  editProfileForm: FormGroup;
  submitted = false;
  loading$: Observable<boolean | null>;
  profile$: Observable<AdvertiserDetailsInterface[]>;

  profileDetails$: Observable<AdvertiserDetailsInterface | null>;
  profileDetails: any;
  adminProfile$: Observable<AdminProfileInterface | null>;

  role: string;
  profileDetailsLP$: Observable<LPInfo | null>;
  roleName = RoleEnum;
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    private jwtService: JwtService
  ) {
    super(store);

    this.profileDetailsLP$ = this.store
      .pipe(select(resultLPInfoSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(advertiserLoadingSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.profileDetails$ = this.store
      .pipe(select(advertiserProfileDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.adminProfile$ = this.store
      .pipe(select(adminProfileSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.editProfileForm = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      email: [null, [Validators.required]],
      companyName: [null, [Validators.required]],
      companyActivity: [null, [Validators.required]],
      companyAddress: [null, [Validators.required]],
      website: [null, [Validators.required]],
      birthday: [null],
      companyRole: [null],
    });
  }

  ngOnInit(): void {
    this.role = this.jwtService.getRoleName(this.jwtService.getToken());

    if (this.role == RoleEnum.ROLE_ADMIN) {
      this.adminProfile$.subscribe((result: AdminProfileInterface | null) => {
        if (result != null) {
          this.profileDetails = result;
          this.editProfileForm.patchValue(result);
        } else {
          this.store.dispatch(invokeAdminProfile());
        }
      });
    }

    if (this.role == RoleEnum.ROLE_ADVERTISER) {
      this.profileDetails$.subscribe(
        (result: AdvertiserDetailsInterface | null) => {
          if (result != null) {
            this.profileDetails = result;
            this.editProfileForm.patchValue(result);
          } else {
            this.store.dispatch(invokeProfileDetails());
          }
        }
      );
    }

    if (this.role == RoleEnum.ROLE_LOCATION) {
      this.profileDetailsLP$.subscribe((result: LPInfo | null) => {
        if (result != null) {
          this.profileDetails = result;
          this.editProfileForm.patchValue(result);
        } else {
          this.store.dispatch(invokeLPInfo());
        }
      });
    }
  }
  onSubmitEditProfile() {
    if (this.role == RoleEnum.ROLE_ADVERTISER) {
      const email: string = this.jwtService.getEmail();
      this.store.dispatch(
        invokeUpdateAdvertiser({
          profileDetails: {
            ...this.editProfileForm.value,
            activated: this.profileDetails?.activated,
            logo: this.profileDetails?.logo,
          },
          email: email,
        })
      );
    }
    if (this.role == RoleEnum.ROLE_LOCATION) {
      this.store.dispatch(
        invokeUpdateAccount({
          Authorization: this.jwtService.getToken(),
          locationPartnerDto: {
            ...this.editProfileForm.value,
            activated: this.profileDetails?.activated,
            logo: this.profileDetails?.logo,
          },
        })
      );
    }

    if (this.role == RoleEnum.ROLE_ADMIN) {
      this.store.dispatch(
        invokeUpdateAdminProfile({
          profile: {
            ...this.editProfileForm.value,
            logo: this.profileDetails?.logo,
          },
        })
      );
    }
  }
}
