import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { Store } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";
import { setAPIStatus } from "../apiState.interface";
import { ClientIberisService } from "src/app/shared/services/iberis/client-iberis.service";
import {
  invokeClienByHashedId,
  invokeCreateClient,
  invokeUpdateClient,
  resultClientByHashedId,
  resultCreateUpdateClient,
} from "./clientIberis.actions";
import { ResultListClientsFromIberis } from "src/app/shared/models/iberis/client/resultListClientFromIbris";
import { ResultCreateClient } from "src/app/shared/models/iberis/client/resultCreateClient";

@Injectable()
export class ClientIberisEffect {
  constructor(
    private actions$: Actions,
    private store: Store<AppStateInterface>,
    private clientIberisService: ClientIberisService
  ) {}

  invokeClientByHashedId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeClienByHashedId),
      mergeMap((data) => {
        return this.clientIberisService
          .getClientByHashedId(data.params, data.clientId)
          .pipe(
            map((data: ResultListClientsFromIberis | null) =>
              resultClientByHashedId({ resultClientByHashedId: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
  invokeCreateClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCreateClient),
      mergeMap((data) => {
        return this.clientIberisService
          .createClient(data.params, data.createClient)
          .pipe(
            map((data: ResultCreateClient | null) =>
              resultCreateUpdateClient({ createClient: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeUpdateClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateClient),
      mergeMap((data) => {
        return this.clientIberisService
          .updateClient(data.params, data.updateClient, data.idClient)
          .pipe(
            map((data: ResultCreateClient | null) =>
              resultCreateUpdateClient({ createClient: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
}
