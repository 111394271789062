export enum InvoiceStatusEnum{
    BROUILLON = "BROUILLON",
    VALIDE = "VALIDE",
    PAYÉ = "PAYMENT",
    EN_RETARD= "EN_RETARD"
}

  
  export const InvoiceStatusEnumMapping: Record<InvoiceStatusEnum, string> = {
    [InvoiceStatusEnum.BROUILLON]: "enumMapping.invoice.draft",
    [InvoiceStatusEnum.VALIDE]: "enumMapping.invoice.valid",
    [InvoiceStatusEnum.PAYÉ]: "enumMapping.invoice.paid",
    [InvoiceStatusEnum.EN_RETARD]: "enumMapping.invoice.delay",
  };
  
  export interface InvoiceStatusInterface {
    id: InvoiceStatusEnum;
    value: string;
  }