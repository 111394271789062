import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { IberisActivities } from "src/app/shared/models/iberis/activities";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-business-information",
  templateUrl: "./business-information.component.html",
})
export class BusinessInformationComponent implements OnInit {
  @Input("form") form: FormGroup;
  @Input("CompanyActivity") CompanyActivity: IberisActivities[];
  @Input("businessInformationSubmitted")
  businessInformationSubmitted: Observable<boolean>;
  submitted: boolean = false;
  termsOfUse = environment.links.termsOfUse
  constructor() {}
  ngOnInit(): void {
    this.businessInformationSubmitted.subscribe((data) => {
      if (data) {
        this.form.get("companyName")!.markAsTouched();
        this.form.get("companyName")!.updateValueAndValidity();

        this.form.get("companyAddress")!.markAsTouched();
        this.form.get("companyAddress")!.updateValueAndValidity();

        this.form.get("companyActivity")!.markAsTouched();
        this.form.get("companyActivity")!.updateValueAndValidity();

        this.form.get("acceptTerms")!.markAsTouched();
        this.form.get("acceptTerms")!.updateValueAndValidity();
      }
    });
  }
}
