import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
    
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Relead | Boost your business',
      description: 'Relead - reach your potential customers and grow your business through Wi-Fi advertising.'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Sign-up | Relead ',
      description: 'Sign-up now and start advertising with Relead.'
    }
  },
  {
    path: 'advertiser-confirm-email',
    component: ConfirmEmailComponent,
    data: {
      title: 'Sign-up | Relead ',
      description: 'Sign-up now and start advertising with Relead.'
    }
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent, data: {
      title: 'Reset your password | Relead',
      description: 'Have you lost the password to your Relead account? Enter your email address, and you should receive an email from us with a reset link.'
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
