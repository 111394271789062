import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";

@Injectable()
export class GuestGuardService implements CanActivate {
  constructor(private router: Router) { }

  canActivate() {
    if (localStorage.getItem("token") == null) {
      return true;
    }
    else {
      this.router.navigate(['dashboard']);
      return false;
    }
  }
}
