import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { bugReportStatus } from "src/app/shared/enum/bugReport-status";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeFilterBugReportByUser,
  invokeUpdateBugReportStatus,
  resultUpdateBugReportStatus,
} from "src/app/store/bug-report/bug-report.action";
import { resultUpdateBugReportStatusSelector } from "src/app/store/bug-report/bug-report.selectors";
import { loadingApproveAdvertiserSelector } from "src/app/store/advertiser/advertiser.selectors";
import {
  invokeApproveAdvertiser,
  approveAdvertiserByEmail,
} from "src/app/store/advertiser/advertiser.actions";
import { FilterListBugReport } from "src/app/shared/models/bugReport/filter-list-bug-report.interface";
import { initialState } from "src/app/store/bug-report/bug-report.reducers";
import { NavigationEnd, Router } from "@angular/router";
import { RoleEnum } from "src/app/shared/models/user/role.interface";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import { OptionActionEnum } from "src/app/shared/data/actions-data";
import { catchServerErrorSelector } from "src/app/store/user/user.selectors";
import { catchServerError } from "src/app/store/user/user.actions";

@Component({
  selector: "app-approve-modal",
  templateUrl: "./approve-modal.component.html",
})
export class ApproveModalComponent extends BaseComponent implements OnInit {
  @Output() closeTheModal: EventEmitter<boolean> = new EventEmitter();
  @Input() name: string;
  @Input() reference: string;
  @Input() status: bugReportStatus;
  @Input() title: string;
  @Input() roleName: RoleEnum;
  @Input() role: RoleEnum;
  @Input() type: OptionActionEnum;

  updateStatusBugReport$: Observable<SuccessDto | null>;
  updatedSuccessfully: string;
  filterListBugReportByUser: FilterListBugReport = {
    ...initialState.filterBugReportDto,
  };
  isAdvertiser: boolean;

  loading$: Observable<boolean | null>;
  loading: boolean = false;
  catchServerErreur$: Observable<boolean | null>;
  constructor(
    private store: Store<AppStateInterface>,
    private toastr: ToastrService,
    private translate: TranslateService,
    private route: Router,
    private modalService: NgbModal
  ) {
    super(store);
    this.updateStatusBugReport$ = this.store
      .pipe(select(resultUpdateBugReportStatusSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isAdvertiser = this.route.url.endsWith("/annonceur");
      }
    });
    this.loading$ = this.store
      .pipe(select(loadingApproveAdvertiserSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.catchServerErreur$ = this.store
      .pipe(select(catchServerErrorSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.catchServerErreur$.subscribe((result) => {
      if (result) {
        this.loading = false;
        this.store.dispatch(approveAdvertiserByEmail({ successMessage: null }));
        this.store.dispatch(catchServerError({ serverError: null }));
      }
    });
    this.loading$.subscribe((data) => {
      if (data) {
        this.loading = data;
      }
    });

    if (this.isAdvertiser) {
      this.filterListBugReportByUser.roleName = RoleEnum.ROLE_ADVERTISER;
    } else {
      this.filterListBugReportByUser.roleName = RoleEnum.ROLE_LOCATION;
    }
    this.updateStatusBugReport$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.updatedSuccessfully = result.message;
        this.store.dispatch(resultUpdateBugReportStatus({ successDto: null }));
        if (this.updatedSuccessfully != "") {
          this.toastr.success(
            this.translate.instant(
              "response.success." + this.updatedSuccessfully
            ),
            this.translate.instant("response.successTitle")
          );
          this.modalService.dismissAll(ApproveModalComponent);
          this.store.dispatch(
            invokeFilterBugReportByUser({
              filterBugReport: {
                ...this.filterListBugReportByUser,
                roleName: this.role,
              },
            })
          );
        }
      }
    });
  }
  closeModal() {
    this.closeTheModal.emit(true);
  }
  onClickUpdateStatus() {
    switch (this.type) {
      case OptionActionEnum.ADMIN_BUG_REPORT: {
        if (this.status == bugReportStatus.IN_PROGRESS) {
          this.store.dispatch(
            invokeUpdateBugReportStatus({
              title: this.reference,
              status: bugReportStatus.RESOLVED,
            })
          );
        } else {
          this.store.dispatch(
            invokeUpdateBugReportStatus({
              title: this.reference,
              status: bugReportStatus.IN_PROGRESS,
            })
          );
        }
        break;
      }
    }
  }
}
