<!-- [style.left.px]="popoverPosition.x"
    [style.top.px]="popoverPosition.y" -->
<div class="row" style="min-height: 500px">
  <div class="col-6">
    <div class="p-l-50 p-r-50'">
      <div class="d-flex justify-content-center m-t-20">
        <h3
          class="txt-primary f-w-300"
          style="text-align: center; line-height: 1.5"
        >
          {{ "maps.description1" | translate }}
        </h3>
      </div>

      <div class="d-flex justify-content-center">
        <h5
          class="txt-info f-w-300"
          style="text-align: center; line-height: 1.5"
        >
          {{ "maps.default" | translate }}
        </h5>
      </div>
      <div *ngIf="!showPopover; else showDetails">
        <div
          class="d-flex justify-content-center m-t-30"
          style="text-align: center"
        >
          <h4 class="f-w-500">{{ "maps.description2" | translate }}</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="map-container">
      <div
        #mapElement
        id="map"
        style="height: 500px; width: 100%; border-radius: 20px"
      ></div>
    </div>
  </div>
</div>

<ng-template #showDetails style="text-align: center; max-height: 400px">
  <div class="row m-t-25">
    <div class="col-6 d-flex justify-content-center align-items-center">
      <h4 class="f-w-500">{{ selectedLocation.companyName }}</h4>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <h5>
            <span class="f-w-400">{{ "maps.description3" | translate }} : </span
            ><span class="f-w-200">{{ selectedLocation.subCategories }}</span>
          </h5>
        </div>
        <div class="col-12">
          <h5>
            <span class="f-w-400">{{ "maps.description4" | translate }} : </span
            ><span class="f-w-200">{{ selectedLocation.tags }}</span>
          </h5>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div id="chart-widget4">
      <apx-chart
        #chartApex
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [labels]="chartOptions.labels"
        [legend]="chartOptions.legend"
        [yaxis]="chartOptions.yaxis"
        [xaxis]="chartOptions.xaxis"
        [dataLabels]="chartOptions.dataLabels"
        [stroke]="chartOptions.stroke"
        [title]="chartOptions.title"
        *ngIf="!isLoadingChart && length != 0"
      >
      </apx-chart>
      <div class="text-center" *ngIf="isLoadingChart">
        <div class="loader-box">
          <div class="loader-34"></div>
        </div>
        <h6 class="f-w-600">{{ "fetching" | translate }}</h6>
      </div>
    </div>

    <!--  <app-column-chart
            [xaxisTitle]="columnChart.xaxisTitle"
            [seriesTitles]="columnChart.seriesTitles"
            [yaxisTitle]="columnChart.yaxisTitle"
            [xaxisTitle]="columnChart.xaxisTitle"
            [resultGenderAgeStatics]="genderStatsForLp$"
          >
          </app-column-chart> -->
  </div>
</ng-template>
