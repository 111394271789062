<div class="media profile-media">
  <svg-icon src="assets/svgIcon/account.svg"></svg-icon>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li>
    <a  [routerLink]="['/dashboard/'+roleName+'/profile']"
      ><i data-feather="user"></i><span>{{ "header.profil" | translate }} </span></a
    >
  </li>
  <li  *ngIf="role != roleToShow.ROLE_ADMIN ">
    <a [routerLink]="['/dashboard/'+roleName+'/support']"><i data-feather="headphones"></i><span>Support</span></a>
  </li>
  <!--<li>
    <a href="task"><i data-feather="file-text"></i><span>Taskboard</span></a>
  </li>
   <li>
    <a href="users/users-edit"
      ><i data-feather="settings"></i><span>Settings</span></a
    >
  </li> -->
  <li>
    <a
      data-bs-toggle="modal"
      data-original-title="test"
      data-bs-target="#exampleModal"
      (click)="openLogoutModal(logoutModal)"
      ><i data-feather="log-in"> </i><span>{{ "header.logOut" | translate }}</span></a
    >
  </li>
</ul>
<!--(click)="logout()" -->
<ng-template #logoutModal let-modal>
  <app-logout [modal]="modal"></app-logout>
</ng-template>
